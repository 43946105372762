import { get, post, postFromData } from "../ApiHelper/index"
import {
    REMIND_TASK,
    NGUOI_NHAN_UPLOAD_RESULT_FILE,
    NGUOI_NHAN_CHECK_COMPLETED_TASK_CONTENT,
    NGUOI_NHAN_FILE_STEP_UPLOAD,
    NGUOI_NHAN_EDIT_RESULT_FILE,
    CONFIRM_TASK_CONTENT,
    CONFIRM_TASK_CONTENT_NGUOIGIAO
} from "../apis/_index";

export const Task_Remind = (taskId: any) => {
    return new Promise(async resolve => {
        return await get(REMIND_TASK + taskId)
            .then((res: any) => {
                resolve({
                    status: true,
                    data: res.data.Data
                });
            })
            .catch((err: any) => {
                resolve({
                    status: false,
                    err: err
                });
            })
    })
}

export const UploadFileTaskResult = (body: any) => {


    return new Promise(async resolve => {
        return await postFromData(NGUOI_NHAN_UPLOAD_RESULT_FILE, body)
            .then((res: any) => {


                resolve({
                    status: true,
                    data: res.data.Data
                });
            })
            .catch((err: any) => {

                resolve({
                    status: false,
                    err: err
                });
            })
    })
}

export const UploadFileTaskContent = (body: any) => {


    return new Promise(async resolve => {
        return await postFromData(NGUOI_NHAN_FILE_STEP_UPLOAD, body)
            .then((res: any) => {


                resolve({
                    status: true,
                    data: res.data.Data
                });
            })
            .catch((err: any) => {

                resolve({
                    status: false,
                    err: err
                });
            })
    })
}

export const EditFileTaskContent = (body: any) => {


    return new Promise(async resolve => {
        return await postFromData(NGUOI_NHAN_EDIT_RESULT_FILE, body)
            .then((res: any) => {


                resolve({
                    status: true,
                    data: res.data.Data
                });
            })
            .catch((err: any) => {

                resolve({
                    status: false,
                    err: err
                });
            })
    })
}

export const CheckCompletedTaskContent = (id: any) => {


    return new Promise(async resolve => {
        return await get(NGUOI_NHAN_CHECK_COMPLETED_TASK_CONTENT + id)
            .then((res: any) => {


                resolve({
                    status: true,
                    data: res.data.Data
                });
            })
            .catch((err: any) => {

                resolve({
                    status: false,
                    err: err
                });
            })
    })
}

export const ConfirmTaskContent = (body: any) => {
    return new Promise(async resolve => {
        return await post(CONFIRM_TASK_CONTENT, body)
            .then((res: any) => {
                resolve({
                    status: true,
                    data: res.data.Data
                });
            })
            .catch((err: any) => {

                resolve({
                    status: false,
                    err: err
                });
            })
    })
}

export const ConfirmTaskContent_NguoiGiao = (body: any) => {
    return new Promise(async resolve => {
        return await post(CONFIRM_TASK_CONTENT_NGUOIGIAO, body)
            .then((res: any) => {
                resolve({
                    status: true,
                    data: res.data.Data
                });
            })
            .catch((err: any) => {

                resolve({
                    status: false,
                    err: err
                });
            })
    })
}
