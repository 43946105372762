import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';

import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import axios from "axios";

import { onTouchForm } from "../../../funcs/onTochForm";

import LoaderSpinner from "../../loader-spinner/_index";
import { toast } from "react-toastify";

import { DETAIL_PARAMETER, UPDATE_PARAMETER, READ_PARAMETER } from "../../../apis/_index";
import { UPDATE_PARAM_CACHE } from "../../../redux/reducers/settingReducer/_index";
import { ONLOAD_PARAMETER_LIST } from "../../../redux/reducers/settingReducer/_index";

import SubModal from "../_subModal/_index";
import { convertNameParameter } from "../../../helperText/index";

const DetailListOfGeneral = (props: any) => {

  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const _onDeleteGeneral = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  }

  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const data = useSelector((state: any) => state.settingReducer);
  const shouldLog = useRef(true);
  const dispatch = useDispatch();

  const [isActive, setIsActive] = useState<boolean>(false);
  const [parameterKey, setParameterKey] = useState<any>("");
  const [parameterValue, setParameterValue] = useState<any>("");

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (
      parameterKey === ""
      && parameterValue === ""
      && shouldLog.current
    ) {
      shouldLog.current = false;
      const fetchData = async () => {
        try {
          const API = DETAIL_PARAMETER + taskId.toString();
          await axios.get(
            API,
            {
              headers: {
                Authorization: "Bearer " + accessToken
              }
            }
          )
            .then((res: any) => {
              if (res.status === 200) {
                setParameterKey(res.data.Data[0].ParameterKey);
                setParameterValue(res.data.Data[0].ParameterValue);
                setIsActive(res.data.Data[0].IsActive);
              } else {
                toast.error("Tải dữ liệu thất bại.")
              }
            })
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại.");
        }
      }
      fetchData();
    } else {
      shouldLog.current = false
      return;
    }
  })

  // funcs
  const _onClickUpdateParameter = async () => {
    setIsLoading(true)
    const accessToken = localStorage.getItem("accessToken");

    const body = {
      ParameterId: taskId,
      ParameterKey: parameterKey,
      ParameterValue: parameterValue,
      IsActive: isActive
    }

    try {
      axios.post(
        UPDATE_PARAMETER,
        body,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
            Accept: "text/plain"
          }
        }
      )
        .then((res: any) => {
          if (res.status === 200) {
            const accessToken = localStorage.getItem("accessToken");
            let API = "";

            if (data.parameterChoose.toString() === "1") {
              API = READ_PARAMETER;
            } else {
              API = READ_PARAMETER + `?key=${data.parameterChoose}`
            }

            const takeParameterList = async () => {
              try {
                await axios.get(
                  API,
                  {
                    headers: {
                      Authorization: "Bear " + accessToken
                    }
                  }
                )
                  .then((res: any) => {
                    if (res.status === 200) {
                      dispatch(ONLOAD_PARAMETER_LIST(res.data.Data))
                      toast.success("Cập nhật thành công.");
                      handleClose()
                    } else {
                      return;
                    }
                  })
              } catch (error: any) {
                toast.error("Tải dữ liệu thất bại.");
              }
            }

            takeParameterList();
          } else {
            toast.error("Cập nhật danh mục thất bại.")
          }
        })
    } catch (error: any) {
      toast.error("Cập nhật danh mục thất bại.")
    }
  }

  return (
    <div className="
    flex flex-col bg-white
    lg:w-[30rem] w-screen h-[21rem]
    rounded-md
  ">

      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
      />

      {/* header */}
      <div className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      ">
        <p>Chi tiết / Chỉnh sửa</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      {parameterKey === "" && parameterValue === "" || isLoading == true ?
        <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4 justify-center items-center">
          <LoaderSpinner
            w={32}
            h={32}
          />
          <p className="text-primary">Đang xử lý dữ liệu</p>
        </div>
        :
        <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
          {/* lines */}
          <div className="flex w-full h-max">
            <div className="flex flex-col w-full pr-0 h-max">
              <label>Tên danh mục&nbsp;<span className="text-red-500">(*)</span></label>

              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                value={parameterValue}

                onChange={(e: any) => {
                  e.preventDefault();
                  setParameterValue(e.target.value);
                }}

                onTouchStart={(e: any) => onTouchForm(e)}
              />

            </div>
          </div>

          <div className="flex w-full h-max">
            <div className="flex flex-col w-full h-max">
              <label>Nhóm danh mục&nbsp;<span className="text-red-500">(*)</span></label>
              <select
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                value={convertNameParameter(parameterKey)}

                onChange={(e: any) => {
                  e.preventDefault();
                  setParameterKey(e.target.value);
                }}
              >
                <option className="text-gray-300" value={0} disabled>--- Chọn nhóm danh mục ---</option>
                {data.parameterListKey.length === 0 ? ""
                  : data.parameterListKey.map((item: any, index: any) => (
                    <option value={item.ParameterKey} key={index}>{convertNameParameter(item.ParameterKey)}</option>
                  ))
                }
              </select>
            </div>
          </div>


          <div className="absolute left-4 bottom-4 flex items-center space-x-4 lg:w-1/3 w-full lg:pr-2 mt-5 pr-0 h-max">
            <p className="font-bold text-primary">Hiển thị</p>
            <input
              type="checkbox"
              checked={isActive}
              onChange={() => {
                setIsActive((value: any) => !value)
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>
      }


      {/* footer */}
      {parameterKey === "" && parameterValue === "" ?
        <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-between items-center pl-4 pr-4 text-sm space-x-4"></div>
        :
        <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-between items-center pl-4 pr-4 text-sm space-x-4">
          <button className={`
        flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1  text-white rounded-md
        ${isLoading === true ? "bg-slate-300" : "bg-slate-500 hover:bg-slate-400"}
      `}
            disabled={isLoading}
            onClick={() => _onDeleteGeneral("deleteGeneral")}
          >
            <DeleteIcon fontSize="small" />
            <p>Xoá</p>
          </button>

          <div className="flex w-max h-max space-x-3">
            <button className={`
        flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md
        ${isLoading === true ? "bg-slate-300" : "bg-primary hover:bg-sky-400"}
      `}
              onClick={_onClickUpdateParameter}
              disabled={isLoading}
            >
              <SaveIcon fontSize="small" />
              <p>Cập nhật</p>
            </button>

            <button className="
        flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
        hover:bg-slate-200
      "
              onClick={handleClose}
              disabled={isLoading}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      }

    </div>
  )
}

export default DetailListOfGeneral;
