import React, { useEffect, useRef, useState } from "react";
import parse, { HTMLReactParserOptions, Element } from "html-react-parser"
import ReactToPrint from "react-to-print";
import PrintIcon from '@mui/icons-material/Print';

import { BAO_CAO_CHI_TIET, BAO_CAO_UPDATE_REPORT } from "../../../apis/_index";

import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import { toast } from "react-toastify";
import axios from "axios";

import moment from "moment";

import reportView from "../../../temp/baocao";

import lacoLogo from "../../../temp/baocao/Asset7.png";
import LoaderSpinner from "../../loader-spinner/_index";

const EditElectric = (props: any) => {

  // Take props
  const taskId = props.taskId;
  const dataModal = props.dataModal;
  const handleClose = props.handleClose;

  const componentRef = useRef(null)

  const [resultOfReport, setResultOfReport] = useState<any>(null)
  const [optionParser, setOptionParser] = useState<any>(null)
  const [reportNotFinish, setReportNotFinish] = useState<any[] | null>(null)

  // funcs
  const _onChangeProcessingTask = (id: string, type: string, text: string) => {

    const tempArray = reportNotFinish?.map((item: any) =>
      item.TaskID === id && type === "different" ? { ...item, Report_Hard: text }
        : item.TaskID === id && type === "solution" ? { ...item, Report_Solution: text }
          : item.TaskID === id && type === "plan" ? { ...item, Report_Plan: text }
            : item
    )

    setReportNotFinish(tempArray === undefined ? [] : tempArray)
  }

  const reactToPrintTrigger = React.useCallback(() => {
    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
    // to the root node of the returned component as it will be overwritten.

    // Bad: the `onClick` here will be overwritten by `react-to-print`
    // return <button onClick={() => alert('This will not work')}>Print this out!</button>;

    // Good
    return <button
      id="print"
      className="
    flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-blue-500 text-white rounded-md
    hover:bg-blue-400
  "

    >
      <PrintIcon fontSize="small" />
      <p>In báo cáo</p>
    </button>;
  }, []);

  // onMount
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken")

    const fetchData = async () => {
      try {
        await axios.post(
          BAO_CAO_CHI_TIET,
          {
            ID: taskId
          },
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain"
            }
          }
        )
          .then(res => {
            if (res.data.StatusCode === 200) {
              const fetchData = () => {
                setReportNotFinish(res.data.Data.taskProccessing)
              }
              fetchData()
            }
          })
      } catch (error) {
        toast.error("Tải dữ liệu báo cáo thất bại.")
      }
    }

    fetchData()
  }, [])

  // console.log(resultOfReport)

  // parser-html
  useEffect(() => {
    const options: HTMLReactParserOptions = {
      replace: domNode => {
        const typedDomNode = domNode as Element

        if (typedDomNode.attribs) {
          if (parseInt(dataModal.periodic) === 1) {
            switch (typedDomNode.attribs.id) {
              case "lacoImage":
                return <img style={{ width: "100%", height: "8rem" }} src={lacoLogo} alt="" />
              case "giverName":
                return <p className="w-2/4">Tôi tên: <span className="text-black">{resultOfReport?.tasks_users[0].NameUser}</span></p>
              case "fromDate":
                return <div>&nbsp;{moment(resultOfReport?.tasks_report[0].Time_From).format('DD/MM/YYYY')}</div>;
              case "nameOfReport":
                return <h1 className="text-orange-400 font-bold text-3xl my-[3%]">{dataModal?.taskName}</h1>
              case "toDate":
                return <div>&nbsp;{moment(resultOfReport?.tasks_report[0].Time_To).format('DD/MM/YYYY')}</div>;
              case "taskOwner":
                return <span>{resultOfReport?.tasks_users[0].NameOwner}</span>;
              case "taskOwnerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuOwner}</span>;
              case "taskManager":
                return <span>{resultOfReport?.tasks_users[0].NameManager}</span>;
              case "taskManagerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuManager}</span>;
              case "fromDateOnTable":
                return <span>{moment(resultOfReport?.tasks_report[0].Time_From).format('DD/MM/YYYY')}</span>;
              case "toDateOnTable":
                return <span>{moment(resultOfReport?.tasks_report[0].Time_To).format('DD/MM/YYYY')}</span>;
              case "bodyOfTaskResponsible":
                return <tbody>
                  {
                    resultOfReport?.tasks_responsible.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {
                            item.TaskStatus === 0 ? "Đang tạo"
                              : item.TaskStatus === 1 ? "Đang chờ giao "
                                : item.TaskStatus === 2 ? "Đã giao"
                                  : item.TaskStatus === 3 ? "Đã nhận"
                                    : item.TaskStatus === 4 ? "Đang xử lý"
                                      : item.TaskStatus === 5 ? "Đã hoàn thành"
                                        : item.TaskStatus === 7 ? "Đã trả việc"
                                          : ""
                          }
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskComplete":
                return <tbody>
                  {
                    resultOfReport?.taskCompleted.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="w-full h-full">
                          </div>
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskProcessing":
                return <div className="flex flex-col space-y-10">
                  {
                    resultOfReport?.taskProccessing.map((item: any, index: number) => (
                      <div key={index}>
                        <p className="text-lg font-bold">{index + 1}. Tên công việc: {item.TaskName}</p>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Khó Khăn</label>
                          <textarea
                            readOnly
                            value={item.Report_Hard}
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "different", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Giải pháp</label>
                          <textarea
                            readOnly
                            wrap="soft"
                            value={item.Report_Solution}
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "solution", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
                          <textarea
                            readOnly
                            value={item.Report_Plan}
                            wrap="soft"
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "plan", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>
                      </div>
                    ))
                  }
                </div>;
              default:
                break
            }
          } else if (parseInt(dataModal.periodic) === 2) {
            switch (typedDomNode.attribs.id) {
              case "lacoImage":
                return <img style={{ width: "100%", height: "8rem" }} src={lacoLogo} alt="" />
              case "giverName":
                return <p className="w-2/4">Tôi tên: <span className="text-black">{resultOfReport?.tasks_users[0].NameUser}</span></p>
              case "optionsMonth":
                return <div>Tháng: {resultOfReport?.tasks_report[0].Month_Report}</div>;
              case "optionsYear":
                return <div>Năm: {resultOfReport?.tasks_report[0].Year_Report}</div>;
              case "fromDate":
                return <></>;
              case "nameOfReport":
                return <h1 id="nameOfReport" className="text-orange-400 font-bold text-3xl my-[3%]"></h1>
              case "toDate":
                return <></>;
              case "taskOwner":
                return <span>{resultOfReport?.tasks_users[0].NameOwner}</span>;
              case "taskOwnerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuOwner}</span>;
              case "taskManager":
                return <span>{resultOfReport?.tasks_users[0].NameManager}</span>;
              case "taskManagerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuManager}</span>;
              case "fromDateOnTable":
                return <></>;
              case "toDateOnTable":
                return <></>;
              case "bodyOfTaskResponsible":
                return <tbody>
                  {
                    resultOfReport?.tasks_responsible.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {
                            item.TaskStatus === 0 ? "Đang tạo"
                              : item.TaskStatus === 1 ? "Đang chờ giao "
                                : item.TaskStatus === 2 ? "Đã giao"
                                  : item.TaskStatus === 3 ? "Đã nhận"
                                    : item.TaskStatus === 4 ? "Đang xử lý"
                                      : item.TaskStatus === 5 ? "Đã hoàn thành"
                                        : item.TaskStatus === 7 ? "Đã trả việc"
                                          : ""
                          }
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskComplete":
                return <tbody>
                  {
                    resultOfReport?.taskCompleted.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="w-full h-full">
                          </div>
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskProcessing":
                return <div className="flex flex-col space-y-10">
                  {
                    resultOfReport?.taskProccessing.map((item: any, index: number) => (
                      <div key={index}>
                        <p className="text-lg font-bold">{index + 1}. Tên công việc: {item.TaskName}</p>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Khó Khăn</label>
                          <textarea
                            readOnly
                            value={item.Report_Hard}
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "different", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Giải pháp</label>
                          <textarea
                            readOnly
                            wrap="soft"
                            value={item.Report_Solution}
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "solution", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
                          <textarea
                            readOnly
                            value={item.Report_Plan}
                            wrap="soft"
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "plan", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>
                      </div>
                    ))
                  }
                </div>;

              default:
                break
            }
          } else if (parseInt(dataModal.periodic) === 3) {
            switch (typedDomNode.attribs.id) {
              case "lacoImage":
                return <img style={{ width: "100%", height: "8rem" }} src={lacoLogo} alt="" />
              case "giverName":
                return <p className="w-2/4">Tôi tên: <span className="text-black">{resultOfReport?.tasks_users[0].NameUser}</span></p>
              case "optionsQuarter":
                return <div>Quý: {resultOfReport?.tasks_report[0].Quarter_Report}</div>;
              case "optionsYear":
                return <div>Năm: {resultOfReport?.tasks_report[0].Year_Report}</div>;
              case "fromDate":
                return <></>;
              case "nameOfReport":
                return <h1 id="nameOfReport" className="text-orange-400 font-bold text-3xl my-[3%]"></h1>
              case "toDate":
                return <></>;
              case "taskOwner":
                return <span>{resultOfReport?.tasks_users[0].NameOwner}</span>;
              case "taskOwnerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuOwner}</span>;
              case "taskManager":
                return <span>{resultOfReport?.tasks_users[0].NameManager}</span>;
              case "taskManagerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuManager}</span>;
              case "fromDateOnTable":
                return <></>;
              case "toDateOnTable":
                return <></>;
              case "bodyOfTaskResponsible":
                return <tbody>
                  {
                    resultOfReport?.tasks_responsible.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {
                            item.TaskStatus === 0 ? "Đang tạo"
                              : item.TaskStatus === 1 ? "Đang chờ giao "
                                : item.TaskStatus === 2 ? "Đã giao"
                                  : item.TaskStatus === 3 ? "Đã nhận"
                                    : item.TaskStatus === 4 ? "Đang xử lý"
                                      : item.TaskStatus === 5 ? "Đã hoàn thành"
                                        : item.TaskStatus === 7 ? "Đã trả việc"
                                          : ""
                          }
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskComplete":
                return <tbody>
                  {
                    resultOfReport?.taskCompleted.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="w-full h-full">
                          </div>
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskProcessing":
                return <div className="flex flex-col space-y-10">
                  {
                    resultOfReport?.taskProccessing.map((item: any, index: number) => (
                      <div key={index}>
                        <p className="text-lg font-bold">{index + 1}. Tên công việc: {item.TaskName}</p>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Khó Khăn</label>
                          <textarea
                            readOnly
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "different", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Giải pháp</label>
                          <textarea
                            readOnly
                            wrap="soft"
                            value={item.Report_Solution}
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "solution", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
                          <textarea
                            readOnly
                            value={item.Report_Plan}
                            wrap="soft"
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "plan", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>
                      </div>
                    ))
                  }
                </div>;

              default:
                break
            }
          } else if (parseInt(dataModal.periodic) === 4) {
            switch (typedDomNode.attribs.id) {
              case "lacoImage":
                return <img style={{ width: "100%", height: "8rem" }} src={lacoLogo} alt="" />
              case "giverName":
                return <p className="w-2/4">Tôi tên: <span className="text-black">{resultOfReport?.tasks_users[0].NameUser}</span></p>
              case "optionsYear":
                return <div>Năm: {resultOfReport?.tasks_report[0].Year_Report}</div>;
              case "fromDate":
                return <></>;
              case "nameOfReport":
                return <h1 id="nameOfReport" className="text-orange-400 font-bold text-3xl my-[3%]"></h1>
              case "toDate":
                return <></>;
              case "taskOwner":
                return <span>{resultOfReport?.tasks_users[0].NameOwner}</span>;
              case "taskOwnerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuOwner}</span>;
              case "taskManager":
                return <span>{resultOfReport?.tasks_users[0].NameManager}</span>;
              case "taskManagerLevel":
                return <span>{resultOfReport?.tasks_users[0].ChucVuManager}</span>;
              case "fromDateOnTable":
                return <></>;
              case "toDateOnTable":
                return <></>;
              case "bodyOfTaskResponsible":
                return <tbody>
                  {
                    resultOfReport?.tasks_responsible.map((item: any, index: number) => (
                      <tr key={index}>
                        <th style={{ border: '2px solid black', height: "2rem" }} scope="row">{index + 1}</th>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {item.TaskOwnerName}
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.AssignmentDate).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.AssignmentDate).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          <div className="flex flex-col text-center">
                            <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
                            <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
                          </div>
                        </td>
                        <td style={{ border: '2px solid black' }}>
                          {
                            item.TaskStatus === 0 ? "Đang tạo"
                              : item.TaskStatus === 1 ? "Đang chờ giao "
                                : item.TaskStatus === 2 ? "Đã giao"
                                  : item.TaskStatus === 3 ? "Đã nhận"
                                    : item.TaskStatus === 4 ? "Đang xử lý"
                                      : item.TaskStatus === 5 ? "Đã hoàn thành"
                                        : item.TaskStatus === 7 ? "Đã trả việc"
                                          : ""
                          }
                        </td>
                      </tr>
                    ))
                  }

                </tbody>;
              case "bodyOfTaskComplete":
                return <div className="flex flex-col space-y-10">
                  {
                    resultOfReport?.taskProccessing.map((item: any, index: number) => (
                      <div key={index}>
                        <p className="text-lg font-bold">{index + 1}. Tên công việc: {item.TaskName}</p>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Khó Khăn</label>
                          <textarea
                            readOnly
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "different", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Giải pháp</label>
                          <textarea
                            readOnly
                            wrap="soft"
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "solution", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
                          <textarea
                            readOnly
                            wrap="soft"
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "plan", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>
                      </div>
                    ))
                  }

                </div>;
              case "bodyOfTaskProcessing":
                return <div className="flex flex-col space-y-10">
                  {
                    resultOfReport?.taskProccessing.map((item: any, index: number) => (
                      <div key={index}>
                        <p className="text-lg font-bold">{index + 1}. Tên công việc: {item.TaskName}</p>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Khó Khăn</label>
                          <textarea
                            readOnly
                            value={item.Report_Hard}
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "different", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Giải pháp</label>
                          <textarea
                            readOnly
                            wrap="soft"
                            value={item.Report_Solution}
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "solution", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>

                        <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                          <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
                          <textarea
                            readOnly
                            value={item.Report_Plan}
                            wrap="soft"
                            onChange={(e: any) => {
                              e.preventDefault()
                              _onChangeProcessingTask(item.ID, "plan", e.target.value)
                            }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                        </div>
                      </div>
                    ))
                  }
                </div>;
              default:
                break
            }
          }
        }
      }
    };

    setOptionParser(options)

  }, [resultOfReport])

  // funcs
  const _onClickUpdateReport = () => {
    const accessToken = localStorage.getItem("accessToken")
    const body = {
      ID: taskId,
      TaskName: dataModal.taskName,
      Report_Update: reportNotFinish
    }

    const fetchData = async () => {
      try {
        await axios.post(
          BAO_CAO_UPDATE_REPORT,
          body,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain"
            }
          }
        )
          .then(res => {
            if (res.data.StatusCode === 200) {
              toast.success("Cập nhật thành công")
              handleClose()
              window.location.reload()
            }
          })
      } catch (error) {
        toast.error("Cập nhật thất bại.")
      }
    }

    fetchData()
  }

  return (
    <div
      className="
        flex flex-col bg-white
        lg:w-[50rem] w-screen lg:h-[90vh] h-[calc(100vh-5rem)]
        rounded-md 
      "
    >
      {/* Headers */}
      <div
        className="        
          flex items-center pl-4 pr-4
          text-white font-semibold
          bg-sky-500 w-full h-[3rem]
          rounded-t-md text-lg justify-between
        ">
        <p>Biểu mẫu báo cáo</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* Content */}
      {
        reportNotFinish === null ?
          <div className="flex-col w-full h-[calc(100%-6rem)] flex p-3 text-sm overflow-scroll justify-center items-center">
            <LoaderSpinner w={32} h={32} />
            <p className="text-primary">Đang tải dữ liệu</p>
          </div>
          :
          <div className="w-full h-[calc(100%-6rem)] flex p-3 text-sm overflow-scroll">
            <div className="w-full h-max flex">
              <div className="w-full h-max flex flex-col space-y-5">
                {
                  reportNotFinish?.map((item: any, index: number) => (
                    <div className="w-full h-max flex flex-col">
                      <p className="font-bold text-lg">{index + 1}. {item.TaskName}</p>

                      <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                        <label className="form-label" >Khó Khăn</label>
                        <textarea

                          value={item?.Report_Hard}
                          onChange={(e: any) => {

                            _onChangeProcessingTask(item.ID, "different", e.target.value)
                          }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                      </div>

                      <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                        <label className="form-label" >Giải pháp</label>
                        <textarea

                          wrap="soft"
                          value={item?.Report_Solution}
                          onChange={(e: any) => {

                            _onChangeProcessingTask(item.ID, "solution", e.target.value)
                          }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                      </div>

                      <div className="form-control mt-6 form-control-sm flex flex-col w-full h-max">
                        <label className="form-label" >Kế hoạch hành động tháng kế tiếp</label>
                        <textarea

                          value={item?.Report_Plan}
                          wrap="soft"
                          onChange={(e: any) => {
                            e.preventDefault()
                            _onChangeProcessingTask(item.ID, "plan", e.target.value)
                          }} className="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1"></textarea>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
      }



      {/* Footer */}
      <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md  items-center pl-4 pr-4 text-sm space-x-4">
        <div className="flex w-full h-max space-x-3 justify-between items-center">
          {/* Print button */}
          {/* <button
            className={`
              flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md bg-green-500 hover:bg-green-400
            `}
          >

          </button> */}
          <div className="w-max h-max flex space-x-3">
            <ReactToPrint
              content={() => { return componentRef.current }}
              trigger={reactToPrintTrigger}
            />

            <div className="hidden">
              <div className="w-full h-max" ref={componentRef}>
                {parse(reportView, optionParser)}
              </div>
            </div>
          </div>
          {/* Print button */}


          {/* Close button */}

          <div className="w-max h-max flex space-x-3">
            {/* <button
              className="
              flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-primary text-white rounded-md
              hover:bg-sky-400
            "
              onClick={_onClickUpdateReport}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Lưu cập nhật</p>
            </button> */}

<button
              className="
              flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-red-500 text-white rounded-md
              hover:bg-red-400
            "
              onClick={_onClickUpdateReport}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Xoá</p>
            </button>

            <button
              className="
              flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-primary text-white rounded-md
              hover:bg-sky-400
            "
              onClick={_onClickUpdateReport}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Gửi báo cáo</p>
            </button>

            <button
              className="
              flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
              hover:bg-slate-200
            "
              onClick={handleClose}
            >
              <ExitToAppTwoToneIcon fontSize="small" />
              <p>Đóng</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditElectric