import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import TaskTwoToneIcon from "@mui/icons-material/TaskTwoTone";

import SubModal from "../_subModal/_index";
import axios from "axios";

import { CREATE_QUYTRINH, READ_ALL_LIST_QUYTRINH } from "../../../apis/_index";
import { ONLOAD_QUYTRINH_LIST } from "../../../redux/reducers/workingReducer/_index";
import { toast } from "react-toastify";

const CreateWorkflow = (props: any) => {
  const dispatch = useDispatch();

  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [quytrinhName, setQuytrinhName] = useState<string>("");

  const [stepList, setStepList] = useState<any[]>([]);
  const [tempStepList, setTempStepList] = useState<string>("");

  const [resultList, setResultList] = useState<any[]>([]);
  const [tempResultList, setTempResultList] = useState<string>("");

  const _onChangeMultipleChoicePartner = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  // // funcs
  // const _onChangeStepValue = (e: any) => {
  //   e.preventDefault();
  //   setTempStepList(e.target.value);
  // }

  // const _onChangeResultValue = (e: any) => {
  //   e.preventDefault();
  //   setTempResultList(e.target.value);
  // }

  // const _onClickPushStep = () => {
  //   setStepList((oldValue: any) => [...oldValue, tempStepList]);
  //   setTempStepList("");
  // }

  // const _onClickPushResult = () => {
  //   setResultList((oldValue: any) => [...oldValue, tempResultList]);
  //   setTempResultList("");
  // }

  // const _onKeyUpStep = (e: any) => {
  //   if (e.key === "Enter") {
  //     e.preventDefault();
  //     _onClickPushStep()
  //   }
  // }

  // const _onKeyUpResult = (e: any) => {
  //   if (e.key === "Enter") {
  //     e.preventDefault();
  //     _onClickPushResult()
  //   }
  // }

  // const _onRemoveStep = (item: number) => {
  //   setStepList((oldValue: any) => oldValue.filter((elements: any) => elements !== item))
  // }

  // const _onRemoveResult = (item: number) => {
  //   setResultList((oldValue: any) => oldValue.filter((elements: any) => elements !== item))
  // }

  const _onClickSubmit = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const bodyQuytrinh = { tenquytrinh: quytrinhName };

    try {
      await axios
        .post(CREATE_QUYTRINH, bodyQuytrinh, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        })
        .then((res: any) => {
          if (res.status === 200) {
            setQuytrinhName("");
            const takeData = async () => {
              await axios
                .get(READ_ALL_LIST_QUYTRINH, {
                  headers: {
                    Accept: "text/plain",
                    Authorization: "Bearer " + accessToken,
                  },
                })
                .then((res: any) => {
                  dispatch(ONLOAD_QUYTRINH_LIST(res.data.Data));
                  toast.success("Tạo mới đầu việc thành công.");
                  handleClose();
                });
            };

            takeData();
          }
        });
    } catch (error: any) {
      toast.error("Tạo mới đầu việc thất bại.");
    }
  };

  // take props
  const handleClose = props.handleClose;

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[35rem] w-screen h-[15rem]
      rounded-md
    "
    >
      <SubModal open={openSub} handleClose={handleCloseSub} funcs={funcsSub} />

      {/* header */}
      <div
        className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Tạo quy trình</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-3 text-sm">
        <div className="flex flex-col space-y-4 w-full h-full">
          <div className="flex flex-col w-full h-max">
            <label>Tên quy trình</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              placeholder="Nhập tên quy trình"
              onChange={(e: any) => {
                e.preventDefault();
                setQuytrinhName(e.target.value);
              }}
            />
          </div>

          {/* <div className="flex w-full h-max">
            <div className="flex flex-col w-4/5 h-max">
              <label>Nội dung bước thực hiện</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Nhập nội dung bước"
                value={tempStepList}
                onChange={(e: any) => _onChangeStepValue(e)}
                onKeyUp={(e: any) => _onKeyUpStep(e)}
              />
            </div>
            <div className="pl-3 flex flex-col w-1/5 h-max">
              <label className="text-white">Thêm</label>
              <button className="w-full h-[1.9rem] bg-green-500 text-white rounded-md hover:bg-green-400"
                onClick={_onClickPushStep}
              >
                Thêm
              </button>
            </div>
          </div>

          {stepList.length === 0 ? "" :
            <div className="flex flex-col w-full h-max">
              {stepList.map((item: any, index: any) => (
                <div key={index} className="flex w-full h-max text-primary items-center justify-between">
                  <div className="flex w-5/6 h-max space-x-1">
                    <ModeTwoToneIcon fontSize="small" />
                    <p>Bước {index + 1}: <span className="text-black">{item}</span></p>
                  </div>

                  <button className="text-red-500 w-1/6 flex justify-end" onClick={() => _onRemoveStep(item)}>
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>
              ))}
            </div>
          }

          <div className="flex w-full h-max">
            <div className="flex flex-col w-4/5 h-max">
              <label>Nội dung kết quả</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Nhập nội dung kết quả"
                value={tempResultList}
                onChange={(e: any) => _onChangeResultValue(e)}
                onKeyUp={(e: any) => _onKeyUpResult(e)}
              />
            </div>
            <div className="pl-3 flex flex-col w-1/5 h-max">
              <label className="text-white">Thêm</label>
              <button className="w-full h-[1.9rem] bg-green-500 text-white rounded-md hover:bg-green-400"
                onClick={_onClickPushResult}
              >
                Thêm
              </button>
            </div>
          </div>

          {resultList.length === 0 ? "" :
            <div className="flex flex-col w-full h-max">
              {resultList.map((item: any, index: any) => (
                <div key={index} className="flex w-full h-max text-primary items-center justify-between">
                  <div className="flex w-5/6 h-max space-x-1">
                    <TaskTwoToneIcon fontSize="small" />
                    <p>Kết quả {index + 1}: <span className="text-black">{item}</span></p>
                  </div>

                  <button className="text-red-500 w-1/6 flex justify-end" onClick={() => _onRemoveResult(item)}>
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>
              ))}
            </div>
          } */}

          {/* <div className="flex flex-col w-full h-max">
            <div className="flex w-full h-max text-primary items-center justify-between">
              <div className="flex w-5/6 h-max space-x-1">
                <ModeTwoToneIcon fontSize="small" />
                <p>Bước thực hiện 1</p>
              </div>

              <button className="text-red-500 w-1/6 flex justify-end">
                <DeleteTwoToneIcon fontSize="small" />
              </button>
            </div>

            <div className="flex w-full h-max text-primary items-center justify-between">
              <div className="flex w-5/6 h-max space-x-1">
                <ModeTwoToneIcon fontSize="small" />
                <p>Bước thực hiện 2</p>
              </div>

              <button className="text-red-500 w-1/6 flex justify-end">
                <DeleteTwoToneIcon fontSize="small" />
              </button>
            </div>

            <div className="flex w-full h-max text-primary items-center justify-between">
              <div className="flex w-5/6 h-max space-x-1">
                <ModeTwoToneIcon fontSize="small" />
                <p>Bước thực hiện 3</p>
              </div>

              <button className="text-red-500 w-1/6 flex justify-end">
                <DeleteTwoToneIcon fontSize="small" />
              </button>
            </div>

            <div className="flex w-full h-max text-primary items-center justify-between">
              <div className="flex w-5/6 h-max space-x-1">
                <ModeTwoToneIcon fontSize="small" />
                <p>Bước thực hiện 4</p>
              </div>

              <button className="text-red-500 w-1/6 flex justify-end">
                <DeleteTwoToneIcon fontSize="small" />
              </button>
            </div>
          </div> */}
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-end items-center pl-4 pr-4 text-sm space-x-4">
        <button
          className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-primary text-white rounded-md
          hover:bg-sky-400
        "
          onClick={_onClickSubmit}
        >
          <AddCircleOutlineTwoToneIcon fontSize="small" />
          <p>Tạo quy trình</p>
        </button>

        <button
          className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
          onClick={handleClose}
        >
          <ExitToAppTwoToneIcon fontSize="small" />
          <p>Đóng</p>
        </button>
      </div>
    </div>
  );
};

export default CreateWorkflow;
