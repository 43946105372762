import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  READ_ALL_LIST_QUYTRINH_DROPDOWN,
  LOAD_PHONG_BAN,
  PRIOR_LIST_DROPDOWN,
  SECURITY_READ_ALL_DROPDOWN,
  READ_TASK_LIST_BY_USER,
  TAKE_PARAMETER_LISTKEY_DROPDOWN,
  DETAIL_STEP_DROPDOWN,
  DETAIL_RESULT_DROPDOWN,
  CREATE_TASK_LIST_NGUOI_GIAO,
  NGUOI_GIAO_VIEC_LIST,
  DIFFICULT_LEVEL,
  CONFIG_IS_FORCE,
  DIFICULT_LEVEL_DROPDOWN,
  LOAD_PHONG_BAN_BY_USER,
  APPROVE_TASK,
} from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import Select from "react-select";

import { takePermission } from "../../../funcs";

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SubModal from "../_subModal/_index";
import { toast } from "react-toastify";
import axios from "axios";
import CircleIcon from "@mui/icons-material/Circle";
import { ONLOAD_TASKLIST_ALL } from "../../../redux/reducers/workingReducer/_index";
import CheckBox from "@mui/icons-material/CheckBox";

const CreateNewTask = (props: any) => {
  // reducer
  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  let userData: any = localStorage.getItem("userData");
  let defaultPhongBanTaskReviewer: any;
  let defaultTaskReviewer: any;


  userData = userData.replace(/'/g, '"'); //replacing all ' with "
  userData = JSON.parse(userData);
  userData.Data.map(

    (item: any) =>
    (defaultPhongBanTaskReviewer = {
      IdPhongBan: item.PhongBan,
      TenPhongBan: item.TenPhongBan,
    })
  );
  userData.Data.map((item: any) => {
    defaultTaskReviewer = {
      FullName: item.FullName,
      Id: item.Id,
    };
  });

  // take props
  const handleClose = props.handleClose;

  // state
  const shouldLog = useRef(true);
  const shouldLog3 = useRef(true);
  const urlLink = window.location.pathname;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const menu = takePermission(19);

  // submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [userList, setUserList] = useState<any[] | null>(null);
  const [loaiViecList, setLoaiViecList] = useState<any[] | null>(null);
  const [nguonVanBanList, setNguonVanBanList] = useState<any[] | null>(null);
  const [uuTienList, setUuTienList] = useState<any[] | null>(null);
  const [doMatList, setDoMatList] = useState<any[] | null>(null);
  const [dauViecList, setDauViecList] = useState<any[] | null>(null);
  const [quyTrinhList, setQuyTrinhList] = useState<any[] | null>(null);
  const [doKhoList, setDoKhoList] = useState<any[] | null>(null);
  const uploadFileChat = useRef<any>(null);
  const [selectedDoKho, setSelectedDoKho] = useState<any>(-1);
  const [selectedPhongBan, setSelectedPhongBan] = useState<any>(-1);
  const [selectedNguoiNhan, setSelectedNguoiNhan] = useState<any>(-1);

  const [userDanhGiaList, setUserDanhGiaList] = useState<any>(null);
  const [phongBanDanhGiaList, setPhongBanDanhGiaList] = useState<any[] | null>(
    null
  );
  const [selectedPhongBanDanhGia, setSelectedPhongBanDanhGia] = useState<any>(
    defaultPhongBanTaskReviewer || null
  );
  const [selectedNguoiDanhGiaList, setSelectedNguoiDanhGiaList] =
    useState<any>(null);

  const [selectedNguoiDanhGia, setSelectedNguoiDanhGia] = useState<any>(
    defaultTaskReviewer || null
  );

  const [selectedLoaiViec, setSelectedLoaiViec] = useState<any>(-1);
  const [selectedNguonVanBan, setSelectedNguonVanBan] = useState<any>(-1);
  const [selectedUuTien, setSelectedUuTien] = useState<any>(-1);
  const [selectedDoMat, setSelectedDoMat] = useState<any>(-1);
  const [selectedDauViec, setSelectedDauViec] = useState<any>(-1);
  const [selectedQuyTrinh, setSelectedQuyTrinh] = useState<any>(-1);

  const [isAutoForce, setIsAutoForce] = useState<boolean | null>(null);
  const [isAutoSecurity, setIsAutoSecurity] = useState<boolean | null>(null);

  const [selectedNguoiNhanList, setSelectedNguoiNhanList] = useState<any>(null);
  const [selectedNguoiPhoiHopList, setSelectedNguoiPhoiHopList] =
    useState<any>(null);

  const [selectedNguoiLamViecList, setSelectedNguoiLamViecList] = useState<any>(
    []
  );

  const [selectedBuocList, setSelectedBuocList] = useState<any>(null);
  const [selectedKetQuaList, setSelectedKetQuaList] = useState<any>(null);

  const [filesList, setFilesList] = useState<any>([]);
  const [filesNameList, setFilesNameList] = useState<any>();
  const [notes, setNotes] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isForce, setIsForce] = useState<boolean>(false);
  const [isChooseTaskReviewer, setIsChooseTaskReviewer] =
    useState<boolean>(false);
  const [isSelectedBuocOK, setIsSelectedBuocOK] = useState<boolean>(false);

  // other thing
  const [taskName, setTaskName] = useState<any>("");
  const [deadLine, setDeadLine] = useState<any>("");
  const [refCode, setRefCode] = useState<any>("");

  // props - states
  const [positionStep, setPositionStep] = useState<number | null>(null);
  const [hourStep, setHourStep] = useState<number | null>(null);
  const [contentStep, setContentStep] = useState<string>("");

  const [ketqua, setKetqua] = useState<string>("");

  // funcs
  const _onChangeRefCode = (e: any) => {
    e.preventDefault();
    setRefCode(e.target.value);
  };

  const _onChangePhongBan = (e: any) => {
    e.preventDefault();
    setSelectedPhongBan(
      phongBanList?.find(
        (item: any) => item.IdPhongBan === parseInt(e.target.value)
      )
    );
    setSelectedNguoiNhanList(
      userList?.filter(
        (item: any) => item.IdPhongBan === parseInt(e.target.value)
      )
    );
    setSelectedNguoiNhan(-1);

    setSelectedNguoiLamViecList([]);
    setSelectedQuyTrinh(-1);
    setSelectedBuocList(null);
    setSelectedKetQuaList(null);
  };

  const _onChangeNguoiNhan = (e: any) => {
    e.preventDefault();
    setSelectedNguoiNhan(
      selectedNguoiNhanList?.find((item: any) => item.Id === e.target.value)
    );
    setSelectedNguoiPhoiHopList(
      userList?.filter((item: any) => item.Id !== e.target.value)
    );

    const tempArray = [
      selectedNguoiNhanList?.find((item: any) => item.Id === e.target.value),
    ];

    setSelectedNguoiLamViecList(tempArray);
  };

  const _onChangePhongBanDanhGia = (e: any) => {
    e.preventDefault();
    setSelectedPhongBanDanhGia(
      phongBanDanhGiaList?.find(
        (item: any) => item.IdPhongBan === parseInt(e.target.value)
      )
    );
    setSelectedNguoiDanhGiaList(
      userDanhGiaList?.filter(
        (item: any) => item.IdPhongBan === parseInt(e.target.value)
      )
    );
    setSelectedNguoiDanhGia(-1);
  };

  const _onChangeNguoiDanhGia = (e: any) => {
    e.preventDefault();
    setSelectedNguoiDanhGia(
      selectedNguoiDanhGiaList?.find((item: any) => item.Id === e.target.value)
    );
  };

  const _onChangeStepList = (type: string, value: any, index: any) => {
    switch (type) {
      case "stepName":
        let newArray = [...selectedBuocList];
        newArray[index].StepName = value;
        setSelectedBuocList(newArray);
        break;
      case "orderBy":
        let newArray2 = [...selectedBuocList];
        newArray2[index].SortOrder = value;
        setSelectedBuocList(newArray2);
        break;
      case "result":
        let newArray3 = [...selectedKetQuaList];
        newArray3[index].tenketqua = value;
        setSelectedKetQuaList(newArray3);
        break;
      default:
        break;
    }
  };

  const _onCheckNguoiLamViec = (id: any) => {
    const isTrue = selectedNguoiLamViecList?.find(
      (item: any) => item?.Id === id
    );

    if (isTrue === undefined) {
      setSelectedNguoiLamViecList((oldValue: any) => [
        ...oldValue,
        userList?.find((item: any) => item.Id === id),
      ]);
    } else {
      const tempArray = selectedNguoiLamViecList?.filter(
        (item: any) => item.Id !== id
      );
      setSelectedNguoiLamViecList(tempArray);
    }
  };

  const _onChangeQuyTrinh = (e: any) => {
    e.preventDefault();

    if (e.target.value === "-1") {
      setSelectedQuyTrinh(-1);
      setSelectedBuocList(null);
      setSelectedKetQuaList(null);
    } else {
      const accessToken = localStorage.getItem("accessToken");

      setSelectedQuyTrinh(
        quyTrinhList?.find((item: any) => item.id === parseInt(e.target.value))
      );

      const takeStep = async () => {
        try {
          await axios
            .get(DETAIL_STEP_DROPDOWN + e.target.value, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              let tempArray: any[] = [];

              res.data.Data.forEach((item: any, index: any) => {
                const body = {
                  StepName: item.tenbuoc,
                  UserDo: "",
                  DeadLine_Step: "",
                  SortOrder: item.orderBy,
                };
                tempArray.push(body);
              });

              setSelectedBuocList(tempArray);
            });
        } catch (error: any) { }
      };

      const takeResult = async () => {
        try {
          await axios
            .get(DETAIL_RESULT_DROPDOWN + e.target.value, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              setSelectedKetQuaList(res.data.Data);
            });
        } catch (error: any) { }
      };

      takeStep();
      takeResult();
    }
  };

  // non - funcs
  const _onChangeNguoiPhoiHop = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onClickEditBuoc = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onClickEditKetqua = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  // props - funcs
  // buoc
  const _onChangePositionStep = (e: any) => {
    e.preventDefault();
    setPositionStep(parseInt(e.target.value));
  };

  const _onChangeHourStep = (e: any) => {
    e.preventDefault();
    setHourStep(e.target.value);
  };

  const _onChangeContentStep = (e: any) => {
    e.preventDefault();
    setContentStep(e.target.value);
  };

  const _onPushStep = () => {
    const body = {
      StepName: contentStep,
      UserDo: "",
      DeadLine_Step: "",
      SortOrder: positionStep,
    };

    if (selectedBuocList === null || selectedBuocList === undefined) {
      setSelectedBuocList([body]);
    } else {
      let isExistedStep = selectedBuocList.find(
        (item: any) => item.SortOrder === positionStep
      );

      if (isExistedStep === undefined) {
        setSelectedBuocList((oldValue: any) => [...oldValue, body]);
      } else {
      }
    }

    setPositionStep(null);
    setHourStep(null);
    setContentStep("");
  };

  const _onDeleteQuyTrinh = (step: any) => {
    setSelectedBuocList(
      selectedBuocList.filter((item: any) => item.SortOrder !== step)
    );
  };

  const _onChangeNguoiPhoiHopEachStep = (orderBy: any, nguoiPhoiHop: any) => {
    const updateArray = selectedBuocList.map((item: any) => {
      if (item.SortOrder === orderBy) {
        return {
          ...item,
          UserDo: nguoiPhoiHop,
        };
      }

      return item;
    });

    setSelectedBuocList(updateArray);
  };

  const _onChangDeadLineStep = (orderBy: any, deadLine: any) => {
    const updateArray = selectedBuocList.map((item: any) => {
      if (item.SortOrder === orderBy) {
        return {
          ...item,
          DeadLine_Step: deadLine,
        };
      }

      return item;
    });

    setSelectedBuocList(updateArray);
  };
  const _onClickChooseFileChat = () => {
    uploadFileChat.current.click();
  };
  // ket qua
  const _onChangeKetqua = (e: any) => {
    e.preventDefault();
    setKetqua(e.target.value);
  };

  const _onPushKetqua = () => {
    const body = {
      idquytrinh: selectedQuyTrinh.Id,
      tenketqua: ketqua,
    };

    if (selectedKetQuaList === null) {
      setSelectedKetQuaList([body]);
    } else {
      setSelectedKetQuaList((oldValue: any) => [...oldValue, body]);
    }

    setKetqua("");
  };

  const _onDeleteKetqua = (ketQua: string) => {
    setSelectedKetQuaList(
      selectedKetQuaList.filter((item: any) => item.tenketqua !== ketQua)
    );
  };

  // files
  const _onChangeFileImage = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...filesList] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setFilesList(tempArray);
  };

  const _onDeleteElements = (index: number) => {
    const tempArray: any[] = [...filesList];
    tempArray.splice(index, 1);
    setFilesList(tempArray);
  };

  // other things
  const _onChangeTaskName = (e: any) => {
    e.preventDefault();
    setTaskName(e.target.value);
  };

  const _onChangeDeadLine = (e: any) => {
    e.preventDefault();
    setDeadLine(e.target.value);
  };

  const _onChangeSelectedLoaiCongViec = (e: any) => {
    e.preventDefault();
    setSelectedLoaiViec(
      loaiViecList?.find(
        (item: any) => item.ParameterId === parseInt(e.target.value)
      )
    );
  };

  const _onChangeNguonVanBan = (e: any) => {
    e.preventDefault();
    setSelectedNguonVanBan(
      nguonVanBanList?.find(
        (item: any) => item.ParameterId === parseInt(e.target.value)
      )
    );
  };

  const _onChangeDoKho = (e: any) => {
    e.preventDefault();
    setSelectedDoKho(
      doKhoList?.find((item: any) => item.Id === parseInt(e.target.value))
    );
  };

  const _onChangeSelectedUuTien = (e: any) => {
    e.preventDefault();
    setSelectedUuTien(
      uuTienList?.find((item: any) => item.Id === parseInt(e.target.value))
    );
  };

  const _onChangeDoMat = (e: any) => {
    e.preventDefault();
    setSelectedDoMat(
      doMatList?.find((item: any) => item.Id === e.target.value)
    );
  };

  const _onChangeDauViec = (e: any) => {
    e.preventDefault();
    setSelectedDauViec(
      dauViecList?.find((item: any) => item.Id === parseInt(e.target.value))
    );
  };

  const _onChangeNotes = (e: any) => {
    e.preventDefault();
    setNotes(e.target.value);
  };

  const _onChangeDescription = (e: any) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const _onChangeIsForce = () => {
    setIsForce((item: any) => !item);
  };

  const _onChangeIsChooseTaskReviewer = () => {
    setIsChooseTaskReviewer((item: any) => !item);
  };

  // -- Caching temporary data
  const _onClickCaching = () => {
    /*
      - selectedDauViec
      - taskName
      - selectedLoaiViec
      - selectedDoMat
      - deadLine
      - selectedNguonVanBan
      - selectedUuTien
      - selectedDoKho
      - selectedPhongBan
      - selectedNguoiNhan
      - isChooseTaskReviewer
      - selectedPhongBanDanhGia
      - selectedNguoiDanhGia
      - selectedQuyTrinh
      - isForce
      - selectedBuocList
      - selectedKetquaList
      - description
      - notes
      - selectedNguoiNhanList
      - selectedNguoiPhoiHopList
      - selectedNguoiLamViecList
      - dauViecList,
      - quyTrinhList
    */

    const body = {
      selectedDauViec,

      taskName,
      selectedLoaiViec,
      selectedDoMat,
      deadLine,
      selectedNguonVanBan,
      selectedUuTien,
      selectedDoKho,
      selectedPhongBan,
      selectedNguoiNhan,
      isChooseTaskReviewer,
      selectedPhongBanDanhGia,
      selectedNguoiDanhGia,
      selectedQuyTrinh,
      isForce,
      selectedBuocList,
      selectedKetQuaList,
      description,
      notes,
      selectedNguoiNhanList,
      selectedNguoiPhoiHopList,
      selectedNguoiLamViecList,

      dauViecList,
      quyTrinhList
    }

    localStorage.setItem("tempTaskCreate", JSON.stringify(body))
    handleClose()
  }
  // -- End caching

  // Take Caching
  useEffect(() => {
    const tempTaskCreate = localStorage.getItem("tempTaskCreate")

    if (tempTaskCreate === null || tempTaskCreate === undefined) {

    } else {
      /*
        - selectedDauViec
        - taskName
        - selectedLoaiViec
        - selectedDoMat
        - deadLine
        - selectedNguonVanBan
        - selectedUuTien
        - selectedDoKho
        - selectedPhongBan
        - selectedNguoiNhan
        - isChooseTaskReviewer
        - selectedPhongBanDanhGia
        - selectedNguoiDanhGia
        - selectedQuyTrinh
        - isForce
        - selectedBuocList
        - selectedKetquaList
        - description
        - notes
        - selectedNguoiNhanList
        - selectedNguoiPhoiHopList
        - selectedNguoiLamViecList
        - dauViecList,
        - quyTrinhList
      */

      const tempObject = JSON.parse(tempTaskCreate)

      setSelectedDauViec(tempObject?.selectedDauViec)

      setTaskName(tempObject?.taskName)
      setSelectedLoaiViec(tempObject?.selectedLoaiViec)
      setSelectedDoMat(tempObject?.selectedDoMat)
      setDeadLine(tempObject?.deadLine)
      setSelectedNguonVanBan(tempObject?.selectedNguonVanBan)
      setSelectedUuTien(tempObject?.selectedUuTien)
      setSelectedDoKho(tempObject?.selectedDoKho)
      setSelectedPhongBan(tempObject?.selectedPhongBan)
      setSelectedNguoiNhan(tempObject?.selectedNguoiNhan)
      setIsChooseTaskReviewer(tempObject?.isChooseTaskReviewer)
      setSelectedPhongBanDanhGia(tempObject?.selectedPhongBanDanhGia)
      setSelectedNguoiDanhGia(tempObject?.selectedNguoiDanhGia)
      setSelectedQuyTrinh(tempObject?.selectedQuyTrinh)


      setIsForce(tempObject?.isForce)
      setSelectedBuocList(tempObject?.selectedBuocList)
      setSelectedKetQuaList(tempObject?.selectedKetQuaList)
      setDescription(tempObject?.description)
      setNotes(tempObject?.notes)
      setSelectedNguoiNhanList(tempObject?.selectedNguoiNhanList)
      setSelectedNguoiPhoiHopList(tempObject?.selectedNguoiPhoiHopList)
      setSelectedNguoiLamViecList(tempObject?.selectedNguoiLamViecList)
      
      setQuyTrinhList(tempObject?.quyTrinhList)
      setDauViecList(tempObject?.dauViecList)
    }
  }, [])

  // submit
  // funcs
  const _onClickSubmit = () => {
    // CHÚ Ý:
    // - Tạo 2 mảng
    // - Lưu
    setIsLoading(true);
    let tempIsForce: string = isForce === true ? "1" : "0";

    let tempStringNguoiPhoiHop: string = "";

    selectedNguoiLamViecList.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiLamViecList.length - 1) {
        tempStringNguoiPhoiHop += `${item.Id.toString()}`;
      } else {
        tempStringNguoiPhoiHop += `${item.Id.toString()},`;
      }
    });

    const formData = new FormData();

    formData.append("TaskName", taskName ? taskName : "");
    formData.append("TaskListID", selectedDauViec ? selectedDauViec.Id : "");
    formData.append("PriorityLevelId", selectedUuTien ? selectedUuTien.Id : "");
    formData.append("SecurityLevelID", selectedDoMat ? selectedDoMat.Id : null);

    formData.append("DifficultLevelID", selectedDoKho ? selectedDoKho.Id : "");

    formData.append("RefCode", refCode);

    formData.append(
      "LoaiViecID",
      selectedLoaiViec ? selectedLoaiViec.ParameterId : ""
    );
    formData.append(
      "NguonViecID",
      selectedNguonVanBan ? selectedNguonVanBan.ParameterId : ""
    );
    formData.append("QuyTrinhID", selectedQuyTrinh ? selectedQuyTrinh.id : "");
    formData.append("Recipient", selectedNguoiNhan ? selectedNguoiNhan.Id : "");
    formData.append(
      "TaskReviewer",
      selectedNguoiDanhGia ? selectedNguoiDanhGia.Id : ""
    );

    formData.append("Supporter", tempStringNguoiPhoiHop);
    formData.append("Description", description ? description : "");
    formData.append("Notes", notes ? notes : "");
    formData.append("DeadLine", deadLine ? deadLine : "");

    let fileString: string = "";

    if (filesList) {
      for (let i = 0; i < filesList.length; i++) {
        formData.append(`Files_TaskFiles`, filesList[i]);

        if (i === filesList.length - 1) {
          fileString += `${filesList[i].name}`;
        } else {
          fileString += `${filesList[i].name};`;
        }
      }
    }

    formData.append("FilesName_TaskFiles", fileString);

    if (selectedBuocList !== null) {
      for (let index = 0; index < selectedBuocList.length; index++) {
        formData.append(
          `TaskContent[${index}].StepName`,
          selectedBuocList[index].StepName
        );
        formData.append(
          `TaskContent[${index}].UserDo`,
          selectedBuocList[index].UserDo
        );
        formData.append(
          `TaskContent[${index}].SortOrder`,
          selectedBuocList[index].SortOrder
        );
        formData.append(
          `TaskContent[${index}].DeadLine_Step`,
          selectedBuocList[index].DeadLine_Step
        );
      }
    }

    if (selectedKetQuaList !== null) {
      for (let index = 0; index < selectedKetQuaList.length; index++) {
        formData.append(
          `TaskResult[${index}].ResultText`,
          selectedKetQuaList[index].tenketqua
        );
      }
    }

    formData.append("IsForce", isForce.toString());

    const takeData = async () => {
      const accessToken = localStorage.getItem("accessToken");

      try {
        await axios
          .post(CREATE_TASK_LIST_NGUOI_GIAO, formData, {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              readList(
                NGUOI_GIAO_VIEC_LIST,
                data.pageCurrent,
                data.choosePage,
                data.searchFilter.taskName,
                data.searchFilter.selectedPrior,
                data.searchFilter.selectedReceiver,
                data.searchFilter.fromDate,
                data.searchFilter.toDate,
                data.searchFilter.deadLine,
                shouldLog3,
                urlLink,
                (res: any) => {
                  dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
                }
              );

              localStorage.removeItem("tempTaskCreate")

              setIsLoading(false);
              toast.success("Tạo công việc mới thành công.");
              handleClose();
            } else {
              setIsLoading(false);
            }
          });
      } catch (error: any) {
        setIsLoading(false);
        toast.error("Tạo công việc mới thất bại");
      }
    };

    takeData();
  };

  const _onClickSubmitNow = () => {
    // CHÚ Ý:
    // - Tạo 2 mảng
    // - Lưu
    setIsLoading(true);
    let tempIsForce: string = isForce === true ? "1" : "0";

    let tempStringNguoiPhoiHop: string = "";

    selectedNguoiLamViecList.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiLamViecList.length - 1) {
        tempStringNguoiPhoiHop += `${item.Id.toString()}`;
      } else {
        tempStringNguoiPhoiHop += `${item.Id.toString()},`;
      }
    });

    const formData = new FormData();

    formData.append("TaskName", taskName ? taskName : "");
    formData.append("TaskListID", selectedDauViec ? selectedDauViec.Id : "");
    formData.append("PriorityLevelId", selectedUuTien ? selectedUuTien.Id : "");
    formData.append("SecurityLevelID", selectedDoMat ? selectedDoMat.Id : null);

    formData.append("DifficultLevelID", selectedDoKho ? selectedDoKho.Id : "");

    formData.append("RefCode", refCode);

    formData.append(
      "LoaiViecID",
      selectedLoaiViec ? selectedLoaiViec.ParameterId : ""
    );
    formData.append(
      "TaskReviewer",
      selectedNguoiDanhGia ? selectedNguoiDanhGia.Id : ""
    );
    formData.append(
      "NguonViecID",
      selectedNguonVanBan ? selectedNguonVanBan.ParameterId : ""
    );
    formData.append("QuyTrinhID", selectedQuyTrinh ? selectedQuyTrinh.id : "");
    formData.append("Recipient", selectedNguoiNhan ? selectedNguoiNhan.Id : "");

    formData.append("Supporter", tempStringNguoiPhoiHop);
    formData.append("Description", description ? description : "");
    formData.append("Notes", notes ? notes : "");
    formData.append("DeadLine", deadLine ? deadLine : "");

    let fileString: string = "";

    if (filesList) {
      for (let i = 0; i < filesList.length; i++) {
        formData.append(`Files_TaskFiles`, filesList[i]);

        if (i === filesList.length - 1) {
          fileString += `${filesList[i].name}`;
        } else {
          fileString += `${filesList[i].name};`;
        }
      }
    }

    formData.append("FilesName_TaskFiles", fileString);

    if (selectedBuocList !== null) {
      for (let index = 0; index < selectedBuocList.length; index++) {
        formData.append(
          `TaskContent[${index}].StepName`,
          selectedBuocList[index].StepName
        );
        formData.append(
          `TaskContent[${index}].UserDo`,
          selectedBuocList[index].UserDo
        );
        formData.append(
          `TaskContent[${index}].SortOrder`,
          selectedBuocList[index].SortOrder
        );
        formData.append(
          `TaskContent[${index}].DeadLine_Step`,
          selectedBuocList[index].DeadLine_Step
        );
      }
    }

    if (selectedKetQuaList !== null) {
      for (let index = 0; index < selectedKetQuaList.length; index++) {
        formData.append(
          `TaskResult[${index}].ResultText`,
          selectedKetQuaList[index].tenketqua
        );
      }
    }

    formData.append("IsForce", isForce.toString());

    const takeData = async () => {
      const accessToken = localStorage.getItem("accessToken");

      try {
        setIsLoading(true);
        await axios
          .post(CREATE_TASK_LIST_NGUOI_GIAO, formData, {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res: any) => {
            if (res.data.StatusCode === 200) {

              localStorage.removeItem("tempTaskCreate")

              const approve = async () => {
                const accessToken = localStorage.getItem("accessToken");
                const API = APPROVE_TASK + res.data.Data.RId;

                try {
                  await axios
                    .get(API, {
                      headers: {
                        Authorization: "Bearer " + accessToken,
                        Accept: "text/plain",
                      },
                    })
                    .then((res: any) => {
                      if (res.data.StatusCode === 200) {
                        toast.success("Tạo công viêc và giao thành công.");
                        setIsLoading(false);
                        localStorage.removeItem("tempTaskCreate")
                        handleClose();
                      }
                    });
                } catch (error: any) {
                  if (handleClose) {
                    setIsLoading(false);
                    handleClose();
                  }
                  toast.error("Giao thất bại.");
                }
              };
              approve();
            } else {
              setIsLoading(false);
            }
          });
      } catch (error: any) {
        setIsLoading(false);
        toast.error("Tạo công việc mới thất bại");
      }
    };

    takeData();
  };

  // load selecting data
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog.current) {
      shouldLog.current = false;

      if (isAutoForce === null) {
        const accessToken = localStorage.getItem("accessToken");

        const takeData = async () => {
          try {
            await axios
              .get(CONFIG_IS_FORCE, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  if (res.data.Data[0].AllowTaskReject === false) {
                    setIsForce(true);
                  }
                  if (res.data.Data[0].AllowUseSecurity === false) {
                    setSelectedDoMat(null);
                  }

                  setIsAutoForce(res.data.Data[0].AllowTaskReject);
                  setIsAutoSecurity(res.data.Data[0].AllowUseSecurity);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (phongBanList === null || userList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(LOAD_PHONG_BAN_BY_USER, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setPhongBanList(res.data.Data.PhongBan);
                  setUserList(res.data.Data.NguoiNhan);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (phongBanDanhGiaList === null || userDanhGiaList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(LOAD_PHONG_BAN, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setPhongBanDanhGiaList(res.data.Data.PhongBan);
                  setUserDanhGiaList(res.data.Data.NguoiNhan);

                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (loaiViecList === null) {
        const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_LOAIVIEC";

        const takeData = async () => {
          try {
            await axios
              .get(API, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setLoaiViecList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (nguonVanBanList === null) {
        const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_NGUONVANBAN";

        const takeData = async () => {
          try {
            await axios
              .get(API, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setNguonVanBanList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (uuTienList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(PRIOR_LIST_DROPDOWN, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setUuTienList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (doMatList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(SECURITY_READ_ALL_DROPDOWN, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setDoMatList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (dauViecList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(READ_TASK_LIST_BY_USER, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setDauViecList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (quyTrinhList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(READ_ALL_LIST_QUYTRINH_DROPDOWN, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setQuyTrinhList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (doKhoList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(DIFICULT_LEVEL_DROPDOWN, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setDoKhoList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }
    }
  });

  const _onCheckValid = (): boolean => {
    if (
      selectedBuocList === null ||
      selectedKetQuaList === null ||
      selectedQuyTrinh === -1
    ) {
      return true;
    } else {
      for (let i = 0; i < selectedBuocList.length; i++) {
        if (
          selectedBuocList[i].UserDo === "" ||
          selectedBuocList[i].DeadLine_Step === ""
        ) {
          return false;
        }
      }
      return true;
    }
  };

  useEffect(() => {
    if (selectedBuocList === null || selectedBuocList === undefined) {
      setIsSelectedBuocOK(false);
    } else {
      for (let i = 0; i < selectedBuocList.length; i++) {
        if (
          selectedBuocList[i].UserDo === "" ||
          selectedBuocList[i].DeadLine_Step === ""
        ) {
          setIsSelectedBuocOK(false);
          break;
        } else {
          setIsSelectedBuocOK(true);
        }
      }
    }
  }, [selectedBuocList]);

  // Preprocessing
  const tempQuyTrinhList = quyTrinhList?.map((item) => {
    return { ...item, value: item.tenquytrinh, label: item.tenquytrinh };
  });
  const tempDauViecList = dauViecList?.map((item) => {
    return { ...item, value: item.Tendauviec, label: item.Tendauviec };
  });


  const _onChangeQuytrinhList = (data: any) => {
    if (data === null) {
      setSelectedQuyTrinh(-1);
      setSelectedBuocList(null);
      setSelectedKetQuaList(null);
    } else {
      const accessToken = localStorage.getItem("accessToken");

      setSelectedQuyTrinh(
        quyTrinhList?.find((item: any) => item.id === parseInt(data.id))
      );

      const takeStep = async () => {
        try {
          await axios
            .get(DETAIL_STEP_DROPDOWN + data.id, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              let tempArray: any[] = [];

              res.data.Data.forEach((item: any, index: any) => {
                const body = {
                  StepName: item.tenbuoc,
                  UserDo: "",
                  DeadLine_Step: "",
                  SortOrder: item.orderBy,
                };
                tempArray.push(body);
              });

              setSelectedBuocList(tempArray);
            });
        } catch (error: any) { }
      };

      const takeResult = async () => {
        try {
          await axios
            .get(DETAIL_RESULT_DROPDOWN + data.id, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              setSelectedKetQuaList(res.data.Data);
            });
        } catch (error: any) { }
      };

      takeStep();
      takeResult();
    }
  };

  const _onChangeDauViecList = (_data: any) => {
    if (_data === null) {
      setSelectedDauViec(-1);
    } else {
      setSelectedDauViec(
        dauViecList?.find((item: any) => item.Id === _data.Id)
      );
    }
  };

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[70rem] w-screen lg:h-[40rem] h-[calc(100vh-5rem)]
      rounded-md
    "
    >
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        _onCheckNguoiPhoiHop={_onCheckNguoiLamViec}
        selectedNguoiPhoiHopList={selectedNguoiPhoiHopList}
        selectedNguoiLamViecList={selectedNguoiLamViecList}
        selectedBuocList={selectedBuocList}
        positionStep={positionStep}
        hourStep={hourStep}
        contentStep={contentStep}
        _onChangePositionStep={_onChangePositionStep}
        _onChangeHourStep={_onChangeHourStep}
        _onChangeContentStep={_onChangeContentStep}
        _onPushStep={_onPushStep}
        _onDeleteQuyTrinh={_onDeleteQuyTrinh}
        _onChangeNguoiPhoiHopEachStep={_onChangeNguoiPhoiHopEachStep}
        selectedKetQuaList={selectedKetQuaList}
        ketqua={ketqua}
        _onPushKetqua={_onPushKetqua}
        _onChangeKetqua={_onChangeKetqua}
        _onDeleteKetqua={_onDeleteKetqua}
        _onChangeStepList={_onChangeStepList}
        funcs={funcsSub}
      />

      {/* header */}
      <div
        className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Tạo công việc mới</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={_onClickCaching}
        >
          X
        </button>
      </div>

      {/* content */}
      {phongBanList === null ||
        userList === null ||
        loaiViecList === null ||
        nguonVanBanList === null ||
        uuTienList === null ||
        doMatList === null ||
        dauViecList === null ||
        quyTrinhList === null ||
        doKhoList === null ||
        isAutoSecurity === null ||
        isAutoForce === null ? (
        <div className="flex text-primary justify-center items-center flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
          <LoaderSpinner w={32} h={32} />
          <p>Đang tải biểu mẫu</p>
        </div>
      ) : isLoading === true ? (
        <div className="flex text-primary justify-center items-center flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
          <LoaderSpinner w={32} h={32} />
          <p>Đang tạo công việc mới</p>
        </div>
      ) : (
        <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-2">
          <div className="w-full h-max flex justify-between items-center">
            <p className="font-semibold text-red-500">
              Lưu ý: Việc đã được giao thì không được phép chỉnh sửa thông tin
            </p>
            <button
              className="w-max h-max rounded-md pl-2 pr-2 pt-1.5 pb-1.5 bg-slate-300 font-bold hover:bg-slate-200"
              onClick={() => {
                localStorage.removeItem("tempTaskCreate")
                window.location.reload();
              }}
            >
              Tải lại
            </button>
          </div>

          <>
            {/* Web detail */}
            <div className="hidden lg:flex w-full h-max flex-col mt-4 text-sm space-y-2">
              <div className="w-full h-max">
                <label>
                  Đầu mục&nbsp;<span className="text-red-500">(*)</span>
                </label>

                <Select
                  className="z-[5]"
                  options={tempDauViecList}
                  onChange={(data: any) => _onChangeDauViecList(data)}
                  isClearable
                  placeholder="--- Chọn đầu mục ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={tempDauViecList?.find(item => item.Id === selectedDauViec?.Id)}
                />
              </div>
              <div className="w-full h-max">
                <label>
                  Tên công việc &nbsp;
                  <span className="text-red-500">(*)</span>{" "}
                </label>
                <textarea
                  className="w-full h-[2.5rem] rounded-md p-2 border-2 border-slate-200"
                  placeholder="Nhập tên công việc"
                  value={taskName}
                  onChange={(e: any) => _onChangeTaskName(e)}
                />
              </div>
              <div className="flex w-full space-x-4">
                <div className="w-full space-y-2">
                  <div className="flex w-full space-x-3">
                    <div className="w-full space-y-1">
                      <label>
                        Loại công việc&nbsp;
                        <span className="text-red-500">(*)</span>
                      </label>
                      <select
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={
                          selectedLoaiViec === -1
                            ? -1
                            : selectedLoaiViec.ParameterId
                        }
                        onChange={(e: any) => _onChangeSelectedLoaiCongViec(e)}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn loại công việc ---
                        </option>
                        {loaiViecList === null ? (
                          <></>
                        ) : (
                          loaiViecList.map((item: any, index: any) => (
                            <option key={index} value={item.ParameterId}>
                              {item.ParameterValue}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div className="w-full space-y-1">
                      <label>
                        Nguồn văn bản&nbsp;
                        <span className="text-red-500">(*)</span>
                      </label>
                      <select
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={
                          selectedNguonVanBan === -1
                            ? -1
                            : selectedNguonVanBan.ParameterId
                        }
                        onChange={(e: any) => _onChangeNguonVanBan(e)}
                      >
                        <option value={-1} disabled>
                          --- Chọn nguồn văn bản ---
                        </option>
                        {nguonVanBanList === null ? (
                          <></>
                        ) : (
                          nguonVanBanList.map((item: any, index: any) => (
                            <option key={index} value={item.ParameterId}>
                              {item.ParameterValue}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="flex w-full space-x-3">
                    <div
                      className={`w-full space-y-1 flex-col ${isAutoSecurity === false ? "hidden" : "flex"
                        }`}
                    >
                      <label>
                        Độ mật&nbsp;
                        <span className="text-red-500">(*)</span>
                      </label>
                      <select
                        className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedDoMat === -1 || selectedDoMat === null
                            ? ""
                            : "font-bold"
                          }
                  `}
                        value={
                          selectedDoMat === -1 || selectedDoMat === null
                            ? -1
                            : selectedDoMat.Id
                        }
                        onChange={(e: any) => _onChangeDoMat(e)}
                        style={{
                          color: `${selectedDoMat === -1 || selectedDoMat === null
                            ? ""
                            : selectedDoMat.Color
                            }`,
                          borderColor: `${selectedDoMat === -1 || selectedDoMat === null
                            ? ""
                            : selectedDoMat.Color
                            }`,
                        }}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn độ mật ---
                        </option>
                        {doMatList === null ? (
                          <></>
                        ) : (
                          doMatList.map((item: any, index: any) => (
                            <option
                              style={{ color: `${item.Color}` }}
                              key={index}
                              value={item.Id}
                            >
                              {item.SecurityLevelName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div className="w-full space-y-1">
                      <label>
                        Mức độ&nbsp;<span className="text-red-500">(*)</span>
                      </label>
                      <select
                        className={`
                      w-full h-[2rem] rounded-md pl-2 pr-2 border-2
                      ${selectedUuTien === -1 ? "" : "font-bold"}
                    `}
                        value={selectedUuTien === -1 ? -1 : selectedUuTien.Id}
                        onChange={(e: any) => _onChangeSelectedUuTien(e)}
                        style={{
                          color: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                            }`,
                          borderColor: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                            }`,
                        }}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn mức độ ---
                        </option>
                        {uuTienList === null ? (
                          <></>
                        ) : (
                          uuTienList.map((item: any, index: any) => (
                            <option
                              style={{ color: `${item.Color}` }}
                              key={index}
                              value={item.Id}
                            >
                              {item.PriorityLevelName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="flex w-full space-x-3">
                    <div className="w-full space-y-1">
                      <label>
                        Thời hạn hoàn thành&nbsp;
                        <span className="text-red-500">(*)</span>
                      </label>
                      <input
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập tên công việc"
                        type="datetime-local"
                        value={deadLine}
                        onChange={(e: any) => _onChangeDeadLine(e)}
                      />
                    </div>

                    <div className="w-full space-y-1">
                      <label>
                        Độ khó<template></template>&nbsp;
                        <span className="text-red-500">(*)</span>
                      </label>
                      <select
                        className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedDoKho === -1 ? "" : "font-bold"}
                  `}
                        value={selectedDoKho === -1 ? -1 : selectedDoKho.Id}
                        onChange={(e: any) => _onChangeDoKho(e)}
                        style={{
                          color: `${selectedDoKho === -1 ? "" : selectedDoKho.Color
                            }`,
                          borderColor: `${selectedDoKho === -1 ? "" : selectedDoKho.Color
                            }`,
                        }}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn độ khó ---
                        </option>
                        {doKhoList === null ? (
                          <></>
                        ) : (
                          doKhoList.map((item: any, index: any) => (
                            <option
                              style={{ color: `${item.Color}` }}
                              key={index}
                              value={item.Id}
                            >
                              {item.DifficultLevelName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>
                  <div className="flex w-full pr-3 h-max space-x-3">
                    <div className="w-full h-max">
                      <label>Mã tham chiếu công việc</label>
                      <input
                        className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        placeholder="Nhập mã"
                        value={refCode}
                        onChange={(e: any) => _onChangeRefCode(e)}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-full space-y-2">
                  <div className="flex w-full space-x-3">
                    <div className="w-full space-y-1">
                      <label>
                        Phòng ban&nbsp;
                        <span className="text-red-500">(*)</span>
                      </label>
                      <select
                        className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 ${selectedPhongBan === -1 ? "" : "font-bold"
                          }`}
                        value={
                          selectedPhongBan === -1
                            ? -1
                            : selectedPhongBan?.IdPhongBan
                        }
                        onChange={(e: any) => _onChangePhongBan(e)}
                        style={{
                          color: `${selectedPhongBan === -1
                            ? ""
                            : selectedPhongBan?.ColorText
                            }`,
                          borderColor: `${selectedPhongBan === -1
                            ? ""
                            : selectedPhongBan?.ColorText
                            }`,
                        }}
                      >
                        <option value={-1} disabled>
                          --- Chọn phòng ban ---
                        </option>
                        {phongBanList === null ? (
                          <></>
                        ) : (
                          phongBanList.map((item: any, index: any) => (
                            <option key={index} value={item.IdPhongBan}>
                              {item.TenPhongBan}
                            </option>
                          ))
                        )}
                      </select>
                    </div>

                    <div className="w-full space-y-1">
                      <label>
                        Người nhận&nbsp;
                        <span className="text-red-500">(*)</span>
                      </label>
                      <select
                        className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                      ${selectedNguoiNhan === -1 ? "" : "font-bold"}
                    `}
                        style={{
                          color: `${selectedNguoiNhan === -1
                            ? ""
                            : selectedNguoiNhan.ColorText
                            }`,
                          borderColor: `${selectedNguoiNhan === -1
                            ? ""
                            : selectedNguoiNhan.ColorText
                            }`,
                        }}
                        value={
                          selectedNguoiNhan === -1 ? -1 : selectedNguoiNhan.Id
                        }
                        onChange={(e: any) => _onChangeNguoiNhan(e)}
                        disabled={selectedPhongBan === -1 ? true : false}
                      >
                        <option className="text-gray-300" value={-1} disabled>
                          --- Chọn người nhận ---
                        </option>
                        {selectedNguoiNhanList === null ? (
                          <></>
                        ) : (
                          selectedNguoiNhanList?.map((item: any, index: any) => (
                            <option
                              key={index}
                              value={item.Id}
                              style={{
                                color: `${item.ColorText}`,
                              }}
                            >
                              {item.FullName}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                  </div>

                  <div className="flex w-full">
                    <input
                      className="mr-2"
                      type="checkbox"
                      checked={isChooseTaskReviewer}
                      onChange={_onChangeIsChooseTaskReviewer}
                    />
                    <p>Chọn người đánh giá</p>

                  </div>
                  <div className="flex w-full">
                    <p className="font-semibold text-red-500">(Nếu không chọn thì mặc định người giao việc là người đánh giá)</p>
                  </div>

                  {isChooseTaskReviewer && (
                    <div className="flex w-full space-x-3">
                      <div className="w-full space-y-1">
                        <label>
                          Phòng ban người đánh giá&nbsp;
                          <span className="text-red-500">(*)</span>
                        </label>
                        <select
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2"
                          value={selectedPhongBanDanhGia.IdPhongBan}
                          onChange={(e: any) => _onChangePhongBanDanhGia(e)}
                        >
                          {/* <option value={userData.Data[0].IdPhongBan} disabled>
                      {userData.Data[0].TenPhongBan}
                    </option> */}
                          {phongBanDanhGiaList === null ? (
                            <></>
                          ) : (
                            phongBanDanhGiaList.map((item: any, index: any) => (
                              <option key={index} value={item.IdPhongBan}>
                                {item.TenPhongBan}
                              </option>
                            ))
                          )}
                        </select>
                      </div>

                      <div className="w-full space-y-1">
                        <label>
                          Người đánh giá&nbsp;
                          <span className="text-red-500">(*)</span>
                        </label>
                        <select
                          className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                          value={selectedNguoiDanhGia.Id}
                          onChange={(e: any) => _onChangeNguoiDanhGia(e)}
                          disabled={
                            selectedPhongBanDanhGia === -1 ? true : false
                          }
                        >
                          <option value={selectedNguoiDanhGia.Id} disabled>
                            {selectedNguoiDanhGia.FullName}
                          </option>
                          {selectedNguoiDanhGiaList === null ? (
                            <></>
                          ) : (
                            selectedNguoiDanhGiaList.map(
                              (item: any, index: any) => (
                                <option key={index} value={item.Id}>
                                  {item.FullName}
                                </option>
                              )
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  )}

                  <div className="w-full h-max space-y-1">
                    <div className="flex w-max h-max space-x-3">
                      <label>Người phối hợp (nếu có)</label>
                      <button
                        className={`
                    text-white pl-2 pr-2 pt-0.5 pb-0.5 rounded-md
                    text-xs
                    ${selectedNguoiNhan === -1
                            ? "bg-slate-300"
                            : "bg-primary hover:bg-sky-400"
                          }
                  `}
                        disabled={selectedNguoiNhan === -1 ? true : false}
                        onClick={() =>
                          _onChangeNguoiPhoiHop("multipleChoicePartner")
                        }
                      >
                        Chọn
                      </button>
                    </div>
                    <div className="w-full h-max">
                      {selectedNguoiLamViecList === null
                        ? ""
                        : selectedNguoiLamViecList.map(
                          (item: any, index: any) => (
                            <>
                              {item.Id === selectedNguoiNhan.Id ? (
                                <></>
                              ) : (
                                <div
                                  key={index}
                                  className="flex w-max h-max text-primary items-center justify-between"
                                >
                                  <div
                                    className="flex w-max h-max space-x-1 font-bold mr-2"
                                    style={{
                                      color: `${item.ColorText}`,
                                    }}
                                  >
                                    <PersonOutlineTwoToneIcon fontSize="small" />
                                    <p>{item.FullName}</p>
                                  </div>
                                  <button
                                    className="text-red-500 justify-end"
                                    onClick={() =>
                                      _onCheckNguoiLamViec(item.Id)
                                    }
                                  >
                                    <DeleteTwoToneIcon fontSize="small" />
                                  </button>
                                </div>
                              )}
                            </>
                          )
                        )}
                    </div>
                    <div className="flex w-full h-max items-center space-x-6">
                      {/* overload */}
                      <div className="flex items-center space-x-1">
                        <CircleIcon className="text-[#00ff00]" />
                        <p>Có thể nhận việc</p>
                      </div>
                      <div className="flex items-center space-x-1">
                        <CircleIcon className="text-[#FFAA00]" />
                        <p>Đang nhiều việc</p>
                      </div>
                      <div className="flex items-center space-x-1">
                        <CircleIcon className="text-[#ff0000]" />
                        <p>Quá tải</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full">
                <div className="flex w-full h-max">
                  {/* elemetns */}
                  <div className="flex flex-col w-1/2 space-x-3 space-y-4">
                    <div
                      className={`
                flex-col w-full h-max space-y-1
              `}
                    >
                      <div
                        className={`${isAutoForce === false ? "hidden" : "flex"
                          } w-max h-max space-x-3`}
                      >
                        <input
                          type="checkbox"
                          checked={isForce}
                          onChange={_onChangeIsForce}
                        />
                        <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200">
                          KHÔNG CHO PHÉP TRẢ VIỆC
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-full h-max space-y-2">
                <div className="w-full h-max justify-start items-center flex space-x-3">
                  <label>Quy trình&nbsp;(nếu có)</label>
                  <div
                    className={`
                    ${menu?.PerInsert === true ? "flex" : "hidden"}
                    w-max h-max
                  `}
                  >
                    {/* <button className="text-xs pl-1.5 pr-1.5 pt-0.5 pb-0.5 bg-primary
                      text-white rounded-md hover:bg-sky-400
                    "
                      onClick={() => {
                        setFuncsSub("createWorkflowSub")
                        handleOpenSub()
                      }}
                    > 
                      <p>Tạo quy trình</p>
                    </button> */}
                  </div>
                </div>
                {/* <select
                  className={`
                  w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                  `}
                  value={selectedQuyTrinh === -1 ? -1 : selectedQuyTrinh.id}
                  disabled={selectedNguoiNhan === -1 ? true : false}
                  onChange={(e: any) => _onChangeQuyTrinh(e)}
                >
                  <option className="text-gray-300" value={-1}>
                    --- Chọn quy trình ---
                  </option>
                  {quyTrinhList === null ? (
                    <></>
                  ) : (
                    quyTrinhList.map((item: any, index: any) => (
                      <option key={index} value={item.id}>
                        {item.tenquytrinh}
                      </option>
                    ))
                  )}
                </select> */}
                <Select
                  className="z-[5]"
                  options={tempQuyTrinhList}
                  onChange={(data: any) => _onChangeQuytrinhList(data)}
                  isClearable
                  placeholder="--- Chọn quy trình ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={tempQuyTrinhList?.find(item => item.id === selectedQuyTrinh.id)}
                />
              </div>
              <div className="flex w-full h-max">
                {/* buoc */}
                <div
                  className={`
              w-2/3 pr-3 tableFixHead
              ${selectedBuocList === null || selectedBuocList === undefined
                      ? "flex"
                      : "flex"
                    }
            `}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300">Bước</th>
                        <th className="border border-slate-300">Nội dung</th>
                        <th className="border border-slate-300">
                          Người thực hiện
                        </th>
                        <th className="border border-slate-300">Thời hạn</th>
                        <th className="border border-slate-300 w-[1rem]">
                          <button
                            className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                            onClick={() => _onClickEditBuoc("editBuoc")}
                          >
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedBuocList === null ||
                        selectedBuocList === undefined
                        ? ""
                        : selectedBuocList
                          .sort((a: any, b: any) => {
                            return a.SortOrder - b.SortOrder;
                          })
                          .map((item: any, index: any) => (
                            <tr
                              key={index}
                              className="w-max h-[3rem] bg-white"
                            >
                              <td className="text-center border border-slate-300">
                                <p>{item.SortOrder}</p>
                              </td>
                              <td className="border border-slate-300">
                                <p>{item.StepName}</p>
                              </td>
                              <td className="text-center border border-slate-300">
                                <select
                                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                  value={item.UserDo}
                                  onChange={(e: any) => {
                                    e.preventDefault();
                                    _onChangeNguoiPhoiHopEachStep(
                                      item.SortOrder,
                                      e.target.value
                                    );
                                  }}
                                >
                                  <option
                                    className="text-gray-300"
                                    value={""}
                                    disabled
                                  >
                                    --- Chọn người phối hợp ---
                                  </option>
                                  {selectedNguoiLamViecList === null
                                    ? ""
                                    : selectedNguoiLamViecList.map(
                                      (item: any, index: any) => (
                                        <option key={index} value={item.Id}>
                                          {item.FullName}
                                        </option>
                                      )
                                    )}
                                </select>
                              </td>
                              <td className="text-center border border-slate-300">
                                <input
                                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                  type="datetime-local"
                                  value={item.DeadLine_Step}
                                  onChange={(e: any) => {
                                    e.preventDefault();
                                    _onChangDeadLineStep(
                                      item.SortOrder,
                                      e.target.value
                                    );
                                  }}
                                />
                              </td>
                              <td className="border border-slate-300">
                                <button
                                  className="text-red-500 w-1/6"
                                  onClick={() =>
                                    _onDeleteQuyTrinh(item.SortOrder)
                                  }
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </button>
                              </td>
                            </tr>
                          ))}
                    </tbody>
                  </table>
                </div>

                {/* ket qua */}
                <div
                  className={`
              w-1/3 h-max pl-3
              ${selectedKetQuaList === null ? "flex" : "flex"}
            `}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 ">STT</th>
                        <th className="border border-slate-300 ">
                          Kết quả cần đạt
                        </th>
                        <th className="border text-center border-slate-300 w-[1rem]">
                          <button
                            className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                            onClick={() => _onClickEditKetqua("editKetqua")}
                          >
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedKetQuaList === null ? (
                        <tr></tr>
                      ) : (
                        selectedKetQuaList?.map((item: any, index: any) => (
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {index + 1}
                            </td>
                            <td className="text-left border border-slate-300">
                              {item.tenketqua}
                            </td>
                            <td className="border text-center border-slate-300 w-[1rem]">
                              <button
                                className="text-red-500"
                                onClick={() => _onDeleteKetqua(item.tenketqua)}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Phone detail */}
            <div className="lg:hidden space-y-1.5">
              <div className="w-full h-max space-y-0.5">
                <label>
                  Đầu mục&nbsp;<span className="text-red-500">(*)</span>
                </label>
                <Select
                  className="z-[5]"
                  options={tempDauViecList}
                  onChange={(data: any) => _onChangeDauViecList(data)}
                  isClearable
                  placeholder="Nhập đầu mục"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
              <div className="w-full h-max space-y-0.5">
                <label>
                  Tên công việc &nbsp;
                  <span className="text-red-500">(*)</span>{" "}
                </label>
                <input
                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập tên công việc"
                  value={taskName}
                  onChange={(e: any) => _onChangeTaskName(e)}
                />
              </div>
              <div className="w-full h-max space-y-0.5">
                <label>
                  Loại công việc&nbsp;
                  <span className="text-red-500">(*)</span>
                </label>
                <select
                  className="w-full h-[2rem] rounded-md px-1 border-2 border-slate-200"
                  value={
                    selectedLoaiViec === -1 ? -1 : selectedLoaiViec.ParameterId
                  }
                  onChange={(e: any) => _onChangeSelectedLoaiCongViec(e)}
                >
                  <option className="text-gray-300" value={-1} disabled>
                    Chọn loại công việc
                  </option>
                  {loaiViecList === null ? (
                    <></>
                  ) : (
                    loaiViecList.map((item: any, index: any) => (
                      <option key={index} value={item.ParameterId}>
                        {item.ParameterValue}
                      </option>
                    ))
                  )}
                </select>
              </div>

              <div className="flex w-full h-max space-x-1">
                <div className="w-full h-max space-y-0.5">
                  <label>
                    Mức độ&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`
                      w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500
                      ${selectedUuTien === -1 ? "" : "font-bold"}
                    `}
                    value={selectedUuTien === -1 ? -1 : selectedUuTien.Id}
                    onChange={(e: any) => _onChangeSelectedUuTien(e)}
                    style={{
                      color: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                        }`,
                      borderColor: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                        }`,
                    }}
                  >
                    <option value={-1} disabled>
                      Chọn mức độ
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList.map((item: any, index: any) => (
                        <option
                          key={index}
                          value={item.Id}
                          style={{ color: `${item.Color}` }}
                        >
                          {item.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-full h-max space-y-0.5">
                  <label>
                    Nguồn văn bản&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className="w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500"
                    value={
                      selectedNguonVanBan === -1
                        ? -1
                        : selectedNguonVanBan.ParameterId
                    }
                    onChange={(e: any) => _onChangeNguonVanBan(e)}
                  >
                    <option value={-1} disabled>
                      Chọn nguồn văn bản
                    </option>
                    {nguonVanBanList === null ? (
                      <></>
                    ) : (
                      nguonVanBanList.map((item: any, index: any) => (
                        <option key={index} value={item.ParameterId}>
                          {item.ParameterValue}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="flex w-full h-max space-x-1">
                <div
                  className={`w-full flex-col h-max space-y-0.5 ${isAutoSecurity === false ? "hidden" : "flex"
                    }`}
                >
                  <label>
                    Độ mật<template></template>&nbsp;
                    <span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`
                    w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500
                    ${selectedDoMat === -1 || selectedDoMat === null
                        ? ""
                        : "font-bold"
                      }
                  `}
                    value={
                      selectedDoMat === -1 || selectedDoMat === null
                        ? -1
                        : selectedDoMat.Id
                    }
                    onChange={(e: any) => _onChangeDoMat(e)}
                    style={{
                      color: `${selectedDoMat === -1 || selectedDoMat === null
                        ? ""
                        : selectedDoMat.Color
                        }`,
                      borderColor: `${selectedDoMat === -1 || selectedDoMat === null
                        ? ""
                        : selectedDoMat.Color
                        }`,
                    }}
                  >
                    <option value={-1} disabled>
                      Chọn độ mật
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item.Color}` }}
                          key={index}
                          value={item.Id}
                        >
                          {item.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-full h-max space-y-0.5">
                  <label>
                    Độ khó&nbsp;
                    <span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`
                    w-full h-[2rem] rounded-md px-1 border-2 border-slate-200 text-gray-500
                    ${selectedDoKho === -1 ? "" : "font-bold"}
                  `}
                    value={
                      selectedDoKho === -1 ||
                        selectedDoKho === null ||
                        selectedDoKho === 0 ||
                        selectedDoKho === undefined
                        ? -1
                        : selectedDoKho.Id
                    }
                    onChange={(e: any) => _onChangeDoKho(e)}
                    style={{
                      color: `${selectedDoKho === -1 || selectedDoKho === undefined
                        ? ""
                        : selectedDoKho.Color
                        }`,
                      borderColor: `${selectedDoKho === -1 || selectedDoKho === undefined
                        ? ""
                        : selectedDoKho.Color
                        }`,
                    }}
                  >
                    <option value={-1} disabled>
                      Chọn độ khó
                    </option>
                    {doKhoList === null ? (
                      <></>
                    ) : (
                      doKhoList.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item.Color}` }}
                          key={index}
                          value={item.Id}
                        >
                          {item.DifficultLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="flex w-full h-max">
                <div className="flex w-full h-max space-x-3">
                  {/* elements */}
                  <div className="w-full h-max">
                    <label>
                      Thời hạn hoàn thành&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      type="datetime-local"
                      value={deadLine}
                      onChange={(e: any) => _onChangeDeadLine(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full h-max space-y-0.5">
                <label>
                  Phòng ban&nbsp;<span className="text-red-500">(*)</span>
                </label>
                <select
                  className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 text-gray-500 ${selectedPhongBan === -1 ? "" : "font-bold"
                    }`}
                  value={
                    selectedPhongBan === -1 ? -1 : selectedPhongBan?.IdPhongBan
                  }
                  onChange={(e: any) => _onChangePhongBan(e)}
                  style={{
                    color: `${selectedPhongBan === -1 ? "" : selectedPhongBan?.ColorText
                      }`,
                    borderColor: `${selectedPhongBan === -1 ? "" : selectedPhongBan?.ColorText
                      }`,
                  }}
                >
                  <option value={-1} disabled>
                    --- Chọn phòng ban ---
                  </option>
                  {phongBanList === null ? (
                    <></>
                  ) : (
                    phongBanList.map((item: any, index: any) => (
                      <option key={index} value={item.IdPhongBan}>
                        {item.TenPhongBan}
                      </option>
                    ))
                  )}
                </select>
              </div>

              <div className="w-full h-max space-y-0.5">
                <label>
                  Người nhận&nbsp;<span className="text-red-500">(*)</span>
                </label>

                <select
                  className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 text-gray-500
                    ${selectedNguoiNhan === -1 ? "" : "font-bold"}
                  `}
                  style={{
                    color: `${selectedNguoiNhan === -1
                      ? ""
                      : selectedNguoiNhan.ColorText
                      }`,
                    borderColor: `${selectedNguoiNhan === -1
                      ? ""
                      : selectedNguoiNhan.ColorText
                      }`,
                  }}
                  value={selectedNguoiNhan === -1 ? -1 : selectedNguoiNhan.Id}
                  onChange={(e: any) => _onChangeNguoiNhan(e)}
                  disabled={selectedPhongBan === -1 ? true : false}
                >
                  <option value={-1} disabled>
                    --- Chọn người nhận ---
                  </option>
                  {selectedNguoiNhanList === null ? (
                    <></>
                  ) : (
                    selectedNguoiNhanList?.map((item: any, index: any) => (
                      <option
                        style={{ color: `${item.ColorText}` }}
                        key={index}
                        value={item.Id}
                      >
                        {item.FullName}
                      </option>
                    ))
                  )}
                </select>
              </div>

              <div className="flex w-full">
                <input
                  className="mr-2"
                  type="checkbox"
                  checked={isChooseTaskReviewer}
                  onChange={_onChangeIsChooseTaskReviewer}
                />
                <p>Chọn người đánh giá</p>
              </div>
              <div className="flex w-full">
                <p className="font-semibold text-red-500">(Nếu không chọn thì mặc định người giao việc là người đánh giá)</p>
              </div>
              {isChooseTaskReviewer && (
                <>
                  <div className="w-full space-y-0.5">
                    <label>
                      Phòng ban người đánh giá&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <select
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2"
                      value={selectedPhongBanDanhGia.IdPhongBan}
                      onChange={(e: any) => _onChangePhongBanDanhGia(e)}
                    >
                      {phongBanDanhGiaList === null ? (
                        <></>
                      ) : (
                        phongBanDanhGiaList.map((item: any, index: any) => (
                          <option key={index} value={item.IdPhongBan}>
                            {item.TenPhongBan}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                  <div className="w-full space-y-0.5">
                    <label>
                      Người đánh giá&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <select
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      value={selectedNguoiDanhGia.Id}
                      onChange={(e: any) => _onChangeNguoiDanhGia(e)}
                      disabled={selectedPhongBanDanhGia === -1 ? true : false}
                    >
                      <option value={selectedNguoiDanhGia.Id} disabled>
                        {selectedNguoiDanhGia.FullName}
                      </option>
                      {selectedNguoiDanhGiaList === null ? (
                        <></>
                      ) : (
                        selectedNguoiDanhGiaList.map(
                          (item: any, index: any) => (
                            <option key={index} value={item.Id}>
                              {item.FullName}
                            </option>
                          )
                        )
                      )}
                    </select>
                  </div>
                </>
              )}

              <div className="w-1/2 space-y-4">
                <div className="w-full space-y-1">
                  <div className="flex w-max h-max space-x-3">
                    <label>Người phối hợp (nếu có)</label>
                    <button
                      className={`
                    text-white pl-2 pr-2 pt-0.5 pb-0.5 rounded-md
                    text-xs
                    ${selectedNguoiNhan === -1
                          ? "bg-slate-300"
                          : "bg-primary hover:bg-sky-400"
                        }
                  `}
                      disabled={selectedNguoiNhan === -1 ? true : false}
                      onClick={() =>
                        _onChangeNguoiPhoiHop("multipleChoicePartner")
                      }
                    >
                      Chọn
                    </button>
                  </div>
                  <div className="flex flex-col h-max">
                    {selectedNguoiLamViecList === null
                      ? ""
                      : selectedNguoiLamViecList.map(
                        (item: any, index: any) => (
                          <>
                            {item.Id === selectedNguoiNhan.Id ? (
                              <></>
                            ) : (
                              <div
                                key={index}
                                className="flex w-max h-max text-primary items-center justify-between"
                              >
                                <div
                                  className="flex w-max h-max space-x-1 font-bold"
                                  style={{
                                    color: `${item.ColorText}`,
                                  }}
                                >
                                  <PersonOutlineTwoToneIcon fontSize="small" />
                                  <p>{item.FullName}</p>
                                </div>
                                <button
                                  className="text-red-500 flex justify-end"
                                  onClick={() =>
                                    _onCheckNguoiLamViec(item.Id)
                                  }
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </button>
                              </div>
                            )}
                          </>
                        )
                      )}
                  </div>
                </div>
                <div
                  className={`
                flex-col w-full h-max space-y-1
              `}
                >
                  <div
                    className={`${isAutoForce === false ? "hidden" : "flex"
                      } w-max h-max space-x-3`}
                  >
                    <input
                      type="checkbox"
                      checked={isForce}
                      onChange={_onChangeIsForce}
                    />
                    <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200">
                      KHÔNG CHO PHÉP TRẢ VIỆC
                    </label>
                  </div>
                </div>
              </div>

              <div className="w-full">
                {/* overload */}
                <div className="flex items-center space-x-1">
                  <CircleIcon className="text-[#00ff00]" />
                  <p>Có thể nhận việc</p>
                </div>
                <div className="flex items-center space-x-1">
                  <CircleIcon className="text-[#FFAA00]" />
                  <p>Đang nhiều việc</p>
                </div>
                <div className="flex items-center space-x-1">
                  <CircleIcon className="text-[#ff0000]" />
                  <p>Quá tải</p>
                </div>
              </div>

              <div className="w-full h-max space-y-0.5">
                <label>Mã tham chiếu công việc</label>
                <input
                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập mã"
                  value={refCode}
                  onChange={(e: any) => _onChangeRefCode(e)}
                />
              </div>

              <div className="flex flex-col w-full h-max space-y-0.5">
                <div className="w-full h-max justify-start items-center flex space-x-3">
                  <label>Quy trình&nbsp;(nếu có)</label>
                  <div
                    className={`
                    ${menu?.PerInsert === true ? "flex" : "hidden"}
                    w-max h-max
                  `}
                  ></div>
                </div>
                <Select
                  className="z-[5]"
                  options={tempQuyTrinhList}
                  onChange={(data: any) => _onChangeQuytrinhList(data)}
                  isClearable
                  placeholder="--- Nhập quy trình ---"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  defaultValue={tempQuyTrinhList?.find(item => item.id === selectedQuyTrinh.id)}
                />
              </div>

              <div className="flex w-full h-max my-1.5">
                {/* buoc */}
                <div
                  className={`w-full h-max tableFixHead ${selectedBuocList === null || selectedBuocList === undefined
                    ? "flex"
                    : "flex"
                    }`}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300">Nội dung</th>
                        <th className="border border-slate-300 w-[1rem]">
                          <button
                            className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                            onClick={() => _onClickEditBuoc("editBuoc")}
                          >
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedBuocList === null ||
                        selectedBuocList === undefined
                        ? ""
                        : selectedBuocList.map((item: any, index: any) => (
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="flex flex-col border border-slate-300 space-y-2">
                              <p className="font-bold">
                                Bước&nbsp;{item.SortOrder}:
                              </p>
                              <p>{item.StepName}</p>
                              <select
                                className="lg:w-full sm:w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                value={item.UserDo}
                                onChange={(e: any) => {
                                  e.preventDefault();
                                  _onChangeNguoiPhoiHopEachStep(
                                    item.SortOrder,
                                    e.target.value
                                  );
                                }}
                              >
                                <option
                                  className="text-gray-300"
                                  value={""}
                                  disabled
                                >
                                  --- Chọn người thực hiện ---
                                </option>
                                {selectedNguoiLamViecList === null
                                  ? ""
                                  : selectedNguoiLamViecList.map(
                                    (item: any, index: any) => (
                                      <option key={index} value={item.Id}>
                                        {item.FullName}
                                      </option>
                                    )
                                  )}
                              </select>
                              <input
                                className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                type="datetime-local"
                                value={item.DeadLine_Step}
                                onChange={(e: any) => {
                                  e.preventDefault();
                                  _onChangDeadLineStep(
                                    item.SortOrder,
                                    e.target.value
                                  );
                                }}
                              />
                            </td>
                            <td className="border border-slate-300">
                              <button className="text-red-500 w-1/6">
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex w-full h-max my-1.5">
                {/* ket qua */}
                <div
                  className={` w-full h-max ${selectedKetQuaList === null ? "flex" : "flex"
                    }`}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300">STT</th>
                        <th className="border border-slate-300 w-full">
                          Kết quả cần đạt
                        </th>
                        <th className="border text-center border-slate-300 w-[1rem]">
                          <button
                            className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                            onClick={() => _onClickEditKetqua("editKetqua")}
                          >
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedKetQuaList === null ? (
                        <tr></tr>
                      ) : (
                        selectedKetQuaList?.map((item: any, index: any) => (
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {index + 1}
                            </td>
                            <td className="text-left border border-slate-300">
                              {item.tenketqua}
                            </td>
                            <td className="border text-center border-slate-300 w-[1rem]">
                              <button className="text-red-500">
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>

          <div className="w-full h-max space-y-4">
            {/* elements */}
            <div className="flex flex-col w-full h-max">
              <label>Mô tả</label>
              <textarea
                className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
                placeholder="Nhập mô tả"
                value={description}
                onChange={(e: any) => _onChangeDescription(e)}
              />
            </div>
            <div className="flex flex-col w-full h-max">
              <label>Ghi chú</label>
              <textarea
                className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
                placeholder="Nhập ghi chú"
                value={notes}
                onChange={(e: any) => _onChangeNotes(e)}
              />
            </div>
            <div className="flex w-full h-max">
              {/* elements */}
              <div className="flex flex-col w-1/2 h-max">
                <div className="flex w-max h-full space-x-2 text-xs">
                  <button
                    className="flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white hover:bg-sky-400 "
                    onClick={_onClickChooseFileChat}
                  >
                    <AttachFileIcon fontSize="small" />
                    <p>Chọn file</p>
                  </button>
                </div>
                <input
                  ref={uploadFileChat}
                  className={`w-max h-max`}
                  multiple
                  type="file"
                  onChange={(e: any) => _onChangeFileImage(e)}
                  hidden
                />
                {/* fields of file */}
                <div className="flex flex-col w-full h-max">
                  {/* element */}
                  <div className="flex w-full h-max text-primary items-center justify-between">
                    <div className="flex flex-col w-full h-max">
                      {filesList.length === 0 ||
                        typeof filesList === "undefined" ? (
                        <></>
                      ) : (
                        filesList.map((item: any, index: any) => (
                          <div key={index} className="flex w-full h-max">
                            <div className="flex w-5/6 h-max space-x-1">
                              <InsertDriveFileTwoToneIcon fontSize="small" />
                              <p>{item.name}</p>
                              <button
                                className="text-red-500 w-1/6 flex"
                                onClick={() => _onDeleteElements(index)}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </div>
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* footer */}
      <div
        className={`
        flex w-full h-[3rem] bg-slate-100 rounded-b-md pl-4 pr-4 text-sm space-x-4
        ${selectedPhongBan === -1 ||
            selectedNguoiNhan === -1 ||
            selectedLoaiViec === -1 ||
            selectedNguonVanBan === -1 ||
            selectedUuTien === -1 ||
            selectedDoMat === -1 ||
            selectedDauViec === -1 ||
            // selectedQuyTrinh === -1 ||
            selectedDoKho === -1 ||
            deadLine === "" ||
            isLoading === true
            ? "justify-between"
            : "justify-end"
          }
        items-center
      `}
      >
        <div
          className={`
          items-center space-x-1 w-full h-max text-white rounded-md
          ${selectedPhongBan === -1 ||
              selectedNguoiNhan === -1 ||
              selectedLoaiViec === -1 ||
              selectedNguonVanBan === -1 ||
              selectedUuTien === -1 ||
              selectedDoMat === -1 ||
              selectedDauViec === -1 ||
              // selectedQuyTrinh === -1 ||
              selectedDoKho === -1 ||
              deadLine === "" ||
              isLoading === true
              ? "flex"
              : "hidden"
            }
        `}
        >
          {/* <p className="text-red-500 font-semibold text-xs">
            Vui lòng nhập đầy đủ thông tin công việc
          </p> */}
        </div>

        <div className="flex w-max h-max space-x-3">
          <div className="w-max h-max multiple">
            {/* button options */}
            <button
              className={`flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-primary text-white rounded-md
              ${selectedPhongBan === -1 ||
                  selectedNguoiNhan === -1 ||
                  selectedLoaiViec === -1 ||
                  selectedNguonVanBan === -1 ||
                  selectedUuTien === -1 ||
                  selectedDoMat === -1 ||
                  selectedDauViec === -1 ||
                  selectedDoKho === -1 ||
                  taskName === "" ||
                  deadLine === "" ||
                  _onCheckValid() === false ||
                  isLoading === true
                  ? // || selectedQuyTrinh === -1 ||
                  // isSelectedBuocOK === false
                  "bg-slate-300"
                  : "bg-primary hover:bg-sky-400"
                }
            `}
              onClick={_onClickSubmit}
              disabled={
                selectedPhongBan === -1 ||
                  selectedNguoiNhan === -1 ||
                  selectedLoaiViec === -1 ||
                  selectedNguonVanBan === -1 ||
                  selectedUuTien === -1 ||
                  selectedDoMat === -1 ||
                  selectedDauViec === -1 ||
                  selectedDoKho === -1 ||
                  deadLine === "" ||
                  taskName === "" ||
                  _onCheckValid() === false ||
                  isLoading === true
                  ? // || selectedQuyTrinh === -1 ||
                  // isSelectedBuocOK === false
                  true
                  : false
              }
            >
              <AssignmentIcon fontSize="small" />
              <p>Xác nhận</p>
            </button>

            {/* delivery at the moment */}

            {/* options fields */}
            <div
              className={`
              text-xs font-semibold
              ${selectedPhongBan === -1 ||
                  selectedNguoiNhan === -1 ||
                  selectedLoaiViec === -1 ||
                  selectedNguonVanBan === -1 ||
                  selectedUuTien === -1 ||
                  selectedDoMat === -1 ||
                  selectedDauViec === -1 ||
                  selectedDoKho === -1 ||
                  deadLine === "" ||
                  taskName === "" ||
                  _onCheckValid() === false ||
                  isLoading === true
                  ? // || selectedQuyTrinh === -1 ||
                  // isSelectedBuocOK === false
                  "hidden"
                  : "multiple-content"
                }
            `}
            >
              {/* delete */}

              {/* detail */}
            </div>
          </div>

          <button
            className={`items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-emerald-500 text-white rounded-md
              ${selectedPhongBan === -1 ||
                selectedNguoiNhan === -1 ||
                selectedLoaiViec === -1 ||
                selectedNguonVanBan === -1 ||
                selectedUuTien === -1 ||
                selectedDoMat === -1 ||
                selectedDauViec === -1 ||
                selectedDoKho === -1 ||
                taskName === "" ||
                deadLine === "" ||
                _onCheckValid() === false ||
                isLoading === true
                ? // || selectedQuyTrinh === -1 ||
                // isSelectedBuocOK === false
                "bg-slate-300"
                : "bg-emerald-500 hover:bg-emerald-400"
              }

              ${takePermission(20).PerApprove === true ? "flex" : "hidden"}
            `}
            onClick={_onClickSubmitNow}
            disabled={
              selectedPhongBan === -1 ||
                selectedNguoiNhan === -1 ||
                selectedLoaiViec === -1 ||
                selectedNguonVanBan === -1 ||
                selectedUuTien === -1 ||
                selectedDoMat === -1 ||
                selectedDauViec === -1 ||
                selectedDoKho === -1 ||
                deadLine === "" ||
                taskName === "" ||
                _onCheckValid() === false ||
                isLoading === true
                ? true
                : false
            }
          >
            <SendTwoToneIcon fontSize="small" />
            <p>Giao việc ngay</p>
          </button>

          <button
            className="
          hidden lg:flex md:flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={_onClickCaching}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};
export default CreateNewTask;
