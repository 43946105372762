import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import Title from "../../../components/title";
import Table from "../../../components/table";

import SaveIcon from "@mui/icons-material/Save";

import Select from "react-select";

import { takePermission } from "../../../funcs";

import {
  GROUP_READ_ALL_DROPDOWN,
  SYS_DATA_LEVEL,
  SYS_DATA_LEVEL_UPDATE,
} from "../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";

import {
  ONLOAD_GROUPLIST_DROPDOWN,
  ONCHANGE_SCREENCHOOSE,
} from "../../../redux/reducers/userReducer/_index";

const DataOptionUI = () => {
  const data = useSelector((state: any) => state.userReducer);
  const dispatch = useDispatch();
  const shouldLog = useRef(true);

  const menu = takePermission(8);

  const shouldLog2 = useRef(true);

  // state
  const [selectedGroupList, setSelectedGroupList] = useState<any>("0");
  const [dataLevel, setDataLevel] = useState<number>(0);

  // funcs
  const _onChangeDataLevel = (e: any) => {
    setDataLevel(parseInt(e.target.value));
  };

  const _onClickSubmit = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const API = SYS_DATA_LEVEL_UPDATE(
      selectedGroupList.toString(),
      dataLevel.toString()
    );

    try {
      await axios
        .get(API, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        })
        .then((res: any) => {
          if (res.status === 200) {
            toast.success("Cập nhật thành công.");
          } else {
            toast.error("Cập nhật thất bại.");
          }
        });
    } catch (error: any) {
      toast.error("Cập nhật thất bại.");
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const API = SYS_DATA_LEVEL + data.screenChoose;

    if (shouldLog2.current) {
      shouldLog2.current = false;

      const takeData = async () => {
        try {
          await axios
            .get(API, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                setDataLevel(res.data.Data[0].DataLevel);
              }
            });
        } catch (error: any) {}
      };
      takeData();
    }
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const API = SYS_DATA_LEVEL + data.screenChoose;

    if (selectedGroupList === "0") {
      return;
    } else {
      const takeData = async () => {
        try {
          await axios
            .get(API, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                setDataLevel(res.data.Data[0].DataLevel);
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại");
        }
      };
      takeData();
    }
  }, [selectedGroupList, data.screenChoose]);

  // load group
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (data.groupList === null && shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await axios
            .get(GROUP_READ_ALL_DROPDOWN, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_GROUPLIST_DROPDOWN(res.data.Data));
              } else {
                toast.error("Tải dữ liệu nhóm quyền thất bại.");
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu nhóm quyền thất bại.");
        }
      };

      takeData();
    } else {
      return;
    }
  });

  // funcs
  const tempArray = data.groupListDropdown?.map((item: any) => { return { ...item, label: item.GroupName, value: item.GroupName } })

  const _onChangePermission = (data: any) => {
    if (data === null) {
      setSelectedGroupList("0");
      dispatch(ONCHANGE_SCREENCHOOSE(0));
    } else {
      setSelectedGroupList(data.ID);
      dispatch(ONCHANGE_SCREENCHOOSE(data.ID));
    }
  }

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-1.8rem)] text-sm pl-5 pr-5 pt-3 pb-3">
        <div className="flex w-full h-max justify-between">
          <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label className="text-xs">Nhóm quyền</label>
            
            <Select
            className="z-[5]"
            options={tempArray}
            onChange={(data: any) => _onChangePermission(data)}
            isClearable
            placeholder="--- Chọn nhóm quyền ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />


            {/* <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={data.screenChoose}
              onChange={(e: any) => {
                e.preventDefault();
                setSelectedGroupList(e.target.value);
                dispatch(ONCHANGE_SCREENCHOOSE(e.target.value));
              }}
            >
              <option className="text-gray-300" value={0} disabled>
                --- Chọn nhóm quyền ---
              </option>
              {data.groupListDropdown === null
                ? ""
                : data.groupListDropdown.map((item: any, index: any) => (
                    <option key={index} value={item.ID}>
                      {item.GroupName}
                    </option>
                  ))}
            </select> */}
          </div>

          <div
            className={`${
              menu.PerEdit === true ? "flex flex-col lg:w-2/3 w-full lg:pr-7 pr-0 h-max" : "hidden" 
            } w-max h-max`}
          >
            <button
              className={`
              pl-2.5 pr-2.5 pt-2.5 pb-3 bg-primary rounded-md text-white w-[6rem]
              hover:bg-sky-400 items-center space-x-1
              ${data.screenChoose === 0 ? "hidden" : "flex"}
            `}
              onClick={_onClickSubmit}
            >
              <SaveIcon fontSize="medium" />
              <p className="lg:flex hidden">Lưu lại</p>
            </button>
          </div>
        </div>

        <div className="w-full h-max flex flex-col mt-5">
          <p className="text-primary">
            Ứng với mỗi đầu mục công việc, chức năng và màn hình được sử dụng.
            Mỗi tài khoản được tiếp cận dữ liệu đã tạo ra theo 3 cấp độ.
          </p>
          <p className="text-primary">
            Chọn mức tiếp cận dữ liệu theo mục bên dưới
          </p>

          {data.screenChoose === 0 ? (
            <p className="mt-3 text-slate-500 font-semibold text-lg">
              VUI LÒNG CHỌN NHÓM QUYỀN
            </p>
          ) : (
            <fieldset
              onChange={(e: any) => {
                _onChangeDataLevel(e);
              }}
              className={`
                flex flex-col w-full h-max pl-10 mt-5 space-y-1 font-bold text-slate-500
              `}
              disabled={menu.PerEdit === true ? false : true}
            >
              <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  id="1"
                  name="fav_language"
                  value={1}
                  checked={dataLevel === 1 ? true : false}
                  placeholder=""
                />
                <p>Xem dữ liệu cá nhân</p>
              </div>
              <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  id="1"
                  name="fav_language"
                  value={2}
                  checked={dataLevel === 2 ? true : false}
                  placeholder=""
                />
                <p>Xem dữ liệu cấp phòng ban</p>
              </div>
              <div className="flex items-center space-x-3">
                <input
                  type="radio"
                  id="1"
                  name="fav_language"
                  value={3}
                  checked={dataLevel === 3 ? true : false}
                  placeholder=""
                />
                <p>Xem toàn bộ dữ liệu</p>
              </div>
            </fieldset>
          )}
        </div>
      </div>
    </div>
  );
};

export default DataOptionUI;
