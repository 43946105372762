import React from "react";

import SendIcon from "@mui/icons-material/Send";

import { CheckCompletedTaskContent } from "../../../action/taskOptionsAction";

import { toast } from "react-toastify";

const CompletedTaskContent = (props: any) => {
  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const taskContentHistoryId = props.taskContentHistoryId;

  const _onClickSubmit = () => {
    CheckCompletedTaskContent(taskContentHistoryId)
      .then((res: any) => {
        const data = (res || {}).data || {};
        if (data.RCode == 200) {
          if (handleClose) {
            handleClose();
          }
          toast.success("Hoàn tất bước thành công!");
        } else {
          if (handleClose) {
            handleClose();
          }
          toast.error(data.RMessage || "Hoàn tất bước thất bại!");
        }
      })
      .catch((err: any) => {
        if (handleClose) {
          handleClose();
        }
        toast.error(err || "Hoàn tất bước thất bại!");
      });
  };

  return (
    <div
      className="
      flex bg-emerald-500
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    "
    >
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <SendIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>Bạn có đồng ý hoàn tất bước này không?</p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button
            className="w-[5rem] h-max pt-1 pb-1 bg-slate-500 rounded-md hover:bg-slate-400"
            onClick={() => {
              _onClickSubmit();
            }}
          >
            Có
          </button>
          <button
            className="w-[5rem] h-max pt-1 pb-1 bg-red-500 rounded-md hover:bg-red-400"
            onClick={handleClose}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompletedTaskContent;
