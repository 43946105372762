import React, { useEffect, useRef, useState, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { USER_READ_ALL } from "./apis/_index";

import { PRIOR_LIST_DROPDOWN } from "./apis/_index";

import {
  ONSET_NAVBAR_BUTTON_AT_FIRST,
  ONSET_NAVBAR_OPTIONS_AT_FIRST,
  ONCHANGE_MENULIST,
  ONCHANGE_NOTI,
  ONCHANGE_NOTI_PLUS1,
  ONCHANGE_PRIORLIST_DROPDOWN,
} from "./redux/reducers/_appReducer/_index";

import {
  ONCHANGE_RESET_FILTER,
  ONSET_RETURN_PAGE_CURRENT,
  RESET_HIGHMANGER_STORAGE
} from "./redux/reducers/workingReducer/_index";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// temp
import { Start } from "./utils/signalR";
import { Notification } from "./utils/notification";

// router importing
import Router from "./router/_index";
import axios from "axios";

function App() {
  // testing
  const urlLink = window.location.pathname;

  // temp
  const shouldLogTemp = useRef(true);
  const userData_: any = localStorage.getItem("userData");
  const token: any = localStorage.getItem("accessToken");

  const workingData = useSelector((state: any) => state.workingReducer)

  const menuList = localStorage.getItem("menuList");
  const data = useSelector((state: any) => state.appReducer);
  const dispatch = useDispatch();

  // reload
  useEffect(() => {
    dispatch(RESET_HIGHMANGER_STORAGE)
  }, [urlLink, dispatch])

  // useEffect(() => {
  //   const resetDispatch = async () => {
  //     await Promise.all([
  //       dispatch(ONCHANGE_RESET_FILTER()),
  //       dispatch(ONSET_RETURN_PAGE_CURRENT())
  //     ])
  //   }

  //   resetDispatch()
  // }, [urlLink, dispatch])

  const shouldLogPriorList = useRef(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken === undefined || accessToken === null) {
      // do nothing
    } else {
      if (data.priorListDropdown === null) {
        if (shouldLogPriorList.current === true) {
          shouldLogPriorList.current = false;

          const takeData = async () => {
            try {
              await axios
                .get(PRIOR_LIST_DROPDOWN, {
                  headers: {
                    Authorization: "Bearer " + accessToken,
                    Accept: "text/plain",
                  },
                })
                .then((res) => {
                  if (res.data.StatusCode === 200) {
                    dispatch(ONCHANGE_PRIORLIST_DROPDOWN(res.data.Data));
                  }
                });
            } catch (error) {
              toast.error("Tải dữ liệu mức độ công việc thất bại");
            }
          };

          takeData();
        }
      } else {
        // do nothing
      }
    }
  });

  useEffect(() => {
    if (userData_ && token && shouldLogTemp.current) {
      shouldLogTemp.current = false;

      const fetchData = async () => {
        await Promise.all([
          Notification((res: any) => {
            dispatch(ONCHANGE_NOTI(res));
          }, token),
          Start({
            users: userData_,
            token: token,
            callback: () => {
              dispatch(ONCHANGE_NOTI_PLUS1());
            },
          }),
        ]);
      };

      fetchData();
      // Notification(
      //   (res: any) => { dispatch(ONCHANGE_NOTI(res)) },
      //   token
      // );
      // Start({ users: userData_, token: token, callback: () => { dispatch(ONCHANGE_NOTI_PLUS1()) } });
    }

    // console.log(data.totalNoti);
  });

  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);

  // check isShowButtonNavbar

  useEffect(() => {
    let navbarButton = sessionStorage.getItem("isShowButtonNavbar");
    let navbarOptions = sessionStorage.getItem("whatMainOptionsShowing");

    // navbar button show
    if (navbarButton === null) {
      sessionStorage.setItem("isShowButtonNavbar", "false");
      dispatch(ONSET_NAVBAR_BUTTON_AT_FIRST("true"));
    } else {
      dispatch(ONSET_NAVBAR_BUTTON_AT_FIRST(navbarButton));
    }

    // options on navbar
    if (navbarOptions === null) {
      sessionStorage.setItem("whatMainOptionsShowing", "1");
      dispatch(ONSET_NAVBAR_OPTIONS_AT_FIRST("1"));
    } else {
      dispatch(ONSET_NAVBAR_OPTIONS_AT_FIRST(navbarOptions));
    }
  });

  useEffect(() => {
    if (menuList !== null && shouldLog.current) {
      shouldLog.current = false;

      if (data.mainMenu.length === 0 && data.subMenu.length === 0) {
        dispatch(ONCHANGE_MENULIST());
      } else {
        return;
      }
    } else {
      return;
    }
  }, [data.mainMenu, data.subMenu.length, dispatch, menuList]);

  /// đoạn này LAD thêm vào để chặn robot tìm kiếm hệ thống phần mềm : 28/06/2023 10:57 AM
  /// cần thời gian chạy mới biết nó có chặn đầy đủ không
  /// lý do: phần mềm không thể để bị search engine tìm ra, vì nếu tìm ra thì rất dễ bị tấn công trực diện
  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/Googlebot|bingbot|BingPreview|AdIdxBot/.test(userAgent)) {
      window.location.href = "https://www.isopro.vn";
    }

    const addNoIndexMetaTag = () => {
      const metaTag = document.createElement("meta");
      metaTag.name = "robots";
      metaTag.content = "noindex";
      document.head.appendChild(metaTag);
    };

    addNoIndexMetaTag();

    return () => {
      const metaTag = document.querySelector('meta[name="robots"]');
      if (metaTag) {
        document.head.removeChild(metaTag);
      }
    };
  }, []);
  /// kết thúc đoạn kiểm tra chặn search engine

  return (
    <div className="w-screen h-screen">
      <Router />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        theme="colored"
      />
    </div>
  );
}

export default App;
