import React, { useEffect, useRef, useState } from "react";
import Title from "../../../../components/title";
import SubmitHeader from "../../../../components/submitHeader";
import ContentTop from "../../../../components/contentTop";

import Chart, { CategoryScale } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie, Bar } from "react-chartjs-2";
import { readReport, randomColor } from "../../../../funcs/reportCalling";
import { takePhongban } from "../../../../funcs/reportCalling";
import { REPORT_PERSONAL_DIFTASK } from "../../../../apis/_index";

import moment from "moment";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const UserDifTaskUI = (props: any) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear(); // Lấy năm hiện tại
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Lấy tháng hiện tại (0-11, nên cộng thêm 1)
  const day = currentDate.getDate().toString().padStart(2, "0"); // Lấy ngày hiện tại

  const [data, setData] = useState<any[] | null>(null);
  const [Tiledokho_percent, setTiledokho_percent] = useState<
    number[] | undefined
  >(undefined);
  const [Tiledokho_Dif, setTiledokho_Dif] = useState<number[] | undefined>(
    undefined
  );
  const [Tiledokho_DifPB, setTiledokho_DifPB] = useState<number[] | undefined>(
    undefined
  );
  const [Tiledokho_DifA, setTiledokho_DifA] = useState<number[] | undefined>(
    undefined
  );
  const [Tiledokho_label, setTiledokho_label] = useState<string[] | undefined>(
    undefined
  );
  const [Tiledokho_color, setTiledokho_color] = useState<string[] | undefined>(
    undefined
  );

  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [selectedPhongban, setSelectedPhongban] = useState<any>(-1);

  const [fromDate, setFromDate] = useState<any | null>(
    new Date().getFullYear() + "-01-01"
  );
  const [toDate, setToDate] = useState<any | null>(
    new Date().getFullYear() + "-12-31"
  );

  const shouldLog = useRef(true);

  // first call
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const body = {
        TuNgay: fromDate,
        DenNgay: toDate,
        _isCurUser: true,
      };

      readReport(REPORT_PERSONAL_DIFTASK, body, (res: any) => {
        setData(res.data.Data);
      });

      takePhongban((res: any) => setPhongBanList(res.data.Data));
    }
  });
  // follow first call
  useEffect(() => {
    if (data === null) {
    } else {
      let tempArrTile: any[] = [];
      let tempArrData: any[] = [];
      let tempArrMausac: any[] = [];
      let tempArrDif: any[] = [];
      let tempArrDifPB: any[] = [];
      let tempArrDifA: any[] = [];

      data.forEach((item: any) => {
        let tempTile = item._percent.toFixed(2);
        let tempData = item.DifficultLevelName;
        let tempMausac = item.Color;
        let tempDif = item.SoLuong;
        let tempDifPB = item.DifPB;
        let tempDifA = item.DifA;

        tempArrTile.push(tempTile);
        tempArrData.push(tempData);
        tempArrMausac.push(tempMausac);
        tempArrDif.push(tempDif);
        tempArrDifPB.push(tempDifPB);
        tempArrDifA.push(tempDifA);
      });

      setTiledokho_color(tempArrMausac);
      setTiledokho_percent(tempArrTile);
      setTiledokho_label(tempArrData);
      setTiledokho_Dif(tempArrDif);
      setTiledokho_DifPB(tempArrDifPB);
      setTiledokho_DifA(tempArrDifA);
    }
  }, [data]);

  // filter
  const _onClickSubmit = () => {
    let body: any;

    body = {
      TuNgay: fromDate,
      DenNgay: toDate,
      _isCurUser: true,
    };

    readReport(REPORT_PERSONAL_DIFTASK, body, (res: any) => {
      setData(res.data.Data);
    });

    takePhongban((res: any) => setPhongBanList(res.data.Data));
  };

  return (
    <>
      <br></br>
      <div className="flex lg:flex-row flex-col w-full h-max pl-2">
        <div className="flex flex-col w-full lg:h-[4rem] h-max space-y-3">
          <div className="flex lg:flex-row flex-col w-full h-max">
            <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
              <label className="drop-shadow-lg">Từ ngày</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Từ Ngày"
                value={fromDate}
                type="date"
                onChange={(e: any) => {
                  e.preventDefault();
                  setFromDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full lg:h-[4rem] h-max space-y-3">
          <div className="flex lg:flex-row flex-col w-full h-max">
            <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
              <label className="drop-shadow-lg">Đến ngày</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Đến Ngày"
                value={toDate}
                type="date"
                onChange={(e: any) => {
                  e.preventDefault();
                  setToDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="flex flex-col lg:w-1/2 w-full lg:pr-7 pr-0 h-max"
          style={{ textAlign: "center" }}
        >
          <label>&nbsp; </label>
          <button
            className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white 
            hover:bg-sky-400 active:shadow-inner active:shadow-slate-500  
            "
            onClick={_onClickSubmit}
          >
            <p style={{ width: "100%", textAlign: "center" }}>Xem</p>
          </button>
        </div>
      </div>

      <hr style={{ margin: "20px" }}></hr>

      {/* chartjs */}
      <div className="lg:flex w-full lg:h-5/6 h-[20rem] p-3 ">
        {/* left side */}
        <div className="lg:w-1/2 h-full">
          <div className="w-full h-full flex flex-col">
            <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold">
              BIỂU ĐỒ TỈ LỆ ĐỘ KHÓ CÔNG VIỆC CÁ NHÂN
            </div>
            <div className="flex justify-center items-center w-full h-[calc(100%-2rem)]">
              <Pie
                className="w-full h-full drop-shadow-lg"
                datasetIdKey="id"
                data={{
                  labels: Tiledokho_label,
                  datasets: [
                    {
                      label: "Tỉ lệ",
                      data: Tiledokho_percent,
                      backgroundColor: Tiledokho_color,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    datalabels: {
                      color: "black",
                      font: {
                        size: 15,
                        weight: "bold",
                      },
                      formatter: (value: any) => `${value}%`,
                      clamp: true,
                    },
                    legend: {
                      position: "bottom",
                      labels: {
                        usePointStyle: true,
                        font: {
                          size: 11,
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>

        {/* right side */}
        <div className="flex flex-col lg:w-1/2 h-full">
          <div className="flex w-full lg:h-1/2 pb-2 drop-shadow-lg">
            <div className="flex flex-col w-full h-full rounded-sm bg-white">
              <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold">
                TỈ LỆ CÁ NHÂN SO VỚI CẢ PHÒNG
              </div>
              <div className="flex justify-center items-center w-full h-[calc(100%-2rem)] ">
                <Bar
                  className="w-full h-full drop-shadow-lg"
                  datasetIdKey="id"
                  data={{
                    labels: Tiledokho_label,
                    datasets: [
                      {
                        label: "Cá nhân",
                        data: Tiledokho_Dif,
                        backgroundColor: ["#0ea449"],
                      },

                      {
                        label: "Toàn phòng",
                        data: Tiledokho_DifPB,
                        backgroundColor: ["#f4423c"],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      datalabels: {
                        color: "black",
                        font: {
                          size: 13,
                          weight: "bold",
                        },
                        //   formatter: (value: any) => `${value}`,
                        formatter: function (value: any, context) {
                          return value === 0 ? "" : value;
                        },
                        anchor: "end",
                        align: "end",
                      },
                      legend: {
                        display: true,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>

          <div className="flex w-full lg:h-1/2 pt-2">
            <div className="flex flex-col w-full h-full rounded-sm bg-white drop-shadow-lg ">
              <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold v ">
                TỈ LỆ CÁ NHÂN SO VỚI TOÀN CÔNG TY
              </div>
              <div className="flex justify-center items-center w-full h-[calc(100%-2rem)]">
                <Bar
                  className="w-full h-full drop-shadow-lg"
                  datasetIdKey="id"
                  data={{
                    labels: ["Dể", "Trung Bình", "Khó"],
                    datasets: [
                      {
                        label: "Cá nhân",
                        data: Tiledokho_Dif,
                        backgroundColor: ["#0ea449"],
                      },

                      {
                        label: "Toàn cơ quan",
                        data: Tiledokho_DifA,
                        backgroundColor: ["#f4423c"],
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      datalabels: {
                        color: "black",
                        font: {
                          size: 13,
                          weight: "bold",
                        },
                        //   formatter: (value: any) => `${value}`,
                        formatter: function (value: any, context) {
                          return value === 0 ? "" : value;
                        },
                        anchor: "end",
                        align: "end",
                      },
                      legend: {
                        display: true,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDifTaskUI;
