import React, { useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ModalController } from "../../../ui/homeUI/_index";

import DeleteIcon from '@mui/icons-material/Delete';

import { readList } from "../../../funcs/taskNguoiGiaoViec";

import { ONLOAD_TASKLIST_ALL } from "../../../redux/reducers/workingReducer/_index";

import { NGUOI_GIAO_VIEC_XOA, NGUOI_GIAO_VIEC_LIST } from "../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";

const DeleteTask = (props: any) => {

  const data = useSelector((state: any) => state.workingReducer)
  const dispatch = useDispatch()
  const shouldLog = useRef(true);
  const urlLink = window.location.pathname;
  const context = useContext(ModalController)

  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  // funcs
  const _onClickDelete = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const API = NGUOI_GIAO_VIEC_XOA + taskId.toString();

    try {
      await axios.get(
        API,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain"
          }
        }
      )
      .then((res: any) => {
        if (res.data.StatusCode === 200) {
          readList(
            NGUOI_GIAO_VIEC_LIST,
            data.pageCurrent,
            data.choosePage,
            data.searchFilter.taskName,
            data.searchFilter.selectedPrior,
            data.searchFilter.selectedReceiver,
            data.searchFilter.fromDate,
            data.searchFilter.toDate,
            data.searchFilter.deadLine,
            shouldLog,
            urlLink,
            (res: any) => { dispatch(ONLOAD_TASKLIST_ALL(res.data.Data)) }
          )
          toast.success("Xoá thành công");
          handleClose();
          context.handleClose()
        } else {
          toast.error(res.data.Data.Message)
          handleClose();
          context.handleClose()
        }
      })
    } catch (error: any) {
      toast.error("Xoá thất bại");
    }
  }

  return (
    <div className="
      flex bg-red-400
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    ">
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <DeleteIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>Bạn có đồng ý <span className="font-semibold">XOÁ</span> công việc này không?</p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button className="w-[5rem] h-max pt-1 pb-1 bg-slate-500 rounded-md hover:bg-slate-400"
            onClick={_onClickDelete}
          >
            Có
          </button>
          <button className="w-[5rem] h-max pt-1 pb-1 bg-red-500 rounded-md hover:bg-red-300"
            onClick={handleClose}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteTask;
