import React, { useState, useContext } from "react";

import { CONFIRM_EXTEND, NGUOI_GIAO_UPDATE_DEADLINE } from "../../../apis/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import axios from "axios";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import LoaderSpinner from "../../loader-spinner/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import ModalBase from "../../../components/modal/_index";
import { toast } from "react-toastify";
import { set } from "date-fns";

const EditDeadLineNguoiGiao = (props: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const taskId = props.taskId;
  const timeExtend = props.timeExtend;
  const [isChangeTimeExtend, setIsChangeTimeExtend] = useState<boolean>(false);
  const extendId = props.extendId;
  const isResult = props.isResult;
  const [result, setResult] = useState<string>("");
  const [newTimeExtend, setNewTimeExtend] = useState<string>(timeExtend);
  const handleClose = props.handleClose;
  const context = useContext(ModalController);
  const _loadData = props._loadData;

  const [isloading, setIsLoading] = useState(false);

  const _onClickSubmit = async () => {
    // const body = {
    //   ID: extendId,
    //   TaskID: taskId,
    //   Result: result,
    //   TimeExtend: newTimeExtend,
    //   IsResult: isResult,
    // };

    const body = {

    }

    setIsLoading(true);

    try {
      await axios
        .get(NGUOI_GIAO_UPDATE_DEADLINE(taskId, newTimeExtend), {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        })
        .then((res: any) => {
          if (((res || {}).data || {}).StatusCode === 200) {
            toast.success(res.data.Data.RMessage || "Gia hạn thành công");

            setIsLoading(false);
            handleClose();
            context.handleClose();
            // _loadData();
            // window.location.reload()
          } else {
            toast.error(res.data.Data.RMessage || "Gia hạn thất bại");
          }
        });
    } catch (error: any) {
      toast.error(error || "Gia hạn thất bại");
    }
  };

  return (
    <div className="flex flex-col bg-white lg:w-[34rem] w-screen h-full rounded-md">
      {/* header */}
      <ModalBase _loadData={_loadData} />
      <div
        className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Xác nhận gia hạn thời hạn</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>
      {/* content */}
      <div className="relative flex flex-col w-full h-full overflow-auto p-4 text-sm styled-scrollbars space-y-2">
        <div className="space-y-2">
          <div className="flex items-center">
            <label className="w-1/4">Thời hạn mới</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              type="datetime-local"
              value={newTimeExtend}
              onChange={(e: any) => {
                e.preventDefault();
                setNewTimeExtend(e.target.value);
                setIsChangeTimeExtend(
                  timeExtend === e.target.value ? false : true
                );
              }}
            />
          </div>
          {/* {isChangeTimeExtend && (
            <div className="space-y-1">
              <label>
                Lý do thay đổi ngày&nbsp;
                <span className="text-red-500">(*)</span>
              </label>
              <textarea
                className="w-full h-[5rem] rounded-md p-2 border-2 border-slate-200"
                placeholder="Nhập lí do thay đổi ngày"
                onChange={(e: any) => {
                  e.preventDefault();
                  setResult(e.target.value);
                }}
              />
            </div>
          )} */}
        </div>
      </div>
      {/* footer */}
      <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-end items-center pl-4 pr-4 text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          {isloading ? (
            <LoaderSpinner h={25} w={25} />
          ) : (
            <button
              className={`flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md
              ${(isResult === false && result === "")
                  ? "bg-slate-300"
                  : "bg-green-500 hover:bg-green-400"
                }`}
              disabled={
                (isResult === false && result === "")
              }
              onClick={_onClickSubmit}
            >
              <p>Xác nhận</p>
            </button>
          )}

          <button
            className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditDeadLineNguoiGiao;
