import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ONLOAD_SELECTED_RECEIVER, ONLOAD_SELECTED_PRIOR, ONCHANGE_TASK_NAME } from "../redux/reducers/workingReducer/_index";


import { ModalController } from "../ui/homeUI/_index";

import { onTouchForm } from "../funcs/onTochForm";

import WorkingNewTaskJSX from "./contentTop/WorkingNewTaskJSX";
import WorkingReceiverAllTaskJSX from "./contentTop/WorkingReceiverAllTask";

const ContentTop = () => {

  const urlLink = window.location.pathname;

  // report
  const ReportDeliverWorkerCapacity = () => {
    return (
      <div className="flex lg:flex-row flex-col w-full h-max">
        <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
          <label>Ngày xem dữ liệu</label>
          <input
            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
            placeholder="Nhập tên công việc"
            type="date"
            onTouchStart={(e: any) => onTouchForm(e)}
          />
        </div>
        <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
          <label>Phòng ban</label>
          <select
            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
            defaultValue={0}

          >
            <option className="text-gray-300" value={0} disabled>--- Chọn phòng ban ---</option>
            <option>Phòng ban 1</option>
            <option>Phòng ban 2</option>
            <option>Phòng ban 3</option>
          </select>
        </div>
      </div>
    )
  }

  const ReportDetailWorkerCapacity = () => {
    return (<>
      <div className="flex lg:flex-row flex-col w-full h-max">
        <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
          <label>Xem kỳ đánh giá</label>
          <input
            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
            placeholder="Nhập tên công việc"
            type="date"
            onTouchStart={(e: any) => onTouchForm(e)}
          />
        </div>
        <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
          <label>Chọn nhân viên</label>
          <select
            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
            defaultValue={0}
          >
            <option className="text-gray-300" value={0} disabled>--- Chọn nhân viên ---</option>
            <option>Nhân viên 1</option>
            <option>Nhân viên 2</option>
            <option>Nhân viên 3</option>
          </select>
        </div>

      </div>
      <br />
    </>
    )
  }

  const ReportIndividualCapactity = () => {
    return (
      <div className="flex lg:flex-row flex-col w-full h-max">
        <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
          <label>Ngày xem</label>
          <input
            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
            placeholder="Nhập tên công việc"
            type="date"
            onTouchStart={(e: any) => onTouchForm(e)}
          />
        </div>
      </div>
    )
  }

  const ReceiverTaskAllTask = () => {

  }

  const TableReportCapacityUI = () => {
    return (
      <div className="flex lg:flex-row flex-col w-full h-max">
        <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
          <label>Xem kỳ đánh giá</label>
          <input
            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
            placeholder="Nhập tên công việc"
            type="date"
            onTouchStart={(e: any) => onTouchForm(e)}
          />
        </div>

      </div>
    )
  }

  // WorkingReceiverAllTaskJSX

  return (
    <>
      {urlLink === "/report/deliver/worker-capacity" ? <ReportDeliverWorkerCapacity />
        : urlLink === "/report/deliver/detail-worker-capacity" ? <ReportDetailWorkerCapacity />
          : urlLink === "/report/receiver/individual-capacity" ? <ReportIndividualCapactity />
            : urlLink === "/report/receiver/table-report-capacity" ? <TableReportCapacityUI />
              : urlLink === "/working/receiver/all-task" || urlLink === "/working/task-total" || urlLink === "/working/task-totalall" ? <WorkingReceiverAllTaskJSX />
                : <WorkingNewTaskJSX />}
    </>
  )
}

export default ContentTop;
