import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import SendIcon from "@mui/icons-material/Send";
import { ONLOAD_SET_USER_LIST } from "../../../redux/reducers/_appReducer/_index";
import { USER_READ_ALL_DROPDOWN } from "../../../apis/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import {
  PRIOR_LIST_DROPDOWN,
  SECURITY_READ_ALL_DROPDOWN,
  SENT_MESSAGE,
} from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import { NGUOI_NHAN_VIEC_DETAIL } from "../../../apis/_index";

import SubModal from "../_subModal/_index";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

import {
  UploadFileTaskResult,
  CheckCompletedTaskContent,
} from "../../../action/taskOptionsAction";

import AttachFileIcon from "@mui/icons-material/AttachFile";

import FindInPageIcon from "@mui/icons-material/FindInPage";

const ChatPopup = (props: any) => {
  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [uuTienList, setUuTienList] = useState<any[] | null>(null);
  const [doMatList, setDoMatList] = useState<any[] | null>(null);

  const appData = useSelector((state: any) => state.appReducer);
  const dispatch = useDispatch();

  const [task, setTask] = useState<any>(null);
  const [taskContents, setTaskContents] = useState<any[]>([]);
  const [_taskContents, set_TaskContents] = useState<any[]>([]);
  const [taskContentsHistory, setTaskContentsHistory] = useState<any[]>([]);
  const [taskResults, setTaskResults] = useState<any>(null);
  const [taskInfoText, setTaskInfoText] = useState<any[]>([]);
  const [isloading, setIsLoading] = useState(false);
  const [taskContentHistoryId, setTaskContentHistoryId] = useState<any>(null);
  const [isViewHistoty, setIsViewHistoty] = useState<any>(null);
  const [taskHistory, setTaskHistory] = useState<any[]>([]);

  const [text, setText] = useState<string>("");
  const [fileListAPI, setFileListAPI] = useState<any[]>([]);
  const [fileListAPI2, setFileListAPI2] = useState<any[]>([]);
  const [isShowConfirmtaskForUser, setIsShowConfirmtaskForUser] =
    useState<boolean>(false);
  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);
  const shouldLog3 = useRef(true);

  const uploadFileResult = useRef<any>(null);
  const uploadFileChat = useRef<any>(null);
  const uploadFileStep = useRef<any>(null);

  const [filesList, setFilesList] = useState<any[]>([]);
  const [filesList2, setFilesList2] = useState<any[]>([]);
  const [filesList3, setFilesList3] = useState<any[]>([]);
  const [selectedTypeOf, setSelectedTypeOf] = useState<number>(0);

  const urlLink = window.location.pathname;

  const _taskInfoText = props.taskInfoText;
  const _handleCheckCompled = props._handleCheckCompled;

  const _onClickChooseFileChat = () => {
    uploadFileChat.current.click();
  };

  const _onChangeFileImage = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...filesList] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setFilesList(tempArray);
  };

  const _onClickSentChat = () => {
    if (text === "") {
      // do nothing
    } else {
      const accessToken = localStorage.getItem("accessToken");

      const formData = new FormData();
      let fileNameString: string = "";

      formData.append("TaskID", taskId);
      formData.append("Contents", text);
      formData.append("typeofNote", selectedTypeOf.toString());

      if (filesList) {
        for (let i = 0; i < filesList.length; i++) {
          formData.append(`File_ListFile`, filesList[i]);
          if (i === filesList.length - 1) {
            fileNameString += `${filesList[i].name}`;
          } else {
            fileNameString += `${filesList[i].name};`;
          }
        }
        formData.append("ListFileName", fileNameString);
      }

      const sentCode = async () => {
        try {
          await axios
            .post(SENT_MESSAGE, formData, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res: any) => {
              const data = res.data.Data[0];
              if (data.RCode === 200) {
                if (_handleCheckCompled) {
                  _handleCheckCompled(1);
                }

                setSelectedTypeOf(0);
                setText("");
                setFilesList([]);

                toast.success("Gửi trao đổi thành công");
              } else {
                toast.error(data.RMessage || "Gửi trao đổi thất bại");
              }
            })
            .catch((err: any) => toast.error("Gửi trao đổi thất bại"));
        } catch (error: any) {}
      };

      sentCode();
    }
  };

  const _handeleShowConfirmTask = () => {
    if (taskHistory.length > 0) {
      taskHistory?.map((x: any) => {
        if (x.Checked === true) {
          setIsShowConfirmtaskForUser(false);
        } else if (x.Checked === false) {
          setIsShowConfirmtaskForUser(true);
        } else {
          setIsShowConfirmtaskForUser(false);
        }
      });
    } else {
      setIsShowConfirmtaskForUser(true);
    }
  };

  useEffect(() => {
    _handeleShowConfirmTask();
  }, [taskHistory]);

  useEffect(() => {
    //getDetail(1);
    if (_taskInfoText) {
      setTaskInfoText(_taskInfoText);
    }
  }, [_taskInfoText]);

  // useEffect(() => {
  //   _handleTaskContentHistory();
  // }, [taskContents, taskContentsHistory]);

  // useEffect(() => {
  //   if (uuTienList === null || doMatList === null) {
  //     if (shouldLog2.current) {
  //       const accessToken = localStorage.getItem("accessToken");
  //       shouldLog2.current = false;

  //       const takeUuTien = async () => {
  //         try {
  //           await axios
  //             .get(PRIOR_LIST_DROPDOWN, {
  //               headers: {
  //                 Authorization: "Bearer " + accessToken,
  //                 Accept: "text/plain",
  //               },
  //             })
  //             .then((res: any) => {
  //               setUuTienList(res.data.Data);
  //             });
  //         } catch (error: any) {
  //           toast.error("Tải dữ liệu thất bại");
  //         }
  //       };

  //       const takeDoMat = async () => {
  //         try {
  //           await axios
  //             .get(SECURITY_READ_ALL_DROPDOWN, {
  //               headers: {
  //                 Authorization: "Bearer " + accessToken,
  //                 Accept: "text/plain",
  //               },
  //             })
  //             .then((res: any) => {
  //               setDoMatList(res.data.Data);
  //             });
  //         } catch (error: any) {
  //           toast.error("Tải dữ liệu thất bại");
  //         }
  //       };

  //       takeUuTien();
  //       takeDoMat();
  //     }
  //   }
  // });

  // useEffect(() => {
  //   if (appData.userList === null && shouldLog3.current) {
  //     shouldLog3.current = false;

  //     const accessToken = localStorage.getItem("accessToken");

  //     const takeData = async () => {
  //       try {
  //         await axios
  //           .get(USER_READ_ALL_DROPDOWN, {
  //             headers: {
  //               Authorization: "Bearer " + accessToken,
  //               Accept: "text/plain",
  //             },
  //           })
  //           .then((res: any) => {
  //             dispatch(ONLOAD_SET_USER_LIST(res.data.Data));
  //           });
  //       } catch (error: any) {
  //         toast.error("Tải dữ liệu thất bại");
  //       }
  //     };
  //     takeData();
  //   }
  // });

  // const _handleTaskContentHistory = () => {
  //   if (taskContents.length > 0 && taskContentsHistory.length > 0) {
  //     let _taskContent: any[] = [];
  //     let foundObject: any[] = [];
  //     let _foundObject: any[] = [];

  //     _taskContent = taskContents.map((item1: any) => {
  //       foundObject = taskContentsHistory.filter(
  //         (item2: any) => item1.ID === item2.Task_ContentID
  //       );
  //       if (foundObject.length > 0) {
  //         _foundObject = foundObject.find(
  //           (x: any, index: any) =>
  //             x.Checked === null || index == foundObject.length - 1
  //         );

  //         return { ...item1, taskContentsHistory: _foundObject };
  //       } else {
  //         return { ...item1 };
  //       }
  //     });
  //     set_TaskContents(_taskContent);
  //   } else {
  //     set_TaskContents(taskContents);
  //   }
  // };

  // take props
  const handleClose = props.handleClose;
  const taskId = props.taskId;

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[80rem] w-screen lg:h-[40rem] h-[calc(100vh-10rem)]
      rounded-md
    "
    >
      {/* header */}
      <div
        className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <div className="flex space-x-2 items-center">
          <DescriptionTwoToneIcon />
          <p>Thông tin trao đổi</p>
        </div>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      {taskInfoText === null || appData.userList === null || isloading ? (
        <div className="flex justify-center items-center w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm">
          <LoaderSpinner h={32} w={32} />
          <p className="text-primary">Đang tải biểu mẫu</p>
        </div>
      ) : (
        <div className="flex w-full h-full overflow-hidden pr-4 pl-4 pb-4 text-sm drop-shadow-lg">
          <div className="styled-scrollbars w-full h-full pr-5 pl-5 pb-5 flex flex-col space-y-5 overflow-hidden mb-[]">
            <div className="styled-scrollbars relative w-full h-full">
              <div className="styled-scrollbars absolute top-[1.5rem] w-full h-[calc(100vh-9rem)] overflow-auto flex flex-col space-y-2 pb-2">
                {taskInfoText.length <= 0 ? (
                  <div className="text-center">
                    <p className="uppercase text-slate-500">
                      Không có trao đổi
                    </p>
                  </div>
                ) : (
                  taskInfoText?.map((item: any, index: any) => (
                    <>
                      <div
                        key={index}
                        className={
                          item.UserAdd === appData.userData.Id
                            ? "flex flex-col w-full h-max justify-end items-end"
                            : "flex flex-col w-full h-max justify-start items-start"
                        }
                      >
                        <p className="text-xs text-slate-500 drop-shadow-lg">
                          <b>{item.UserAddName ? item.UserAddName : ""}</b>
                        </p>
                        <div
                          // className={
                          //   item.UserAdd === appData.userData.Id
                          //     ? "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-primary pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-white"
                          //     : item.typeofNote === 0
                          //       ? "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-slate-200 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black"
                          //       : item.typeofNote === 1
                          //         ? "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-green-200 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black"
                          //         : "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-red-200 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black"
                          // }
                          className={
                            item.typeofNote === 0
                              ? "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-slate-300 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black drop-shadow-lg"
                              : item.typeofNote === 1
                              ? "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-green-300 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black drop-shadow-lg"
                              : "flex flex-col space-y-2 w-max max-w-full max-h-full h-max bg-red-300 pt-1 pb-1 pl-1.5 pr-1.5 rounded-md text-black drop-shadow-lg"
                          }
                        >
                          <div className="w-full h-full flex flex-col">
                            <p>{item.Contents}</p>
                            {item.listFileInChat.length > 0 ? (
                              <>
                                {item.listFileInChat.map(
                                  (itemFChat: any, indexFChat: any) => (
                                    <a
                                      key={indexFChat}
                                      className={
                                        item.UserAdd === appData.userData.Id
                                          ? `text-white text-xs hover:underline`
                                          : `text-primary text-xs hover:underline`
                                      }
                                      href={itemFChat.listFile}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <FindInPageIcon fontSize="small" />
                                      {itemFChat.listFileName}
                                    </a>
                                  )
                                )}
                              </>
                            ) : null}
                          </div>
                          <p className="text-left text-[12px] text-white-500">
                            {moment(item.DateAdd).format("HH:mm:ss")} -{" "}
                            {moment(item.DateAdd).format("DD/MM/YYYY")}
                          </p>
                        </div>
                      </div>
                    </>
                  ))
                )}
              </div>
              {urlLink === "/voting/voting_work" ||
              urlLink === "/voting/voting_wait" ? null : (
                <div className="flex flex-col absolute bottom-0 w-full h-[6.5rem] bg-slate-100 mt-[2rem] rounded-sm">
                  <div className="flex items-center w-full h-[2rem]">
                    <div className="flex w-max h-full space-x-2 text-xs">
                      <button
                        className="flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white hover:bg-sky-400 drop-shadow-lg"
                        onClick={_onClickChooseFileChat}
                      >
                        <AttachFileIcon fontSize="small" />
                        <p>Chọn file</p>
                      </button>
                    </div>
                    <input
                      ref={uploadFileChat}
                      className={`w-max h-max`}
                      multiple
                      type="file"
                      onChange={(e: any) => _onChangeFileImage(e)}
                      hidden
                      onTouchStart={(e: any) => onTouchForm(e)}
                    />
                    <p
                      style={{
                        marginLeft: 5,
                        marginRight: 5,
                      }}
                    >
                      {filesList.length > 0
                        ? filesList.length + " file được chọn"
                        : ""}
                    </p>

                    <div className="flex w-max h-full space-x-2 text-xs">
                      <button
                        className={`
                        flex justify-center pl-1 pr-1 items-center w-max h-full text-slate-500 hover:text-slate-400 drop-shadow-lg transition-all delay-75
                        ${selectedTypeOf === 0 ? "bg-slate-200" : ""}
                      `}
                        onClick={() => setSelectedTypeOf(0)}
                      >
                        <p>Bình thường</p>
                      </button>

                      <button
                        className={`
                        flex justify-center pl-1 pr-1 items-center w-max h-full text-green-500 hover:text-green-400 drop-shadow-lg transition-all delay-75
                        ${selectedTypeOf === 1 ? "bg-slate-200" : ""}
                      `}
                        onClick={() => setSelectedTypeOf(1)}
                      >
                        <p>Ưu tiên</p>
                      </button>

                      <button
                        className={`
                        flex justify-center pl-1 pr-1 items-center w-max h-full text-red-500 hover:text-red-400 drop-shadow-lg transition-all delay-75
                        ${selectedTypeOf === 2 ? "bg-slate-200" : ""}
                      `}
                        onClick={() => setSelectedTypeOf(2)}
                      >
                        <p>Khẩn cấp</p>
                      </button>
                    </div>
                  </div>

                  <div className="flex w-full h-[4.5rem]">
                    <div className="w-4/5 flex h-full">
                      <textarea
                        className="w-full h-full rounded-md p-2 border-2 border-slate-200"
                        placeholder="Nhập nội dung"
                        value={text}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setText(e.target.value);
                        }}
                      />
                    </div>

                    <div className="w-1/5 h-full bottom-0 flex right-0 justify-center items-end">
                      <button
                        className="bg-primary hover:bg-green-500 w-max h-max pl-3 pr-3 pt-1.5 pb-1.5 rounded-md font-semibold"
                        onClick={_onClickSentChat}
                      >
                        <div className="flex text-white drop-shadow-lg">
                          <SendIcon fontSize="small" />
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatPopup;
