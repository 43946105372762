import React, { useState, useRef, useEffect, useContext } from "react";
import { HexColorPicker } from "react-colorful";
import { SketchPicker } from 'react-color';

import { CHiTietTieuChiDanhGia } from "../../../apis/_index";

import { useSelector, useDispatch } from "react-redux";
import { NGUOI_NHAN_TASK_COMPLETE } from "../../../apis/_index";

import SubModal from "../_subModal/_index";

import { ModalController } from "../../../ui/homeUI/_index";
import LoaderSpinner from "../../loader-spinner/_index";

import axios from "axios";

import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';

import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import { NGUOI_NHAN_VIEC_LIST_PROCESSING } from "../../../apis/_index";
import { ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN } from "../../../redux/reducers/workingReducer/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import { CHANGEPASSWORD } from "../../../apis/_index";

import { toast } from "react-toastify";
import { readFile } from "fs";
import { error } from "console";

import VisibilityIcon from '@mui/icons-material/Visibility';



const ChangePassword = (props: any) => {

    const handleClose = props.handleClose

    const [OLDpassword, setOLDpassword] = useState<string>("");
    const [NEWpassword, setNEWpassword] = useState<string>("");
    const [ConfirmPassword, setConfirmPassword] = useState<string>("");

    const [showOLDpassword, setshowOLDpassword] = useState<string>("password");
    const [showNEWpassword, setshowNEWpassword] = useState<string>("password");
    const [showConfirmPassword, setshowConfirmPassword] = useState<string>("password");

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const data = useSelector((state: any) => state.appReducer);

    const context = useContext(ModalController)

    

    function _OnshowPassword(text: string) {
        switch (text) {
            case "showOLDpassword":
                if (showOLDpassword === "text") {
                    setshowOLDpassword("password")
                }
                else {
                    setshowOLDpassword("text")
                }
                break

            case "showNEWpassword":

                if (showNEWpassword === "text") {
                    setshowNEWpassword("password")
                }
                else {
                    setshowNEWpassword("text")
                }
                break

            case "showConfirmPassword":

                if (showConfirmPassword === "text") {
                    setshowConfirmPassword("password")
                }
                else {
                    setshowConfirmPassword("text")
                }
                break

            default:
                break
        }

    }

    function ConfirmAccount() {
        if (OLDpassword === "" || NEWpassword === "" || ConfirmPassword === "") {
            toast.error("Vui lòng điền đầy đủ thông tin");
        }
        else {
            const accessToken = localStorage.getItem("accessToken")

            const body = {
                UserName: data.userData.UserName,
                Id: data.userData.Id,
                PasswordOld: OLDpassword,
                PasswordNew: NEWpassword,
                ConfirmPasswordNew: ConfirmPassword,
            }

            const takeData = async () => {
                setIsLoading(true)
                try {
                    await axios.post(
                        CHANGEPASSWORD,
                        body,
                        {
                            headers: {
                                Authorization: "Bearer " + accessToken,
                                "Content-Type": "application/json"
                            }
                        }
                    )
                        .then((res: any) => {
                            if (res.data.StatusCode !== 200) {
                                toast.error(res.data.Message)
                                setIsLoading(false)
                            } else {
                                toast.success(res.data.Message)
                                setIsLoading(false)
                                context.handleClose()
                            }
                        })
                }
                catch (error: any) {
                    toast.error("Thay đổi mật khẩu thất bại")
                    setIsLoading(false)
                }

            }

            takeData()
        }
    }

    return (
        <div className="
    flex flex-col bg-white
    lg:w-[34rem] w-screen h-[33em]
    rounded-md
  ">

            {/* header */}
            <div className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      ">
                <p>Thay đổi mật khẩu</p>
                <button
                    className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
                    onClick={handleClose}
                >
                    X
                </button>
            </div>


            {/* content */}
            {
                isLoading === true ?
                    <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4 justify-center items-center ">
                        <LoaderSpinner w={32} h={32} />
                        <p className="text-primary">Đang tải dữ liệu</p>
                    </div>
                    :
                    <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4 justify-center items-center ">
                        {/* lines */}

                        <div className="text-center ">
                            <p className="font-semibold text-black text-xl">
                                Tài khoản đăng nhập:
                                <br />
                                <span className=" bg-slate-200">-- {data.userData.UserName} --</span> </p>
                        </div>
                        
                        {/* Mật khẩu cũ */}
                        <div className="w-full">
                            <p className="font-semibold text-black ">
                                Mật khẩu cũ:</p>
                            <div className="flex flex-row  w-full h-max">
                                <div className="w-[95%] h-[3.7rem]">
                                    <input
                                        className={`w-full h-[2.3rem] rounded-md pl-2 pr-2 border-2 border-slate-200 "bg-slate-100" : ""
                                    }`}
                                        placeholder="Nhập mật khẩu cũ"
                                        type={showOLDpassword}
                                        value={OLDpassword}
                                        onChange={(e: any) => {
                                            e.preventDefault()
                                            setOLDpassword(e.target.value)
                                        }}
                                        onTouchStart={(e: any) => onTouchForm(e)}
                                    />
                                </div>
                                <div className="h-[3.7rem]">
                                    <button
                                        className=" w-full h-[2.3rem] rounded-md pl-2 pr-2 text-sky-500 bg-white hover:text-sky-400"
                                        onClick={() => _OnshowPassword("showOLDpassword")}
                                    ><VisibilityIcon />
                                    </button>
                                </div>
                            </div>
                        </div>
                        

                        {/* Mật khẩu mới*/}
                        <div className="w-full">
                            <p className="font-semibold text-black ">
                                Mật khẩu mới:</p>
                            <div className="flex flex-row  w-full h-max">
                                <div className="w-[95%] h-[3.7rem]">
                                    <input
                                        className={`w-full h-[2.3rem] rounded-md pl-2 pr-2 border-2 border-slate-200 "bg-slate-100" : ""
                                    }`}
                                    placeholder="Mật khẩu mới"
                                    type={showNEWpassword}
                                    value={NEWpassword}
                                    onChange={(e: any) => {
                                        e.preventDefault()
                                        setNEWpassword(e.target.value)
                                    }}
                                    onTouchStart={(e: any) => onTouchForm(e)}
                                    />
                                </div>
                                <div className="h-[3.7rem]">
                                    <button
                                        className=" w-full h-[2.3rem] rounded-md pl-2 pr-2 text-sky-500 bg-white hover:text-sky-400"
                                        onClick={() => _OnshowPassword("showOLDpassword")}
                                    ><VisibilityIcon />
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Xác nhận mật khẩu mới */}
                        <div className="w-full">
                            <p className="font-semibold text-black ">
                                Xác nhận mật khẩu mới:</p>
                            <div className="flex flex-row  w-full h-max">
                                <div className="w-[95%] h-[3.7rem]">
                                    <input
                                        className={`w-full h-[2.3rem] rounded-md pl-2 pr-2 border-2 border-slate-200 "bg-slate-100" : ""
                                    }`}
                                    placeholder="Nhập lại mật khẩu mới"
                                    type={showConfirmPassword}
                                    value={ConfirmPassword}
                                    onChange={(e: any) => {
                                        e.preventDefault()
                                        setConfirmPassword(e.target.value)
                                    }}
                                    onTouchStart={(e: any) => onTouchForm(e)}
                                    />
                                </div>
                                <div className="h-[3.7rem]">
                                    <button
                                        className=" w-full h-[2.3rem] rounded-md pl-2 pr-2 text-sky-500 bg-white hover:text-sky-400"
                                        onClick={() => _OnshowPassword("showOLDpassword")}
                                    ><VisibilityIcon />
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* <div className="w-full">
                            <p className="font-semibold text-black ">
                                Mật khẩu cũ:</p>
                            <table className=" w-[100%] ">
                                <tr>
                                    <td className="w-[90%]">

                                        <input
                                            className="w-full h-[3rem] rounded-md pl-2 pr-2 border-2 border-amber-400 text-amber-400 font-bold"
                                            placeholder="Mật khẩu cũ"
                                            type={showOLDpassword}
                                            value={OLDpassword}
                                            onChange={(e: any) => {
                                                e.preventDefault()
                                                setOLDpassword(e.target.value)
                                            }}
                                        >

                                        </input>
                                    </td>
                                    <td className="w-[10%]">
                                        <button className="flex items-right space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-emerald-500 text-white rounded-md hover:bg-emerald-400"
                                            onClick={() => _OnshowPassword("showOLDpassword")}
                                        ><VisibilityIcon /></button>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className="w-full">
                            <p className="font-semibold text-black">
                                Mật khẩu mới:</p>
                            <table width="100%">
                                <tr>
                                    <td className="w-[90%]">

                                        <input
                                            className="w-full h-[3rem] rounded-md pl-2 pr-2 border-2 border-sky-400 text-sky-400 font-bold"
                                            placeholder="Mật khẩu mới"
                                            type={showNEWpassword}
                                            value={NEWpassword}
                                            onChange={(e: any) => {
                                                e.preventDefault()
                                                setNEWpassword(e.target.value)
                                            }}
                                        >

                                        </input>
                                    </td>
                                    <td className="w-[10%]">
                                        <button className="flex items-right space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-emerald-500 text-white rounded-md hover:bg-emerald-400"
                                            onClick={() => _OnshowPassword("showNEWpassword")}
                                        >

                                            <VisibilityIcon /></button>
                                    </td>
                                </tr>
                            </table>
                        </div>

                        <div className="w-full">
                            <p className="font-semibold text-black">
                                Xác nhận mật khẩu mới:
                            </p>
                            <table width="100%">
                                <tr>
                                    <td className="w-[90%]">

                                        <input
                                            className="w-full h-[3rem] rounded-md pl-2 pr-2 border-2 border-sky-400 text-sky-400 font-bold"
                                            placeholder="Xác nhận mật khẩu mới"
                                            type={showConfirmPassword}
                                            value={ConfirmPassword}
                                            onChange={(e: any) => {
                                                e.preventDefault()
                                                setConfirmPassword(e.target.value)
                                            }}
                                        >

                                        </input>
                                    </td>
                                    <td className="w-[10%]">
                                        <button className="flex items-right space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-emerald-500 text-white rounded-md hover:bg-emerald-400"
                                            onClick={() => _OnshowPassword("showConfirmPassword")}>
                                            <VisibilityIcon /></button>
                                    </td>
                                </tr>
                            </table>

                        </div>*/}
 

                    </div>
            }


            {/* footer */}
            <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-end items-center pl-4 pr-4 text-sm space-x-4">

                <div className="flex w-max h-max space-x-3">
                    <button className={`
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md

        `}

                    >


                    </button>

                    <button className={`
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md

          ${isLoading === true ? "bg-slate-300" : "hover:bg-sky-400 bg-sky-500"}
        `}
                        onClick={ConfirmAccount}
                        disabled={isLoading}
                    >
                        <ExitToAppTwoToneIcon fontSize="small" />
                        <p>Xác nhận</p>
                    </button>

                    <button className="flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
                        onClick={handleClose}
                        disabled={isLoading}
                    >
                        <ExitToAppTwoToneIcon fontSize="small" />
                        <p>Đóng</p>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChangePassword;
