import React, { useState, createContext, memo, useEffect, useRef } from "react";
import ModalBase from "../../components/modal/_index";
import { useSelector } from "react-redux";

import { useDispatch } from "react-redux";

import { ONCHANGE_ALL_NOTI, ONCHANGE_ALL_NOTI2, ONCHANGE_PRIORLIST_DROPDOWN } from "../../redux/reducers/_appReducer/_index";

// ui importing
import HeaderUI from "../shared/headerUI/_index";
import FooterUI from "../shared/footerUI/_index";
import ContentUI from "../shared/contentUI/_index";

import { ONCHANGE_RESET_FILTER, RESET_HIGHMANGER_STORAGE } from "../../redux/reducers/workingReducer/_index";

import { ONSET_RETURN_PAGE_CURRENT } from "../../redux/reducers/workingReducer/_index";
import { LOAD_ALL_NOTI, LOAD_ALL_NOTI_2, PRIOR_LIST_DROPDOWN } from "../../apis/_index";
import { toast } from "react-toastify";
import axios from "axios";

export const ModalController = createContext<any>(null);

const HomeUI = () => {

  const dispatch = useDispatch()
  const urlLink = window.location.pathname
  const data = useSelector((state: any) => state.appReducer);
  // const workingData = useState((state: any) => state.workingReducer)

  const [funcsLoad, setFuncsLoad] = useState<any>();

  const shouldLog = useRef(true)

  // reset filter
  useEffect(() => {
    if (shouldLog.current === true) {
      shouldLog.current = false

      const takeData = async () => {
        const accessToken = localStorage.getItem("accessToken")

        try {
          await axios.get(
            LOAD_ALL_NOTI,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain"
              }
            }
          )
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              dispatch(ONCHANGE_ALL_NOTI(res.data.Data))
            } else {
              toast.error(res.data.Message)
            }
          })
        } catch (error: any) {
          toast.error("Tải thông báo thất bại")
        }
      }

      const takeData2 = async () => {
        const accessToken = localStorage.getItem("accessToken")

        try {
          await axios.get(
            LOAD_ALL_NOTI_2,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain"
              }
            }
          )
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              dispatch(ONCHANGE_ALL_NOTI2(res.data.Data))
            } else {
              toast.error(res.data.Message)
            }
          })
        } catch (error: any) {
          toast.error("Tải thông báo thất bại")
        }
      }

      const takeData3 = async () => {
        dispatch(RESET_HIGHMANGER_STORAGE())
      }

      takeData()
      takeData2()
      takeData3()
    }
  })

  // modal context
  const [funcs, setFuncs] = useState<string>("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const [dataModal, setDataModal] = useState<any>()

  // sys attr
  const [taskId, setTaskId] = useState<any>(null);
  const [maTieuChi, setMaTieuChi] = useState<any>(null);

  // tasklist
  const [taskName, setTaskName] = useState<string>("");
  const [userId, setUserId] = useState<string>("");

  const _onChangeFilterTaskName = (e: any) => {
    setTaskName(e.target.value);
  }

  return (
    <ModalController.Provider
      value={{
        // modal
        funcs, setFuncs,
        open, setOpen,
        handleOpen, handleClose,
        dataModal, setDataModal,
        userId, setUserId,
        funcsLoad, setFuncsLoad,

        // sys attr
        taskId, setTaskId,
        taskName, _onChangeFilterTaskName,
        maTieuChi, setMaTieuChi
      }}
    >
      <div className="flex flex-col h-full w-full">
        {/* modal */}
        <ModalBase
          open={open}
          handleClose={handleClose}
          funcs={funcs}

          userId={userId}
          dataModal={dataModal}
          taskId={taskId}
          maTieuChi={maTieuChi}
        />

        {/* header */}
        <HeaderUI />

        {/* content */}
        <ContentUI />

        {/* footer */}
        <FooterUI />
      </div>
    </ModalController.Provider>
  )
}

export default memo(HomeUI);
