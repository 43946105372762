import React, { memo } from "react";

const FooterUI = () => {
  return (
    <div className="
      flex w-full h-[2.5rem] border-t-2 border-slate-200
      justify-between items-center text-primary
    ">
      <p className="ml-2 lg:flex hidden"> Bản quyền thuộc về LACOTECH - © 2023 LACOGROUP</p>
      <p className="mr-2 lg:flex hidden">Phiên bản: 5.0.0.1</p>
    </div>
  )
}

export default memo(FooterUI);
