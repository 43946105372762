import React, { useEffect, useRef, useState } from "react";

import Title from "../../../../components/title";
import SubmitHeader from "../../../../components/submitHeader";
import ContentTop from "../../../../components/contentTop";

import Chart, { CategoryScale } from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie, Bar } from 'react-chartjs-2';
import { readReport, randomColor } from "../../../../funcs/reportCalling";

import { takePhongban } from "../../../../funcs/reportCalling";

import { REPORT_USERTASKPERCENT } from "../../../../apis/_index";
import moment from "moment";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const UserTaskPercentReportUI = (props: any) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear(); // Lấy năm hiện tại
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Lấy tháng hiện tại (0-11, nên cộng thêm 1)
    const day = currentDate.getDate().toString().padStart(2, '0'); // Lấy ngày hiện tại

    const [data, setData] = useState<any | null>(null);

    const [array_percent_name, setarray_percent_name] = useState<any[] | undefined>(undefined);
    const [array_percent_dung, setarray_percent_dung] = useState<any[] | null>(null);
    const [array_percent_tre, setarray_percent_tre] = useState<any[] | undefined>(undefined);

    const [array_cot_name, setarray_cot_name] = useState<any[] | undefined>(undefined);
    const [array_cot_dung, setarray_cot_dung] = useState<any[] | null>(null);
    const [array_cot_tre, setarray_cot_tre] = useState<any[] | undefined>(undefined);

    const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
    const [selectedPhongban, setSelectedPhongban] = useState<any>(-1)

    const [fromDate, setFromDate] = useState<any | null>(new Date().getFullYear() + "-01-01");
    const [toDate, setToDate] = useState<any | null>(new Date().getFullYear() + "-12-31");

    const shouldLog = useRef(true);

    // first call
    useEffect(() => {
        if (shouldLog.current) {
            shouldLog.current = false

            // const body = {
            //     TuNgay: fromDate,
            //     DenNgay: toDate,
            //     _isCurUser: false
            // }

            // readReport(
            //     REPORT_USERTASKPERCENT,
            //     body,
            //     (res: any) => { setData(res.data.Data) }
            // )

            takePhongban((res: any) => setPhongBanList(res.data.Data))
        }
    })

    // follow first call
    useEffect(() => {
        if (data === null) {

        } else {
            let datatile: any[] = []
            let datacot: any[] = []
            datatile = data.BieuDoTiLe
            datacot = data.BieuDoCot

            let item_percent_name: any[] = []
            let item_percent_dung: any[] = []
            let item_percent_tre: any[] = []

            let item_cot_name: any[] = []
            let item_cot_dung: any[] = []
            let item_cot_tre: any[] = []

            datatile.forEach((item: any) => {
                let temp_item_percent_name = item._recipentName;
                let temp_item_percent_dung = item.TiLeDungHan.toFixed(2);
                let temp_item_percent_tre = item.TiLeTreHan.toFixed(2);
                item_percent_name.push(temp_item_percent_name);
                item_percent_dung.push(temp_item_percent_dung);
                item_percent_tre.push(temp_item_percent_tre);
            })

            datacot.forEach((item: any) => {
                let temp_item_cot_name = item._recipentName;
                let temp_item_cot_dung = item.DungHan;
                let temp_item_cot_tre = item.TreHan;
                item_cot_name.push(temp_item_cot_name);
                item_cot_dung.push(temp_item_cot_dung);
                item_cot_tre.push(temp_item_cot_tre);
            })

            setarray_percent_name(item_percent_name)
            setarray_percent_dung(item_percent_dung)
            setarray_percent_tre(item_percent_tre)

            setarray_cot_name(item_cot_name)
            setarray_cot_dung(item_cot_dung)
            setarray_cot_tre(item_cot_tre)
        }
    }, [data])
    // filter
    const _onClickSubmit = () => {
        let body: any;

            body = {
                TuNgay: fromDate,
                DenNgay: toDate,
                PhongBan: selectedPhongban,
                _isCurUser: false
            }
            
        readReport(
            REPORT_USERTASKPERCENT,
            body,
            (res: any) => { setData(res.data.Data) }
        )

           takePhongban((res: any) => setPhongBanList(res.data.Data))

    }
    return (
        <>

            <br></br>
            <div className="flex lg:flex-row flex-col w-full h-max">
                <div className="flex flex-col w-full lg:h-[4rem] h-max space-y-3">
                    <div className="flex lg:flex-row flex-col w-full h-max">
                        <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
                            <label>Từ ngày</label>
                            <input
                                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                placeholder="Từ Ngày"
                                value={fromDate}
                                type="date"
                                onChange={(e: any) => {
                                    e.preventDefault()
                                    setFromDate(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full lg:h-[4rem] h-max space-y-3">
                    <div className="flex lg:flex-row flex-col w-full h-max">
                        <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
                            <label>Đến ngày</label>
                            <input
                                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                placeholder="Đến Ngày"
                                value={toDate}
                                type="date"
                                onChange={(e: any) => {
                                    e.preventDefault()
                                    setToDate(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
                    <label>Phòng ban</label>
                    <select
                        className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={selectedPhongban}
                        onChange={(e: any) => {
                            e.preventDefault()
                            setSelectedPhongban(parseInt(e.target.value))
                        }}
                    >
                        <option className="text-gray-300" value={-1} disabled>--- Chọn phòng ban ---</option>
                        {
                            phongBanList === null ? <></>
                                : phongBanList.map((item: any, index: any) => (
                                    <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                                ))
                        }
                    </select>
                </div>

                <div className="flex flex-col lg:w-1/2 w-full lg:pr-7 pr-0 h-max" style={{ textAlign: "center" }}>
                    <label>&nbsp; </label>
                    <button className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
            hover:bg-sky-400 active:shadow-inner active:shadow-slate-500
            "
                        onClick={_onClickSubmit}
                    >
                        <p style={{ width: "100%", textAlign: "center" }}>Xem</p>
                    </button>
                </div>
            </div>
            <hr style={{ margin: '20px' }}></hr>

            <div className="flex flex-col w-full h-1/2">
                <div className="flex w-full h-full">
                    <div className="flex flex-col w-full h-full drop-shadow-lg">
                        <div className="w-full h-[1rem] text-xs font-bold flex justify-center items-center">
                            TỈ LỆ ĐÚNG / TRỄ HẠN
                        </div>
                        <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                            <Bar
                                className="w-full h-full"
                                datasetIdKey='id'
                                data={{
                                    labels: array_percent_name,
                                    datasets: [
                                        {
                                            label: 'Đúng hạn',
                                            data: array_percent_dung,
                                            backgroundColor: 'limegreen',
                                            datalabels: {
                                                color: '#e11d48'
                                            }
                                        },
                                        {
                                            label: 'Trễ hạn',
                                            data: array_percent_tre,
                                            backgroundColor: '#dc2626',
                                            datalabels: {
                                                color: 'black'
                                            }
                                        },
                                    ],
                                }}

                                options={{
                                    indexAxis: 'y',
                                    maintainAspectRatio: false,
                                    plugins: {
                                        datalabels: {
                                            labels: {
                                                title: {
                                                    font: {
                                                        weight: 'bold'
                                                    },
                                                },
                                            },

                                            font: {
                                                size: 13,
                                                weight: 'bold',
                                            },

                                            formatter: (value: any) => `${value}%`,
                                            anchor: "center",
                                            align: "center",
                                        },
                                        legend: {
                                            labels: {
                                                color: 'black'
                                            }
                                        }
                                    },
                                    scales: {
                                        x: {
                                            stacked: true,
                                            ticks: {
                                                maxTicksLimit: 6,
                                            }
                                        },
                                        y: {
                                            stacked: true,
                                            ticks: {
                                            },
                                            beginAtZero: true
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>


            <hr></hr>
            <div className="flex flex-col w-full h-1/2 pt-10">
                <div className="flex flex-col w-full h-[calc(100%+5rem)] space-y-12 shadow-md pl-5">
                    <div className="w-full h-max">
                        <div className="flex lg:flex-row flex-col w-full h-[calc(100vh-8rem)]">


                            <div className="flex flex-col w-full h-full ">
                                <div className="flex w-full h-1/2">
                                    <div className="flex flex-col w-full h-full drop-shadow-lg">
                                        <div className="w-full h-[1rem] text-xs font-bold flex justify-center items-center">
                                            HOÀN THÀNH NHƯNG TRỄ HẠN
                                        </div>
                                        <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                                            <Bar
                                                className="w-full h-full"
                                                datasetIdKey='id'
                                                data={{
                                                    labels: array_cot_name,
                                                    datasets: [

                                                        {
                                                            label: 'Chưa hoàn thành',
                                                            data: array_cot_tre,
                                                            backgroundColor: [
                                                                '#dc2626',
                                                            ]
                                                        },
                                                    ]
                                                }}
                                                options={{
                                                    maintainAspectRatio: false,
                                                    plugins: {
                                                        datalabels: {
                                                            color: '#e11d48',
                                                            labels: {
                                                                title: {

                                                                    font: {
                                                                        weight: 'bold'
                                                                    }
                                                                },
                                                                value: {
                                                                    color: 'white'
                                                                }
                                                            },

                                                            font: {
                                                                size: 13,
                                                                weight: 'bold',
                                                            },

                                                            formatter: (value: any) => `${value}`,
                                                            anchor: "start",
                                                            align: "top",
                                                        },
                                                        legend: {
                                                            display: false
                                                        },
                                                    },
                                                    scales: {
                                                        x: {
                                                            stacked: true,
                                                            ticks: {
                                                                maxTicksLimit: 6,
                                                            }
                                                        },
                                                        y: {
                                                            stacked: true,
                                                            ticks: {
                                                            },
                                                            beginAtZero: true
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col w-full h-1/2">
                                <div className="flex w-full h-full">
                                    <div className="flex flex-col w-full h-full drop-shadow-lg">
                                        <div className="w-full h-[1rem] text-xs font-bold flex justify-center items-center">
                                            HOÀN THÀNH ĐÚNG HẠN
                                        </div>
                                        <div className="flex justify-center items-center w-full h-[calc(100%-1rem)]">
                                            <Bar
                                                className="w-full h-full"
                                                datasetIdKey='id'
                                                data={{
                                                    labels: array_cot_name,
                                                    datasets: [
                                                        {
                                                            label: 'Hoàn thành',
                                                            data: array_cot_dung,
                                                            backgroundColor: [
                                                                'limegreen',
                                                            ]
                                                        },
                                                    ]
                                                }}
                                                options={{
                                                    maintainAspectRatio: false,
                                                    plugins: {

                                                        datalabels: {
                                                            color: '#e11d48',
                                                            labels: {
                                                                title: {
                                                                    color: 'red',
                                                                    font: {
                                                                        weight: 'bold'
                                                                    }
                                                                },
                                                                value: {
                                                                    color: '#f43f5e'
                                                                }
                                                            },

                                                            font: {
                                                                size: 13,
                                                                weight: 'bold',
                                                            },

                                                            formatter: (value: any) => `${value}`,
                                                            anchor: "end",
                                                            align: "start",
                                                        },

                                                        legend: {
                                                            display: false
                                                        },
                                                    },
                                                    scales: {
                                                        x: {
                                                            ticks: {
                                                                maxTicksLimit: 6,
                                                            }
                                                        },
                                                        y: {
                                                            ticks: {

                                                            },
                                                            beginAtZero: true
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserTaskPercentReportUI;
