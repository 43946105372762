// import React, { useEffect } from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// // redux importing
// import store from './redux/store/_index';
// import { Provider } from 'react-redux';


// // const root = ReactDOM.createRoot(
// //   document.getElementById('root') as HTMLElement
// // );


// //HUB

// // const userData_: any = localStorage.getItem("userData");
// // const token: any = localStorage.getItem("accessToken")

// // if (userData_ && token) {
// //   Start({ users: userData_, token: token });
// //   Notification();
// // }

// //---

// ReactDOM.render(
//   // <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   // </React.StrictMode>
//   ,document.getElementById('root') as HTMLElement
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store/_index';

ReactDOM.render(
  // <React.StrictMode>
  <React.Fragment>
    <Provider store={store}>
      <App />
    </Provider>
  </React.Fragment>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

reportWebVitals();

