import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import SubModal from "../_subModal/_index";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

import { ModalController } from "../../../ui/homeUI/_index";
import { toast } from "react-toastify";
import axios from "axios";

import { PROPOSE_DETAIL } from "../../../apis/_index";
import moment from "moment";

const ProposeDetail = (props: any) => {
  // take props
  const handleClose = props.handleClose;
  const taskId = props.taskId;
  const id = props.id;

  // state

  const urlLink = window.location.pathname;

  // submodal
  const [openSub, setOpenSub] = useState(false);
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);
  const [funcsSub, setFuncsSub] = useState<string>("");

  const [isForce, setIsForce] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<any>(null);
  const data = useSelector((state: any) => state.appReducer);
  const context = useContext(ModalController);

  const _onChangeIsForce = () => {
    setIsForce((item: any) => !item);
  };

  // load detail
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const API = PROPOSE_DETAIL(taskId);

    const takeData = async () => {
      try {
        await axios
          .get(API, {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain",
            },
          })
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              setDataDetail(res.data.Data[0]);
              // console.log("res.data", res.data.Data[0]);
            } else {
              toast.error(res.Message);
            }
          });
      } catch (error: any) {
        toast.error(error);
      }
    };

    takeData();
  }, [taskId]);

  // onClick confirm
  const _onClickConfirm = (id: string) => {
    context.handleOpen();
    context.setFuncs("proposeConfirm");
  };
  const onOpenSub = (text: string, id: String) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  // onClick unConfirm
  const _onClickUnConfirm = (id: string) => {
    context.handleOpen();
    context.setFuncs("proposeUnConfirm");
  };

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[40rem] md:w-[40rem] w-screen h-max
      rounded-md
    "
    >
      <SubModal taskId={taskId} open={openSub} handleClose={handleCloseSub} />

      {/* header */}
      <div
        className="
        flex lg:w-[40rem] md:w-[40rem] w-screen h-[3rem] bg-sky-500 rounded-t-md
        px-4 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Chi tiết đề xuất</p>
        <button
          className="hover:bg-slate-300 px-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>
      <>
        {dataDetail === null ? (
          <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars"></div>
        ) : (
          <div className="flex flex-col w-full items-center text-sm p-2 overflow-auto styled-scrollbars">
            <div className="w-full h-max flex font-semibold items-center rounded-sm">
              <p className="font-semibold text-black bg-slate-200 px-1 pt-1.5 pb-1.5 w-max rounded-sm shadow-sm">
                Mã đề xuất - {dataDetail.ProposeCode}
              </p>
            </div>

            <div className="w-full h-max font-semibold items-center rounded-sm">
              <p className="p-1">
                Tên đề xuất -
                <span className="w-full h-[1.9rem] rounded-md px-2">
                  {dataDetail.Name}
                </span>
              </p>
            </div>

            <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
              <div className="w-full h-max flex flex-col p-1">
                <p>Loại đề xuất</p>
                <input
                  className="w-full h-[1.9rem] item-center rounded-md border-2 pl-2 border-slate-200"
                  value={dataDetail.ProposeTypeID}
                  disabled
                />
              </div>
              <div className="w-full h-max flex flex-col p-1">
                <p className="">Mức độ</p>
                <input
                  className="w-full h-[1.9rem] rounded-md px-2 border-2 border-slate-200"
                  value={dataDetail.PriorityLevelName}
                  disabled
                />
              </div>
            </div>
            <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
              <div className="w-full h-max flex flex-col p-1">
                <p>Người đề xuất</p>
                <input
                  className="w-full h-[1.9rem] rounded-md border-2 pl-2"
                  value={dataDetail.UserAddName}
                  disabled
                />
              </div>
              <div className="w-full h-max flex flex-col p-1">
                <p>Người nhận</p>
                <input
                  className="w-full h-[1.9rem] rounded-md px-2 border-2"
                  value={dataDetail.UserCheckName}
                  disabled
                />
              </div>
            </div>
            {dataDetail.ListNamePropose && (
              <div className="w-full h-max flex flex-col p-1">
                <p>Người hỗ trợ</p>
                <textarea
                  className="w-full h-[1.9rem] rounded-md border-2 items-center p-1"
                  value={dataDetail.ListNamePropose}
                  disabled
                />
              </div>
            )}

            {dataDetail.IsUseTime === true ? (
              <div className="w-full h-max flex lg:flex-row md:flex-row flex-col">
                <div className="w-full h-max flex flex-col p-1">
                  <p className="">Ngày bắt đầu</p>
                  <input
                    className="w-full h-[1.9rem] rounded-md border-2 pl-2"
                    value={moment(dataDetail.StartTime).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )}
                    disabled
                  />
                </div>
                <div className="w-full h-max flex flex-col p-1">
                  <p className="">Ngày kết thúc</p>
                  <input
                    className="w-full h-[1.9rem] rounded-md px-2 border-2"
                    value={moment(dataDetail.EndTime).format(
                      "DD/MM/YYYY hh:mm:ss"
                    )}
                    disabled
                  />
                </div>
              </div>
            ) : (
              <div className="w-full h-max flex font-bold flex-col p-1">
                <p className="">Đề xuất không có thời hạn</p>
              </div>
            )}

            <div className="w-full h-max flex flex-col p-1">
              <p className="">Nội dung</p>
              <textarea
                className="w-full h-[3rem] rounded-md pt-2 px-2 border-2"
                value={dataDetail.Content}
                disabled
              />
            </div>
            {dataDetail.Status !== 0 ? (
              <div className="danh-gia w-full h-max flex flex-row p-1">
                <p className="mb-2">Đánh giá</p>
                <div>
                  <Box
                    sx={{
                      "& > legend": { mt: 2 },
                    }}
                  >
                    <Rating
                      name="simple-controlled"
                      value={dataDetail.Star}
                      readOnly={dataDetail.Status}
                    />
                  </Box>
                </div>
              </div>
            ) : null}
            {dataDetail.Reason !== null ? (
              <div className="w-full h-max flex flex-col p-1">
                <p className="">Lý do</p>
                <textarea
                  className="w-full h-[3rem] rounded-md pt-2 px-2 border-2"
                  value={dataDetail.Reason}
                  disabled
                />
              </div>
            ) : null}
          </div>
        )}
      </>

      {/* footer */}
      <div
        className={`flex w-full h-[3rem] bg-slate-100 rounded-b-md ${
          urlLink === "/working/receiver/task-new" && isForce === false
            ? "justify-end"
            : "justify-end"
        }  items-center pl-4 pr-4 text-sm space-x-4`}
      >
        {urlLink !== "/propose/sender/list" && dataDetail?.Status === 0 ? (
          <>
            <button
              className="
          flex items-center space-x-1 w-max h-max lg:px-2 pt-1 pb-1 bg-red-500 text-white rounded-md
          hover:bg-red-700
        "
              onClick={() => _onClickUnConfirm(dataDetail.ID)}
            >
              <CloseIcon fontSize="small" />
              <p>Không duyệt</p>
            </button>
            <button
              className="
          flex items-center space-x-1 w-max h-max px-2 pt-1 pb-1 bg-green-500 text-white rounded-md
          hover:bg-green-700
        "
              onClick={() => _onClickConfirm(dataDetail.ID)}
            >
              <CheckIcon fontSize="small" />
              <p>Duyệt</p>
            </button>
          </>
        ) : null}

        <button
          className="
          flex items-center space-x-1 w-max h-max px-2 pt-1 pb-1 bg-slate-400 text-white rounded-md
          hover:bg-slate-500
        "
          onClick={handleClose}
        >
          <ExitToAppTwoToneIcon fontSize="small" />
          <p>Đóng</p>
        </button>
      </div>
    </div>
  );
};

export default ProposeDetail;
