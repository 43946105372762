import React from "react";

import { onTouchForm } from "../funcs/onTochForm";
import { useSelector } from "react-redux";

const Title = () => {
  const urlLink = window.location.pathname;

  // jsx
  const WorkingTaskReturnJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Danh
        sách công việc mới &nbsp; */}
        Việc đã giao đang xử lý bị trả lại
      </div>
    );
  };

  const WorkingTaskTotalReceiverJSX = (props: any) => {

    const currentState = window.history.state;

    const data = useSelector((state: any) => state.workingReducer)

    console.log(data.searchFilter.result)

    console.log("something you know")

    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Danh
        sách công việc mới &nbsp; */}
        {
          currentState.title === null ||  currentState.title === undefined ? "Tổng hợp công việc đã giao" : currentState.title
        }
      </div>
    );
  };

  const WorkingTaskReturnReceiverJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Danh
        sách công việc mới &nbsp; */}
        Việc đang xử lý bị trả lại
      </div>
    );
  };

  const WorkingTaskWaitingJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Danh
        sách công việc mới &nbsp; */}
        Công việc chờ duyệt
      </div>
    );
  };

  const WorkingTaskLateJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Danh
        sách công việc mới &nbsp; */}
        việc hoàn tất trễ hạn
      </div>
    );
  };

  const WorkingTaskEarlyJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Danh
        sách công việc mới &nbsp; */}
        việc hoàn tất sớm
      </div>
    );
  };

  const WorkingTaskPunctualJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Danh
        sách công việc mới &nbsp; */}
        việc hoàn tất đúng thời hạn
      </div>
    );
  };

  const WorkingNewTaskJSX = () => {
    return (
      <div className="uppercase text-primary lg:pl-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Danh
        sách công việc mới &nbsp; */}
        Công việc mới chưa giao
      </div>
    );
  };

  const WorkingTaskListAllJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Danh
        sách công việc mới &nbsp; */}
        &gt;&nbsp;Thống kê số liệu năng lực nhân sự
      </div>
    );
  };

  const WorkingFinishEarly = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Danh
        sách công việc mới &nbsp; */}
        Việc đã giao hoàn tất sớm
      </div>
    );
  };

  const WorkingFinishLate = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Danh
        sách công việc mới &nbsp; */}
        Việc đã giao hoàn tất trễ hạn
      </div>
    );
  };

  const WorkingFinishPunctual = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Danh
        sách công việc mới &nbsp; */}
        Việc đã giao hoàn tất đúng tiến độ
      </div>
    );
  };

  const AllPageJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Trang chủ
      </div>
    );
  };

  const WorkingManagementWorkflowJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Quản lý
        quy trình &nbsp; */}
        Quản lý quy trình
      </div>
    );
  };

  const WorkingTaskNeedGiveJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Công
        việc cần giao &nbsp; */}
        &gt;&nbsp; Công việc cần giao
      </div>
    );
  };

  const WorkingTaskFinishJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Việc
        hoàn tất đang chờ duyệt &nbsp; */}
        Việc hoàn tất đang chờ duyệt
      </div>
    );
  };

  const WorkingTaskDelayJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Việc
        đang bị trễ hạn &nbsp; */}
        Việc đã giao đang xử lý trễ hạn
      </div>
    );
  };

  const WorkingTaskChangeDeadlineJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Việc
        đang bị trễ hạn &nbsp; */}
        Việc đang xin gia hạn
      </div>
    );
  };

  const WorkingTaskProccessingJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Việc đã
        giao đang xử lý &nbsp; */}
        Việc đã giao đang xử lý trong tiến độ
      </div>
    );
  };

  const WorkingTaskEndSoonJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Việc sắp
        hết hạn &nbsp; */}
        &gt;&nbsp;Việc sắp hết hạn
      </div>
    );
  };

  const WorkingTaskEndTodayJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Việc tới
        hạn hôm nay &nbsp; */}
        &gt;&nbsp;Việc tới hạn hôm nay
      </div>
    );
  };

  const WorkingTaskTotalJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Danh
        sách công việc đã giao &nbsp; */}
        Tổng hợp việc toàn công ty
      </div>
    );
  };

  // receiver
  const WorkingTaskNewReceiverJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người nhận việc &nbsp; &gt; &nbsp; Công
        việc mới &nbsp; */}
        Công việc mới được giao
      </div>
    );
  };

  const WorkingTaskReceivedReceiverJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người nhận việc &nbsp; &gt; &nbsp; Công
        việc đã nhận &nbsp; */}
        Công việc chưa xử lý
      </div>
    );
  };

  const WorkingTaskProcessingReceiverJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người nhận việc &nbsp; &gt; &nbsp; Công
        việc đang xử lý &nbsp; */}
        Việc đang xử lý trong tiến độ
      </div>
    );
  };

  const WorkingTaskDelayReceiverJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người nhận việc &nbsp; &gt; &nbsp; Công
        việc trễ hạn &nbsp; */}
        Việc đang xử lý trễ hạn
      </div>
    );
  };

  const WorkingTaskEndSoonReceiverJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người nhận việc &nbsp; &gt; &nbsp; Công sắp
        hết hạn &nbsp; */}
        &gt;&nbsp;Công sắp hết hạn
      </div>
    );
  };

  const WorkingTaskAllTaskReceiverJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người nhận việc &nbsp; &gt; &nbsp; Tổng hợp
        công việc &nbsp; */}
        Tổng hợp công việc đã nhận
      </div>
    );
  };

  const VotingWait = () => {
    return (
      <div className="uppercase text-primary lg:pl-3 items-center text-center flex font-bold w-full h-[3rem]">
        Việc đang yêu cầu biểu quyết
      </div>
    );
  };

  const VotingWork = () => {
    return (
      <div className="uppercase text-primary lg:pl-3 items-center text-center flex font-bold w-full h-[3rem]">
        Việc cần tham gia biểu quyết
      </div>
    );
  };

  const WorkingTaskEndTodayReceiverJSX = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        {/* Công việc &nbsp; &gt; &nbsp; Người nhận việc &nbsp; &gt; &nbsp; Công
        việc tới hạn hôm nay &nbsp; */}
        &gt;&nbsp;Công việc tới hạn hôm nay
      </div>
    );
  };

  // propose

  const ProposeSend = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Đề xuất &nbsp; &gt; &nbsp; Đề xuất gửi &nbsp; &gt; &nbsp;
      </div>
    );
  };

  const ProposeGet = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Đề xuất &nbsp; &gt; &nbsp; Đề xuất nhận &nbsp; &gt; &nbsp;
      </div>
    );
  };

  // report
  const ReportDeliverTaskGenerald = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Báo cáo &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Tổng quan
        công việc &nbsp;
      </div>
    );
  };

  const ReportDeliverWorkerCapacity = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Báo cáo &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Năng lực
        nhân sự &nbsp;
      </div>
    );
  };

  const ReportDetailWorkerCapacity = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Báo cáo &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Chi tiết
        năng lực nhân sự &nbsp;
      </div>
    );
  };

  const ReportIndividualCapacityUI = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Báo cáo &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Năng lực
        cá nhân &nbsp;
      </div>
    );
  };

  const TableReportCapacityUI = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Báo cáo &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Bảng đánh
        giá năng lực &nbsp;
      </div>
    );
  };

  const ReportMissionProcessing = () => {
    return (
      <div className="pl-5 pr-5 flex font-semibold w-full h-[3rem] text-sm justify-between">
        <p>
          Báo cáo &nbsp; &gt; &nbsp; Người giao việc &nbsp; &gt; &nbsp; Tình
          hình thực hiện nhiệm vụ &nbsp;
        </p>

        <div className="flex space-x-5">
          <div className="flex flex-col">
            <label>Từ ngày</label>
            <input
              className="w-max h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              placeholder="Nhập tên công việc"
              type="date"
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>

          <div className="flex flex-col">
            <label>Đến ngày ngày</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              placeholder="Nhập tên công việc"
              type="date"
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>
      </div>
    );
  };

  // setting
  const SettingListOfEmployee = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Thiết lập &nbsp; &gt; &nbsp; Danh mục &nbsp; &gt; &nbsp; Danh mục cán bộ
        &nbsp;
      </div>
    );
  };

  const SettingDifficultRate = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Thiết lập &nbsp; &gt; &nbsp; Danh mục &nbsp; &gt; &nbsp; Độ khó &nbsp;
      </div>
    );
  };

  const SettingListOfTask = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Thiết lập &nbsp; &gt; &nbsp; Danh mục &nbsp; &gt; &nbsp; Danh mục công
        việc &nbsp;
      </div>
    );
  };

  const SettingListOfGeneral = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Thiết lập &nbsp; &gt; &nbsp; Danh mục &nbsp; &gt; &nbsp; Danh mục chung
        &nbsp;
      </div>
    );
  };

  const SettingPriorRate = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Thiết lập &nbsp; &gt; &nbsp; Danh mục &nbsp; &gt; &nbsp; Mức độ công
        việc &nbsp;
      </div>
    );
  };

  const SettingTargetSroceCapacity = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Thiết lập &nbsp; &gt; &nbsp; Danh mục &nbsp; &gt; &nbsp; Tiêu chí đánh
        giá năng lực &nbsp;
      </div>
    );
  };

  const SettingScoreCapacity = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Thiết lập &nbsp; &gt; &nbsp; Danh mục &nbsp; &gt; &nbsp; Bảng điểm đánh
        giá năng lực &nbsp;
      </div>
    );
  };

  const SettingEmailAutomatic = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Thiết lập &nbsp; &gt; &nbsp; Tự chọn &nbsp; &gt; &nbsp; Email tự động
        &nbsp;
      </div>
    );
  };

  const SettingOverload = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Thiết lập &nbsp; &gt; &nbsp; Tự chọn &nbsp; &gt; &nbsp; Thiết lập cảnh
        báo quá tải &nbsp;
      </div>
    );
  };

  const SettingAlertTasking = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Thiết lập &nbsp; &gt; &nbsp; Tự chọn &nbsp; &gt; &nbsp; Thiết lập nhắc
        nhở công việc &nbsp;
      </div>
    );
  };

  const SettingSecurityLevel = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Thiết lập &nbsp; &gt; &nbsp; Danh mục &nbsp; &gt; &nbsp; Độ mật &nbsp;
      </div>
    );
  };

  // user
  const UserList = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Phân quyền &nbsp; &gt; &nbsp; Danh sách quyền hạn
      </div>
    );
  };

  const UserFunctionalList = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Phân quyền &nbsp; &gt; &nbsp; Chức năng
      </div>
    );
  };

  const UserScreenList = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Phân quyền &nbsp; &gt; &nbsp; Màn hình
      </div>
    );
  };

  const UserWorkCategoryList = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Phân quyền &nbsp; &gt; &nbsp; Đầu mục công việc
      </div>
    );
  };

  const UserDataOption = () => {
    return (
      <div className="uppercase text-primary p-3 items-center text-center flex font-bold w-full h-[3rem]">
        Phân quyền &nbsp; &gt; &nbsp; Xem dữ liệu
      </div>
    );
  };

  return (
    <>
      {urlLink === "/working/new-task" ? (
        <WorkingNewTaskJSX />
      ) 
      : urlLink === "/working/task-totalall" ? (
        <WorkingTaskTotalReceiverJSX
        />


      ) : urlLink === "/working/receiver/task-waiting" ? (
        <WorkingTaskWaitingJSX />
      ) : urlLink === "/working/receiver/task-returnwork" ? (
        <WorkingTaskReturnReceiverJSX />
      ) : urlLink === "/working/task-need-give" ? (
        <WorkingTaskNeedGiveJSX />
      ) : urlLink === "/working/task-return" ? (
        <WorkingTaskReturnJSX />
      ) : urlLink === "/working/task-finish" ? (
        <WorkingTaskFinishJSX />
      ) : urlLink === "/working/task-delay" ? (
        <WorkingTaskDelayJSX />
      ) : urlLink === "/working/task-change-deadline" ? (
        <WorkingTaskChangeDeadlineJSX />
      ) : urlLink === "/working/task-proccessing" ? (
        <WorkingTaskProccessingJSX />
      ) : urlLink === "/working/task-end-soon" ? (
        <WorkingTaskEndSoonJSX />
      ) : urlLink === "/working/task-end-today" ? (
        <WorkingTaskEndTodayJSX />
      ) : urlLink === "/working/task-total" ? (
        <WorkingTaskTotalJSX />
      ) : urlLink === "/working/receiver/task-new" ? (
        <WorkingTaskNewReceiverJSX />
      ) : urlLink === "/working/receiver/task-delay" ? (
        <WorkingTaskDelayReceiverJSX />
      ) : urlLink === "/working/receiver/task-end-soon" ? (
        <WorkingTaskEndSoonReceiverJSX />
      ) : urlLink === "/working/receiver/task-end-today" ? (
        <WorkingTaskEndTodayReceiverJSX />
      ) : urlLink === "/working/receiver/task-processing" ? (
        <WorkingTaskProcessingReceiverJSX />
      ) : urlLink === "/working/receiver/task-received" ? (
        <WorkingTaskReceivedReceiverJSX />
      ) : urlLink === "/working/receiver/all-task" ? (
        <WorkingTaskAllTaskReceiverJSX />
      ) : urlLink === "/voting/voting_work" ? (
        <VotingWork />
      ) : urlLink === "/voting/voting_wait" ? (
        <VotingWait />
      ) : urlLink === "/working/management-workflow" ? (
        <WorkingManagementWorkflowJSX />
      ) : urlLink === "/propose/sender/list" ? (
        <ProposeSend />
      ) : urlLink === "/propose/proposeGet" ? (
        <ProposeGet />
      ) : urlLink === "/report/deliver/mission-processing" ? (
        <ReportMissionProcessing />
      ) : urlLink === "/report/deliver/task-general-deliver" ? (
        <ReportDeliverTaskGenerald />
      ) : urlLink === "/report/deliver/worker-capacity" ? (
        <ReportDeliverWorkerCapacity />
      ) : urlLink === "/report/deliver/detail-worker-capacity" ? (
        <ReportDetailWorkerCapacity />
      ) : urlLink === "/report/receiver/individual-capacity" ? (
        <ReportIndividualCapacityUI />
      ) : urlLink === "/report/receiver/table-report-capacity" ? (
        <TableReportCapacityUI />
      ) : urlLink === "/setting/listof-category/list-of-employee" ? (
        <SettingListOfEmployee />
      ) : urlLink === "/setting/listof-category/list-of-task" ? (
        <SettingListOfTask />
      ) : urlLink === "/setting/listof-category/list-of-general" ? (
        <SettingListOfGeneral />
      ) : urlLink === "/setting/listof-category/prior-rate" ? (
        <SettingPriorRate />
      ) : urlLink === "/setting/listof-category/score-rating-capacity" ? (
        <SettingScoreCapacity />
      ) : urlLink === "/setting/listof-category/target-rating-capacity" ? (
        <SettingTargetSroceCapacity />
      ) : urlLink === "/setting/setting/alert-tasking" ? (
        <SettingAlertTasking />
      ) : urlLink === "/setting/setting/email-automatic" ? (
        <SettingEmailAutomatic />
      ) : urlLink === "/setting/setting/overload-setting" ? (
        <SettingOverload />
      ) : urlLink === "/setting/listof-category/security-rate" ? (
        <SettingSecurityLevel />
      ) : urlLink === "/setting/listof-category/difficult-rate" ? (
        <SettingDifficultRate />
      ) : urlLink === "/user/user-list" ? (
        <UserList />
      ) : urlLink === "/user/functional-list" ? (
        <UserFunctionalList />
      ) : urlLink === "/user/screen-list" ? (
        <UserScreenList />
      ) : urlLink === "/user/work-category-list" ? (
        <UserWorkCategoryList />
      ) : urlLink === "/user/data-option" ? (
        <UserDataOption />
      ) : urlLink === "/working/task-finish-early" ? (
        <WorkingFinishEarly />
      ) : urlLink === "/working/task-finish-late" ? (
        <WorkingFinishLate />
      ) : urlLink === "/working/task-finish-punctual" ? (
        <WorkingFinishPunctual />
      ) : urlLink === "/working/task-listall" ? (
        <WorkingTaskListAllJSX />
      ) : urlLink === "/working/receiver/task-finish_late" ? (
        <WorkingTaskLateJSX />
      ) : urlLink === "/working/receiver/task-finish_early" ? (
        <WorkingTaskEarlyJSX />
      ) : urlLink === "/working/receiver/task-finish_puntual" ? (
        <WorkingTaskPunctualJSX />
      ) : urlLink === "/allPage" ? (
        <AllPageJSX />
      ) : (
        ""
      )}
    </>
  );
};

export default Title;
