import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import {
  PROPOSESENDLIST,
  PROPOSE_TYPE_LIST,
  PRIOR_LIST_DROPDOWN,
  USER_READ_ALL_DROPDOWN,
  LOAD_SEP,
} from "../../../apis/_index";
import { ONLOAD_PROPOSE_GUI_LIST } from "../../../../src/redux/reducers/proposeReducer/_index";
import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";

import MobileDeliverPropose from "./mobileDeliverPropose";
import FilterProposeWeb from "./filterProposeWeb";
import FilterProposeMobile from "./filterProposeMobile";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import Pagination from "@mui/material/Pagination";

import { ModalController } from "../../homeUI/_index";
import LoaderSpinner from "../../../components/loader-spinner/_index";
import { current } from "@reduxjs/toolkit";

const ProposeDeliverListUI = () => {
  _onClickOther();

  const shouldLog = useRef(true);
  const dispatch = useDispatch();
  const [data, setData] = useState<any[] | null>(null);
  const [openSub, setOpenSub] = useState(false);
  const handleCloseSub = () => setOpenSub(false);
  const handleOpenSub = () => setOpenSub(true);
  const [funcsSub, setFuncsSub] = useState<string>("");

  const context = useContext(ModalController);

  // for pagination
  const [page, setPage] = useState(0);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const [name, setName] = useState<string>("");
  const [priorId, setPriorId] = useState<number>(0);
  const [userAdd, setUserAdd] = useState<string>("");
  const [userCheck, setUserCheck] = useState<string>("");
  const [dateAdd_From, setDateAdd_From] = useState<string>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 7, 0, 0)
      .toISOString()
      .slice(0, 16)
  );
  const [dateAdd_To, setDateAdd_To] = useState<string>(
    // new Date().toISOString().slice(0, 10)
    new Date(new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000)
      .toISOString()
      .slice(0, 16)
  );

  const [proposeTypeID, setProposeTypeID] = useState<string>("");

  const statusList = [
    { Id: "0", value: "Chưa duyệt", label: "Chưa duyệt" },
    { Id: "1", value: "Đã duyệt", label: "Đã duyệt" },
    { Id: "2", value: "Không duyệt", label: "Không duyệt" },
  ];
  const [status, setStatus] = useState<string>("");

  const [proposeTypeList, setProposeTypeList] = useState<any[] | null>(null);
  const [priorList, setPriorList] = useState<any[] | null>(null);
  const [senderList, setSenderList] = useState<any[] | null>(null);
  const [receiverList, setReceiverList] = useState<any[] | null>(null);

  // load all data
  const shouldLogLoadData = useRef(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLogLoadData.current === true) {
      shouldLogLoadData.current = false;

      const takeProposeTypeList = async () => {
        try {
          await axios
            .get(PROPOSE_TYPE_LIST, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setProposeTypeList(res.data.Data);
              }
            });
        } catch (error) {
          toast.error("Tải dữ liệu thất bại");
        }
      };

      const takePriorList = async () => {
        try {
          await axios
            .get(PRIOR_LIST_DROPDOWN, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setPriorList(res.data.Data);
              }
            });
        } catch (error) {
          toast.error("Tải dữ liệu thất bại");
        }
      };

      const takeUserList = async () => {
        try {
          await axios
            .get(USER_READ_ALL_DROPDOWN, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setSenderList(res.data.Data);
              }
            });
        } catch (error) {
          toast.error("Tải dữ liệu thất bại");
        }
      };

      const takeBossList = async () => {
        try {
          await axios
            .get(LOAD_SEP, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setReceiverList(res.data.Data);
              }
            });
        } catch (error) {
          toast.error("Tải dữ liệu thất bại");
        }
      };

      takeProposeTypeList();
      takePriorList();
      takeUserList();
      takeBossList();
    }
  }, []);

  const _onChangeFilter = (type: string, e: any) => {
    switch (type) {
      case "name":
        setName(e.target.value);
        break;

      case "status":
        setPriorId(e.target.value);
        break;

      case "userAdd":
        setUserAdd(e.target.value);
        break;

      case "userCheck":
        setUserCheck(e.target.value);
        break;

      case "dateAdd_From":
        setDateAdd_From(e.target.value);
        break;

      case "setDateAdd_To":
        setDateAdd_To(e.target.value);
        break;

      case "proposeType":
        setProposeTypeID(e.target.value);
        break;

      default:
        break;
    }
  };

  // onClick detail
  const _onClickDetail = (id: string) => {
    context.setFuncs("proposeDetail");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _loadData = () => {
    const accessToken = localStorage.getItem("accessToken");

    let body = {};

    if (priorId === 0) {
      body = {
        Page: page,
        Limit: limitPerPage,
        Name: name,
        UserAdd: userAdd,
        UserCheck: userCheck,
        DateAdd_From: dateAdd_From,
        DateAdd_To: dateAdd_To,
        ProposeTypeID: proposeTypeID,
      };
    } else {
      body = {
        Page: page,
        Limit: limitPerPage,
        Name: name,
        PriorityLevelID: priorId,
        UserAdd: userAdd,
        UserCheck: userCheck,
        DateAdd_From: dateAdd_From,
        DateAdd_To: dateAdd_To,
        ProposeTypeID: proposeTypeID,
      };
    }

    const takeData = async () => {
      try {
        await axios
          .post(PROPOSESENDLIST, body, {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain",
            },
          })
          .then((res: any) => {
            if (((res || {}).data || {}).StatusCode === 200) {
              setData((((res || {}).data || {}).Data || {}).data || []);
              setTotalItems((((res || {}).data || {}).Data || {}).count);
            } else {
              toast.error(res.data.Message || "Tải dữ liệu thất bại.");
            }
          });
      } catch (error: any) {
        toast.error(error);
      }
    };
    takeData();
  };

  const _loadDataStart = () => {
    const accessToken = localStorage.getItem("accessToken");
    let body = {
      Page: page,
      Limit: limitPerPage,
    };

    if (shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await axios
            .post(PROPOSESENDLIST, body, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (((res || {}).data || {}).StatusCode === 200) {
                // dispatch(ONLOAD_PROPOSE_GUI_LIST(res.data.Data));
                setData((((res || {}).data || {}).Data || {}).data || []);
                setTotalItems((((res || {}).data || {}).Data || {}).count);
              } else {
                toast.error(res.data.Message || "Tải dữ liệu thất bại.");
              }
            });
        } catch (error: any) {
          toast.error(error);
        }
      };
      takeData();
    }
  };

  const _handleCheckedColor = (status: any) => {
    if (status === 2) {
      return "#ff0000";
    } else if (status === 1) {
      return "#00ff00";
    } else {
      return "#FFAA00";
    }
  };

  const _handleCheckedStatus = (status: any) => {
    if (status === 2) {
      return "Không duyệt";
    } else if (status === 1) {
      return "Đã duyệt";
    } else {
      return "Chưa duyệt";
    }
  };

  // start loading
  useEffect(() => {
    _loadDataStart();
  });

  // calc total page
  useEffect(() => {
    if (totalItems === 0) {
      // do nothing
    } else {
      if (totalItems % limitPerPage === 0) {
        setTotalPage(totalItems / limitPerPage);
      } else {
        setTotalPage(Math.floor(totalItems / limitPerPage) + 1);
      }
    }

    setPage(0);
  }, [totalItems, limitPerPage]);

  useEffect(() => {
    _loadData();
  }, [page, limitPerPage]);

  // onChange page
  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value - 1);
  };

  return (
    <>
      {proposeTypeList === null ||
      priorList === null ||
      senderList === null ||
      receiverList === null ? (
        <div className="flex justify-center items-center flex-col text-sm space-y-3 w-full h-[calc(100%-7rem)] lg:mt-0 pt-5 px-5">
          <LoaderSpinner w={32} h={32} />
          <p className="text-primary">Đang tải dữ liệu</p>
        </div>
      ) : (
        <div className="flex flex-col text-sm space-y-3 w-full h-[calc(100%-7rem)] lg:mt-0 pt-5 px-5">
          {/* filter */}
          <div className="hidden lg:flex w-full h-max space-y-3">
            <FilterProposeWeb
              data={data}
              _loadData={_loadData}
              name={name}
              setName={setName}
              priorId={priorId}
              setPriorId={setPriorId}
              statusList={statusList}
              status={status}
              setStatus={setStatus}
              userAdd={userAdd}
              setUserAdd={setUserAdd}
              userCheck={userCheck}
              setUserCheck={setUserCheck}
              dateAdd_From={dateAdd_From}
              setDateAdd_From={setDateAdd_From}
              dateAdd_To={dateAdd_To}
              setDateAdd_To={setDateAdd_To}
              proposeTypeID={proposeTypeID}
              setProposeTypeID={setProposeTypeID}
              proposeTypeList={proposeTypeList}
              priorList={priorList}
              senderList={senderList}
              receiverList={receiverList}
            />
          </div>

          {/* table main */}
          <div className="lg:flex flex-col space-y-3 hidden">
            {/* page selection */}
            <div className="flex w-max h-max">
              <p>Xem</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={limitPerPage}
                onChange={(e) => {
                  setLimitPerPage(parseInt(e.target.value));
                }}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>

            {/* table */}
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <th className="border border-slate-300 ">STT</th>
                <th className="border border-slate-300 ">Trạng thái</th>
                <th className="border border-slate-300 ">Tên đề xuất</th>
                <th className="border border-slate-300 ">Nội dung</th>
                <th className="border border-slate-300 ">Thời gian đề xuất</th>
                <th className="border border-slate-300 ">Ngày gửi</th>
                <th className="border border-slate-300 ">Người nhận</th>
                <th className="border border-slate-300 "></th>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {data === null ? (
                  <tr></tr>
                ) : (
                  data?.map((item: any, index: any) => {
                    return (
                      <tr key={index} className="w-max h-[3rem] bg-white">
                        <td className="stt text-center border border-slate-300">
                          {index + 1 + page * limitPerPage}
                        </td>

                        <td className="trang-thai border border-slate-300 text-white">
                          <div
                            style={{
                              backgroundColor: _handleCheckedColor(item.Status),
                              color: "white",
                              padding: 5,
                              textAlign: "center",
                              borderRadius: 13,
                              width: "auto",
                            }}
                          >
                            {_handleCheckedStatus(item.Status)}
                          </div>
                        </td>

                        <td className="ten text-center border border-slate-300">
                          {item.Name}
                        </td>
                        <td className="noi-dung text-center border border-slate-300">
                          {item.Content}
                        </td>
                        {item.IsUseTime === true ? (
                          <td className="thoi-han text-center border border-slate-300">
                            {moment(item.StartTime).format("DD/MM/YYYY")}
                            &nbsp;-&nbsp;
                            {moment(item.EndTime).format("DD/MM/YYYY")}
                          </td>
                        ) : (
                          <td className="text-center border border-slate-300">
                            Không thời hạn
                          </td>
                        )}
                        <td className="ngay-gui text-center border border-slate-300">
                          {moment(item.DateAdd).format("DD/MM/YYYY")}
                          <br />
                          {moment(item.DateAdd).format("hh:mm")}
                        </td>
                        <td className="nguoi-gui text-center border border-slate-300">
                          {item.UserCheckName}
                        </td>
                        <td className="nguoi-nhan text-center border border-slate-300">
                          <div className="justify-center items-center w-full h-full lg:flex hidden">
                            <div className="dropdown">
                              <button className="dropbtn">
                                <MoreHorizIcon fontSize="small" />
                              </button>
                              <div className="dropdown-content text-xs font-semibold">
                                <button
                                  className="flex w-full h-full hover:text-sky-500"
                                  onClick={() => _onClickDetail(item.ID)}
                                >
                                  <div className="flex w-full h-full space-x-1">
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />
                                    <div>Chi tiết</div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>

            {/* pagination */}
            <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem]">
              <Pagination
                count={totalPage}
                variant="outlined"
                color="primary"
                size="small"
                page={page + 1}
                onChange={_onChangePaginate}
              />
            </div>
          </div>

          <div className="lg:hidden">
            {data?.length !== 0 ? (
              <>
                <FilterProposeMobile
                  data={data}
                  _loadData={_loadData}
                  name={name}
                  setName={setName}
                  priorId={priorId}
                  setPriorId={setPriorId}
                  statusList={statusList}
                  status={status}
                  setStatus={setStatus}
                  userAdd={userAdd}
                  setUserAdd={setUserAdd}
                  userCheck={userCheck}
                  setUserCheck={setUserCheck}
                  dateAdd_From={dateAdd_From}
                  setDateAdd_From={setDateAdd_From}
                  dateAdd_To={dateAdd_To}
                  setDateAdd_To={setDateAdd_To}
                  proposeTypeID={proposeTypeID}
                  setProposeTypeID={setProposeTypeID}
                  proposeTypeList={proposeTypeList}
                  priorList={priorList}
                  senderList={senderList}
                  receiverList={receiverList}
                />
                <MobileDeliverPropose
                  data={data}
                  _loadData={_loadData}
                  page={page}
                  setPage={setPage}
                  totalPage={totalPage}
                  limitPerPage={limitPerPage}
                  setLimitPerPage={setLimitPerPage}
                />
              </>
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};
export default ProposeDeliverListUI;
