import React from "react";

import { onTouchForm } from "../../../funcs/onTochForm";

const ChoosePermission = (props: any) => {

  const handleClose = props.handleClose;

  const nhomquyenList = props.nhomquyenList
  const selectedNhomquyen = props.selectedNhomquyen
  const _onChangeSelectedNhomquyen = props._onChangeSelectedNhomquyen

  return (
    <div
      className="
        lg:w-[40rem] w-screen h-[30rem] bg-white rounded-md
        flex flex-col
      "
    >
      {/* header */}
      <div className="
        flex items-center pl-4 pr-4
        text-white font-semibold
        bg-sky-500 w-full h-[3rem]
        rounded-t-md text-lg justify-between
      ">
        <p>Chọn nhóm quyền</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      <div
        className="
          flex flex-col p-4 w-full h-[calc(100%-6rem)]
          text-sm
        "
      >
        <div className="tableFixHead w-full h-[calc(100%-5rem)] shadow-md border-t-2">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300"></th>
                <th className="border-l-[1px] border-slate-300">STT</th>
                <th className="border-l-[1px] border-slate-300">Tên nhóm quyền</th>
                <th className="border-l-[1px] border-slate-300">Mô tả</th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {nhomquyenList === undefined ? <tr></tr>
                : nhomquyenList.map((item: any, index: any) => (
                  <tr key={index} className="w-max h-[3rem] bg-white">
                    <td className="border border-slate-300 text-center">
                      <input
                        value={item.ID}
                        checked={selectedNhomquyen?.includes(item.ID.toString()) === true ? true : false}
                        type="checkbox"
                        onChange={(e: any) => _onChangeSelectedNhomquyen(e)}
                        onTouchStart={(e: any) => onTouchForm(e)}
                      />
                    </td>
                    <td className="border border-slate-300 text-center">{index + 1}</td>
                    <td className="border border-slate-300">{item.GroupName}</td>
                    <td className="border border-slate-300">{item.GroupDescription}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>

      {/* footer */}
      <div
        className="
          flex items-center justify-end w-full h-[3rem] bg-slate-100 rounded-b-md space-x-4 text-sm
          pl-4 pr-4
        "
        onClick={handleClose}
      >
        <button className="bg-green-500 text-white pt-1 pb-1 pr-2 pl-2 rounded-md hover:bg-green-400">
          Xác nhận
        </button>
        <button className="bg-slate-300 text-white pt-1 pb-1 pr-2 pl-2 rounded-md hover:bg-slate-200"
          onClick={handleClose}
        >
          Đóng
        </button>
      </div>
    </div>
  )
}

export default ChoosePermission;
