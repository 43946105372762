import axios from "axios"
import { toast } from "react-toastify"
import { TAKE_PARAMETER_LISTKEY_DROPDOWN, USER_READ_ALL } from "../apis/_index"

export const readReport = async (apiLink: string, body: any, callback: (res: any) => void) => {
  const accessToken = localStorage.getItem("accessToken")

  try {
    await axios.post(
      apiLink,
      body,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "text/plain"
        }
      }
    )
      .then((res: any) => {
        if (res.data.StatusCode === 200) {
          callback(res);
        }
      })
  } catch (error: any) {
    toast.error("Tải dữ liệu thất bại")
  }
}

export const readReport2Array = async (
  apiLink: string,
  body: any,
  callback1: (res: any) => void,
  callback2: (res: any) => void
) => {
  const accessToken = localStorage.getItem("accessToken")

  try {
    await axios.post(
      apiLink,
      body,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          Accept: "text/plain"
        }
      }
    )
      .then((res: any) => {
        if (res.data.StatusCode === 200) {
          callback1(res);
          callback2(res);
        }
      })
  } catch (error: any) {
    toast.error("Tải dữ liệu thất bại")
  }
}

export const randomColor = () => {

  const hexLetters = '0123456789ABCDEF';
  let hexColor = '#';
  for (let i = 0; i < 6; i++) {
    hexColor += hexLetters[Math.floor(Math.random() * 16)];
  }
  return hexColor;
}

// phongBan list
export const takePhongban = async (callback: (res: any) => void) => {
  const accessToken = localStorage.getItem("accessToken")
  const API_PHONGBAN = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_PHONGBAN";

  await axios.get(
    API_PHONGBAN,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "text/plain"
      }
    }
  )
    .then((res: any) => {
      if (res.status === 200) {
        // setPhongbanList(res.data.Data);
        callback(res)
      }
    })
}

// userList
export const takeUser = async (callback: (res: any) => void) => {
  const accessToken = localStorage.getItem("accessToken")

  await axios.get(
    USER_READ_ALL,
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        Accept: "text/plain"
      }
    }
  )
    .then((res: any) => {
      if (res.status === 200) {
        // setPhongbanList(res.data.Data);
        callback(res)
      }
    })
}
