import React, { memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ONCHANGE_NAVBAR_BUTTON } from "../../../redux/reducers/_appReducer/_index";

import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const HiddenButton = () => {
  // redux
  const data = useSelector((state: any) => state.appReducer);
  const dispatch = useDispatch();

  // funcs
  const _onChangeShowingNavbar = () => {
    dispatch(ONCHANGE_NAVBAR_BUTTON());
  }

  return (
    <div className="w-[1rem] h-full bg-slate-50">
      <button className="
      lg:flex hidden
      w-full h-[7rem] mt-5 bg-slate-300 rounded-tr-md rounded-br-md
      hover:bg-slate-200 justify-center items-center
      hover:text-white
    "
        onClick={_onChangeShowingNavbar}
      >
        {data.navBarButton === "true" ? <ArrowLeftIcon fontSize="small" /> : <ArrowRightIcon fontSize="small" />}
      </button>
    </div>
  )
}

export default memo(HiddenButton);
