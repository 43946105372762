import React, { useState } from "react";

import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import AssignmentIcon from "@mui/icons-material/Assignment";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import SubModal from "../_subModal/_index";

const NeedGive = (props: any) => {
  // state submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const _onChangeMultipleChoicePartner = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  // take props
  const handleClose = props.handleClose;

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[55rem] w-screen h-[40rem]
      rounded-md
    "
    >
      <SubModal open={openSub} handleClose={handleCloseSub} funcs={funcsSub} />

      {/* header */}
      <div
        className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Tạo công việc mới</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm">
        <p className="font-semibold text-red-500">
          Lưu ý: Việc đã được giao thì không được phép chỉnh sửa thông tin
        </p>

        <div className="w-full h-max flex flex-col mt-4 text-sm space-y-4">
          <div className="flex w-full h-max">
            <div className="flex flex-col w-2/3 pr-3 h-max">
              <label>
                Tên công việc &nbsp;<span className="text-red-500">(*)</span>{" "}
              </label>
              <input
                className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Nhập tên công việc"
              />
            </div>

            <div className="flex flex-col pl-3 w-1/3 h-max">
              <label>
                Loại công việc&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <select
                className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                defaultValue={0}
              >
                <option className="text-gray-300" value={0} disabled>
                  --- Chọn loại công việc ---
                </option>
                <option>Loại công việc 1</option>
                <option>Loại công việc 2</option>
                <option>Loại công việc 3</option>
              </select>
            </div>
          </div>

          <div className="flex w-full h-max">
            <div className="w-1/2 pr-3 h-max">
              <label>
                Người nhận&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <select
                className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                defaultValue={0}
              >
                <option className="text-gray-300" value={0} disabled>
                  --- Chọn người nhận ---
                </option>
                <option>Người nhận 1</option>
                <option>Người nhận 2</option>
                <option>Người nhận 3</option>
              </select>
            </div>
            <div className="w-1/2 pl-3 h-max">
              <label>
                Nguồn văn bản&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <select
                className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                defaultValue={0}
              >
                <option className="text-gray-300" value={0} disabled>
                  --- Chọn nguồn văn bản ---
                </option>
                <option>Nguồn văn bản 1</option>
                <option>Nguồn văn bản 2</option>
                <option>Nguồn văn bản 3</option>
              </select>
            </div>
          </div>

          <div className="flex w-full h-max">
            <div className="flex flex-col w-1/2 pr-3 h-max">
              <div className="flex w-max h-max space-x-3">
                <label>Người phối hợp (nếu có)</label>
                <button
                  className="
                  bg-primary text-white pl-2 pr-2 pt-0.5 pb-0.5 rounded-md hover:bg-sky-400
                  text-xs
                "
                  onClick={() =>
                    _onChangeMultipleChoicePartner("multipleChoicePartner")
                  }
                >
                  Chọn
                </button>
              </div>
              {/* <div className="flex items-center w-full h-max text-slate-400">
                * Chưa chọn người phối hợp
              </div> */}
              <div className="flex flex-col w-full h-max">
                {/* element */}
                <div className="flex w-full h-max text-primary items-center justify-between">
                  <div className="flex w-5/6 h-max space-x-1">
                    <InsertDriveFileTwoToneIcon fontSize="small" />
                    <p>Người phối hợp 1</p>
                  </div>

                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>
                <div className="flex w-full h-max text-primary items-center justify-between">
                  <div className="flex w-5/6 h-max space-x-1">
                    <InsertDriveFileTwoToneIcon fontSize="small" />
                    <p>Người phối hợp 2</p>
                  </div>

                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>
                {/* <div className="flex w-full h-max text-primary items-center justify-between">
                  <div className="flex w-5/6 h-max space-x-1">
                    <InsertDriveFileTwoToneIcon fontSize="small" />
                    <p>Người phối hợp 3</p>
                  </div>

                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div> */}
              </div>
            </div>
            <div className="w-1/2 pl-3 h-max">
              <label>
                Độ khẩn&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <select
                className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                defaultValue={0}
              >
                <option className="text-gray-300" value={0} disabled>
                  --- Chọn độ khẩn ---
                </option>
                <option>Độ khẩn 1</option>
                <option>Độ khẩn 2</option>
                <option>Độ khẩn 3</option>
              </select>
            </div>
          </div>

          <div className="flex w-full h-max">
            <div className="w-1/2 h-max pr-3">
              <label>
                Thời hạn hoàn thành&nbsp;
                <span className="text-red-500">(*)</span>
              </label>
              <input
                className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Nhập tên công việc"
                type="date"
              />
            </div>
            <div className="w-1/2 h-max pl-3">
              <label>
                Độ mật&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <select
                className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                defaultValue={0}
              >
                <option className="text-gray-300" value={0} disabled>
                  --- Chọn độ mật ---
                </option>
                <option>Độ mật 1</option>
                <option>Độ mật 2</option>
                <option>Độ mật 3</option>
              </select>
            </div>
          </div>

          <div className="flex w-full h-max">
            <div className="flex flex-col w-1/2 h-max">
              <div className="flex w-max h-max space-x-3 items-center">
                <label>Chọn quy trình (nếu có)</label>
                <button
                  className="
                  bg-primary text-white pl-2 pr-2 pt-0.5 pb-0.5 rounded-md hover:bg-sky-400
                  text-xs
                "
                  onClick={() =>
                    _onChangeMultipleChoicePartner("multipleChoiceStep")
                  }
                >
                  Chọn
                </button>
                <button
                  className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-xs
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                >
                  +
                </button>
              </div>
              {/* <div className="flex items-center w-full h-max text-slate-400">
                * Chưa chọn quy trình
              </div> */}
              <div className="flex items-center w-full h-max text-primary">
                Quy trình giao việc số 1
              </div>
            </div>
          </div>

          <table className="w-full">
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border border-slate-300 ">STT</th>
                <th className="border border-slate-300">Nội dung</th>
                <th className="border border-slate-300">Người thực hiện</th>
                <th className="border border-slate-300">Thời hạn</th>
                <th className="border border-slate-300"></th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">1</td>
                <td className="border border-slate-300">
                  <p>Xử lý hồ sơ bước 1</p>
                </td>
                <td className="text-center border border-slate-300">
                  <select
                    multiple
                    className="w-full h-max rounded-md pl-2 pr-2 border-2 border-slate-200"
                    defaultValue={[]}
                  >
                    <option>Người thực hiện chính</option>
                    <option>Người phối hợp 1</option>
                    <option>Người phối hợp 2</option>
                  </select>
                </td>
                <td className="text-center border border-slate-300">
                  <input
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    type="date"
                  />
                </td>
                <td className="border border-slate-300">
                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">2</td>
                <td className="border border-slate-300">
                  <p>Xử lý hồ sơ bước 2</p>
                </td>
                <td className="text-center border border-slate-300">
                  <select
                    multiple
                    className="w-full h-max rounded-md pl-2 pr-2 border-2 border-slate-200"
                    defaultValue={[]}
                  >
                    <option>Người thực hiện chính</option>
                    <option>Người phối hợp 1</option>
                    <option>Người phối hợp 2</option>
                  </select>
                </td>
                <td className="text-center border border-slate-300">
                  <input
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    type="date"
                  />
                </td>
                <td className="border border-slate-300">
                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="flex flex-col w-1/2 h-max">
            <div className="flex w-max h-max space-x-3 items-center">
              <label>Kết quả cần đạt được</label>
            </div>
            {/* <div className="flex items-center w-full h-max text-slate-400">
                * Chưa chọn quy trình
              </div> */}
            <div className="flex space-x-3 items-center w-full h-max text-primary">
              <div className="flex w-full h-max text-primary items-center justify-between">
                <div className="flex w-5/6 h-max space-x-1">
                  <p>Kết quả 1</p>
                </div>

                <button className="text-red-500 w-1/6">
                  <DeleteTwoToneIcon fontSize="small" />
                </button>
              </div>
            </div>
            <div className="flex space-x-3 items-center w-full h-max text-primary">
              <div className="flex w-full h-max text-primary items-center justify-between">
                <div className="flex w-5/6 h-max space-x-1">
                  <p>Kết quả 2</p>
                </div>

                <button className="text-red-500 w-1/6">
                  <DeleteTwoToneIcon fontSize="small" />
                </button>
              </div>
            </div>
            <div className="flex space-x-3 items-center w-full h-max text-primary">
              <div className="flex w-full h-max text-primary items-center justify-between">
                <div className="flex w-5/6 h-max space-x-1">
                  <p>Kết quả 3</p>
                </div>

                <button className="text-red-500 w-1/6">
                  <DeleteTwoToneIcon fontSize="small" />
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-full h-max">
            <label>Mô tả</label>
            <textarea
              className="w-full h-[5rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập tên công việc"
            />
          </div>

          <div className="flex flex-col w-full h-max">
            <label>Ghi chú</label>
            <textarea
              className="w-full h-[5rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập tên công việc"
            />
          </div>

          <div className="flex w-full h-max">
            <div className="flex flex-col w-1/2 h-max pr-4">
              <div className="flex w-full h-max items-center space-x-2">
                <p>Đính kèm</p>
                <button
                  className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-xs
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                >
                  +
                </button>
              </div>

              {/* fields of file */}
              <div className="flex flex-col w-full h-max">
                {/* element */}
                <div className="flex w-full h-max text-primary items-center justify-between">
                  <div className="flex w-5/6 h-max space-x-1">
                    <InsertDriveFileTwoToneIcon fontSize="small" />
                    <p>File đính kèm 1</p>
                  </div>

                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>
                <div className="flex w-full h-max text-primary items-center justify-between">
                  <div className="flex w-5/6 h-max space-x-1">
                    <InsertDriveFileTwoToneIcon fontSize="small" />
                    <p>File đính kèm 2</p>
                  </div>

                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>
                <div className="flex w-full h-max text-primary items-center justify-between">
                  <div className="flex w-5/6 h-max space-x-1">
                    <InsertDriveFileTwoToneIcon fontSize="small" />
                    <p>File đính kèm 3</p>
                  </div>

                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>
                <div className="flex w-full h-max text-primary items-center justify-between">
                  <div className="flex w-5/6 h-max space-x-1">
                    <InsertDriveFileTwoToneIcon fontSize="small" />
                    <p>File đính kèm 4</p>
                  </div>

                  <button className="text-red-500 w-1/6">
                    <DeleteTwoToneIcon fontSize="small" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-end items-center pl-4 pr-4 text-sm space-x-4">
        {/* <button className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-primary text-white rounded-md
          hover:bg-sky-300
        ">
          <SaveIcon fontSize="small" />
          <p>Tạo việc</p>
        </button> */}
        <div className="w-max h-max dropdown">
          {/* button options */}
          <button
            className=" flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-primary text-white rounded-md
          hover:bg-sky-400"
          >
            <AssignmentIcon fontSize="small" />
            <p>Tác vụ</p>
          </button>

          {/* options fields */}
          <div className="dropdown-content text-xs font-semibold">
            {/* detail */}
            <button className="flex w-full h-full hover:text-sky-500">
              {/* detail - contain */}
              <div className="flex w-full h-full space-x-1">
                {/* icon */}
                <DescriptionTwoToneIcon
                  className="text-sky-600"
                  sx={{ fontSize: 18 }}
                  fontSize="small"
                />

                {/* title */}
                <div>Lưu chỉnh sửa</div>
              </div>
            </button>

            {/* delitask */}
            <button className="flex w-full h-full hover:text-emerald-500">
              {/* detail - contain */}
              <div className="flex w-full h-full space-x-1">
                {/* icon */}
                <SendTwoToneIcon
                  className="text-emerald-600"
                  sx={{ fontSize: 18 }}
                  fontSize="small"
                />

                {/* title */}
                <div>Giao việc</div>
              </div>
            </button>

            {/* delete */}
            <button className="flex w-full h-full hover:text-red-500">
              {/* delete - contain */}
              <div className="flex w-full space-x-1">
                {/* icon */}
                <DeleteTwoToneIcon
                  className="text-red-600"
                  sx={{ fontSize: 18 }}
                  fontSize="small"
                />

                {/* title */}
                <div className="h-full">Xoá</div>
              </div>
            </button>
          </div>
        </div>

        <button
          className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
          onClick={handleClose}
        >
          <ExitToAppTwoToneIcon fontSize="small" />
          <p>Đóng</p>
        </button>
      </div>
    </div>
  );
};

export default NeedGive;
