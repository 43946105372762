import React, { useState } from "react";
import { HexColorPicker } from "react-colorful";
import { SketchPicker } from 'react-color';

import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';

import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import SaveIcon from '@mui/icons-material/Save';

import { onTouchForm } from "../../../funcs/onTochForm";

const CreateTableScoringCapacity = (props: any) => {

  const [color, setColor] = useState<string>("#aabbcc");

  const taskId = props.taskId;
  const handleClose = props.handleClose;

  return (
    <div className="
    flex flex-col bg-white
    lg:w-[50rem] w-screen h-[38rem]
    rounded-md
  ">
      {/* header */}
      <div className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      ">
        <p>Tạo bảng thiết lập đánh giá</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="flex w-full h-max space-x-6">
          <div className="flex flex-col w-1/3 pr-3 h-max">
            <label>Phòng ban áp dụng&nbsp;<span className="text-red-500">(*)</span></label>
            <select
              className="w-full h-[1.9rem] rounded-md pl-1 pr-1 border-2 border-slate-200"
              defaultValue={0}
            >
              <option className="text-gray-300" value={0} disabled>--- Chọn phòng ban ---</option>
              <option>Phòng ban 1</option>
              <option>Phòng ban 2</option>
              <option>Phòng ban 3</option>
            </select>
          </div>

          <div className="flex flex-col w-1/3 pr-3 h-max items-start">
            <label>Áp dụng</label>
            <input
              type="checkbox"
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-col w-3/4 pr-3 h-max">
            <label>Tên thiết lập đánh giá&nbsp;<span className="text-red-500">(*)</span></label>
            <select
              className="w-full h-[1.9rem] rounded-md pl-1 pr-1 border-2 border-slate-200"
              defaultValue={0}
            >
              <option className="text-gray-300" value={0} disabled>--- Chọn phòng ban ---</option>
              <option>Phòng ban 1</option>
              <option>Phòng ban 2</option>
              <option>Phòng ban 3</option>
            </select>
          </div>

          <div className="flex flex-col w-1/4 pl-3 h-max">
            <label>Ngày áp dụng</label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              placeholder="Nhập tên công việc"
              type="date"
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-col w-full h-max">
            <label>Mô tả&nbsp;<span className="text-red-500">(*)</span></label>
            <textarea
              className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập mô tả"
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          {/* elements */}
          <div className="flex flex-col w-1/2 h-max pr-3 space-y-3">
            {/* elements */}
            <div className="flex flex-col w-full h-max">
              <label className="font-bold">Tiêu chí</label>

              <div className="flex w-full h-max">
                <div className="w-2/3 h-max pr-2">
                  <select
                    className="w-full h-[1.9rem] rounded-md pl-1 pr-1 border-2 border-slate-200"
                    defaultValue={0}
                  >
                    <option className="text-gray-300" value={0} disabled>--- Chọn tiêu chí ---</option>
                    <option>Tiêu chí 1</option>
                    <option>Tiêu chí 2</option>
                    <option>Tiêu chí 3</option>
                  </select>
                </div>
                <div className="w-1/3 pl-2">
                  <button className="
                    w-[1.9rem] h-[1.9rem] rounded-full
                    text-xl font-semibold border-green-500 border-2
                    flex justify-center items-center text-green-500
                    hover:bg-green-500 hover:text-white
                  ">
                    +
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full h-max">
              <table>
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="border-l-[1px] border-slate-300">STT</th>
                    <th className="border-l-[1px] border-slate-300">Tiêu chí</th>
                    <th className="border-l-[1px] border-r-[1px] border-slate-300 w-[1rem]"></th>
                  </tr>
                </thead>
                <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                  <tr className="w-max h-[3rem] bg-white">
                    <td className="text-center border border-slate-300">#</td>
                    <td className="border border-slate-300">Không có việc trễ hạn quá 3 ngày</td>
                    <td className="text-center border border-slate-300">
                      <button className="text-red-500">
                        <DeleteTwoToneIcon fontSize="small" />
                      </button>
                    </td>
                  </tr>
                  <tr className="w-max h-[3rem] bg-white">
                    <td className="text-center border border-slate-300">#</td>
                    <td className="border border-slate-300">Không đi làm trễ quá 5 lần</td>
                    <td className="text-center border border-slate-300">
                      <button className="text-red-500">
                        <DeleteTwoToneIcon fontSize="small" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="flex flex-col w-1/2 h-max pl-3 space-y-3">
            {/* elements */}
            <div className="flex flex-col w-full h-max">
              <label className="font-bold">Bảng điểm</label>

              <div className="flex w-full h-max space-x-2">
                <div className="flex items-center space-x-1">
                  <p>Từ</p>
                  <input
                    className="w-[2rem] h-[1rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={1}
                  />
                </div>

                <div className="flex items-center space-x-1">
                  <p>Đến</p>
                  <input
                    className="w-[2rem] h-[1rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={5}
                  />
                </div>

                <div className="flex items-center space-x-1">
                  <p>Đạt</p>
                  <input type="checkbox" />
                </div>

                <div className="flex items-center space-x-1">
                  <p>Kết quả</p>

                  <input
                    className="w-[3rem] h-[1rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  />
                </div>

                <div className="flex items-center space-x-1">
                  <button className="
                    w-[1.9rem] h-[1.9rem] rounded-full
                    text-xl font-semibold border-green-500 border-2
                    flex justify-center items-center text-green-500
                    hover:bg-green-500 hover:text-white
                  ">
                    +
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-full h-max">
              <table>
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="border-l-[1px] border-slate-300">STT</th>
                    <th className="border-l-[1px] border-slate-300">Kết quả</th>
                    <th className="border-l-[1px] border-slate-300"></th>
                    <th className="border-l-[1px] border-r-[1px] border-slate-300 w-[1rem]"></th>
                  </tr>
                </thead>
                <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                  <tr className="w-max h-[3rem] bg-white">
                    <td className="text-center border border-slate-300">#</td>
                    <td className="border border-slate-300">Đạt 1 đến 5 tiêu chí</td>
                    <td className="border border-slate-300">Không đạt</td>
                    <td className="text-center border border-slate-300">
                      <button className="text-red-500">
                        <DeleteTwoToneIcon fontSize="small" />
                      </button>
                    </td>
                  </tr>
                  <tr className="w-max h-[3rem] bg-white">
                    <td className="text-center border border-slate-300">#</td>
                    <td className="border border-slate-300">Đạt 6 đến 9 tiêu chí</td>
                    <td className="border border-slate-300">Đạt</td>
                    <td className="text-center border border-slate-300">
                      <button className="text-red-500">
                        <DeleteTwoToneIcon fontSize="small" />
                      </button>
                    </td>
                  </tr>
                  <tr className="w-max h-[3rem] bg-white">
                    <td className="text-center border border-slate-300">#</td>
                    <td className="border border-slate-300">Đạt 10 tiêu chí</td>
                    <td className="border border-slate-300">Xuất sắc</td>
                    <td className="text-center border border-slate-300">
                      <button className="text-red-500">
                        <DeleteTwoToneIcon fontSize="small" />
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-end items-center pl-4 pr-4 text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-primary text-white rounded-md
          hover:bg-sky-400
        "

          >
            <SaveIcon fontSize="small" />
            <p>Tạo mới</p>
          </button>

          <button className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default CreateTableScoringCapacity;
