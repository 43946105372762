import React, { useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import AddIcon from "@mui/icons-material/Add";

import Title from "../../../../components/title";
import Table from "../../../../components/table";

import { takePermission } from "../../../../funcs";

import { ModalController } from "../../../homeUI/_index";
import { PRIOR_LIST } from "../../../../apis/_index";
import { toast } from "react-toastify";
import axios from "axios";

import { ONLOAD_PRIORLIST } from "../../../../redux/reducers/settingReducer/_index";

const PriorRateUI = () => {
  const context = useContext(ModalController);
  const menu = takePermission(13);

  const dispatch = useDispatch();
  const shouldLog = useRef(true);

  const _onClickCreatePrior = () => {
    context.setFuncs("createPrior");
    context.handleOpen();
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog.current) {
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await axios
            .get(PRIOR_LIST, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_PRIORLIST(res.data.Data));
              } else {
                toast.error("Tải dữ liệu thất bại.");
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại.");
        }
      };

      takeData();
    }
  });

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-1.8rem)] p-5 space-y-3">
        <div className="w-full h-[2rem] flex justify-between items-center">
          <input
            className="w-max h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            placeholder="Tìm kiếm mức độ."
          />

          <button
            className={`
              pl-2 pr-2 pt-1 pb-1 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
              ${menu.PerInsert === true ? "flex" : "hidden"}
            `}
            onClick={_onClickCreatePrior}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Thêm mới</p>
          </button>
        </div>

        <Table />
      </div>
    </div>
  );
};

export default PriorRateUI;
