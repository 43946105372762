import React, { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';

import { ModalController } from "../../../ui/homeUI/_index";
import { PRIOR_DELETE, PRIOR_LIST, USER_DELETE, USER_READ_ALL, DELETE_PARAMETER, READ_PARAMETER } from "../../../apis/_index";
import { ONLOAD_USERLIST, ONLOAD_PARAMETER_LIST } from "../../../redux/reducers/settingReducer/_index";
import axios from "axios";
import { toast } from "react-toastify";
import LoaderSpinner from "../../loader-spinner/_index";

const DeleteGeneral = (props: any) => {

  const data = useSelector((state: any) => state.settingReducer);
  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const [isLoading, setIsLoading] = useState<boolean>(false)

  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  // funcs
  const _onClickDelete = async () => {
    setIsLoading(false)
    const accessToken = localStorage.getItem("accessToken");
    const API = DELETE_PARAMETER(taskId.toString())

    try {
      await axios.get(
        API,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain"
          }
        }
      )
        .then((res: any) => {
          if (res.status === 200) {
            const takeData = async () => {
              await axios.get(
                READ_PARAMETER,
                {
                  headers: {
                    Authorization: "Bearer " + accessToken,
                    Accept: "text/plain"
                  }
                }
              )
                .then((res: any) => {
                  if (res.status === 200) {
                    const accessToken = localStorage.getItem("accessToken");
                    let API = "";

                    if (data.parameterChoose.toString() === "1") {
                      API = READ_PARAMETER;
                    } else {
                      API = READ_PARAMETER + `?key=${data.parameterChoose}`
                    }

                    const takeParameterList = async () => {
                      try {
                        await axios.get(
                          API,
                          {
                            headers: {
                              Authorization: "Bear " + accessToken
                            }
                          }
                        )
                          .then((res: any) => {
                            if (res.status === 200) {
                              dispatch(ONLOAD_PARAMETER_LIST(res.data.Data))
                              toast.success("Xoá thành công.");
                              handleClose();
                              context.handleClose();
                              setIsLoading(false)
                            } else {
                              return;
                            }
                          })
                      } catch (error: any) {
                        toast.error("Tải dữ liệu thất bại.");
                        setIsLoading(false)
                      }
                    }
                    takeParameterList();
                  }
                })
            }

            takeData();
          }
        })
    } catch (error: any) {
      toast.error("Xoá thất bại.");
      setIsLoading(false)
    }
  }

  return (
    <div className="
      flex bg-red-400
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    ">

      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <DeleteIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>Bạn có đồng ý <span className="font-semibold">XOÁ</span> danh mục này không?</p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button className={`
            w-[5rem] h-max pt-1 pb-1 rounded-md
            ${isLoading === true ? "bg-slate-300" : "bg-slate-500 hover:bg-slate-400"}
          `}
            onClick={_onClickDelete}
            disabled={isLoading}
          >
            {
              isLoading === true ?
              <div className="flex w-full h-full justify-center items-center">
                <LoaderSpinner
                  w={20}
                  h={20}
                />
              </div>
              :
              <p>Có</p>
            }
          </button>
          <button className={`
            w-[5rem] h-max pt-1 pb-1 rounded-md
            ${isLoading === true ? "bg-slate-300" : "bg-red-500 hover:bg-red-300"}
          `}
            onClick={handleClose}
            disabled={isLoading}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteGeneral;
