import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// components importing
import Title from "../components/title";
import SubmitHeader from "../components/submitHeader";
import ContentTop from "../components/contentTop";
import ContentBottom from "../components/contentBottom";
import Table from "../components/table";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { loadBoss } from "../funcs/taskNguoiGiaoViec";

import { VotingListNeedToJoin, VotingListNeedToJoinWithParams, VotingListRequest } from "../funcs/_votingFuncs";

import {
  NGUOI_GIAO_VIEC_LIST,
  NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
  NGUOI_GIAO_VIEC_LIST_DELAY,
  NGUOI_GIAO_VIEC_LIST_END_TODAY,
  NGUOI_GIAO_VIEC_LIST_END_SOON,
  NGUOI_NHAN_VIEC_LIST,
  NGUOI_NHAN_VIEC_LIST_RECEIVED,
  NGUOI_NHAN_VIEC_LIST_PROCESSING,
  NGUOI_NHAN_VIEC_LIST_DELAY,
  NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
  NGUOI_GIAO_VIEC_LIST_FINISH,
  NGUOI_GIAO_VIEC_LIST_TOTAL,
  NGUOI_NHAN_VIEC_LIST_END_SOON,
  NGUOI_NHAN_VIEC_LIST_ALL,
  NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
  NGUOI_GIAO_LOAD_TASK_LATE,
  NGUOI_GIAO_LOAD_TASK_EARLY,
  NGUOI_GIAO_LOAD_TASK_PUNCTUAL,
  NGUOI_NHAN_VIEC_LIST_EARLY_COMPLETE,
  NGUOI_GIAO_VIEC_LIST_ALL_HAS_DELIVERY,
  NGUOI_NHAN_VIEC_LIST_PUNCTUAL_COMPLETE,
  NGUOI_NHAN_VIEC_LIST_LATE_COMPLETE,
  NGUOI_GIAO_TASK_RETURN,
  NGUOI_NHAN_TASK_RETURN,
  NGUOI_NHAN_CHO_DUYET_TASK,
  NGUOI_GIAO_VIEC_LIST_CHANGE_DEADLINE
} from "../apis/_index";
import {
  ONCHANGE_TOI_HAN_HOM_NAY,
  ONLOAD_TASKLIST_ALL,
} from "../redux/reducers/workingReducer/_index";
import {
  ONLOAD_RECEIVERLIST,
  ONLOAD_PRIORLIST,
  ONCHANGE_NGUOI_NHAN_RETURN,
  ONCHANGE_VIEC_CAN_GIAO,
  ONCHANGE_DANG_TRE_HAN,
  ONCHANGE_SAP_TOI_HAN,
  ONCHANGE_NGUOI_NHAN_MOI,
  ONCHANGE_NGUOI_NHAN_DA_NHAN,
  ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN,
  ONCHANGE_NGUOI_NHAN_TRE_HAN,
  ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY,
  ONCHANGE_NGUOI_GIAO_HOAN_TAT,
  ONCHANGE_NGUOI_GIAO_TONG_HOP,
  ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN,
  ONCHANGE_NGUOI_NHAN_TAT_CA,
  ONCHANGE_TASKLIST_WITH_NORMAL,
  ONCHANGE_NGUOI_NHAN_XONG_SOM,
  ONCHANGE_NGUOI_NHAN_XONG_TRE,
  ONCHANGE_NGUOI_NHAN_XONG,
  ONCHANGE_NGUOI_GIAO_DANG_XU_LY,
  ONCHANGE_NGUOI_GIAO_XIN_GIA_HAN,
  ONLOAD_BOSS_LIST,
  ONCHANGE_RESET_FILTER,
  ONCHANGE_TASK_WAITING,
  ONCHANGE_VOTING_LIST,
  ONCHANGE_TASK_COMPLETED_PUNCTUAL,
  ONCHANGE_TASK_COMPLETED_EARLY,
  ONCHANGE_TASK_COMPLETED_LATE,
  ONCHANGE_NGUOI_GIAO_RETURN,
} from "../redux/reducers/workingReducer/_index";

import { ONCHANGE_REPORT_NANG_LUC_NHAN_SU_NGUOI_GIAO } from "../redux/reducers/reportReducer/_index";

import { readList, loadPrior, loadReceiver } from "../funcs/taskNguoiGiaoViec";

const ContentCore = () => {
  // redux
  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const shouldLog = useRef(true);

  const shouldLog1 = useRef(true);
  const shouldLog2 = useRef(true);
  const shouldLog3 = useRef(true);

  const link = window.location.pathname;

  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);

  const _onChangeIsShowFilter = () => {
    if (isShowFilter === true) {
      setIsShowFilter(false);
    } else {
      setIsShowFilter(true);
    }
  };

  useEffect(() => {
    setIsShowFilter(false);
  }, [link]);

  const receiver = data.searchFilter.selectedReceiver;

  // funcs
  const checkFilterParams = (): boolean => {
    // console.log({
    //   "pageCurrent": data.pageCurrent,
    //   "choosePage": data.choosePage,
    //   "taskName": data.searchFilter.taskName,
    //   "selectedPrior": data.searchFilter.selectedPrior,
    //   "selectedReceiver": data.searchFilter.selectedReceiver,
    //   "fromDate": data.searchFilter.fromDate,
    //   "toDate": data.searchFilter.toDate,
    //   "deadLine": data.searchFilter.deadLine,
    // })

    if (
      data.searchFilter.deadLine === "" &&
      data.searchFilter.fromDate === "" &&
      data.searchFilter.toDate === "" &&
      data.searchFilter.selectedPrior === -1 &&
      data.searchFilter.selectedReceiver === -1 &&
      data.searchFilter.taskName === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  // -- on change page of main table

  useEffect(() => {
    if (link === "/working/new-task") {
      if (checkFilterParams() === true) {

        readList(
          NGUOI_GIAO_VIEC_LIST,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
          }
        );
      }
    } else if (link === "/voting/voting_work") {
      VotingListNeedToJoinWithParams(data.pageCurrent, 10, function (res: any) {
        dispatch(ONCHANGE_VOTING_LIST(res.data.Data));
      }, data.searchFilter.taskName, data.searchFilter.selectedReceiver);
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_NHAN_CHO_DUYET_TASK,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => {
      //       dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
      //     }
      //   );
      // } else {
      //   readList(
      //     NGUOI_NHAN_CHO_DUYET_TASK,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => {
      //       dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
      //     }
      //   );
      // }
    } else if (link === "/voting/voting_wait") {
      VotingListRequest(function (res: any) {
        dispatch(ONCHANGE_VOTING_LIST(res.data.Data));
      });
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_NHAN_CHO_DUYET_TASK,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => {
      //       dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
      //     }
      //   );
      // } else {
      //   readList(
      //     NGUOI_NHAN_CHO_DUYET_TASK,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => {
      //       dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
      //     }
      //   );
      // }
    } else if (link === "/working/receiver/task-waiting") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_CHO_DUYET_TASK,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_CHO_DUYET_TASK,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
          }
        );
      }
    } else if (link === "/working/receiver/task-returnwork") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_TASK_RETURN,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_RETURN(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_TASK_RETURN,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_RETURN(res.data.Data));
          }
        );
      }
    } else if (link === "/working/task-return") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_TASK_RETURN,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_RETURN(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_TASK_RETURN,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_RETURN(res.data.Data));
          }
        );
      }
    } else if (link === "/working/receiver/task-finish_early") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_EARLY_COMPLETE,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_XONG_SOM(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_EARLY_COMPLETE,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_XONG_SOM(res.data.Data));
          }
        );
      }
    } else if (link === "/working/receiver/task-finish_puntual") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_PUNCTUAL_COMPLETE,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_XONG(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_PUNCTUAL_COMPLETE,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_XONG(res.data.Data));
          }
        );
      }
    } else if (link === "/working/receiver/task-finish_late") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_LATE_COMPLETE,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_XONG_TRE(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_LATE_COMPLETE,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_XONG_TRE(res.data.Data));
          }
        );
      }
    } else if (link === "/working/task-need-give") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data));
          }
        );
      }
      // readList(
      //   NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data)) }
      // )
    } else if (link === "/working/task-delay") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_DELAY,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_DELAY,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_DELAY,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data)) }
      // )
    } else if (link === "/working/task-end-today") {
      // re check

      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_END_TODAY,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TOI_HAN_HOM_NAY(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_END_TODAY,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TOI_HAN_HOM_NAY(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_END_TODAY,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_TOI_HAN_HOM_NAY(res.data.Data)) }
      // )
    } else if (link === "/working/task-end-soon") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_END_SOON,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_SAP_TOI_HAN(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_END_SOON,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_SAP_TOI_HAN(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_END_SOON,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_SAP_TOI_HAN(res.data.Data)) }
      // )
    } else if (link === "/working/task-change-deadline") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_CHANGE_DEADLINE,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_XIN_GIA_HAN(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_CHANGE_DEADLINE,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_XIN_GIA_HAN(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_PROCESSING,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/task-new") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => {
      //     dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data))
      //   }
      // )
    } else if (link === "/working/receiver/task-received") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_RECEIVED,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_RECEIVED,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_RECEIVED,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/task-processing") {
      if (checkFilterParams() === true) {
  
        readList(
          NGUOI_NHAN_VIEC_LIST_PROCESSING,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_PROCESSING,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_PROCESSING,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/task-delay") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_DELAY,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_TRE_HAN(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_DELAY,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_TRE_HAN(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_DELAY,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_TRE_HAN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/task-end-today") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY(res.data.Data)) }
      // )
    } else if (link === "/working/task-finish") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_FINISH,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_FINISH,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_FINISH,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data)) }
      // )
    } else if (link === "/working/task-total") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_TOTAL,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_TOTAL,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data));
          },
          data.searchFilter.status
        );
      }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_TOTAL,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data)) }
      // )
    } else if (link === "/working/task-totalall") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_VIEC_LIST_ALL_HAS_DELIVERY,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASKLIST_WITH_NORMAL(res.data.Data));
          },
          data.searchFilter.status,
          data.searchFilter.selectedBoss,
          data.searchFilter.result
        );
      } else {
        readList(
          NGUOI_GIAO_VIEC_LIST_ALL_HAS_DELIVERY,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASKLIST_WITH_NORMAL(res.data.Data));
          },
          data.searchFilter.status,
          data.searchFilter.selectedBoss,
          data.searchFilter.result
        );
      }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_TOTAL,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/task-end-soon") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_END_SOON,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_END_SOON,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_END_SOON,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/all-task") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_NHAN_VIEC_LIST_ALL,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_TAT_CA(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_NHAN_VIEC_LIST_ALL,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_NHAN_TAT_CA(res.data.Data));
          }
        );
      }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_ALL,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => {
      //     dispatch(ONCHANGE_NGUOI_NHAN_TAT_CA(res.data.Data))
      //   }
      // )
    } else if (link === "/working/task-proccessing") {
      if (checkFilterParams() === true) {
        console.log(1)
        readList(
          NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_DANG_XU_LY(res.data.Data));
          }
        );
      } else {
        console.log(2)
        readList(
          NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_NGUOI_GIAO_DANG_XU_LY(res.data.Data));
          }
        );
      }

      // console.log({
      //   "pageCurrent": data.pageCurrent,
      //   "choosePage": data.choosePage,
      //   "taskName": data.searchFilter.taskName,
      //   "selectedPrior": data.searchFilter.selectedPrior,
      //   "selectedReceiver": data.searchFilter.selectedReceiver,
      //   "fromDate": data.searchFilter.fromDate,
      //   "toDate": data.searchFilter.toDate,
      //   "deadLine": data.searchFilter.deadLine,
      // })

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => {
      //     dispatch(ONCHANGE_NGUOI_GIAO_DANG_XU_LY(res.data.Data));
      //   }
      // );
    } else if (link === "/working/task-finish-late") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_LOAD_TASK_LATE,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_COMPLETED_LATE(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_LOAD_TASK_LATE,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_COMPLETED_LATE(res.data.Data));
          }
        );
      }
    } else if (link === "/working/task-finish-early") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_LOAD_TASK_EARLY,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_COMPLETED_EARLY(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_LOAD_TASK_EARLY,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_COMPLETED_EARLY(res.data.Data));
          }
        );
      }
    } else if (link === "/working/task-finish-punctual") {
      if (checkFilterParams() === true) {
        readList(
          NGUOI_GIAO_LOAD_TASK_PUNCTUAL,
          data.pageCurrent,
          data.choosePage,
          "",
          -1,
          -1,
          "",
          "",
          "",
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_COMPLETED_PUNCTUAL(res.data.Data));
          }
        );
      } else {
        readList(
          NGUOI_GIAO_LOAD_TASK_PUNCTUAL,
          data.pageCurrent,
          data.choosePage,
          data.searchFilter.taskName,
          data.searchFilter.selectedPrior,
          data.searchFilter.selectedReceiver,
          data.searchFilter.fromDate,
          data.searchFilter.toDate,
          data.searchFilter.deadLine,
          shouldLog,
          link,
          (res: any) => {
            dispatch(ONCHANGE_TASK_COMPLETED_PUNCTUAL(res.data.Data));
          }
        );
      }
    }
  }, [dispatch, link]);

  // -- on change page of sub table
  useEffect(() => {
    if (link === "/working/new-task") {
      readList(
        NGUOI_GIAO_VIEC_LIST,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
        }
      )
    }
    else if (link === "/working/task-totalall") {

      readList(
        NGUOI_GIAO_VIEC_LIST_ALL_HAS_DELIVERY,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_TASKLIST_WITH_NORMAL(res.data.Data));
        },
        data.searchFilter.status,
        data.searchFilter.selectedBoss,
        data.searchFilter.result
      );
    }
    else if (link === "/working/task-return") {
      readList(
        NGUOI_GIAO_TASK_RETURN,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_GIAO_RETURN(res.data.Data));
        }
      );
    } else if (link === "/working/receiver/task-returnwork") {
      readList(
        NGUOI_NHAN_TASK_RETURN,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_NHAN_RETURN(res.data.Data));
        }
      );
    } else if (link === "/working/receiver/task-waiting") {
      readList(
        NGUOI_NHAN_CHO_DUYET_TASK,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
        }
      );
    } else if (link === "/working/receiver/task-finish_early") {
      readList(
        NGUOI_NHAN_VIEC_LIST_EARLY_COMPLETE,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_NHAN_XONG_SOM(res.data.Data));
        }
      );
    } else if (link === "/working/receiver/task-finish_puntual") {
      readList(
        NGUOI_NHAN_VIEC_LIST_PUNCTUAL_COMPLETE,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_NHAN_XONG(res.data.Data));
        }
      );
    } else if (link === "/working/receiver/task-finish_late") {
      readList(
        NGUOI_NHAN_VIEC_LIST_LATE_COMPLETE,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_NHAN_XONG_TRE(res.data.Data));
        }
      );
    } else if (link === "/working/task-finish-late") {
      readList(
        NGUOI_GIAO_LOAD_TASK_LATE,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_TASK_COMPLETED_LATE(res.data.Data));
        }
      );
    } else if (link === "/working/task-finish-early") {
      readList(
        NGUOI_GIAO_LOAD_TASK_EARLY,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_TASK_COMPLETED_EARLY(res.data.Data));
        }
      );
    } else if (link === "/working/task-finish-punctual") {
      readList(
        NGUOI_GIAO_LOAD_TASK_PUNCTUAL,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_TASK_COMPLETED_PUNCTUAL(res.data.Data));
        }
      );
    } else if (link === "/working/task-need-give") {
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data)) }
      //   )
      // }

      readList(
        NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data));
        }
      );

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data)) }
      // )
    } else if (link === "/working/task-delay") {
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_DELAY,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_DELAY,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data)) }
      //   )
      // }

      readList(
        NGUOI_GIAO_VIEC_LIST_DELAY,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data));
        }
      );

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_DELAY,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data)) }
      // )
    } else if (link === "/working/task-change-deadline") {
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_DELAY,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_DELAY,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data)) }
      //   )
      // }

      readList(
        NGUOI_GIAO_VIEC_LIST_CHANGE_DEADLINE,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_GIAO_XIN_GIA_HAN(res.data.Data));
        }
      );

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_DELAY,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data)) }
      // )
    } else if (link === "/working/task-end-today") {
      // re check

      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_END_TODAY,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_TOI_HAN_HOM_NAY(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_END_TODAY,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_TOI_HAN_HOM_NAY(res.data.Data)) }
      //   )
      // }

      readList(
        NGUOI_GIAO_VIEC_LIST_END_TODAY,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_TOI_HAN_HOM_NAY(res.data.Data));
        }
      );

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_END_TODAY,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_TOI_HAN_HOM_NAY(res.data.Data)) }
      // )
    } else if (link === "/working/task-end-soon") {
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_END_SOON,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_SAP_TOI_HAN(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_END_SOON,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_SAP_TOI_HAN(res.data.Data)) }
      //   )
      // }

      readList(
        NGUOI_GIAO_VIEC_LIST_END_SOON,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_SAP_TOI_HAN(res.data.Data));
        }
      );

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_END_SOON,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_SAP_TOI_HAN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/task-new") {
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data)) }
      //   )
      // }

      readList(
        NGUOI_NHAN_VIEC_LIST,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data));
        }
      );

      // readList(
      //   NGUOI_NHAN_VIEC_LIST,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => {
      //     dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data))
      //   }
      // )
    } else if (link === "/working/receiver/task-received") {
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST_RECEIVED,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST_RECEIVED,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data)) }
      //   )
      // }

      readList(
        NGUOI_NHAN_VIEC_LIST_RECEIVED,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data));
        }
      );

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_RECEIVED,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/task-processing") {
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST_PROCESSING,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST_PROCESSING,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data)) }
      //   )
      // }

      readList(
        NGUOI_NHAN_VIEC_LIST_PROCESSING,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data));
        }
      );

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_PROCESSING,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/task-delay") {
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST_DELAY,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_TRE_HAN(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST_DELAY,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_TRE_HAN(res.data.Data)) }
      //   )
      // }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_DELAY,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_TRE_HAN(res.data.Data)) }
      // )

      readList(
        NGUOI_NHAN_VIEC_LIST_DELAY,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_NHAN_TRE_HAN(res.data.Data));
        }
      );
    } else if (link === "/working/receiver/task-end-today") {
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY(res.data.Data)) }
      //   )
      // }

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY(res.data.Data)) }
      // )

      readList(
        NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY(res.data.Data));
        }
      );
    } else if (link === "/working/task-finish") {
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_FINISH,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_FINISH,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data)) }
      //   )
      // }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_FINISH,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data)) }
      // )

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_FINISH,
      //   data.pageCurrent,
      //   data.choosePage,
      //   data.searchFilter.taskName,
      //   data.searchFilter.selectedPrior,
      //   data.searchFilter.selectedReceiver,
      //   data.searchFilter.fromDate,
      //   data.searchFilter.toDate,
      //   data.searchFilter.deadLine,
      //   shouldLog,
      //   link,
      //   (res: any) => {
      //     dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data));
      //   }
      // );
    } else if (link === "/working/task-total") {
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_TOTAL,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_GIAO_VIEC_LIST_TOTAL,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data)) }
      //   )
      // }

      // readList(
      //   NGUOI_GIAO_VIEC_LIST_TOTAL,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data)) }
      // )

      readList(
        NGUOI_GIAO_VIEC_LIST_TOTAL,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data));
        }
      );
    } else if (link === "/working/receiver/task-end-soon") {
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST_END_SOON,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST_END_SOON,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN(res.data.Data)) }
      //   )
      // }

      readList(
        NGUOI_NHAN_VIEC_LIST_END_SOON,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN(res.data.Data));
        }
      );

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_END_SOON,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN(res.data.Data)) }
      // )
    } else if (link === "/working/receiver/all-task") {
      // if (checkFilterParams() === true) {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST_ALL,
      //     data.pageCurrent,
      //     data.choosePage,
      //     "",
      //     -1,
      //     -1,
      //     "",
      //     "",
      //     "",
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_TAT_CA(res.data.Data)) }
      //   )
      // } else {
      //   readList(
      //     NGUOI_NHAN_VIEC_LIST_ALL,
      //     data.pageCurrent,
      //     data.choosePage,
      //     data.searchFilter.taskName,
      //     data.searchFilter.selectedPrior,
      //     data.searchFilter.selectedReceiver,
      //     data.searchFilter.fromDate,
      //     data.searchFilter.toDate,
      //     data.searchFilter.deadLine,
      //     shouldLog,
      //     link,
      //     (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_TAT_CA(res.data.Data)) }
      //   )
      // }

      readList(
        NGUOI_NHAN_VIEC_LIST_ALL,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_NHAN_TAT_CA(res.data.Data));
        }
      );

      // readList(
      //   NGUOI_NHAN_VIEC_LIST_ALL,
      //   data.pageCurrent,
      //   data.choosePage,
      //   "",
      //   -1,
      //   -1,
      //   "",
      //   "",
      //   "",
      //   shouldLog,
      //   link,
      //   (res: any) => {
      //     dispatch(ONCHANGE_NGUOI_NHAN_TAT_CA(res.data.Data))
      //   }
      // )
    } else if (link === "/working/task-proccessing") {
      readList(
        NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
        data.pageCurrent,
        data.choosePage,
        data.searchFilter.taskName,
        data.searchFilter.selectedPrior,
        data.searchFilter.selectedReceiver,
        data.searchFilter.fromDate,
        data.searchFilter.toDate,
        data.searchFilter.deadLine,
        shouldLog,
        link,
        (res: any) => {
          dispatch(ONCHANGE_NGUOI_GIAO_DANG_XU_LY(res.data.Data));
        }
      );
    }
  }, [data.pageCurrent, data.choosePage, dispatch]);

  useEffect(() => {
    if (data.receiverList === null) {
      loadReceiver(
        (res: any) => dispatch(ONLOAD_RECEIVERLIST(res.data.Data)),
        shouldLog1
      );
    }

    if (data.priorList === null) {
      loadPrior(
        (res: any) => dispatch(ONLOAD_PRIORLIST(res.data.Data)),
        shouldLog2
      );
    }

    if (data.bossList === null) {
      loadBoss(
        (res: any) => dispatch(ONLOAD_BOSS_LIST(res.data.Data)),
        shouldLog3
      );
    }
  });

  return (
    <div className="flex flex-col w-full h-full">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-1.8rem)] pl-5 pr-5 text-sm">
        <SubmitHeader />

        {/* for web */}
        <div className="lg:flex flex-col w-full lg:h-[8rem] hidden h-max space-y-3">
          {/* top */}
          <ContentTop />

          {/* bottom */}
          {
            link === "/voting/voting_wait" || link === "/voting/voting_work" ? <></>
              : <ContentBottom />
          }

        </div>

        {/* for mobile */}
        <div className="lg:hidden flex-col w-full lg:h-[8rem] flex h-max space-y-3">
          <div className="">
            <button
              className="bg-blue-400 p-1 rounded-md hover:bg-blue-300 text-white flex justify-center items-center"
              onClick={_onChangeIsShowFilter}
            >
              <p>Tìm kiếm</p>
              {isShowFilter === false ? (
                <ArrowDropDownIcon fontSize="small" />
              ) : (
                <ArrowDropUpIcon fontSize="small" />
              )}
            </button>
          </div>

          <div className={`${isShowFilter === false ? "hidden" : ""}`}>
            {/* top */}
            <ContentTop />

            {/* bottom */}
            {
              link === "/voting/voting_wait" || link === "/voting/voting_work" ? <></>
                : <ContentBottom />
            }
          </div>
        </div>

        {/* table */}
        <Table />
      </div>
    </div>
  );
};

export default ContentCore;
