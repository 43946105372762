/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Title from "../../../components/title";
import Table from "../../../components/table";
import { GROUP_READ_ALL_DROPDOWN } from "../../../apis/_index";
import { ONLOAD_GROUPLIST_DROPDOWN, ONLOAD_FUNCTIONLIST, ONCHANGE_FUNCTIONCHOOSE } from "../../../redux/reducers/userReducer/_index";
import { SYS_PERMISSION, SYS_PERMISSION_LOADFUNC } from "../../../apis/_index";
import { toast } from "react-toastify";

import { ModalController } from "../../homeUI/_index";
import axios from "axios";

import Select from "react-select"

const FunctionalListUI = () => {

  const data = useSelector((state: any) => state.userReducer);
  const dispatch = useDispatch();

  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);

  const context = useContext(ModalController);

  const [selectedValue, setSelectedValue] = useState<any>(0);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog.current) {
      shouldLog.current = false;

      try {
        const takeData = async () => {
          await axios.get(
            GROUP_READ_ALL_DROPDOWN,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_GROUPLIST_DROPDOWN(res.data.Data))
              }
            })
        }

        takeData();
      } catch (error: any) {
        toast.error("Tải dữ liệu thất bại");
      }
    }
  })

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog2.current) {
      shouldLog2.current = false;

      const takeData = async () => {
        try {
          await axios.get(
            SYS_PERMISSION,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              dispatch(ONLOAD_FUNCTIONLIST(res.data.Data))
            })
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại.")
        }
      }

      takeData();
    }
  })

  useEffect(() => {
    if (selectedValue === 0) {
      return;
    } else {
      const accessToken = localStorage.getItem("accessToken");
      const API = SYS_PERMISSION_LOADFUNC + data.functionChoose.toString();

      const takeData = async () => {
        try {
          await axios.get(
            API,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_FUNCTIONLIST(res.data.Data))
              }
            })
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại.")
        }
      }

      takeData();
    }
  }, [selectedValue, dispatch])

  const tempArray = data.groupListDropdown?.map((item: any) => { return { ...item, label: item.GroupName, value: item.GroupName } })

  // funcs 
  const _onChangeData = (data: any) => {
    if (data === null) {
      setSelectedValue(0) 
      dispatch(ONCHANGE_FUNCTIONCHOOSE(0))
    } else {
      setSelectedValue(data.ID)
      dispatch(ONCHANGE_FUNCTIONCHOOSE(data.ID))
    }
  }

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-1.8rem)] text-sm pl-5 pr-5 pt-3 pb-3">
        <div className="flex w-full h-max justify-between space-x-5">
          <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label className="text-xs">Nhóm quyền</label>

            {/* <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={data.functionChoose}
              onChange={(e: any) => {
                e.preventDefault();
                setSelectedValue(parseInt(e.target.value))
                dispatch(ONCHANGE_FUNCTIONCHOOSE(parseInt(e.target.value)))
              }}
            >
              <option className="text-gray-300" value={0} disabled>--- Chọn nhóm quyền ---</option>
              {data.groupListDropdown === null ? ""
                : data.groupListDropdown.map((item: any, index: any) => (
                  <option key={index} value={item.ID}>{item.GroupName}</option>
                ))
              }
            </select> */}


          <Select
            className="z-[5]"
            options={tempArray}
            onChange={(data: any) => _onChangeData(data)}
            isClearable
            placeholder="--- Chọn người giao ---"
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
          />

          </div>

          <div className="w-2/3 h-max flex flex-col">
            <label className="text-xs">Tìm kiếm</label>
            <input
              className="w-max h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Tìm kiếm chức năng"
            />
          </div>
        </div>

        <Table />
      </div>
    </div>
  )
}

export default FunctionalListUI;
