import React from "react";
import DeleteIcon from '@mui/icons-material/Delete';

import { XOA_TIEU_CHI_DANH_GIA } from "../../../apis/_index";

import axios from "axios";
import { toast } from "react-toastify";

const DELETE_TIEUCHI = (props: any) => {

  // take props
  const taskId = props.taskId;
  const matieuchi = props.matieuchi;
  const handleClose = props.handleClose;

  const _onClickDeleteTieuChi = async () => {
    const accessToken = localStorage.getItem("accessToken")

    const body = {
        id: taskId,
        matieuchi: matieuchi
    }

    try {
        await axios.post(
            XOA_TIEU_CHI_DANH_GIA,
            body,
            {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    Accept: "text/plain"
                }
            }
        )
        .then((res: any) => {
            toast.success("Xoa thanh cong")
            window.location.reload()
        })
    } catch (error: any) {
        toast.error("Xoa that bai")
    }
  }

  return (
    <div className="
      flex bg-red-400
      lg:w-[30rem] w-screen h-[10rem]
      rounded-md text-white
    ">
      {/* icon */}
      <div className="w-1/4 h-full  flex justify-center items-center rounded-l-md border-r-[1px] border-slate-200">
        <DeleteIcon sx={{ fontSize: 100 }} />
      </div>

      {/* content */}
      <div className="flex flex-col space-y-10 items-center justify-center w-3/4 h-full rounded-r-md">
        <p>Bạn có đồng ý <span className="font-semibold">XOÁ</span> Tiêu chí này không?</p>
        <div className="w-full h-max flex justify-center items-center space-x-12">
          <button className="w-[5rem] h-max pt-1 pb-1 bg-slate-500 rounded-md hover:bg-slate-400"
            onClick={_onClickDeleteTieuChi}
          >
            Có
          </button>
          <button className="w-[5rem] h-max pt-1 pb-1 bg-red-500 rounded-md hover:bg-red-300"
            onClick={handleClose}
          >
            Không
          </button>
        </div>
      </div>
    </div>
  )
}

export default DELETE_TIEUCHI;
