import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { HexColorPicker } from "react-colorful";
import { SketchPicker } from "react-color";
import SubModal from "../_subModal/_index";

import AddCircleOutlineTwoToneIcon from "@mui/icons-material/AddCircleOutlineTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import ModeTwoToneIcon from "@mui/icons-material/ModeTwoTone";

import { takePermission } from "../../../funcs";

import { onTouchForm } from "../../../funcs/onTochForm";

import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import axios from "axios";

import { PRIOR_DETAIL, PRIOR_UPDATE, PRIOR_LIST } from "../../../apis/_index";
import { ONLOAD_PRIORLIST } from "../../../redux/reducers/settingReducer/_index";
import { toast } from "react-toastify";

const DetailPrior = (props: any) => {
  const taskId = props.taskId;
  const handleClose = props.handleClose;
  const shouldLog = useRef(true);

  const menu = takePermission(13);

  const dispatch = useDispatch();

  // state
  const [priorState, setPriorState] = useState<any>("");
  const [priorDescription, setPriorDescription] = useState<any>("");
  const [priorLevel, setPriorLevel] = useState<any>("");
  const [priorColor, setPriorColor] = useState<any>("#aabbcc");

  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  // funcs
  const _onClickDelete = () => {
    handleOpenSub();
    setFuncsSub("deletePrior");
  };

  const _onChangePriorState = (e: any) => {
    e.preventDefault();
    setPriorState(e.target.value);
  };

  const _onChangePriorDescription = (e: any) => {
    e.preventDefault();
    setPriorDescription(e.target.value);
  };

  const _onChangePriorLevel = (e: any) => {
    e.preventDefault();
    setPriorLevel(e.target.value);
  };

  const _onClickSubmit = async () => {
    const accessToken = localStorage.getItem("accessToken");

    const body = {
      Id: taskId,
      PriorityLevelName: priorState,
      Color: priorColor,
      Description: priorDescription,
      LevelNumber: priorLevel,
    };

    try {
      await axios
        .post(PRIOR_UPDATE, body, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
        })
        .then((res: any) => {
          if (res.status === 200) {
            const takeData = async () => {
              try {
                await axios
                  .get(PRIOR_LIST, {
                    headers: {
                      Authorization: "Bearer " + accessToken,
                      Accept: "text/plain",
                    },
                  })
                  .then((res: any) => {
                    if (res.status === 200) {
                      dispatch(ONLOAD_PRIORLIST(res.data.Data));
                      toast.success("Cập nhật thành công.");
                      handleClose();
                    } else {
                      toast.error("Cập nhật thất bại.");
                    }
                  });
              } catch (error: any) {
                toast.error("Cập nhật thất bại.");
              }
            };

            takeData();
          } else {
            toast.error("Cập nhật thất bại.");
          }
        });
    } catch (error: any) {
      toast.error("Cập nhật thất bại.");
    }
  };

  useEffect(() => {
    if (
      priorState === "" &&
      priorDescription === "" &&
      priorLevel === "" &&
      priorColor === "#aabbcc" &&
      shouldLog.current
    ) {
      const accessToken = localStorage.getItem("accessToken");
      const API = PRIOR_DETAIL + taskId.toString();
      shouldLog.current = false;

      const takeData = async () => {
        try {
          await axios
            .get(API, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              setPriorState(res.data.Data[0].PriorityLevelName);
              setPriorDescription(res.data.Data[0].Description);
              setPriorLevel(res.data.Data[0].LevelNumber);
              setPriorColor(res.data.Data[0].Color);
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại.");
        }
      };

      takeData();
    }
  });

  return (
    <div
      className="
    flex flex-col bg-white
    lg:w-[52rem] w-screen lg:h-[40rem] h-[calc(100vh-10rem)]
    rounded-md
  "
    >
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
      />

      {/* header */}
      <div
        className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Chi tiết / Chỉnh sửa</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>
              Tên mức độ&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={priorState}
              onChange={(e: any) => {
                _onChangePriorState(e);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-col w-full h-max">
            <label>
              Mô tả&nbsp;<span className="text-red-500">(*)</span>
            </label>
            <textarea
              className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập mô tả"
              value={priorDescription}
              onChange={(e: any) => {
                _onChangePriorDescription(e);
              }}
            />
          </div>
        </div>

        <div className="flex w-full h-max space-x-6">
          <div className="w-1/3 h-max flex flex-col space-y-6">
            <div className="flex items-center w-1/2 justify-between h-max">
              <label className="font-bold text-primary">Hiển thị </label>
              <input type="checkbox" />
            </div>

            <div className="flex lg:flex-row flex-col items-center w-1/2 justify-between h-max">
              <label className="font-bold text-primary">Mức độ</label>
              <input
                className="lg:w-1/3 w-full h-max rounded-md pl-2 pr-2 border-2 border-slate-200"
                value={priorLevel}
                onChange={(e: any) => {
                  _onChangePriorLevel(e);
                }}
                onTouchStart={(e: any) => onTouchForm(e)}
              />
            </div>
          </div>

          <div className="flex flex-col w-2/5 h-max">
            <p>Màu sắc</p>

            <div className="w-full h-max ">
              <div className="w-[6rem] h-[6rem]">
                <HexColorPicker color={priorColor} onChange={setPriorColor} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <div
        className={`flex w-full h-[3rem] bg-slate-100 rounded-b-md ${
          menu.PerDelete === true ? "justify-between" : "justify-end"
        } items-center pl-4 pr-4 text-sm space-x-4`}
      >
        <button
          className={`
          items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-500 text-white rounded-md
          hover:bg-slate-400
          ${menu.PerDelete === true ? "flex" : "hidden"}
        `}
          onClick={_onClickDelete}
        >
          <DeleteIcon fontSize="small" />
          <p>Xoá</p>
        </button>

        <div className="flex w-max h-max space-x-3">
          <button
            className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-primary text-white rounded-md
          hover:bg-sky-400
        "
            onClick={_onClickSubmit}
          >
            <SaveIcon fontSize="small" />
            <p>Cập nhật</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailPrior;
