import React, { useState, useEffect, useRef } from "react";
import Title from "../../../../components/title";
import SubmitHeader from "../../../../components/submitHeader";
import ContentTop from "../../../../components/contentTop";

import Chart, { CategoryScale } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie, Bar } from "react-chartjs-2";

import { readReport } from "../../../../funcs/reportCalling";
import { takePhongban, takeUser } from "../../../../funcs/reportCalling";
import { REPORT_PERSONAL_SUMWORK } from "../../../../apis/_index";
import { randomColor } from "../../../../funcs/reportCalling";
import shadows from "@mui/material/styles/shadows";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const PersonalSumWorkReportUI = (props: any) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear(); // Lấy năm hiện tại
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Lấy tháng hiện tại (0-11, nên cộng thêm 1)
  const day = currentDate.getDate().toString().padStart(2, "0"); // Lấy ngày hiện tại

  const [dataCot, setDataCot] = useState<any[] | null>(null);
  const [dataTile, setDataTile] = useState<any[] | null>(null);

  const [arrayName, setArrayName] = useState<any[] | null>(null);
  const [arraySoluong, setArraySoluong] = useState<any[] | null>(null);
  const [arrayColor, setArrayColor] = useState<any[] | null>(null);

  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [selectedPhongban, setSelectedPhongban] = useState<any>(-1);
  const [userList, setUserList] = useState<any[] | null>(null);
  const [selectedUserList, setSelectedUserList] = useState<any>(-1);

  const [arrayPercentTile, setArrayPercentTile] = useState<any[] | null>(null);
  const [arrayTotalThongKe, setArrayTotalThongKe] = useState<any[] | null>(
    null
  );

  const [fromDate, setFromDate] = useState<any | null>(
    new Date().getFullYear() + "-01-01"
  );
  const [toDate, setToDate] = useState<any | null>(
    new Date().getFullYear() + "-12-31"
  );

  const arrayNameTemp = [
    "Công việc đuợc giao trong thời gian này",
    "Công việc chưa tiếp nhận",
    "Công việc đang xử lý",
    "Công việc đã hoàn thành",
    "Công việc đang trễ hạn",
    "Công việc hỗ trợ",
  ];

  const shouldLog = useRef(true);

  // first call
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const body = {
        TuNgay: fromDate,
        DenNgay: toDate,
        _isCurUser: true,
      };

      readReport(REPORT_PERSONAL_SUMWORK, body, (res: any) => {
        setDataTile(res.data.Data.BieuDoTiLe);
        setDataCot(res.data.Data.BieuDoCot);
      });

      takePhongban((res: any) => setPhongBanList(res.data.Data));
      takeUser((res: any) => setUserList(res.data.Data));
    }
  });

  // follow first call
  useEffect(() => {
    if (dataTile === null) {
    } else {
      let tempArray: any[] = [];
      let tempMau: any[] = [];

      dataTile.forEach((item: any) => {
        let tempValue = item._percent.toFixed(2);
        let tempColor = randomColor();

        tempArray.push(tempValue);
        tempMau.push(tempColor);
      });

      setArrayPercentTile(tempArray);
      setArrayColor(tempMau);
    }
  }, [dataTile]);

  useEffect(() => {
    if (dataCot === null) {
    } else {
      let tempArray: any[] = [];

      dataCot.forEach((item: any) => {
        let tempValue = item.SoLuong;

        tempArray.push(tempValue);
      });

      setArrayTotalThongKe(tempArray);
    }
  }, [dataCot]);

  const _onClickSubmit = () => {
    let body: any;

    body = {
      TuNgay: fromDate,
      DenNgay: toDate,
      _isCurUser: true,
    };

    readReport(REPORT_PERSONAL_SUMWORK, body, (res: any) => {
      setDataTile(res.data.Data.BieuDoTiLe);
      setDataCot(res.data.Data.BieuDoCot);
    });
  };
  return (
    <>
      <br></br>
      <div className="flex lg:flex-row flex-col w-full h-max pl-2">
        <div className="flex flex-col w-full lg:h-[4rem] h-max space-y-3">
          <div className="flex lg:flex-row flex-col w-full h-max">
            <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
              <label className="drop-shadow-lg">Từ ngày</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Từ Ngày"
                type="date"
                value={fromDate}
                onChange={(e: any) => {
                  e.preventDefault();
                  setFromDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full lg:h-[4rem] h-max space-y-3">
          <div className="flex lg:flex-row flex-col w-full h-max">
            <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
              <label className="drop-shadow-lg">Đến ngày</label>
              <input
                className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Đến Ngày"
                value={toDate}
                type="date"
                onChange={(e: any) => {
                  e.preventDefault();
                  setToDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max" >
          <label>Phòng ban</label>
          <select
            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
            value={selectedPhongban}
            onChange={(e: any) => {
              e.preventDefault()

              setSelectedPhongban(parseInt(e.target.value))
            }}
          >
            <option className="text-gray-300" value={-1}>--- Xem tất cả ---</option>
            {
              phongBanList === null ? <></>
                : phongBanList.map((item: any, index: any) => (

                <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                ))
            }
          </select>
        </div>
        <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
          <label>Nhân viên</label>
          <select
            className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
            value={selectedUserList}
            onChange={(e: any) => {
              e.preventDefault()
              setSelectedUserList(e.target.value)
            }}
          >
            <option className="text-gray-300" value={'-1'}>--- Xem tất cả ---</option>
            {
              userList === null ? <></>
                : userList.map((item: any, index: any) => (
                  <option key={index} value={item.Id}>{item.FullName}</option>
                ))
            }
          </select>
        </div> */}
        <div
          className="flex flex-col lg:w-1/2 w-full lg:pr-7 pr-0 h-max"
          style={{ textAlign: "center" }}
        >
          <label>&nbsp; </label>
          <button
            className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400 active:shadow-inner active:shadow-slate-500 
            "
            onClick={_onClickSubmit}
          >
            <p style={{ width: "100%", textAlign: "center" }}>Xem</p>
          </button>
        </div>
      </div>
      <hr style={{ margin: "20px" }}></hr>

      {dataCot === null ||
      dataTile === null ||
      arrayColor === null ||
      dataCot === null ? (
        <></>
      ) : (
        <div className="flex lg:flex-row flex-col w-full lg:h-[calc(100%-8rem)] h-max lg:divide-x-[2px] lg:space-y-0 space-y-5 pb-3">
          {/* left */}
          <div className="flex flex-col lg:w-1/2 w-full lg:h-full h-[30rem] pr-3">
            <div className="w-full lg:h-1/2 h-full">
              <div className="flex flex-col w-full h-full shadow-md rounded-sm bg-white">
                <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold">
                  BIỂU ĐỒ THỐNG KÊ SỐ LƯỢNG CÔNG VIỆC
                </div>
                <div className="flex justify-center items-center w-full h-[calc(100%-2rem)]">
                  <Bar
                    className="w-1/2 h-full drop-shadow-lg"
                    datasetIdKey="id"
                    data={{
                      labels: arrayNameTemp,
                      datasets: [
                        {
                          label: "Tổng",
                          data: arrayTotalThongKe,
                          backgroundColor: arrayColor,
                        },
                      ],
                    }}
                    options={{
                      indexAxis: "y",
                      maintainAspectRatio: false,
                      plugins: {
                        datalabels: {
                          color: "white",
                          font: {
                            size: 13,
                            weight: "bold",
                          },
                          formatter: (value: any) => `${value}`,
                          anchor: "center",
                          align: "center",
                        },
                        legend: {
                          display: false,
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* right */}
          <div className="flex flex-col lg:w-1/2 w-full lg:h-full h-[30rem] space-y-4 pl-3">
            <div className="w-full h-full flex flex-col">
              <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold">
                BIỂU ĐỒ TỈ LỆ SỐ LƯỢNG CÔNG VIỆC
              </div>
              <div className="flex justify-center items-center w-full h-[calc(100%-2rem)] shadow-lg ">
                <Pie
                  className="w-full h-full"
                  datasetIdKey="id"
                  data={{
                    labels: [
                      "Công việc chưa tiếp nhận",
                      "Công việc đang xử lý",
                    ],
                    datasets: [
                      {
                        label: "Tỉ lệ",
                        data: arrayPercentTile,
                        backgroundColor: arrayColor,
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      datalabels: {
                        color: "white",
                        font: {
                          size: 15,
                          weight: "bold",
                        },
                        // formatter: (value: any) => `${value}%`,
                        formatter: (value: any, context: any) => {
                          return `${Math.round(value * 100) / 100} %`;
                        },
                        clamp: true,
                      },
                      legend: {
                        position: "bottom",
                        labels: {
                          usePointStyle: true,
                          font: {
                            size: 11,
                          },
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalSumWorkReportUI;
