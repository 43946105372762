import React from "react";
import { Modal } from "@mui/material";

// components importing
import MultipleChoicePartner from "./multipleChoicePartner";
import MultipleChoiceStep from "./multipleChoiceStep";
import DeleteTask from "./deleteTask";
import SwitchTaskToBoss from "./switchBoss";
import GiveTask from "./giveTask";

import PlanSend from "./planSend";
import PlanDelete from "./planDelete";

import EditDeadLineNguoiGiao from "./editDeadLineNguoiGiao";

import DeleteTaskList from "./deleteTaskList";
import DeletePrior from "./deletePrior";
import DeleteUser from "./deleteUser";
import DeleteEmployee from "./deleteEmployee";

import DeleteGeneral from "./deleteGeneral";
import ChoosePermission from "./choosePermission";

import ConfirmReport from "./confirmReport";

import DeleteSecurity from "./deleteSecurity";
import DeleteQuytrinh from "./deleteQuytrinh";

import EditBuoc from "./editBuoc";
import EditKetqua from "./editKetqua";

import ReceiveTask from "./receiveTask";

import DELETE_TIEUCHI from "./deleteTieuChi";
import StartTaskSub from "./startTaskSub";
import FinishTask from "./finishTask";
import AskExtend from "./askExtend";
import ConfirmExtend from "./confirmExtend";
import ReTasking from "./reTasking";
import ConfirmTask from "./confirmTask";

import CheckPlanning from "./checkPlanning";

import DetailOfTask from "./_detailOfTask";

import DeleteDificult from "./deleteDificultRate";

import NotiTask from "./notiTask";
import CompletedTaskContent from "./completedTaskContent";

import ComfirmCompleteTask from "./confirmCompleteTask";
import SwitchBoss from "./switchBoss";

import EditPlanning from "./editPlan";

import MultipleUploadFileContent from "./multipleUploadFileContent";
import ConfirmTaskContents from "./confirmTaskContent";
import ChatPopup from "./chatPopup";
import VotingTask from "../working/votingTask";

import CreateWorkflowSub from "./createWorkFlowSub";

import CheckViewPort from "./checkViewport";

import ReportDelete from "./reportDelete";
import ReportSend from "./reportSend";

import ChangeTaskReviewer from "./changeTaskReviewer"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const SubModal = (props: any) => {
  // take props
  const funcs = props.funcs;
  const open = props.open;
  const handleClose = props.handleClose;

  const taskId = props.taskId;
  const isResult = props.isResult;
  const timeExtend = props.timeExtend;
  const extendId = props.extendId;
  const id = props.id;
  const taskOwner = props.taskOwner;
  const maTieuChi = props.maTieuChi;
  const bodyOfReport = props.bodyOfReport

  // -- tofile
  const taskContentHistoryId = props.taskContentHistoryId;
  const taskContentID = props.taskContentID;
  const taskContentsHistory = props.taskContentsHistory;
  const _handleCheckCompled = props._handleCheckCompled;
  const isConfirm = props.isConfirm;

  // -- end tofile
  const nhomquyenList = props.nhomquyenList;
  const selectedNhomquyen = props.selectedNhomquyen;
  const _onChangeSelectedNhomquyen = props._onChangeSelectedNhomquyen;

  const selectedNguoiPhoiHopList = props.selectedNguoiPhoiHopList;
  const selectedNguoiLamViecList = props.selectedNguoiLamViecList;
  const selectedBuocList = props.selectedBuocList;

  const _onCheckNguoiPhoiHop = props._onCheckNguoiPhoiHop;

  const positionStep = props.positionStep;
  const hourStep = props.hourStep;
  const contentStep = props.contentStep;
  const _onChangePositionStep = props._onChangePositionStep;
  const _onChangeHourStep = props._onChangeHourStep;
  const _onChangeContentStep = props._onChangeContentStep;
  const _onPushStep = props._onPushStep;
  const _onDeleteQuyTrinh = props._onDeleteQuyTrinh;
  const _onChangeStepList = props._onChangeStepList;

  const selectedKetQuaList = props.selectedKetQuaList;
  const ketqua = props.ketqua;
  const _onPushKetqua = props._onPushKetqua;
  const _onChangeKetqua = props._onChangeKetqua;
  const _onDeleteKetqua = props._onDeleteKetqua;
  const isViewHistoty = props.isViewHistoty;
  const _taskContents = props._taskContents;

  const taskCode = props.taskCode;
  const isOwner = props.isOwner;

  const taskInfoText = props.taskInfoText;
  const task = props.task;

  // TODO: Setting backdropClick
  const closeModal = (event: any, reason: any) => {
    if (reason !== "backdropClick") {
      handleClose(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={style} className="w-max h-max">
        {funcs === "multipleChoicePartner" ? (
          <MultipleChoicePartner
            _onCheckNguoiPhoiHop={_onCheckNguoiPhoiHop}
            selectedNguoiPhoiHopList={selectedNguoiPhoiHopList}
            selectedNguoiLamViecList={selectedNguoiLamViecList}
            handleClose={handleClose}
          />
        )

          : funcs === "checkViewPort" ? (
            <CheckViewPort bodyOfReport={bodyOfReport} handleClose={handleClose} />
          )

            : funcs === "checkPlanning" ? (
              <CheckPlanning bodyOfReport={bodyOfReport} handleClose={handleClose} />
            )

              : funcs === "reportDelete" ? (
                <ReportDelete taskId={taskId} handleClose={handleClose} />
              )

              : funcs === "confirmReport" ? (
                <ConfirmReport taskId={taskId} handleClose={handleClose} />
              )

                : funcs === "editDeadLine" ? (
                  <EditDeadLineNguoiGiao timeExtend={timeExtend} taskId={taskId} handleClose={handleClose} />
                )

                  : funcs === "planDelete" ? (
                    <PlanDelete taskId={taskId} handleClose={handleClose} />
                  )

                    : funcs === "reportSend" ? (
                      <ReportSend taskId={taskId} handleClose={handleClose} />
                    )

                      : funcs === "editPlanning" ? (
                        <EditPlanning taskId={taskId} handleClose={handleClose} />
                      )

                        : funcs === "planSend" ? (
                          <PlanSend taskId={taskId} handleClose={handleClose} />
                        )

                          : funcs === "detailOfTask" ? (
                            <DetailOfTask taskId={taskId} handleClose={handleClose} />
                          )

                            : funcs === "multipleChoiceStep" ? (
                              <MultipleChoiceStep handleClose={handleClose} />
                            ) : funcs === "deleteTask" ? (
                              <DeleteTask taskId={taskId} handleClose={handleClose} />
                            ) : funcs === "giveTask" ? (
                              <GiveTask taskId={taskId} handleClose={handleClose} />
                            ) : funcs === "switchBoss" ? (
                              <SwitchTaskToBoss
                                taskOwner={taskOwner}
                                taskId={taskId}
                                handleClose={handleClose}
                              />
                            ) : funcs === "changeTaskReviewer" ? (
                              <ChangeTaskReviewer taskId={taskId} handleClose={handleClose} />
                            ) : funcs === "deleteTieuChi" ? (
                              <DELETE_TIEUCHI
                                maTieuChi={maTieuChi}
                                taskId={taskId}
                                handleClose={handleClose}
                              />
                            ) : funcs === "reTasking" ? (
                              <ReTasking
                                taskCode={taskCode}
                                taskId={taskId}
                                handleClose={handleClose}
                              />
                            ) : funcs === "finishTask" ? (
                              <FinishTask
                                taskCode={taskCode}
                                taskId={taskId}
                                handleClose={handleClose}
                              />
                            ) : funcs === "askExtend" ? (
                              <AskExtend
                                taskCode={taskCode}
                                taskId={taskId}
                                handleClose={handleClose}
                              />
                            ) : funcs === "confirmExtend" ? (
                              <ConfirmExtend
                                taskId={taskId}
                                extendId={extendId}
                                timeExtend={timeExtend}
                                isResult={isResult}
                                handleClose={handleClose}
                              />
                            ) : funcs === "receiveTaskSub" ? (
                              <ReceiveTask taskId={taskId} handleClose={handleClose} />
                            ) : funcs === "startTaskSub" ? (
                              <StartTaskSub taskId={taskId} handleClose={handleClose} />
                            ) : funcs === "notiTask" ? (
                              <NotiTask taskId={taskId} handleClose={handleClose} />
                            ) : funcs === "completedTaskContent" ? (
                              <CompletedTaskContent
                                taskContentHistoryId={taskContentHistoryId}
                                taskId={taskId}
                                handleClose={handleClose}
                              />
                            ) : funcs === "deleteDificult" ? (
                              <DeleteDificult taskId={taskId} handleClose={handleClose} />
                            ) : funcs === "deleteTaskList" ? (
                              <DeleteTaskList taskId={taskId} handleClose={handleClose} />
                            ) : funcs === "deletePrior" ? (
                              <DeletePrior taskId={taskId} handleClose={handleClose} />
                            ) : funcs === "deleteUser" ? (
                              <DeleteUser taskId={taskId} handleClose={handleClose} />
                            ) : funcs === "deleteEmployee" ? (
                              <DeleteEmployee taskId={taskId} handleClose={handleClose} />
                            ) : funcs === "deleteGeneral" ? (
                              <DeleteGeneral taskId={taskId} handleClose={handleClose} />
                            ) : // : funcs === "deleteEmployee" ? <DeleteEmployee taskId={taskId} handleClose={handleClose} />
                              funcs === "deleteGeneral" ? (
                                <DeleteGeneral taskId={taskId} handleClose={handleClose} />
                              ) : funcs === "chatPopup" ? (
                                <ChatPopup
                                  taskId={taskId}
                                  taskInfoText={taskInfoText}
                                  handleClose={handleClose}
                                  _handleCheckCompled={_handleCheckCompled}
                                />
                              ) : funcs === "votingTask" ? (
                                <VotingTask taskId={taskId} handleClose={handleClose} />
                              ) : funcs === "confirmCompleteTask" ? (
                                <ComfirmCompleteTask
                                  id={id}
                                  taskId={taskId}
                                  taskCode={taskCode}
                                  task={task}
                                  _handleCheckCompled={_handleCheckCompled}
                                  handleClose={handleClose}
                                />
                              ) : funcs === "confirmTask" ? (
                                <ConfirmTask taskId={taskId} handleClose={handleClose} />
                              ) : funcs === "multipleUploadFileContent" ? (
                                <MultipleUploadFileContent
                                  task={task}
                                  handleClose={handleClose}
                                  taskContentsHistory={taskContentsHistory}
                                  taskContentHistoryId={taskContentHistoryId}
                                  taskId={taskId}
                                  isViewHistoty={isViewHistoty}
                                  _handleCheckCompled={_handleCheckCompled}
                                  _taskContents={_taskContents}
                                />
                              ) : funcs === "confirmTaskContents" ? (
                                <ConfirmTaskContents
                                  taskContentID={taskContentID}
                                  taskContentHistoryId={taskContentHistoryId}
                                  isConfirm={isConfirm}
                                  isOwner={isOwner}
                                  _handleCheckCompled={_handleCheckCompled}
                                  handleClose={handleClose}
                                />
                              ) : funcs === "deleteQuytrinh" ? (
                                <DeleteQuytrinh taskId={taskId} handleClose={handleClose} />
                              ) : funcs === "deleteSecurity" ? (
                                <DeleteSecurity taskId={taskId} handleClose={handleClose} />
                              ) : funcs === "editBuoc" ? (
                                <EditBuoc
                                  positionStep={positionStep}
                                  hourStep={hourStep}
                                  contentStep={contentStep}
                                  _onChangePositionStep={_onChangePositionStep}
                                  _onChangeHourStep={_onChangeHourStep}
                                  _onChangeContentStep={_onChangeContentStep}
                                  _onPushStep={_onPushStep}
                                  _onDeleteQuyTrinh={_onDeleteQuyTrinh}
                                  selectedBuocList={selectedBuocList}
                                  _onChangeStepList={_onChangeStepList}
                                  taskId={taskId}
                                  handleClose={handleClose}
                                />
                              ) : funcs === "editKetqua" ? (
                                <EditKetqua
                                  selectedKetQuaList={selectedKetQuaList}
                                  _onChangeKetqua={_onChangeKetqua}
                                  _onPushKetqua={_onPushKetqua}
                                  _onDeleteKetqua={_onDeleteKetqua}
                                  ketqua={ketqua}
                                  _onChangeStepList={_onChangeStepList}
                                  taskId={taskId}
                                  handleClose={handleClose}
                                />
                              ) : funcs === "choosePermission" ? (
                                <ChoosePermission
                                  nhomquyenList={nhomquyenList}
                                  selectedNhomquyen={selectedNhomquyen}
                                  _onChangeSelectedNhomquyen={_onChangeSelectedNhomquyen}
                                  handleClose={handleClose}
                                />
                              ) : funcs === "createWorkflowSub" ? (
                                <CreateWorkflowSub handleClose={handleClose} />
                              ) : (
                                ""
                              )}
      </div>
    </Modal>
  );
};

export default SubModal;
