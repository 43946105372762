import React, { useEffect, useRef, useState } from "react";

import { 
  READ_PARAMETER, 
  USER_READ_ALL, 
  REPORT_RANKING,
  USER_READ_ALL_DROPDOWN, 
  YEAR_LIST 
} from "../../../../apis/_index";
import { toast } from "react-toastify";
import axios from "axios";
import LoaderSpinner from "../../../../components/loader-spinner/_index";

import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';

import SearchIcon from '@mui/icons-material/Search';
import ReplayIcon from '@mui/icons-material/Replay';

const ReportRankingUI = () => {

  const [yearList, setYearList] = useState<any[] | null>(null)
  const [userList, setUserList] = useState<any[] | null>(null)
  const [boPhanList, setBoPhanList] = useState<any[] | null>(null)
  const [chucVuList, setChucVuList] = useState<any[] | null>(null)
  const [phongBanList, setPhongBanList] = useState<any[] | null>(null)

  const [searchYear, setSearchYear] = useState<null>(null)
  const [searchUser, setSearchUser] = useState<any>(null)
  const [searchBophan, setSearchBoPhan] = useState<any>(null)
  const [searchChucVu, setSearchChucVu] = useState<any>(null)
  const [searchPhongBan, setSearchPhongBan] = useState<any>(null)

  const [data, setData] = useState<any[] | null>()


  const shouldLog = useRef(true)
  const shouldLog2 = useRef(true)

  // load primitive data
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken")

    const API_BOPHAN = READ_PARAMETER + "?key=LI_BOPHAN"
    const API_CHUCVU = READ_PARAMETER + "?key=LI_CHUCVU"
    const API_PHONGBAN = READ_PARAMETER + "?key=LI_PHONGBAN"

    if (shouldLog.current === true) {
      shouldLog.current = false

      const takeYearList = async () => {
        try {
          await axios.get(
            YEAR_LIST,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setYearList(res.data.Data.data)
              }
            })
        } catch (error) {
          toast.error("Tải dữ liệu thất bại")
        }
      }

      const takeUserList = async () => {
        try {
          await axios.get(
            USER_READ_ALL_DROPDOWN,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setUserList(res.data.Data)
              }
            })
        } catch (error) {
          toast.error("Tải dữ liệu thất bại")
        }
      }

      const takeBoPhanList = async () => {
        try {
          await axios.get(
            API_BOPHAN,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setBoPhanList(res.data.Data)
              }
            })
        } catch (error) {
          toast.error("Tải dữ liệu thất bại")
        }
      }

      const takeChucVuList = async () => {
        try {
          await axios.get(
            API_CHUCVU,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setChucVuList(res.data.Data)
              }
            })
        } catch (error) {
          toast.error("Tải dữ liệu thất bại")
        }
      }

      const takePhongBanList = async () => {
        try {
          await axios.get(
            API_PHONGBAN,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain"
              }
            }
          )
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setPhongBanList(res.data.Data)
              }
            })
        } catch (error) {
          toast.error("Tải dữ liệu thất bại")
        }
      }

      takeYearList()
      takeUserList()
      takeBoPhanList()
      takeChucVuList()
      takePhongBanList()
    }
  }, [])

  // load data
  useEffect(() => {
    _onLoadData()
  }, [])

  // funcs
  const _onChangeParam = (type: string, e: any) => {
    e.preventDefault()

    switch (type) {
      case "year":
        setSearchYear(e.target.value)
        break
      case "user":
        setSearchUser(e.target.value)
        break
      case "phongBan":
        setSearchPhongBan(e.target.value)
        break
      case "boPhan":
        setSearchBoPhan(e.target.value)
        break
      case "chucVu":
        setSearchChucVu(e.target.value)
        break

      default:
        break
    }
  }

  const _onLoadData = () => {
    const accessToken = localStorage.getItem("accessToken")


    const body = {
      Search_UserId: searchUser === "-1" ? null : searchUser,
      Search_Bophan: searchBophan === "-1" ? null : searchBophan,
      Search_ChucVu: searchChucVu === "-1" ? null : searchChucVu,
      Search_PhongBan: searchPhongBan === "-1" ? null : searchPhongBan,
      Search_Year: searchYear === "-1" ? null : searchYear
    }

    const takeData = async () => {
      try {
        await axios.post(
          REPORT_RANKING,
          body,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain"
            }
          }
        )
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              setData(res.data.Data.data)
            }
          })
      } catch (error) {
        toast.error("Tải dữ liệu thất bại")
      }
    }

    takeData()
  }

  return (
    <>
      {
        yearList === null || phongBanList === null || userList === null || boPhanList === null || chucVuList === null ?
          <div className="flex flex-col w-full h-full p-3 text-sm space-y-10 justify-center items-center">
            <LoaderSpinner w={32} h={32} />
            <p className="text-primary">Đang tải dữ liệu</p>
          </div>
          :
          <div className="flex flex-col w-full h-full p-3 text-sm space-y-10">
            {/* filter */}
            <div className="w-full h-max flex flex-col">
              <div className="w-max h-max flex lg:flex-row flex-col lg:space-x-4">
                <div className="flex flex-col w-max h-max">
                  <label>Năm</label>
                  <select
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={searchYear === null ? -1 : searchYear}
                    onChange={(e: any) => {
                      _onChangeParam("year", e)
                    }}
                  >
                    <option className="text-gray-300" value={-1}>--- Chọn năm ---</option>
                    {
                      yearList === null ? <></>
                        : yearList.map((item: any, index: number) => (
                          <option key={index} value={item.Year}>{item.Year}</option>
                        ))
                    }
                  </select>
                </div>

                <div className="flex flex-col w-max h-max">
                  <label>Nhân viên</label>
                  <select
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={searchUser === null ? -1 : searchUser}
                    onChange={(e: any) => {
                      _onChangeParam("user", e)
                    }}
                  >
                    <option className="text-gray-300" value={-1}>--- Chọn nhân viên ---</option>
                    {
                      userList === null ? <></>
                        : userList.map((item: any, index: number) => (
                          <option key={index} value={item.Id}>{item.FullName}</option>
                        ))
                    }
                  </select>
                </div>

                <div className="flex flex-col w-max h-max">
                  <label>Phòng ban</label>
                  <select
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={searchPhongBan === null ? -1 : searchPhongBan}
                    onChange={(e: any) => {
                      _onChangeParam("phongBan", e)
                    }}
                  >
                    <option className="text-gray-300" value={-1}>--- Chọn phòng ban ---</option>
                    {
                      phongBanList === null ? <></>
                        : phongBanList.map((item: any, index: number) => (
                          <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                        ))
                    }
                  </select>
                </div>

                <div className="flex flex-col w-max h-max">
                  <label>Bộ phận</label>
                  <select
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={searchBophan === null ? -1 : searchBophan}
                    onChange={(e: any) => {
                      _onChangeParam("boPhan", e)
                    }}
                  >
                    <option className="text-gray-300" value={-1}>--- Chọn bộ phận ---</option>
                    {
                      boPhanList === null ? <></>
                        : boPhanList.map((item: any, index: number) => (
                          <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                        ))
                    }
                  </select>
                </div>

                <div className="flex flex-col w-max h-max">
                  <label>Chức vụ</label>
                  <select
                    className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={searchChucVu === null ? -1 : searchChucVu}
                    onChange={(e: any) => {
                      _onChangeParam("chucVu", e)
                    }}
                  >
                    <option className="text-gray-300" value={-1}>--- Chọn chức vụ ---</option>
                    {
                      chucVuList === null ? <></>
                        : chucVuList.map((item: any, index: number) => (
                          <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                        ))
                    }
                  </select>
                </div>
              </div>
              
              <div className="w-max h-max flex space-x-2">
              <button
                className="
                bg-primary text-white font-semibold w-max h-max rounded-md
                hover:bg-sky-400 pt-1.5 pb-1.5 pl-2 pr-2 flex space-x-1 mt-4
              "
                onClick={_onLoadData}
              >
                <SearchIcon fontSize="small" />
                <p className="lg:flex hidden">Tìm kiếm</p>
              </button>

              <button
                className="
                bg-amber-500 text-white font-semibold w-max h-max rounded-md
                hover:bg-amber-400 pt-1.5 pb-1.5 pl-2 pr-2 flex space-x-1 mt-4
              "
                onClick={() => window.location.reload()}
              >
                <ReplayIcon fontSize="small" />
                <p className="lg:flex hidden">Tải lại</p>
              </button>
              </div>
              
            </div>

            {/* table - web */}
            <div className="lg:flex hidden tableFixHead styled-scrollbars w-full h-[calc(100%-10.7rem)] shadow-md border-t-2">
              <table>
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="border-l-[1px] border-slate-300 text-right">STT</th>
                    <th className="border-l-[1px] border-slate-300 text-left">Tên nhân viên</th>
                    <th className="border-l-[1px] border-slate-300">Phòng ban</th>
                    <th className="border-l-[1px] border-slate-300">Bộ phận</th>
                    <th className="border-l-[1px] border-slate-300">Chức vụ</th>
                    <th className="border-l-[1px] border-slate-300">Điểm thực hiện chính</th>
                    <th className="border-l-[1px] border-slate-300">Điểm phối hợp</th>
                    <th className="border-l-[1px] border-slate-300">Điểm tổng</th>
                    <th className="border-l-[1px] border-r-[1px] border-slate-300 text-left">Xếp hạng</th>
                  </tr>
                </thead>
                <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                  {
                    data === null ? <></>
                      : data?.map((item: any, index: any) => (
                        <>
                          {
                            (index + 1) % 4 === 0 ?
                              <tr key={index}>
                                <td className="border border-slate-300 text-right">
                                  {index + 1}
                                </td>
                                <td className="border border-slate-300 text-left">
                                  {item.FullName}
                                </td>
                                <td className="text-center border border-slate-300">
                                  {item.TenPhongBan}
                                </td>
                                <td className="text-center border border-slate-300">
                                  {item.TenBoPhan}
                                </td>
                                <td className="text-center border border-slate-300">
                                  {item.TenChucVu}
                                </td>
                                <td className="text-center border border-slate-300">
                                  {item.TotalCount_Staff}
                                </td>
                                <td className="text-center border border-slate-300">
                                  {item.TotalCount_Recipient}
                                </td>
                                <td className="text-center border border-slate-300">
                                  {item.TotalCount_Supporter}
                                </td>
                                <td className="text-left border border-slate-300">
                                  {item.Ranking}
                                </td>
                              </tr>
                              :
                              <tr className={`
                                ${item.Ranking === 1 ? "bg-yellow-100"
                                  : item.Ranking === 2 ? "bg-gray-100"
                                    : item.Ranking === 3 ? "bg-red-100"
                                      : ""
                                }
                              `}
                                key={index}>
                                <td className="border border-slate-300 text-right">
                                  {index + 1}
                                </td>
                                <td className="border border-slate-300 text-left">
                                  {item.FullName}
                                </td>
                                <td className="text-center border border-slate-300">
                                  {item.TenPhongBan}
                                </td>
                                <td className="text-center border border-slate-300">
                                  {item.TenBoPhan}
                                </td>
                                <td className="text-center border border-slate-300">
                                  {item.TenChucVu}
                                </td>
                                <td className="text-center border border-slate-300">
                                  {item.TotalCount_Recipient}
                                </td>
                                <td className="text-center border border-slate-300">
                                  {item.TotalCount_Supporter}
                                </td>
                                <td className="text-center border border-slate-300">
                                  {item.TotalCount_Staff}
                                </td>
                                <td className="text-left border border-slate-300 font-bold">
                                  {
                                    item.Ranking === 1 || item.Ranking === 2 || item.Ranking === 3 ?
                                      <>
                                        <div className="w-full h-full flex justify-between items-center">
                                          <p className={`${item.Ranking === 1 ? "text-[#FFD700]" : item.Ranking === 2 ? "text-[#C0C0C0]" : item.Ranking === 3 ? "text-[#B22222]" : ""}`}>{item.Ranking}</p>
                                          <div className="w-max h-max"
                                            style={{
                                              color: `${item.Ranking === 1 ? "#FFD700" : item.Ranking === 2 ? "#C0C0C0" : item.Ranking === 3 ? "#B22222" : ""}`,
                                              fontWeight: "bold"
                                            }}

                                          >
                                            <WorkspacePremiumRoundedIcon fontSize="small" />
                                          </div>
                                        </div>
                                      </>
                                      :
                                      <>
                                        {item.Ranking}
                                      </>
                                  }

                                </td>
                              </tr>
                          }
                        </>

                      ))
                  }
                  <tr>
                  </tr>

                </tbody>
              </table>
            </div>

            {/* table - mobile */}
            <div className="lg:hidden flex tableFixHead styled-scrollbars w-full h-[calc(100%-10.7rem)] shadow-md border-t-2">
              <table>
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex flex-col">
                        <p>Bảng xếp hạng</p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                  {
                    data === null ? <></>
                      : data?.map((item: any, index: any) => (
                        <>
                          {
                            (index + 1) % 4 === 0 ?
                              <tr key={index}>
                                <td className="text-left border border-slate-300">
                                  <p>STT: {index + 1}</p>
                                  <p>Tên nhân viên: {item.FullName}</p>
                                  <p>Phòng ban: {item.TenPhongBan}</p>
                                  <p>Bộ phận: {item.TenBoPhan}</p>
                                  <p>Chức vụ: {item.TenChucVu}</p>
                                  <p>Điểm thực hiện chính: {item.TotalCount_Recipient}</p>
                                  <p>Điểm hỗ trọ: {item.TotalCount_Supporter}</p>
                                  <p>Điểm tổng: {item.TotalCount_Staff}</p>
                                  <div className="flex font-bold">
                                    <p>Xếp hạng: {item.Ranking}</p>
                                  </div>
                                </td>
                              </tr>
                              :
                              <tr key={index} className={`
                              ${item.Ranking === 1 ? "bg-yellow-100"
                                : item.Ranking === 2 ? "bg-gray-100"
                                  : item.Ranking === 3 ? "bg-red-100"
                                    : ""
                              }
                            `}>
                                <td className="text-left border border-slate-300">
                                  <p>STT: {index + 1}</p>
                                  <p>Tên nhân viên: {item.FullName}</p>
                                  <p>Phòng ban: {item.TenPhongBan}</p>
                                  <p>Bộ phận: {item.TenBoPhan}</p>
                                  <p>Chức vụ: {item.TenChucVu}</p>
                                  <p>Điểm thực hiện chính: {item.TotalCount_Recipient}</p>
                                  <p>Điểm hỗ trọ: {item.TotalCount_Supporter}</p>
                                  <p>Điểm tổng: {item.TotalCount_Staff}</p>
                                  {
                                    item.Ranking === 1 || item.Ranking === 2 || item.Ranking === 3 ?
                                      <div className={`flex font-bold items-center space-x-2 ${item.Ranking === 1 ? "text-[#FFD700]" : item.Ranking === 2 ? "text-[#C0C0C0]" : item.Ranking === 3 ? "text-[#B22222]" : ""}`}>
                                        <p>Xếp hạng: {item.Ranking}</p>
                                        <div className="w-max h-max"
                                          style={{
                                            color: `${item.Ranking === 1 ? "#FFD700" : item.Ranking === 2 ? "#C0C0C0" : item.Ranking === 3 ? "#B22222" : ""}`,
                                            fontWeight: "bold"
                                          }}

                                        >
                                          <WorkspacePremiumRoundedIcon fontSize="small" />
                                        </div>
                                      </div>
                                      :
                                      <div className="flex font-bold items-center space-x-2">
                                        <p>Xếp hạng: {item.Ranking}</p>

                                      </div>
                                  }
                                </td>
                              </tr>
                          }
                        </>
                      ))
                  }
                </tbody>
              </table>
            </div>
          </div>
      }
    </>

  )
}

export default ReportRankingUI