import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import Pagination from "@mui/material/Pagination";

import { readList } from "../funcs/taskNguoiGiaoViec";
import { NGUOI_GIAO_VIEC_LIST } from "../apis/_index";
import {
  ONLOAD_TASKLIST_ALL,
  ONCHANGE_CHOOSEPAGE,
} from "../redux/reducers/workingReducer/_index";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import NotificationsNoneTwoToneIcon from "@mui/icons-material/NotificationsNoneTwoTone";
import SearchIcon from "@mui/icons-material/Search";
import LoaderSpinner from "./loader-spinner/_index";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import { takePermission } from "../funcs";
import { convertNameParameter } from "../helperText/index";
// context
import { ModalController } from "../ui/homeUI/_index";

// receiver
import PanToolAltTwoToneIcon from "@mui/icons-material/PanToolAltTwoTone";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import axios from "axios";

import {
  ONCHANGE_PAGELIST,
  ONCHANGE_PAGENUMBER,
} from "../redux/reducers/settingReducer/_index";
import { ONCHANGE_PAGINATION_TASKLIST } from "../redux/reducers/workingReducer/_index";
import { ONLOAD_FUNCTIONLIST } from "../redux/reducers/userReducer/_index";
import { ONLOAD_SCREENLIST } from "../redux/reducers/userReducer/_index";
import {
  SYS_DASHBOARD_UPDATE,
  SYS_PERMISSION_UPDATE,
  SYS_PERMISSION_LOADFUNC,
  SYS_DASHBOARD_LOADFUNCS,
} from "../apis/_index";
import { toast } from "react-toastify";

import VotingWork from "./modal/working/VotingWork";

import WorkingTaskChangeDeadlineJSX from "./modal/pageTable_nguoigiao/WorkingTaskChangeDeadlineJSX";
import WorkingTaskNeedGiveJSX from "./modal/pageTable_nguoigiao/WorkingTaskNeedGiveJSX";
import WorkingNewTaskReceiverJSX from "./modal/pageTable_nguoinhan/WorkingNewTaskReceiverJSX";
// import WorkingTaskReceivedReceiverJSX from "./modal/pageTable_nguoinhan/WorkingTaskReceivedReceiverJSX";
import WorkingTaskReceivedReceiverJSX from "./modal/pageTable_nguoinhan/WorkingTaskReceivedReceiverJSX";
import WorkingTaskProcessingReceiverJSX from "./modal/pageTable_nguoinhan/WorkingTaskProcessingReceiverJSX";
import WorkingTaskDelayReceiverJSX from "./modal/pageTable_nguoinhan/WorkingTaskDelayReceiverJSX";
import WorkingTaskEndTodayReceiverJSX from "./modal/pageTable_nguoinhan/WorkingTaskEndTodayReceiverJSX";
import WorkingTaskReturnReceiverJSX from "./modal/pageTable_nguoinhan/WorkingTaskReturnReceiverJSX";

import WorkingTaskFinishJSX from "./modal/pageTable_nguoigiao/WorkingTaskFinishJSX";
import WorkingTaskFinishLateReceiver from "./modal/pageTable_nguoinhan/WorkingTaskFinishLateReceiver";
import WorkingTaskFinishEarlyReceiver from "./modal/pageTable_nguoinhan/WorkingTaskFinishEarlyReceiver";
import WorkingTaskFinishPunctualReceiver from "./modal/pageTable_nguoinhan/WorkingTaskFinishPunctualReceiver";
import WorkingTaskTotalJSX from "./modal/pageTable_nguoigiao/WorkingTaskTotalJSX";
import WorkingNewTaskJSX from "./modal/pageTable_nguoigiao/WorkingNewTaskJSX";
import WorkingTaskAllTaskReceiverJSX from "./modal/pageTable_nguoinhan/WorkingTaskAllTaskReceiverJSX";
import WorkingTaskProccessingJSX from "./modal/pageTable_nguoigiao/WorkingTaskProccessingJSX";
import WorkingTaskReturnJSX from "./modal/pageTable_nguoigiao/WorkingTaskReturnJSX";

import SettingTargetScoring from "./modal/pageTable_Setting/SettingTargetScoring";
import SettingScoring from "./modal/pageTable_Setting/SettingScoring";
import WorkingTaskEndSoonReceiverJSX from "./modal/pageTable_nguoinhan/WorkingTaskEndSoonReceiverJSX";
import ReportWorkerCapacity from "./modal/pageTable_Report_nguoigiao/ReportWorkerCapacity";
import WorkingTaskEndSoonJSX from "./modal/pageTable_nguoigiao/WorkingTaskEndSoonJSX";
import WorkingTaskDelayJSX from "./modal/pageTable_nguoigiao/WorkingTaskDelayJSX";
import WorkingTaskEndTodayJSX from "./modal/pageTable_nguoigiao/WorkingTaskEndTodayJSX";
import WorkingTaskWaiting from "./modal/pageTable_nguoinhan/WorkingTaskWaiting";

import WorkingTaskFinishPunctual from "./modal/pageTable_nguoigiao/WorkingTaskFinishPunctual";
import WorkingTaskFinishEarly from "./modal/pageTable_nguoigiao/WorkingTaskFinishEarly";
import WorkingTaskFinishLate from "./modal/pageTable_nguoigiao/WorkingTaskFinishLate";

import UserFunctionalList from "./modal/pageTable_UserFunctionalList/UserFunctionalList";
import TotalTaskWithBossJSX from "./modal/pageTable_nguoigiao/TotalTaskWithBoss";

const Table = () => {
  const urlLink = window.location.pathname;
  const context = useContext(ModalController);
  const color = "#CCCCCC";

  // redux
  const settingData = useSelector((state: any) => state.settingReducer);
  const userData = useSelector((state: any) => state.userReducer);
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  // ref log
  const parameterShouldBlog = useRef(true);

  // state
  const pageList = useMemo(() => [10, 20, 50], []);
  const [parameterSelectedNumber, setParameterSelectedNumber] =
    useState<number>(pageList[0]);

  // onChange funcs
  const _onChangeParameterSelectedNumber = (e: any) => {
    e.preventDefault();
    setParameterSelectedNumber(parseInt(e.target.value));
  };

  const _onChangeParameterPaginate = (e: any, value: number) => {
    e.preventDefault();
    dispatch(ONCHANGE_PAGENUMBER(value - 1));
  };

  // funcs
  const _onClickReceiverTaskNew = (id: any) => {
    context.setFuncs("receiveTaskDetail");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDetailPrior = (id: any) => {
    context.setFuncs("detailPrior");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDetailSecurity = (id: any) => {
    context.setFuncs("detailSecurity");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDetailSecuritySingle = (id: any) => {
    context.setFuncs("detailSecuritySingle");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDetailDificult = (id: any) => {
    context.setFuncs("detailDificult");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDetailPriorSingle = (id: any) => {
    context.setFuncs("detailPriorSingle");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickTaskEndToday = (id: any) => {
    context.setFuncs("taskEndToday");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDeleteSecurity = (id: any) => {
    context.setFuncs("deleteSecurity");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDeleteDificult = (id: any) => {
    context.setFuncs("deleteDificult");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickTaskDelay = (id: any) => {
    context.setFuncs("taskDelay");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickListOfEmployee = (id: any) => {
    context.setFuncs("detailListOfEmployee");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickListOfEmployeeSingle = (id: any) => {
    context.setFuncs("detailListOfEmployeeSingle");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDetailTargetCapactity = (id: any) => {
    context.setFuncs("detailTargetCapacity");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickNeedGive = (id: any) => {
    context.setFuncs("needGive");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickEditTaskWithId = (id: any) => {
    context.setFuncs("editTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickConfirmTaskWithId = (id: any) => {
    context.setFuncs("confirmFinishTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDelayDetail = (id: any) => {
    context.setFuncs("delayDetail");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickSwitchToBoss = (id: any) => {
    context.setFuncs("switchTaskToBoss");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDetailUserList = (id: any) => {
    context.setFuncs("detailUser");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDetailUserListSingle = (id: any) => {
    context.setFuncs("detailUserSingle");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickGiveTask = (id: any) => {
    context.setFuncs("giveTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDeleteTask = (id: any) => {
    context.setFuncs("deleteEmployee");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDeleteUser = (id: any) => {
    context.setFuncs("deleteUser");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDeletePrior = (id: any) => {
    context.setFuncs("deletePrior");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickAlertTask = (id: any) => {
    context.setFuncs("alertTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickReceiveTask = (id: any) => {
    context.setFuncs("receiveTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickStartTask = (id: any) => {
    context.setFuncs("startTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDeleteGeneral = (id: any) => {
    context.setFuncs("deleteGeneral");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDetailTableCapacity = (id: any) => {
    context.setFuncs("detailTableScoringCapacity");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDetailListOfGeneral = (id: any) => {
    context.setFuncs("detailListOfGeneral");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDetailSingleOfGeneral = (id: any) => {
    context.setFuncs("detailSingleOfGeneral");
    context.setTaskId(id);
    context.handleOpen();
  };

  // funcs: - sys

  // jsx
  // const WorkingNewTaskJSX = () => {

  //   const _onChangePaginate = (event: React.ChangeEvent<unknown>, value: number) => {
  //     dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  //   }

  //   const _onChangeChoosePage = (e: any) => {
  //     dispatch(ONCHANGE_CHOOSEPAGE(e.target.value));
  //   }

  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[3rem] space-x-1 items-center justify-between">
  //         <div className="flex w-max h-max">
  //           <p>Xem</p>
  //           <select
  //             className="border-2 border-slate-200 rounded-md"
  //             value={workingData.choosePage}
  //             onChange={(e: any) => _onChangeChoosePage(e)}
  //           >
  //             <option value={10}>10</option>
  //             <option value={20}>20</option>
  //             <option value={50}>50</option>
  //           </select>
  //           <p>dòng</p>
  //         </div>
  //       </div>

  //       <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md">
  //         <table>
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>STT</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Tên công việc</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Độ ưu tiên</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Ngày tạo</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Thời hạn</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Người nhận</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             {workingData.newTaskList === null ? <tr></tr> :
  //               workingData.newTaskList.data.map((item: any, index: any) => (
  //                 <tr key={index} className="w-max h-[3rem] bg-white">
  //                   <td className="text-center border border-slate-300">{(index + 1) + workingData.pageCurrent * workingData.choosePage}</td>
  //                   <td className="border border-slate-300">{item.TaskName}</td>
  //                   <td className="text-center border border-slate-300"><p className="font-bold">{item.PriorityLevelName}</p></td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="w-full h-max flex flex-col justify-center items-center">
  //                       <p>{moment(item.DateAdd).format('DD/MM/YYYY')}</p>
  //                       <p>{moment(item.DateAdd).format('HH:mm:ss')}</p>
  //                     </div>
  //                   </td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="w-full h-max flex flex-col justify-center items-center">
  //                       <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
  //                       <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
  //                     </div>
  //                   </td>
  //                   <td className="border border-slate-300">{item.RecipientName}</td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="dropdown">
  //                       {/* button options */}
  //                       <button className="dropbtn">
  //                         <MoreHorizIcon fontSize="small" />
  //                       </button>

  //                       {/* options fields */}
  //                       <div className="dropdown-content text-xs font-semibold">
  //                         {/* detail */}
  //                         <button
  //                           className="flex w-full h-full hover:text-sky-500"
  //                           onClick={() => _onClickEditTaskWithId(item.ID)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Chi tiết / Chỉnh sửa
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* tranf boss */}
  //                         <button
  //                           className="flex w-full h-full hover:text-amber-500"
  //                           onClick={() => _onClickSwitchToBoss(1)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <CameraFrontTwoToneIcon className="text-amber-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Chuyển sếp
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* delitask */}
  //                         <button
  //                           className="flex w-full h-full hover:text-emerald-500"
  //                           onClick={() => _onClickGiveTask(1)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <SendTwoToneIcon className="text-emerald-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Giao việc
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* delete */}
  //                         <button className="flex w-full h-full hover:text-red-500"
  //                           onClick={() => _onClickDeleteTask(1)}
  //                         >
  //                           {/* delete - contain */}
  //                           <div className="flex w-full space-x-1">
  //                             {/* icon */}
  //                             <DeleteTwoToneIcon className="text-red-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div className="h-full">
  //                               Xoá
  //                             </div>
  //                           </div>
  //                         </button>
  //                       </div>
  //                     </div>
  //                   </td>
  //                 </tr>
  //               ))
  //             }
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem]">
  //         <Pagination
  //           count={workingData.paginateTaskList === null ? 0 : workingData.paginateTaskList}
  //           page={workingData.pageCurrent + 1}
  //           variant="outlined"
  //           color="primary"
  //           size="small"
  //           onChange={_onChangePaginate}
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  // const WorkingTaskNeedGiveJSX = () => {

  //   // workingData.createTask.selectedViecCanGiao

  //   const _onChangePaginate = (event: React.ChangeEvent<unknown>, value: number) => {
  //     dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  //   }

  //   const _onChangeChoosePage = (e: any) => {
  //     dispatch(ONCHANGE_CHOOSEPAGE(e.target.value));
  //   }

  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[3rem] space-x-1 items-center justify-between">
  //         <div className="flex w-max h-max">
  //           <p>Xem</p>
  //           <select
  //             className="border-2 border-slate-200 rounded-md"
  //             value={workingData.choosePage}
  //             onChange={(e: any) => _onChangeChoosePage(e)}
  //           >
  //             <option value={10}>10</option>
  //             <option value={20}>20</option>
  //             <option value={50}>50</option>
  //           </select>
  //           <p>dòng</p>
  //         </div>
  //       </div>

  //       <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md">
  //         <table>
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>STT</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Tên công việc</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Độ ưu tiên</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Ngày tạo</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Thời hạn</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Người nhận</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             {workingData.needGiveList === null ? <tr></tr> :
  //               workingData.needGiveList.data.map((item: any, index: any) => (
  //                 <tr key={index} className="w-max h-[3rem] bg-white">
  //                   <td className="text-center border border-slate-300">{(index + 1) + workingData.pageCurrent * workingData.choosePage}</td>
  //                   <td className="border border-slate-300">{item.TaskName}</td>
  //                   <td className="text-center border border-slate-300"><p className="font-bold">{item.PriorityLevelName}</p></td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="w-full h-max flex flex-col justify-center items-center">
  //                       <p>{moment(item.DateAdd).format('DD/MM/YYYY')}</p>
  //                       <p>{moment(item.DateAdd).format('HH:mm:ss')}</p>
  //                     </div>
  //                   </td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="w-full h-max flex flex-col justify-center items-center">
  //                       <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
  //                       <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
  //                     </div>
  //                   </td>
  //                   <td className="border border-slate-300">{item.RecipientName}</td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="dropdown">
  //                       {/* button options */}
  //                       <button className="dropbtn">
  //                         <MoreHorizIcon fontSize="small" />
  //                       </button>

  //                       {/* options fields */}
  //                       <div className="dropdown-content text-xs font-semibold">
  //                         {/* detail */}
  //                         <button
  //                           className="flex w-full h-full hover:text-sky-500"
  //                           onClick={() => _onClickEditTaskWithId(item.ID)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Chi tiết / Chỉnh sửa
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* tranf boss */}
  //                         <button
  //                           className="flex w-full h-full hover:text-amber-500"
  //                           onClick={() => _onClickSwitchToBoss(1)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <CameraFrontTwoToneIcon className="text-amber-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Chuyển sếp
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* delitask */}
  //                         <button
  //                           className="flex w-full h-full hover:text-emerald-500"
  //                           onClick={() => _onClickGiveTask(1)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <SendTwoToneIcon className="text-emerald-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Giao việc
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* delete */}
  //                         <button className="flex w-full h-full hover:text-red-500"
  //                           onClick={() => _onClickDeleteTask(1)}
  //                         >
  //                           {/* delete - contain */}
  //                           <div className="flex w-full space-x-1">
  //                             {/* icon */}
  //                             <DeleteTwoToneIcon className="text-red-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div className="h-full">
  //                               Xoá
  //                             </div>
  //                           </div>
  //                         </button>
  //                       </div>
  //                     </div>
  //                   </td>
  //                 </tr>
  //               ))
  //             }
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem]">
  //         <Pagination
  //           count={workingData.paginateTaskList === null ? 0 : workingData.paginateTaskList}
  //           page={workingData.pageCurrent + 1}
  //           variant="outlined"
  //           color="primary"
  //           size="small"
  //           onChange={_onChangePaginate}
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  // const WorkingTaskFinishJSX = () => {
  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[2rem] space-x-1 items-center">
  //         <p>Xem</p>
  //         <select className="border-2 border-slate-200 rounded-md">
  //           <option>10</option>
  //           <option>20</option>
  //           <option>50</option>
  //         </select>
  //         <p>dòng</p>
  //       </div>

  //       <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
  //         <table>
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border-l-[1px] border-slate-300">STT</th>
  //               <th className="border-l-[1px] border-slate-300">Tên công việc</th>
  //               <th className="border-l-[1px] border-slate-300">Độ khó</th>
  //               <th className="border-l-[1px] border-slate-300">Ngày tạo</th>
  //               <th className="border-l-[1px] border-slate-300">Ngày thực hiện</th>
  //               <th className="border-l-[1px] border-slate-300">Thời hạn</th>
  //               <th className="border-l-[1px] border-slate-300">Người nhận</th>
  //               <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="text-red-500 font-semibold">Trễ 3 ngày</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                       onClick={() => _onClickConfirmTaskWithId(1)}
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết / Duyệt
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
  //         <p>Đang xem 10 trên 20 dòng</p>
  //         <Pagination count={10} variant="outlined" color="primary" size="small" />
  //       </div>
  //     </div>
  //   )
  // }

  // const WorkingTaskDelayJSX = () => {

  //   const _onChangePaginate = (event: React.ChangeEvent<unknown>, value: number) => {
  //     dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  //   }

  //   const _onChangeChoosePage = (e: any) => {
  //     dispatch(ONCHANGE_CHOOSEPAGE(e.target.value));
  //   }

  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[3rem] space-x-1 items-center justify-between">
  //         <div className="flex w-max h-max">
  //           <p>Xem</p>
  //           <select
  //             className="border-2 border-slate-200 rounded-md"
  //             value={workingData.choosePage}
  //             onChange={(e: any) => _onChangeChoosePage(e)}
  //           >
  //             <option value={10}>10</option>
  //             <option value={20}>20</option>
  //             <option value={50}>50</option>
  //           </select>
  //           <p>dòng</p>
  //         </div>
  //       </div>

  //       <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md">
  //         <table>
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>STT</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Tên công việc</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Độ ưu tiên</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Ngày tạo</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Thời hạn</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Người nhận</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             {workingData.taskDelay === null ? <tr></tr> :
  //               workingData.taskDelay.data.map((item: any, index: any) => (
  //                 <tr key={index} className="w-max h-[3rem] bg-white">
  //                   <td className="text-center border border-slate-300">{(index + 1) + workingData.pageCurrent * workingData.choosePage}</td>
  //                   <td className="border border-slate-300">
  //                     <p>{item.TaskName}</p>
  //                     <p className="text-red-500 font-semibold">Trễ {item.DayLate} ngày</p>
  //                   </td>
  //                   <td className="text-center border border-slate-300"><p className="font-bold">{item.PriorityLevelName}</p></td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="w-full h-max flex flex-col justify-center items-center">
  //                       <p>{moment(item.DateAdd).format('DD/MM/YYYY')}</p>
  //                       <p>{moment(item.DateAdd).format('HH:mm:ss')}</p>
  //                     </div>
  //                   </td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="w-full h-max flex flex-col justify-center items-center">
  //                       <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
  //                       <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
  //                     </div>
  //                   </td>
  //                   <td className="border border-slate-300">{item.RecipientName}</td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="dropdown">
  //                       {/* button options */}
  //                       <button className="dropbtn">
  //                         <MoreHorizIcon fontSize="small" />
  //                       </button>

  //                       {/* options fields */}
  //                       <div className="dropdown-content text-xs font-semibold">
  //                         {/* detail */}
  //                         <button
  //                           className="flex w-full h-full hover:text-sky-500"
  //                           onClick={() => _onClickEditTaskWithId(item.ID)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Chi tiết / Chỉnh sửa
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* tranf boss */}
  //                         <button
  //                           className="flex w-full h-full hover:text-amber-500"
  //                           onClick={() => _onClickSwitchToBoss(1)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <CameraFrontTwoToneIcon className="text-amber-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Chuyển sếp
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* delitask */}
  //                         <button
  //                           className="flex w-full h-full hover:text-emerald-500"
  //                           onClick={() => _onClickGiveTask(1)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <SendTwoToneIcon className="text-emerald-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Giao việc
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* delete */}
  //                         <button className="flex w-full h-full hover:text-red-500"
  //                           onClick={() => _onClickDeleteTask(1)}
  //                         >
  //                           {/* delete - contain */}
  //                           <div className="flex w-full space-x-1">
  //                             {/* icon */}
  //                             <DeleteTwoToneIcon className="text-red-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div className="h-full">
  //                               Xoá
  //                             </div>
  //                           </div>
  //                         </button>
  //                       </div>
  //                     </div>
  //                   </td>
  //                 </tr>
  //               ))
  //             }
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem]">
  //         <Pagination
  //           count={workingData.paginateTaskList === null ? 0 : workingData.paginateTaskList}
  //           page={workingData.pageCurrent + 1}
  //           variant="outlined"
  //           color="primary"
  //           size="small"
  //           onChange={_onChangePaginate}
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  // const WorkingTaskEndSoonJSX = () => {
  //   const _onChangePaginate = (event: React.ChangeEvent<unknown>, value: number) => {
  //     dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  //   }

  //   const _onChangeChoosePage = (e: any) => {
  //     dispatch(ONCHANGE_CHOOSEPAGE(e.target.value));
  //   }

  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[3rem] space-x-1 items-center justify-between">
  //         <div className="flex w-max h-max">
  //           <p>Xem</p>
  //           <select
  //             className="border-2 border-slate-200 rounded-md"
  //             value={workingData.choosePage}
  //             onChange={(e: any) => _onChangeChoosePage(e)}
  //           >
  //             <option value={10}>10</option>
  //             <option value={20}>20</option>
  //             <option value={50}>50</option>
  //           </select>
  //           <p>dòng</p>
  //         </div>
  //       </div>

  //       <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md">
  //         <table>
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>STT</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Tên công việc</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Độ ưu tiên</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Ngày tạo</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Thời hạn</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Người nhận</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             {workingData.taskEndSoon === null ? <tr></tr> :
  //               workingData.taskEndSoon.data.map((item: any, index: any) => (
  //                 <tr key={index} className="w-max h-[3rem] bg-white">
  //                   <td className="text-center border border-slate-300">{(index + 1) + workingData.pageCurrent * workingData.choosePage}</td>
  //                   <td className="border border-slate-300">
  //                     <p>{item.TaskName}</p>
  //                     <p className="text-amber-500 font-semibold">Đã làm {item.NumberOfWorking} ngày</p>
  //                     <p className="text-amber-500 font-semibold">Còn {item.DayLate} ngày</p>
  //                   </td>
  //                   <td className="text-center border border-slate-300"><p className="font-bold">{item.PriorityLevelName}</p></td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="w-full h-max flex flex-col justify-center items-center">
  //                       <p>{moment(item.DateAdd).format('DD/MM/YYYY')}</p>
  //                       <p>{moment(item.DateAdd).format('HH:mm:ss')}</p>
  //                     </div>
  //                   </td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="w-full h-max flex flex-col justify-center items-center">
  //                       <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
  //                       <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
  //                     </div>
  //                   </td>
  //                   <td className="border border-slate-300">{item.RecipientName}</td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="dropdown">
  //                       {/* button options */}
  //                       <button className="dropbtn">
  //                         <MoreHorizIcon fontSize="small" />
  //                       </button>

  //                       {/* options fields */}
  //                       <div className="dropdown-content text-xs font-semibold">
  //                         {/* detail */}
  //                         <button
  //                           className="flex w-full h-full hover:text-sky-500"
  //                           onClick={() => _onClickEditTaskWithId(item.ID)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Chi tiết / Chỉnh sửa
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* tranf boss */}
  //                         <button
  //                           className="flex w-full h-full hover:text-amber-500"
  //                           onClick={() => _onClickSwitchToBoss(1)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <CameraFrontTwoToneIcon className="text-amber-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Chuyển sếp
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* delitask */}
  //                         <button
  //                           className="flex w-full h-full hover:text-emerald-500"
  //                           onClick={() => _onClickGiveTask(1)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <SendTwoToneIcon className="text-emerald-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Giao việc
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* delete */}
  //                         <button className="flex w-full h-full hover:text-red-500"
  //                           onClick={() => _onClickDeleteTask(1)}
  //                         >
  //                           {/* delete - contain */}
  //                           <div className="flex w-full space-x-1">
  //                             {/* icon */}
  //                             <DeleteTwoToneIcon className="text-red-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div className="h-full">
  //                               Xoá
  //                             </div>
  //                           </div>
  //                         </button>
  //                       </div>
  //                     </div>
  //                   </td>
  //                 </tr>
  //               ))
  //             }
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem]">
  //         <Pagination
  //           count={workingData.paginateTaskList === null ? 0 : workingData.paginateTaskList}
  //           page={workingData.pageCurrent + 1}
  //           variant="outlined"
  //           color="primary"
  //           size="small"
  //           onChange={_onChangePaginate}
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  // const WorkingTaskEndTodayJSX = () => {

  //   const _onChangePaginate = (event: React.ChangeEvent<unknown>, value: number) => {
  //     dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  //   }

  //   const _onChangeChoosePage = (e: any) => {
  //     dispatch(ONCHANGE_CHOOSEPAGE(e.target.value));
  //   }

  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[3rem] space-x-1 items-center justify-between">
  //         <div className="flex w-max h-max">
  //           <p>Xem</p>
  //           <select
  //             className="border-2 border-slate-200 rounded-md"
  //             value={workingData.choosePage}
  //             onChange={(e: any) => _onChangeChoosePage(e)}
  //           >
  //             <option value={10}>10</option>
  //             <option value={20}>20</option>
  //             <option value={50}>50</option>
  //           </select>
  //           <p>dòng</p>
  //         </div>
  //       </div>

  //       <div className="tableFixHead w-full h-[calc(100%-4rem)] shadow-md">
  //         <table className="">
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>STT</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Tên công việc</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Độ ưu tiên</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Ngày tạo</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Thời hạn</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-slate-300">
  //                 <div className="w-full h-full flex space-x-1 justify-center items-center">
  //                   <p>Người nhận</p>
  //                   <button className="w-max h-max hover:text-slate-200">
  //                     <ArrowDropDownIcon fontSize="small" />
  //                   </button>
  //                 </div>
  //               </th>
  //               <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             {workingData.taskEndToday === null ? <tr></tr> :
  //               workingData.taskEndToday.data.map((item: any, index: any) => (
  //                 <tr key={index} className="w-max h-[3rem] bg-white">
  //                   <td className="text-center border border-slate-300">{(index + 1) + workingData.pageCurrent * workingData.choosePage}</td>
  //                   <td className="border border-slate-300">
  //                     <p>{item.TaskName}</p>
  //                     <p className="text-purple-500 font-semibold">Tới hạn hôm nay</p>
  //                   </td>
  //                   <td className="text-center border border-slate-300"><p className="font-bold">{item.PriorityLevelName}</p></td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="w-full h-max flex flex-col justify-center items-center">
  //                       <p>{moment(item.DateAdd).format('DD/MM/YYYY')}</p>
  //                       <p>{moment(item.DateAdd).format('HH:mm:ss')}</p>
  //                     </div>
  //                   </td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="w-full h-max flex flex-col justify-center items-center">
  //                       <p>{moment(item.DeadLine).format('DD/MM/YYYY')}</p>
  //                       <p>{moment(item.DeadLine).format('HH:mm:ss')}</p>
  //                     </div>
  //                   </td>
  //                   <td className="border border-slate-300">{item.RecipientName}</td>
  //                   <td className="text-center border border-slate-300">
  //                     <div className="dropdown">
  //                       {/* button options */}
  //                       <button className="dropbtn">
  //                         <MoreHorizIcon fontSize="small" />
  //                       </button>

  //                       {/* options fields */}
  //                       <div className="dropdown-content text-xs font-semibold">
  //                         {/* detail */}
  //                         <button
  //                           className="flex w-full h-full hover:text-sky-500"
  //                           onClick={() => _onClickEditTaskWithId(item.ID)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Chi tiết / Chỉnh sửa
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* tranf boss */}
  //                         <button
  //                           className="flex w-full h-full hover:text-amber-500"
  //                           onClick={() => _onClickSwitchToBoss(1)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <CameraFrontTwoToneIcon className="text-amber-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Chuyển sếp
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* delitask */}
  //                         <button
  //                           className="flex w-full h-full hover:text-emerald-500"
  //                           onClick={() => _onClickGiveTask(1)}
  //                         >
  //                           {/* detail - contain */}
  //                           <div className="flex w-full h-full space-x-1">
  //                             {/* icon */}
  //                             <SendTwoToneIcon className="text-emerald-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div>
  //                               Giao việc
  //                             </div>
  //                           </div>
  //                         </button>

  //                         {/* delete */}
  //                         <button className="flex w-full h-full hover:text-red-500"
  //                           onClick={() => _onClickDeleteTask(1)}
  //                         >
  //                           {/* delete - contain */}
  //                           <div className="flex w-full space-x-1">
  //                             {/* icon */}
  //                             <DeleteTwoToneIcon className="text-red-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                             {/* title */}
  //                             <div className="h-full">
  //                               Xoá
  //                             </div>
  //                           </div>
  //                         </button>
  //                       </div>
  //                     </div>
  //                   </td>
  //                 </tr>
  //               ))
  //             }
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem]">
  //         <Pagination
  //           count={workingData.paginateTaskList === null ? 0 : workingData.paginateTaskList}
  //           page={workingData.pageCurrent + 1}
  //           variant="outlined"
  //           color="primary"
  //           size="small"
  //           onChange={_onChangePaginate}
  //         />
  //       </div>
  //     </div>
  //   )
  // }

  // const WorkingTaskTotalJSX = () => {
  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[2rem] space-x-1 items-center">
  //         <p>Xem</p>
  //         <select className="border-2 border-slate-200 rounded-md">
  //           <option>10</option>
  //           <option>20</option>
  //           <option>50</option>
  //         </select>
  //         <p>dòng</p>
  //       </div>

  //       <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md border-t-2">
  //         <table>
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border-l-[1px] border-slate-300">STT</th>
  //               <th className="border-l-[1px] border-slate-300">Tên công việc</th>
  //               <th className="border-l-[1px] border-slate-300">Tiến độ</th>
  //               <th className="border-l-[1px] border-slate-300">Ngày tạo</th>
  //               <th className="border-l-[1px] border-slate-300">Ngày thực hiện</th>
  //               <th className="border-l-[1px] border-slate-300">Thời hạn</th>
  //               <th className="border-l-[1px] border-slate-300">Người nhận</th>
  //               <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="bg-amber-500 text-white w-max p-1 rounded-md text-xs font-semibold">Đang xử lý</p>
  //               </td>
  //               <td className="text-center border border-slate-300">8/10 (80%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">Trễ hạn</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3/10 (30%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>

  //                 <div className="flex w-full h-max space-x-2 items-center">
  //                   <p className="bg-amber-500 text-white w-max p-1 rounded-md text-xs font-semibold">
  //                     Đang xử lý
  //                   </p>
  //                   <p className="text-red-500 font-semibold">(Khẩn)</p>
  //                 </div>

  //               </td>
  //               <td className="text-center border border-slate-300">3/10 (30%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">Trễ hạn</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3/10 (30%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">Trễ hạn</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3/10 (30%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">

  //                 <div className="flex w-full h-max space-x-2 items-center">
  //                   <p className="text-red-500 font-semibold">[Khẩn]</p>
  //                   <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 </div>

  //                 <div className="flex w-full h-max space-x-2 items-center">
  //                   <p className="bg-amber-500 text-white w-max p-1 rounded-md text-xs font-semibold">
  //                     Đang xử lý
  //                   </p>
  //                 </div>
  //               </td>
  //               <td className="text-center border border-slate-300">3/10 (30%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <div className="flex w-full h-max space-x-2 items-center">
  //                   <p className="bg-purple-500 text-white w-max p-1 rounded-md text-xs font-semibold">
  //                     Tới hạn hôm nay
  //                   </p>
  //                 </div>
  //               </td>
  //               <td className="text-center border border-slate-300">3/10 (30%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">Trễ hạn</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3/10 (30%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">Trễ hạn</p>
  //               </td>
  //               <td className="text-center border border-slate-300">4/10 (40%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">Trễ hạn</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3/10 (30%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">Trễ hạn</p>
  //               </td>
  //               <td className="text-center border border-slate-300">2/10 (20%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">Trễ hạn</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3/10 (30%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="bg-red-500 text-white w-max p-1 rounded-md text-xs font-semibold">Trễ hạn</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3/10 (30%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>

  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="bg-green-500 text-white w-max p-1 rounded-md text-xs font-semibold">Hoàn thành</p>
  //               </td>
  //               <td className="text-center border border-slate-300">10/10 (100%)</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">01/01/2022 12:10</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="border border-slate-300">Quân</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
  //         <p>Đang xem 10 trên 20 dòng</p>
  //         <Pagination count={10} variant="outlined" color="primary" size="small" />
  //       </div>
  //     </div>
  //   )
  // }

  // receiver
  // const WorkingNewTaskReceiverJSX = () => {
  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[2rem] space-x-1 items-center">
  //         <p>Xem ok</p>
  //         <select className="border-2 border-slate-200 rounded-md">
  //           <option>10</option>
  //           <option>20</option>
  //           <option>50</option>
  //         </select>

  //         <p>dòng</p>
  //       </div>

  //       <div className="tableFixHead w-full h-[calc(100%-4rem)] shadow-md">
  //         <table className="">
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border border-slate-300 ">STT</th>
  //               <th className="border border-slate-300">Tên công việc</th>
  //               <th className="border border-slate-300">Độ khó</th>
  //               <th className="border border-slate-300">Ngày giao</th>
  //               <th className="border border-slate-300">Người giao</th>
  //               <th className="border border-slate-300">Thời hạn</th>
  //               <th className="border border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">Lãnh đạo 1</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                       onClick={() => _onClickReceiverTaskNew(1)}
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                     {/* take task */}
  //                     <button
  //                       className="flex w-full h-full"
  //                       onClick={() => _onClickReceiveTask(1)}
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-emerald-500">
  //                         {/* icon */}
  //                         <PanToolAltTwoToneIcon className="text-emerald-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Nhận việc
  //                         </div>
  //                       </div>
  //                     </button>
  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
  //         <p>Đang xem 10 trên 20 dòng</p>
  //         <Pagination count={10} variant="outlined" color="primary" size="small" />
  //       </div>
  //     </div>
  //   )
  // }

  // const WorkingTaskReceivedReceiverJSX = () => {
  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[2rem] space-x-1 items-center">
  //         <p>Xem</p>
  //         <select className="border-2 border-slate-200 rounded-md">
  //           <option>10</option>
  //           <option>20</option>
  //           <option>50</option>
  //         </select>
  //         <p>dòng</p>
  //       </div>

  //       <div className="tableFixHead w-full h-[calc(100%-4rem)] shadow-md">
  //         <table className="">
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border border-slate-300 ">STT</th>
  //               <th className="border border-slate-300">Tên công việc</th>
  //               <th className="border border-slate-300">Độ khó</th>
  //               <th className="border border-slate-300">Ngày giao</th>
  //               <th className="border border-slate-300">Người giao</th>
  //               <th className="border border-slate-300">Thời hạn</th>
  //               <th className="border border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="text-sky-500 font-semibold">Chưa xử lý</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">Lãnh đạo 1</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>

  //                     {/* start */}
  //                     <button
  //                       className="flex w-full h-full"
  //                       onClick={() => _onClickStartTask(1)}
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-cyan-500">
  //                         {/* icon */}
  //                         <FlagTwoToneIcon className="text-cyan-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Bắt đầu
  //                         </div>
  //                       </div>
  //                     </button>
  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
  //         <p>Đang xem 10 trên 20 dòng</p>
  //         <Pagination count={10} variant="outlined" color="primary" size="small" />
  //       </div>
  //     </div>
  //   )
  // }

  // const WorkingTaskProcessingReceiverJSX = () => {
  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[2rem] space-x-1 items-center">
  //         <p>Xem</p>
  //         <select className="border-2 border-slate-200 rounded-md">
  //           <option>10</option>
  //           <option>20</option>
  //           <option>50</option>
  //         </select>
  //         <p>dòng</p>
  //       </div>

  //       <div className="tableFixHead w-full h-[calc(100%-4rem)] shadow-md">
  //         <table className="">
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border border-slate-300 ">STT</th>
  //               <th className="border border-slate-300">Tên công việc</th>
  //               <th className="border border-slate-300">Độ khó</th>
  //               <th className="border border-slate-300">Ngày giao</th>
  //               <th className="border border-slate-300">Người giao</th>
  //               <th className="border border-slate-300">Thời hạn</th>
  //               <th className="border border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="text-amber-500 font-semibold">Còn 3 ngày</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">Lãnh đạo 1</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                       onClick={() => _onClickEditTaskWithId(1)}
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>
  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
  //         <p>Đang xem 10 trên 20 dòng</p>
  //         <Pagination count={10} variant="outlined" color="primary" size="small" />
  //       </div>
  //     </div>
  //   )
  // }

  // const WorkingTaskDelayReceiverJSX = () => {
  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[2rem] space-x-1 items-center">
  //         <p>Xem</p>
  //         <select className="border-2 border-slate-200 rounded-md">
  //           <option>10</option>
  //           <option>20</option>
  //           <option>50</option>
  //         </select>
  //         <p>dòng</p>
  //       </div>

  //       <div className="tableFixHead w-full h-[calc(100%-4rem)] shadow-md">
  //         <table className="">
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border border-slate-300 ">STT</th>
  //               <th className="border border-slate-300">Tên công việc</th>
  //               <th className="border border-slate-300">Độ khó</th>
  //               <th className="border border-slate-300">Ngày giao</th>
  //               <th className="border border-slate-300">Người giao</th>
  //               <th className="border border-slate-300">Thời hạn</th>
  //               <th className="border border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="text-red-500 font-semibold">Trễ 3 ngày</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">Lãnh đạo 1</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                       onClick={() => _onClickEditTaskWithId(1)}
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>
  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
  //         <p>Đang xem 10 trên 20 dòng</p>
  //         <Pagination count={10} variant="outlined" color="primary" size="small" />
  //       </div>
  //     </div>
  //   )
  // }

  // const WorkingTaskEndSoonReceiverJSX = () => {
  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[2rem] space-x-1 items-center">
  //         <p>Xem</p>
  //         <select className="border-2 border-slate-200 rounded-md">
  //           <option>10</option>
  //           <option>20</option>
  //           <option>50</option>
  //         </select>
  //         <p>dòng</p>
  //       </div>

  //       <div className="tableFixHead w-full h-[calc(100%-4rem)] shadow-md">
  //         <table className="">
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border border-slate-300 ">STT</th>
  //               <th className="border border-slate-300">Tên công việc</th>
  //               <th className="border border-slate-300">Độ khó</th>
  //               <th className="border border-slate-300">Ngày giao</th>
  //               <th className="border border-slate-300">Người giao</th>
  //               <th className="border border-slate-300">Thời hạn</th>
  //               <th className="border border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="text-amber-500 font-semibold">Còn 3 ngày</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">Lãnh đạo 1</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                       onClick={() => _onClickEditTaskWithId(1)}
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>
  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
  //         <p>Đang xem 10 trên 20 dòng</p>
  //         <Pagination count={10} variant="outlined" color="primary" size="small" />
  //       </div>
  //     </div>
  //   )
  // }

  // const WorkingTaskEndTodayReceiverJSX = () => {
  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[2rem] space-x-1 items-center">
  //         <p>Xem</p>
  //         <select className="border-2 border-slate-200 rounded-md">
  //           <option>10</option>
  //           <option>20</option>
  //           <option>50</option>
  //         </select>
  //         <p>dòng</p>
  //       </div>

  //       <div className="tableFixHead w-full h-[calc(100%-4rem)] shadow-md">
  //         <table className="">
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border border-slate-300 ">STT</th>
  //               <th className="border border-slate-300">Tên công việc</th>
  //               <th className="border border-slate-300">Độ khó</th>
  //               <th className="border border-slate-300">Ngày giao</th>
  //               <th className="border border-slate-300">Người giao</th>
  //               <th className="border border-slate-300">Thời hạn</th>
  //               <th className="border border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">
  //                 <p>Xử lý hồ sơ FEYDK357502353</p>
  //                 <p className="text-purple-500 font-semibold">Tới hạn hôm nay</p>
  //               </td>
  //               <td className="text-center border border-slate-300">3</td>
  //               <td className="text-center border border-slate-300">01/01/2022 11:12</td>
  //               <td className="text-center border border-slate-300">Lãnh đạo 1</td>
  //               <td className="text-center border border-slate-300">07/01/2022 17:00</td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                       onClick={() => _onClickEditTaskWithId(1)}
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết
  //                         </div>
  //                       </div>
  //                     </button>
  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
  //         <p>Đang xem 10 trên 20 dòng</p>
  //         <Pagination count={10} variant="outlined" color="primary" size="small" />
  //       </div>
  //     </div>
  //   )
  // }

  // report
  // const ReportWorkerCapacity = () => {
  //   return (
  //     <div className="flex flex-col w-full h-[calc(100%-7rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[2rem] space-x-1 items-center">
  //         <p>Xem</p>
  //         <select className="border-2 border-slate-200 rounded-md">
  //           <option>10</option>
  //           <option>20</option>
  //           <option>50</option>
  //         </select>
  //         <p>dòng</p>
  //       </div>

  //       <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
  //         <table>
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border-l-[1px] border-slate-300">STT</th>
  //               <th className="border-l-[1px] border-slate-300">Danh mục</th>
  //               <th className="border-l-[1px] border-slate-300">Nhân viên</th>
  //               <th className="border-l-[1px] border-slate-300">SL công việc</th>
  //               <th className="border-l-[1px] border-slate-300">Chưa hoàn tất</th>
  //               <th className="border-l-[1px] border-slate-300">Hoàn tất</th>
  //               <th className="border-l-[1px] border-slate-300">Đúng hạn</th>
  //               <th className="border-l-[1px] border-slate-300">Trễ hạn</th>
  //               <th className="border-l-[1px] border-r-[1px] border-slate-300">Xếp loại</th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn A</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn A</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn A</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn A</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn A</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn A</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn A</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn A</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn A</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn A</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn A</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn A</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn A</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Phòng ban</td>
  //               <td className="text-left border border-slate-300">Nguyễn Aádasdasdasdasdasdasdasdasdsdasdasdasdasdasd</td>
  //               <td className="text-center border border-slate-300">120</td>
  //               <td className="text-center border border-slate-300">20</td>
  //               <td className="text-center border border-slate-300">100</td>
  //               <td className="text-center border border-slate-300">98</td>
  //               <td className="text-center border border-slate-300">2</td>
  //               <td className="text-center border border-slate-300">
  //                 Xuất sắc
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
  //         <p>Đang xem 10 trên 20 dòng</p>
  //         <Pagination count={10} variant="outlined" color="primary" size="small" />
  //       </div>
  //     </div>
  //   )
  // }

  // setting
  const SettingListOfEmployee = () => {
    const menu = takePermission(10);

    return (
      <div className="flex flex-col text-sm w-full lg:h-[calc(100%-7rem)] lg:mt-0 mt-3 lg:pb-0 pb-[5rem]">
        {" "}
        {/* warm */}
        <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300">STT</th>
                <th className="border-l-[1px] border-slate-300">Mã số</th>
                <th className="border-l-[1px] border-slate-300">Họ và tên</th>
                <th className="border-l-[1px] border-slate-300">Chức vụ</th>
                <th className="border-l-[1px] border-slate-300">Bộ phận</th>
                <th className="border-l-[1px] border-slate-300">Hoạt động</th>
                <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {settingData.userList === null ? (
                <tr></tr>
              ) : (
                settingData.userList.map((item: any, index: any) => (
                  <tr key={index} className="w-max h-[3rem] bg-white">
                    <td className="text-center border border-slate-300">
                      {index + 1}
                    </td>
                    <td className="border border-slate-300">{item.UserName}</td>
                    <td className="text-left border border-slate-300">
                      {item.FullName}
                    </td>
                    <td className="text-left border border-slate-300">
                      {item.TenChucVu}
                    </td>
                    <td className="text-left border border-slate-300">
                      {item.TenBoPhan}
                    </td>
                    <td className="text-center border border-slate-300">
                      <input
                        type="checkbox"
                        checked={item.IsActive === 1 ? true : false}
                        readOnly
                      />
                    </td>
                    <td className="text-center border border-slate-300">
                      <div className="dropdown">
                        {/* button options */}
                        <button className="dropbtn">
                          <MoreHorizIcon fontSize="small" />
                        </button>

                        {/* options fields */}
                        <div className="dropdown-content text-xs font-semibold">
                          {/* detail */}
                          <div
                            className={`${menu.PerEdit === true ? "flex" : "hidden"
                              } w-full h-full`}
                          >
                            <button
                              className="flex w-full h-full"
                              onClick={() => _onClickListOfEmployee(item.Id)}
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                {/* icon */}
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Chỉnh sửa</div>
                              </div>
                            </button>
                          </div>

                          <div
                            className={`${menu.PerEdit === true ? "hidden" : "flex"
                              } w-full h-full`}
                          >
                            <button
                              className="flex w-full h-full"
                              onClick={() =>
                                _onClickListOfEmployeeSingle(item.Id)
                              }
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                {/* icon */}
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Chi tiết</div>
                              </div>
                            </button>
                          </div>

                          <div
                            className={`${menu.PerDelete === true ? "flex" : "hidden"
                              } w-full h-full`}
                          >
                            <button
                              className="flex w-full h-full hover:text-red-500"
                              onClick={() => _onClickDeleteTask(item.Id)}
                            >
                              {/* delete - contain */}
                              <div className="flex w-full space-x-1">
                                {/* icon */}
                                <DeleteTwoToneIcon
                                  className="text-red-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div className="h-full">Xoá</div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const SettingListOfGeneral = () => {
    const menu = takePermission(12);

    return (
      <>
        {settingData.parameterList === null ? (
          <div className="flex space-y-1 justify-center items-center flex-col text-sm w-full h-[calc(100%-7rem)] lg:mt-0 mt-3">
            <LoaderSpinner w={36} h={36} />
            <p className="text-primary">Đang tải dữ liệu</p>
          </div>
        ) : (
          <div className="flex flex-col text-sm w-full lg:h-[calc(100%-7rem)] pb-[5rem] lg:mt-0 mt-3">
            {" "}
            {/* warm */}
            {/* <div className="flex w-full h-[2rem] space-x-1 items-center">
              <p>Xem</p>
              <select className="border-2 border-slate-200 rounded-md">
                <option>10</option>
                <option>20</option>
                <option>50</option>
              </select>
              <p>dòng</p>
            </div> */}
            <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] mt-6 shadow-md">
              <table>
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="border-l-[1px] border-slate-300">STT</th>
                    <th className="border-l-[1px] border-slate-300">
                      Danh mục
                    </th>
                    <th className="border-l-[1px] border-slate-300">
                      Nội dung
                    </th>
                    <th className="border-l-[1px] border-slate-300">
                      Hiển thị
                    </th>
                    <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
                  </tr>
                </thead>
                <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                  {settingData.parameterList === null ? (
                    <tr></tr>
                  ) : (
                    settingData.parameterList.map((item: any, index: any) => (
                      <tr key={index} className="w-max h-[3rem] bg-white">
                        <td className="text-center border border-slate-300">
                          {index +
                            1 +
                            settingData.pageNumber * settingData.pageList}
                        </td>
                        <td className="border border-slate-300">
                          {convertNameParameter(item.ParameterKey)}
                        </td>
                        <td className="text-left border border-slate-300">
                          {item.ParameterValue}
                        </td>
                        <td className="text-center border border-slate-300">
                          <input
                            type="checkbox"
                            checked={item.IsActive}
                            readOnly
                          />
                        </td>
                        <td className="text-center border border-slate-300">
                          <div className="dropdown">
                            {/* button options */}
                            <button className="dropbtn">
                              <MoreHorizIcon fontSize="small" />
                            </button>

                            {/* options fields */}
                            <div className="dropdown-content text-xs font-semibold">
                              {/* detail */}
                              <div
                                className={`${menu.PerEdit === true ? "flex" : "hidden"
                                  } w-full h-full`}
                              >
                                <button
                                  className={`flex w-full h-full`}
                                  onClick={() =>
                                    _onClickDetailListOfGeneral(
                                      item.ParameterId
                                    )
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                    {/* icon */}
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Chỉnh sửa</div>
                                  </div>
                                </button>
                              </div>

                              <div
                                className={`${menu.PerEdit === false ? "flex" : "hidden"
                                  }  w-full h-full`}
                              >
                                <button
                                  className={`flex w-full h-full`}
                                  onClick={() =>
                                    _onClickDetailSingleOfGeneral(
                                      item.ParameterId
                                    )
                                  }
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                    {/* icon */}
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Chi tiết</div>
                                  </div>
                                </button>
                              </div>

                              <div
                                className={`${menu.PerDelete === true ? "flex" : "hidden"
                                  }  w-full h-full`}
                              >
                                <button
                                  className="flex w-full h-full hover:text-red-500"
                                  onClick={() =>
                                    _onClickDeleteGeneral(item.ParameterId)
                                  }
                                >
                                  {/* delete - contain */}
                                  <div className="flex w-full space-x-1">
                                    {/* icon */}
                                    <DeleteTwoToneIcon
                                      className="text-red-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div className="h-full">Xoá</div>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            {/* <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
              <p>Đang xem 10 trên 20 dòng</p>
              <Pagination count={10} variant="outlined" color="primary" size="small" />
            </div> */}
          </div>
        )}
      </>
    );
  };

  const SettingPriorRate = () => {
    const menu = takePermission(13);

    return (
      <div className="flex flex-col text-sm w-full lg:h-[calc(100%-7rem)] lg:mt-0 mt-3">
        {" "}
        {/* warm */}
        <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300">STT</th>
                <th className="border-l-[1px] border-slate-300">Danh mục</th>
                <th className="border-l-[1px] border-slate-300">Nội dung</th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  Hiển thị
                </th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  Mức độ
                </th>
                <th className="hidden lg:table-cell border-l-[1px] border-r-[1px] border-slate-300"></th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {settingData.priorList.length === 0 ? (
                <tr></tr>
              ) : (
                settingData.priorList.map((item: any, index: any) => {
                  return (
                    <tr key={index} className="w-max h-[3rem] bg-white">
                      <td className="text-center border border-slate-300">
                        {index + 1}
                      </td>
                      <td className="border border-slate-300">
                        {item.PriorityLevelName}
                      </td>
                      <td className="text-left border border-slate-300">
                        {item.Description}

                        <div className="lg:hidden text-slate-500">
                          <div className="flex space-x-3">
                            <p>Hiển thị</p>
                            <input type="checkbox" />
                          </div>
                          <div className="flex space-x-2">
                            <p>Mức độ</p>
                            <div
                              style={{ backgroundColor: `${item.Color}` }}
                              className="w-[1.4rem] h-[1.4rem] rounded-full"
                            />
                          </div>

                          <div className="flex pt-1 space-x-3">
                            <p>Chi tiết</p>
                            <div className="dropdown">
                              {/* button options */}
                              <button className="dropbtn">
                                <MoreHorizIcon fontSize="small" />
                              </button>

                              {/* options fields */}
                              <div className="dropdown-content text-xs font-semibold">
                                {/* detail */}
                                <div
                                  className={`w-full h-full ${menu.PerEdit === true ? "flex" : "hidden"
                                    }`}
                                >
                                  <button
                                    className="flex w-full h-full"
                                    onClick={() => _onClickDetailPrior(item.Id)}
                                  >
                                    {/* detail - contain */}
                                    <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                      {/* icon */}
                                      <DescriptionTwoToneIcon
                                        className="text-sky-600"
                                        sx={{ fontSize: 18 }}
                                        fontSize="small"
                                      />

                                      {/* title */}
                                      <div>Chỉnh sửa</div>
                                    </div>
                                  </button>
                                </div>

                                <div
                                  className={`${menu.PerEdit === true ? "hidden" : "flex"
                                    } w-full h-full`}
                                >
                                  <button
                                    className="flex w-full h-full"
                                    onClick={() =>
                                      _onClickDetailPriorSingle(item.Id)
                                    }
                                  >
                                    {/* detail - contain */}
                                    <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                      {/* icon */}
                                      <DescriptionTwoToneIcon
                                        className="text-sky-600"
                                        sx={{ fontSize: 18 }}
                                        fontSize="small"
                                      />

                                      {/* title */}
                                      <div>Chi tiết</div>
                                    </div>
                                  </button>
                                </div>

                                <div
                                  className={`w-full h-full ${menu.PerDelete === true ? "flex" : "hidden"
                                    }`}
                                >
                                  <button
                                    className="flex w-full h-full hover:text-red-500"
                                    onClick={() => _onClickDeletePrior(item.Id)}
                                  >
                                    {/* delete - contain */}
                                    <div className="flex w-full space-x-1">
                                      {/* icon */}
                                      <DeleteTwoToneIcon
                                        className="text-red-600"
                                        sx={{ fontSize: 18 }}
                                        fontSize="small"
                                      />

                                      {/* title */}
                                      <div className="h-full">Xoá</div>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <input type="checkbox" />
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300 ">
                        {/* <p className={`bg-[${item.Color}] text-[${item.Color}]`}>mức độ</p> */}
                        <div
                          style={{ backgroundColor: `${item.Color}` }}
                          className={`w-full h-[1.5rem]`}
                        ></div>
                      </td>

                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="dropdown">
                          {/* button options */}
                          <button className="dropbtn">
                            <MoreHorizIcon fontSize="small" />
                          </button>

                          {/* options fields */}
                          <div className="dropdown-content text-xs font-semibold">
                            {/* detail */}
                            <div
                              className={`w-full h-full ${menu.PerEdit === true ? "flex" : "hidden"
                                }`}
                            >
                              <button
                                className="flex w-full h-full"
                                onClick={() => _onClickDetailPrior(item.Id)}
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chỉnh sửa</div>
                                </div>
                              </button>
                            </div>

                            <div
                              className={`${menu.PerEdit === true ? "hidden" : "flex"
                                } w-full h-full`}
                            >
                              <button
                                className="flex w-full h-full"
                                onClick={() =>
                                  _onClickDetailPriorSingle(item.Id)
                                }
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chi tiết</div>
                                </div>
                              </button>
                            </div>

                            <div
                              className={`w-full h-full ${menu.PerDelete === true ? "flex" : "hidden"
                                }`}
                            >
                              <button
                                className="flex w-full h-full hover:text-red-500"
                                onClick={() => _onClickDeletePrior(item.Id)}
                              >
                                {/* delete - contain */}
                                <div className="flex w-full space-x-1">
                                  {/* icon */}
                                  <DeleteTwoToneIcon
                                    className="text-red-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div className="h-full">Xoá</div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const SettingDifficultRate = () => {
    const menu = takePermission(42);

    return (
      <div className="flex flex-col text-sm w-full lg:h-[calc(100%-7rem)] pb-[5rem] lg:mt-0 mt-3">
        {" "}
        {/* warm */}
        <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300">STT </th>
                <th className="border-l-[1px] border-slate-300">Danh mục</th>
                <th className="border-l-[1px] border-slate-300">Nội dung</th>
                {/* <th className="border-l-[1px] border-slate-300">Hiển thị</th> */}
                <th className="border-l-[1px] border-slate-300">Mức độ</th>
                <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {settingData.dificultList === null ? (
                <tr></tr>
              ) : (
                settingData.dificultList.map((item: any, index: any) => {
                  return (
                    <tr key={index} className="w-max h-[3rem] bg-white">
                      <td className="text-center border border-slate-300">
                        {index + 1}
                      </td>
                      <td className="text-center border border-slate-300">
                        {item.DifficultLevelName}
                      </td>
                      <td className="text-center border border-slate-300">
                        {item.Description}
                      </td>
                      {/* <td className="text-center border border-slate-300">
                        <input type="checkbox" />
                      </td> */}
                      <td className="text-center border border-slate-300 ">
                        {/* <p className={`bg-[${item.Color}] text-[${item.Color}]`}>mức độ</p> */}
                        <div
                          style={{ backgroundColor: `${item.Color}` }}
                          className={`w-full h-[1.5rem] drop-shadow-lg`}
                        ></div>
                      </td>

                      <td className="text-center border border-slate-300">
                        <div className="dropdown">
                          {/* button options */}
                          <button className="dropbtn">
                            <MoreHorizIcon fontSize="small" />
                          </button>

                          {/* options fields */}
                          <div className="dropdown-content text-xs font-semibold">
                            {/* detail */}
                            <div
                              className={`w-full h-full ${menu.PerEdit === true ? "flex" : "hidden"
                                }`}
                            >
                              <button
                                className="flex w-full h-full"
                                onClick={() => _onClickDetailDificult(item.Id)}
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chỉnh sửa</div>
                                </div>
                              </button>
                            </div>

                            <div
                              className={`${menu.PerEdit === true ? "hidden" : "flex"
                                } w-full h-full`}
                            >
                              <button
                                className="flex w-full h-full"
                                onClick={() =>
                                  _onClickDetailSecuritySingle(item.Id)
                                }
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chi tiết</div>
                                </div>
                              </button>
                            </div>

                            <div
                              className={`w-full h-full ${menu.PerDelete === true ? "flex" : "hidden"
                                }`}
                            >
                              <button
                                className="flex w-full h-full hover:text-red-500"
                                onClick={() => _onClickDeleteDificult(item.Id)}
                              >
                                {/* delete - contain */}
                                <div className="flex w-full space-x-1">
                                  {/* icon */}
                                  <DeleteTwoToneIcon
                                    className="text-red-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div className="h-full">Xoá</div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const SettingSecurityLevel = () => {
    const menu = takePermission(40);

    return (
      <div className="flex flex-col text-sm w-full lg:h-[calc(100%-7rem)] pb-[5rem] lg:mt-0 mt-3">
        {" "}
        {/* warm */}
        <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300">STT</th>
                <th className="border-l-[1px] border-slate-300">Danh mục</th>
                <th className="border-l-[1px] border-slate-300">Nội dung</th>
                <th className="border-l-[1px] border-slate-300">Hiển thị</th>
                <th className="border-l-[1px] border-slate-300">Mức độ</th>
                <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {settingData.securityList === null ? (
                <tr></tr>
              ) : (
                settingData.securityList.map((item: any, index: any) => {
                  return (
                    <tr key={index} className="w-max h-[3rem] bg-white">
                      <td className="text-center border border-slate-300">
                        {index + 1}
                      </td>
                      <td className="border border-slate-300">
                        {item.SecurityLevelName}
                      </td>
                      <td className="text-left border border-slate-300">
                        {item.Description}
                      </td>
                      <td className="text-center border border-slate-300">
                        <input type="checkbox" />
                      </td>
                      <td className="text-center border border-slate-300 ">
                        {/* <p className={`bg-[${item.Color}] text-[${item.Color}]`}>mức độ</p> */}
                        <div
                          style={{ backgroundColor: `${item.Color}` }}
                          className={`w-full h-[1.5rem] drop-shadow-lg`}
                        ></div>
                      </td>

                      <td className="text-center border border-slate-300">
                        <div className="dropdown">
                          {/* button options */}
                          <button className="dropbtn">
                            <MoreHorizIcon fontSize="small" />
                          </button>

                          {/* options fields */}
                          <div className="dropdown-content text-xs font-semibold">
                            {/* detail */}
                            <div
                              className={`w-full h-full ${menu.PerEdit === true ? "flex" : "hidden"
                                }`}
                            >
                              <button
                                className="flex w-full h-full"
                                onClick={() => _onClickDetailSecurity(item.Id)}
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chỉnh sửa</div>
                                </div>
                              </button>
                            </div>

                            <div
                              className={`${menu.PerEdit === true ? "hidden" : "flex"
                                } w-full h-full`}
                            >
                              <button
                                className="flex w-full h-full"
                                onClick={() =>
                                  _onClickDetailSecuritySingle(item.Id)
                                }
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chi tiết</div>
                                </div>
                              </button>
                            </div>

                            <div
                              className={`w-full h-full ${menu.PerDelete === true ? "flex" : "hidden"
                                }`}
                            >
                              <button
                                className="flex w-full h-full hover:text-red-500"
                                onClick={() => _onClickDeleteSecurity(item.Id)}
                              >
                                {/* delete - contain */}
                                <div className="flex w-full space-x-1">
                                  {/* icon */}
                                  <DeleteTwoToneIcon
                                    className="text-red-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div className="h-full">Xoá</div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  // const SettingTargetScoring = () => {
  //   return (
  //     <div className="flex flex-col text-sm w-full h-[calc(100%-7rem)] lg:mt-0 mt-3"> {/* warm */}
  //       <div className="flex w-full h-[2rem] space-x-1 items-center">
  //         <p>Xem</p>
  //         <select className="border-2 border-slate-200 rounded-md">
  //           <option>10</option>
  //           <option>20</option>
  //           <option>50</option>
  //         </select>
  //         <p>dòng</p>
  //       </div>

  //       <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
  //         <table>
  //           <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //             <tr>
  //               <th className="border-l-[1px] border-slate-300">STT</th>
  //               <th className="border-l-[1px] border-slate-300">Tiêu chí</th>
  //               <th className="border-l-[1px] border-slate-300">Nội dung</th>
  //               <th className="border-l-[1px] border-slate-300">Hiển thị</th>
  //               <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
  //             </tr>
  //           </thead>
  //           <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Không có việc trễ hạn quá 3 ngày</td>
  //               <td className="text-left border border-slate-300">Tiêu chí đánh giá</td>
  //               <td className="text-center border border-slate-300">
  //                 <input
  //                   type="checkbox"
  //                 />
  //               </td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                       onClick={() => _onClickDetailTargetCapactity(1)}
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết / Chỉnh sửa
  //                         </div>
  //                       </div>
  //                     </button>

  //                     <button className="flex w-full h-full hover:text-red-500"
  //                       onClick={() => _onClickDeleteTask(1)}
  //                     >
  //                       {/* delete - contain */}
  //                       <div className="flex w-full space-x-1">
  //                         {/* icon */}
  //                         <DeleteTwoToneIcon className="text-red-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div className="h-full">
  //                           Xoá
  //                         </div>
  //                       </div>
  //                     </button>
  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //             <tr className="w-max h-[3rem] bg-white">
  //               <td className="text-center border border-slate-300">#</td>
  //               <td className="border border-slate-300">Không đi làm trễ quá 5 lần</td>
  //               <td className="text-left border border-slate-300">Tiêu chí đánh giá</td>
  //               <td className="text-center border border-slate-300">
  //                 <input
  //                   type="checkbox"
  //                 />
  //               </td>
  //               <td className="text-center border border-slate-300">
  //                 <div className="dropdown">
  //                   {/* button options */}
  //                   <button className="dropbtn">
  //                     <MoreHorizIcon fontSize="small" />
  //                   </button>

  //                   {/* options fields */}
  //                   <div className="dropdown-content text-xs font-semibold">
  //                     {/* detail */}
  //                     <button
  //                       className="flex w-full h-full"
  //                       onClick={() => _onClickDetailTargetCapactity(1)}
  //                     >
  //                       {/* detail - contain */}
  //                       <div className="flex w-full h-full space-x-1 hover:text-sky-500">
  //                         {/* icon */}
  //                         <DescriptionTwoToneIcon className="text-sky-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div>
  //                           Chi tiết / Chỉnh sửa
  //                         </div>
  //                       </div>
  //                     </button>

  //                     <button className="flex w-full h-full hover:text-red-500"
  //                       onClick={() => _onClickDeleteTask(1)}
  //                     >
  //                       {/* delete - contain */}
  //                       <div className="flex w-full space-x-1">
  //                         {/* icon */}
  //                         <DeleteTwoToneIcon className="text-red-600" sx={{ fontSize: 18 }} fontSize="small" />

  //                         {/* title */}
  //                         <div className="h-full">
  //                           Xoá
  //                         </div>
  //                       </div>
  //                     </button>
  //                   </div>
  //                 </div>
  //               </td>
  //             </tr>
  //           </tbody>
  //         </table>
  //       </div>

  //       <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
  //         <p>Đang xem 10 trên 20 dòng</p>
  //         <Pagination count={10} variant="outlined" color="primary" size="small" />
  //       </div>
  //     </div>
  //   )
  // }

  const SettingSrocing = () => {
    return (
      <div className="flex flex-col text-sm w-full h-[calc(100%-7rem)] lg:mt-0 mt-3">
        {" "}
        {/* warm */}
        <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300">STT</th>
                <th className="border-l-[1px] border-slate-300">
                  Tiêu thiết lập
                </th>
                <th className="border-l-[1px] border-slate-300">Nội dung</th>
                <th className="border-l-[1px] border-slate-300">Phòng ban</th>
                <th className="border-l-[1px] border-slate-300">
                  Ngày áp dụng
                </th>
                <th className="border-l-[1px] border-slate-300">Áp dụng</th>
                <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">#</td>
                <td className="border border-slate-300">Đánh giá tháng</td>
                <td className="text-left border border-slate-300">
                  Đánh giá tháng
                </td>
                <td className="text-left border border-slate-300">Kế toán</td>
                <td className="text-center border border-slate-300">
                  01/0/1/2022
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <div className="dropdown">
                    {/* button options */}
                    <button className="dropbtn">
                      <MoreHorizIcon fontSize="small" />
                    </button>

                    {/* options fields */}
                    <div className="dropdown-content text-xs font-semibold">
                      {/* detail */}
                      <button
                        className="flex w-full h-full"
                        onClick={() => _onClickDetailTableCapacity(1)}
                      >
                        {/* detail - contain */}
                        <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                          {/* icon */}
                          <DescriptionTwoToneIcon
                            className="text-sky-600"
                            sx={{ fontSize: 18 }}
                            fontSize="small"
                          />

                          {/* title */}
                          <div>Chi tiết / Chỉnh sửa</div>
                        </div>
                      </button>

                      <button
                        className="flex w-full h-full hover:text-red-500"
                        onClick={() => _onClickDeleteTask(1)}
                      >
                        {/* delete - contain */}
                        <div className="flex w-full space-x-1">
                          {/* icon */}
                          <DeleteTwoToneIcon
                            className="text-red-600"
                            sx={{ fontSize: 18 }}
                            fontSize="small"
                          />

                          {/* title */}
                          <div className="h-full">Xoá</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  // user
  const UserList = () => {
    const menu = takePermission(5);

    return (
      <div className="flex flex-col text-sm w-full h-[calc(100%-7rem)] lg:mt-0 mt-3">
        {" "}
        {/* warm */}
        <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300">STT</th>
                <th className="border-l-[1px] border-slate-300">Tên quyền</th>
                <th className="border-l-[1px] border-slate-300">Mô tả</th>
                <th className="border-l-[1px] border-slate-300">Sử dụng</th>
                <th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {userData.groupList === null ? (
                <></>
              ) : (
                userData.groupList.map((item: any, index: any) => (
                  <tr key={index} className="w-max h-[3rem] bg-white">
                    <td className="text-center border border-slate-300">
                      {index + 1}
                    </td>
                    <td className="border border-slate-300">
                      {item.GroupName}
                    </td>
                    <td className="text-left border border-slate-300">
                      {item.GroupDescription}
                    </td>
                    <td className="text-center border border-slate-300">
                      <input type="checkbox" checked={item.IsActive} readOnly />
                    </td>
                    <td className="text-center border border-slate-300">
                      <div className="dropdown">
                        {/* button options */}
                        <button className="dropbtn">
                          <MoreHorizIcon fontSize="small" />
                        </button>

                        {/* options fields */}
                        <div className="dropdown-content text-xs font-semibold">
                          {/* detail */}
                          <div
                            className={`${menu.PerEdit === true ? "flex" : "hidden"
                              } w-full h-full`}
                          >
                            <button
                              className="flex w-full h-full"
                              onClick={() => _onClickDetailUserList(item.ID)}
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                {/* icon */}
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Chỉnh sửa</div>
                              </div>
                            </button>
                          </div>

                          <div
                            className={`${menu.PerEdit === false ? "flex" : "hidden"
                              } w-full h-full`}
                          >
                            <button
                              className="flex w-full h-full"
                              onClick={() =>
                                _onClickDetailUserListSingle(item.ID)
                              }
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                {/* icon */}
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Chi tiết</div>
                              </div>
                            </button>
                          </div>

                          <div
                            className={`${menu.PerDelete === true ? "flex" : "hidden"
                              } w-full h-full`}
                          >
                            <button
                              className="flex w-full h-full hover:text-red-500"
                              onClick={() => _onClickDeleteUser(item.ID)}
                            >
                              {/* delete - contain */}
                              <div className="flex w-full space-x-1">
                                {/* icon */}
                                <DeleteTwoToneIcon
                                  className="text-red-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div className="h-full">Xoá</div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  // const UserFunctionalList = () => {

  //   const menu = takePermission(6);

  //   // funcs
  //   const _onChangeCheckbox = async (e: any, typeCall: string, id: any, value: any) => {

  //     const choose = userData.functionChoose;
  //     const FormId = id
  //     const checked = value === true ? false : true;
  //     let type: string = ""

  //     switch (typeCall) {
  //       case "perActive":
  //         type = "view";
  //         break;
  //       case "perInsert":
  //         type = "create"
  //         break;
  //       case "perEdit":
  //         type = "update"
  //         break;
  //       case "perDelete":
  //         type = "delete"
  //         break;
  //       case "perApprove":
  //         type = "approve"
  //         break;

  //       default:
  //         break;
  //     }

  //     const accessToken = localStorage.getItem("accessToken");
  //     const API = SYS_PERMISSION_UPDATE(choose, type, FormId, checked);

  //     try {
  //       await axios.get(
  //         API,
  //         {
  //           headers: {
  //             Authorization: "Bearer " + accessToken,
  //             Accept: "text/plain"
  //           }
  //         }
  //       )
  //         .then((res: any) => {
  //           if (res.status === 200) {
  //             const API = SYS_PERMISSION_LOADFUNC + userData.functionChoose.toString();

  //             const takeData = async () => {
  //               try {
  //                 await axios.get(
  //                   API,
  //                   {
  //                     headers: {
  //                       Authorization: "Bearer " + accessToken,
  //                       Accept: "text/plain"
  //                     }
  //                   }
  //                 )
  //                   .then((res: any) => {
  //                     if (res.status === 200) {
  //                       dispatch(ONLOAD_FUNCTIONLIST(res.data.Data))
  //                     }
  //                   })
  //               } catch (error: any) {
  //                 toast.error("Tải dữ liệu thất bại.")
  //               }
  //             }

  //             takeData();
  //           }
  //         })
  //     } catch (error: any) {
  //       toast.error("Cập nhật thất bại.");
  //     }
  //   }

  //   return (
  //     <div className="flex flex-col text-sm w-full h-[calc(100%-7rem)] mt-3"> {/* warm */}

  //       {userData.functionChoose === 0 ? <p className="mt-3 text-slate-500 font-semibold text-lg">VUI LÒNG CHỌN NHÓM QUYỀN</p>
  //         : <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
  //           <table>
  //             <thead className="text-white font-semibold text-center w-full h-[2rem]">
  //               <tr>
  //                 <th className="border-l-[1px] border-slate-300">STT</th>
  //                 <th className="border-l-[1px] border-slate-300">Nhóm chức năng</th>
  //                 <th className="border-l-[1px] border-slate-300">Chức năng</th>
  //                 <th className="border-l-[1px] border-slate-300">Xem</th>
  //                 <th className="border-l-[1px] border-slate-300">Tạo</th>
  //                 <th className="border-l-[1px] border-slate-300">Sửa</th>
  //                 <th className="border-l-[1px] border-slate-300">Xoá</th>
  //                 <th className="border-l-[1px] border-r-[1px] border-slate-300">Giao việc</th>
  //               </tr>
  //             </thead>
  //             <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
  //               {userData.functionList === null ? <tr></tr>
  //                 : userData.functionList.map((item: any, index: any) => (
  //                   <tr key={index} className="w-max h-[3rem] bg-white">
  //                     <td className="text-center border border-slate-300">{index + 1}</td>
  //                     <td className="border border-slate-300">{item.FormGroup}</td>
  //                     <td className="border border-slate-300">{item.FormTitle}</td>
  //                     <td className="text-center border border-slate-300">
  //                       {item.v_Active === true
  //                         ? <input
  //                           type="checkbox"
  //                           checked={item.PerActive}
  //                           onChange={(e: any) => _onChangeCheckbox(e, "perActive", item.FormId, item.PerActive)}
  //                           disabled={menu.PerEdit === true ? false : true}
  //                         /> : ""}
  //                     </td>
  //                     <td className="text-center border border-slate-300">
  //                       {item.v_Insert === true
  //                         ? <input
  //                           type="checkbox"
  //                           checked={item.PerInsert}
  //                           onChange={(e: any) => _onChangeCheckbox(e, "perInsert", item.FormId, item.PerInsert)}
  //                           disabled={menu.PerEdit === true ? false : true}
  //                         /> : ""}
  //                     </td>
  //                     <td className="text-center border border-slate-300">
  //                       {item.v_Edit === true
  //                         ? <input
  //                           type="checkbox"
  //                           checked={item.PerEdit}
  //                           onChange={(e: any) => _onChangeCheckbox(e, "perEdit", item.FormId, item.PerEdit)}
  //                           disabled={menu.PerEdit === true ? false : true}
  //                         /> : ""}
  //                     </td>
  //                     <td className="text-center border border-slate-300">
  //                       {item.v_Delete === true
  //                         ? <input
  //                           type="checkbox"
  //                           checked={item.PerDelete}
  //                           onChange={(e: any) => _onChangeCheckbox(e, "perDelete", item.FormId, item.PerDelete)}
  //                           disabled={menu.PerEdit === true ? false : true}
  //                         /> : ""}
  //                     </td>
  //                     <td className="text-center border border-slate-300">
  //                       {item.v_Approve === true
  //                         ? <input
  //                           type="checkbox"
  //                           checked={item.PerApprove}
  //                           onChange={(e: any) => _onChangeCheckbox(e, "perApprove", item.FormId, item.PerApprove)}
  //                           disabled={menu.PerEdit === true ? false : true}
  //                         /> : ""}
  //                     </td>
  //                   </tr>
  //                 ))
  //               }
  //             </tbody>
  //           </table>
  //         </div>
  //       }
  //     </div>
  //   )
  // }

  const UserScreenList = () => {
    // funcs
    const _onChangeCheckbox = async (perActive: boolean, id: any) => {
      const accessToken = localStorage.getItem("accessToken");

      const checked = perActive === true ? false : true;
      const screenChoose = userData.screenChoose;

      try {
        await axios
          .get(SYS_DASHBOARD_UPDATE(id, screenChoose, checked), {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain",
            },
          })
          .then((res: any) => {
            if (res.status === 200) {
              const takeData = async () => {
                const API =
                  SYS_DASHBOARD_LOADFUNCS + userData.screenChoose.toString();

                try {
                  await axios
                    .get(API, {
                      headers: {
                        Authorization: "Bearer " + accessToken,
                        Accept: "text/plain",
                      },
                    })
                    .then((res: any) => {
                      dispatch(ONLOAD_SCREENLIST(res.data.Data));
                    });
                } catch (error: any) { }
              };
              takeData();
            }
          });
      } catch (error: any) {
        toast.error("Cập nhật thất bại. plplpl");
      }
    };

    return (
      <div className="flex flex-col text-sm w-full h-[calc(100%-7rem)]">
        {" "}
        {/* warm */}
        <div className="flex w-full h-[1rem] space-x-1 items-center"></div>
        {userData.screenChoose === 0 ? (
          <p className="mt-3 text-slate-500 font-semibold text-lg">
            VUI LÒNG CHỌN NHÓM QUYỀN
          </p>
        ) : (
          <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="border-l-[1px] border-slate-300">STT</th>
                  <th className="border-l-[1px] border-slate-300">
                    Tên màn hình
                  </th>
                  <th className="border-l-[1px] border-r-[1px] border-slate-300">
                    Sử dụng
                  </th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {userData.screenList === null ? (
                  <tr></tr>
                ) : (
                  userData.screenList.map((item: any, index: any) => (
                    <>
                      <tr key={index} className="w-max h-[3rem] bg-white">
                        <td className="text-center border border-slate-300">
                          {index + 1}
                        </td>
                        <td className="text-left border border-slate-300">
                          {item.Ddescription}
                        </td>
                        <td className="text-center border border-slate-300">
                          <input
                            type="checkbox"
                            checked={item.PerActive}
                            onChange={() => {
                              _onChangeCheckbox(item.PerActive, item.Id);
                            }}
                          />
                        </td>
                      </tr>
                    </>
                  ))
                )}
                {/* <tr className="w-max h-[3rem] bg-white">
      <td className="text-center border border-slate-300">#</td>
      <td className="border border-slate-300">Công việc</td>
      <td className="text-left border border-slate-300">Màn hình hiển thị danh sách việc cần làm</td>
      <td className="text-center border border-slate-300">
        <input
          type="checkbox"
        />
      </td>
    </tr> */}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  const UserWorkCategoryList = () => {
    return (
      <div className="flex flex-col text-sm w-full h-[calc(100%-7rem)] mt-3">
        {" "}
        {/* warm */}
        <div className="flex w-full h-[2rem] space-x-1 items-center">
          <p>Xem</p>
          <select className="border-2 border-slate-200 rounded-md">
            <option>10</option>
            <option>20</option>
            <option>50</option>
          </select>
          <p>dòng</p>
        </div>
        <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300">STT</th>
                <th className="border-l-[1px] border-slate-300">
                  Nhóm chức năng
                </th>
                <th className="border-l-[1px] border-slate-300">Chức năng</th>
                <th className="border-l-[1px] border-slate-300">Xem</th>
                <th className="border-l-[1px] border-slate-300">Tạo</th>
                <th className="border-l-[1px] border-slate-300">Sửa</th>
                <th className="border-l-[1px] border-r-[1px] border-slate-300">
                  Xoá
                </th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">#</td>
                <td className="border border-slate-300">Công việc</td>
                <td className="text-left border border-slate-300">
                  Tạo công việc mới
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">#</td>
                <td className="border border-slate-300">Công việc</td>
                <td className="text-left border border-slate-300">
                  Công việc cần giao
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300"></td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">#</td>
                <td className="border border-slate-300">Công việc</td>
                <td className="text-left border border-slate-300">
                  Việc đã hoàn tất đang chờ duyệt
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300"></td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
          <p>Đang xem 10 trên 20 dòng</p>
          <Pagination
            count={10}
            variant="outlined"
            color="primary"
            size="small"
          />
        </div>
      </div>
    );
  };

  const UserDataOptionList = () => {
    return (
      <div className="flex flex-col text-sm w-full h-[calc(100%-7rem)] mt-3">
        {" "}
        {/* warm */}
        <div className="flex w-full h-[2rem] space-x-1 items-center">
          <p>Xem</p>
          <select className="border-2 border-slate-200 rounded-md">
            <option>10</option>
            <option>20</option>
            <option>50</option>
          </select>
          <p>dòng</p>
        </div>
        <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300">STT</th>
                <th className="border-l-[1px] border-slate-300">
                  Nhóm chức năng
                </th>
                <th className="border-l-[1px] border-slate-300">Chức năng</th>
                <th className="border-l-[1px] border-slate-300">Xem</th>
                <th className="border-l-[1px] border-slate-300">Tạo</th>
                <th className="border-l-[1px] border-slate-300">Sửa</th>
                <th className="border-l-[1px] border-r-[1px] border-slate-300">
                  Xoá
                </th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">#</td>
                <td className="border border-slate-300">Công việc</td>
                <td className="text-left border border-slate-300">
                  Tạo công việc mới
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">#</td>
                <td className="border border-slate-300">Công việc</td>
                <td className="text-left border border-slate-300">
                  Công việc cần giao
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300"></td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">#</td>
                <td className="border border-slate-300">Công việc</td>
                <td className="text-left border border-slate-300">
                  Việc đã hoàn tất đang chờ duyệt
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300"></td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
          <p>Đang xem 10 trên 20 dòng</p>
          <Pagination
            count={10}
            variant="outlined"
            color="primary"
            size="small"
          />
        </div>
      </div>
    );
  };

  const ProposeSenderList = () => {
    return (
      <div className="flex flex-col text-sm w-full h-[calc(100%-7rem)] mt-3">
        {" "}
        {/* warm */}
        <div className="flex w-full h-[2rem] space-x-1 items-center">
          <p>Xem</p>
          <select className="border-2 border-slate-200 rounded-md">
            <option>10</option>
            <option>20</option>
            <option>50</option>
          </select>
          <p>dòng</p>
        </div>
        <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300">STT</th>
                <th className="border-l-[1px] border-slate-300">
                  Nhóm chức năng
                </th>
                <th className="border-l-[1px] border-slate-300">Chức năng</th>
                <th className="border-l-[1px] border-slate-300">Xem</th>
                <th className="border-l-[1px] border-slate-300">Tạo</th>
                <th className="border-l-[1px] border-slate-300">Sửa</th>
                <th className="border-l-[1px] border-r-[1px] border-slate-300">
                  Xoá
                </th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">#</td>
                <td className="border border-slate-300">Công việc</td>
                <td className="text-left border border-slate-300">
                  Tạo công việc mới
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">#</td>
                <td className="border border-slate-300">Công việc</td>
                <td className="text-left border border-slate-300">
                  Công việc cần giao
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300"></td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">#</td>
                <td className="border border-slate-300">Công việc</td>
                <td className="text-left border border-slate-300">
                  Việc đã hoàn tất đang chờ duyệt
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300"></td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
          <p>Đang xem 10 trên 20 dòng</p>
          <Pagination
            count={10}
            variant="outlined"
            color="primary"
            size="small"
          />
        </div>
      </div>
    );
  };

  const ProposeReceiverList = () => {
    return (
      <div className="flex flex-col text-sm w-full h-[calc(100%-7rem)] mt-3">
        {" "}
        {/* warm */}
        <div className="flex w-full h-[2rem] space-x-1 items-center">
          <p>Xem</p>
          <select className="border-2 border-slate-200 rounded-md">
            <option>10</option>
            <option>20</option>
            <option>50</option>
          </select>
          <p>dòng</p>
        </div>
        <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="border-l-[1px] border-slate-300">STT</th>
                <th className="border-l-[1px] border-slate-300">
                  Nhóm chức năng
                </th>
                <th className="border-l-[1px] border-slate-300">Chức năng</th>
                <th className="border-l-[1px] border-slate-300">Xem</th>
                <th className="border-l-[1px] border-slate-300">Tạo</th>
                <th className="border-l-[1px] border-slate-300">Sửa</th>
                <th className="border-l-[1px] border-r-[1px] border-slate-300">
                  Xoá
                </th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">#</td>
                <td className="border border-slate-300">Công việc</td>
                <td className="text-left border border-slate-300">
                  Tạo công việc mới
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">#</td>
                <td className="border border-slate-300">Công việc</td>
                <td className="text-left border border-slate-300">
                  Công việc cần giao
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300"></td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
              </tr>
              <tr className="w-max h-[3rem] bg-white">
                <td className="text-center border border-slate-300">#</td>
                <td className="border border-slate-300">Công việc</td>
                <td className="text-left border border-slate-300">
                  Việc đã hoàn tất đang chờ duyệt
                </td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300"></td>
                <td className="text-center border border-slate-300">
                  <input type="checkbox" />
                </td>
                <td className="text-center border border-slate-300"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex lg:flex-row flex-col items-center lg:justify-between w-full h-[2rem]">
          <p>Đang xem 10 trên 20 dòng</p>
          <Pagination
            count={10}
            variant="outlined"
            color="primary"
            size="small"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {urlLink === "/working/new-task" ? (
        <WorkingNewTaskJSX />
      ) : urlLink === "/working/task-return" ? (
        <WorkingTaskReturnJSX />
      ) : urlLink === "/working/receiver/task-waiting" ? (
        <WorkingTaskWaiting />
      ) : urlLink === "/working/receiver/task-returnwork" ? (
        <WorkingTaskReturnReceiverJSX />
      ) : urlLink === "/working/task-need-give" ? (
        <WorkingTaskNeedGiveJSX />
      ) : urlLink === "/working/task-finish" ? (
        <WorkingTaskFinishJSX />

      ) : urlLink === "/working/task-delay" ? (
        <WorkingTaskDelayJSX />
      ) 
      
      : urlLink === "/working/task-proccessing" ? (
        <WorkingTaskProccessingJSX />
      ) : urlLink === "/working/task-end-soon" ? (
        <WorkingTaskEndSoonJSX />
      ) : urlLink === "/working/task-change-deadline" ? (
        <WorkingTaskChangeDeadlineJSX />
      ) : urlLink === "/working/task-end-today" ? (
        <WorkingTaskEndTodayJSX />
      ) : urlLink === "/working/task-totalall" ? (
        <TotalTaskWithBossJSX />
      ) : urlLink === "/working/task-total" ? (
        <WorkingTaskTotalJSX />

      ) : urlLink === "/voting/voting_work" ? (
        <VotingWork />
      ) : urlLink === "/voting/voting_wait" ? (
        <VotingWork />
        
      ) : urlLink === "/working/receiver/task-new" ? (
        <WorkingNewTaskReceiverJSX />
      ) : urlLink === "/working/receiver/task-received" ? (
        <WorkingTaskReceivedReceiverJSX />
      ) : urlLink === "/working/receiver/task-delay" ? (
        <WorkingTaskDelayReceiverJSX />
      ) : urlLink === "/working/receiver/task-end-soon" ? (
        <WorkingTaskEndSoonReceiverJSX />
      ) : urlLink === "/working/receiver/task-end-today" ? (
        <WorkingTaskEndTodayReceiverJSX />
      ) : urlLink === "/working/receiver/task-processing" ? (
        <WorkingTaskProcessingReceiverJSX />
      ) : urlLink === "/working/receiver/all-task" ? (
        <WorkingTaskAllTaskReceiverJSX />
      ) : urlLink === "/report/deliver/worker-capacity" ? (
        <ReportWorkerCapacity />
      ) : urlLink === "/setting/listof-category/list-of-employee" ? (
        <SettingListOfEmployee />
      ) : urlLink === "/setting/listof-category/list-of-general" ? (
        <SettingListOfGeneral />
      ) : urlLink === "/setting/listof-category/prior-rate" ? (
        <SettingPriorRate />
      ) : urlLink === "/setting/listof-category/target-rating-capacity" ? (
        <SettingTargetScoring />
      ) : urlLink === "/setting/listof-category/score-rating-capacity" ? (
        <SettingScoring />
      ) : urlLink === "/setting/listof-category/security-rate" ? (
        <SettingSecurityLevel />
      ) : urlLink === "/setting/listof-category/difficult-rate" ? (
        <SettingDifficultRate />
      ) : urlLink === "/user/user-list" ? (
        <UserList />
      ) : urlLink === "/user/functional-list" ? (
        <UserFunctionalList />
      ) : urlLink === "/user/screen-list" ? (
        <UserScreenList />
      ) : urlLink === "/user/work-category-list" ? (
        <UserWorkCategoryList />
      ) : urlLink === "/user/data-option" ? (
        <UserDataOptionList />
      ) : urlLink === "/propose/sender/list" ? (
        <ProposeSenderList />
      ) : urlLink === "/propose/receiver/list" ? (
        <ProposeReceiverList />
      ) : urlLink === "/working/task-finish-late" ? (
        <WorkingTaskFinishLate />
      ) : urlLink === "/working/task-finish-early" ? (
        <WorkingTaskFinishEarly />
      ) : urlLink === "/working/task-finish-punctual" ? (
        <WorkingTaskFinishPunctual />
      ) : urlLink === "/working/receiver/task-finish_late" ? (
        <WorkingTaskFinishLateReceiver />
      ) : urlLink === "/working/receiver/task-finish_early" ? (
        <WorkingTaskFinishEarlyReceiver />
      ) : urlLink === "/working/receiver/task-finish_puntual" ? (
        <WorkingTaskFinishPunctualReceiver />
      ) : (
        ""
      )}
    </>
  );
};

export default Table;
