import React, { useState, useEffect, useRef } from "react";
import Title from "../../../../components/title";
import SubmitHeader from "../../../../components/submitHeader";
import ContentTop from "../../../../components/contentTop";

import Chart, { CategoryScale } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie, Bar } from "react-chartjs-2";

import { REPORT_PERSONAL_REDWORK } from "../../../../apis/_index";

import { readReport } from "../../../../funcs/reportCalling";
import { takePhongban } from "../../../../funcs/reportCalling";
import { takeUser } from "../../../../funcs/reportCalling";

Chart.register(CategoryScale);
Chart.register(ChartDataLabels);

const PersonalRedWorkReportUI = (props: any) => {
  const [data, setData] = useState<any[] | null>(null);

  const [arraySoNgay, setArraySoNgay] = useState<any[] | null>(null);
  const [arrayThang, setArrayThang] = useState<any[] | null>(null);

  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [userList, setUserList] = useState<any[] | null>(null);

  const [selectedPhongban, setSelectedPhongban] = useState<any>(-1);
  const [selectedUser, setSelectedUser] = useState<any>(-1);

  const [fromDate, setFromDate] = useState<any | null>(
    new Date().getFullYear()
  );
  const [toDate, setToDate] = useState<any | null>(new Date().getFullYear());

  const shouldLog = useRef(true);

  // first call
  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;

      const body = {
        TuNgay: fromDate + "-01-01",
        DenNgay: toDate + "-12-31",
        _isCurUser: true,
      };

      readReport(REPORT_PERSONAL_REDWORK, body, (res: any) => {
        setData(res.data.Data);
      });

      takePhongban((res: any) => setPhongBanList(res.data.Data));
      takeUser((res: any) => setUserList(res.data.Data));
    }
  });

  useEffect(() => {
    if (data === null) {
    } else {
      let tempArraySoNgay: any[] = [];
      let tempArrayThang: any[] = [];

      data.forEach((item: any) => {
        let tempSoNgay = item.SoNgay;
        let tempThang = "Tháng " + item.Thang;

        tempArraySoNgay.push(tempSoNgay);
        tempArrayThang.push(tempThang);
      });

      setArraySoNgay(tempArraySoNgay);
      setArrayThang(tempArrayThang);
    }
  }, [data]);

  // filter
  const _onClickSubmit = () => {
    let body: any;

    body = {
      TuNgay: fromDate + "-01-01",
      DenNgay: toDate + "-12-31",
      _isCurUser: true,
    };

    readReport(REPORT_PERSONAL_REDWORK, body, (res: any) => {
      setData(res.data.Data);
    });
  };

  return (
    <>
      <br></br>
      {/* <div className="flex lg:flex-row flex-col w-full h-max">
        <div className="flex flex-col w-full lg:h-[4rem] h-max space-y-3  pl-36">
          <div className="flex lg:flex-row flex-col w-full h-max">
            <div className="flex flex-col lg:w-full w-full lg:pr-7 pr-0 h-max">
              <label>Năm</label>
              <input
                className="w-1/3 h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                placeholder="Năm"
                value={fromDate}
                type="number"
                id="year"
                name="year"
                min="1900"
                max="2099"
                onChange={(e: any) => {
                  e.preventDefault();
                  setFromDate(e.target.value);
                  setToDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        
        <div
          className="flex flex-col lg:w-1/2 w-full lg:pr-7 pr-0 h-max"
          style={{ textAlign: "center" }}
        >
          <label>&nbsp; </label>
          <button
            className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
            onClick={_onClickSubmit}
          >
            <p style={{ width: "100%", textAlign: "center" }}>Xem</p>
          </button>
        </div>
      </div> */}
      <div className="flex lg:flex-row flex-col w-full h-max pl-2">
        <div className="flex w-1/2  lg:pr-7 pr-0 h-max space-x-3">
          <input
            aria-label="Năm"
            className="w-1/2 lg:w-1/2 h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
            placeholder="Năm"
            // value={fromDate}
            type="number"
            id="year"
            name="year"
            min="1900"
            max="2099"
            onChange={(e: any) => {
              e.preventDefault();
              setFromDate(e.target.value);
              setToDate(e.target.value);
            }}
          />
          <button
            className="
            flex items-center pl-3 pr-3 pt-1 pb-1 bg-primary rounded-md text-white
            hover:bg-sky-400 active:shadow-inner active:shadow-slate-500 
            "
            onClick={_onClickSubmit}
          >
            <p style={{ width: "100%", textAlign: "center" }}>Xem</p>
          </button>
        </div>
      </div>
      <hr style={{ margin: "20px" }}></hr>
      {data === null || arraySoNgay === null || arrayThang === null ? (
        <></>
      ) : (
        <div className="flex w-full lg:h-4/5 h-[20rem] p-3">
          <div className="flex flex-col w-full h-full shadow-md rounded-sm bg-white">
            <div className="flex w-full h-[2rem] justify-center items-center text-primary font-semibold ">
              BIỂU ĐỒ TỔNG SỐ NGÀY TRỄ TIẾN ĐỘ CÁ NHÂN
            </div>
            <div className="flex justify-center items-center w-full h-[calc(100%-2rem)]">
              <Bar
                className="w-full h-full drop-shadow-lg"
                datasetIdKey="id"
                data={{
                  labels: arrayThang,
                  datasets: [
                    {
                      label: "Tổng ngày trễ tiến độ",
                      data: arraySoNgay,
                      backgroundColor: ["#FF0000"],
                    },
                  ],
                }}
                options={{
                  plugins: {
                    datalabels: {
                      color: "black",
                      font: {
                        size: 13,
                        weight: "bold",
                      },
                      // formatter: (value: any) => `${value}`,
                      formatter: function (value: any, context) {
                        return value !== 0 ? value : "";
                      },
                      anchor: "center",
                      align: "end",
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalRedWorkReportUI;
