import React from "react";

import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';

const Chart3Detail = (props: any) => {

  // take props
  const taskId = props.taskId;
  const handleClose = props.handleClose;

  // sessions
  const name = localStorage.getItem("nameOfChart3");
  const value = localStorage.getItem("valueOfChart3");

  return (
    <div className="
      flex flex-col bg-white
      lg:w-[35rem] w-screen h-[30rem]
      rounded-md
    ">

      {/* header */}
      <div className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      ">
        <p>Bảng đánh giá kết quả</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm">
        <p>Tên nhân sự: <span className="font-bold">{name}</span></p>
        <p>Điểm thành tích: <span className="font-bold">{value}</span></p>

        <p className="mt-5">(Cái bảng công việc ở chỗ này này.)</p>
      </div>

      {/* footer */}
      <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-end items-center pl-4 pr-4 text-sm space-x-4">
        <button className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
          onClick={handleClose}
        >
          <ExitToAppTwoToneIcon fontSize="small" />
          <p>Đóng</p>
        </button>
      </div>
    </div>
  )
}

export default Chart3Detail;
