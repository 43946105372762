import React, { useState, useRef, useEffect, useContext } from "react";
import { HexColorPicker } from "react-colorful";
import { SketchPicker } from 'react-color';

import { CHiTietTieuChiDanhGia } from "../../../apis/_index";
import LoaderSpinner from '../../loader-spinner/_index';

import { useSelector, useDispatch } from "react-redux";
import { NGUOI_NHAN_TASK_COMPLETE, NGUOI_NHAN_VIEC_GIVEBACK } from "../../../apis/_index";

import { ModalController } from "../../../ui/homeUI/_index";

import SubModal from "../_subModal/_index";
import { ONCHANGE_NGUOI_NHAN_MOI } from "../../../redux/reducers/workingReducer/_index";

import axios from "axios";

import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import { NGUOI_NHAN_VIEC_LIST } from "../../../apis/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { toast } from "react-toastify";

const ReTasking = (props: any) => {

  const taskId = props.taskId;
  const taskCode = props.taskCode
  const handleClose = props.handleClose

  const context = useContext(ModalController);

  const [notes, setNotes] = useState<string>("")

  const data = useSelector((state: any) => state.appReducer)
  const workingData = useSelector((state: any) => state.workingReducer)

  const dispatch = useDispatch()

  const urlLink = window.location.pathname
  const shouldLog = useRef(true)
const [loading, setLoading] = useState(false);

  // funcs
  const _onClickSubmit = async () => {
    setLoading(true); // Đặt trạng thái loading thành true
    const accessToken = localStorage.getItem("accessToken")
    const API = NGUOI_NHAN_VIEC_GIVEBACK(taskId, notes)

    try {
      await axios.get(
        API,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain"
          }
        }
      )
      .then((res: any) => {
        if (res.status === 200) {
          readList(
            NGUOI_NHAN_VIEC_LIST,
            workingData.pageCurrent,
            workingData.choosePage,
            workingData.searchFilter.taskName,
            workingData.searchFilter.selectedPrior,
            workingData.searchFilter.selectedReceiver,
            workingData.searchFilter.fromDate,
            workingData.searchFilter.toDate,
            workingData.searchFilter.deadLine,
            shouldLog,
            urlLink,
            (res: any) => { dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data)) }
          )
          toast.success("Trả việc thành công")
          handleClose();
          context.handleClose();
        }
      })
    } catch (error: any) {
      toast.error("!")
    } finally {
      setLoading(false); // Đặt trạng thái loading lại thành false khi hoàn thành xong
    }
  }

  return (
    <div className="
    flex flex-col bg-white
    lg:w-[34rem] w-screen h-[28rem]
    rounded-md
  ">

      {/* header */}
      <div className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      ">
        <p>Xác nhận trả việc</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
        {/* lines */}
        <div className="flex w-full h-max">
          <div className="flex flex-col w-full pr-0 h-max">
            <label>Mã công việc&nbsp;<span className="text-red-500">(*)</span></label>
            <input
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={taskCode}
              disabled
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>

        <div className="flex w-full h-max">
          <div className="flex flex-col w-full h-max">
            <label>Lý do trả việc&nbsp;<span className="text-red-500">(*)</span></label>
            <textarea
              className="w-full h-[10rem] rounded-md p-2 border-2 border-slate-200"
              placeholder="Nhập lý do"
              onChange={(e: any) => {
                e.preventDefault();
                setNotes(e.target.value)
              }}
            />
          </div>
        </div>

        {/* <div className="flex flex-col w-full h-max">
          <label>Nhóm tham chiếu (nếu có)&nbsp;<span className="text-red-500">(*)</span></label>
          <select
            className="w-full h-[1.9rem] rounded-md pl-1 pr-1 border-2 border-slate-200"
            defaultValue={0}
          >
            <option className="text-gray-300" value={0} disabled>--- Chọn nhóm tham chiếu ---</option>
            <option>Nhóm tham chiếu 1</option>
            <option>Nhóm tham chiếu 2</option>
            <option>Nhóm tham chiếu 3</option>
          </select>
        </div>

        <div className="flex items-center w-full space-x-6 h-max">
          <p className="font-bold text-primary">Hiển thị</p>
          <input
            type="checkbox"
          />
        </div> */}
      </div>

      {/* footer */}
      <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-end items-center pl-4 pr-4 text-sm space-x-4">

        <div className="flex w-max h-max space-x-3">
         {loading ?(
        <div><LoaderSpinner w={20} h={20} /></div> // Hiển thị thông báo "loading" khi trạng thái là true
      ) : ( <button className={`
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md
          ${notes === "" ? "bg-slate-300" : "bg-green-500 hover:bg-green-400"}
        `}
          disabled={notes === "" ? true : false}
          onClick={_onClickSubmit}
          >
            <p>Xác nhận</p>
          </button>)}

          <button className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
        onClick={loading ? null : handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ReTasking;

