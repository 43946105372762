import React, { useState, KeyboardEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { bodyLogin } from "../../data/_index";
import { LOGIN_API } from "../../apis/_index";

import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { generateCaptchaText, DEFAULTS } from "../../utils/capcha";
import CachedIcon from '@mui/icons-material/Cached';

import { onTouchForm } from "../../funcs/onTochForm";

// ui importing
import LoginLeftSideUI from "../shared/loginLeftSideUI/_index";

const LoginUI = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [_originalCaptcha, setOriginalCaptcha] = useState<any>();
  const [_displayCaptcha, setDisplayCaptcha] = useState<any>();
  const [captcha, setCaptcha] = useState<any>();

  const _onChangeFields = (funcs: string, e: any) => {
    e.preventDefault();

    if (funcs === "username") {
      setUsername(e.target.value);
    } else if (funcs === "password") {
      setPassword(e.target.value);
    } else if (funcs === "captcha") {
      setCaptcha(e.target.value);
    }
    else {
      return;
    }
  };

  useEffect(() => {
    const { originalCaptcha, displayCaptcha } = generateCaptchaText(DEFAULTS.lengthCaptcha, ' ');
    setOriginalCaptcha(originalCaptcha);
    setDisplayCaptcha(displayCaptcha);
  }, [])

  // login funcs
  const _onClickLogin = async () => {
    setIsLoading(true);

    if (captcha !== _originalCaptcha) {
      const { originalCaptcha, displayCaptcha } = generateCaptchaText(DEFAULTS.lengthCaptcha, ' ');
      setOriginalCaptcha(originalCaptcha);
      setDisplayCaptcha(displayCaptcha);

      toast.error("Mã captcha không đúng!")

      setIsLoading(false);
      return;
    }

    const body = bodyLogin(username, password);

    try {
      await axios
        .post(LOGIN_API, new URLSearchParams(Object.entries(body)).toString())
        .then((res: any) => {
          if (res.status === 200) {
            localStorage.setItem("accessToken", res.data.access_token);

            // navigate("/workingtable/total", { replace: true });
            navigate("/allPage", { replace: true });
          } else {
            toast.error("Hệ thống lỗi.");
          }
        });
    } catch (error: any) {
      toast.error("Thông tin tài khoản không chính xác.");
    }

    setIsLoading(false);
  };

  const navigate = useNavigate();

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      _onClickLogin();
    }
  };

  const onPressCaptcha = () => {
    const { originalCaptcha, displayCaptcha } = generateCaptchaText(DEFAULTS.lengthCaptcha, ' ');
    setOriginalCaptcha(originalCaptcha);
    setDisplayCaptcha(displayCaptcha);
  }

  return (
    <div
      className="flex h-full w-full"
      onKeyDown={(e: any) => handleKeyPress(e)}
    >
      {/* left side */}
      <LoginLeftSideUI />

      {/* right side */}
      <div
        className="
        flex lg:w-1/2 w-full h-full
        justify-center items-center
        relative
      "
      >
        {/* forms */}
        <div
          className="
          lg:w-2/3 w-full h-max
        "
        >
          {/* title */}
          <div className="flex justify-center items-center w-full h-[3rem]">
            {/* title-content */}
            <div
              className="
              flex justify-center items-center
              w-1/2 h-full
              text-2xl text-primary font-semibold border-b-2 border-primary drop-shadow-[0_5px_10px_rgba(0,0,0,0.2)]
            "
            >
              ĐĂNG NHẬP
            </div>
          </div>

          {/* content */}
          <div
            className="
            flex flex-col w-full h-max
            pt-12 pr-4 pl-4 lg:space-y-3 space-y-3 
          "
          >
            {/* username */}
            <div className="flex flex-col w-full h-max drop-shadow-[0_0px_3px_rgba(0,0,0,0.1)]">
              <label>Tên đăng nhập</label>
              <input
                className={`w-full h-[2.3rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                hover:shadow-inner hover:shadow-slate-300 transition-all delay-75 ${isLoading === true ? "bg-slate-100" : ""
                  }`}
                placeholder="Nhập tên đăng nhập"
                onChange={(e: any) => {
                  _onChangeFields("username", e);
                }}
                readOnly={isLoading}
                onTouchStart={(e: any) => onTouchForm(e)}
              />
            </div>

            {/* password */}
            <div className="flex flex-col w-full h-max drop-shadow-[0_0px_3px_rgba(0,0,0,0.1)]">
              <label>Mật khẩu</label>
              <input
                className={`w-full h-[2.3rem] rounded-md pl-2 pr-2 border-2 border-slate-200 
                hover:shadow-inner hover:shadow-slate-300 transition-all delay-75 ${isLoading === true ? "bg-slate-100" : ""
                  }`}
                placeholder="Nhập mật khẩu"
                type="password"
                onChange={(e: any) => {
                  _onChangeFields("password", e);
                }}
                readOnly={isLoading}
                onTouchStart={(e: any) => onTouchForm(e)}
              />
            </div>

            {/* captcha */}
            <div className="flex w-full h-max">
              {/* captcha-input */}
              <div className="w-1/2 h-[3.7rem] drop-shadow-[0_0px_3px_rgba(0,0,0,0.1)]">
                <label>Captcha </label>
                <input
                  className={`${isLoading === true ? "bg-slate-100" : ""
                    } w-5/6 h-[2.3rem] rounded-md pl-2 pr-2 border-2 border-slate-200 
                    hover:shadow-inner hover:shadow-slate-300 transition-all delay-75`}
                  placeholder="Nhập captcha"
                  onChange={(e: any) => {
                    _onChangeFields("captcha", e);
                  }}
                  readOnly={isLoading}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>

              {/* captcha-show */}
              <div
                className="
                flex w-1/2 h-[3.7rem] border-2 border-primary
                text-primary text-2xl justify-center items-center font-semibold rounded-md
              "
              >
                {_displayCaptcha}
              </div>
              <div
                className="
                flex w-1/12 h-[3.7rem]  rounded-sm
                text-primary text-2xl justify-center items-center font-semibold transition-all delay-75 
                hover:text-yellow-500 hover:drop-shadow-[0_0px_10px_rgba(255,200,0,1)] hover:text-xl
                active:text-2xl active:drop-shadow-[0_5px_10px_rgba(0,0,0,1)] active:text-primary
              "
              >
                <button onClick={onPressCaptcha}><CachedIcon /></button>
              </div>
            </div>

            {/* remember password */}
            {/* <div className="flex space-x-2 w-full h-max items-center">
              <input
                type="checkbox"
                name="rememberLogin"
                value="Bike"
                readOnly={isLoading}
              />
              <label>Nhớ thông tin đăng nhập</label>
            </div> */}

            {/* submit */}
            <div className="relative flex flex-col w-full h-[4rem] border-b-2 border-slate-200">
              <div
                className={`absolute left-0 bottom-2 w-max h-max ${isLoading === true ? "flex" : "hidden"
                  }`}
              >
                <Oval
                  height={32}
                  width={32}
                  color="#00B1FF"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#38bdf8"
                  strokeWidth={3}
                  strokeWidthSecondary={3}
                />
              </div>

              {/* forget password */}
            <div className="flex space-x-2 w-full h-max items-center 
            pt-2 pb-2">
              <button
                disabled={isLoading}
                onClick={() => {
                  navigate("/forget-password");
                }}
                className={`${isLoading === true
                  ? "text-sky-300"
                  : "text-primary underline hover:text-red-400"
                  }`}
              >
                Quên mật khẩu? Bấm vào đây...
              </button>
            </div>

              <button
                className={`
                absolute right-0 w-max h-max
                pt-2 pb-2 pl-3 pr-3
                flex justify-center items-center rounded-md
                ${isLoading === true
                    ? "bg-slate-200 text-black"
                    : "text-white bg-button hover:bg-sky-400 transition-all delay-75 hover:shadow-lg active:shadow-inner active:shadow-slate-900 "
                  }
              `}
                disabled={isLoading}
                onClick={_onClickLogin}
              >
                Đăng nhập
              </button>
            </div>

            
          </div>
        </div>

        {/* version */}
        <div
          className="
          flex divide-x-0 lg:justify-end 
          w-full h-[2.5rem] bottom-2 absolute
          text-primary md: text-sm
        "
        >
          <div className="lg:hidden flex ml-2 sm:justify-end">
            Phần mềm ISOPRO 5 - GIAOVIEC4.0 2023 - Thiết kế và phát triển bởi
            LACOTECH - © 2023 LACOGROUP
          </div>

          <div className="mr-1 md:justify-end ml-3 items-center flex">Phiên bản 5.0.0.1</div>
        </div>

        {/* logo */}
        <div
          className="
          lg:hidden flex
          w-full h-[7rem]
          absolute top-0
          flex-col justify-center items-center
          divide-y text-white bg-primary font-semibold
          divide-white
        "
        >
          <p className="text-xl">ISOPRO5</p>
          {/* <p>UBND PHUONG 9 TÂN BÌNH</p> */}
          {/* <p>CÔNG TY LACO</p> */}
        </div>
      </div>
    </div>
  );
};

export default LoginUI;
