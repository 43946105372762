import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { ONCHANGE_NAVBAR_CLOSE_RESPONSIVE } from "../../../redux/reducers/_appReducer/_index";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { it } from "node:test";
import { group, log } from "console";

const OptionList = () => {
  // redux
  const data = useSelector((state: any) => state.appReducer);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const urlLink = window.location.pathname;

  const [_groupName, set_groupName] = useState<string | "">("MenuNone");
  // funcs
  const _onChangeNavbarCloseResponsive = () => {
    dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
  };

  // useEffect(() => {
  //   console.log("ok")
  // })

  // tsx
  const WorkingTableOptions = () => {
    return <></>;
  };

  const WorkingOptions = () => {
    return (
      <>
        <div
          className="
          w-full h-[2.25rem]
          flex pl-3 pr-3 items-center
          text-sm font-semibold
        "
        >
          <span className="w-max h-max flex">
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Người giao việc</p>
          </span>
        </div>

        {/* list of task */}
        <div className="w-full h-max flex flex-col pl-7 pr-3 text-sm space-y-2">
          <button
            className={`w-max h-max flex hover:text-primary
             ${urlLink === "/working/management-workflow" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/management-workflow");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Quản lý quy trình</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
             ${urlLink === "/working/new-task" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/new-task");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Tạo công việc mới</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/task-need-give" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-need-give");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc cần giao</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/task-finish" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-finish");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Việc hoàn tất đang chờ duyệt</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/task-delay" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-delay");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Việc đang bị trễ hạn</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/task-change-deadline" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-change-deadline");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc chờ duyệt</p>
          </button>


          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "working/task-proccessing" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("working/task-proccessing");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Việc đã giao đang xử lý</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/task-end-soon" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-end-soon");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Việc sắp hết hạn</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/task-end-today" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-end-today");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Việc tới hạn hôm nay</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/task-total" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/task-total");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Danh sách tổng việc đã giao</p>
          </button>
        </div>

        <div
          className="
          w-full h-[2.25rem]
          flex pl-3 pr-3 items-center
          text-sm font-semibold
        "
        >
          <button className="w-max h-max flex hover:text-primary">
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Người nhận việc</p>
          </button>
        </div>

        {/* list of task receiving */}
        <div className="w-full h-max flex flex-col pl-7 pr-3 text-sm space-y-2">
          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/receiver/task-new" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/receiver/task-new");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc mới</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/receiver/task-received"
                ? "text-sky-400"
                : ""
              }
          `}
            onClick={() => {
              navigate("/working/receiver/task-received");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc đã nhận</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/receiver/task-processing"
                ? "text-sky-400"
                : ""
              }
          `}
            onClick={() => {
              navigate("/working/receiver/task-processing");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc đang xử lý</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/receiver/task-delay" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/receiver/task-delay");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc trễ hạn</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/receiver/task-end-soon"
                ? "text-sky-400"
                : ""
              }
          `}
            onClick={() => {
              navigate("/working/receiver/task-end-soon");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc sắp trễ hạn</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/receiver/all-task" ? "text-sky-400" : ""}
          `}
            onClick={() => {
              navigate("/working/receiver/all-task");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Danh sách tổng hợp công việc</p>
          </button>

          <button
            className={`w-max h-max flex hover:text-primary
            ${urlLink === "/working/receiver/task-end-today"
                ? "text-sky-400"
                : ""
              }
          `}
            onClick={() => {
              navigate("/working/receiver/task-end-today");
              dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
            }}
          >
            <KeyboardArrowRightIcon fontSize="small" />
            <p>Công việc tới hạn hôm nay</p>
          </button>
        </div>
      </>
    );
  };

  // render menu
  const RenderMenuWeb = () => {
    let groupName: string = "MenuNone";

    const XulyMang = (e: any) => {
      if (e !== groupName) {
        groupName = e;
        return (
          <div
            className="w-full h-max flex p-3 "
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
              color: "#3498db",
            }}
          >
            {groupName}
          </div>
        );
      } else {
        return <></>;
      }
    };

    return (
      <>
        {data.showingMenu === "0" ? (
          <WorkingTableOptions />
        ) : (
          data?.subMenu.map((item: any, index: number) => (
            <>
              {item.ParentFormId.toString() === data.showingMenu &&
                item.PerActive === true ? (
                <>
                  <>{XulyMang(item.FormGroup)}</>

                  <div
                    className="w-full h-max flex flex-col pl-3 pr-3 text-sm p-2"
                    key={index}
                    style={{
                      display:
                        item.FormTitle !== "" &&
                          typeof item.FormTitle !== "undefined" &&
                          item.FormTitle !== null
                          ? "block"
                          : "none",
                    }}
                  >
                    <button
                      key={index}
                      className={`w-max h-max flex hover:text-primary
                      ${urlLink === `/${item.FormLink}` ? "text-primary" : ""}
                        `}
                      onClick={() => {
                        navigate(`/${item.FormLink}`);
                        dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
                      }}
                    >
                      <ArrowRightIcon fontSize="small" />
                      <div
                        style={{
                          width: "234px",
                          textAlign: "left",
                          fontSize: "12pt",
                        }}
                      >
                        <p>
                          {item.FormTitle}&nbsp;
                          {item.FormId === 20 ||
                            item.FormId === 21 ||
                            item.FormId === 22 ||
                            item.FormId === 41 ||
                            item.FormId === 23 ||
                            item.FormId === 26 ||
                            item.FormId === 62 ||
                            item.FormId === 63 ||
                            item.FormId === 76 ||
                            item.FormId === 72 ||
                            item.FormId === 78 ||

                            item.FormId === 71 ||
                            item.FormId === 70 ||
                            item.FormId === 75 ||
                            item.FormId === 67 ||
                            item.FormId === 68 ||
                            item.FormId === 69 ||
                            item.FormId === 64 ||
                            item.FormId === 27 ||
                            item.FormId === 74 ||
                            item.FormId === 28 ||
                            item.FormId === 29 ||
                            item.FormId === 30 ||
                            item.FormId === 33 ? (
                            <span className="w-max h-max text-md font-semibold p-1 justify-center items-center">
                              {item.FormId === 20 ? (
                                `${data.loadNoti?.countListNew === 0 ||
                                  data.loadNoti?.countListNew === undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListNew})`
                                }`
                              ) : item.FormId === 22 ? (
                                `${data.loadNoti?.countListCompleted === 0 ||
                                  data.loadNoti?.countListCompleted ===
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListCompleted})`
                                }`
                              ) : item.FormId === 41 ? (
                                `${data.loadNoti?.countListProccessing === 0 ||
                                  data.loadNoti?.countListProccessing ===
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListProccessing})`
                                }`
                              ) : item.FormId === 21 ? (
                                `${data.loadNoti?.countListNeedDelivery === 0 ||
                                  data.loadNoti?.countListNeedDelivery ===
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListNeedDelivery})`
                                }`
                              ) : item.FormId === 23 ? (
                                `${data.loadNoti?.countListLate === 0 ||
                                  data.loadNoti?.countListLate === undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListLate})`
                                }`
                              ) : item.FormId === 26 ? (
                                `${data.loadNoti?.countListAllHasDelivery_All ===
                                  0 ||
                                  data.loadNoti?.countListAllHasDelivery_All ===
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListAllHasDelivery_All})`
                                }`
                              ) : item.FormId === 76 ? (
                                `${data.loadNoti?.countListAllHasDelivery ===
                                  0 ||
                                  data.loadNoti?.countListAllHasDelivery ===
                                  undefined
                                  ? ""
                                  : `(${data.loadNoti?.countListAllHasDelivery})`
                                }`
                              )
                                : item.FormId === 62 ? (
                                  `${data.loadNoti?.countListLateComplete === 0 ||
                                    data.loadNoti?.countListLateComplete ===
                                    undefined
                                    ? ""
                                    : `(${data.loadNoti?.countListLateComplete})`
                                  }`
                                ) : item.FormId === 72 ? (
                                  `${data.loadNoti?.countListReturnWork === 0 ||
                                    data.loadNoti?.countListReturnWork ===
                                    undefined
                                    ? ""
                                    : `(${data.loadNoti?.countListReturnWork})`
                                  }`
                                ) : item.FormId === 78 ? (
                                  `${data.loadNoti?.countListChangeDeadLine === 0 ||
                                    data.loadNoti?.countListChangeDeadLine ===
                                    undefined
                                    ? ""
                                    : `(${data.loadNoti?.countListChangeDeadLine})`
                                  }`
                                ) : item.FormId === 63 ? (
                                  `${data.loadNoti?.countListEarlyComplete === 0 ||
                                    data.loadNoti?.countListEarlyComplete ===
                                    undefined
                                    ? ""
                                    : `(${data.loadNoti?.countListEarlyComplete})`
                                  }`
                                ) : item.FormId === 64 ? (
                                  `${data.loadNoti?.countListPunctualCompleted ===
                                    0 ||
                                    data.loadNoti?.countListPunctualCompleted ===
                                    undefined
                                    ? ""
                                    : `(${data.loadNoti?.countListPunctualCompleted})`
                                  }`
                                )

                                  : item.FormId === 27 ? (
                                    `${data.loadNoti2?.countListNew === 0 ||
                                      data.loadNoti2?.countListNew === undefined
                                      ? ""
                                      : `(${data.loadNoti2?.countListNew})`
                                    }`
                                  )
                                    : item.FormId === 75 ? (
                                      `${data.loadNoti?.countListAllNeedToJoin === 0 ||
                                        data.loadNoti?.countListAllNeedToJoin === undefined
                                        ? ""
                                        : `(${data.loadNoti?.countListAllNeedToJoin})`
                                      }`
                                    ) : item.FormId === 74 ? (
                                      `${data.loadNoti?.countListAllRequest_Voting === 0 ||
                                        data.loadNoti?.countListAllRequest_Voting === undefined
                                        ? ""
                                        : `(${data.loadNoti?.countListAllRequest_Voting})`
                                      }`
                                    ) : item.FormId === 28 ? (
                                      `${data.loadNoti2?.countListReveced === 0 ||
                                        data.loadNoti2?.countListReveced === undefined
                                        ? ""
                                        : `(${data.loadNoti2?.countListReveced})`
                                      }`
                                    ) : item.FormId === 70 ? (
                                      `${data.loadNoti2?.countListCompleted === 0 ||
                                        data.loadNoti2?.countListCompleted === undefined
                                        ? ""
                                        : `(${data.loadNoti2?.countListCompleted})`
                                      }`
                                    ) : item.FormId === 29 ? (
                                      `${data.loadNoti2?.countListWorking === 0 ||
                                        data.loadNoti2?.countListWorking === undefined
                                        ? ""
                                        : `(${data.loadNoti2?.countListWorking})`
                                      }`
                                    ) : item.FormId === 67 ? (
                                      `${data.loadNoti2?.countListLateComplete === 0 ||
                                        data.loadNoti2?.countListLateComplete === undefined
                                        ? ""
                                        : `(${data.loadNoti2?.countListLateComplete})`
                                      }`
                                    )
                                      : item.FormId === 71 ? (
                                        `${data.loadNoti2?.countReturnWork === 0 ||
                                          data.loadNoti2?.countReturnWork === undefined
                                          ? ""
                                          : `(${data.loadNoti2?.countReturnWork})`
                                        }`
                                      ) : item.FormId === 68 ? (
                                        `${data.loadNoti2?.countListEarlyComplete === 0 ||
                                          data.loadNoti2?.countListEarlyComplete === undefined
                                          ? ""
                                          : `(${data.loadNoti2?.countListEarlyComplete})`
                                        }`
                                      )
                                        : item.FormId === 69 ? (
                                          `${data.loadNoti2?.countListPunctualCompleted === 0 ||
                                            data.loadNoti2?.countListPunctualCompleted === undefined
                                            ? ""
                                            : `(${data.loadNoti2?.countListPunctualCompleted})`
                                          }`
                                        )
                                          : item.FormId === 30 ? (
                                            `${data.loadNoti2?.countListLated === 0 ||
                                              data.loadNoti2?.countListLated === undefined
                                              ? ""
                                              : `(${data.loadNoti2?.countListLated})`
                                            }`
                                          )

                                            : item.FormId === 33 ? (
                                              `${data.loadNoti2?.countListAll ===
                                                0 ||
                                                data.loadNoti2?.countListAll ===
                                                undefined
                                                ? ""
                                                : `(${data.loadNoti2?.countListAll})`
                                              }`
                                            ) : (
                                              <></>
                                            )}

                              {/* {data.loadNoti?.countListNeedDelivery} */}
                              {/* {item.FormId} */}
                            </span>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    </button>
                  </div>
                </>
              ) : null}
            </>
          ))
        )}
      </>
    );
  };

  const RenderMenuMobile = () => {
    let groupName: string = "MenuNone";

    const XulyMang = (e: any) => {
      if (e !== groupName) {
        groupName = e;
        return (
          <div
            className="w-max h-max flex p-3 "
            style={{
              textTransform: "uppercase",
              fontWeight: "bold",
              color: "#3498db",
            }}
          >
            {groupName}
          </div>
        );
      } else {
        return <></>;
      }
    };

    return (
      <>
        {/* close optionList */}
        <div className="flex justify-end w-full h-[2.5rem] items-center">
          <button
            className="w-[2rem] h-[2rem] bg-slate-300 rounded-full shadow-md shadow-gray-500  font-semibold active:bg-slate-200
          active:shadow-inner active:shadow-gray-500 X active:text-slate-400"
            onClick={() => dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE())}
          >
            <CloseRoundedIcon />
          </button>
        </div>

        {data.showingMenu === "0" ? (
          <WorkingTableOptions />
        ) : (
          data.subMenu.map((item: any, index: number) => (
            <>
              {item.ParentFormId.toString() === data.showingMenu &&
                item.PerActive === true ? (
                <>
                  {/* {
                    item.FormGroup !== _groupName ?
                      (
                        <div className="w-full h-max flex p-2" style={{ textTransform: "uppercase", fontWeight: "bold" }}>{item.FormGroup}</div>
                      ) : null
                  } */}
                  <>{XulyMang(item.FormGroup)}</>

                  <div
                    className="w-max h-max flex flex-col pl-3 pr-3 text-sm p-2"
                    key={index}
                    style={{
                      display:
                        item.FormTitle !== "" &&
                          typeof item.FormTitle !== "undefined" &&
                          item.FormTitle !== null
                          ? "block"
                          : "none",
                    }}
                  >
                    <button
                      key={item.FormId}
                      className={`w-max h-max flex hover:text-primary
                      ${urlLink === `/${item.FormLink}` ? "text-sky-400" : ""}
                        `}
                      onClick={() => {
                        navigate(`/${item.FormLink}`);
                        dispatch(ONCHANGE_NAVBAR_CLOSE_RESPONSIVE());
                      }}
                    >
                      <ArrowRightIcon fontSize="small" />
                      <div
                        style={{
                          width: "234px",
                          textAlign: "left",
                          fontSize: "12pt",
                        }}
                      >
                        <p>
                          {item.FormTitle}&nbsp;
                          {item.FormId === 20 ||
                            item.FormId === 21 ||
                            item.FormId === 22 ||
                            item.FormId === 41 ||
                            item.FormId === 23 ||
                            item.FormId === 26 ||
                            item.FormId === 62 ||
                            item.FormId === 63 ||
                            item.FormId === 72 ||
                            item.FormId === 78 ||

                            item.FormId === 71 ||
                            item.FormId === 70 ||
                            item.FormId === 69 ||
                            item.FormId === 64 ||
                            item.FormId === 27 ||
                            item.FormId === 75 ||
                            item.FormId === 74 ||
                            item.FormId === 28 ||
                            item.FormId === 29 ||
                            item.FormId === 30 ||
                            item.FormId === 33 ? (
                            <span className="w-max h-max text-md font-semibold p-1 justify-center items-center">
                              {item.FormId === 20 ? (
                                `${data.loadNoti?.countListNew === 0
                                  ? ""
                                  : `(${data.loadNoti?.countListNew})`
                                }`
                              ) : item.FormId === 22 ? (
                                `${data.loadNoti?.countListCompleted === 0
                                  ? ""
                                  : `(${data.loadNoti?.countListCompleted})`
                                }`
                              ) : item.FormId === 41 ? (
                                `${data.loadNoti?.countListProccessing === 0
                                  ? ""
                                  : `(${data.loadNoti?.countListProccessing})`
                                }`
                              ) : item.FormId === 23 ? (
                                `${data.loadNoti?.countListLate === 0
                                  ? ""
                                  : `(${data.loadNoti?.countListLate})`
                                }`
                              ) : item.FormId === 26 ? (
                                `${data.loadNoti?.countListAllHasDelivery_All === 0
                                  ? ""
                                  : `(${data.loadNoti?.countListAllHasDelivery_All})`
                                }`
                              ) : item.FormId === 76 ? (
                                `${data.loadNoti?.countListAllHasDelivery === 0
                                  ? ""
                                  : `(${data.loadNoti?.countListAllHasDelivery})`
                                }`
                              ) : item.FormId === 21 ? (
                                `${data.loadNoti?.countListNeedDelivery === 0
                                  ? ""
                                  : `(${data.loadNoti?.countListNeedDelivery})`
                                }`
                              ) : item.FormId === 62 ? (
                                `${data.loadNoti?.countListLateComplete === 0
                                  ? ""
                                  : `(${data.loadNoti?.countListLateComplete})`
                                }`
                              ) : item.FormId === 78 ? (
                                `${data.loadNoti?.countListChangeDeadLine === 0
                                  ? ""
                                  : `(${data.loadNoti?.countListChangeDeadLine})`
                                }`
                              )
                                : item.FormId === 72 ? (
                                  `${data.loadNoti?.countListReturnWork === 0
                                    ? ""
                                    : `(${data.loadNoti?.countListReturnWork})`
                                  }`
                                ) : item.FormId === 63 ? (
                                  `${data.loadNoti?.countListEarlyComplete === 0
                                    ? ""
                                    : `(${data.loadNoti?.countListEarlyComplete})`
                                  }`
                                ) : item.FormId === 64 ? (
                                  `${data.loadNoti?.countListPunctualCompleted ===
                                    0
                                    ? ""
                                    : `(${data.loadNoti?.countListPunctualCompleted})`
                                  }`
                                ) : item.FormId === 27 ? (
                                  `${data.loadNoti2?.countListNew === 0
                                    ? ""
                                    : `(${data.loadNoti2?.countListNew})`
                                  }`
                                )
                                  : item.FormId === 70 ? (
                                    `${data.loadNoti2?.countListCompleted === 0
                                      ? ""
                                      : `(${data.loadNoti2?.countListCompleted})`
                                    }`
                                  ) : item.FormId === 74 ? (
                                    `${data.loadNoti?.countListAllRequest_Voting === 0
                                      ? ""
                                      : `(${data.loadNoti?.countListAllRequest_Voting})`
                                    }`
                                  )
                                    : item.FormId === 75 ? (
                                      `${data.loadNoti?.countListAllNeedToJoin === 0
                                        ? ""
                                        : `(${data.loadNoti?.countListAllNeedToJoin})`
                                      }`
                                    ) : item.FormId === 28 ? (
                                      `${data.loadNoti2?.countListReveced === 0
                                        ? ""
                                        : `(${data.loadNoti2?.countListReveced})`
                                      }`
                                    ) : item.FormId === 29 ? (
                                      `${data.loadNoti2?.countListWorking === 0
                                        ? ""
                                        : `(${data.loadNoti2?.countListWorking})`
                                      }`
                                    ) : item.FormId === 30 ? (
                                      `${data.loadNoti2?.countListLateComplete === 0
                                        ? ""
                                        : `(${data.loadNoti2?.countListLateComplete})`
                                      }`
                                    )
                                      : item.FormId === 71 ? (
                                        `${data.loadNoti2?.countReturnWork === 0
                                          ? ""
                                          : `(${data.loadNoti2?.countReturnWork})`
                                        }`
                                      ) : item.FormId === 33 ? (
                                        `${data.loadNoti2?.countListPunctualCompleted ===
                                          0
                                          ? ""
                                          : `(${data.loadNoti2?.countListPunctualCompleted})`
                                        }`
                                      ) : (
                                        <></>
                                      )}

                              {/* {data.loadNoti?.countListNeedDelivery} */}
                              {/* {item.FormId} */}
                            </span>
                          ) : (
                            <></>
                          )}
                        </p>
                      </div>
                    </button>
                  </div>
                </>
              ) : null}
            </>
          ))
        )}
      </>
    );
  };

  // TODO: responsive
  const OptionListWebJSX = () => {
    return (
      <div className="lg:flex hidden w-max h-full text-xs">
        <div
          className={`
          w-[18rem] h-full bg-navbar shadow-sm pl-3 pr-3 text-slate-500 border-r-2 overflow-auto styled-scrollbars
          ${data.navBarButton === "true" ? "flex flex-col" : "hidden"}
        `}
        >
          <RenderMenuWeb />
        </div>
      </div>
    );
  };

  const OptionListMobileJSX = () => {
    return (
      <div
        className={`lg:hidden z-[40] ${data.navBarButtonResponsive === true ? "flex" : "hidden"
          } w-max lg:h-full h-[calc(100vh-5.14rem)] absolute left-[3rem] overflow-auto`}
      >
        <div
          className={`
          w-[18rem] h-full bg-navbar shadow-sm pl-3 pr-3 text-slate-500 border-r-2 relative overflow-auto styled-scrollbars
        `}
        >
          <RenderMenuMobile />
        </div>
      </div>
    );
  };

  return (
    <>
      <OptionListMobileJSX />
      <OptionListWebJSX />
    </>
  );
};

export default memo(OptionList);
