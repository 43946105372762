import React, { useContext, useEffect, useRef, useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

import PrintIcon from "@mui/icons-material/Print";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ReplayIcon from "@mui/icons-material/Replay";

import { VotingListNeedToJoinWithParams, VotingListRequestWithParams } from "../funcs/_votingFuncs";

import { useSelector, useDispatch } from "react-redux";

import {
  ONLOAD_PROPOSE_GUI_LIST,
  ONCHANGE_PROPOSE_NHAN_LIST,
} from "../redux/reducers/proposeReducer/_index";
import {
  NGUOI_NHAN_VIEC_LIST_ALL,
  NGUOI_NHAN_VIEC_LIST_END_SOON,
  NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
  NGUOI_NHAN_VIEC_LIST_DELAY,
  NGUOI_NHAN_VIEC_LIST_PROCESSING,
  NGUOI_NHAN_VIEC_LIST_RECEIVED,
  NGUOI_GIAO_VIEC_LIST_TOTAL,
  NGUOI_GIAO_VIEC_LIST_ALL_HAS_DELIVERY,
  NGUOI_GIAO_VIEC_LIST,
  NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
  NGUOI_GIAO_VIEC_LIST_FINISH,
  NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
  NGUOI_GIAO_VIEC_LIST_DELAY,
  NGUOI_GIAO_VIEC_LIST_END_TODAY,
  NGUOI_GIAO_VIEC_LIST_END_SOON,
  NGUOI_NHAN_VIEC_LIST,
  NGUOI_NHAN_VIEC_LIST_EARLY_COMPLETE,
  NGUOI_NHAN_VIEC_LIST_PUNCTUAL_COMPLETE,
  NGUOI_NHAN_VIEC_LIST_LATE_COMPLETE,
  PROPOSEGETLIST,
  PROPOSESENDLIST,
  NGUOI_GIAO_LOAD_TASK_LATE,
  NGUOI_GIAO_TASK_RETURN,
  NGUOI_NHAN_TASK_RETURN,
  NGUOI_NHAN_CHO_DUYET_TASK,
  NGUOI_GIAO_LOAD_TASK_PUNCTUAL,
} from "../apis/_index";

import {
  ONCHANGE_TASK_COMPLETED_LATE,
  ONCHANGE_TASK_COMPLETED_EARLY,
  ONCHANGE_TASK_COMPLETED_PUNCTUAL,
  ONCHANGE_NGUOI_NHAN_TAT_CA,
  ONCHANGE_NGUOI_GIAO_TONG_HOP,
  ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN,
  ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY,
  ONCHANGE_NGUOI_NHAN_TRE_HAN,
  ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN,
  ONCHANGE_NGUOI_NHAN_DA_NHAN,
  ONCHANGE_NGUOI_NHAN_XONG_SOM,
  ONCHANGE_TASKLIST_WITH_NORMAL,
  ONCHANGE_NGUOI_NHAN_XONG,
  ONCHANGE_NGUOI_NHAN_XONG_TRE,
  ONCHANGE_NGUOI_NHAN_MOI,
  ONCHANGE_VOTING_LIST,
  ONCHANGE_SAP_TOI_HAN,
  ONCHANGE_TOI_HAN_HOM_NAY,
  ONCHANGE_DANG_TRE_HAN,
  ONCHANGE_NGUOI_GIAO_HOAN_TAT,
  ONCHANGE_NGUOI_GIAO_RETURN,
  ONLOAD_TASKLIST_ALL,
  ONCHANGE_VIEC_CAN_GIAO,
  ONCHANGE_NGUOI_GIAO_DANG_XU_LY,
  ONCHANGE_RESET_FILTER,
  ONCHANGE_TASK_WAITING,
  ONCHANGE_NGUOI_NHAN_RETURN,
  ONSET_RETURN_PAGE_CURRENT,
} from "../redux/reducers/workingReducer/_index";
// import {
//   ONCHANGE_NGUOI_GUI_DE_XUAT,
//   ONCHANGE_NGUOI_GUI_DE_XUAT,
// } from "../redux/reducers/proposeReducer/_index";
import { readList } from "../funcs/taskNguoiGiaoViec";

// context importing
import { ModalController } from "../ui/homeUI/_index";
import { propose } from "../funcs/propose";
import VotingWork from "./modal/working/VotingWork";

const SubmitHeader = () => {
  const urlLink = window.location.pathname;
  const context = useContext(ModalController);

  const workingData = useSelector((state: any) => state.workingReducer);
  const proposeData = useSelector((state: any) => state.proposeReducer);
  const shouldLog = useRef(true);
  const dispatch = useDispatch();

  // reset filter
  useEffect(() => {
    const resetDispatch = async () => {
      await Promise.all([
        dispatch(ONCHANGE_RESET_FILTER()),
        dispatch(ONSET_RETURN_PAGE_CURRENT()),
      ]);
    };

    resetDispatch();
  }, [urlLink, dispatch]);

  // funcs for methods
  const _onClickFindVoting = () => { };

  const _onClickFindWaiting = () => {
    readList(
      NGUOI_NHAN_CHO_DUYET_TASK,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_TASK_WAITING(res.data.Data));
      }
    );
  };

  const _onClickFindTask = () => {
    const link = window.location.pathname;

    readList(
      NGUOI_GIAO_VIEC_LIST,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
      }
    );
  };

  const _onClickFindTaskReturnOfReceiver = () => {
    readList(
      NGUOI_NHAN_TASK_RETURN,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_RETURN(res.data.Data));
      }
    );
  };

  const _onClickFindTaskReturn = () => {
    readList(
      NGUOI_GIAO_TASK_RETURN,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_GIAO_RETURN(res.data.Data));
      }
    );
  };

  const _onClickFindTaskGive = () => {
    const link = window.location.pathname;

    readList(
      NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      link,
      (res: any) => {
        dispatch(ONCHANGE_VIEC_CAN_GIAO(res.data.Data));
      }
    );
  };

  const _onClickFindTaskFinish = () => {
    readList(
      NGUOI_GIAO_VIEC_LIST_FINISH,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data));
      }
    );
  };

  const _onClickFindTaskProccessing = () => {
    // console.log(workingData.searchFilter.selectedReceiver)

    // console.log({
    //   "pageCurrent": workingData.pageCurrent,
    //   "choosePage": workingData.choosePage,
    //   "taskName": workingData.searchFilter.taskName,
    //   "selectedPrior": workingData.searchFilter.selectedPrior,
    //   "selectedReceiver": workingData.searchFilter.selectedReceiver,
    //   "fromDate": workingData.searchFilter.fromDate,
    //   "toDate": workingData.searchFilter.toDate,
    //   "deadLine": workingData.searchFilter.deadLine,
    // })

    readList(
      NGUOI_GIAO_VIEC_LIST_PROOCCESSING,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_GIAO_DANG_XU_LY(res.data.Data));
      },
      workingData.searchFilter.status,
      workingData.searchFilter.selectedBoss,
      workingData.searchFilter.result,
      workingData.searchFilter.selectPhongBan
    );
  };

  const _onClickFindTaskDelay = () => {
    readList(
      NGUOI_GIAO_VIEC_LIST_DELAY,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_DANG_TRE_HAN(res.data.Data));
      }
    );
  };

  const _onClickFindTaskEndToday = () => {
    readList(
      NGUOI_GIAO_VIEC_LIST_END_TODAY,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_TOI_HAN_HOM_NAY(res.data.Data));
      }
    );
  };

  const _onClickFindTaskEndSoon = () => {
    readList(
      NGUOI_GIAO_VIEC_LIST_END_SOON,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_SAP_TOI_HAN(res.data.Data));
      }
    );
  };

  const _onClickFindTaskAll = () => {
    readList(
      NGUOI_GIAO_VIEC_LIST_ALL_HAS_DELIVERY,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_TASKLIST_WITH_NORMAL(res.data.Data));
      },
      workingData.searchFilter.status,
      workingData.searchFilter.selectedBoss,
      workingData.searchFilter.result,
      workingData.searchFilter.selectPhongBan
    );
  };

  const _onClickFindTaskAll2 = () => {

    readList(
      NGUOI_GIAO_VIEC_LIST_TOTAL,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_GIAO_TONG_HOP(res.data.Data));
      },
      workingData.searchFilter.status,
      "",
      workingData.searchFilter.result
    );
  };

  // receiver
  const _onClickReFindTaskNew = () => {
    readList(
      NGUOI_NHAN_VIEC_LIST,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data));
      }
    );
  };

  const _onClickReFindTaskRE = () => {
    readList(
      NGUOI_NHAN_VIEC_LIST_RECEIVED,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_DA_NHAN(res.data.Data));
      }
    );
  };

  const _onClickVotingWaiting = () => {
    VotingListRequestWithParams(workingData.pageCurrent, 10, function (res: any) {
      dispatch(ONCHANGE_VOTING_LIST(res.data.Data));
    }, workingData.searchFilter.taskName, workingData.searchFilter.selectedReceiver);
  }

  const _onClickReFindTaskProccessing = () => {
    readList(
      NGUOI_NHAN_VIEC_LIST_PROCESSING,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_DANG_THUC_HIEN(res.data.Data));
      }
    );
  };

  const _onClickFindTaskFinishEarly = () => {
    readList(
      NGUOI_NHAN_VIEC_LIST_EARLY_COMPLETE,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_XONG_SOM(res.data.Data));
      }
    );
  };

  const _onClickFindTaskFinishPunctual = () => {
    readList(
      NGUOI_NHAN_VIEC_LIST_PUNCTUAL_COMPLETE,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_XONG(res.data.Data));
      }
    );
  };
  const _onClickFindTaskFinishLate = () => {
    readList(
      NGUOI_NHAN_VIEC_LIST_LATE_COMPLETE,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_XONG_TRE(res.data.Data));
      }
    );
  };

  // _onClickFindTaskFinishLate
  const _onClickReFindTaskDelay = () => {
    readList(
      NGUOI_NHAN_VIEC_LIST_DELAY,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_TRE_HAN(res.data.Data));
      }
    );
  };

  const _onClickReFindTaskEndToday = () => {
    readList(
      NGUOI_NHAN_VIEC_LIST_END_TO_DAY,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_TOI_HAN_HOM_NAY(res.data.Data));
      }
    );
  };

  const _onClickReFindTaskEndSoon = () => {
    readList(
      NGUOI_NHAN_VIEC_LIST_END_SOON,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_SAP_TOI_HAN(res.data.Data));
      }
    );
  };

  const _onClickReFindTaskAll = () => {
    readList(
      NGUOI_NHAN_VIEC_LIST_ALL,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_NGUOI_NHAN_TAT_CA(res.data.Data));
      },
      workingData.searchFilter.status
    );
  };

  const _onClickProposeSend = () => {
    propose(
      PROPOSESENDLIST,
      0,
      0,
      null,
      0,
      null,
      null,
      "",
      "",
      shouldLog,
      urlLink,
      (res: any) => {
        // dispatch(ONLOAD_PROPOSE_GUI_LIST(res.data.Data));
      }
    );
  };

  const _onClickProposeGet = () => {
    propose(
      PROPOSEGETLIST,
      0,
      0,
      null,
      0,
      null,
      null,
      "",
      "",
      shouldLog,
      urlLink,
      (res: any) => {
        // dispatch(ONCHANGE_PROPOSE_NHAN_LIST(res.data.Data));
      }
    );
  };

  const _onClickTaskFinishLate = () => {
    readList(
      NGUOI_GIAO_LOAD_TASK_LATE,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_TASK_COMPLETED_LATE(res.data.Data));
      }
    );
  };

  const _onClickVotingWork = () => {
    VotingListNeedToJoinWithParams(workingData.pageCurrent, 10, function (res: any) {
      dispatch(ONCHANGE_VOTING_LIST(res.data.Data));
    }, workingData.searchFilter.taskName, workingData.searchFilter.selectedReceiver);
  }

  const _onClickFindReturnWork = () => { };

  const _onClickTaskFinishEarly = () => {
    readList(
      NGUOI_GIAO_LOAD_TASK_LATE,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_TASK_COMPLETED_EARLY(res.data.Data));
      }
    );
  };

  const _onClickTaskFinishPunctual = () => {
    readList(
      NGUOI_GIAO_LOAD_TASK_PUNCTUAL,
      workingData.pageCurrent,
      workingData.choosePage,
      workingData.searchFilter.taskName,
      workingData.searchFilter.selectedPrior,
      workingData.searchFilter.selectedReceiver,
      workingData.searchFilter.fromDate,
      workingData.searchFilter.toDate,
      workingData.searchFilter.deadLine,
      shouldLog,
      urlLink,
      (res: any) => {
        dispatch(ONCHANGE_TASK_COMPLETED_PUNCTUAL(res.data.Data));
      }
    );
  };
  // - end funcs

  // funcs open modal
  const _onClickCreateNewTask = () => {
    context.setFuncs("createNewTask");
    context.handleOpen();
  };

  // JSX
  const WorkingNewTaskJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTask}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>

        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>

        <button
          className="
              flex pl-2 pr-2 pt-1 pb-1 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
            "
          onClick={_onClickCreateNewTask}
        >
          <AddIcon fontSize="small" />
          <p className="hidden lg:flex">Thêm mới</p>
        </button>
      </div>
    );
  };

  const WorkingNewTaskReturnReceiverJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTaskReturnOfReceiver}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>

        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskNeedGiveJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTaskGive}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const VotingJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickVotingWork}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskFinishLateJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickTaskFinishLate}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskFinishEarlyJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickTaskFinishEarly}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskFinishPunctualJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickTaskFinishPunctual}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskWaitingJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindWaiting}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskFinishJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTaskFinish}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskDelayJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTaskDelay}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskReturnJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTaskReturn}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskProccessingJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTaskProccessing}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskEndSoonJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTaskEndSoon}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskEndTodayJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTaskEndToday}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskTotalJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTaskAll2}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingAllTaskWithDelieryJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTaskAll}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  }

  // receiver
  const WorkingTaskNewReceiverJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickReFindTaskNew}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskEndSoonReceiverJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickReFindTaskEndSoon}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskChangeDeadlineJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickReFindTaskEndSoon}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskEndTodayReceiverJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickReFindTaskEndToday}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex"></p>
        </button>
      </div>
    );
  };

  const VotingWatingJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
        flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
          hover:bg-sky-400
        "
          onClick={_onClickVotingWaiting}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
        flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
          hover:bg-amber-400
        "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    )
  }

  const WorkingTaskProcessingReceiverJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickReFindTaskProccessing}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };
  const WorkingTaskFinishLateReceiver = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTaskFinishLate}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskFinishEarlyReceiver = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTaskFinishEarly}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskFinishPunctualReceiver = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickFindTaskFinishPunctual}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskReceivedReceiverJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickReFindTaskRE}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingTaskDelayReceiverJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickReFindTaskDelay}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const WorkingAllTaskReceiverJSX = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickReFindTaskAll}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  //propose
  const ProposeSend = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickProposeSend}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  const ProposeGet = () => {
    return (
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickProposeGet}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex">Tải lại</p>
        </button>
      </div>
    );
  };

  // report
  const ReportTaskGeneralJSX = () => {
    return (
      <div className="flex justify-end space-x-4 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-0.5 pb-0.5 bg-amber-500 rounded-md text-white
              hover:bg-amber-400 space-x-1
            "
        >
          <PrintIcon fontSize="small" />
          <p>In ấn</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-0.5 pb-0.5 bg-emerald-500 rounded-md text-white
              hover:bg-emerald-400 space-x-1
            "
        >
          <InsertDriveFileIcon fontSize="small" />
          <p>Xuất Excel</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-0.5 pb-0.5 bg-orange-500 rounded-md text-white
              hover:bg-orange-400 space-x-1
            "
        >
          <PictureAsPdfIcon fontSize="small" />
          <p>Xuất PDF</p>
        </button>
        <button
          className="
            flex items-center pl-2 pr-2 pt-0.5 pb-0.5 bg-primary rounded-md text-white
              hover:bg-sky-400 space-x-1
            "
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex">Tìm kiếm</p>
        </button>
      </div>
    );
  };

  // VotingJSX

  return (
    <>
      {urlLink === "/working/new-task" ? (
        <WorkingNewTaskJSX />
      ) : urlLink === "/working/receiver/task-returnwork" ? (
        <WorkingNewTaskReturnReceiverJSX />
      ) : urlLink === "/working/receiver/task-waiting" ? (
        <WorkingTaskWaitingJSX />
      ) : urlLink === "/voting/voting_work" ? (
        <VotingJSX />
      )

        : urlLink === "/voting/voting_wait" ? (
          <VotingWatingJSX />
        )

          : urlLink === "/working/task-return" ? (
            <WorkingTaskReturnJSX />
          ) : urlLink === "/working/task-finish-late" ? (
            <WorkingTaskFinishLateJSX />
          ) : urlLink === "/working/task-finish-early" ? (
            <WorkingTaskFinishEarlyJSX />
          ) : urlLink === "/working/task-finish-punctual" ? (
            <WorkingTaskFinishPunctualJSX />
          ) : urlLink === "/working/task-need-give" ? (
            <WorkingTaskNeedGiveJSX />
          ) : urlLink === "/working/task-finish" ? (
            <WorkingTaskFinishJSX />
          ) : urlLink === "/working/task-delay" ? (
            <WorkingTaskDelayJSX />
          ) : urlLink === "/working/task-change-deadline" ? (
            <WorkingTaskChangeDeadlineJSX />
          ) : urlLink === "/working/task-proccessing" ? (
            <WorkingTaskProccessingJSX />
          ) : urlLink === "/working/task-end-soon" ? (
            <WorkingTaskEndSoonJSX />
          ) : urlLink === "/working/task-end-today" ? (
            <WorkingTaskEndTodayJSX />
          ) : urlLink === "/working/task-total" ? (
            <WorkingTaskTotalJSX />
          ) : urlLink === "/working/receiver/task-new" ? (
            <WorkingTaskNewReceiverJSX />
          ) : urlLink === "/working/receiver/task-delay" ? (
            <WorkingTaskDelayReceiverJSX />
          ) : urlLink === "/working/receiver/task-end-soon" ? (
            <WorkingTaskEndSoonReceiverJSX />
          ) : urlLink === "/working/receiver/task-end-today" ? (
            <WorkingTaskEndTodayReceiverJSX />
          ) : urlLink === "/working/receiver/task-processing" ? (
            <WorkingTaskProcessingReceiverJSX />
          ) : urlLink === "/working/receiver/task-received" ? (
            <WorkingTaskReceivedReceiverJSX />
          ) : urlLink === "/report/deliver/task-general-deliver" ? (
            <ReportTaskGeneralJSX />
          ) : urlLink === "/report/deliver/worker-capacity" ? (
            <ReportTaskGeneralJSX />
          ) : urlLink === "/report/deliver/detail-worker-capacity" ? (
            <ReportTaskGeneralJSX />
          ) : urlLink === "/report/receiver/individual-capacity" ? (
            <ReportTaskGeneralJSX />
          ) : urlLink === "/report/receiver/table-report-capacity" ? (
            <ReportTaskGeneralJSX />
          ) : urlLink === "/working/receiver/all-task" ? (
            <WorkingAllTaskReceiverJSX />
          ) : urlLink === "/propose/sender/list" ? (
            <ProposeSend />
          )
            : urlLink === "/working/task-totalall" ? (
              <WorkingAllTaskWithDelieryJSX />
            )

              : urlLink === "/propose/receiver/list" ? (
                <ProposeGet />
              ) : urlLink === "/working/receiver/task-finish_early" ? (
                <WorkingTaskFinishEarlyReceiver />
              ) : urlLink === "/working/receiver/task-finish_puntual" ? (
                <WorkingTaskFinishPunctualReceiver />
              ) : urlLink === "/working/receiver/task-finish_late" ? (
                <WorkingTaskFinishLateReceiver />
              ) : (
                ""
              )}
    </>
  );
};

export default SubmitHeader;
