const reportView = `
<!DOCTYPE html>
<html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <!-- <link rel="stylesheet" href="reset.css">
        <link rel="stylesheet" href="style.css"> -->
        <!-- <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"> -->
        <script src="https://cdn.tailwindcss.com"></script>
        <title>Document</title>
    </head>
    <body style="font-size: 13px;">
        <div class="border-solid mx-[10%] my-[5%]">
            <form action="#" method="GET">
                <div class="mx-[5%] my-[3%]">
                    <header class="h-[9rem] flex border-b-[1rem] border-b-orange-400 space-x-3"
                    style="margin-left: -5.5%; margin-right: -5.5%;">
                        <article>
                            <img id="lacoImage" style="width: 8rem; height: 6rem; margin-left: 20%; padding-right: 10%; border-right: 1px solid gray;" src="../Asset1.png" alt="">
                        </article>
                        <article class="my-0">
                            <div class="ml-5">
                            <h2 class="text-blue-400 font-bold" style="font-size: 26px;">
                                LACO TRADE SERVICE CORPORATION
                            </h2>
                            <p class="text-xs">Địa chỉ: Biệt thự Chủ động Anna, Khu nhà ở Minh Ân, X. Xuân Thới Đông, H. Hóc Môn, TP.HCM <br>
                                MST: 0307287200 <br>
                                Website: <a href="www.lacocorp.vn">www.lacocorp.vn</a>
                            </p>
                            </div>
                        </article>   
                    
                    </header>
                    <div>                  
                        <div class="flex justify-center " >
                            <h1 id="nameOfReport" class="text-orange-400 font-bold text-3xl my-[3%] ">BÁO CÁO CÔNG VIỆC</h1>
                            <!-- <h1 style="font-weight: bold;">CÔNG VIỆC VÀ KẾ HOẠCH HÀNH ĐỘNG</h1> -->
                        </div>
                        <div style="font-size: 13px" >
                            <div class="">
                                <div classname="" style="font-weight: bold;" >
                                    <div class="flex justify-center items-stretch space-x-3" >
                                        <div classname="flex space-x-2">
                                            <p id="fromTextDate">Từ ngày:</p>  
                                            <input id="fromDate" style="border: 0cap; margin-right: 20px;" type="date" name="bday" max="1979-12-31">
                                        </div>
                                        <div classname="flex space-x-2">    
                                            <p id="toTextDate">Đến ngày:</p>
                                            <input id="toDate" style="border: 0cap;" type="date" name="bday" min="2000-01-02">      
                                        </div>
                                    </div>
                                    <div classname="flex space-x-3 justify-center mt-3">
                                        <div>
                                            <select id="optionsMonth" disabled class="thang" style="font-weight: bold;">
                                                <option selected>Tháng</option>
                                                <option value="1">Tháng 1</option>
                                                <option value="2">Tháng 2</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div>
                                            <select id="optionsQuarter" disabled class="quy" style="font-weight: bold;">
                                                <option selected>Quý</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                        <div>
                                            <select id="optionsYear" disabled class="nam" style="font-weight: bold;">
                                                <option selected>Năm</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex justify-end items-stretch ">

                        </div>
                        <br>

                        <br>
                        <div classname="space-y-3" style="font-size: 13px;font-weight: bold;">
                            <div classname="flex" style="width: 100%;">
                                <p classname="w-2/3">Theo yêu cầu của: <span id="taskManager"></span></p>
                                <!-- <select style="background-color: white; border: 0cap;" aria-label="Ông/Bà">
                                    <option selected>Ông</option>
                                    <option value="1">Bà</option>
                                    </select> -->
                                <!-- <input style="height: 1.3rem; width: 7rem; border: 0cap"  type="text" value="" id="input"> -->
                                <p classname="w-1/3 text-right">Chức vụ: <span id="taskManagerLevel"></span></p>
                                <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Chức vụ">
                                    <option selected>Tổng Giám Đốc</option>
                                    <option value="1">One</option>  
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    </select> -->
                            </div>
                            <div classname="flex" style="width: 100%;">
                                <p classname="w-2/3" >Kính gửi: <span id="taskOwner"></span></p>
                                <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Ông/Bà">
                                    <option selected>Ông</option>
                                    <option value="1">Bà</option>
                                    </select>
                                    <input style="height: 1.3rem; width: 10rem;border: 0cap;" type="text" value="" id="input"> -->
                                <p classname="w-1/3 text-right">Chức vụ: Giám Đốc</p>
                                <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Chức vụ">
                                    <option selected>Giám đốc</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    </select> -->
                            </div>
                            <div classname="flex" style="width: 100%;">
                                <p id="giverName" classname="w-2/4">Tôi tên: Lý Trung Thành Lợi</p>
                                <!-- <input style="height: 1.3rem; width: 13.9rem; border: 0cap;"  type="text" value="" id="input">   
                                    <i class="recipient_subttl">Chức vụ : </i>
                                    <select style="background-color: white; border: 0cap;" class=" " aria-label="Chức vụ">
                                        <option selected>Nhân viên</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                      </select> -->
                                <p classname="w-1/4">Chức vụ: Nhân viên</p>
                                <p classname="w-1/4">Phòng Ban: Phần mềm</p>
                                <!-- <select style="background-color: white; border: 0cap;" class=" " aria-label="Phòng Ban">
                                    <option selected>Phần mềm</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                    </select> -->
                            </div>
                        </div>
                        <br>
                    </div>
                    <div class=" section_1">
                        <h6 style="font-weight: bold;">I. CÁC CÔNG VIỆC ĐANG CHỊU TRÁCH NHIỆM</h6>

                        <div style="margin: 10px auto;">
                            <div id="fromTextDate" class="" style="font-size: 15px;margin-left: 15px;">
                                Từ ngày
                                <input id="fromDateOnTable" style="border: 0cap;" type="date" name="bday" max="2030-12-31">
                                <p id="toTextDate">Đến ngày</p>
                                <input id="toDateOnTable" style="border: 0cap;" type="date" name="bday" min="2030-01-02">
                            </div>
                            <p style="font-size: 15px;margin-left: 15px;">Tôi hiện đang chịu trách nhiệm thực hiện những công việc như sau: </p>
                        </div>
                        
                        <table style="font-size: 12px; width: 100%;  ">
                            <div style="background-color: #0051ff;">
                                <tr style="font-weight: bold; height: 2rem; font-size: 15px;">
                                    <th style="color:white; border: 2px solid black;" scope="col">STT</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Nội dung công việc</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Người giao</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Ngày nhận</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Thời hạn</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Tình trạng</th>
                                </tr>
                            </div>
                            <tbody id="bodyOfTaskResponsible">
                                <tr >
                                    <th style="border: 2px solid black; height: 2rem;" scope="row">1</th>
                                    <td style="border: 2px solid black;">
                                        
                                    </td>
                                    <td style="border: 2px solid black;">
                                        
                                    </td>
                                    <td style="border: 2px solid black;">
                                        
                                    </td>
                                    <td style="border: 2px solid black;">
                                        
                                    </td>
                                    <td style="border: 2px solid black;">
                                        
                                    </td>
                                    
                                </tr>
                                <tr>
                                    <th style="border: 2px solid black;" scope="row">2</th>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;"></td>
                                </tr>
                                <tr>
                                    <th style="border: 2px solid black;" scope="row">3</th>
                                    <td style="border: 2px solid black;">
                                        <input style="border: 0cap; width: 100%; height: 100%;" type="text" value="">
                                    </td>
                                    <td style="border: 2px solid black;">
                                        <input style="border: 0cap; width: 100%; height: 100%;" type="text" value="">
                                    </td>
                                    <td style="border: 2px solid black;">
                                        <input style="border: 0cap; width: 100%; height: 100%;" type="text" value="">
                                    </td>
                                    <td style="border: 2px solid black;">
                                        <input style="border: 0cap; width: 100%; height: 100%;" type="text" value="">
                                    </td>
                                    <td style="border: 2px solid black;">
                                        <input style="border: 0cap; width: 100%; height: 100%;" type="text" value="">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class=" section_2">
                        <h6 style="font-weight: bold;margin: 15px auto;">II.	CÁC CÔNG VIỆC ĐÃ THỰC HIỆN HOÀN THÀNH</h6>
                        <!-- <table class="table table-bordered table-hover border-dark"> -->
                        <table style="font-size: 12px; width: 100%;  text-align: center; align-items: center;">
                            <div style="background-color: blue;">
                                <tr style="font-weight: bold; height: 2rem; font-size: 15px;">
                                    <th style="color:white; border: 2px solid black;" scope="col">STT</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Nội dung công việc</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Người giao</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Ngày nhận</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Thời hạn</th>
                                    <th style="color:white; border: 2px solid black;" scope="col">Kết luận kết quả được đánh giá</th>
                                </tr>
                            </div>
                            <tbody id="bodyOfTaskComplete">
                                <tr>
                                    <th style="border: 2px solid black;" scope="row">1</th>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;">
                                        <textarea class="w-full" id="" cols="" rows="5"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <th style="border: 2px solid black;" scope="row">2</th>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;">
                                        <textarea class="w-full" id="" cols="" rows="5"></textarea>
                                    </td>
                                </tr>
                                <tr>
                                    <th style="border: 2px solid black;" scope="row">3</th>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;"></td>
                                    <td style="border: 2px solid black;">
                                        <textarea class="w-full" id="" cols="" rows="5"></textarea>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="section_3">
                        <h6 style="font-weight: bold;margin:15px auto">III. GIẢI PHÁP THỰC HIỆN CÁC CÔNG VIỆC CÒN TỒN ĐỌNG</h6>
                        <div class="section_3--content" style="font-size: 12px;">
                            <p style="font-weight: bold; font-size: 15px; margin-bottom: 10px;">CÁC CÔNG VIỆC CÒN TỒN ĐỌNG</p>

                            <div id="bodyOfTaskProcessing">
                                <div class="form-control form-control-sm flex flex-col w-full h-max">
                                    <label for="exampleFormControlTextarea1" class="form-label" >Khó Khăn</label>
                                    <textarea class="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1" style="" rows="3"></textarea>
                                </div>
                                <br>
                                <div class="form-control form-control-sm flex flex-col w-full h-max">
                                    <label for="exampleFormControlTextarea1" class="form-label" >Giải pháp</label>
                                    <textarea class="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1" style="" rows="3"></textarea>
                                </div>
                                <br>
                                <div class="form-control form-control-sm flex flex-col w-full h-max">
                                    <label for="exampleFormControlTextarea1" class="form-label" >Kế hoạch hành động tháng kế tiếp</label>
                                    <textarea class="form-control border-2 border-slate-200 p-2 h-40" id="exampleFormControlTextarea1" style="" rows="3"></textarea>
                                </div>
                                <br>
                            </div>

                        </div>
                    </div>
                    <div class="review" style="font-size: 12px;">
                    </div>
                    <!-- <div class="btn_submit" style="width: 100%; text-align: center;">
                        <button type="submit" class="btn btn-primary">
                            Gui
                        </button>
                        </div> -->
                    
                </div>
            </form>
            <footer>
                <img style=" width:100%;" src="Asset1.svg" alt="">
            </footer>
        </div>

          
    </body>
</html>
`

export default reportView;