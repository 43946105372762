import { configureStore } from "@reduxjs/toolkit";

// reducers importing
import appReducer from "../reducers/_appReducer/_index";
import workingReducer from "../reducers/workingReducer/_index";
import userReducer from "../reducers/userReducer/_index";
import settingReducer from "../reducers/settingReducer/_index";
import reportReducer from "../reducers/reportReducer/_index";

const store = configureStore({
  reducer: {
    appReducer: appReducer,
    workingReducer: workingReducer,
    userReducer: userReducer,
    settingReducer: settingReducer,
    reportReducer: reportReducer,
  },
});

export default store;
