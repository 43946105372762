//DEV
//const DOMAIN_API = "https://task-ids.isopro.vn";
//export const DOMAIN_USED = "https://task-api.isopro.vn";
//export const DOMAIN_USED = "https://localhost:5000";
//export const DOMAIN_USED = "https://localhost:44307";
//----

//HCM TBP9
// const DOMAIN_API = "https://hcmtbp9-ids.isopro.vn";
// export const DOMAIN_USED = "https://hcmtbp9-api.isopro.vn";
//----

//LACO
// const DOMAIN_API = "https://isolaco-ids.isopro.vn";
// export const DOMAIN_USED = "https://isolaco-api.isopro.vn";
//----

//QLDT
const DOMAIN_API = "https://hcmhm-qldt-ids.isopro.vn";
export const DOMAIN_USED = "https://hcmhm-qldt-api.isopro.vn";
//----

//DEMO
// const DOMAIN_API = "https://demo-task-ids.isopro.vn";
// export const DOMAIN_USED = "https://demo-task-api.isopro.vn";
//----

export const LOGIN_API = DOMAIN_API + "/connect/token";
export const TAKE_USER_INFO = DOMAIN_USED + "/api/User/Info";
export const TAKE_MENU = DOMAIN_USED + "/api/SysForm";

// parameter
export const TAKE_PARAMETER_LISTKEY = DOMAIN_USED + "/api/Parameter/Listkey";
export const READ_PARAMETER = DOMAIN_USED + "/api/Parameter/Key";
export const CREATE_PAREMETER = DOMAIN_USED + "/api/Parameter";
export const DETAIL_PARAMETER = DOMAIN_USED + "/api/Parameter/id?id=";
export const UPDATE_PARAMETER = DOMAIN_USED + "/api/Parameter/Update";
export const DELETE_PARAMETER = (id: any) => {
  return DOMAIN_USED + `/api/Parameter/Delete?id=${id}&ParameterKey=1`;
};
export const TAKE_PARAMETER_LISTKEY_DROPDOWN =
  DOMAIN_USED + "/api/Parameter/Key/DropDown?key=";

// task list
export const READ_ALL_TASK_LIST = DOMAIN_USED + "/api/TaskList";
export const READ_ALL_TASK_LIST_DROPDOWN =
  DOMAIN_USED + "/api/TaskList/ListDropdown";
export const READ_TASK_LIST_BY_USER = DOMAIN_USED + "/api/TaskList/ListByUser";
export const DETAIL_TASK_LIST = DOMAIN_USED + "/api/TaskList/id?id=";
export const CREATE_TASK_LIST = DOMAIN_USED + "/api/TaskList";
export const UPDATE_TASK_LIST = DOMAIN_USED + "/api/TaskList/Update";
export const DELETE_TASK_LIST = DOMAIN_USED + "/api/TaskList/Delete?id=";

// prior list
export const PRIOR_LIST = DOMAIN_USED + "/api/PriorityLevel";
export const PRIOR_LIST_DROPDOWN =
  DOMAIN_USED + "/api/PriorityLevel/ListByKeyDropdown";
export const PRIOR_DETAIL = DOMAIN_USED + "/api/PriorityLevel/id?id=";
export const PRIOR_UPDATE = DOMAIN_USED + "/api/PriorityLevel/Update";
export const PRIOR_CREATE = DOMAIN_USED + "/api/PriorityLevel";
export const PRIOR_DELETE = DOMAIN_USED + "/api/PriorityLevel/Delete?id=";

// config
export const CONFIG_EMAIL = DOMAIN_USED + "/api/Configs/MailSetings";
export const CONFIG_IS_FORCE = DOMAIN_USED + "/api/Configs/TaskSettingInfo";
export const CONFIG_EMAILINFO = DOMAIN_USED + "/api/Configs/EmailInfo";
export const CONFIG_OVERLOADINFO = DOMAIN_USED + "/api/Configs/OverloadInfo";
export const CONFIG_OVERLOADSETTING =
  DOMAIN_USED + "/api/Configs/OverloadSetings";
export const CONFIG_REMINDINFO = DOMAIN_USED + "/api/Configs/RemindInfo";
export const CONFIG_REMINDSETTING = DOMAIN_USED + "/api/Configs/RemindSetings";

// groups
export const GROUP_READ_ALL = DOMAIN_USED + "/api/Groups";
export const GROUP_READ_ALL_DROPDOWN = DOMAIN_USED + "/api/Groups/ListDropdown";
export const GROUP_READ_DETAIL = DOMAIN_USED + "/api/Groups/id?id=";
export const GROUP_UPDATE = DOMAIN_USED + "/api/Groups/Update";
export const GROUP_DELETE = DOMAIN_USED + "/api/Groups/Delete?id=";
export const GROUP_CREATE = DOMAIN_USED + "/api/Groups";

// users
export const USER_READ_ALL = DOMAIN_USED + "/api/User";
export const USER_READ_ALL_DROPDOWN = DOMAIN_USED + "/api/User/ListDropdown";
export const USER_CREATE = DOMAIN_USED + "/api/User";
export const USER_DETAIL = DOMAIN_USED + "/api/User/id?id=";
export const USER_UPDATE = DOMAIN_USED + "/api/User/Update";
export const USER_DELETE = DOMAIN_USED + "/api/User/Delete?Id=";
export const LOAD_SEP = DOMAIN_USED + "/api/User/ListSep";
export const FORGOT_PASSWORD = DOMAIN_USED + "/api/User/ForgotPassword";
export const CHANGEPASSWORD = DOMAIN_USED + "/api/User/ChangePassword";
export const USER_CHANGE_INFO = DOMAIN_USED + "/api/User/ChangeInfo";

// sys
export const SYS_DATA_LEVEL = DOMAIN_USED + "/api/SysDataLevel/id?id=";
export const SYS_DATA_LEVEL_UPDATE = (id: string, dataLevel: string) => {
  return (
    DOMAIN_USED + `/api/SysDataLevel/Update?Id=${id}&Datalevel=${dataLevel}`
  );
};

// sys dashboard
export const SYS_DASHBOARD = DOMAIN_USED + "/api/SysDashboard";
export const SYS_DASHBOARD_LOADFUNCS =
  DOMAIN_USED + "/api/SysDashboard/LoadFunctionByGroup?groupId=";
export const SYS_DASHBOARD_UPDATE = (
  id: any,
  screenChoose: any,
  perActive: any
) => {
  return (
    DOMAIN_USED +
    `/api/SysDashboard/Update?Id=${id.toString()}&groupId=${screenChoose.toString()}&value=${perActive.toString()}`
  );
};

// sys permission
export const SYS_PERMISSION = DOMAIN_USED + "/api/SysFormPermission";
export const SYS_PERMISSION_LOADFUNC =
  DOMAIN_USED + "/api/SysFormPermission/LoadFunctionByGroup?groupId=";
export const SYS_PERMISSION_UPDATE = (
  groupId: any,
  type: any,
  formId: any,
  value: any
) => {
  return (
    DOMAIN_USED +
    `/api/SysFormPermission/CheckFunction?groupId=${groupId.toString()}&type=${type.toString()}&formId=${formId.toString()}&value=${value.toString()}`
  );
};

// security
export const SECURITY_READ_ALL = DOMAIN_USED + "/api/SecurityLevel";
export const SECURITY_READ_ALL_DROPDOWN =
  DOMAIN_USED + "/api/SecurityLevel/ListByKeyDropdown";
export const SECURITY_CREATE = DOMAIN_USED + "/api/SecurityLevel";
export const SECURITY_DETAIL = DOMAIN_USED + "/api/SecurityLevel/id?id=";
export const SECURITY_UPDATE = DOMAIN_USED + "/api/SecurityLevel/Update";
export const SECURITY_DELETE = DOMAIN_USED + "/api/SecurityLevel/Delete?id=";

// li tasklist permission
export const LI_TASK_LIST_PERMISSION =
  DOMAIN_USED + "/api/LiTaskListPermission";
export const LI_TASK_LIST_LOADFUNCBYGROUP =
  DOMAIN_USED + "/api/LiTaskListPermission/LoadFunctionByGroup?groupId=";
export const LI_TASK_LIST_UPDATE = (
  groupId: any,
  taskListId: any,
  value: any
) => {
  return (
    DOMAIN_USED +
    `/api/LiTaskListPermission/CheckFunction?groupId=${groupId.toString()}&taskListId=${taskListId.toString()}&value=${value.toString()}`
  );
};

// li quy trinh
export const READ_ALL_LIST_QUYTRINH =
  DOMAIN_USED + "/api/LiQuytrinh/ListQuyTrinh";
export const READ_ALL_LIST_QUYTRINH_DROPDOWN =
  DOMAIN_USED + "/api/LiQuytrinh/ListQuyTrinhDropdown";
export const DELETE_QUYTRINH =
  DOMAIN_USED + "/api/LiQuytrinh/Delete_quy_trinh?id=";
export const READ_STEP_BY_ID_QUYTRINH =
  DOMAIN_USED + "/api/LiQuytrinh/ListQuyTrinh_Buoc?idQuyTrinh=";
export const CREATE_QUYTRINH = DOMAIN_USED + "/api/LiQuytrinh/Create_quy_trinh";
export const DETAIL_QUYTRINH = DOMAIN_USED + "/api/LiQuytrinh/id_quy_trinh?id=";
export const DETAIL_STEP =
  DOMAIN_USED + "/api/LiQuytrinh/ListQuyTrinh_Buoc?idQuyTrinh=";
export const DETAIL_STEP_DROPDOWN =
  DOMAIN_USED + "/api/LiQuytrinh/ListQuyTrinhBuocDropdown?idQuyTrinh=";
export const DETAIL_RESULT =
  DOMAIN_USED + "/api/LiQuytrinh/ListQuyTrinh_KQ?idQuyTrinh=";
export const DETAIL_RESULT_DROPDOWN =
  DOMAIN_USED + "/api/LiQuytrinh/ListQuyTrinhKQ_Dropdown?idQuyTrinh=";
export const UPDATE_QUYTRINH = DOMAIN_USED + "/api/LiQuytrinh/Update_quy_trinh";
export const UPDATE_QUYTRINH_BUOC =
  DOMAIN_USED + "/api/LiQuytrinh/Update_quy_trinh_buoc";
export const CREATE_QUYTRINH_STEP =
  DOMAIN_USED + "/api/LiQuytrinh/Create_quy_trinh_buoc";
export const DELETE_QUYTRINH_STEP =
  DOMAIN_USED + "/api/LiQuytrinh/Delete_quy_trinh_buoc?idBuoc=";
export const CREATE_RESULT =
  DOMAIN_USED + "/api/LiQuytrinh/Create_quy_trinh_KQ";
export const DELETE_RESULT =
  DOMAIN_USED + "/api/LiQuytrinh/Delete_quy_trinh_KQ?idQK=";
export const UPDATE_RESULT =
  DOMAIN_USED + "/api/LiQuytrinh/Update_quy_trinh_KQ";

// nguoi giao viec
export const NGUOI_GIAO_VIEC_DETAIL = (id: string) => {
  return DOMAIN_USED + `/api/TaskNguoiGiaoViec/id?id=${id}&type=false`;
};
export const NGUOI_GIAO_VIEC_LIST =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListListNew";
export const NGUOI_GIAO_VIEC_LIST_PROOCCESSING =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListProccessing";
export const NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListNeedDelivery";
export const NGUOI_GIAO_VIEC_LIST_DELAY =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListLate";
export const NGUOI_GIAO_VIEC_LIST_CHANGE_DEADLINE =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListChangeDeadLine";
export const NGUOI_GIAO_VIEC_LIST_END_TODAY =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListDeadlineToday";
export const NGUOI_GIAO_VIEC_LIST_END_SOON =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListNextToDeadline";
export const NGUOI_GIAO_VIEC_LIST_FINISH =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListCompleted";
export const NGUOI_GIAO_VIEC_LIST_TOTAL =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListAllHasDelivery";
export const NGUOI_GIAO_VIEC_XOA =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/Delete?taskId=";
export const NGUOI_GIAO_VIEC_CHUYEN_NGUOI_DANH_GIA =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ChangeTaskReviewer";
export const NGUOI_GIAO_VIEC_UPDATE =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/Update";
export const NGUOI_GIAO_VIEC_CHUYEN_SEP = (taskId: any, taskOwner: any) => {
  return (
    DOMAIN_USED +
    `/api/TaskNguoiGiaoViec/TransferTask?taskId=${taskId}&taskOwner=${taskOwner}`
  );
};
export const LOAD_PHONG_BAN =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/TaskDropdown";
export const CREATE_TASK_LIST_NGUOI_GIAO =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/Create";
export const CREATE_TASK_CONTENT_FOR_REPICIENT =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/CreateJobSupport";
export const REMIND_TASK =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/RemindTask?taskId=";
export const APPROVE_TASK =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/Approve?taskId=";
export const CONFIRM_TASK_CONTENT_NGUOIGIAO =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/CheckTaskContent";

export const LOAD_PHONG_BAN_BY_USER =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/TaskDropdown_ByUser";

export const LOAD_TASK_FROM_DETAIL_USER =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListAll";

export const LOAD_ALL_NOTI =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/TotalAllTask";

export const NGUOI_GIAO_LOAD_TASK_LATE =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListLateComplete";
export const NGUOI_GIAO_LOAD_TASK_EARLY =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListEarlyComplete";
export const NGUOI_GIAO_LOAD_TASK_PUNCTUAL =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListPunctualCompleted";

//nguoi nhan viec
export const NGUOI_NHAN_VIEC_LIST =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/ListNew";
export const NGUOI_NHAN_VIEC_GIVEBACK = (taskId: string, notes: string) => {
  return (
    DOMAIN_USED +
    `/api/TaskNguoiNhanViec/GiveBackTask?taskId=${taskId}&reason=${notes}`
  );
};
export const NGUOI_NHAN_VIEC_LIST_RECEIVED =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/ListReveced";
export const NGUOI_NHAN_VIEC_LIST_PROCESSING =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/ListWorking";
export const NGUOI_NHAN_VIEC_LIST_EARLY_COMPLETE =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/ListEarlyComplete";
export const NGUOI_NHAN_VIEC_LIST_PUNCTUAL_COMPLETE =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/ListPunctualCompleted";
export const NGUOI_NHAN_VIEC_LIST_LATE_COMPLETE =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/ListLateComplete";
export const NGUOI_NHAN_VIEC_LIST_DELAY =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/ListLated";
export const NGUOI_NHAN_VIEC_LIST_ALL =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/ListAll";
export const NGUOI_NHAN_VIEC_LIST_END_TO_DAY =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/ListDeadlineToday";
export const NGUOI_NHAN_VIEC_LIST_END_SOON =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/ListNextToDeadline";
export const NGUOI_NHAN_VIEC_NHAN_VIEC =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/GetOfTask?taskId=";
export const NGUOI_NHAN_VIEC_BAT_DAU =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/StartTask?taskId=";
export const CONFIRM_TASK_CONTENT =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/CheckTaskContent";
export const NGUOI_NHAN_VIEC_DETAIL = (id: any) => {
  return DOMAIN_USED + `/api/TaskNguoiGiaoViec/id?id=${id}&type=true`;
};
export const LICH_SU_GIA_HAN = (id: any) => {
  return DOMAIN_USED + `/api/TaskNguoiGiaoViec/HistoryExtend?taskID=${id}`;
};

export const NGUOI_GIAO_UPDATE_DEADLINE = (id: string, deadLine: string) => {
  return DOMAIN_USED + `/api/TaskNguoiGiaoViec/UpdateDeadLine?ID=${id}&deadline=${deadLine}`
}

export const SENT_MESSAGE =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ChangeInfortext";
export const NGUOI_NHAN_FILE_STEP_UPLOAD =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/UploadFileTaskContent";
export const NGUOI_NHAN_TASK_COMPLETE =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/CheckCompletedTask";
export const NGUOI_NHAN_UPLOAD_RESULT_FILE =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/UploadResultFile";
export const NGUOI_NHAN_EDIT_RESULT_FILE =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/EditFileTaskContent";
export const NGUOI_NHAN_CHECK_COMPLETED_TASK_CONTENT =
  DOMAIN_USED +
  "/api/TaskNguoiNhanViec/CheckCompletedTaskContentForUserDo?taskContentHistoryId=";

export const LOAD_ALL_NOTI_2 =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/TotalTaskAll";

// Report nanglucnhansu
export const THIET_LAP_DANH_GIA_DROPDOWN =
  DOMAIN_USED + "/api/Task_ThietLapDanhGia/ListDropDown";
export const NANG_LUC_NHAN_SU_LIST = DOMAIN_USED + "/api/Reports/NangLucNhanSu";

// tieu chi danh gia
export const TIEU_CHI_DANH_GIA =
  DOMAIN_USED + "/api/Task_TieuChiDanhGia/laytatcatieuchi";
export const XOA_TIEU_CHI_DANH_GIA =
  DOMAIN_USED + "/api/Task_TieuChiDanhGia/Xoatieuchi";
export const CHiTietTieuChiDanhGia =
  DOMAIN_USED + "/api/Task_TieuChiDanhGia/laychitiettieuchi?id=";
export const THEM_TIEU_CHI_DANH_GIA =
  DOMAIN_USED + "/api/Task_TieuChiDanhGia/Themmoitieuchi";
export const CAP_NHAT_TIEU_CHI_DANH_GIA =
  DOMAIN_USED + "/api/Task_TieuChiDanhGia/Capnhattieuchi";
export const NHOM_THAM_CHIEU =
  DOMAIN_USED + "/api/Task_TieuChiDanhGia/LoadQuery";

//notification
export const TOTAL_NOTI = DOMAIN_USED + "/api/Notification/TotalNoti";
export const LIST_NOTI = DOMAIN_USED + "/api/Notification/ListNoti";
export const READ_NOTI =
  DOMAIN_USED + "/api/Notification/ReadNoti?Id={0}&userNoti={1}";
export const READ_ALL_NOTI =
  DOMAIN_USED + "/api/Notification/ReadAllNoti?userNoti=";
export const CONFIRM_TASKING =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ConfirmTask";
export const CHECK_RESULT = DOMAIN_USED + "/api/TaskNguoiNhanViec/CheckResult";

// setting
export const DIFFICULT_LEVEL = DOMAIN_USED + "/api/DifficultLevel";
export const DIFICULT_DETAIL = DOMAIN_USED + "/api/DifficultLevel/id?id=";
export const DIFICULT_UPDATE = DOMAIN_USED + "/api/DifficultLevel/Update";
export const DIFICULT_DELETE = DOMAIN_USED + "/api/DifficultLevel/Delete?id=";
export const DIFICULT_LEVEL_DROPDOWN =
  DOMAIN_USED + "/api/DifficultLevel/ListDropdown";

// report
export const YEAR_LIST = DOMAIN_USED + "/api/ReportRanking/YearInReport";
export const REPORT_TASK_TOTAL_LIST =
  DOMAIN_USED + "/api/TaskTotalList/TaskTotalList";
export const REPORT_RANKING = DOMAIN_USED + "/api/ReportRanking/ReportAll";
export const REPORT_SUMWORK = DOMAIN_USED + "/api/Reports/sumwork";
export const REPORT_OVERVIEW = DOMAIN_USED + "/api/Reports/overview";
export const REPORT_SUMREDWORK = DOMAIN_USED + "/api/Reports/sumredwork";
export const REPORT_SUMGREENWORK = DOMAIN_USED + "/api/Reports/sumgreenwork";
export const REPORT_USERGREENWORK = DOMAIN_USED + "/api/Reports/usergreenwork";
export const REPORT_USERREDWORK = DOMAIN_USED + "/api/Reports/userredwork";
export const REPORT_SUMTASKPERCENT =
  DOMAIN_USED + "/api/Reports/sumtaskpercent";
export const REPORT_USERTASKPERCENT =
  DOMAIN_USED + "/api/Reports/useraskpercent";
export const REPORT_SUMTASKLISTALL =
  DOMAIN_USED + "/api/Reports/sumtasklistall";
export const REPORT_USERSUMWORK = DOMAIN_USED + "/api/Reports/usersumwork";

export const REPORT_PERSONAL_GREENWORK =
  DOMAIN_USED + "/api/Reports/personalgreenwork";
export const REPORT_PERSONAL_REDWORK =
  DOMAIN_USED + "/api/Reports/personalredwork";
export const REPORT_PERSONAL_SUMWORK =
  DOMAIN_USED + "/api/Reports/personalsumwork";

export const REPORT_PERSONAL_DIFTASK = DOMAIN_USED + "/api/Reports/userdiftask";
export const REPORT_SUM_DIFTASK = DOMAIN_USED + "/api/Reports/sumdiftask";

// Task_DanhGiaNangLuc(cho bảng điểm)
export const ALL_BANGDIEM =
  DOMAIN_USED + "/api/Task_DanhGiaNangLuc/ListLoadAll";
export const ONE_BANGDIEM =
  DOMAIN_USED + "/api/Task_DanhGiaNangLuc/LoadOne?id=";

//propose
export const PROPOSESENDLIST =
  DOMAIN_USED + "/api/ProposeController_NguoiGui/List";
export const PROPOSE_DETAIL = (id: string) => {
  return (
    DOMAIN_USED + `/api/ProposeController_NguoiGui/ListOnePropose?id=${id}`
  );
};
export const CREATE_PROPOSE =
  DOMAIN_USED + "/api/ProposeController_NguoiGui/Create";

export const PROPOSEGETLIST =
  DOMAIN_USED + "/api/ProposeController_NguoiNhan/List";
export const PROPOSE_CONFIRM =
  DOMAIN_USED + "/api/ProposeController_NguoiNhan/Confirm";
export const PROPOSE_UNCONFIRM =
  DOMAIN_USED + "/api/ProposeController_NguoiNhan/UnConfirm";

export const PROPOSE_TYPE_LIST =
  DOMAIN_USED + "/api/Li_ProposeType/LoadAllType";

// extend

export const ASK_EXTEND = DOMAIN_USED + "/api/TaskNguoiNhanViec/AskExtend";
export const CONFIRM_EXTEND =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ConfirmExtend";

export const SCORING_LIST = DOMAIN_USED + "/api/TaskScorecard/List";

export const NGUOI_GIAO_TASK_RETURN =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListReturnWork";
export const NGUOI_NHAN_TASK_RETURN =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/ListReturnWork";
export const NGUOI_NHAN_CHO_DUYET_TASK =
  DOMAIN_USED + "/api/TaskNguoiNhanViec/ListCompleted";

// No auth
export const NGUOI_GIAO_RECEVED =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListReveced_NO_AUT";
export const NGUOI_GIAO_LIST_NEW =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListListNew_NO_AUT";
export const NGUOI_GIAO_LIST_NEED_DELIVERY =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListNeedDelivery_NO_AUT";
export const NGUOI_GIAO_LIST_COMPLETED =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListCompleted_NO_AUT";
export const NGUOI_GIAO_LIST_PROCCESSING =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListProccessing_NO_AUT";
export const NGUOI_GIAO_LIST_LISTLATE =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListLate_NO_AUT";
export const NGUOI_GIAO_LIST_DEADLINETODAY =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListDeadlineToday_NO_AUT";
export const NGUOI_GIAO_LIST_NEXTTODEADLINE =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListNextToDeadline_NO_AUT";
export const NGUOI_GIAO_LIST_LATECOMPLETE =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListLateComplete_NO_AUT";
export const NGUOI_GIAO_LIST_EARLYCOMPLETE =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListEarlyComplete_NO_AUT";
export const NGUOI_GIAO_LIST_PUNCTUALCOMPLETE =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListPunctualCompleted_NO_AUT";
export const NGUOI_GIAO_LIST_RETURNWORK =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListReturnWork_NO_AUT";
export const NGUOI_GIAO_LIST_ALLHASDELIVERY =
  DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListAllHasDelivery_NO_AUT";

export const BAO_CAO_CONG_VIEC_BAO_CAO = DOMAIN_USED + "/api/Task_BaoCaoCongViec/BaoCao";
export const BAO_CAO_READ_SENDER = DOMAIN_USED + "/api/Task_BaoCaoCongViec/ListReport_Sender";
export const BAO_CAO_READ_OWNER = DOMAIN_USED + "/api/Task_BaoCaoCongViec/ListReport_Owner";
export const BAO_CAO_READ_MANAGER = DOMAIN_USED + "/api/Task_BaoCaoCongViec/ListReport_Manager";
export const BAO_CAO_CHI_TIET = DOMAIN_USED + "/api/Task_BaoCaoCongViec/ChiTietBaoCao"

export const BAO_CAO_GUI = DOMAIN_USED + "/api/Task_BaoCaoCongViec/SendReport"
export const BAO_CAO_XOA = DOMAIN_USED + "/api/Task_BaoCaoCongViec/DeleteReport"

export const BAO_CAO_CONG_VIEC_CHECK_REPORT = DOMAIN_USED + "/api/Task_BaoCaoCongViec/CheckReport";
export const BAO_CAO_CONG_VIEC_REPORTJOB_TEXT = DOMAIN_USED + "/api/Task_BaoCaoCongViec/ReportJob_Text";
export const BAO_CAO_CONG_VIEC_REPORTPLAN_TEXT = DOMAIN_USED + "/api/Task_BaoCaoCongViec/ReportPlan_Text";
export const BAO_CAO_CONG_VIEC_CREATE_REPORT = DOMAIN_USED + "/api/Task_BaoCaoCongViec/CreateReport";
export const BAO_CAO_UPDATE_REPORT = DOMAIN_USED + "/api/Task_BaoCaoCongViec/UpdateReport";

export const KE_HOACH_GUI = DOMAIN_USED + "/api/Task_BaoCaoCongViec/report_kehoach";
export const KE_HOACH_SENDER = DOMAIN_USED + "/api/Task_BaoCaoCongViec/ListPlanSender";
export const KE_HOACH_MANAGER = DOMAIN_USED + "/api/Task_BaoCaoCongViec/ListPlan_Manager"
export const KE_HOACH_CHI_TIET = DOMAIN_USED + "/api/Task_BaoCaoCongViec/ChitietKeHoach";
export const KE_HOACH_CAP_NHAT = DOMAIN_USED + "/api/Task_BaoCaoCongViec/UpdatePlan"

export const KE_HOACH_SEND_BOSS = DOMAIN_USED + "/api/Task_BaoCaoCongViec/SendPlan";
export const KE_HOACH_XOA = DOMAIN_USED + "/api/Task_BaoCaoCongViec/DeletePlan";

export const VOTING_LIST_NEED_TO_JOIN =
  DOMAIN_USED + "/api/TaskVoting/ListAllNeedToJoin_Voting";
export const VOTING_LIST_REQUEST =
  DOMAIN_USED + "/api/TaskVoting/ListAllRequest_Voting";
export const VOTING_DETAILS = DOMAIN_USED + "/api/TaskVoting/LoadOne?id=";
export const CHECK_VOTING = DOMAIN_USED + "/api/TaskVoting/CheckVoting";
export const VOTING_RESULT =
  DOMAIN_USED + "/api/TaskVoting/LoadAll_VotingResult";
export const NGUOI_GIAO_VIEC_LIST_ALL_HAS_DELIVERY = DOMAIN_USED + "/api/TaskNguoiGiaoViec/ListAllHasDeliveryByUser"
export const TU_TAO_BAO_CAO = DOMAIN_USED + "/api/Task_BaoCaoCongViec/ReportCreate"

export const BAO_CAO_CONFIRM = DOMAIN_USED + "/api/Task_BaoCaoCongViec/CheckReportPlan"