import React, { useState, useRef, useEffect, useContext } from "react";
import { HexColorPicker } from "react-colorful";
import { SketchPicker } from "react-color";
import Select from "react-select";
import { CHiTietTieuChiDanhGia } from "../../../apis/_index";

import { useSelector, useDispatch } from "react-redux";
import {
  NGUOI_GIAO_VIEC_LIST_FINISH,
  LOAD_PHONG_BAN,
  USER_READ_ALL,
  NGUOI_GIAO_VIEC_DETAIL,
  SCORING_LIST,
} from "../../../apis/_index";

import { CONFIRM_TASKING } from "../../../apis/_index";

import { ModalController } from "../../../ui/homeUI/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

import { ONCHANGE_NGUOI_GIAO_HOAN_TAT } from "../../../redux/reducers/workingReducer/_index";

import axios from "axios";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import { readList } from "../../../funcs/taskNguoiGiaoViec";

import { toast } from "react-toastify";
import LoaderSpinner from "../../loader-spinner/_index";
import { getUniqueTags } from "../../../helperText/index";

const formatGroupLabel = (data: any = []) => (

  <div>
    <span><b>{data?.label}</b></span>
  </div>
);

const ComfirmCompleteTask = (props: any) => {
  const taskId = props.taskId;
  const taskName = props.taskCode; // taskCode here is taskName
  const task = props.task;
  // console.log("task", task);

  const id = props.id;

  const handleClose = props.handleClose;
  const _handleCheckCompled = props._handleCheckCompled;

  const data = useSelector((state: any) => state.appReducer);
  const workingData = useSelector((state: any) => state.workingReducer);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const context = useContext(ModalController);

  const [notes, setNotes] = useState<string>("");

  const [userList, setUserList] = useState<any[] | null>(null);
  const [selectedPhongBan, setSelectedPhongBan] = useState<any>(-1);
  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [selectedNguoiNhan, setSelectedNguoiNhan] = useState<any>(-1);
  const [selectedNguoiLamViecList, setSelectedNguoiLamViecList] = useState<any>(
    []
  );
  const [infoNguoiPhoiHop, setInfoNguoiPhoiHop] = useState<any[]>([]);
  const [getNguoiNhanList, setGetNguoiNhanList] = useState<any>([]);

  let userData: any = localStorage.getItem("userData");
  userData = userData.replace(/'/g, '"'); //replacing all ' with "
  userData = JSON.parse(userData);

  const selectedNguoiNhanList = getNguoiNhanList.map((item: any) => {
    return { label: item?.FullName, value: item?.Id };
  });
  const _onChangePhongBan = (e: any) => {
    e.preventDefault();
    setSelectedPhongBan(
      phongBanList?.find(
        (item: any) => item?.IdPhongBan === parseInt(e.target.value)
      )
    );
    const nguoiNhan = userList?.filter((x: any) => task.Recipient !== x.Id && x.Id !== userData?.Data[0]?.Id &&
      (task?.Supporter ? (task?.Supporter?.includes('{' + x.Id + '}') == false) : (x))).filter(
        (item: any) => item?.IdPhongBan === parseInt(e.target.value)
      );

    setGetNguoiNhanList(nguoiNhan);
    setSelectedNguoiNhan(-1);
  };

  const _onChangeNguoiNhan = (e: any) => {
    e.preventDefault();
    setSelectedNguoiNhan(
      selectedNguoiNhanList?.find((item: any) => item?.Id === e.target.value)
    );

    const tempArray = [
      selectedNguoiNhanList?.find((item: any) => item?.Id === e.target.value),
    ];

    setSelectedNguoiLamViecList(tempArray);
  };



  const votingList = workingData?.receiverList?.filter((x: any) => task?.Recipient !== x.Id && x.Id !== userData?.Data[0]?.Id &&
    (task?.Supporter ? (task?.Supporter?.includes('{' + x.Id + '}') == false) : (x))).map((item: any) => {
      //if (item?.Id !== userData.Data[0].Id) {

      return { label: item?.FullName, value: item?.Id };
      //}
    });

  const [voting, setVoting] = useState<any[]>([]);

  const [votingTime, setVotingTime] = useState<string>("");

  const _onChangeVoting = (e: any, id: any) => {
    setVoting(e);
    // console.log("workingData", workingData);
  };

  const _onChangeVoting_Supporter = (e: any, id: any) => {
    const _infoNguoiPhoiHop = [...infoNguoiPhoiHop];

    _infoNguoiPhoiHop?.map((_i: any) => {
      if (_i?.id?.trim().toUpperCase() === id?.trim().toUpperCase()) {
        _i.userVoteId_Supporter = e
      }
    });

    setInfoNguoiPhoiHop(_infoNguoiPhoiHop);
  };

  const phongBanIdList = workingData.listPhongBan?.map((item: any) => {
    return { label: item?.ParameterValue, value: item?.ParameterId };
  });
  let _arr: any = [];

  const returnSelected = (arr: any[]) => {
    arr.map((item: any) => {
      if (item && item?.FullName && item?.Id) {
        _arr.push({ label: item?.FullName, value: item?.Id });
      }
    });

    return _arr;
  };

  const returnSingleSelected = (item: any) => {
    if (item && item?.FullName && item?.Id) {
      return { label: item?.FullName, value: item?.Id };
    }
  };

  const _receiverList = workingData?.receiverList?.map((item: any) => {
    return { label: item?.FullName, value: item?.Id };
  });
  const [phongBanId, setPhongBanId] = useState<any[]>([]);


  const getUnique = (arr: any[], index: any) => {

    if (arr.length == 0) {
      return []
    }
    const unique = arr
      .map(e => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e: any) => arr[e]).map((e: any) => arr[e]);

    return unique;
  }


  const _onChangePhongBanId = (e: any) => {
    setPhongBanId([]);

    const _voting = [...voting];

    workingData?.receiverList?.filter((x: any) => task.Recipient !== x.Id &&
      (task?.Supporter ? (task?.Supporter?.includes('{' + x.Id + '}') == false) : (x))).map((item: any) => {
        if (item && e.value && item?.PhongBan == e.value) {
          if (item?.Id !== userData.Data[0].Id) {
            _voting.push(returnSingleSelected(item));
          }
        }
      });
    setVoting(getUnique(_voting, 'value'));
  };


  const _onChangePhongBanId_Supporter = (e: any, id: any) => {
    setPhongBanId([]);
    let _infoNguoiPhoiHop = [...infoNguoiPhoiHop];

    workingData?.receiverList?.filter((x: any) => task.Recipient !== x.Id && x?.Id !== userData.Data[0].Id &&
      (task?.Supporter ? (task?.Supporter?.includes('{' + x.Id + '}') == false) : (x))).map((item: any) => {
        if (item && e.value && item?.PhongBan == e.value) {

          _infoNguoiPhoiHop?.map((_i: any) => {

            if (_i.id.trim().toUpperCase() === id.trim().toUpperCase()) {
              _i.userVoteId_Supporter.push(returnSingleSelected(item))
            }
          });
        }
      });

    _infoNguoiPhoiHop.map((xmm: any) => {
      if (xmm.id.trim().toUpperCase() === id.trim().toUpperCase()) {
        xmm.userVoteId_Supporter = getUnique(xmm.userVoteId_Supporter, 'value')
      }
    })

    setInfoNguoiPhoiHop(_infoNguoiPhoiHop);
  };

  const _onChangeVotingTimeSupporter = (e: any, id: any) => {

    let _infoNguoiPhoiHop = [...infoNguoiPhoiHop];
    _infoNguoiPhoiHop.map((xmm: any) => {
      if (xmm.id.trim().toUpperCase() === id.trim().toUpperCase()) {
        xmm.votingTime_Supporter = e?.target?.value
      }
    })

    setInfoNguoiPhoiHop(_infoNguoiPhoiHop);
  }

  const [single, setSingle] = useState<any[]>([]);
  const [singleId, setSingleId] = useState<any[]>([]);

  // const singleList = singleId.map((item: any) => {
  //   return { label: item?.FullName, value: item?.Id };
  // });

  let _item: any = [];

  // console.log("voting", voting);
  // console.log("singleId", singleId);

  // singleId.map((item: any) => {
  //   if (item?.Id !== userData.Data[0].Id) {
  //     _item?.push({ label: item?.FullName, value: item?.Id });
  //   }
  //   voting.map((voting: any) => {
  //     if (voting.value !== item?.Id) {
  //       _item?.push({ label: item?.FullName, value: item?.Id });
  //     }
  //   });
  // });

  // doraemon 9/8
  // let unique = new Map();
  // voting.length !== 0
  //   ? singleId.map((item: any) => {
  //     voting.filter((voting: any) => {
  //       if (voting.value.trim().toUpperCase() !== item?.Id.trim().toUpperCase()) {

  //         if (item?.Id !== userData.Data[0].Id) {
  //           unique.set(item?.Id, { lable: item?.FullName, value: item?.Id });
  //         }
  //       }
  //     });
  //   })
  //   : singleId.map((item: any) => {
  //     if (item?.Id !== userData.Data[0].Id) {
  //       unique.set(item?.Id, { lable: item?.FullName, value: item?.Id });
  //     }
  //   });


  // const valuesArray1 = Array.from(unique.values());
  // const valuesArray = valuesArray1.map((item: any) => {
  //   return { label: item?.lable, value: item?.value };
  // });

  // console.log("valuesArray", valuesArray);

  const _onChangeSingle = (e: any) => {
    setSingle([]);

    // e?.map((item: any) => {
    //   const _voting = [...voting];
    //   _voting.push(item[0]);
    //   setVoting(returnSelected(_voting));
    // });
    const _voting = [...voting];
    _voting.push(e);

    setVoting(getUnique(_voting, 'value'));
  };

  const _onChangeSingle_Supporter = (e: any, id: any) => {


    // e?.map((item: any) => {
    //   const _voting = [...voting];
    //   _voting.push(item[0]);
    //   setVoting(returnSelected(_voting));
    // });
    const _infoNguoiPhoiHop = [...infoNguoiPhoiHop];

    _infoNguoiPhoiHop?.map((_i: any) => {

      if (_i.id.trim().toUpperCase() === id.trim().toUpperCase()) {
        _i.userVoteId_Supporter.push(e)
      }
    });

    _infoNguoiPhoiHop.map((xmm: any) => {
      if (xmm.id.trim().toUpperCase() === id.trim().toUpperCase()) {
        xmm.userVoteId_Supporter = getUnique(xmm.userVoteId_Supporter, 'value')
      }
    })

    setInfoNguoiPhoiHop(_infoNguoiPhoiHop);

  };

  const dispatch = useDispatch();

  const urlLink = window.location.pathname;
  const shouldLog = useRef(true);

  const shouldLogReload = useRef(true);

  const shouldLogLoadDetail = useRef(true);

  const [infoNguoiNhan, setInfoNguoiNhan] = useState<any | null>(null);

  const [scoreList, setScoreList] = useState<any[] | null>(null);
  const [scoreListAll, setScoreListAll] = useState<any[] | null>(null);
  const [scoreList_For_Supporter, setScoreList_For_Supporter] = useState<any[] | null>(null);

  const bieuQuyetList = [];
  if (infoNguoiNhan?.length !== 0) {
    if (infoNguoiNhan?.score === 1 || infoNguoiNhan?.score === 2) {
      bieuQuyetList.push(infoNguoiNhan);
    }
  }

  const shouldLogScoreLoading = useRef(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const _ScoreList = [];
    if (shouldLogScoreLoading.current === true) {
      shouldLogScoreLoading.current = false;

      const takeData = async () => {
        try {
          await axios
            .get(SCORING_LIST, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                let listGetGroup_Supporter = getUniqueTags(res?.data?.Data, 'Group_Supporter');

                listGetGroup_Supporter = listGetGroup_Supporter.map((x: any) => {
                  return { label: x.Group_Supporter, options: [] }
                })
                res?.data?.Data?.map((item: any) => {
                  listGetGroup_Supporter.map((x: any) => {
                    if (x.label.trim().toUpperCase() === item?.Group_Supporter.trim().toUpperCase()) {
                      x.options.push({ label: item?.Name, value: item?.ID, color: '#000' })
                    }
                  })
                });
                let dataRepicient = res?.data?.Data.filter((x: any) => x.IsOnlySuporter == false)
                setScoreList(dataRepicient)
                setScoreList_For_Supporter(listGetGroup_Supporter);
                setScoreListAll(res?.data?.Data)
              }
            });
        } catch (error) {
          toast.error("Tải dữ liệu điểm thất bại");
        }
      };

      const takeSingleList = async () => {
        try {
          await axios
            .get(USER_READ_ALL, {
              headers: {
                Accept: "text/plain",
                Authorization: "Bearer " + accessToken,
              },
            })
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setSingleId(res.data.Data);
              }
            });
        } catch (error: any) { }
      };
      takeData();

      takeSingleList();
    }
  });

  // useEffect(() => {
  //   const accessToken = localStorage.getItem("accessToken");
  //   if (shouldLogScoreLoading.current === true) {
  //     shouldLogScoreLoading.current = false;
  //     const takeData = async () => {
  //       try {
  //         await axios
  //           .get(USER_READ_ALL, {
  //             headers: {
  //               Accept: "text/plain",
  //               Authorization: "Bearer " + accessToken,
  //             },
  //           })
  //           .then((res: any) => {
  //             if (res.data.StatusCode === 200) {
  //               setSingleId(res.data.Data);
  //               console.log("res.data.Data", res.data.Data);
  //             }
  //           });
  //       } catch (error: any) {}
  //     };

  //     takeData();
  //   }
  // });

  // loadDetail task
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const API = NGUOI_GIAO_VIEC_DETAIL(taskId);

    if (shouldLogLoadDetail.current === true) {
      shouldLogLoadDetail.current = false;

      const takeDataDetail = async () => {
        try {
          await axios
            .get(API, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setInfoNguoiNhan({
                  id: res.data.Data.task.Recipient,
                  name: res.data.Data.task.RecipientName,
                  score: 0,
                });

                setPhongBanId(res.data.Data.task.IDPhongban);

                if (res.data.Data.task.Supporter === null) {
                  // do nothing
                  setInfoNguoiPhoiHop([]);
                } else {
                  let tempString: any[] = [];

                  let tempSupId = res.data.Data.task.Supporter.replace("{", "")
                    .replaceAll("}{", ",")
                    .replace("}", "");

                  let tempSupId2 = tempSupId.split(",");
                  let tempSupName = res.data.Data.task.SupporterName.split(",");

                  tempSupId2.forEach((item: any, index: any) => {
                    tempString.push({
                      id: item?.trim(),
                      name: tempSupName[index].trim(),
                      score: 0,
                      note: "",
                      userVoteId_Supporter: [],
                      isVote: false,
                      votingTime_Supporter: null
                    });
                  });

                  setInfoNguoiPhoiHop(tempString);
                }
              }
            });
        } catch (error) {
          toast.error("Tải dữ liệu thất bại.");
        }
      };

      takeDataDetail();
    }
  });
  const phongBan = () => {
    return (
      <div>
        Chọn cả phòng
        <input type="checkbox" />
      </div>
    );
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog.current) {
      shouldLog.current = false;

      if (phongBanList === null || userList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(LOAD_PHONG_BAN, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setPhongBanList(res.data.Data.PhongBan);
                  setUserList(res.data.Data.NguoiNhan);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }
    }
  });

  // funcs
  // const _onClickSubmit = async () => {
  //   const accessToken = localStorage.getItem("accessToken");
  //   const API = NGUOI_NHAN_VIEC_GIVEBACK(taskId, notes);

  //   try {
  //     await axios
  //       .get(API, {
  //         headers: {
  //           Authorization: "Bearer " + accessToken,
  //           Accept: "text/plain",
  //         },
  //       })
  //       .then((res: any) => {
  //         if (res.status === 200) {
  //           readList(
  //             NGUOI_NHAN_VIEC_LIST,
  //             workingData.pageCurrent,
  //             workingData.choosePage,
  //             workingData.searchFilter.taskName,
  //             workingData.searchFilter.selectedPrior,
  //             workingData.searchFilter.selectedReceiver,
  //             workingData.searchFilter.fromDate,
  //             workingData.searchFilter.toDate,
  //             workingData.searchFilter.deadLine,
  //             shouldLog,
  //             urlLink,
  //             (res: any) => {
  //               dispatch(ONCHANGE_NGUOI_NHAN_MOI(res.data.Data));
  //             }
  //           );
  //           toast.success("Trả việc thành công");
  //           handleClose();
  //           context.handleClose();
  //         }
  //       });
  //   } catch (error: any) {
  //     toast.error("!");
  //   }
  // };

  // const _onClickChecking = (type: string) => {
  //   switch (type) {
  //     case "OK":
  //       setInfoNguoiNhan((item: any) => {
  //         return {
  //           ...item,
  //           score: 0,
  //         };
  //       });
  //       setNotes("");
  //       setOnCheck(1);
  //       break;
  //     case "notOK":
  //       setInfoNguoiNhan((item: any) => {
  //         return {
  //           ...item,
  //           score: 0,
  //         };
  //       });

  //       const updateArray =
  //         infoNguoiPhoiHop === null
  //           ? []
  //           : infoNguoiPhoiHop?.map((item: any) => {
  //               return {
  //                 ...item,
  //                 score: 0,
  //               };
  //             });

  //       setInfoNguoiPhoiHop(updateArray);

  //       setNotes("");
  //       setOnCheck(0);
  //       break;

  //     default:
  //       break;
  //   }
  // };

  const _onClickSubmit = () => {
    _onClickOK();
  };

  const _onClickOK = async () => {
    setIsLoading(true);
    const accessToken = localStorage.getItem("accessToken");

    let tempArray: any[] = [];

    infoNguoiPhoiHop?.forEach((item: any) => {
      let tempArrSup = "";

      item?.userVoteId_Supporter?.forEach((uS: any, uSindex: number) => {
        if (uSindex === item?.userVoteId_Supporter?.length - 1) {
          tempArrSup += `${uS?.value}`;
        } else {
          tempArrSup += `${uS?.value},`;
        }
        return tempArrSup;
      })

      tempArray.push({
        SupporterId: item?.id,
        Supporter_TaskScorecardID: item?.score,
        Note: item?.note,
        VotingTime_Supporter: item?.votingTime_Supporter || null,
        UserVoteId_Supporter: tempArrSup,

      });
    });

    let tempString: string = "";

    voting?.forEach((item: any, index: number) => {
      // tempArray.push(item?.value);
      if (index === voting.length - 1) {
        tempString += `${item?.value}`;
      } else {
        tempString += `${item?.value},`;
      }
      return tempString;
    });

    const body = {
      ID: id,
      TaskID: taskId,
      Reason: notes,
      Checked: true,
      UserAdd: task.TaskOwner,
      RepicientId: infoNguoiNhan.id,
      Repicient_TaskScorecardID: infoNguoiNhan.score,
      Count_Supporters: tempArray,
      VotingTime: votingTime || null,
      UserVoteId: tempString || null,
    };
    // voting.map((item: any) => {
    //   return item?.value;
    // }) || null,
    // Voting:
    //   voting.map((item: any) => {
    //     return { UserVoteId: item?.value };
    //   }) || null,


    try {
      await axios
        .post(CONFIRM_TASKING, body, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        })
        .then((res: any) => {
          if (res.data.StatusCode === 200) {

            readList(
              NGUOI_GIAO_VIEC_LIST_FINISH,
              workingData.pageCurrent,
              workingData.choosePage,
              workingData.searchFilter.taskName,
              workingData.searchFilter.selectedPrior,
              workingData.searchFilter.selectedReceiver,
              workingData.searchFilter.fromDate,
              workingData.searchFilter.toDate,
              workingData.searchFilter.deadLine,
              shouldLogReload,
              urlLink,
              (res: any) => {
                dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data));
              }
            );

            toast.success("Đã xác nhận HOÀN TẤT");
            handleClose();
            setIsLoading(false);
            context.handleClose();

            // const processData = async () => {
            //   Promise.all([

            //     // toast.success("Đã xác nhận HOÀN TẤT"),
            //     // handleClose(),
            //     // setIsLoading(false),
            //     // context.handleClose(),
            //   ]);
            // }

            // processData();

            // readList(
            //   NGUOI_GIAO_VIEC_LIST_FINISH,
            //   workingData.pageCurrent,
            //   workingData.choosePage,
            //   workingData.searchFilter.taskName,
            //   workingData.searchFilter.selectedPrior,
            //   workingData.searchFilter.selectedReceiver,
            //   workingData.searchFilter.fromDate,
            //   workingData.searchFilter.toDate,
            //   workingData.searchFilter.deadLine,
            //   shouldLog,
            //   urlLink,
            //   (res: any) => {
            //     dispatch(ONCHANGE_NGUOI_GIAO_HOAN_TAT(res.data.Data));
            //   }
            // );
          }
        });
    } catch (error: any) {
      toast.error("Xác nhận thất bại");
      setIsLoading(false);
    }
  };

  const _onCheckScore = () => {
    const scoreList = [];
    {
      infoNguoiPhoiHop?.map((item: any) => {
        if (item?.score !== 0 && item?.note !== "") {
          scoreList.push(item);
        }
      });
    }

    if (infoNguoiPhoiHop?.length === 0) {
      if (infoNguoiNhan?.score !== 0 && notes !== "") {
        return true;
      } else {
        return false;
      }
    } else if (
      scoreList.length == infoNguoiPhoiHop?.length &&
      infoNguoiNhan?.score !== 0 &&
      notes !== ""
    ) {

      return true;

    } else {
      return false;
    }
  };

  const _onCheckVoting = () => {
    if (votingTime === "" || (voting.length === 0)) {
      return false;
    }
  };

  const _onShowButton = () => {
    let scoreListGood = [];
    {
      infoNguoiPhoiHop?.map((item: any) => {
        if (item?.score === 1 || item?.score === 2) {
          scoreListGood.push(item);
        }
      });
    }

    if (
      infoNguoiNhan?.score === 1 ||
      infoNguoiNhan?.score === 2 ||
      scoreListGood.length !== 0
    ) {
      return "Biểu quyết";
    } else if (infoNguoiNhan?.score === 3) {
      return "Chấp nhận kết quả";
    } else if (infoNguoiNhan?.score === 4) {
      return "Trả về";
    }
  };

  const _onChangeScoreNguoiNhan = (e: any) => {
    e.preventDefault();

    setInfoNguoiNhan((item: any) => {
      return {
        ...item,
        score: parseInt(e.target.value),
      };
    });
  };

  const _onChangeScorePhoiHop = (id: string, e: any) => {
    // e.preventDefault();

    const updateArray =
      infoNguoiPhoiHop === null
        ? []
        : infoNguoiPhoiHop?.map((item: any) => {
          if (item?.id.trim().toUpperCase() === id.trim().toUpperCase()) {
            return {
              ...item,
              score: parseInt(e?.value),
              isVote: (e?.value == 1 || e?.value == 2) ? true : false
            };
          }

          return item;
        });

    setInfoNguoiPhoiHop(updateArray);
  };

  const _onChangeNotePhoiHop = (id: string, e: any) => {
    e.preventDefault();

    const updateArray =
      infoNguoiPhoiHop === null
        ? []
        : infoNguoiPhoiHop?.map((item: any) => {
          if (item?.id === id) {
            return {
              ...item,
              note: e.target.value,
            };
          }

          return item;
        });

    setInfoNguoiPhoiHop(updateArray);
  };

  return (
    (
      <div
        className="
      flex flex-col bg-white
      lg:w-[40rem] w-screen h-[calc(100vh-5rem)] 
      rounded-md
  "
      >
        {/* header */}
        <div
          className="
        flex w-full h-max bg-sky-500 rounded-t-md
        p-2 items-center font-semibold text-white
        text-lg justify-between
      "
        >
          <p>Đánh giá kết quả</p>
          <button
            className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
            onClick={handleClose}
          >
            X
          </button>
        </div>

        {/* content */}
        {isLoading === true ||
          scoreList === null ||
          scoreList_For_Supporter === null ||
          infoNguoiNhan === null ||
          infoNguoiPhoiHop === null ? (
          <div className="relative justify-center items-center flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
            <LoaderSpinner w={32} h={32} />
            <p className="text-primary">Đang xử lý dữ liệu</p>
          </div>
        ) : (
          <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
            {/* lines */}
            <div className="flex w-full h-max">
              <div className="w-full pr-0 h-max">
                <p className="">Tên công việc&nbsp;</p>
                <input
                  className="w-full uppercase font-bold h-[1.9rem] rounded-md text-center px-2 border-2 border-slate-200"
                  value={taskName}
                  disabled
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>
            </div>

            <div className="w-full h-max flex items-center border-slate-300 border-t-2">
              <div className="w-full h-max flex flex-col space-y-4">
                <div className="w-full h-full pt-2 flex flex-col space-y-4">
                  {/* <p className="text-green-500 font-bold">XÁC NHẬN HOÀN TẤT</p> */}
                  <div className="flex flex-col w-full h-max space-y-1">
                    <label className="uppercase font-semibold text-center">
                      Bảng đánh giá&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <table>
                      <thead className="text-white font-semibold text-center w-full h-[2rem]">
                        <tr className="border-solid border-black border-[2px]">
                          <th className="border-l-[1px] border-slate-300">
                            Thông tin đánh giá người thực hiện chính
                          </th>
                        </tr>
                      </thead>
                      <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                        <tr className="border-solid border-black border-[2px]">
                          <td className="border-l-[1px] border-slate-300 border-[1px] space-y-1">
                            <div className="flex flex-col w-full h-max">
                              {/* <p className="font-semibold">
                      Tên: {infoNguoiNhan === null ? "" : infoNguoiNhan.name}
                    </p> */}
                              <select
                                className="border-2 border-slate-200 rounded-md h-[2rem] text-center"
                                value={
                                  infoNguoiNhan === null
                                    ? 0
                                    : infoNguoiNhan.score
                                }
                                onChange={(e: any) => {
                                  _onChangeScoreNguoiNhan(e);
                                }}
                              >
                                <option value={0} disabled>
                                  --- Chọn loại đánh giá ---
                                </option>
                                {scoreList &&
                                  scoreList.map((item: any, index: any) => (
                                    <option key={index} value={item?.ID}>
                                      {item?.Name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <textarea
                              className="w-full h-[4rem] rounded-md p-2 border-2 border-slate-200"
                              placeholder="Nhập nhận xét"
                              value={notes}
                              onChange={(e: any) => {
                                e.preventDefault();
                                setNotes(e.target.value);
                              }}
                            />
                            <div className="flex flex-col w-full h-max space-y-1">
                              {infoNguoiNhan.score !== 0 && (
                                <p className="font-bold pt-3">
                                  <span className="text-red-500 underline">
                                    Kết luận:
                                  </span>
                                  <span className="">
                                    &nbsp;{infoNguoiNhan.name}
                                    &nbsp;thực hiện công việc&nbsp;"
                                    {taskName}"&nbsp;được&nbsp;
                                    {task.TaskReviewerName}
                                    &nbsp;đánh giá&nbsp;
                                    <span
                                      className={`${infoNguoiNhan.score === 1 ||
                                        infoNguoiNhan.score === 2
                                        ? "text-green-500"
                                        : infoNguoiNhan.score === 3
                                          ? "text-yellow-500"
                                          : "text-red-500"
                                        } font-bold`}
                                    >

                                      {scoreListAll !== null &&
                                        scoreListAll.find((x: any) => infoNguoiNhan.score == x.ID)?.Name?.toUpperCase()}
                                      .
                                    </span>
                                  </span>
                                </p>
                              )}

                            </div>
                            {bieuQuyetList.length !== 0 && (

                              <div className="space-y-2">
                                <hr />
                                <p>
                                  <b>Chọn ban biểu quyết</b>&nbsp;
                                  <span className="text-red-500">(*)</span>
                                </p>
                                <div className="flex space-x-2 items-top">
                                  <div className="w-full space-y-2">
                                    <label>Chọn nhanh cả phòng</label>
                                    <Select
                                      className="w-full"
                                      options={phongBanIdList}
                                      value={phongBanId}
                                      onChange={_onChangePhongBanId}
                                      // isClearable
                                      placeholder="Chọn nhanh cả phòng"
                                      components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                      }}
                                    />
                                    <label>Chọn từng người biểu quyết</label>
                                    <Select
                                      className="w-full"
                                      options={votingList}
                                      value={single}
                                      onChange={_onChangeSingle}
                                      placeholder="Chọn từng người biểu quyết"
                                      components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                      }}
                                    />
                                    {/* <div className="w-full space-y-1"> */}
                                    <label>Chọn theo phòng ban</label>
                                    {/* <div className="flex w-full space-x-3"> */}
                                    {/* <div className="w-full space-y-1"> */}
                                    <select
                                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2"
                                      value={
                                        selectedPhongBan === -1
                                          ? -1
                                          : selectedPhongBan?.IdPhongBan
                                      }
                                      onChange={(e: any) => _onChangePhongBan(e)}
                                    >
                                      <option value={-1} disabled>
                                        --- Chọn phòng ban ---
                                      </option>
                                      {phongBanList === null ? (
                                        <></>
                                      ) : (
                                        phongBanList.map(
                                          (item: any, index: any) => (
                                            <option
                                              key={index}
                                              value={item?.IdPhongBan}
                                            >
                                              {item?.TenPhongBan}
                                            </option>
                                          )
                                        )
                                      )}
                                    </select>
                                    {/* </div> */}

                                    <Select
                                      className="w-full"
                                      options={selectedNguoiNhanList}
                                      value={single}
                                      onChange={_onChangeSingle}
                                      placeholder="Chọn nhân viên"
                                      components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                      }}

                                    />

                                    {/* </div> */}
                                    {/* </div> */}
                                  </div>

                                  <div className="w-full">
                                    <Select
                                      className="w-full"

                                      // options={votingList}
                                      isMulti
                                      value={voting}
                                      onChange={_onChangeVoting}
                                      isClearable
                                      placeholder="Danh sách biểu quyết"
                                      components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                        NoOptionsMessage: () => null,
                                      }}
                                      styles={{
                                        control: (baseStyles, state) => (console.log(state.getClassNames),
                                        {
                                          ...baseStyles,
                                          whiteSpace: 'normal'

                                        }),
                                      }}
                                    />
                                  </div>
                                </div>

                                <p>
                                  Chọn thời hạn biểu quyết&nbsp;
                                  <span className="text-red-500">(*)</span>
                                </p>
                                <input
                                  className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                                  type="datetime-local"
                                  value={votingTime}
                                  onChange={(e: any) => {
                                    setVotingTime(e.target.value);
                                  }}
                                />
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>


                  </div>
                  {infoNguoiPhoiHop !== null &&
                    infoNguoiPhoiHop.length !== 0 && (
                      <div className="flex flex-col w-full h-max space-y-1">
                        <table>
                          <thead className="text-white font-semibold text-center w-full h-[2rem] border-solid border-black border-[2px]">
                            <tr>
                              <th className="border-l-[1px] border-slate-300 ">
                                Thông tin đánh giá người phối hợp
                              </th>
                            </tr>
                          </thead>
                          <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                            {infoNguoiPhoiHop.map((item: any, index: any) => (
                              <tr key={index} className="border-solid border-black border-[2px]">
                                <td className="border-l-[1px] border-slate-300 border-[1px] space-y-1">
                                  <div className="flex flex-col w-full h-max z-50">
                                    <p className="font-semibold">
                                      {index + 1}. {item?.name}
                                    </p>
                                    <Select
                                      options={scoreList_For_Supporter}
                                      placeholder='--- Chọn loại đánh giá ---'
                                      formatGroupLabel={formatGroupLabel}
                                      onChange={(e: any) => {
                                        _onChangeScorePhoiHop(item?.id, e);
                                      }}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          textAlign: 'center',
                                        }),
                                      }}
                                    />
                                  </div>
                                  <div className="flex flex-col w-full h-max space-y-1">
                                    <textarea
                                      className="w-full h-[4rem] rounded-md p-2 border-2 border-slate-200"
                                      placeholder="Nhập nhận xét"
                                      value={item?.note}
                                      onChange={(e: any) => {
                                        e.preventDefault();
                                        _onChangeNotePhoiHop(item?.id, e);
                                      }}
                                    />
                                    <div className="flex flex-col w-full h-max space-y-1">
                                      {item?.score !== 0 && (
                                        <p className=" font-bold pt-3">
                                          <span className="text-red-500 underline">
                                            Kết luận:
                                          </span>
                                          <span className="">
                                            &nbsp;{item?.name}
                                            &nbsp;được&nbsp;
                                            {task.TaskReviewerName}
                                            &nbsp;đánh giá phối hợp&nbsp;
                                            <span
                                              className={`${item?.score === 1 ||
                                                item?.score === 2
                                                ? "text-green-500"
                                                : item?.score === 3
                                                  ? "text-yellow-500"
                                                  : "text-red-500"
                                                } font-bold`}
                                            >
                                              {scoreListAll !== null &&
                                                scoreListAll.find((x: any) => item?.score == x.ID)?.Name?.toUpperCase()}
                                              .
                                            </span>
                                          </span>
                                        </p>
                                      )}
                                      {item?.isVote && (
                                        <div className="space-y-2">
                                          <hr />
                                          <p>
                                            <b>Chọn ban biểu quyết</b>&nbsp;
                                            <span className="text-red-500">(*)</span>
                                          </p>
                                          <div className="flex space-x-2 items-top">
                                            <div className="w-full space-y-2">
                                              <label>Chọn nhanh cả phòng</label>
                                              <Select
                                                className="w-full"
                                                options={phongBanIdList}
                                                value={phongBanId}
                                                onChange={(e: any) => _onChangePhongBanId_Supporter(e, item?.id)}
                                                // isClearable
                                                placeholder="Chọn nhanh cả phòng"
                                                components={{
                                                  DropdownIndicator: () => null,
                                                  IndicatorSeparator: () => null,
                                                }}
                                              />
                                              <label>Chọn từng người biểu quyết</label>
                                              <Select
                                                className="w-full"
                                                options={votingList}
                                                value={single}
                                                onChange={(e: any) => _onChangeSingle_Supporter(e, item.id)}
                                                placeholder="Chọn từng người biểu quyết"
                                                components={{
                                                  DropdownIndicator: () => null,
                                                  IndicatorSeparator: () => null,
                                                }}
                                              />
                                              {/* <div className="w-full space-y-1"> */}
                                              <label>Chọn theo phòng ban</label>
                                              {/* <div className="flex w-full space-x-3"> */}
                                              {/* <div className="w-full space-y-1"> */}
                                              <select
                                                className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2"
                                                value={
                                                  selectedPhongBan === -1
                                                    ? -1
                                                    : selectedPhongBan?.IdPhongBan
                                                }
                                                onChange={(e: any) => _onChangePhongBan(e)}
                                              >
                                                <option value={-1} disabled>
                                                  --- Chọn phòng ban ---
                                                </option>
                                                {phongBanList === null ? (
                                                  <></>
                                                ) : (
                                                  phongBanList.map(
                                                    (item: any, index: any) => (
                                                      <option
                                                        key={index}
                                                        value={item?.IdPhongBan}
                                                      >
                                                        {item?.TenPhongBan}
                                                      </option>
                                                    )
                                                  )
                                                )}
                                              </select>
                                              {/* </div> */}
                                              <Select
                                                className="w-full"
                                                options={selectedNguoiNhanList}
                                                value={single}
                                                onChange={(e: any) => { _onChangeSingle_Supporter(e, item.id) }}
                                                placeholder="Chọn nhân viên"
                                                components={{
                                                  DropdownIndicator: () => null,
                                                  IndicatorSeparator: () => null,
                                                }}
                                              />
                                              {/* </div>
                                              </div> */}
                                            </div>

                                            <div className="w-full">
                                              <Select
                                                className="w-full"
                                                // options={votingList}
                                                isMulti
                                                value={item?.userVoteId_Supporter}
                                                onChange={(e: any) => _onChangeVoting_Supporter(e, item?.id)}
                                                isClearable
                                                placeholder="Danh sách biểu quyết"
                                                components={{
                                                  DropdownIndicator: () => null,
                                                  IndicatorSeparator: () => null,
                                                  NoOptionsMessage: () => null,
                                                }}
                                                styles={{
                                                  control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    textAlign: 'center',
                                                  }),
                                                }}
                                              />
                                            </div>
                                          </div>

                                          <p>
                                            Chọn thời hạn biểu quyết&nbsp;
                                            <span className="text-red-500">(*)</span>
                                          </p>
                                          <input
                                            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                                            type="datetime-local"
                                            value={item?.votingTime_Supporter}
                                            onChange={(e: any) => {
                                              _onChangeVotingTimeSupporter(e, item.id);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>

                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}


                </div>
              </div>
            </div>
          </div>
        )}

        {/* footer */}
        <div className="flex h-[3rem] bg-slate-100 rounded-b-md items-center px-4 text-sm">
          {_onShowButton() === "Biểu quyết" ? (
            <div className="flex w-full h-max items-center justify-end space-x-3">
              {(notes === "" ||
                isLoading === true ||
                _onCheckScore() === false ||
                _onCheckVoting() === false) && (
                  <p className="text-xs font-semibold text-red-500">
                    Bạn chưa nhập đủ các mục yêu cầu
                  </p>
                )}
              <button
                className={`
          flex items-center space-x-1 w-fit h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md
          ${notes === "" ||
                    isLoading === true ||
                    _onCheckScore() === false ||
                    _onCheckVoting() === false
                    ? "bg-slate-400"
                    : "bg-green-500 hover:bg-green-400"
                  }
        `}
                disabled={
                  notes === "" ||
                    isLoading === true ||
                    _onCheckScore() === false ||
                    _onCheckVoting() === false
                    ? true
                    : false
                }
                onClick={_onClickSubmit}
              >
                <ExitToAppTwoToneIcon fontSize="small" />
                <p className="">Chuyển biểu quyết</p>
              </button>
            </div>
          ) : _onShowButton() === "Chấp nhận kết quả" ? (
            <div className="flex w-full h-max items-center justify-end space-x-3">
              {(isLoading === true || _onCheckScore() === false) && (
                <p className="text-xs font-semibold text-red-500">
                  Bạn chưa nhập đủ các mục yêu cầu
                </p>
              )}
              <button
                className={`
          flex items-center space-x-1 w-fit h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md
          ${isLoading === true || _onCheckScore() === false
                    ? "bg-slate-400"
                    : "bg-yellow-500"
                  }
        `}
                disabled={
                  isLoading === true || _onCheckScore() === false ? true : false
                }
                onClick={_onClickSubmit}
              >
                <ExitToAppTwoToneIcon fontSize="small" />
                <p className="">Chấp nhận kết quả</p>
              </button>
            </div>
          ) : _onShowButton() === "Trả về" ? (
            <div className="flex w-full h-max items-center justify-end space-x-3">
              {(notes === "" ||
                isLoading === true ||
                _onCheckScore() === false) && (
                  <p className="text-xs font-semibold text-red-500">
                    Bạn chưa nhập đủ các mục yêu cầu
                  </p>
                )}
              <button
                className={`
          flex items-center space-x-1 w-fit h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md
          ${notes === "" || isLoading === true || _onCheckScore() === false
                    ? "bg-slate-400"
                    : "bg-red-500 hover:bg-red-400"
                  }
        `}
                disabled={
                  notes === "" ||
                    isLoading === true ||
                    _onCheckScore() === false
                    ? true
                    : false
                }
                onClick={_onClickSubmit}
              >
                <ExitToAppTwoToneIcon fontSize="small" />
                <p className="">Bị kém trả về</p>
              </button>
            </div>
          ) : (
            <div className="flex w-full h-max items-center justify-end space-x-3">
              <button
                className="
      flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-400 text-white rounded-md
      hover:bg-slate-500
    "
                onClick={handleClose}
                disabled={isLoading}
              >
                <ExitToAppTwoToneIcon fontSize="small" />
                <p>Đóng</p>
              </button>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default ComfirmCompleteTask;
