import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  PRIOR_LIST_DROPDOWN,
  CREATE_PROPOSE,
  LOAD_SEP,
  PROPOSE_TYPE_LIST,
  USER_READ_ALL,
} from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import AssignmentIcon from "@mui/icons-material/Assignment";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";

import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";

import Checkbox from "@mui/material/Checkbox";
import { onTouchForm } from "../../../funcs/onTochForm";

import SubModal from "../_subModal/_index";
import { toast } from "react-toastify";
import axios from "axios";

const CreatePropose = (props: any) => {
  // reducer
  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  // take props
  const handleClose = props.handleClose;
  const _loadData = props._loadData;

  // state
  const shouldLog = useRef(true);
  const shouldLog3 = useRef(true);
  const urlLink = window.location.pathname;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [userList, setUserList] = useState<any[] | null>(null);
  const [uuTienList, setUuTienList] = useState<any[] | null>(null);

  const [proposeList, setProposeList] = useState<any[] | null>(null);
  const [selectedPropose, setSelectedPropose] = useState<any | null>(-1);

  const [proposeName, setProposeName] = useState<any>("");
  const [content, setContent] = useState<any>("");
  const [selectedUuTien, setSelectedUuTien] = useState<any>(-1);
  const [isUseTime, setIsUseTime] = useState<boolean>(true);
  const [startTime, setStartTime] = useState<any>(null);
  const [endTime, setEndTime] = useState<any>(null);
  const [selectedNguoiNhan, setSelectedNguoiNhan] = useState<any>(-1);

  const [nguoiPhoiHopList, setNguoiPhoiHopList] = useState<any[] | null>(null);
  const [selectedNguoiPhoiHopList, setSelectedNguoiPhoiHopList] =
    useState<any>(null);

  const [selectedNguoiLamViecList, setSelectedNguoiLamViecList] = useState<any>(
    []
  );

  const [isForce, setIsForce] = useState<boolean>(false);

  const shouldLog1 = useRef(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog1.current === true) {
      shouldLog1.current = false;

      const takeData = async () => {
        try {
          await axios
            .get(USER_READ_ALL, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setNguoiPhoiHopList(
                  res.data.Data.filter((item: any) => item.Id !== "/")
                );
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu người phối hợp thất bại");
        }
      };

      takeData();
    }
  }, []);

  // props - states
  const [positionStep, setPositionStep] = useState<number | null>(null);

  // funcs
  const _onCheckNguoiLamViec = (id: any) => {
    const isTrue = selectedNguoiLamViecList?.find(
      (item: any) => item?.Id === id
    );

    if (isTrue === undefined) {
      setSelectedNguoiLamViecList((oldValue: any) => [
        ...oldValue,
        nguoiPhoiHopList?.find((item: any) => item?.Id === id),
      ]);
    } else {
      const tempArray = selectedNguoiLamViecList?.filter(
        (item: any) => item?.Id !== id
      );
      setSelectedNguoiLamViecList(tempArray);
    }
  };

  const _onChangeNguoiNhan = (e: any) => {
    e.preventDefault();
    setSelectedNguoiNhan(
      userList?.find((item: any) => item.Id === e.target.value).Id
    );
  };

  const _onChangePositionStep = (e: any) => {
    e.preventDefault();
    setPositionStep(parseInt(e.target.value));
  };

  const _onChangeProposeName = (e: any) => {
    e.preventDefault();
    setProposeName(e.target.value);
  };
  const _onChangeIsUseTime = (e: any) => {
    // e.preventDefault();
    setIsUseTime(!isUseTime);
  };
  const _onChangeStartTime = (e: any) => {
    setStartTime(e.target.value);
    e.preventDefault();
  };
  const _onChangeEndTime = (e: any) => {
    e.preventDefault();
    setEndTime(e.target.value);
  };

  const _onChangeSelectedUuTien = (e: any) => {
    e.preventDefault();
    setSelectedUuTien(
      uuTienList?.find((item: any) => item.Id === parseInt(e.target.value))
    );
  };
  const _onChangeContent = (e: any) => {
    e.preventDefault();
    setContent(e.target.value);
  };

  const _onChangeIsForce = () => {
    setIsForce((item: any) => !item);
  };

  // submit
  // funcs
  const _onClickSubmit = () => {
    // CHÚ Ý:
    // - Tạo 2 mảng
    // - Lưu
    setIsLoading(true);

    let tempString: string = "";

    if (selectedNguoiLamViecList.length === 0) {
      tempString = "";
    } else {
      selectedNguoiLamViecList.forEach((item: any, index: any) => {
        if (index === selectedNguoiLamViecList.length - 1) {
          tempString += `${item.Id.toString()}`;
        } else {
          tempString += `${item.Id.toString()},`;
        }
      });
    }

    const body = {
      Name: proposeName,
      Content: content,
      PriorityLevelID: selectedUuTien?.Id,
      IsUseTime: isUseTime,
      StartTime: startTime,
      EndTime: endTime,
      UserCheck: selectedNguoiNhan,
      ProposeTypeID: selectedPropose.ID,
      ListUserPropose: tempString,
    };

    const takeData = async () => {
      const accessToken = localStorage.getItem("accessToken");

      try {
        await axios
          .post(CREATE_PROPOSE, body, {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain",
            },
          })
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              _loadData();
              setIsLoading(false);
              toast.success("Tạo đề xuất mới thành công.");
              handleClose();
            } else {
              setIsLoading(false);
              toast.error(res.data.Message || "Tạo đề xuất mới thất bại.");
            }
          });
      } catch (error: any) {
        setIsLoading(false);
        toast.error(error);
      }
    };

    takeData();
  };

  // for load proposeType
  const shouldLog5 = useRef(true);

  // load selecting data
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog.current) {
      shouldLog.current = false;

      if (userList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(LOAD_SEP, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.data.StatusCode === 200) {
                  setUserList(res.data.Data);
                } else {
                  toast.error(res.data.Message || "Tải danh sách sếp thất bại");
                }
              });
          } catch (error: any) {
            toast.error(error || "Tải danh sách sếp thất bại");
          }
        };

        takeData();
      }

      if (uuTienList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(PRIOR_LIST_DROPDOWN, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setUuTienList(res.data.Data);
                }
              });
          } catch (error: any) {}
        };

        takeData();
      }
    }
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog5.current === true) {
      shouldLog5.current = false;

      const takeData = async () => {
        try {
          await axios
            .get(PROPOSE_TYPE_LIST, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.data.StatusCode === 200) {
                setProposeList(res.data.Data);
              } else {
                toast.error(res.data.Message);
              }
            });
        } catch (error: any) {
          toast.error("Tải danh sách loại đề xuất thất bại");
        }
      };

      takeData();
    }
  }, []);

  useEffect(() => {}, [isLoading]);

  const _onCheckValid = (): boolean => {
    if (isUseTime === false) {
      return true;
    } else {
      if (startTime === null || endTime === null) {
        return false;
      } else {
        return true;
      }
    }
  };

  // funcs
  const _onChangeNguoiPhoiHop = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  return (
    <div className="flex flex-col bg-white lg:w-[40rem] md:w-[40rem] w-screen h-max rounded-md">
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        positionStep={positionStep}
        _onCheckNguoiPhoiHop={_onCheckNguoiLamViec}
        _onChangePositionStep={_onChangePositionStep}
        selectedNguoiPhoiHopList={nguoiPhoiHopList}
        funcs={funcsSub}
        selectedNguoiLamViecList={selectedNguoiLamViecList}
      />

      {/* header */}
      <div
        className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Tạo đề xuất mới</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>

        {selectedPropose === -1 ? "" : selectedPropose.Id}
      </div>

      {/* content */}
      {userList === null ||
      uuTienList === null ||
      proposeList === null ||
      nguoiPhoiHopList === null ? (
        <div className="flex text-primary justify-center items-center flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
          <LoaderSpinner w={32} h={32} />
          <p>Đang tải biểu mẫu</p>
        </div>
      ) : isLoading === true ? (
        <div className="flex text-primary justify-center items-center flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
          <LoaderSpinner w={32} h={32} />
          <p>Đang tạo đề xuất mới</p>
        </div>
      ) : (
        <div className="flex flex-col lg:w-[40rem] md:w-[40rem] w-screen h-max overflow-auto p-4 text-sm styled-scrollbars">
          <div className="w-full h-max flex justify-between items-center">
            <p className="font-semibold text-red-500">
              Lưu ý: Không thể chỉnh sửa đề xuất đã gửi!
            </p>
            <button
              className="w-max h-max rounded-md pl-2 pr-2 pt-1.5 pb-1.5 bg-slate-300 font-bold hover:bg-slate-200"
              onClick={() => {
                window.location.reload();
              }}
            >
              Tải lại
            </button>
          </div>

          <div className="flex w-full h-max flex-col mt-4 text-sm space-y-4">
            <div className="flex w-full h-max space-x-5">
              <div className="flex flex-col w-full h-max">
                <label>
                  Loại đề xuất &nbsp;
                  <span className="text-red-500">(*)</span>{" "}
                </label>
                <select
                  className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  value={selectedPropose === -1 ? -1 : selectedPropose?.ID}
                  onChange={(e: any) => {
                    e.preventDefault();
                    setSelectedPropose(
                      proposeList?.find(
                        (item: any) => item.ID === e.target.value
                      )
                    );
                  }}
                >
                  <option className="text-gray-300" value={-1} disabled>
                    --- Chọn loại đề xuất ---
                  </option>
                  {proposeList === null ? (
                    <></>
                  ) : (
                    proposeList.map((item: any, index: any) => (
                      <option key={index} value={item.ID}>
                        {item.Name}
                      </option>
                    ))
                  )}
                </select>
              </div>

              <div className="flex flex-col w-full h-max">
                <label>
                  Tên đề xuất &nbsp;
                  <span className="text-red-500">(*)</span>{" "}
                </label>
                <input
                  className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập tên đề xuất"
                  value={proposeName}
                  onChange={(e: any) => _onChangeProposeName(e)}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>
            </div>

            <div className="flex w-full h-max">
              <div className="flex flex-col w-full h-max">
                <label>
                  Nội dung &nbsp;
                  <span className="text-red-500">(*)</span>{" "}
                </label>
                <textarea
                  className="w-full h-[5rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                  placeholder="Nhập nội dung đề xuất"
                  value={content}
                  onChange={(e: any) => _onChangeContent(e)}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />
              </div>
            </div>

            <div className="flex flex-col w-full h-max">
              <label>
                Mức độ&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <select
                className={`
                      w-full h-[2rem] rounded-md pl-2 pr-2 border-2
                      ${selectedUuTien === -1 ? "" : "font-bold"}
                    `}
                value={selectedUuTien === -1 ? -1 : selectedUuTien.Id}
                onChange={(e: any) => _onChangeSelectedUuTien(e)}
                style={{
                  color: `${selectedUuTien === -1 ? "" : selectedUuTien.Color}`,
                  borderColor: `${
                    selectedUuTien === -1 ? "" : selectedUuTien.Color
                  }`,
                }}
              >
                <option className="text-gray-300" value={-1} disabled>
                  --- Chọn mức độ ---
                </option>
                {uuTienList === null ? (
                  <></>
                ) : (
                  uuTienList.map((item: any, index: any) => (
                    <option key={index} value={item.Id}>
                      {item.PriorityLevelName}
                    </option>
                  ))
                )}
              </select>
            </div>

            <div className="flex flex-col w-full h-max">
              <label>
                Người nhận&nbsp;<span className="text-red-500">(*)</span>
              </label>
              <select
                className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedNguoiNhan === -1 ? "" : "font-bold"}
                  `}
                style={{
                  color: `${
                    selectedNguoiNhan === -1 ? "" : selectedNguoiNhan.ColorText
                  }`,
                  borderColor: `${
                    selectedNguoiNhan === -1 ? "" : selectedNguoiNhan.ColorText
                  }`,
                }}
                value={selectedNguoiNhan === -1 ? -1 : selectedNguoiNhan.Id}
                onChange={(e: any) => _onChangeNguoiNhan(e)}
              >
                <option className="text-gray-300" value={-1} disabled>
                  --- Chọn người nhận ---
                </option>
                {userList === null ? (
                  <></>
                ) : (
                  userList.map((item: any, index: any) => (
                    <option key={index} value={item.Id}>
                      {item.FullName}
                    </option>
                  ))
                )}
              </select>
            </div>

            <div className="flex flex-col w-full h-max">
              <label>Người phối hợp (nếu có)</label>
              <button
                className={`
                    text-white pl-2 pr-2 pt-0.5 pb-0.5 rounded-md
                    text-xs bg-primary hover:bg-sky-400
                  }
                  `}
                onClick={() => _onChangeNguoiPhoiHop("multipleChoicePartner")}
              >
                Chọn
              </button>
              <div className="flex flex-col w-1/2 h-max">
                {selectedNguoiLamViecList === null
                  ? ""
                  : selectedNguoiLamViecList.map((item: any, index: any) => (
                      <>
                        {item.Id === selectedNguoiNhan.Id ? (
                          <></>
                        ) : (
                          <div
                            key={index}
                            className="flex w-full h-max text-primary items-center justify-between"
                          >
                            <div
                              className="flex w-5/6 h-max space-x-1 font-bold"
                              style={{
                                color: `${item.ColorText}`,
                              }}
                            >
                              <PersonOutlineTwoToneIcon fontSize="small" />
                              <p>{item.FullName}</p>
                            </div>
                            <button
                              className="text-red-500 w-1/6 flex justify-end"
                              onClick={() => _onCheckNguoiLamViec(item.Id)}
                            >
                              <DeleteTwoToneIcon fontSize="small" />
                            </button>
                          </div>
                        )}
                      </>
                    ))}
              </div>
            </div>

            <div className="flex w-full">
              <p className="mt-2">Có thời hạn</p>
              <Checkbox checked={isUseTime} onChange={_onChangeIsUseTime} />
            </div>

            {isUseTime == true ? (
              <div className="flex w-full justify-between">
                <div className="w-full h-max">
                  <label>Thời gian bắt đầu</label>
                  <input
                    className="w-2/3 h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    placeholder="Nhập tên công việc"
                    type="datetime-local"
                    value={startTime}
                    onChange={(e: any) => _onChangeStartTime(e)}
                  />
                </div>

                <div className="w-full h-max">
                  <label>Thời gian kết thúc</label>
                  <input
                    className="w-2/3 h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    placeholder="Nhập tên công việc"
                    type="datetime-local"
                    value={endTime}
                    onChange={(e: any) => _onChangeEndTime(e)}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}

      {/* footer */}
      <div
        className={`
        flex w-full h-[3rem] bg-slate-100 rounded-b-md pl-4 pr-4 text-sm space-x-4
        ${
          selectedNguoiNhan === -1 || proposeName === "" || isLoading === true
            ? "justify-between"
            : "justify-end"
        }
        items-center
      `}
      >
        <div
          className={`
          items-center space-x-1 w-max h-max text-white rounded-md
          ${
            proposeName === "" ||
            selectedNguoiNhan === -1 ||
            selectedUuTien === -1 ||
            _onCheckValid() === false ||
            isLoading === true
              ? "flex"
              : "hidden"
          }
        `}
        >
          {/* <p className="text-red-500 font-semibold uppercase text-sm">
            Vui lòng chọn đầy đủ thông tin!
          </p> */}
        </div>

        <div className="flex w-max h-max space-x-3">
          <div className="w-max h-max multiple">
            {/* button options */}
            <button
              className={`flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-primary text-white rounded-md
              ${
                proposeName === "" ||
                selectedNguoiNhan === -1 ||
                selectedUuTien === -1 ||
                _onCheckValid() === false ||
                isLoading === true
                  ? "bg-slate-300"
                  : "bg-primary hover:bg-sky-400"
              }
            `}
              disabled={
                proposeName === "" ||
                selectedNguoiNhan === -1 ||
                selectedUuTien === -1 ||
                selectedPropose === -1 ||
                _onCheckValid() === false
              }
              onClick={_onClickSubmit}
            >
              <AssignmentIcon fontSize="small" />
              <p>Gửi</p>
            </button>
          </div>
          <button
            className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};
export default CreatePropose;
