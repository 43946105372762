import React, { useContext } from "react";

import AddIcon from '@mui/icons-material/Add';

import Title from "../../../../components/title";
import Table from "../../../../components/table";

import { ModalController } from "../../../homeUI/_index";

const ScoreRatingCapacityUI = () => {

  const context = useContext(ModalController);

  const _onClickCreateScoringCapacity = () => {
    context.setFuncs("createTableScoringCapacity");
    context.handleOpen();
  }

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-1.8rem)] p-5 space-y-3">
        <div className="w-full h-[2rem] flex justify-between items-center">
          <input
            className="w-max h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            placeholder="Tìm kiếm đánh giá.. "
          />

          <button className="
              flex pl-2 pr-2 pt-1 pb-1 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
            "
            onClick={_onClickCreateScoringCapacity}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Thêm mới</p>
          </button>
        </div>

        <Table />
      </div>
    </div>
  )
}

export default ScoreRatingCapacityUI;
