import React from "react";

import HomeUI from "../../ui/homeUI/_index";

const AllPage = () => {

  return (
    <HomeUI />
  )
}

export default AllPage