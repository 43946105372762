import axios from "axios";
import { toast } from "react-toastify";


const accessToken = localStorage.getItem("accessToken");

export const header = () => {
    var header = {
        Authorization: "Bearer " + accessToken,
        Accept: "text/plain",
        "Content-Type": "application/json"
    }
    return header
}


export const get = async (api: any) => {
    if (accessToken) {
        try {
            return await axios.get(
                api,
                {
                    headers: header()
                }
            )
                .then((res: any) => {
                    if (res.status === 200) {
                        return res
                    }
                })
        } catch (error: any) {
            toast.error("Tải dữ liệu thất bại");
        }
    } else {

    }
}

export const post = async (api: any, body: any) => {
    if (accessToken) {
        try {
            return await axios.post(
                api,
                body,
                {
                    headers: header()
                }
            )
                .then((res: any) => {

                    if (res.status === 200) {


                        return res
                    }
                })
        } catch (error: any) {
            toast.error("Tải dữ liệu thất bại");
        }
    } else {
    }
}

export const postFromData = async (api: any, body: any) => {

    if (accessToken) {
        try {
            return await axios.post(
                api,
                body,
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                        "Content-Type": "multipart/form-data"
                    }
                }
            )
                .then((res: any) => {
                    if (res.status === 200) {

                        return res
                    }
                })
        } catch (error: any) {
            toast.error("Tải dữ liệu thất bại");
        }
    } else {
    }
}

export const postNotoken = async (api: any, body: any) => {

    try {
        return await axios.post(
            api,
            body,
            {
                headers: {
                    Accept: "text/plain",
                    "Content-Type": "application/json"
                }
            }
        )
            .then((res: any) => {

                if (res.status === 200) {


                    return res
                }
            })
    } catch (error: any) {
        toast.error("Tải dữ liệu thất bại");
    }

}
