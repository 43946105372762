import React, { useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ModalController } from "../../homeUI/_index";

import Title from "../../../components/title";
import Table from "../../../components/table";

import { GROUP_READ_ALL } from "../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";

import { takePermission } from "../../../funcs";

import {
  ONLOAD_GROUPLIST,
  ONLOAD_GROUPLIST_DROPDOWN,
} from "../../../redux/reducers/userReducer/_index";
import AddIcon from "@mui/icons-material/Add";

const UserListUI = () => {
  const data = useSelector((state: any) => state.userReducer);
  const menu = takePermission(5);

  const context = useContext(ModalController);
  const dispatch = useDispatch();
  const shouldLog = useRef(true);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog.current) {
      shouldLog.current = false;

      try {
        const takeData = async () => {
          await axios
            .get(GROUP_READ_ALL, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_GROUPLIST(res.data.Data));
              }
            });
        };

        takeData();
      } catch (error: any) {
        toast.error("Tải dữ liệu thất bại");
      }
    }
  });

  const _onClickCreateUser = () => {
    context.setFuncs("createUser");
    context.handleOpen();
  };

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-1.8rem)] text-sm pl-5 pr-5 pt-3 pb-3 space-y-3">
        <div className="flex w-full h-max justify-between">
          <input
            className="w-max h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            placeholder="Nhập nội dung cần tìm"
          />

          <button
            className={`
              pl-2 pr-2 pt-1 pb-1 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
              ${menu.PerInsert === true ? "flex" : "hidden"}
            `}
            onClick={_onClickCreateUser}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Thêm mới</p>
          </button>
        </div>

        <Table />
      </div>
    </div>
  );
};

export default UserListUI;
