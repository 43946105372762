import { useContext, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ONCHAGE_FILTER_STATUS } from "../../../redux/reducers/workingReducer/_index";

import { ModalController } from "../../homeUI/_index";
import { onTouchForm } from "../../../funcs/onTochForm";

import ReplayIcon from "@mui/icons-material/Replay";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import ModalBase from "../../../components/modal/_index";
import Select from "react-select";

const FilterProposeWeb = (props: any) => {
  const urlLink = window.location.pathname;
  const _loadData = props._loadData;

  const [openSub, setOpenSub] = useState(false);
  const handleCloseSub = () => setOpenSub(false);
  const handleOpenSub = () => setOpenSub(true);
  const [funcsSub, setFuncsSub] = useState<string>("");

  // redux
  const dispatch = useDispatch();
  const workingData = useSelector((state: any) => state.workingReducer);

  const context = useContext(ModalController);

  const _onClickCreatePropose = () => {
    context.handleOpen();
    context.setFuncs("createPropose");
  };

  const onOpenSub = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  let renameObjectKey = (object: any) => {
    object.FullName && (object.label = object.FullName);

    object.FullName && (object.value = object.FullName);

    object.PriorityLevelName && (object.label = object.PriorityLevelName);

    object.PriorityLevelName && (object.value = object.PriorityLevelName);

    object.label && (object.value = object.label);

    object.Name && (object.label = object.Name);
  };

  // take props
  const statusList = props.statusList;
  const setStatus = props.setStatus;
  const proposeTypeList = props.proposeTypeList;

  proposeTypeList.map((item: any) => renameObjectKey(item));

  const priorList = props.priorList;
  priorList.map((item: any) => renameObjectKey(item));

  const senderList = props.senderList;
  senderList.map((item: any) => renameObjectKey(item));

  const receiverList = props.receiverList;
  receiverList.map((item: any) => renameObjectKey(item));

  const name = props.name;
  const setName = props.setName;
  const proposeTypeID = props.proposeTypeID;
  const setProposeTypeID = props.setProposeTypeID;
  const priorId = props.priorId;
  const setPriorId = props.setPriorId;
  const userAdd = props.userAdd;
  const setUserAdd = props.setUserAdd;

  var userCheck = props.userCheck;
  var setUserCheck = props.setUserCheck;

  const dateAdd_From = props.dateAdd_From;
  const setDateAdd_From = props.setDateAdd_From;
  const dateAdd_To = props.dateAdd_To;
  const setDateAdd_To = props.setDateAdd_To;

  function handleSelectStatus(data: any) {
    setStatus(data ? data.Id : "");
  }

  function handleSelectProposeType(data: any) {
    setProposeTypeID(data ? data.ID : "");
  }

  function handleSelectPrior(data: any) {
    setPriorId(data ? data.Id : "");
  }

  function handleSelectReceiver(data: any) {
    setUserCheck(data ? data.Id : "");
  }

  function handleSelectSender(data: any) {
    setUserAdd(data ? data.Id : "");
  }

  return (
    <div className="w-full h-max">
      <ModalBase
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        _loadData={_loadData}
      />
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_loadData}
        >
          <SearchIcon fontSize="small" />
          <p className="hidden lg:flex md:flex">Tìm kiếm</p>
        </button>

        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
          <p className="hidden lg:flex md:flex">Tải lại</p>
        </button>
        {urlLink !== "/propose/receiver/list" ? (
          <button
            className="flex h-[1.9rem] pl-2 pr-2 pt-1 pb-1 bg-green-500 rounded-md text-white hover:bg-green-400 items-center "
            onClick={() => onOpenSub("createPropose")}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex md:flex">Thêm mới</p>
          </button>
        ) : null}
      </div>

      <div className="flex flex-col py-3">
        <div className="flex">
          <div className="ten-de-xuat flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label>Tên đề xuất</label>
            <input
              className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Nhập tên đề xuất"
              value={name}
              onChange={(e: any) => {
                e.preventDefault();
                setName(e.target.value);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
          <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label>Trạng thái</label>
            <Select
              options={statusList}
              onChange={handleSelectStatus}
              isClearable
              placeholder="Chọn trạng thái"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>

          <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label>Loại đề xuất</label>
            {/* <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={proposeTypeID}
              onChange={(e: any) => {
                e.preventDefault();

                if (e.target.value === "-1") {
                  setProposeTypeID("");
                } else {
                  setProposeTypeID(
                    proposeTypeList?.find(
                      (item: any) => item.ID === e.target.value
                    ).ID
                  );
                }
              }}
              placeholder="Chọn loại đề xuất"
            >
              <option className="text-gray-300" value={-1}>
                --- Chọn loại đề xuất ---
              </option>

              {proposeTypeList === null
                ? ""
                : proposeTypeList.map((item: any, index: any) => (
                    <option key={index} value={item.ID}>
                      {item.Name}
                    </option>
                  ))}
            </select> */}
            <Select
              options={proposeTypeList}
              onChange={handleSelectProposeType}
              isClearable
              placeholder="Chọn loại đề xuất"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="muc-do flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label>Mức độ</label>
            {/* <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={priorId}
              onChange={(e: any) => {
                e.preventDefault();

                if (e.target.value === "-1") {
                  setPriorId(0);
                } else {
                  setPriorId(
                    priorList?.find(
                      (item: any) => item.Id === parseInt(e.target.value)
                    ).Id
                  );
                }
              }}
            >
              <option className="text-gray-300" value={-1}>
                --- Chọn mức độ ---
              </option>
              {priorList &&
                priorList.map((item: any, index: any) => (
                  <option key={index} value={item.Id}>
                    {item.PriorityLevelName}
                  </option>
                ))}
            </select> */}
            <Select
              options={priorList}
              onChange={handleSelectPrior}
              isClearable
              placeholder="Chọn mức độ"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
        </div>
        <div className="flex pt-2">
          <div className="nguoi-gui flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label>Người đề xuất</label>
            {/* <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={userAdd}
              onChange={(e: any) => {
                e.preventDefault();

                if (e.target.value === "-1") {
                  setUserAdd("");
                } else {
                  setUserAdd(e.target.value);
                }
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            >
              <option className="text-gray-300" value={-1}>
                --- Chọn người đề xuất ---
              </option>
              {senderList === null ? (
                <></>
              ) : (
                senderList.map((item: any, index: any) => (
                  <option key={item.Id} value={item.Id}>
                    {item.FullName}
                  </option>
                ))
              )}
            </select> */}
            <Select
              options={senderList}
              onChange={handleSelectSender}
              isClearable
              placeholder="Chọn người đề xuất"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="nguoi-nhan flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label>Người nhận</label>

            {/* <select
              className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              value={userCheck}
              onChange={(e: any) => {
                e.preventDefault();

                if (e.target.value === "-1") {
                  setUserCheck("");
                } else {
                  setUserCheck(e.target.value);
                }
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            >
              <option className="text-gray-300" value={-1}>
                --- Chọn người nhận ---
              </option>
              {receiverList === null ? (
                <></>
              ) : (
                receiverList.map((item: any, index: any) => (
                  <option key={index} value={item.Id}>
                    {item.FullName}
                  </option>
                ))
              )}
            </select> */}
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={receiverList}
              onChange={handleSelectReceiver}
              isClearable
              isSearchable
              placeholder="Chọn người nhận"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="ngay-gui-from flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label>Ngày gửi (Từ ngày)</label>
            <input
              className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Ngày gửi (Từ ngày)"
              type="datetime-local"
              value={dateAdd_From}
              onChange={(e: any) => {
                e.preventDefault();
                setDateAdd_From(e.target.value);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
            <input type="date" id="hidden-input" style={{ display: "none" }} />
          </div>
          <div className="ngay-gui-to flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label>Ngày gửi (Đến ngày)</label>
            <input
              className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Ngày gửi (Đến ngày)"
              type="datetime-local"
              value={dateAdd_To}
              onChange={(e: any) => {
                e.preventDefault();
                setDateAdd_To(e.target.value);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterProposeWeb;
