export const _onClickFuncs = (id: string) => {
  document.getElementById(id)?.classList.toggle("show2");
}

export const _onClickOther = () => {
  window.onclick = function(event: any) {
    if (!event.target.matches('.dropbtn2')) {
      var dropdowns = document.getElementsByClassName("dropdown-content2");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show2')) {
          openDropdown.classList.remove('show2');
        }
      }
    }
  }
}