import React, { useState, useContext, useEffect } from "react";

import { NGUOI_GIAO_VIEC_CHUYEN_NGUOI_DANH_GIA, LOAD_SEP } from "../../../apis/_index";
import axios from "axios";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import LoaderSpinner from "../../loader-spinner/_index";
import { ModalController } from "../../../ui/homeUI/_index";
import ModalBase from "../../../components/modal/_index";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const ChangeTaskReviewer = (props: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const taskId = props.taskId;

  const handleClose = props.handleClose;
  const context = useContext(ModalController);
  const _loadData = props._loadData;
  const [userList, setUserList] = useState<any[] | null>(null);
  const [taskReviewerId, setTaskReviewerId] = useState<any>(null);
  const [isloading, setIsLoading] = useState(false);
  const appData = useSelector((state: any) => state.appReducer);
  useEffect(() => {
    const takeData = async () => {
      try {
        await axios
          .get(LOAD_SEP, {
            headers: {
              Accept: "text/plain",
              Authorization: "Bearer " + accessToken,
            },
          })
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              setUserList(res.data.Data);
            } else {
              toast.error(res.data.Message || "Tải danh sách sếp thất bại");
            }
          });
      } catch (error: any) {
        toast.error(error || "Tải danh sách sếp thất bại");
      }
    };

    takeData();
  }, []);

  const _onChange_TaskReviewer = (e: any) => {
    const taskReviewerId = e?.target?.value;
    setTaskReviewerId(taskReviewerId)
  }

  const _onClickSubmit = async () => {
    const body = {
      TaskID: taskId,
      TaskRiviewer: taskReviewerId
    };

    setIsLoading(true);

    try {
      await axios
        .post(NGUOI_GIAO_VIEC_CHUYEN_NGUOI_DANH_GIA, body, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        })
        .then((res: any) => {

          if (res?.data?.StatusCode === 200) {

            toast.success(res?.data?.Message || "Chuyển người đánh giá thành công");

            setIsLoading(false);
            handleClose();
            context.handleClose();
            // _loadData();
            window.location.reload()
          } else {
            toast.error(res?.data?.Message || "Chuyển người đánh giá thất bại");
          }
        });
    } catch (error: any) {
      toast.error(error || "Chuyển người đánh giá thất bại");
    }
  };

  return (
    <div className="flex flex-col bg-white lg:w-[34rem] w-screen h-full rounded-md">
      {/* header */}
      <ModalBase _loadData={_loadData} />
      <div
        className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Chuyển người đánh giá công việc</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>
      {/* content */}
      <div className="relative flex flex-col w-full h-full overflow-auto p-4 text-sm styled-scrollbars space-y-2">
        <div className="flex items-center">
          <label className="w-2/4">Chọn người đánh giá</label>
          <select
            className="w-full border-2 border-slate-200 rounded-md h-[2rem] text-center"
            value={
              taskReviewerId ? taskReviewerId : 0
            }
            onChange={(e: any) => {
              _onChange_TaskReviewer(e);
            }}
          >
            <option value={0} disabled>
              --- Chọn người đánh giá ---
            </option>
            {userList &&
              userList.filter((x: any) => x.Id.trim().toUpperCase() != appData?.userData?.Id?.trim().toUpperCase()).map((item: any, index: any) => (
                <option key={index} value={item.Id}>
                  {item.FullName}
                </option>
              ))}
          </select>
        </div>
      </div>
      {/* footer */}
      <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-end items-center pl-4 pr-4 text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          {isloading ? (
            <LoaderSpinner h={25} w={25} />
          ) : (
            <button
              className={`flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md
            bg-green-500 hover:bg-green-400`}
              onClick={_onClickSubmit}
            >
              <p>Xác nhận</p>
            </button>
          )}

          <button
            className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeTaskReviewer;
