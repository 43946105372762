import React, { useContext, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ONLOAD_USERLIST } from "../../../../redux/reducers/settingReducer/_index";

import AddIcon from "@mui/icons-material/Add";

import { takePermission } from "../../../../funcs";

import Title from "../../../../components/title";
import Table from "../../../../components/table";

import { ModalController } from "../../../homeUI/_index";
import { USER_READ_ALL } from "../../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";

const ListOfEmployeeUI = () => {
  const menu = takePermission(10);

  const context = useContext(ModalController);
  const dispatch = useDispatch();
  const shouldLog = useRef(true);

  const _onClickCreateListOfEmployee = (id: any) => {
    context.setFuncs("createListOfEmployee");
    context.setTaskId(id);
    context.handleOpen();
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog.current) {
      shouldLog.current = false;

      try {
        const takeData = async () => {
          await axios
            .get(USER_READ_ALL, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_USERLIST(res.data.Data));
              }
            });
        };

        takeData();
      } catch (error: any) {
        toast.error("Tải dữ liệu thất bại.");
      }
    }
  });

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-1.8rem)] p-5 space-y-3">
        <div className="w-full h-[2rem] flex justify-between items-center">
          <input
            className="w-max h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            placeholder="Tìm kiếm nhân sự.."
          />

          <button
            className={`
              pl-2 pr-2 pt-1 pb-1 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
              ${menu.PerInsert === true ? "flex" : "hidden"}
            `}
            onClick={_onClickCreateListOfEmployee}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Thêm mới</p>
          </button>
        </div>

        <Table />
      </div>
    </div>
  );
};

export default ListOfEmployeeUI;
