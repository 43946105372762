import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  ONCHANGE_PAGINATION_TASKLIST,
  ONCHANGE_CHOOSEPAGE,
  ONCHANGE_VOTING_LIST,
  ONFILTER_DATA,
} from "../../../redux/reducers/workingReducer/_index";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { ModalController } from "../../../ui/homeUI/_index";
import Pagination from "@mui/material/Pagination";

import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";

import LoaderSpinner from "../../loader-spinner/_index";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import PanToolAltTwoToneIcon from "@mui/icons-material/PanToolAltTwoTone";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import axios from "axios";
import { Checkcolor } from "../../../funcs/checkColor";
import { url } from "inspector";

import { VotingListRequestWithParams } from "../../../funcs/_votingFuncs";

const VotingWork = () => {
  _onClickOther();
  const urlLink = window.location.pathname;
  // redux
  const settingData = useSelector((state: any) => state.settingReducer);
  const userData = useSelector((state: any) => state.userReducer);
  const appData = useSelector((state: any) => state.appReducer);
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  };

  // onMount
  useEffect(() => {
    if (urlLink === "/voting/voting_wait") {
      VotingListRequestWithParams(workingData.pageCurrent, 10, function (res: any) {
        dispatch(ONCHANGE_VOTING_LIST(res.data.Data));
      }, workingData.searchFilter.taskName, workingData.searchFilter.selectedReceiver);
    } else {
    }
  }, [workingData.pageCurrent, workingData.paginateTaskList])

  const _onChangeChoosePage = (e: any) => {
    dispatch(ONCHANGE_CHOOSEPAGE(e.target.value));
  };

  const _onClickViewTaskWithId = (id: any) => {
    context.setFuncs("votingTaskDetail");
    context.setTaskId(id);
    context.handleOpen();
  };
  const _onClickViewVotingResultWithId = (id: any) => {
    context.setFuncs("votingResult");
    context.setTaskId(id);
    context.handleOpen();
  };
  const _onClickVotingTaskWithId = (id: any) => {
    context.setFuncs("votingTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  return (
    <>
      {workingData.votingList === null ? (
        <div className="flex flex-col justify-center items-center w-full h-[calc(100%-10rem)] lg:mt-0 mt-3">
          <LoaderSpinner w={32} h={32} />
          <p className="text-primary">Đang tải dữ liệu</p>
        </div>
      ) : (
        <div className="flex flex-col w-full lg:h-[calc(100%-10rem)] lg:mt-0 pb-10">
          {/* warm */}
          <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 w-full h-max items-end justify-between my-1 space-y-1">
            <div className="font-bold">
              <p>
                Tổng:&nbsp;
                {workingData.votingList?.count} việc
              </p>
              <p>
                Trang hiện tại:&nbsp;
                {workingData.votingList?.data?.length} việc
              </p>
            </div>

            <div className="flex lg:justify-end md:justify-end w-full h-max items-center space-x-1">
              <p>Hiển thị</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={workingData.choosePage}
                onChange={(e: any) => _onChangeChoosePage(e)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>
          </div>

          <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md">
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="hidden lg:table-cell border border-slate-300 ">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>STT</p>
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Tên công việc</p>
                    </div>
                  </th>
                  {/* <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người giao</p>
                    </div>
                  </th> */}
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Trạng thái</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người nhận</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Kết quả đánh giá</p>
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Thời hạn biểu quyết</p>
                    </div>
                  </th>
                  {/* {urlLink === "/voting/voting_wait" && (
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Kết quả biểu quyết</p>
                      </div>
                    </th>
                  )} */}
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center"></div>
                  </th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {workingData.votingList === null ? (
                  <tr></tr>
                ) : (
                  workingData.votingList?.data?.map((item: any, index: any) => (
                    <tr key={index} className="w-max h-[3rem] bg-white">
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        {index +
                          1 +
                          workingData.pageCurrent * workingData.choosePage}
                      </td>
                      <td className="border border-slate-300 space-y-0.5">
                        <p className="hidden lg:flex">{item.TaskName}</p>

                        <div className="lg:hidden">
                          <p className="font-bold">
                            <span>
                              {index +
                                1 +
                                workingData.pageCurrent *
                                workingData.choosePage}
                              .&nbsp;
                            </span>
                            {item.TaskName}
                          </p>
                          {/* <p className="">
                            <span className="text-xs">Người giao: </span>
                            <span className="font-semibold">
                              {item.TaskOwnerName}
                            </span>
                          </p> */}
                          <p className="">
                            <span className="text-xs">Người nhận: </span>
                            <span className="font-semibold">
                              {item.UserIDName}
                            </span>
                          </p>
                          {item.TaskScorecardID === 1 ? (
                            <p>
                              <span className="text-xs">Kết quả đánh giá:</span>
                              &nbsp;
                              <span className="font-semibold">Xuất sắc</span>
                            </p>
                          ) : (
                            item.TaskScorecardID === 2 && (
                              <p>
                                <span className="text-xs">
                                  Kết quả đánh giá:
                                </span>
                                &nbsp;
                                <span className="font-semibold">Tốt</span>
                              </p>
                            )
                          )}
                          {item.VotingTime && (
                            <p>
                              <span className="text-xs">
                                Thời hạn biểu quyết:&nbsp;
                              </span>
                              <span className="italic">
                                {moment(item.VotingTime).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </span>
                            </p>
                          )}
                        </div>
                        <div className="lg:hidden flex justify-end items-center">
                          <div className="dropdown2">
                            {/* button options */}
                            <button
                              className="dropbtn2"
                              onClick={() => _onClickFuncs(index.toString())}
                            >
                              ...
                            </button>
                            <div
                              className="dropdown-content2 text-xs font-semibold"
                              id={index.toString()}
                            >
                              {urlLink === "/voting/voting_wait" && (
                                <button
                                  className="flex w-full h-full"
                                  onClick={() =>
                                    _onClickViewVotingResultWithId(item.Id)
                                  }
                                >
                                  <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    <div>Chi tiết biểu quyết</div>
                                  </div>
                                </button>
                              )}

                              <button
                                className="flex w-full h-full"
                                onClick={() => _onClickViewTaskWithId(item.TaskId)}
                              >
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  <div>Chi tiết công việc</div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>

                      {/* <td className="hidden lg:table-cell text-center border border-slate-300">
                        {item.TaskOwnerName}
                      </td> */}
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        {item.IsCompletedVoting === true ? 'Hoàn thành' : 'Đang biểu quyết'}
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        {item.UserIDName}
                      </td>
                      <td className="hidden lg:table-cell border border-slate-300 text-center">
                        {item.TaskScorecardID === 1 ? (
                          <p>Xuất sắc</p>
                        ) : (
                          item.TaskScorecardID === 2 && <p>Tốt</p>
                        )}
                      </td>
                      <td className="hidden lg:table-cell border border-slate-300 text-center">
                        {item.VotingTime && (
                          <>
                            <p>
                              {moment(item.VotingTime).format("DD/MM/YYYY")}
                            </p>
                            <p>{moment(item.VotingTime).format("HH:mm:ss")}</p>
                          </>
                        )}
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="dropdown">
                          {/* button options */}
                          <button className="dropbtn">
                            <MoreHorizIcon fontSize="small" />
                          </button>

                          {/* options fields */}

                          <div className="dropdown-content text-xs font-semibold">
                            {urlLink === "/voting/voting_work" ? (
                              <></>
                            ) : (
                              <button
                                className="flex w-full h-full"
                                onClick={() =>
                                  _onClickViewVotingResultWithId(item.Id)
                                }
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chi tiết biểu quyết</div>
                                </div>
                              </button>
                            )}

                            <button
                              className="flex w-full h-full"
                              onClick={() => _onClickViewTaskWithId(item.TaskId)}
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                {/* icon */}
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Chi tiết công việc</div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
            <Pagination
              count={
                workingData.paginateTaskList === null
                  ? 0
                  : workingData.paginateTaskList
              }
              page={workingData.pageCurrent + 1}
              variant="outlined"
              color="primary"
              size="small"
              onChange={_onChangePaginate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default VotingWork;
