import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ONCHANGE_DATE, ONCHANGE_SELECTED_BOSS } from "../../redux/reducers/workingReducer/_index";

import { onTouchForm } from "../../funcs/onTochForm";
import Select from "react-select";


const WorkingReceiverTaskWaiting = () => {

  const urlLink = window.location.pathname;

  // redux
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState<string>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 7, 0, 0)
      .toISOString()
      .slice(0, 16)
  );

  const [toDate, setToDate] = useState<string>(
    new Date(new Date().getTime() + new Date().getTimezoneOffset() * -60 * 1000)
      .toISOString()
      .slice(0, 16)
  );

  const tempBossList = workingData.bossList?.map((item: any) => { return { ...item, label: item.FullName, value: item.FullName } })

  return (
    <div className="flex lg:flex-row flex-col w-full h-max">
      <div className="flex lg:flex-row flex-col w-full h-max">
        <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
          <label>Ngày giao (Từ ngày)</label>
          <input
            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            type="datetime-local"
            defaultValue=""
            // value={fromDate}
            onChange={(e: any) => {
              setFromDate(e.target.value);
              dispatch(
                ONCHANGE_DATE({ type: "fromDate", value: e.target.value })
              );
            }}
          // onTouchStart={(e: any) => onTouchForm(e)}
          />
        </div>
        <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
          <label>Ngày giao (Đến ngày)</label>
          <input
            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            type="datetime-local"
            defaultValue=""
            // value={toDate}
            onChange={(e: any) => {
              setToDate(e.target.value);
              dispatch(
                ONCHANGE_DATE({
                  type: "toDate",
                  value: e.target.value || toDate,
                })
              );
            }}
          />
        </div>
        <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
          <label>Thời hạn</label>
          <input
            className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
            placeholder="Thời hạn"
            type="date"
            value={workingData.searchFilter.deadLine}
            onChange={(e: any) =>
              dispatch(
                ONCHANGE_DATE({ type: "deadLine", value: e.target.value })
              )
            }
            onTouchStart={(e: any) => onTouchForm(e)}
          />
        </div>
      </div>

      <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
        <label>Người giao</label>
        <Select
          className="z-[5]"
          options={tempBossList}
          onChange={(data: any) => dispatch(ONCHANGE_SELECTED_BOSS(data))}
          isClearable
          placeholder="--- Chọn người giao ---"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        />
      </div>
    </div>
  );
}

export default WorkingReceiverTaskWaiting