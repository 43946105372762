import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

// components importing
import Title from "../components/title";
import SubmitHeader from "../components/submitHeader";
import ContentTop from "../components/contentTop";
import Table from "../components/table";

import {
  NANG_LUC_NHAN_SU_LIST
} from "../apis/_index";


import { ONCHANGE_REPORT_NANG_LUC_NHAN_SU_NGUOI_GIAO } from "../redux/reducers/reportReducer/_index";

import { readList } from "../funcs/reportNguoiGiaoViec";

const ReportContent = () => {

  // redux
  const data = useSelector((state: any) => state.reportReducer);
  const dispatch = useDispatch();
  
  const shouldLog = useRef(true);

  const shouldLog1 = useRef(true);
  const shouldLog2 = useRef(true);

  const link = window.location.pathname;


  return (
    <div className="flex flex-col w-full h-full">
      <Title />
      <div className="flex flex-col w-full h-[calc(100%-1.8rem)] pl-5 pr-5 text-sm">
        {/* <SubmitHeader /> */}
        <Table />
      </div>
    </div>

  )
}

export default ReportContent;
