import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  ONCHANGE_PAGINATION_TASKLIST,
  ONCHANGE_CHOOSEPAGE,
  ONFILTER_DATA,
} from "../../../redux/reducers/workingReducer/_index";
import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { ModalController } from "../../../ui/homeUI/_index";
import Pagination from "@mui/material/Pagination";

import LoaderSpinner from "../../loader-spinner/_index";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

import { _onClickFuncs, _onClickOther } from "../../../funcs/onClickShowBtn";

import PanToolAltTwoToneIcon from "@mui/icons-material/PanToolAltTwoTone";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import axios from "axios";

import { Checkcolor } from "../../../funcs/checkColor";

const WorkingTaskEndSoonJSX = () => {
  _onClickOther();

  // redux
  const settingData = useSelector((state: any) => state.settingReducer);
  const userData = useSelector((state: any) => state.userReducer);
  const workingData = useSelector((state: any) => state.workingReducer);
  const appData = useSelector((state: any) => state.appReducer);

  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const _onChangePaginate = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(ONCHANGE_PAGINATION_TASKLIST(value));
  };

  const _onChangeChoosePage = (e: any) => {
    dispatch(ONCHANGE_CHOOSEPAGE(e.target.value));
  };

  const _onClickEditTaskWithId = (id: any) => {
    context.setFuncs("readTotalTask_nguoigiao");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickSwitchToBoss = (id: any) => {
    context.setFuncs("switchTaskToBoss");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickGiveTask = (id: any) => {
    context.setFuncs("giveTask");
    context.setTaskId(id);
    context.handleOpen();
  };
  const _onClickDeleteTask = (id: any) => {
    context.setFuncs("deleteTask");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickRemindTaskWithId = (id: any) => {
    context.setFuncs("notiTask_Model");
    context.setTaskId(id);
    context.handleOpen();
  };

  return (
    <>
      {workingData.taskEndSoon === null ? (
        <div className="flex justify-center items-center flex-col w-full h-[calc(100%-10rem)] lg:mt-0 mt-3">
          <LoaderSpinner w={32} h={32} />
          <p className="text-primary">Đang tải dữ liệu</p>
        </div>
      ) : (
        <div className="flex flex-col w-full h-[calc(100%-10rem)] lg:mt-0 pb-10">
          {" "}
          {/* warm */}
          <div className="flex w-full h-[3rem] space-x-1 items-center justify-between">
            <div className="flex w-max h-max">
              <p>Xem</p>
              <select
                className="border-2 border-slate-200 rounded-md"
                value={workingData.choosePage}
                onChange={(e: any) => _onChangeChoosePage(e)}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
              </select>
              <p>dòng</p>
            </div>
          </div>
          <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-5rem)] shadow-md">
            <table>
              <thead className="text-white font-semibold text-center w-full h-[2rem]">
                <tr>
                  <th className="hidden lg:table-cell border border-slate-300 ">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>STT</p>
                      {/* <button className="flex w-max h-max hover:text-slate-200">
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Tên công việc</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskName" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Mức độ</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "priorLevel" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Ngày giao</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "assignmentDate" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Thời hạn</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "deadLine" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                    <div className="w-full h-full flex space-x-1 justify-center items-center">
                      <p>Người nhận</p>
                      {/* <button
                        className="w-max h-max hover:text-slate-200"
                        onClick={() =>
                          dispatch(ONFILTER_DATA({ type: "taskOwner" }))
                        }
                      >
                        <ArrowDropDownIcon fontSize="small" />
                      </button> */}
                    </div>
                  </th>
                  <th className="hidden lg:table-cell border border-slate-300"></th>
                </tr>
              </thead>
              <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                {workingData.taskEndSoon === null ? (
                  <tr></tr>
                ) : (
                  workingData.taskEndSoon.data?.map((item: any, index: any) => (
                    <tr key={index} className="w-max h-[3rem] bg-white">
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        {index +
                          1 +
                          workingData.pageCurrent * workingData.choosePage}
                      </td>
                      <td className="border border-slate-300">
                        <p className="lg:hidden font-bold">
                          <span className="lg:hidden">
                            {index +
                              1 +
                              workingData.pageCurrent * workingData.choosePage}
                            .{" "}
                          </span>
                          {item.TaskName}
                        </p>
                        <p className="hidden lg:flex">{item.TaskName}</p>
                        <div className="lg:hidden w-full h-max flex flex-col justify-center">
                          <p>
                            Từ{" "}
                            <span className="italic">
                              {moment(item.AssignmentDate).format("HH:mm")}{" "}
                              {moment(item.AssignmentDate).format("DD/MM")}
                            </span>{" "}
                            đến{" "}
                            <span className="italic">
                              {moment(item.DeadLine).format("HH:mm")}{" "}
                              {moment(item.DeadLine).format("DD/MM")}
                            </span>
                          </p>
                        </div>
                        <p className="text-amber-500 font-semibold">
                          Đã giao {item.NumberOfWorking} ngày
                        </p>
                        <p className="text-red-500 font-semibold">
                          Còn {item.DayLate} ngày
                        </p>
                        <div className="lg:hidden flex justify-between items-center">
                          <p
                            style={{
                              color: Checkcolor(item.PriorityLevelID, appData),
                            }}
                            className={`font-bold mr-2 ${Checkcolor(
                              item.PriorityLevelID,
                              appData
                            )}`}
                          >
                            {item.PriorityLevelName}
                          </p>
                          <div className="dropdown2">
                            {/* button options */}
                            <button
                              className="dropbtn2"
                              onClick={() => _onClickFuncs(index.toString())}
                            >
                              ...
                            </button>

                            {/* options fields */}
                            <div
                              id={index.toString()}
                              className="dropdown-content2 text-xs font-semibold"
                            >
                              {/* detail */}
                              <button
                                className="flex w-full h-full hover:text-sky-500"
                                onClick={() => _onClickEditTaskWithId(item.ID)}
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1">
                                  {/* icon */}
                                  <DescriptionTwoToneIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Chi tiết</div>
                                </div>
                              </button>

                              <button
                                className="flex w-full h-full hover:text-sky-500"
                                onClick={() =>
                                  _onClickRemindTaskWithId(item.ID)
                                }
                              >
                                {/* detail - contain */}
                                <div className="flex w-full h-full space-x-1">
                                  {/* icon */}
                                  <NotificationsActiveIcon
                                    className="text-sky-600"
                                    sx={{ fontSize: 18 }}
                                    fontSize="small"
                                  />

                                  {/* title */}
                                  <div>Nhắc việc</div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <p
                          style={{
                            color: Checkcolor(item.PriorityLevelID, appData),
                          }}
                          className={`font-bold ${Checkcolor(
                            item.PriorityLevelID,
                            appData
                          )}`}
                        >
                          {item.PriorityLevelName}
                        </p>
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="w-full h-max flex flex-col justify-center items-center">
                          <p>
                            {moment(item.AssignmentDate).format("DD/MM/YYYY")}
                          </p>
                          <p>
                            {moment(item.AssignmentDate).format("HH:mm:ss")}
                          </p>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="w-full h-max flex flex-col justify-center items-center">
                          <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
                          <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                        </div>
                      </td>
                      <td className="hidden lg:table-cell border border-slate-300">
                        {item.RecipientName}
                      </td>
                      <td className="hidden lg:table-cell text-center border border-slate-300">
                        <div className="dropdown">
                          {/* button options */}
                          <button className="dropbtn">
                            <MoreHorizIcon fontSize="small" />
                          </button>

                          {/* options fields */}
                          <div className="dropdown-content text-xs font-semibold">
                            {/* detail */}
                            <button
                              className="flex w-full h-full hover:text-sky-500"
                              onClick={() => _onClickEditTaskWithId(item.ID)}
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1">
                                {/* icon */}
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Chi tiết</div>
                              </div>
                            </button>

                            <button
                              className="flex w-full h-full hover:text-sky-500"
                              onClick={() => _onClickRemindTaskWithId(item.ID)}
                            >
                              {/* detail - contain */}
                              <div className="flex w-full h-full space-x-1">
                                {/* icon */}
                                <NotificationsActiveIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                {/* title */}
                                <div>Nhắc việc</div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
            <Pagination
              count={
                workingData.paginateTaskList === null
                  ? 0
                  : workingData.paginateTaskList
              }
              page={workingData.pageCurrent + 1}
              variant="outlined"
              color="primary"
              size="small"
              onChange={_onChangePaginate}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default WorkingTaskEndSoonJSX;
