import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";

import {
  ONLOAD_SELECTED_RECEIVER,
  ONCHANGE_SELECTED_BOSS,
  ONLOAD_SELECTED_PRIOR,
  ONCHANGE_TASK_NAME,
} from "../../redux/reducers/workingReducer/_index";

import { onTouchForm } from "../../funcs/onTochForm";

// components

const WorkingNewTaskJSX = () => {
  const urlLink = window.location.pathname;
  // change funcs
  const renameObjectKey = (object: any) => {
    object.FullName && (object.label = object.FullName);

    object.FullName && (object.value = object.FullName);

    object.PriorityLevelName && (object.label = object.PriorityLevelName);

    object.PriorityLevelName && (object.value = object.PriorityLevelName);

    object.label && (object.value = object.label);

    object.Name && (object.label = object.Name);
  };

  // --- DON'T DEL
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  //   // object.PriorityLevelName && (object.label = object.PriorityLevelName);
  //   // object.label && (object.value = object.label);
  // };
  // --- END

  const tempReceiverList = workingData.receiverList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });

  const tempPriorList = workingData.priorList?.map((item: any) => {
    return {
      ...item,
      label: item.PriorityLevelName,
      value: item.PriorityLevelName,
    };
  });

  const tempBossList = workingData.bossList?.map((item: any) => {
    return { ...item, label: item.FullName, value: item.FullName };
  });

  return (
    <div className="flex lg:flex-row flex-col w-full h-max">
      <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
        <label>Tên công việc</label>
        <input
          className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
          placeholder="Nhập tên công việc"
          value={workingData.searchFilter.taskName}
          onChange={(e: any) => {
            e.preventDefault();
            dispatch(ONCHANGE_TASK_NAME(e.target.value));
          }}
          onTouchStart={(e: any) => onTouchForm(e)}
        />
      </div>


      <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
        {urlLink === "/working/receiver/task-new" ||
          urlLink === "/working/receiver/task-received" ||
          urlLink === "/working/receiver/task-processing" ||
          urlLink === "/working/receiver/task-delay" ||
          urlLink === "/working/receiver/task-end-today" ||
          urlLink === "/working/receiver/task-end-soon" ||
          urlLink === "/working/receiver/all-task" ? (
          <label>Người nhận</label>
        ) : (
          <label>Người nhận</label>
        )}
        {/* <select
           className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
           value={workingData.searchFilter.selectedReceiver}
           onChange={(e: any) => {
             e.preventDefault();
 
             if (e.target.value === "-1") {
               dispatch(ONLOAD_SELECTED_RECEIVER(parseInt(e.target.value)));
             } else {
               dispatch(ONLOAD_SELECTED_RECEIVER(e.target.value));
             }
           }}
         >
           {urlLink === "/working/receiver/task-new" ||
           urlLink === "/working/receiver/task-received" ||
           urlLink === "/working/receiver/task-processing" ||
           urlLink === "/working/receiver/task-delay" ||
           urlLink === "/working/receiver/task-end-today" ||
           urlLink === "/working/receiver/task-end-soon" ||
           urlLink === "/working/receiver/all-task" ? (
             <option className="text-gray-300" value={-1}>
               --- Chọn người nhận ---
             </option>
           ) : (
             <option className="text-gray-300" value={-1}>
               --- Chọn người nhận ---
             </option>
           )}
           {workingData.receiverList === null
             ? ""
             : workingData.receiverList?.map((item: any, index: any) => (
               <option key={index} value={item.Id}>{item.FullName}</option>
             ))
           }
         </select> */}
        <Select
          className="z-20"
          options={tempReceiverList}
          onChange={(data: any) => dispatch(ONLOAD_SELECTED_RECEIVER(data))}
          isClearable
          placeholder="--- Chọn người nhận ---"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
        />

        {/* <Select
             className="z-[5]"
             options={tempBossList}
             onChange={(data: any) => dispatch(ONCHANGE_SELECTED_BOSS(data))}
             isClearable
             placeholder="--- Chọn người nhận ---"
             components={{
               DropdownIndicator: () => null,
               IndicatorSeparator: () => null,
             }}
           /> */}
      </div>


      {
        urlLink === "/voting/voting_wait" || urlLink === "/voting/voting_work" ? <></>
          : <div className="flex flex-col lg:w-1/3 w-full lg:pr-7 pr-0 h-max">
            <label>Mức độ</label>
            <Select
              className="z-10"
              options={tempPriorList}
              onChange={(data: any) => dispatch(ONLOAD_SELECTED_PRIOR(data))}
              isClearable
              placeholder="--- Chọn mức độ ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
            {/* <select
          className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
          value={workingData.searchFilter.selectedPrior}
          onChange={(e: any) => {
            dispatch(ONLOAD_SELECTED_PRIOR(parseInt(e.target.value)));
          }}
        >
          <option className="text-gray-300" value={-1}>
            --- Chọn mức độ ---
          </option>
          {workingData.priorList === null
            ? ""
            : workingData.priorList.map((item: any, index: any) => (
              <option key={index} value={item.Id}>{item.PriorityLevelName}</option>
            ))
          }
        </select> */}
          </div>
      }

    </div>
  );
};

export default WorkingNewTaskJSX;
