import { get, post } from "../ApiHelper/index";
import {
    ALL_BANGDIEM,
    ONE_BANGDIEM,
} from "../apis/_index";

export const bangdiemnangluc_list = () => {
    return new Promise(async (resolve) => {
        return await get(ALL_BANGDIEM)
            .then((res: any) => {
                resolve({
                    status: true,
                    data: res.data.Data,
                });
            })
            .catch((err: any) => {
                resolve({
                    status: false,
                    err: err,
                });
            });
    });
};
