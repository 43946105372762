import React, { memo, useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  ONCHANGE_NAVBAR_OPTIONS,
  ONCHANGE_NAVBAR_RESPONSIVE,
} from "../../../redux/reducers/_appReducer/_index";

// menu images
import home from "../../../assets/menu/Home.svg"
import report from "../../../assets/menu/BAOCAO.svg"
import setting from "../../../assets/menu/CAIDAT.svg"
import propose from "../../../assets/menu/DEXUAT.svg"
import permission from "../../../assets/menu/PHANQUYEN.svg"
import adding from "../../../assets/menu/THEMVIEC.svg"
import working from "../../../assets/menu/CONGVIEC.svg"

import { ModalController } from "../../homeUI/_index";

// import { takePermission } from "../../../funcs";

// // icon bàn làm viẹc
// import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
// // icon phân quyền
// import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
// // icon thiết lập
// import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
// // icon công việc
// import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
// // icon báo cáo
// import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
// // icon đề xuất
// import LightbulbIcon from "@mui/icons-material/Lightbulb";

// import NoteAddIcon from '@mui/icons-material/NoteAdd';

import LoaderSpinner from "../../../components/loader-spinner/_index";


const Navbar = () => {

  const context = useContext(ModalController)

  const urlLink = window.location.pathname;

  const [isCreateTask, setIsCreateTask] = useState<any | null>(null)

  const navigate = useNavigate()

  const shouldLog = useRef(true);

  // const menu = takePermission(20)

  // redux
  const data = useSelector((state: any) => state.appReducer);
  const dispatch = useDispatch();

  // funcs
  const _onChangeNavbarOptions = (id: string) => {
    dispatch(ONCHANGE_NAVBAR_OPTIONS(id));
  };

  const _onChangeNavbarOptionsResponsive = (id: string) => {
    dispatch(ONCHANGE_NAVBAR_OPTIONS(id));
    dispatch(ONCHANGE_NAVBAR_RESPONSIVE());
  };

  const _onClickCreateNewTask = () => {
    context.setFuncs("createNewTask");
    context.handleOpen();
  };

  const RenderMenuWeb = (props: any) => {
    const title = props.title === "Administrator" ? "Phân quyền" : props.title;
    const id = props.id;
    const jsx = props.jsx;
    const url = props.url;
    const index = props.index;

    return (
      <button
        className={`
        flex flex-col justify-center items-center w-full h-[5rem]
      text-slate-500 border-l-4
      ${data.showingMenu === id.toString()
            ? "border-sky-400 text-sky-400 shadow-md shadow-slate-400 transition-all hover:delay-1 hover:border-sky-600 hover:text-sky-600 hover:shadow-md active:border-sky-600 active:shadow-inner active:shadow-slate-500"
            : "border-slate-100 transition-all hover:delay-1 hover:border-yellow-500  hover:text-yellow-400 hover:shadow-md active:shadow-slate-500 active:text-primary active:shadow-inner"
          }
      `}
        onClick={() => _onChangeNavbarOptions(id.toString())}
      >
        {jsx}
        <p
          className={`${data.showingMenu === id.toString() ? "text-primary" : ""
            } text-sm`}
        >
          {title}
        </p>
      </button>
    );
  };

  const RenderMenuMobile = (props: any) => {
    const title = props.title === "Administrator" ? "Phân quyền" : props.title;
    const id = props.id;
    const jsx = props.jsx;
    const url = props.url;
    const index = props.index;

    return (
      <button
        className={`
      flex flex-col justify-center items-center w-full sm:h-[2.5rem] h-[5rem]
    text-slate-500 border-l-4 
    ${data.showingMenu === id.toString()
            ? "border-sky-400 text-primary shadow-lg  active:border-sky-600 active:shadow-inner active:shadow-slate-500"
            : "border-slate-100  active:border-yellow-500 active:shadow-slate-500 active:text-primary active:shadow-inner"
          }
    `}
        onClick={() => _onChangeNavbarOptionsResponsive(id.toString())}
      >
        {jsx}
      </button>
    );
  };

  useEffect(() => {
    if (data.subMenu.length === 0) {
      // do nothing
    } else {
      if (shouldLog.current === true) {
        shouldLog.current = false

        setIsCreateTask(data.subMenu.find((item: any) => item.FormId === 20)?.PerInsert)
      }
    }
  }, [data.subMenu])

  // tsx
  const NavbarWebList = () => {
    return (
      <>
        <button
          className={`
        flex flex-col justify-center items-center w-full h-[5rem]
      text-slate-500 border-l-4
      ${data.showingMenu === "0"
              ? "border-sky-400 text-sky-400  transition-all hover:delay-1 hover:border-sky-600 hover:text-sky-600 hover:shadow-md active:border-sky-600 active:shadow-inner active:shadow-slate-500 "
              : "border-slate-100 transition-all hover:delay-1 hover:border-yellow-500  hover:text-yellow-400 hover:shadow-md active:shadow-slate-500 active:text-primary active:shadow-inner"
            }
      `
          }
         onClick={() => {
          navigate("/allPage")
         }}
        > <div className="w-full h-full flex flex-col justify-center items-center">
            {/* <HomeRoundedIcon
            className={`${data.showingMenu === "0" ? "text-primary" : ""}`}
            fontSize="large"
          /> */}

            <img
              className="w-[2.3rem] h-[2.3rem]"
              src={home}
              alt=""
            />

            <p
              className={`${data.showingMenu === "0" ? "text-primary" : ""
                } text-sm`}
            >
              Bàn làm việc
            </p>
          </div>
        </button>

        {
          isCreateTask === null ? <></>
            : isCreateTask === undefined || isCreateTask === false ? <></>
              :
              <button
                className={`
                    flex flex-col justify-center items-center w-full h-[5rem]
                  text-slate-500 border-l-4
                  ${data.showingMenu === "0"
                    ? "border-sky-400 text-sky-400 transition-all hover:delay-1 hover:border-sky-600 hover:text-sky-600 hover:shadow-md active:border-sky-600 active:shadow-inner active:shadow-slate-500 "
                    : "border-slate-100 transition-all hover:delay-1 hover:border-yellow-500  hover:text-yellow-400 hover:shadow-md active:shadow-slate-500 active:text-primary active:shadow-inner"
                  }
      `
                }
               onClick={() => _onClickCreateNewTask()}
              > <div className="w-full h-full flex flex-col justify-center items-center">
                  {/* <HomeRoundedIcon
            className={`${data.showingMenu === "0" ? "text-primary" : ""}`}
            fontSize="large"
          /> */}

                  <img
                    className="w-[2.3rem] h-[2.3rem]"
                    src={adding}
                    alt=""
                  />

                  <p
                    className={`${data.showingMenu === "0" ? "text-primary" : ""
                      } text-sm`}
                  >
                    Tạo công việc
                  </p>
                </div>
              </button>
        }

        {data.mainMenu.map((item: any, index: any) => (
          // console.log(data.mainMenu),
          <RenderMenuWeb
            index={index}
            key={index}
            title={item.FormTitle}
            id={item.FormId}
            jsx={
              item.FormId === 1 ? (
                <img
                  className="w-[2.3rem] h-[2.3rem]"
                  src={permission}
                  alt=""
                />
              ) : item.FormId === 2 ? (

                <img
                  className="w-[2.3rem] h-[2.3rem]"
                  src={setting}
                  alt=""
                />
              ) : item.FormId === 3 ? (

                <img
                  className="w-[2.3rem] h-[2.3rem]"
                  src={working}
                  alt=""
                />
              ) : item.FormId === 4 ? (
                <img
                  className="w-[2.3rem] h-[2.3rem]"
                  src={report}
                  alt=""
                />
              ) : item.FormId === 59 ? (
                <img
                  className="w-[2.3rem] h-[2.3rem]"
                  src={propose}
                  alt=""
                />
              ) : (
                ""
              )
            }
            url={
              item.FormId === 1
                ? "user"
                : item.FormId === 2
                  ? "setting"
                  : item.FormId === 3
                    ? "working"
                    : item.FormId === 4
                      ? "report"
                      : item.FormId === 59
                        ? "propose"
                        : ""
            }
          />
        ))}
      </>
    );
  };

  const NavbarMobileList = () => {
    return (
      <div className="w-full h-full flex flex-col ">
        <button
          className={`
          flex flex-col justify-center items-center w-full sm:h-[2.5rem] h-[5rem]
        text-slate-500 border-l-4
        ${data.showingMenu === "0"
              ? "border-sky-400 text-primary"
              : "border-slate-100 active:border-yellow-500 active:shadow-slate-500 active:text-primary active:shadow-inner"
            }
        `}


          onClick={() => {
            navigate("/allPage")
          }}
        ><div className="w-full h-full flex flex-col justify-center items-center">
            {/* <HomeRoundedIcon
              className={`${data.showingMenu === "0" ? "text-primary" : ""}`}
              fontSize="large"
            />  */}
            <img
            
              className="w-[2.3rem] h-[2.3rem] sm:w-[1.7rem] sm:h-[1.7rem] "
              src={home}
              alt=""
            />
          </div>
        </button>

        {
          isCreateTask === null ? <></>
            : isCreateTask === undefined || isCreateTask === false ? <></>
              :
              <button
              className={`
              flex flex-col justify-center items-center w-full sm:h-[2.5rem] h-[5rem]
            text-slate-500 border-l-4
            ${data.showingMenu === "0"
                  ? "border-sky-400 text-primary"
                  : "border-slate-100 active:border-yellow-500 active:shadow-slate-500 active:text-primary active:shadow-inner "
                }
            `}
    
    
            onClick={() => _onClickCreateNewTask()}
            ><div className="w-full h-full flex flex-col justify-center items-center">
                {/* <HomeRoundedIcon
                  className={`${data.showingMenu === "0" ? "text-primary" : ""}`}
                  fontSize="large"
                />  */}
                <img
                  className="w-[2.3rem] h-[2.3rem] sm:w-[1.7rem] sm:h-[1.7rem] "
                  src={adding}
                  alt=""
                />
              </div>
            </button>
        }


        {data.mainMenu.map((item: any, index: any) => (
          <RenderMenuMobile
            index={index}
            key={index}
            title={item.FormTitle}
            id={item.FormId}
            jsx={
              item.FormId === 1 ? (
                <img
                  className="w-[2.3rem] h-[2.3rem] sm:w-[1.7rem] sm:h-[1.7rem]"
                  src={permission}
                  alt=""
                />
              ) : item.FormId === 2 ? (

                <img
                  className="w-[2.3rem] h-[2.3rem] sm:w-[1.7rem] sm:h-[1.7rem]"
                  src={setting}
                  alt=""
                />
              ) : item.FormId === 3 ? (

                <img
                  className="w-[2.3rem] h-[2.3rem] sm:w-[1.7rem] sm:h-[1.7rem]"
                  src={working}
                  alt=""
                />
              ) : item.FormId === 4 ? (
                <img
                  className="w-[2.3rem] h-[2.3rem] sm:w-[1.7rem] sm:h-[1.7rem]"
                  src={report}
                  alt=""
                />
              ) : item.FormId === 59 ? (
                <img
                  className="w-[2.3rem] h-[2.3rem] sm:w-[1.7rem] sm:h-[1.7rem]"
                  src={propose}
                  alt=""
                />
              ) : (
                ""
              )
            }
            url={
              item.FormId === 1
                ? "user"
                : item.FormId === 2
                  ? "setting"
                  : item.FormId === 3
                    ? "working"
                    : item.FormId === 4
                      ? "report"
                      : item.FormId === 59
                        ? "propose"
                        : ""
            }
          />
        ))}

      </div>
    );
  };

  // TODO: responsive
  const NavbarWebJSX = () => {
    return (
      <div className="lg:flex hidden flex-col w-[6rem] h-full bg-navbar border-r-2">
        {data.mainMenu.length === 0 || isCreateTask === null ? (
          <div className="w-full h-full flex flex-col pt-3 items-center">
            <LoaderSpinner h={32} w={32} />
            <p className="text-xs text-primary">Đang tải</p>
          </div>
        ) : (
          <NavbarWebList />
        )}
      </div>
    );
  };

  const NavbarMobileJSX = () => {
    return (
      <div className="lg:hidden flex flex-col w-[3.5rem] h-full bg-navbar border-r-2">
        {data.mainMenu.length === 0 || isCreateTask === null ? (
          <div className="w-full h-full flex flex-col pt-3 items-center">
            <LoaderSpinner h={32} w={32} />
            <p className="text-xs text-primary">Đang tải</p>
          </div>
        ) : (

          <NavbarMobileList />
        )}
      </div>
    );
  };

  return (
    <>
      <NavbarWebJSX />
      <NavbarMobileJSX />
    </>
  );
};

export default memo(Navbar);
