import React, { memo} from "react";
import { takePermission } from "../../../funcs";
import { useSelector, useDispatch } from "react-redux";
import { SYS_DASHBOARD_UPDATE, SYS_PERMISSION_UPDATE, SYS_PERMISSION_LOADFUNC, SYS_DASHBOARD_LOADFUNCS } from "../../../apis/_index";
import axios from "axios";
import { ONLOAD_FUNCTIONLIST } from "../../../redux/reducers/userReducer/_index";
import { toast } from "react-toastify";

import { onTouchForm } from "../../../funcs/onTochForm";

const UserFunctionalList = () => {

    // redux
    const settingData = useSelector((state: any) => state.settingReducer);
    const userData = useSelector((state: any) => state.userReducer);
    const workingData = useSelector((state: any) => state.workingReducer);
    const dispatch = useDispatch();

    const menu = takePermission(6);

    // funcs
    const _onChangeCheckbox = async (e: any, typeCall: string, id: any, value: any) => {

        const choose = userData.functionChoose;
        const FormId = id
        const checked = value === true ? false : true;
        let type: string = ""

        switch (typeCall) {
            case "perActive":
                type = "view";
                break;
            case "perInsert":
                type = "create"
                break;
            case "perEdit":
                type = "update"
                break;
            case "perDelete":
                type = "delete"
                break;
            case "perApprove":
                type = "approve"
                break;

            default:
                break;
        }

        const accessToken = localStorage.getItem("accessToken");
        const API = SYS_PERMISSION_UPDATE(choose, type, FormId, checked);

        try {
            await axios.get(
                API,
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                        Accept: "text/plain"
                    }
                }
            )
                .then((res: any) => {
                    if (res.status === 200) {
                        const API = SYS_PERMISSION_LOADFUNC + userData.functionChoose.toString();

                        const takeData = async () => {
                            try {
                                await axios.get(
                                    API,
                                    {
                                        headers: {
                                            Authorization: "Bearer " + accessToken,
                                            Accept: "text/plain"
                                        }
                                    }
                                )
                                    .then((res: any) => {
                                        if (res.status === 200) {
                                            dispatch(ONLOAD_FUNCTIONLIST(res.data.Data))
                                        }
                                    })
                            } catch (error: any) {
                                toast.error("Tải dữ liệu thất bại.")
                            }
                        }

                        takeData();
                    }
                })
        } catch (error: any) {
            toast.error("Cập nhật thất bại.");
        }
    }

    return (
        <div className="flex flex-col text-sm w-full h-[calc(100%-7rem)] mt-3"> {/* warm */}

            {userData.functionChoose === 0 ? <p className="mt-3 text-slate-500 font-semibold text-lg">VUI LÒNG CHỌN NHÓM QUYỀN</p>
                : <div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
                    <table>
                        <thead className="text-white font-semibold text-center w-full h-[2rem]">
                            <tr>
                                <th className="border-l-[1px] border-slate-300">STT</th>
                                <th className="border-l-[1px] border-slate-300">Nhóm chức năng</th>
                                <th className="border-l-[1px] border-slate-300">Chức năng</th>
                                <th className="border-l-[1px] border-slate-300">Xem</th>
                                <th className="border-l-[1px] border-slate-300">Tạo</th>
                                <th className="border-l-[1px] border-slate-300">Sửa</th>
                                <th className="border-l-[1px] border-slate-300">Xoá</th>
                                <th className="border-l-[1px] border-r-[1px] border-slate-300">Giao việc</th>
                            </tr>
                        </thead>
                        <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                            {userData.functionList === null ? <tr></tr>
                                : userData.functionList.map((item: any, index: any) => (
                                    <tr key={index} className="w-max h-[3rem] bg-white">
                                        <td className="text-center border border-slate-300">{index + 1}</td>
                                        <td className="border border-slate-300">{item.FormGroup}</td>
                                        <td className="border border-slate-300">{item.FormTitle}</td>
                                        <td className="text-center border border-slate-300">
                                            {item.v_Active === true
                                                ? <input
                                                    type="checkbox"
                                                    checked={item.PerActive}
                                                    onChange={(e: any) => _onChangeCheckbox(e, "perActive", item.FormId, item.PerActive)}
                                                    disabled={menu.PerEdit === true ? false : true}
                                                    onTouchStart={(e: any) => onTouchForm(e)}
                                                /> : ""}
                                        </td>
                                        <td className="text-center border border-slate-300">
                                            {item.v_Insert === true
                                                ? <input
                                                    type="checkbox"
                                                    checked={item.PerInsert}
                                                    onChange={(e: any) => _onChangeCheckbox(e, "perInsert", item.FormId, item.PerInsert)}
                                                    disabled={menu.PerEdit === true ? false : true}
                                                    onTouchStart={(e: any) => onTouchForm(e)}
                                                /> : ""}
                                        </td>
                                        <td className="text-center border border-slate-300">
                                            {item.v_Edit === true
                                                ? <input
                                                    type="checkbox"
                                                    checked={item.PerEdit}
                                                    onChange={(e: any) => _onChangeCheckbox(e, "perEdit", item.FormId, item.PerEdit)}
                                                    disabled={menu.PerEdit === true ? false : true}
                                                    onTouchStart={(e: any) => onTouchForm(e)}
                                                /> : ""}
                                        </td>
                                        <td className="text-center border border-slate-300">
                                            {item.v_Delete === true
                                                ? <input
                                                    type="checkbox"
                                                    checked={item.PerDelete}
                                                    onChange={(e: any) => _onChangeCheckbox(e, "perDelete", item.FormId, item.PerDelete)}
                                                    disabled={menu.PerEdit === true ? false : true}
                                                    onTouchStart={(e: any) => onTouchForm(e)}
                                                /> : ""}
                                        </td>
                                        <td className="text-center border border-slate-300">
                                            {item.v_Approve === true
                                                ? <input
                                                    type="checkbox"
                                                    checked={item.PerApprove}
                                                    onChange={(e: any) => _onChangeCheckbox(e, "perApprove", item.FormId, item.PerApprove)}
                                                    disabled={menu.PerEdit === true ? false : true}
                                                    onTouchStart={(e: any) => onTouchForm(e)}
                                                /> : ""}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    )
}

export default memo(UserFunctionalList)