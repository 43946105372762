import React, { memo } from "react";

const LoginLeftSideUI = () => {
  return (
    <>
      <div
        className="
      lg:flex hidden flex-col w-1/2 h-full bg-primary
      justify-center items-center text-7xl text-white
      space-y-3 divide-y relative
    "
      >
        <img src="logo_quochuy.png" style={{ width: "192px" }} alt=""></img>
        {/* <img src="logoclient.png" style={{ width: "192px" }} alt=""></img> */}
        {/* <p>ISOPRO5</p> */}
        {/* <p className="text-4xl">UBND PHƯỜNG 9 - TÂN BÌNH</p> */}
        <p className="text-4xl">PHÒNG QUẢN LÝ ĐÔ THỊ - HÓC MÔN</p>
        {/* <p className="text-4xl">DEMO</p> */}
        <p className="text-4xl">LACOGROUP - LACOTECH</p>
        {/* laco tech */}
        <div className="flex divide-x-0 text-base text-center w-full h-[3rem] bottom-0 m-2 absolute items-center">
          Bản quyền thuộc về LACOTECH - © 2023 LACOGROUP
        </div>
      </div>
    </>
  );
};

export default memo(LoginLeftSideUI);
