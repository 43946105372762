/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useContext } from "react";
import { toast } from "react-toastify";

import { ModalController } from "../../homeUI/_index";

import { USER_READ_ALL_DROPDOWN, TAKE_PARAMETER_LISTKEY_DROPDOWN, REPORT_TASK_TOTAL_LIST } from "../../../apis/_index";
import { LOAD_TASK_FROM_DETAIL_USER } from "../../../apis/_index";
import Divider from '@mui/material/Divider';

import Select from "react-select";

import {
  NGUOI_GIAO_VIEC_LIST_TOTAL,
} from "../../../apis/_index";

import ReplayIcon from "@mui/icons-material/Replay";
import SearchIcon from "@mui/icons-material/Search";
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';

// chart importing
import Chart, { CategoryScale } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";

import Title from "../../../components/title";
import axios from "axios";
import LoaderSpinner from "../../../components/loader-spinner/_index";

import { useNavigate } from "react-router-dom";

const tinhwidth = (maxWidth: any, currentWidth: any) => {
  if (maxWidth <= 0) {
    return 10;
  } else {
    return currentWidth / maxWidth * 100;
  }
}

const WorkingTaskListAllUI = () => {

  // data
  const dataButton = [
    {
      id: 1,
      title: "Đánh giá kết quả"
    },
    {
      id: 2,
      title: "Nhận việc mới"
    },
    {
      id: 3,
      title: "Trả về"
    },
    {
      id: 4,
      title: "Đề xuất"
    },
  ]

  // pre state
  const context = useContext(ModalController)

  const [userData, setUserData] = useState<any>([]);

  const _onClickReportTasking = (type: string, userId: string, dataModal: any) => {
    switch (type) {
      case "TotalListAll":
        context.setFuncs("TotalListAll")
        context.setDataModal(dataModal)
        context.setUserId(userId)
        context.handleOpen()
        break
      case "TotalListNew":
        context.setFuncs("TotalListNew")
        context.setDataModal(dataModal)
        context.setUserId(userId)
        context.handleOpen()
        break
      case "TotalListReveced":
        context.setFuncs("TotalListReveced")
        context.setDataModal(dataModal)
        context.setUserId(userId)
        context.handleOpen()
        break
      case "TotalListReveced_Support":
        context.setFuncs("TotalListReveced_Support")
        context.setDataModal(dataModal)
        context.setUserId(userId)
        context.handleOpen()
        break

      case "TotalListProccessing":
        context.setFuncs("TotalListProccessing")
        context.setDataModal(dataModal)
        context.setUserId(userId)
        context.handleOpen()
        break
      case "TotalListCompletedWaiting":
        context.setFuncs("TotalListCompletedWaiting")
        context.setDataModal(dataModal)
        context.setUserId(userId)
        context.handleOpen()
        break
      case "TotalListCompleted":
        context.setFuncs("TotalListCompleted")
        context.setDataModal(dataModal)
        context.setUserId(userId)
        context.handleOpen()
        break

      default:
        break
    }
  }

  const monthList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

  const tempMonthList = [
    {
      id: 1,
      value: 1,
      label: 1
    },
    {
      id: 2,
      value: 2,
      label: 2
    },
    {
      id: 3,
      value: 3,
      label: 3
    },
    {
      id: 4,
      value: 4,
      label: 4
    },
    {
      id: 5,
      value: 5,
      label: 5
    },
    {
      id: 6,
      value: 6,
      label: 6
    },
    {
      id: 7,
      value: 7,
      label: 7
    },
    {
      id: 8,
      value: 8,
      label: 8
    },
    {
      id: 9,
      value: 9,
      label: 9
    },
    {
      id: 10,
      value: 10,
      label: 10
    },
    {
      id: 11,
      value: 11,
      label: 11
    },
    {
      id: 12,
      value: 12,
      label: 12
    },
  ]

  // state
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoading2, setIsLoading2] = useState<boolean>(false)

  const [userList, setUserList] = useState<any[] | null>(null)
  const [phongBanList, setPhongBanList] = useState<any[] | null>(null)
  const [month, setMonth] = useState<number>(0)
  const [year, setYear] = useState<number>(2023)

  const [selectedUser, setSelectedUser] = useState<any | null>(-1)
  const [selectedPhongBan, setSelectedPhongBan] = useState<any | null>(-1)

  // -- paging
  const [totalPaginate, setTotalPaginate] = useState()
  const [pageCurrentPaginate, setPageCurrentPaginate] = useState()
  const [pageShowing, setPageShowing] = useState<number>(10)

  // -- data
  const [data, setData] = useState<any[] | null>(null)
  const [counting, setCounting] = useState<any | null>(null)

  const [notiFirst, setNotiFirst] = useState<any | null>(null)

  const userLog = useRef(true)
  const navigate = useNavigate();

  // onLoadDataList
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken")

    const takeUserList = async () => {
      try {
        await axios.get(
          USER_READ_ALL_DROPDOWN,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain"
            }
          }
        )
          .then(res => {
            if (res.data.StatusCode === 200) {
              setUserList(res.data.Data)
            }
          })
      } catch (error) {

      }
    }

    const takePhongBanList = async () => {
      const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_PHONGBAN"

      try {
        await axios.get(
          API,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain"
            }
          }
        )
          .then(res => {
            if (res.data.StatusCode === 200) {
              setPhongBanList(res.data.Data)
            }
          })
      } catch (error) {

      }
    }

    takeUserList()
    takePhongBanList()
  }, [])

  const _onLoadData = async (
    searchMonth: number | undefined,
    searchYear: number,
    searchPhongBan: string | number,
    userId: string | number
  ) => {
    const accessToken = localStorage.getItem("accessToken")

    let tempUser: string = userId === -1 || userId === "" ? "" : selectedUser.Id
    let tempPhongBan: string = selectedPhongBan === -1 || selectedPhongBan === "" ? "" : selectedPhongBan?.ParameterId.toString()

    const body = {
      "SEARCH_MONTH": searchMonth,
      "SEARCH_YEAR": searchYear,
      "SERACH_PHONGBAN": tempPhongBan,
      "USER_ID": tempUser
    }


    try {
      await axios.post(
        REPORT_TASK_TOTAL_LIST,
        body,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain"
          }
        }
      )
        .then(res => {
          if (res.data.StatusCode === 200) {
            if (res.data.Data.data === null) {
              setData([])
            } else {
              res.data.Data.data.forEach((item: any, index: number) => {
                const tempValue = res.data.Data.useradd.find((item2: any) => item2.Userid === item.Userid)

                if (tempValue) {
                  item.useradd = tempValue
                }
              })

              setData(res.data.Data.data)
            }
          }
        })
    } catch (error) {

    }
  }

  // onMount
  useEffect(() => {
    _onLoadData(0, 2023, "", "")
  }, [])

  const _onClickFinding = () => {
    _onLoadData(month, year, selectedPhongBan, selectedUser)

    // if (selectedUser === -1) {
    //   setData(null)
    // } else {
    //   const accessToken = localStorage.getItem("accessToken")

    //   const body = {
    //     Page: 0,
    //     Limit: 10,
    //     Recipient: selectedUser.Id
    //   }

    //   const takeData = async () => {
    //     try {
    //       setIsLoading2(true)
    //       await axios.post(
    //         LOAD_TASK_FROM_DETAIL_USER,
    //         body,
    //         {
    //           headers: {
    //             Authorization: "Bearer " + accessToken,
    //             Accept: "text/plain"
    //           }
    //         }
    //       )
    //         .then(res => {
    //           if (res.data.StatusCode === 200) {
    //             setData(res.data.Data.data)
    //             setShowingSelectedUser(selectedUser)
    //             setIsLoading2(false)
    //           }
    //         })
    //     } catch (error) {
    //       toast.error("Tải dữ liệu thất bại.")
    //       setIsLoading2(false)
    //     }
    //   }

    //   takeData()
    // }
  }

  // Preprocessing
  // state
  let tempNhanVienList = userList?.map(item => { return { ...item, value: item.FullName, label: item.FullName } })
  const tempPhongBanList = phongBanList?.map(item => { return { ...item, value: item.ParameterValue, label: item.ParameterValue } })

  // funcs
  const _onChangeNhanVien = (data: any) => {
    if (data === null) {
      setSelectedUser(-1)
    } else {
      setSelectedUser(userList?.find(item => item.Id === data.Id))
    }
  }

  const _onChangePhongBan = (data: any) => {
    if (data === null) {
      setSelectedPhongBan(-1)
    } else {
      setSelectedPhongBan(phongBanList?.find(item => item.ParameterId === data.ParameterId))
    }
  }

  const _onChangeMonth = (data: any) => {
    if (data === null) {
      setMonth(0)
    } else {
      setMonth(data.id)
    }
  }

  // onMount
  // --- selectedPhongBan
  useEffect(() => {
    tempNhanVienList = userList?.map(item => { return { ...item, value: item.FullName, label: item.FullName } })

    if (selectedPhongBan === -1) {
      const tempArray: any = tempNhanVienList
      tempNhanVienList = tempArray
    } else {
      const tempArray: any = tempNhanVienList?.filter(item => item.PhongBan === selectedPhongBan?.ParameterId)
      tempNhanVienList = tempArray
    }
    // setUserList(tempArray)
  }, [selectedPhongBan])

  return (
    <>
      {
        isLoading === true ?
          <div className="w-full h-full justify-center items-center flex flex-col">
            <LoaderSpinner w={32} h={32} />
            <p className="text-primary">Đang tải dữ liệu</p>
          </div>
          :
          <div className="w-full h-full flex flex-col overflow-x-hidden">
            <Title />

            {/* Content */}
            <div className="w-full h-max flex flex-col p-4 text-sm">

              {/* Options */}
              <div className="flex flex-col w-full h-max pb-6 border-dashed lg:border-b-2 border-b-0 border-slate-500">
                {/* Filter fields */}
                <div className="w-max h-max flex lg:flex-row flex-col lg:space-x-3 space-x-0">
                  <div className="flex lg:w-max w-full h-max space-x-3">
                    <div className="w-max h-max flex flex-col">
                      <label className="text-xs">Phòng ban</label>
                      {/* <select
                        className="lg:w-max w-[10rem] h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={selectedPhongBan === -1 ? -1 : selectedPhongBan?.ParameterId}
                        onChange={(e: any) => {
                          _onChangeSelectedPhongBan(e)
                        }}
                      >
                        <option className="text-gray-300" value={-1}>--- Chọn phòng ban ---</option>
                        {
                          phongBanList === null ? <></>
                            : phongBanList.map((item: any, index: any) => (
                              <option key={index} value={item.ParameterId}>{item.ParameterValue}</option>
                            ))
                        }
                      </select> */}
                      <Select
                        className="z-[5]"
                        options={tempPhongBanList}
                        onChange={(data: any) => _onChangePhongBan(data)}
                        isClearable
                        placeholder="--- Chọn phòng ban ---"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>

                    <div className="w-max h-max flex flex-col">
                      <label className="text-xs">Nhân viên</label>
                      {/* <select
                        className="lg:w-max w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={selectedUser === -1 ? -1 : selectedUser.Id}
                        onChange={(e: any) => {
                          _onChangeSelectedUser(e)
                        }}
                      >
                        <option className="text-gray-300" value={-1}>--- Chọn nhân viên ---</option>
                        {
                          userList === null ? <></>
                            : userList.map((item: any, index: any) => (
                              <option key={index} value={item.Id}>{item.FullName}</option>
                            ))
                        }
                      </select> */}
                      <Select
                        className="z-[5]"
                        options={tempNhanVienList}
                        onChange={(data: any) => _onChangeNhanVien(data)}
                        isClearable
                        placeholder="--- Chọn nhân viên ---"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </div>

                  <div className="w-max h-max flex space-x-3">
                    <div className="w-max h-max flex flex-col">
                      <label className="text-xs">Tháng</label>
                      <Select
                        className="z-[5]"
                        options={tempMonthList}
                        onChange={(data: any) => _onChangeMonth(data)}
                        isClearable
                        placeholder="--- Chọn tháng ---"
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                      {/* <select
                        className="w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                        value={month}
                        onChange={(e: any) => {
                          e.preventDefault();
                          setMonth(parseInt(e.target.value));
                        }}
                      >
                        <option className="text-gray-300" value={0}>--- Chọn tháng ---</option>
                        {
                          monthList.map((item: any, index: any) => (
                            <option key={index} value={item}>{item}</option>
                          ))
                        }
                      </select> */}
                    </div>

                    <div className="w-max h-max flex flex-col">
                      <label className="text-xs">Năm</label>
                      <input
                        className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
                        type="number"
                        value={year}
                        onChange={(e: any) => {
                          e.preventDefault()
                          setYear(e.target.value)
                        }}
                      >
                      </input>
                    </div>

                  </div>
                </div>

                {/* Button fields */}
                <div className="w-max h-max flex space-x-2">
                  <div className="w-max h-max flex flex-col">
                    <label className="text-slate-50">Tải lại</label>
                    <button
                      className="
                      flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
                      hover:bg-sky-400 active:bg-sky-600
                    "
                      onClick={_onClickFinding}
                    >
                      <SearchIcon fontSize="small" />
                      <p className="hidden lg:flex">Tìm kiếm</p>
                    </button>
                  </div>

                  <div className="w-max h-max flex flex-col">
                    <label className="text-slate-50">Tải lại</label>
                    <button
                      className="
                      flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
                      hover:bg-amber-400 active:bg-amber-600 
                    "
                      onClick={() => window.location.reload()}
                    >
                      <ReplayIcon fontSize="small" />
                      <p className="hidden lg:flex">Tải lại</p>
                    </button>
                  </div>
                </div>
              </div>

              {/* Showing */}
              <div className="flex flex-col w-full h-[calc(100%-3rem)] pt-4">
                {
                  <>
                    {
                      isLoading2 === true || data === null ?
                        <div className="w-full h-max flex flex-col justify-center items-center space-y-1">
                          <LoaderSpinner w={32} h={32} />
                          <p className="text-primary">Đang tải dữ liệu</p>
                        </div>
                        :
                        <div className="w-full h-max flex flex-col justify-center items-center space-y-20 pr-5 ">
                          {
                            data?.map((item, index) => (
                              <>
                                <div key={index} className="w-full lg:h-max flex flex-col border-b-2 border-slate-300 space-y-2 pb-10">
                                  <p className="w-full text-xl font-semibold space-y-3 pb-3">
                                    Họ Tên: <span className="text-primary"> {item.TenUser} </span>
                                  </p>
                                  <p className="w-full text-md font-semibold ">
                                    Chức vụ: <span className="text-primary"> {item.TenChucVu} </span> <br />
                                    Bộ phận: <span className="text-primary"> {item.TenBoPhan} </span> <br />
                                    Phòng ban: <span className="text-primary"> {item.TenPhongBan} </span>
                                  </p>
           
                                  <div className="flex w-full h-max space-y-2">

                                    {/* Fields */}
                                    <div className="w-full h-full justify-center items-center">
                                      {/* Total task */}
                                      <div className="w-full h-max">
                                        <p className="text-2xl text-center font-semibold text-sky-600 space-y-3 pb-3">Thống kê số lượng công việc</p>
                              
                                        {/* Top fields */}

                                        <div className="flex flex-col w-full h-max p-5 space-y-5">
                                          {/* Elements */}
                                          <div className="w-full h-max flex flex-col">
                                            <p className="font-bold">Tổng hợp công việc</p>

                                            <div style={{
                                              width: `${tinhwidth(item.TotalListAll, item.TotalListAll)}%`,
                                              color: "black"
                                            }}
                                              className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-yellow-200 to-yellow-500 text-center text-white rounded-r-lg justify-center items-center `}
                                            >
                                              <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-yellow-300 to-yellow-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.TotalListAll}</div>
                                              <p className="w-[0%] text-center font-bold text-semibold">{item.TotalListAll}</p>
                                            </div>
                                          </div>

                                          <div className="w-full h-max flex flex-col">
                                            <p className="font-bold">Tổng việc mới được giao</p>

                                            <button style={{
                                              width: `${tinhwidth(item.TotalListAll, item.TotalListNew)}%`,
                                              color: "black"
                                            }}
                                              className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-green-200 to-green-500 text-center text-white rounded-r-lg justify-center items-center `}
                                              onClick={() => _onClickReportTasking(
                                                "TotalListNew",
                                                item.Userid,
                                                {
                                                  countListPunctualCompleted: item.countListPunctualCompleted,
                                                  countListEarlyComplete: item.countListEarlyComplete,
                                                  countListLateComplete: item.countListLateComplete,
                                                  countListCompleted: item.countListCompleted,
                                                  countListCompletedWaiting: item.countListCompletedWaiting
                                                })}
                                            >
                                              <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-green-300 to-green-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.TotalListNew}</div>
                                              <p className="w-[0%] text-center font-bold text-semibold">{item.TotalListNew}</p>
                                            </button>
                                          </div>

                                          <div className="w-full h-max flex flex-col">
                                            <p className="font-bold">Tổng việc chưa xử lý: <span className="text-orange-600 font-bold">{item.TotalListReveced + item.TotalListReveced_SUP}</span></p>

                                            {/* Main of Elemnets */}
                                            <div className="w-full h-max flex flex-col space-y-1 mt-2">
                                              <div className="w-full h-max flex flex-col text-xs">
                                                <p>Thực hiện chính</p>
                                                <button style={{
                                                  width: `${tinhwidth(item.TotalListAll, item.TotalListReveced)}%`,
                                                  color: "black"
                                                }}
                                                  className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-orange-200 to-orange-500 text-center text-white rounded-r-lg justify-center items-center `}
                                                  onClick={() => _onClickReportTasking(
                                                    "TotalListReveced",
                                                    item.Userid,
                                                    {
                                                      countListReveced: item.countListReveced,
                                                      countListWorking: item.countListWorking
                                                    })}
                                                >

                                                  <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.TotalListReveced}</div>
                                                  <p className="w-[0%] text-center font-bold text-semibold">{item.TotalListReveced}</p>
                                                </button>
                                              </div>

                                              <div className="w-full h-max flex flex-col text-xs">
                                                <p>Hỗ trợ</p>
                                                <button style={{
                                                  width: `${tinhwidth(item.TotalListAll, item.TotalListReveced_SUP)}%`,
                                                  color: "black"
                                                }}
                                                  className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-orange-200 to-orange-500 text-center text-white rounded-r-lg justify-center items-center `}
                                                  onClick={() => _onClickReportTasking(
                                                    "TotalListReveced_Support",
                                                    item.Userid,
                                                    {
                                                      countListReveced: item.countListReveced,
                                                      countListWorking: item.countListWorking
                                                    })}
                                                >

                                                  <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.TotalListReveced_SUP}</div>
                                                  <p className="w-[0%] text-center font-bold text-semibold">{item.TotalListReveced_SUP}</p>
                                                </button>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="w-full h-max flex flex-col">
                                            <p className="font-bold">Tổng việc đang xử lý: <span className="text-purple-600 font-bold">{item.TotalListProccessing + item.TotalListProccessing_SUP}</span></p>

                                            {/* Main of Elemnets */}
                                            <div className="w-full h-max flex flex-col space-y-1 mt-2">
                                              <div className="w-full h-max flex flex-col text-xs">
                                                <p>Thực hiện chính</p>
                                                <button style={{
                                                  width: `${tinhwidth(item.TotalListAll, item.TotalListProccessing)}%`,
                                                  color: "black"
                                                }}
                                                  className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-purple-200 to-purple-500 text-center text-white rounded-r-lg  justify-center items-center  `}

                                                  onClick={() => _onClickReportTasking(
                                                    "TotalListProccessing",
                                                    item.Userid,
                                                    {
                                                      countListProccessing: item.countListProccessing,
                                                      countListLate: item.countListLate,
                                                      countListReturnWork: item.countListReturnWork
                                                    })}
                                                >

                                                  <div className="w-[0%]  h-[1.5rem] bg-gradient-to-r from-purple-300 to-purple-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.TotalListProccessing}</div>
                                                  <p className="w-[0%] text-center font-bold text-semibold">{item.TotalListProccessing}</p>
                                                </button>
                                              </div>

                                              <div className="w-full h-max flex flex-col text-xs">
                                                <p>Hỗ trợ</p>
                                                <button style={{
                                                  width: `${tinhwidth(item.TotalListAll, item.TotalListProccessing_SUP)}%`,
                                                  color: "black"
                                                }}
                                                  className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-purple-200 to-purple-500 text-center text-white rounded-r-lg  justify-center items-center  `}

                                                  onClick={() => _onClickReportTasking(
                                                    "TotalListProccessing",
                                                    item.Userid,
                                                    {
                                                      countListProccessing: item.countListProccessing,
                                                      countListLate: item.countListLate,
                                                      countListReturnWork: item.countListReturnWork
                                                    })}
                                                >

                                                  <div className="w-[0%]  h-[1.5rem] bg-gradient-to-r from-purple-300 to-purple-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.TotalListProccessing_SUP}</div>
                                                  <p className="w-[0%] text-center font-bold text-semibold">{item.TotalListProccessing_SUP}</p>
                                                </button>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="w-full h-max flex flex-col">
                                            <p className="font-bold">Tổng việc hoàn tất: <span className="text-rose-600 font-bold">{item.TotalListCompleted + item.TotalListCompleted_SUP}</span></p>

                                            {/* <button style={{
                                          width: `${tinhwidth(item.TotalListAll, item.TotalListCompleted)}%`,
                                          color: "black"
                                        }}
                                          className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-red-200 to-red-500 shadow-lg text-center text-white rounded-r-lg  justify-center items-center  `}

                                          onClick={() => _onClickReportTasking(
                                            "TotalListCompleted",
                                            item.Userid,
                                            {
                                              countListEarlyComplete: item.countListEarlyComplete,
                                              countListPunctualCompleted: item.countListPunctualCompleted,
                                              countListLateComplete: item.countListLateComplete
                                            })}
                                        >

                                          <div className="w-[0%]  h-[1.5rem] bg-gradient-to-r from-red-300 to-red-600 transition-all delay-75 shadow-lg opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.TotalListCompleted}</div>
                                          <p className="w-[0%] text-center font-bold text-semibold">{item.TotalListCompleted}</p>
                                        </button> */}

                                            <div className="w-full h-max flex flex-col space-y-1 mt-2">
                                              <div className="w-full h-max flex flex-col text-xs">
                                                <p>Thực hiện chính</p>
                                                <button style={{
                                                  width: `${tinhwidth(item.TotalListAll, item.TotalListCompleted)}%`,
                                                  color: "black"
                                                }}
                                                  className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-red-200 to-red-500 text-center text-white rounded-r-lg  justify-center items-center  `}

                                                  onClick={() => _onClickReportTasking(
                                                    "TotalListCompleted",
                                                    item.Userid,
                                                    {
                                                      countListEarlyComplete: item.countListEarlyComplete,
                                                      countListPunctualCompleted: item.countListPunctualCompleted,
                                                      countListLateComplete: item.countListLateComplete
                                                    })}
                                                >

                                                  <div className="w-[0%]  h-[1.5rem] bg-gradient-to-r from-red-300 to-red-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.TotalListCompleted}</div>
                                                  <p className="w-[0%] text-center font-bold text-semibold">{item.TotalListCompleted}</p>
                                                </button>
                                              </div>

                                              <div className="w-full h-max flex flex-col text-xs">
                                                <p>Hỗ trợ</p>
                                                <button style={{
                                                  width: `${tinhwidth(item.TotalListAll, item.TotalListCompleted_SUP)}%`,
                                                  color: "black"
                                                }}
                                                  className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-red-200 to-red-500 text-center text-white rounded-r-lg  justify-center items-center  `}

                                                  onClick={() => _onClickReportTasking(
                                                    "TotalListCompleted",
                                                    item.Userid,
                                                    {
                                                      countListEarlyComplete: item.countListEarlyComplete,
                                                      countListPunctualCompleted: item.countListPunctualCompleted,
                                                      countListLateComplete: item.countListLateComplete
                                                    })}
                                                >

                                                  <div className="w-[0%]  h-[1.5rem] bg-gradient-to-r from-red-300 to-red-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.TotalListCompleted_SUP}</div>
                                                  <p className="w-[0%] text-center font-bold text-semibold">{item.TotalListCompleted_SUP}</p>
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      {/* TotalTask delivery */}

                                      {
                                        item?.useradd ?
                                          <div className="w-full h-max">
                                            <p className="text-2xl text-center font-semibold text-rose-600 space-y-3 pb-3">Thống kê công việc đã giao</p>
                                            {/* Top fields */}

                                            <div className="flex flex-col w-full h-max p-5 space-y-5">
                                              {/* Elements */}
                                              <div className="w-full h-max flex flex-col">
                                                <p className="font-bold">Tổng hợp công việc đã giao</p>

                                                <div style={{
                                                  width: `${tinhwidth(item.useradd.TotalListAll_UserAdd, item.useradd.TotalListAll_UserAdd)}%`,
                                                  color: "black"
                                                }}
                                                  className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-yellow-200 to-yellow-500 text-center text-white rounded-r-lg justify-center items-center `}
                                                >
                                                  <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-yellow-300 to-yellow-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.useradd.TotalListAll_UserAdd}</div>
                                                  <p className="w-[0%] text-center font-bold text-semibold">{item.useradd.TotalListAll_UserAdd}</p>
                                                </div>
                                              </div>

                                              <div className="w-full h-max flex flex-col">
                                                <p className="font-bold">Công việc mới giao</p>

                                                <button style={{
                                                  width: `${tinhwidth(item.useradd.TotalListAll_UserAdd, item.useradd.TotalListNew_UserAdd)}%`,
                                                  color: "black"
                                                }}
                                                  className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-green-200 to-green-500 text-center text-white rounded-r-lg justify-center items-center `}
                                                  onClick={() => _onClickReportTasking(
                                                    "TotalListNew",
                                                    item.Userid,
                                                    {
                                                      countListPunctualCompleted: item.countListPunctualCompleted,
                                                      countListEarlyComplete: item.countListEarlyComplete,
                                                      countListLateComplete: item.countListLateComplete,
                                                      countListCompleted: item.countListCompleted,
                                                      countListCompletedWaiting: item.countListCompletedWaiting
                                                    })}
                                                >
                                                  <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-green-300 to-green-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                              hover:opacity-100 hover:w-full">{item.useradd.TotalListNew_UserAdd}</div>
                                                  <p className="w-[0%] text-center font-bold text-semibold">{item.useradd.TotalListNew_UserAdd}</p>
                                                </button>
                                              </div>

                                              <div className="w-full h-max flex flex-col">
                                                <p className="font-bold">Công việc đã giao đang xử lý</p>

                                                {/* Main of Elemnets */}
                                                <div className="w-full h-max flex flex-col space-y-1 mt-2">
                                                  <div className="w-full h-max flex flex-col text-xs">
                                                    <button style={{
                                                      width: `${tinhwidth(item.useradd.TotalListAll_UserAdd, item.useradd.TotalListProccessing_UserAdd)}%`,
                                                      color: "black"
                                                    }}
                                                      className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-orange-200 to-orange-500 text-center text-white rounded-r-lg justify-center items-center `}
                                                      onClick={() => _onClickReportTasking(
                                                        "TotalListReveced",
                                                        item.Userid,
                                                        {
                                                          countListReveced: item.countListReveced,
                                                          countListWorking: item.countListWorking
                                                        })}
                                                    >

                                                      <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.useradd.TotalListProccessing_UserAdd}</div>
                                                      <p className="w-[0%] text-center font-bold text-semibold">{item.useradd.TotalListProccessing_UserAdd}</p>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="w-full h-max flex flex-col">
                                                <p className="font-bold">Công việc đã giao hoàn tất</p>

                                                {/* Main of Elemnets */}
                                                <div className="w-full h-max flex flex-col space-y-1 mt-2">
                                                  <div className="w-full h-max flex flex-col text-xs">
                                                    <button style={{
                                                      width: `${tinhwidth(item.useradd.TotalListAll_UserAdd, item.useradd.TotalListCompleted_UserAdd)}%`,
                                                      color: "black"
                                                    }}
                                                      className={`h-[1.5rem] hover:cursor-pointer bg-gradient-to-r flex from-purple-200 to-purple-500 text-center text-white rounded-r-lg  justify-center items-center  `}

                                                      onClick={() => _onClickReportTasking(
                                                        "TotalListProccessing",
                                                        item.Userid,
                                                        {
                                                          countListProccessing: item.countListProccessing,
                                                          countListLate: item.countListLate,
                                                          countListReturnWork: item.countListReturnWork
                                                        })}
                                                    >

                                                      <div className="w-[0%]  h-[1.5rem] bg-gradient-to-r from-purple-300 to-purple-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                            hover:opacity-100 hover:w-full">{item.useradd.TotalListCompleted_UserAdd}</div>
                                                      <p className="w-[0%] text-center font-bold text-semibold">{item.useradd.TotalListCompleted_UserAdd}</p>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          :
                                          <></>
                                      }
                                      
                                      {/* Botom left feld */}
                                      <div className="lg:flex hidden flex-col-2 w-full h-max pt-5">
                                        <p className="w-1/2 text-2xl text-center font-semibold text-yellow-600 space-y-3 pb-3">Kết quả</p>
                                        <p className="w-1/2 text-2xl text-center font-semibold text-green-600 space-y-3 pb-3">Đánh giá năng lực</p>
                                      </div>

                                      <div className="flex lg:flex-row flex-col w-full h-max pb-5 space-y-5">
                                        {/* Thuc hien chinh */}
                                        <div className="lg:w-1/2 w-full flex flex-col h-max pt-5 pb-5 items-center justify-center space-y-7">
                                          {/* Title */}
                                          <p className="
                                          w-full text-2xl text-center font-semibold text-yellow-600 space-y-3 pb-3
                                          lg:hidden flex justify-center items-center
                                        ">
                                            Kết quả
                                          </p>

                                          {/* Content */}
                                          {/* Main result */}
                                          <div className="w-full h-full flex justify-center items-center">
                                            {/* Sub title */}
                                            <div className="lg:w-1/5 w-[40%] text-right pr-3 space-y-2">
                                              <div className="w-full h-[1.5rem] font-bold mb-3">Thực hiện chính</div>
                                              <div className="w-full h-[1.5rem]">Xuất sắc</div>
                                              <div className="w-full h-[1.5rem]">Tốt</div>
                                              <div className="w-full h-[1.5rem]">Trung bình</div>
                                              <div className="w-full h-[1.5rem]">Kém</div>
                                            </div>

                                            {/* Value */}
                                            <div className="lg:w-4/5 w-[60%] space-y-2 pr-[3rem] font-semibold">
                                              <div className="mb-8">
                                              </div>
                                              <div style={{ width: `${tinhwidth(100, item.Recipient_NumberExcellent)}%` }}
                                                className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75 justify-center items-center ">
                                                <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full">{item.Recipient_NumberExcellent}</div>
                                                <p className="w-[0%] text-center font-bold text-semibold">{item.Recipient_NumberExcellent}</p>
                                              </div>

                                              <div style={{ width: `${tinhwidth(100, item.Recipient_NumberGood)}%` }}
                                                className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75 justify-center items-center ">
                                                <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full">{item.Recipient_NumberGood}</div>
                                                <p className="w-[0%] text-center font-bold text-semibold">{item.Recipient_NumberGood}</p>
                                              </div>

                                              <div style={{ width: `${tinhwidth(100, item.Recipient_NumberMedium)}%` }}
                                                className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75 justify-center items-center ">
                                                <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full">{item.Recipient_NumberMedium}</div>
                                                <p className="w-[0%] text-center font-bold text-semibold">{item.Recipient_NumberMedium}</p>
                                              </div>

                                              <div style={{ width: `${tinhwidth(100, item.Recipient_NumberExcellent)}%` }}
                                                className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75k justify-center items-center ">
                                                <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full">{item.Recipient_NumberBad}</div>
                                                <p className="w-[0%] text-center font-bold text-semibold">{item.Recipient_NumberBad}</p>
                                              </div>
                                            </div>
                                          </div>

                                          {/* Support result */}
                                          <div className="w-full h-full flex justify-center items-center">
                                            {/* Sub title */}
                                            <div className="lg:w-1/5 w-[40%] text-right pr-3 space-y-2">
                                              <div className="w-full h-[1.5rem] font-bold mb-3">Hỗ trợ</div>
                                              <div className="w-full h-[1.5rem]">Xuất sắc</div>
                                              <div className="w-full h-[1.5rem]">Tốt</div>
                                              <div className="w-full h-[1.5rem]">Trung bình</div>
                                              <div className="w-full h-[1.5rem]">Kém</div>
                                            </div>

                                            {/* Value */}
                                            <div className="lg:w-4/5 w-[60%] space-y-2 pr-[3rem] font-semibold">
                                              <div className="mb-8"></div>
                                              <div style={{ width: `${tinhwidth(100, item.Supporter_NumberExcellent)}%` }}
                                                className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75 justify-center items-center ">
                                                <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full">{item.Supporter_NumberExcellent}</div>
                                                <p className="w-[0%] text-center font-bold text-semibold">{item.Supporter_NumberExcellent}</p>
                                              </div>

                                              <div style={{ width: `${tinhwidth(100, item.Supporter_NumberGood)}%` }}
                                                className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75 justify-center items-center ">
                                                <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full">{item.Supporter_NumberGood}</div>
                                                <p className="w-[0%] text-center font-bold text-semibold">{item.Supporter_NumberGood}</p>
                                              </div>

                                              <div style={{ width: `${tinhwidth(100, item.Supporter_NumberMedium)}%` }}
                                                className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75 justify-center items-center ">
                                                <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full">{item.Supporter_NumberMedium}</div>
                                                <p className="w-[0%] text-center font-bold text-semibold">{item.Supporter_NumberMedium}</p>
                                              </div>

                                              <div style={{ width: `${tinhwidth(100, item.Supporter_NumberFail)}%` }}
                                                className="h-[1.5rem] bg-gradient-to-r flex from-yellow-400 to-orange-500 text-center text-black rounded-r-lg transition-all delay-75k justify-center items-center ">
                                                <div className="w-[0%] h-[1.5rem] bg-gradient-to-r from-orange-300 to-orange-600 transition-all delay-75 opacity-0 rounded-r-lg font-bold text-semibold
                                  hover:opacity-100 hover:w-full">{item.Supporter_NumberFail}</div>
                                                <p className="w-[0%] text-center font-bold text-semibold">{item.Supporter_NumberFail}</p>
                                              </div>
                                            </div>
                                          </div>

                                          {/* Attitude */}
                                          <div className="w-full h-full flex justify-center items-center">
                                            {/* Sub title */}
                                          </div>
                                        </div>

                                        {/* Botom left feld */}
                                        <div className="lg:w-1/2 w-full flex flex-col h-max pt-5 pb-5 font-semibold items-center justify-center">
                                          <div className="w-full h-max">
                                            {/* Title */}
                                            <p className="
                                          w-full text-2xl text-right font-semibold text-green-600 space-y-3 pb-3 pr-[5rem]
                                          lg:hidden flex justify-end items-center
                                        ">
                                              Đánh giá năng lực
                                            </p>

                                            {/* Content */}
                                            <div className="w-full h-full flex">
                                              <div className="w-full h-max flex-col space-y-8 flex p-3">
                                                {/* Element */}
                                                <div className="w-full h-[1.5rem]">
                                                  <p>Năng suất lao động</p>
                                                  <div style={{ width: `${tinhwidth(100, item.NANGSUAT)}%` }}
                                                    className="h-[1.5rem] bg-gradient-to-r from-cyan-400 to-green-500 text-center text-black rounded-r-lg justify-center items-center flex
                                      transition-all delay-75">
                                                    <div className="w-[0%] h-[1.5rem] bg-gradient-to-r opacity-0 from-cyan-500 to-green-600 transition-all delay-75 rounded-r-lg font-bold text-semibold
                                      hover:opacity-100 hover:w-full">{item?.NANGSUAT ? item?.NANGSUAT : 0}%</div>
                                                    <p className="w-0 h-[1.5rem] font-bold text-semibold">{item?.NANGSUAT ? item?.NANGSUAT : 0}%</p>
                                                  </div>
                                                </div>

                                                <div className="w-full h-[1.5rem]">
                                                  <p>Năng lực hoàn thành</p>
                                                  <div style={{ width: `${tinhwidth(100, item.NANGLUC)}%` }}
                                                    className="h-[1.5rem] bg-gradient-to-r from-cyan-400 to-green-500 text-center text-black rounded-r-lg justify-center items-center flex
                                      transition-all delay-75">
                                                    <div className="w-[0%] h-[1.5rem] bg-gradient-to-r opacity-0 from-cyan-500 to-green-600 transition-all delay-75 rounded-r-lg font-bold text-semibold
                                      hover:opacity-100 hover:w-full">{item?.NANGLUC ? item?.NANGLUC : 0}%</div>
                                                    <p className="w-0 h-[1.5rem] font-bold text-semibold">{item?.NANGLUC ? item?.NANGLUC : 0}%</p>
                                                  </div>
                                                </div>

                                                <div className="w-full h-[1.5rem]">
                                                  <p>Năng lực phối hợp</p>
                                                  <div style={{ width: `${tinhwidth(100, item.NANGLUCPHOIHOP)}%` }}
                                                    className="h-[1.5rem] bg-gradient-to-r from-cyan-400 to-green-500 text-center text-black rounded-r-lg justify-center items-center flex
                                                  transition-all delay-75">
                                                    <div className="w-[0%] h-[1.5rem] bg-gradient-to-r opacity-0 from-cyan-500 to-green-600 transition-all delay-75 rounded-r-lg font-bold text-semibold
                                                  hover:opacity-100 hover:w-full">{item?.NANGLUCPHOIHOP ? item?.NANGLUCPHOIHOP : 0}%</div>
                                                    <p className="w-0 h-[1.5rem] font-bold text-semibold">{item?.NANGLUCPHOIHOP ? item?.NANGLUCPHOIHOP : 0}%</p>
                                                  </div>
                                                </div>
                                                {/* <div className="w-full h-[1.5rem]">Chuyên môn</div>
                                            <div className="w-full h-[1.5rem]">Thái độ thực hiện</div> */}
                                              </div>
                                            </div>
                                          </div>

                                          <div className="w-full h-full space-y-2 p-6">
                                            <p className="
                                              w-full text-2xl text-center font-semibold text-purple-600 space-y-3 pb-3
                                              flex justify-center items-center
                                            ">
                                              Thái độ hỗ trợ
                                            </p>
                                            <div className="w-full h-max flex flex-col space-y-2">
                                              <div className="w-full h-[1.5rem]">Nhiệt tình: {item.Supporter_Ardent}</div>
                                              <div className="w-full h-[1.5rem]">Không nhiệt tình: {item.Supporter_NoArdent}</div>
                                              <div className="w-full h-[1.5rem]">Nhiệt tình không chuyên môn: {item.Supporter_Ardent_NoExpertise}</div>
                                              <div className="w-full h-[1.5rem]">Không nhiệt tình không chuyên môn: {item.Supporter_Not_Engaged}</div>
                                              <div className="w-full h-[1.5rem]">Không tham gia: {item.Supporter_No_Joining}</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>

                            ))
                          }
                        </div>
                    }
                  </>
                }
              </div>
            </div>
          </div>
      }
    </>
  )
}

export default WorkingTaskListAllUI