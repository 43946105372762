import { get, post } from "../ApiHelper/index";
import {
  LIST_NOTI,
  READ_NOTI,
  TOTAL_NOTI,
  READ_ALL_NOTI,
} from "../apis/_index";
import axios from "axios";

export const ListNoti = (token: any) => {
  const header = () => {
    var header = {
      Authorization: "Bearer " + token,
      Accept: "text/plain",
      "Content-Type": "application/json",
    };
    return header;
  };
  return new Promise(async (resolve) => {
    return await axios
      .get(LIST_NOTI, {
        headers: header(),
      })
      .then((res: any) => {
        resolve({
          status: true,
          data: res.data.Data,
        });
      })
      .catch((err: any) => {
        resolve({
          status: false,
          err: err,
        });
      });
  });
};

export const TotalNoti = (token: any) => {
  const header = () => {
    var header = {
      Authorization: "Bearer " + token,
      Accept: "text/plain",
      "Content-Type": "application/json",
    };
    return header;
  };
  return new Promise(async (resolve) => {
    return await axios
      .get(TOTAL_NOTI, {
        headers: header(),
      })
      .then((res: any) => {
        resolve({
          status: true,
          data: res.data.Data,
        });
      })
      .catch((err: any) => {
        resolve({
          status: false,
          err: err,
        });
      });
  });
};

export const ReadNoti = (id: string, userId: string, token: string) => {
  const header = () => {
    var header = {
      Authorization: "Bearer " + token,
      Accept: "text/plain",
      "Content-Type": "application/json",
    };
    return header;
  };

  return new Promise(async (resolve) => {
    const url = READ_NOTI.replace("{0}", id).replace("{1}", userId);

    return await axios
      .get(url, {
        headers: header(),
      })
      .then((res: any) => {
        resolve({
          status: true,
          data: res.data.Data,
        });
      })
      .catch((err: any) => {
        resolve({
          status: false,
          err: err,
        });
      });
  });
};

export const ReadAllNoti = (userId: string, token: string) => {
  // return new Promise(async resolve => {
  //     const url = READ_ALL_NOTI + userId;
  //     return await get(url)
  //         .then((res: any) => {

  //             resolve({
  //                 status: true,
  //                 data: res.data.Data
  //             });
  //         })
  //         .catch((err: any) => {
  //             resolve({
  //                 status: false,
  //                 err: err
  //             });
  //         })
  // })

  const header = () => {
    var header = {
      Authorization: "Bearer " + token,
      Accept: "text/plain",
      "Content-Type": "application/json",
    };
    return header;
  };

  const url = READ_ALL_NOTI + userId;

  return new Promise(async (resolve) => {
    return await axios
      .get(url, {
        headers: header(),
      })
      .then((res: any) => {
        resolve({
          status: true,
          data: res.data.Data,
        });
      })
      .catch((err: any) => {
        resolve({
          status: false,
          err: err,
        });
      });
  });
};

export const ReadAllSubNoti = (userId: string, token: string) => {

  const header = () => {
    var header = {
      Authorization: "Bearer " + token,
      Accept: "text/plain",
      "Content-Type": "application/json",
    };
    return header;
  };

  const url = READ_ALL_NOTI + userId;

  return new Promise(async (resolve) => {
    return await axios
      .get(url, {
        headers: header(),
      })
      .then((res: any) => {
        resolve({
          status: true,
          data: res.data.Data,
        });
      })
      .catch((err: any) => {
        resolve({
          status: false,
          err: err,
        });
      });
  });
};
