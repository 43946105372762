import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  ONCHANGE_PAGINATION_TASKLIST,
  ONCHANGE_CHOOSEPAGE,
} from "../../../redux/reducers/workingReducer/_index";

import { ONCHANGE_TARGET_RATING } from "../../../redux/reducers/settingReducer/_index";

import { toast } from "react-toastify";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import { ModalController } from "../../../ui/homeUI/_index";
import Pagination from "@mui/material/Pagination";

import PanToolAltTwoToneIcon from "@mui/icons-material/PanToolAltTwoTone";
import FlagTwoToneIcon from "@mui/icons-material/FlagTwoTone";
import axios from "axios";
import { tieuchidanhgia_list } from "../../../action/tieuchidanhgiaAction";

const SettingTargetScoring = () => {
  // state
  const [data, setData] = useState<any | null>(null);

  // ref
  const shouldLog = useRef(true);

  // redux
  const settingData = useSelector((state: any) => state.settingReducer);
  const userData = useSelector((state: any) => state.userReducer);
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();
  const context = useContext(ModalController);

  const _onClickDetailTargetCapactity = (id: any) => {
    context.setFuncs("detailTargetCapacity");
    context.setTaskId(id);
    context.handleOpen();
  };

  const _onClickDelete_TIEUCHIDANHGIA = (id: any, matieuchi: any) => {
    context.setFuncs("deleteTieuChi");
    context.setMaTieuChi(matieuchi);
    context.setTaskId(id);
    context.handleOpen();
  };

  useEffect(() => {
    if (shouldLog.current) {
      shouldLog.current = false;
      getList();
    }
  }, []);

  // read all
  const getList = () => {
    tieuchidanhgia_list().then((res: any) => {
      dispatch(ONCHANGE_TARGET_RATING(res.data))
    });
  };

  return (
    <div className="flex flex-col text-sm lg:w-full lg:h-[calc(100%-7rem)] pb-[5rem] lg:mt-0 mt-3">
      {" "}
      {/* warm */}
      <div className="tableFixHead styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
        <table>
          <thead className="text-white font-semibold text-center w-full h-[2rem]">
            <tr>
              <th className="border-l-[1px] border-slate-300">STT</th>
              <th className="border-l-[1px] border-slate-300">Tiêu chí</th>
              <th className="border-l-[1px] border-slate-300">Nội dung</th>
              <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                Hiển thị
              </th>
              <th className="hidden lg:table-cell border-l-[1px] border-r-[1px] border-slate-300"></th>
            </tr>
          </thead>
          <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
            {settingData.targetRatingList === null ? (
              <></>
            ) : (
              settingData.targetRatingList.map((item: any, index: any) => (
                <tr className="w-max h-[3rem] bg-white">
                  <td className="text-center border border-slate-300">
                    {index + 1}
                  </td>

                  <td className="text-center border border-slate-300">{item.tentieuchi}</td>

                  <td className="hidden lg:table-cell text-center border border-slate-300">
                    {item.noidung}
                  </td>

                  <td className="hidden lg:table-cell text-center border border-slate-300">
                    <input type="checkbox" checked={item.IsActive} />
                  </td>

                  <td className="hidden lg:table-cell text-center border border-slate-300">
                    <div className="dropdown">
                      <button className="dropbtn">
                        <MoreHorizIcon fontSize="small" />
                      </button>

                      <div className="dropdown-content text-xs font-semibold">
                        <button
                          className="flex w-full h-full"
                          onClick={() => _onClickDetailTargetCapactity(item.id)}
                        >
                          <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                            <DescriptionTwoToneIcon
                              className="text-sky-600"
                              sx={{ fontSize: 18 }}
                              fontSize="small"
                            />

                            <div>Chi tiết / Chỉnh sửa</div>
                          </div>
                        </button>

                        <button
                          className="flex w-full h-full hover:text-red-500"
                          onClick={() =>
                            _onClickDelete_TIEUCHIDANHGIA(
                              item.id,
                              item.matieuchi
                            )
                          }
                        >
                          <div className="flex w-full space-x-1">
                            <DeleteTwoToneIcon
                              className="text-red-600"
                              sx={{ fontSize: 18 }}
                              fontSize="small"
                            />

                            <div className="h-full">Xoá</div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </td>

                  {/* Phone */}
                  <td className="lg:hidden sm:table-cell border border-slate-300">
                    <div>{item.noidung}</div>
                    <div className="flex">
                      <p className="pr-3 text-xs text-slate-500">Hiển thị</p>
                      <input type="checkbox" checked={item.IsActive} />
                    </div>

                    <div className="flex">
                      <p className="text-xs text-slate-500">Chi tiết</p>
                      <div className="ml-1 dropdown">
                        <div className="flex">
                          <button className="dropbtn">
                            <MoreHorizIcon fontSize="small" />
                          </button>

                          <div className="dropdown-content text-xs font-semibold">
                            <button
                              className="flex w-full h-full"
                              onClick={() =>
                                _onClickDetailTargetCapactity(item.id)
                              }
                            >
                              <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                <DescriptionTwoToneIcon
                                  className="text-sky-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                <div>Chi tiết / Chỉnh sửa</div>
                              </div>
                            </button>

                            <button
                              className="flex w-full h-full hover:text-red-500"
                              onClick={() =>
                                _onClickDelete_TIEUCHIDANHGIA(
                                  item.id,
                                  item.matieuchi
                                )
                              }
                            >
                              <div className="flex w-full space-x-1">
                                <DeleteTwoToneIcon
                                  className="text-red-600"
                                  sx={{ fontSize: 18 }}
                                  fontSize="small"
                                />

                                <div className="h-full">Xoá</div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SettingTargetScoring;
