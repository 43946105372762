import axios from "axios";
import { VOTING_LIST_NEED_TO_JOIN, VOTING_LIST_REQUEST } from "../apis/_index";

import { toast } from "react-toastify";

export const VotingListNeedToJoin = async (cb: (res: any) => void) => {
  const accessToken = localStorage.getItem("accessToken");

  try {
    await axios
      .post(
        VOTING_LIST_NEED_TO_JOIN,
        {
          Page: 0,
          Limit: 10,
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.StatusCode === 200) {
          cb(res);
        }
      });
  } catch (error) {
    toast.error("Tải dữ liệu thất bại");
  }
};

export const VotingListRequest = async (cb: (res: any) => void) => {
  const accessToken = localStorage.getItem("accessToken");

  try {
    await axios
      .post(
        VOTING_LIST_REQUEST,
        {
          Page: 0,
          Limit: 10,
        },
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.StatusCode === 200) {
          cb(res);
        }
      });
  } catch (error) {
    toast.error("Tải dữ liệu thất bại");
  }
};

// funcs
export const VotingListNeedToJoinWithParams =  async (
  page: any,
  limit: any,
  cb: (res: any) => void,
  taskName: string,
  idNguoiNhan: any
) => {
  const accessToken = localStorage.getItem("accessToken");

  const body = taskName === undefined || taskName === null ? {
    Page: page,
    Limit: limit,
    Search_UserID: idNguoiNhan === -1 ? "" : idNguoiNhan
  } : {
    Page: page,
    Limit: limit,
    Search_TaskName: taskName,
    Search_UserID: idNguoiNhan === -1 ? "" : idNguoiNhan
  }

  try {
    await axios
      .post(
        VOTING_LIST_NEED_TO_JOIN,
        body,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.StatusCode === 200) {
          cb(res);
        }
      });
  } catch (error) {
    toast.error("Tải dữ liệu thất bại");
  }
}

export const VotingListRequestWithParams = async (
  page: any,
  limit: any,
  cb: (res: any) => void,
  taskName: string,
  idNguoiNhan: any
) => {
  const accessToken = localStorage.getItem("accessToken");

  const body = taskName === undefined || taskName === null ? {
    Page: page,
    Limit: limit,
    Search_UserID: idNguoiNhan === -1 ? "" : idNguoiNhan
  } : {
    Page: page,
    Limit: limit,
    Search_TaskName: taskName,
    Search_UserID: idNguoiNhan === -1 ? "" : idNguoiNhan
  }

  try {
    await axios
      .post(
        VOTING_LIST_REQUEST,
        body,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data.StatusCode === 200) {
          cb(res);
        }
      });
  } catch (error) {
    toast.error("Tải dữ liệu thất bại");
  }
};
