import { get, postNotoken } from "../ApiHelper/index"
import { FORGOT_PASSWORD } from "../apis/_index";

export const ForgotPassword = (body: any) => {
    return new Promise(async resolve => {
        return await postNotoken(FORGOT_PASSWORD, body)
            .then((res: any) => {

                resolve({
                    status: true,
                    data: res
                });
            })
            .catch((err: any) => {
                resolve({
                    status: false,
                    err: err
                });
            })
    })
}