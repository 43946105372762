import React, { useState, useContext, memo, useEffect } from "react";
import { ModalController } from "../../homeUI/_index";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CheckIcon from "@mui/icons-material/Check";

import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import VpnKeyTwoToneIcon from "@mui/icons-material/VpnKeyTwoTone";
import TvTwoToneIcon from "@mui/icons-material/TvTwoTone";
import MeetingRoomTwoToneIcon from "@mui/icons-material/MeetingRoomTwoTone";
import ImportContactsRoundedIcon from "@mui/icons-material/ImportContactsRounded";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";

import { Notification } from "../../../utils/notification";
import { Start } from "../../../utils/signalR";

import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import MailIcon from "@mui/icons-material/Mail";

import { NGUOI_GIAO_VIEC_DETAIL } from "../../../apis/_index";

import { useNavigate } from "react-router-dom";

// drawer notification
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
//import {} from "../../../../public/"

// temp
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import ModalBase from "../../../components/modal/_index";

import {
  ListNoti,
  ReadAllNoti,
  ReadAllSubNoti,
  ReadNoti,
  TotalNoti,
} from "../../../action/notificationAction";
import {
  ONCHANGE_NOTI,
  ONLOAD_RESET_NOTI,
  ONCHANGE_NOTI_PLUS1,
} from "../../../redux/reducers/_appReducer/_index";
import { toast } from "react-toastify";
import axios from "axios";
import { set } from "date-fns";

type Anchor = "right";

const fileHuongDan: any = require("../../../assets/huongdan.pdf");

const HeaderUI = () => {
  // temp
  const data = useSelector((state: any) => state.appReducer);
  const dispatch = useDispatch();

  const userData_: any = localStorage.getItem("userData");
  const token: any = localStorage.getItem("accessToken");

  const context = useContext(ModalController);

  const _userData: any = (JSON.parse(userData_) || {}).Data || [];
  const userData: any = _userData[0] || {};
  //Notification((res: any) => dispatch(ONCHANGE_NOTI(res)))

  // console.log(data.totalNoti);

  // drawer
  const [state, setState] = React.useState({
    right: false,
  });
  const [listNotiRead, setListNotiRead] = useState<any[] | null>(null);
  const [listNotiNew, setListNotiNew] = useState<any[] | null>(null);
  const userDataApp = useSelector((state: any) => state.appReducer);

  const [listNhacViec, setListNhacViec] = useState<any[] | null>(null);
  const [listNhacViecHeThong, setListNhacViecHeThong] = useState<any[] | null>(
    null
  );
  const [listNhacBuocHeThong, setListNhacBuocHeThong] = useState<any[] | null>(
    null
  );
  const [listNhacKiemTraKetQua, setListNhacKiemTraKetQua] = useState<
    any[] | null
  >(null);
  const [listNhacChuyenNguoiDanhGia, setListNhacChuyenNguoiDanhGia] = useState<
    any[] | null
  >(null);
  const [listNhanViec, setListNhanViec] = useState<any[] | null>(null);
  const [listGiaoViec, setListGiaoViec] = useState<any[] | null>(null);
  const [listKetQuaBuoc, setListKetQuaBuoc] = useState<any[] | null>(null);
  const [listHoanThanhViec, setListHoanThanhViec] = useState<any[] | null>(
    null
  );
  const [listHoanThanhBuoc, setListHoanThanhBuoc] = useState<any[] | null>(
    null
  );
  const [listHoanThanhKQ, setListHoanThanhKQ] = useState<any[] | null>(null);
  const [listTraViec, setListTraViec] = useState<any[] | null>(null);
  const [listBatDauViec, setListBatDauViec] = useState<any[] | null>(null);
  const [listGiaHan, setListGiaHan] = useState<any[] | null>(null);
  const [listDuyetGiaHan, setListDuyetGiaHan] = useState<any[] | null>(null);
  const [listNhanTin, setListNhanTin] = useState<any[] | null>(null);
  const [listDuyetViec, setListDuyetViec] = useState<any[] | null>(null);
  const [listChuyenViec, setListChuyenViec] = useState<any[] | null>(null);
  const [listDeXuat, setListDeXuat] = useState<any[] | null>(null);
  const [listDuyetDeXuat, setListDuyetDeXuat] = useState<any[] | null>(null);
  const [listKoDuyetDeXuat, setListKoDuyetDeXuat] = useState<any[] | null>(
    null
  );

  useEffect(() => {
    if (token) {
      listNotiCall();
    }
  }, []);

  const listNotiCall = () => {
    if (token) {
      ListNoti(token).then((res: any) => {
        const dataList = ((res || {}).data || {}).data || [];
        let _listRead: any[] = [];
        let _listNew: any[] = [];

        let _listNhacViec: any[] = [];
        let _listNhacViecHeThong: any[] = [];
        let _listNhacBuocHeThong: any[] = [];
        let _listNhacKiemTraKetQua: any[] = [];
        let _listChuyenNguoiDanhGia: any[] = [];
        let _listNhanViec: any[] = [];
        let _listGiaoViec: any[] = [];
        let _listKetQuaBuoc: any[] = [];
        let _listHoanThanhViec: any[] = [];
        let _listHoanThanhBuoc: any[] = [];
        let _listHoanThanhKQ: any[] = [];
        let _listTraViec: any[] = [];
        let _listBatDauViec: any[] = [];
        let _listGiaHan: any[] = [];
        let _listDuyetGiaHan: any[] = [];
        let _listNhanTin: any[] = [];
        let _listDuyetViec: any[] = [];
        let _listChuyenViec: any[] = [];
        let _listDeXuat: any[] = [];
        let _listDuyetDeXuat: any[] = [];
        let _listKoDuyetDeXuat: any[] = [];

        dataList.map((item: any, index: any) => {
          if (item.IsRead) {
            _listRead.push(item);
          } else {
            //   _listNew.push(item);
            // }
            if (item.TemplateID === 1) {
              _listNhacViec.push(item);
            } else if (item.TemplateID === 2) {
              _listNhanViec.push(item);
            } else if (item.TemplateID === 3) {
              _listGiaoViec.push(item);
            } else if (item.TemplateID === 4) {
              _listKetQuaBuoc.push(item);
            } else if (item.TemplateID === 8) {
              _listHoanThanhViec.push(item);
            } else if (item.TemplateID === 9) {
              _listHoanThanhBuoc.push(item);
            } else if (item.TemplateID === 10) {
              _listHoanThanhKQ.push(item);
            } else if (item.TemplateID === 12) {
              _listTraViec.push(item);
            } else if (item.TemplateID === 13) {
              _listBatDauViec.push(item);
            } else if (item.TemplateID === 14) {
              _listGiaHan.push(item);
            } else if (item.TemplateID === 15) {
              _listNhanTin.push(item);
            } else if (item.TemplateID === 16) {
              _listDuyetViec.push(item);
            } else if (item.TemplateID === 17) {
              _listNhacViecHeThong.push(item);
            } else if (item.TemplateID === 18) {
              _listChuyenViec.push(item);
            } else if (item.TemplateID === 19) {
              _listDeXuat.push(item);
            } else if (item.TemplateID === 20) {
              _listDuyetDeXuat.push(item);
            } else if (item.TemplateID === 21) {
              _listKoDuyetDeXuat.push(item);
            } else if (item.TemplateID === 22) {
              _listNhacBuocHeThong.push(item);
            } else if (item.TemplateID === 23) {
              _listNhacKiemTraKetQua.push(item);
            } else if (item.TemplateID === 24) {
              _listDuyetGiaHan.push(item);
            } else if (item.TemplateID === 27) {
              _listChuyenNguoiDanhGia.push(item)
            }
          }
        });

        // listNotiNew?.map((item: any, index: any) => {
        //   if (item.TemplateID === 1) {
        //     _listNhacViec.push(item);
        //   } else if (item.TemplateID === 2) {
        //     _listNhanViec.push(item);
        //   } else if (item.TemplateID === 3) {
        //     _listGiaoViec.push(item);
        //   } else if (item.TemplateID === 4) {
        //     _listKetQuaBuoc.push(item);
        //   } else if (item.TemplateID === 8) {
        //     _listHoanThanhViec.push(item);
        //   } else if (item.TemplateID === 9) {
        //     _listHoanThanhBuoc.push(item);
        //   } else if (item.TemplateID === 10) {
        //     _listHoanThanhKQ.push(item);
        //   } else if (item.TemplateID === 12) {
        //     _listTraViec.push(item);
        //   } else if (item.TemplateID === 13) {
        //     _listBatDauViec.push(item);
        //   } else if (item.TemplateID === 14) {
        //     _listGiaHan.push(item);
        //   } else if (item.TemplateID === 15) {
        //     _listNhanTin.push(item);
        //   } else if (item.TemplateID === 16) {
        //     _listDuyetViec.push(item);
        //   } else if (item.TemplateID === 17) {
        //     _listNhacViecHeThong.push(item);
        //   } else if (item.TemplateID === 18) {
        //     _listChuyenViec.push(item);
        //   } else if (item.TemplateID === 19) {
        //     _listDeXuat.push(item);
        //   } else if (item.TemplateID === 20) {
        //     _listDuyetDeXuat.push(item);
        //   } else if (item.TemplateID === 21) {
        //     _listKoDuyetDeXuat.push(item);
        //   } else if (item.TemplateID === 22) {
        //     _listNhacBuocHeThong.push(item);
        //   } else if (item.TemplateID === 23) {
        //     _listNhacKiemTraKetQua.push(item);
        //   } else if (item.TemplateID === 24) {
        //     _listDuyetGiaHan.push(item);
        //   }
        // });
        setListNotiRead(_listRead);
        setListNotiNew(_listNew);

        setListNhacViec(_listNhacViec);
        setListNhanViec(_listNhanViec);
        setListGiaoViec(_listGiaoViec);
        setListKetQuaBuoc(_listKetQuaBuoc);
        setListHoanThanhViec(_listHoanThanhViec);
        setListHoanThanhBuoc(_listHoanThanhBuoc);
        setListHoanThanhKQ(_listHoanThanhKQ);
        setListTraViec(_listTraViec);
        setListBatDauViec(_listBatDauViec);
        setListGiaHan(_listGiaHan);
        setListDuyetGiaHan(_listDuyetGiaHan);
        setListNhanTin(_listNhanTin);
        setListDuyetViec(_listDuyetViec);
        setListNhacViecHeThong(_listNhacViecHeThong);
        setListNhacBuocHeThong(_listNhacBuocHeThong);
        setListNhacKiemTraKetQua(_listNhacKiemTraKetQua);
        setListNhacChuyenNguoiDanhGia(_listChuyenNguoiDanhGia)
        setListChuyenViec(_listChuyenViec);
        setListDeXuat(_listDeXuat);
        setListDuyetDeXuat(_listDuyetDeXuat);
        setListKoDuyetDeXuat(_listKoDuyetDeXuat);
      });
    }
  };

  const readNoti = (id: any) => {
    if (userData) {
      if (token) {
        ReadNoti(id, userData.Id, token).then((res) => {
          listNotiCall();
          TotalNoti(token).then((res: any) => {
            dispatch(ONCHANGE_NOTI(res.data.data));
          });
        });
      }
    }
  };

  const _onClickViewPopup = async (item: any) => {
    context.setTaskId(item.TaskId);

    let tempTaskStatus: any = null;
    let templatetId: any = null;

    const API = NGUOI_GIAO_VIEC_DETAIL(item.TaskId);
    const accessToken = localStorage.getItem("accessToken");

    try {
      await axios
        .get(API, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        })
        .then((res: any) => {
          if (res.data.StatusCode === 200) {
            tempTaskStatus = res.data.Data.task.TaskStatus;
            templatetId = res.data.Data.task.TemplateID;
          }
        });
    } catch (error: any) {
      toast.error("Tải dữ liệu thất bại");
    }

    if (tempTaskStatus === null) {
      return;
    } else {
      // context.setFuncs(
      //   tempTaskStatus === 1
      //     ? "readTotalTask"
      //     : tempTaskStatus === 2 || tempTaskStatus === 7
      //     ? "taskDetailOnly"
      //     : item.TemplateID === 14 || item.TemplateID === 24
      //     ? "readHistoryExtend"
      //     : item.TemplateID === 8 ||
      //       item.TemplateID === 23 ||
      //       item.TemplateID === 9
      //     ? "taskNeedConfirm"
      //     : item.TemplateID === 2 || item.TemplateID === 13
      //     ? "taskDetailOnly"
      //     : item.TemplateID === 1 ||
      //       item.TemplateID === 3 ||
      //       item.TemplateID === 17 ||
      //       item.TemplateID === 22 ||
      //       item.TemplateID === 4 ||
      //       tempTaskStatus === 3
      //     ? "receiveTaskDetail"
      //     : item.TemplateID === 16
      //     ? "readTotalTask"
      //     : item.TemplateID === 19 ||
      //       item.TemplateID === 20 ||
      //       item.TemplateID === 21
      //     ? "proposeDetail"
      //     : null
      // );
      context.setFuncs(
        tempTaskStatus === 1
          ? "readTotalTask"
          : tempTaskStatus === 2 || tempTaskStatus === 7
            ? "taskDetailOnly"
            : tempTaskStatus === 3 || tempTaskStatus === 4
              ? "receiveTaskDetail"
              : tempTaskStatus === 5
                ? "readTotalTask"
                : item.TemplateID === 14 || item.TemplateID === 24
                  ? "readHistoryExtend"
                  : item.TemplateID === 8 ||
                    item.TemplateID === 23 ||
                    item.TemplateID === 9
                    ? "taskNeedConfirm"
                    : item.TemplateID === 19 ||
                      item.TemplateID === 20 ||
                      item.TemplateID === 21
                      ? "proposeDetail"
                      : null
      );
      // context.setFuncs(
      //   item.TemplateID === 1 || item.TemplateID === 17
      //     ? "readTotalTask"
      //     : item.TemplateID === 2
      //       ? "readTotalTask_nguoigiao"
      //       : tempTaskStatus === 2
      //         ? "taskDetailOnly"
      //         : item.TemplateID === 4
      //           ? "readTotalTask"
      //           : item.TemplateID === 8
      //             ? "taskNeedConfirm"
      //             : item.TemplateID === 9
      //               ? "readTotalTask_nguoigiao"
      //               : item.TemplateID === 10
      //                 ? "readTotalTask"
      //                 : item.TemplateID === 12
      //                   ? "editTask"
      //                   : item.TemplateID === 13
      //                     ? "readTotalTask_nguoigiao"
      //                     : item.TemplateID === 14
      //                       ? "readTotalTask_nguoigiao"
      //                       : item.TemplateID === 15
      //                         ? "receiveTaskDetail"
      //                         : item.TemplateID === 16
      //                           ? "readTotalTask"
      //                           : ""
      // );
    }

    // context.setFuncs(
    //   item.TemplateID === 1 ? "readTotalTask"
    //     : item.TemplateID === 2 ? "readTotalTask_nguoigiao"
    //       : item.TemplateID === 3 ? "taskDetailOnly"
    //         : item.TemplateID === 4 ? "readTotalTask"
    //           : item.TemplateID === 8 ? "taskNeedConfirm"
    //             : item.TemplateID === 9 ? "readTotalTask_nguoigiao"
    //               : item.TemplateID === 10 ? "readTotalTask"
    //                 : item.TemplateID === 12 ? "editTask"
    //                   : item.TemplateID === 13 ? "readTotalTask_nguoigiao"
    //                     : item.TemplateID === 14 ? "readTotalTask_nguoigiao"
    //                       : item.TemplateID === 16 ? "readTotalTask"
    //                         : ""
    // )

    context.handleOpen();
  };

  const readAllNoti = () => {
    if (userData) {
      if (token) {
        ReadAllNoti(userData.Id, token).then((res) => {
          listNotiCall();
          TotalNoti(token).then((res: any) => {
            dispatch(ONCHANGE_NOTI(res.data.data));
          });
        });
      }
    }
  };

  const readAllSubNoti = () => {
    if (userData) {
      if (token) {
        ReadAllSubNoti(userData.Id, token).then((res) => {
          listNotiCall();
          TotalNoti(token).then((res: any) => {
            dispatch(ONCHANGE_NOTI(res.data.data));
          });
        });
      }
    }
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        if (token) {
          listNotiCall();
          TotalNoti(token).then((res: any) => {
            dispatch(ONCHANGE_NOTI(res.data.data));
          });
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <div className="space-x-2 w-[270px]">
      <div className="p-1 flex justify-between">
        <p className="font-bold">Thông báo</p>
        <button
          className="flex hover:font-bold"
          onClick={() => {
            readAllNoti();
          }}
        >
          <p>Đọc tất cả</p>
          <CheckIcon fontSize="small" />
        </button>
      </div>
      {listNhanViec !== null && listNhanViec.length > 0 && (
        <details className="pr-2">
          <summary className="text-emerald-500">
            Công việc vừa được nhận&nbsp;({listNhanViec?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-emerald-500 hover:font-bold"
                onClick={() => {
                  readAllSubNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listNhanViec.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="w-full bg-emerald-50 rounded-md border-dashed border-b-2 border-white"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listTraViec !== null && listTraViec.length > 0 && (
        <details className="pr-2">
          <summary className="text-red-500">
            Việc bị trả lại&nbsp;({listTraViec?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-red-500 hover:font-bold"
                onClick={() => {
                  readAllSubNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listTraViec?.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-red-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listGiaoViec !== null && listGiaoViec.length > 0 && (
        <details className="pr-2">
          <summary className="text-primary">
            Việc được giao&nbsp;({listGiaoViec?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-primary hover:font-bold"
                onClick={() => {
                  readAllSubNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listGiaoViec.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-blue-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listNhacViec !== null && listNhacViec.length > 0 && (
        <details className="pr-2 ">
          <summary className="text-amber-500">
            Nhắc nhở công việc&nbsp;({listNhacViec?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-amber-500 hover:font-bold"
                onClick={() => {
                  readAllSubNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listNhacViec.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-amber-50 rounded-md border-dashed border-b-2 border-white"
                >
                  <ListItemButton
                    className="w-full"
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listNhacBuocHeThong !== null && listNhacBuocHeThong.length > 0 && (
        <details className="pr-2 ">
          <summary className="text-amber-500">
            Nhắc bước công việc&nbsp;({listNhacBuocHeThong?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-amber-500 hover:font-bold"
                onClick={() => {
                  readAllSubNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listNhacBuocHeThong.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-amber-50 rounded-md border-dashed border-b-2 border-white"
                >
                  <ListItemButton
                    className="w-full"
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listNhacKiemTraKetQua !== null && listNhacKiemTraKetQua.length > 0 && (
        <details className="pr-2 ">
          <summary className="text-amber-500">
            Hệ thống nhắc duyệt công việc&nbsp;({listNhacKiemTraKetQua?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-amber-500 hover:font-bold"
                onClick={() => {
                  readAllSubNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listNhacKiemTraKetQua.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-amber-50 rounded-md border-dashed border-b-2 border-white"
                >
                  <ListItemButton
                    className="w-full"
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listNhacChuyenNguoiDanhGia !== null && listNhacChuyenNguoiDanhGia.length > 0 && (
        <details className="pr-2 ">
          <summary className="text-violet-400">
            Chuyển người đánh giá&nbsp;({listNhacChuyenNguoiDanhGia?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-amber-500 hover:font-bold"
                onClick={() => {
                  readAllSubNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listNhacChuyenNguoiDanhGia.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-amber-50 rounded-md border-dashed border-b-2 border-white"
                >
                  <ListItemButton
                    className="w-full"
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listNhanTin !== null && listNhanTin.length > 0 && (
        <details className="pr-2">
          <summary className="text-red-500">
            Trao đổi thông tin&nbsp;({listNhanTin?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-red-500 hover:font-bold"
                onClick={() => {
                  readAllSubNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listNhanTin?.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-red-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listBatDauViec !== null && listBatDauViec.length > 0 && (
        <details className="pr-2">
          <summary className="text-primary">
            Công việc vừa bắt đầu&nbsp;({listBatDauViec?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-primary hover:font-bold"
                onClick={() => {
                  readAllSubNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listBatDauViec?.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-blue-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listKetQuaBuoc !== null && listKetQuaBuoc.length > 0 && (
        <details className="pr-2">
          <summary className="text-yellow-500">
            Trả kết quả bước&nbsp;({listKetQuaBuoc?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-yellow-500 hover:font-bold"
                onClick={() => {
                  readAllNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listKetQuaBuoc.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-yellow-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listHoanThanhBuoc !== null && listHoanThanhBuoc.length > 0 && (
        <details className="pr-2">
          <summary className="text-emerald-500">
            Bước công việc đã hoàn thành&nbsp;({listHoanThanhBuoc?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-emerald-500 hover:font-bold"
                onClick={() => {
                  readAllNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listHoanThanhBuoc.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-emerald-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listHoanThanhKQ !== null && listHoanThanhKQ.length > 0 && (
        <details className="pr-2">
          <summary className="text-emerald-500">
            Các kết quả đã hoàn thành&nbsp;({listHoanThanhKQ?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-emerald-500 hover:font-bold"
                onClick={() => {
                  readAllNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listHoanThanhKQ?.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-emerald-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listHoanThanhViec !== null && listHoanThanhViec.length > 0 && (
        <details className="pr-2">
          <summary className="text-emerald-500">
            Công việc đã hoàn thành&nbsp;({listHoanThanhViec?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-emerald-500 hover:font-bold"
                onClick={() => {
                  readAllNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listHoanThanhViec.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-emerald-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listDuyetViec !== null && listDuyetViec.length > 0 && (
        <details className="pr-2">
          <summary className="text-emerald-500">
            Công việc chờ duyệt&nbsp;({listDuyetViec?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-emerald-500 hover:font-bold"
                onClick={() => {
                  readAllNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listDuyetViec.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="w-full bg-emerald-50 rounded-md border-dashed border-b-2 border-white"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listGiaHan !== null && listGiaHan.length > 0 && (
        <details className="pr-2">
          <summary className="text-primary">
            Danh sách xin gia hạn&nbsp;({listGiaHan?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-primary hover:font-bold"
                onClick={() => {
                  readAllNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listGiaHan.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-blue-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listDuyetGiaHan !== null && listDuyetGiaHan.length > 0 && (
        <details className="pr-2">
          <summary className="text-primary">
            Gia hạn được duyệt&nbsp;({listDuyetGiaHan?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-primary hover:font-bold"
                onClick={() => {
                  readAllNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listDuyetGiaHan.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-blue-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listNhacViecHeThong !== null && listNhacViecHeThong.length > 0 && (
        <details className="pr-2 ">
          <summary className="text-amber-500">
            Thông báo từ hệ thống&nbsp;({listNhacViecHeThong?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-amber-500 hover:font-bold"
                onClick={() => {
                  readAllNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listNhacViecHeThong.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-amber-50 rounded-md border-dashed border-b-2 border-white"
                >
                  <ListItemButton
                    className="w-full"
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listChuyenViec !== null && listChuyenViec.length > 0 && (
        <details className="pr-2">
          <summary className="text-primary">
            Công việc đã chuyển&nbsp;({listChuyenViec?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-primary hover:font-bold"
                onClick={() => {
                  readAllNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listChuyenViec.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-blue-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listDeXuat !== null && listDeXuat.length > 0 && (
        <details className="pr-2">
          <summary className="text-indigo-500">
            Đề xuất mới&nbsp;({listDeXuat?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-indigo-500 hover:font-bold"
                onClick={() => {
                  readAllNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listDeXuat.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-indigo-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listDuyetDeXuat !== null && listDuyetDeXuat.length > 0 && (
        <details className="pr-2">
          <summary className="text-indigo-500">
            Đề xuất được duyệt&nbsp;({listDuyetDeXuat?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-indigo-500 hover:font-bold"
                onClick={() => {
                  readAllNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listDuyetDeXuat.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-indigo-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {listKoDuyetDeXuat !== null && listKoDuyetDeXuat.length > 0 && (
        <details className="pr-2">
          <summary className="text-red-500">
            Đề xuất không duyệt&nbsp;({listKoDuyetDeXuat?.length})
          </summary>
          <Box
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
          // onKeyDown={toggleDrawer(anchor, false)}
          >
            <div className="p-1 flex justify-end">
              {/* <button
                className="flex hover:text-red-500 hover:font-bold"
                onClick={() => {
                  readAllNoti();
                }}
              >
                <p>Đọc tất cả</p>
                <CheckIcon fontSize="small" />
              </button> */}
            </div>

            <List>
              {listKoDuyetDeXuat?.map((item: any, index: any) => (
                <ListItem
                  key={index}
                  disablePadding
                  className="bg-red-50 rounded-md border-dashed border-b-2"
                >
                  <ListItemButton
                    onClick={() => {
                      readNoti(item.ID);
                      _onClickViewPopup(item);
                    }}
                  >
                    <ListItemText primary={item.Content || ""} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </details>
      )}

      {/* <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ padding: 5, fontWeight: "bold" }}>Chưa đọc</div>
          <button
            style={{ padding: 5 }}
            className="hover:bg-primary hover:text-white"
            onClick={() => {
              readAllNoti();
            }}
          >
            <b>Đọc tất cả</b>
          </button>
        </div>

        <List>
          {listNotiNew === null || typeof listNotiNew === "undefined" ? (
            <></>
          ) : (
            listNotiNew.map((item: any, index: any) => (
              <ListItem
                key={index}
                disablePadding
                className={`${
                  item.TemplateID === 1 ||
                  item.TemplateID === 4 ||
                  item.TemplateID === 17
                    ? "bg-yellow-50"
                    : item.TemplateID === 3 ||
                      item.TemplateID === 13 ||
                      item.TemplateID === 14 ||
                      item.TemplateID === 18
                    ? "bg-sky-50"
                    : item.TemplateID === 2 ||
                      item.TemplateID === 8 ||
                      item.TemplateID === 9 ||
                      item.TemplateID === 10 ||
                      item.TemplateID === 16
                    ? "bg-green-50"
                    : item.TemplateID === 12 ||
                      item.TemplateID === 21 ||
                      item.TemplateID === 15
                    ? "bg-red-50"
                    : item.TemplateID === 19 || item.TemplateID === 20
                    ? "bg-indigo-100"
                    : null
                }`}
              >
                <ListItemButton
                  onClick={() => {
                    readNoti(item.ID);
                    _onClickViewPopup(item);
                  }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {item.TemplateID === 1 ? (
                    <></>
                  ) : item.TemplateID === 2 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-center px-2 py-0.1 bg-emerald-500 text-white rounded-md">
                          Nhận việc
                        </p>
                      </div>
                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 3 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-center px-2 py-0.1 bg-primary text-white rounded-md">
                          Giao việc
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 4 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-sm text-center w-max p-1.5 bg-yellow-500 text-white rounded-md">
                          Kết quả bước
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 8 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-xs text-center w-full mx-2 py-1.5 bg-emerald-500 text-white rounded-md">
                          Hoàn tất công việc
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 9 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-sm text-center w-full mx-2 py-1.5 bg-emerald-500 text-white rounded-md">
                          Hoàn thành bước
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 10 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-sm text-center w-full mx-2 py-1.5 bg-emerald-500 text-white rounded-md">
                          Hoàn thành kết quả
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 12 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-center px-2 py-0.1 bg-red-500 text-white rounded-md">
                          Trả việc
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 13 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-xs text-center w-max p-1.5 bg-primary text-white rounded-md">
                          Bắt đầu công việc
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 14 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-center px-2 py-0.1 bg-primary text-white rounded-md">
                          Xin gia hạn
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 15 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-center px-2 py-0.1 bg-rose-900 text-white rounded-md">
                          Tin nhắn
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 16 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-center px-2 py-0.1 bg-emerald-500 text-white rounded-md">
                          Duyệt công việc
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 17 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-center px-2 py-0.1 bg-amber-500 text-white rounded-md">
                          Nhắc việc
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 18 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-center px-2 py-0.1 bg-primary text-white rounded-md">
                          Chuyển việc
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 19 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-xs text-center w-max p-1.5 bg-indigo-500 text-white rounded-md">
                          Đề xuất công việc
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 20 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-sm text-center w-max p-1.5 bg-violet-500 text-white rounded-md">
                          Duyệt đề xuất
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : item.TemplateID === 21 ? (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <i
                          style={{
                            fontWeight: "bold",
                            color: "gray",
                            fontSize: 13,
                          }}
                        >
                          {moment(item.DateAdd).format("HH:mm DD/MM/yyyy")}
                        </i>
                        <p className="text-xs text-center w-max p-1.5 bg-red-500 text-white rounded-md">
                          Không duyệt đề xuất
                        </p>
                      </div>

                      <ListItemText primary={item.Content || ""} />
                    </>
                  ) : null}
                </ListItemButton>
              </ListItem>
            ))
          )}
        </List>
      </Box> */}
    </div>
  );
  // -- can remove line code from above
  // end drawer

  const navigate = useNavigate();
  const accessToken = localStorage.getItem("accessToken");
  const stringUrl = "https://isolaco-hd.isopro.vn/?access_token=" + accessToken;
  // state
  const [isShowUserOptions, setIsShowUserOptions] = useState(false);

  // funcs
  const _onChangeIsShowUserOptions = () => {
    if (isShowUserOptions === false) {
      setIsShowUserOptions(true);
    } else {
      setIsShowUserOptions(false);
    }
  };

  const AccountIF = () => {
    _onChangeIsShowUserOptions();
    context.setFuncs("AccountIF");
    context.handleOpen();
  };

  const ChangePassword = () => {
    _onChangeIsShowUserOptions();
    context.setFuncs("ChangePassword");
    context.handleOpen();
  };

  const _logout = () => {
    // localStorage.removeItem('menuList');
    // localStorage.removeItem('accessToken');
    // localStorage.removeItem('userData');
    dispatch(ONLOAD_RESET_NOTI());
    // sessionStorage.clear();
    // localStorage.clear();
    // navigate("/login", { replace: true });
  };
  // console.log(userData_);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>

      <div className="header justify-between flex items-center w-full h-[2.7rem] border-b-2 border-slate-200">
        {/* logo */}
        <div className=" flex justify-center items-cente flex-col ml-2 w-1/2 h-full">
          <a className="lg:w-[3rem] lg:ml-4 w-10" href="../../allPage">
            <img src="/favicon.ico" alt="image" />
            {/* <p>GIAO VIỆC 4.0</p> */}
            {/* <p className="text-xs">UBND PHUONG XA</p> */}
          </a>
        </div>

        {/* username button */}
        <div className="flex items-center space-x-3 mr-2 w-max h-max">
          <div>
            <a
              className="
        w-max h-[1.9rem] bg-slate-200 rounded-full shadow-sm
        flex justify-center items-center pl-2 pr-2 pt-1.5 pb-2
        text-primary hover:shadow-md hover:bg-primary hover:text-white
        transition-all delay-75 active:shadow-inner active:shadow-slate-500 
      "
              // href={stringUrl}
              href={fileHuongDan}
              target="_blank"
              rel="noreferrer"
            >
              <ImportContactsRoundedIcon /> &nbsp;
              <div className="lg:flex hidden">
                <p className="text-sm "> Xem hướng dẫn</p>
              </div>
              <div className="lg:hidden flex">
                <p className="text-xs">Hướng dẫn</p>
              </div>
            </a>
          </div>

          {/* <div>
            <a
              className="
        w-max h-[1.9rem] bg-slate-200 rounded-full shadow-sm
        flex justify-center items-center pl-2 pr-2
        text-primary hover:bg-primary hover:text-white
        transition-all delay-75 active:shadow-inner active:shadow-slate-500 
      "
              href={stringUrl}
              target="_blank"
              rel="noreferrer"
            >
              <div className="lg:flex hidden">
                <p className="text-sm">Quản lý tài chính</p>
              </div>

              <div className="lg:hidden flex">
                <p className="text-xs">Tài chính</p>
              </div>
            </a>
          </div> */}

          <button
            className="
        w-[1.9rem] h-[1.9rem] bg-slate-200 rounded-full shadow-sm
        flex justify-center items-center
        text-primary hover:bg-primary hover:text-white hover:shadow-md
        transition-all delay-75 active:shadow-inner active:shadow-slate-500 
      "
            onClick={toggleDrawer("right", true)}
          >
            <Badge
              badgeContent={data.totalNoti}
              color="error"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <div className="text-primary">
                <NotificationsIcon color="primary" />
              </div>
            </Badge>
          </button>

          <div className="relative inline-block h-max w-max">
            <button
              className="
            flex justify-center items-center w-max h-max
            pt-1 pb-1 pr-2 pl-2 bg-primary text-white rounded-md hover:bg-sky-400 hover:shadow-md space-x-1
            transition-all delay-75 active:shadow-inner active:shadow-slate-500 
          "
              onClick={_onChangeIsShowUserOptions}
            >
              <AccountCircleIcon fontSize="small" />
              <div className="w-max h-max lg:flex hidden">
                <p className="header-user-name">
                  {userDataApp.userData === null
                    ? ""
                    : userDataApp.userData.FullName}
                </p>
              </div>

              <ArrowDropDownIcon fontSize="small" />
            </button>

            <div
              className={`${isShowUserOptions === true ? "flex flex-col" : "hidden"
                } 
              absolute right-0 z-40 lg:w-full w-max h-max pl-1 pr-1 flex flex-col text-sm 
              bg-slate-200 header-user-logout rounded-sm shadow-md`}
            >
              <div className={"w-full h-full bg-slate-200 text-slate-600"}>
                <div className="lg:hidden flex items-start pt-2 pb-2 w-full space-x-2  bg-slate-100 rounded-sm">
                  <PersonOutlineTwoToneIcon fontSize="small" />
                  <p>
                    {userDataApp.userData === null
                      ? ""
                      : userDataApp.userData.FullName}
                  </p>
                </div>

                <button
                  className="flex items-start pt-2 pb-2 w-full space-x-2 hover:shadow-md hover:bg-sky-300 hover:text-white rounded-sm
                  transition-all delay-75 active:shadow-inner active:shadow-slate-500 "
                  onClick={AccountIF}
                >
                  <InfoTwoToneIcon fontSize="small" />
                  <p>Thông tin tài khoản</p>
                </button>
                <button
                  className="flex items-start pt-2 pb-2 w-full space-x-2 hover:shadow-md hover:bg-sky-300 hover:text-white rounded-sm
                  transition-all delay-75 active:shadow-inner active:shadow-slate-500 "
                  onClick={ChangePassword}
                >
                  <VpnKeyTwoToneIcon fontSize="small" />
                  <p>Đổi mật khẩu</p>
                </button>
                {/* <button className="flex items-start pt-2 pb-2 w-full space-x-2 hover:bg-sky-300 hover:text-white rounded-sm">
                  <TvTwoToneIcon fontSize="small" />
                  <p>Thiết lập màn hình</p>
                </button> */}
                <button
                  className="flex items-start pt-2 pb-2 w-full space-x-2 hover:shadow-md hover:bg-sky-300 hover:text-white rounded-sm
                  transition-all delay-75 active:shadow-inner active:shadow-slate-500 "
                  onClick={() => _logout()}
                >
                  <MeetingRoomTwoToneIcon fontSize="small" />
                  <p>Thoát khỏi hệ thống</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(HeaderUI);
