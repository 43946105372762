import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// funcs
import Badge from '@mui/material/Badge';

// icon components
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DatasetIcon from '@mui/icons-material/Dataset';

import LandslideRoundedIcon from '@mui/icons-material/LandslideRounded';
import ReduceCapacityRoundedIcon from '@mui/icons-material/ReduceCapacityRounded';

import ContentPasteRoundedIcon from '@mui/icons-material/ContentPasteRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import BalanceRoundedIcon from '@mui/icons-material/BalanceRounded';

import Select from "react-select"

// icon
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ReportGmailerrorredRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded';

import FolderSharedIcon from '@mui/icons-material/FolderShared';
import TopicIcon from '@mui/icons-material/Topic';
import FolderIcon from '@mui/icons-material/Folder';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import FlagCircleIcon from '@mui/icons-material/FlagCircle';

// working
// -- sender
import HourglassFullRoundedIcon from '@mui/icons-material/HourglassFullRounded';
import AlarmAddIcon from '@mui/icons-material/AlarmAdd';
import HistoryIcon from '@mui/icons-material/History';
import PunchClockIcon from '@mui/icons-material/PunchClock';

import ThumbsUpDownRoundedIcon from '@mui/icons-material/ThumbsUpDownRounded';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import TaskIcon from '@mui/icons-material/Task';
import FeaturedPlayListRoundedIcon from '@mui/icons-material/FeaturedPlayListRounded';
import AlarmOnRoundedIcon from '@mui/icons-material/AlarmOnRounded';
import HistoryToggleOffRoundedIcon from '@mui/icons-material/HistoryToggleOffRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';

import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import WorkRoundedIcon from '@mui/icons-material/WorkRounded';
import NextWeekRoundedIcon from '@mui/icons-material/NextWeekRounded';
import WorkHistoryRoundedIcon from '@mui/icons-material/WorkHistoryRounded';
import HowToVoteRoundedIcon from '@mui/icons-material/HowToVoteRounded';
import AssignmentReturnRoundedIcon from '@mui/icons-material/AssignmentReturnRounded';
import RestorePageRoundedIcon from '@mui/icons-material/RestorePageRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import BallotIcon from '@mui/icons-material/Ballot';
import FeedIcon from '@mui/icons-material/Feed';

// -- receiver
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';

// -- chart
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import BubbleChartRoundedIcon from '@mui/icons-material/BubbleChartRounded';
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import ShowChartRoundedIcon from '@mui/icons-material/ShowChartRounded';
import SsidChartRoundedIcon from '@mui/icons-material/SsidChartRounded';
import StackedBarChartRoundedIcon from '@mui/icons-material/StackedBarChartRounded';
import StackedLineChartRoundedIcon from '@mui/icons-material/StackedLineChartRounded';
import TableChartRoundedIcon from '@mui/icons-material/TableChartRounded';
import WaterfallChartRoundedIcon from '@mui/icons-material/WaterfallChartRounded';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded';
import DonutLargeRoundedIcon from '@mui/icons-material/DonutLargeRounded';
import DonutSmallRoundedIcon from '@mui/icons-material/DonutSmallRounded';
import LegendToggleRoundedIcon from '@mui/icons-material/LegendToggleRounded';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';

import { TAKE_PARAMETER_LISTKEY_DROPDOWN, USER_READ_ALL } from "../../apis/_index";

// propose
import LightbulbRoundedIcon from '@mui/icons-material/LightbulbRounded';
import TungstenRoundedIcon from '@mui/icons-material/TungstenRounded';
import LoaderSpinner from "../../components/loader-spinner/_index";

import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';

import { toast } from "react-toastify";
import axios from "axios";

// EXTRA MUI
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// cac viec da giao
import TopicRoundedIcon from '@mui/icons-material/TopicRounded';
//CÁC VIỆC CHỜ DUYỆT VÀ ĐÁNH GIÁ KẾT QUẢ
import { AccessTimeFilledOutlined, DoneRounded } from "@mui/icons-material";

// Screens manager
import HighManagerUI from "../../temp/HighManager/HighMangerUI";
import MediumManagerUI from "../../temp/MediumManager/MediumManagerUI";
import NormalStaffUI from "../../temp/Normal/NormalStaff";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const AllUI = () => {

  // state for tabs
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }

  const data = useSelector((state: any) => state.appReducer)

  // useEffect(() => {
  //   if ()
  // }, [])

  const navigate = useNavigate()

  // state
  const [titlePhanquyen, setTitlePhanQuyen] = useState<any | null>(null)
  const [titleThietlap, setTitleThietlap] = useState<any | null>(null)
  const [titleCongviec, setTitleCongviec] = useState<any | null>(null)
  const [titleBaocao, setTitleBaocao] = useState<any | null>(null)
  const [titleDexuat, setTitleDexuat] = useState<any | null>(null)

  const [arrayFormPhanquyen, setArrayFormPhanQuyen] = useState<any[] | null>(null)
  const [arrayFormThietlap, setArrayFormThietlap] = useState<any[] | null>(null)
  const [arrayFormCongviec, setArrayFormCongviec] = useState<any[] | null>(null)
  const [arrayFormBaocao, setArrayFormBaocao] = useState<any[] | null>(null)
  const [arrayFormDexuat, setArrayFormDexuat] = useState<any[] | null>(null)

  // -- funcs
  const [phongBanList, setPhongBanList] = useState<any[] | undefined>(undefined)
  const [userFollowPhongBanList, setUserFollowPhongBanList] = useState<any[] | undefined>(undefined)

  const [selectedPhongBan, setSelectedPhongBan] = useState<any | undefined>(null)
  const [selectedUserFollowPhongBanList, setSelectedUserFollowPhongBanList] = useState<any[] | undefined>()

  // onMount
  useEffect(() => {
    const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_PHONGBAN"
    const accessToken = localStorage.getItem("accessToken")

    const takeData = async () => {
      try {
        await axios.get(
          API,
          {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain"
            }
          }
        )
          .then(res => {
            if (res.data.StatusCode === 200) {
              const tempArray = res.data.Data.map((item: any) => { return { ...item, value: item.ParameterValue, label: item.ParameterValue } })
              setPhongBanList(tempArray)
            } else {
              toast.error(res.data.Message)
            }
          })
      } catch (error: any) {
        toast.error("Tải dữ liệu phòng ban thất bại")
      }
    }

    takeData()
  }, [])

  // onLoadWithSelectedPhongBan
  useEffect(() => {
    if (selectedPhongBan === null) {
      setUserFollowPhongBanList(undefined)
    } else {
      const accessToken = localStorage.getItem("accessToken")

      const takeData = async () => {
        try {
          await axios.get(
            USER_READ_ALL,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain"
              }
            }
          )
            .then(res => {
              if (res.data.StatusCode === 200) {
                setUserFollowPhongBanList(res.data.Data.filter((item: any) => item.PhongBan === selectedPhongBan))
      
              } else {
                toast.error(res.data.Message)
              }
            })
        } catch (error: any) {
          toast.error("Tải dữ liệu phòng ban thất bại")
        }
      }

      takeData()
    }
  }, [selectedPhongBan])

  // funcs
  const _onChangePhongBan = (data: any) => {
    if (data === null) {
      setSelectedPhongBan(null)
    } else {
      setSelectedPhongBan(data.ParameterId)
    }
  }
  // -- end funcs

  const shouldLog = useRef(true)

  // load data
  useEffect(() => {
    if (data.mainMenu.length === 0 || data.subMenu.length === 0) {
      // do nothing
    } else {
      if (shouldLog.current === true) {
        shouldLog.current = false

        setTitlePhanQuyen(data.mainMenu.find((item: any) => item.FormId === 1))
        setTitleThietlap(data.mainMenu.find((item: any) => item.FormId === 2))
        setTitleCongviec(data.mainMenu.find((item: any) => item.FormId === 3))
        setTitleBaocao(data.mainMenu.find((item: any) => item.FormId === 4))
        setTitleDexuat(data.mainMenu.find((item: any) => item.FormId === 59))

        setArrayFormPhanQuyen(data.subMenu.filter((item: any) => item.ParentFormId === 1))
        setArrayFormThietlap(data.subMenu.filter((item: any) => item.ParentFormId === 2))
        setArrayFormCongviec(data.subMenu.filter((item: any) => item.ParentFormId === 3))
        setArrayFormBaocao(data.subMenu.filter((item: any) => item.ParentFormId === 4))
        setArrayFormDexuat(data.subMenu.filter((item: any) => item.ParentFormId === 59))
      }
    }
  }, [data.mainMenu, data.subMenu])

  // const WebComponentJSX = () => {
  //   return (
  //     <>
  //       {
  //         permissionRendering === null
  //           || settingRendering1 === null
  //           || settingRendering2 === null
  //           || workingRendering1 === null
  //           || workingRendering2 === null
  //           || workingRendering1Extra === null
  //           || reportRendering1 === null
  //           || reportRendering2 === null
  //           || reportRendering3 === null
  //           || proposeRendering === null

  //           || isShowPermission === null
  //           || isShowSettingRendering1 === null
  //           || isShowSettingRendering2 === null
  //           || isShowWorkingRendering1 === null
  //           || isShowWorkingRendering2 === null
  //           || isShowWorkingRendering1Extra === null
  //           || isShowReportRendering1 === null
  //           || isShowReportRendering2 === null
  //           || isShowReportRendering3 === null
  //           || isShowProposeRendering === null
  //           ?
  //           <div className="flex flex-col w-full h-full space-y-10 justify-center items-center">
  //             <LoaderSpinner w={32} h={32} />
  //             <p>Đang tải dữ liệu</p>
  //           </div>
  //           :
  //           <div className="flex flex-col w-full h-full space-y-10">
  //             {/* Permission */}
  //             {
  //               isShowPermission === false ? <></>
  //                 :
  //                 <div className="flex space-y-3 flex-col w-full h-max text-md">
  //                   <p
  //                     style={{
  //                       fontWeight: "bold",
  //                       color: "rgb(52, 152, 219)"
  //                     }}>
  //                     PHÂN QUYỀN
  //                   </p>

  //                   <div className="w-full h-max flex space-x-5">
  //                     {
  //                       permissionRendering?.map((item: any, index: any) => (
  //                         <>
  //                           {
  //                             item.isShow === true ?
  //                               <button className={`
  //                     flex flex-col justify-center items-center w-[10rem] h-[10rem] bg-slate-300 rounded-lg space-y-2
  //                     hover:bg-slate-200 hover:shadow-md ${item.color}
  //                   `}>
  //                                 {item.icon}
  //                                 <p>{item.name}</p>
  //                               </button>
  //                               :
  //                               <></>
  //                           }
  //                         </>

  //                       ))}
  //                   </div>
  //                 </div>
  //             }

  //             {/* Setting */}
  //             {
  //               isShowSettingRendering1 === false && isShowSettingRendering2 === false ? <></>
  //                 :
  //                 <div className="flex space-y-3 flex-col w-full h-max text-md">
  //                   <p
  //                     style={{
  //                       fontWeight: "bold",
  //                       color: "rgb(52, 152, 219)"
  //                     }}>
  //                     THIẾT LẬP
  //                   </p>

  //                   <div className="w-full h-max flex flex-col space-y-5">
  //                     {
  //                       isShowSettingRendering1 === false ? <></>
  //                         :
  //                         <div className="w-full h-max flex space-x-5">
  //                           {
  //                             settingRendering1?.map((item: any, index: any) => (
  //                               <>
  //                                 {
  //                                   item.isShow === true ?
  //                                     <button className={`
  //                     flex flex-col justify-center items-center w-[10rem] h-[10rem] bg-slate-300 rounded-lg space-y-2
  //                     hover:bg-slate-200 hover:shadow-md ${item.color}
  //                   `}>
  //                                       {item.icon}
  //                                       <p>{item.name}</p>
  //                                     </button>
  //                                     :
  //                                     <></>
  //                                 }
  //                               </>

  //                             ))}
  //                         </div>
  //                     }

  //                     {
  //                       isShowSettingRendering2 === false ? <></>
  //                         :
  //                         <div className="w-full h-max flex space-x-5">
  //                           {
  //                             settingRendering2?.map((item: any, index: any) => (
  //                               <>
  //                                 {
  //                                   item.isShow === true ?
  //                                     <button className={`
  //                     flex flex-col justify-center items-center w-[10rem] h-[10rem] bg-slate-300 rounded-lg space-y-2
  //                     hover:bg-slate-200 hover:shadow-md ${item.color}
  //                   `}>
  //                                       {item.icon}
  //                                       <p>{item.name}</p>
  //                                     </button>
  //                                     :
  //                                     <></>
  //                                 }
  //                               </>

  //                             ))}
  //                         </div>
  //                     }

  //                   </div>
  //                 </div>
  //             }

  //             {/* Working sender */}
  //             {
  //               isShowWorkingRendering1 === false
  //                 && isShowWorkingRendering1Extra === false ? <></>
  //                 :
  //                 <div className="flex space-y-3 flex-col w-full h-max text-md">
  //                   <p
  //                     style={{
  //                       fontWeight: "bold",
  //                       color: "rgb(52, 152, 219)"
  //                     }}>
  //                     VIỆC ĐÃ GIAO
  //                   </p>

  //                   {
  //                     isShowWorkingRendering1 === false ? <></>
  //                       :
  //                       <div className="w-full h-max flex space-x-5">
  //                         {
  //                           workingRendering1?.map((item: any, index: any) => (
  //                             <>
  //                               {
  //                                 item.isShow === true ?
  //                                   <button className={`
  //                     flex flex-col justify-center items-center w-[10rem] h-[10rem] bg-slate-300 rounded-lg space-y-2
  //                     hover:bg-slate-200 hover:shadow-md ${item.color}
  //                   `}>
  //                                     {item.icon}
  //                                     <p>{item.name}</p>
  //                                   </button>
  //                                   :
  //                                   <></>
  //                               }
  //                             </>

  //                           ))}
  //                       </div>
  //                   }

  //                   {
  //                     isShowWorkingRendering1Extra === false ? <></>
  //                       :
  //                       <div className="w-full h-max flex space-x-5">
  //                         {
  //                           workingRendering1Extra?.map((item: any, index: any) => (
  //                             <>
  //                               {
  //                                 item.isShow === true ?
  //                                   <button className={`
  //                     flex flex-col justify-center items-center w-[10rem] h-[10rem] bg-slate-300 rounded-lg space-y-2
  //                     hover:bg-slate-200 hover:shadow-md ${item.color}
  //                   `}>
  //                                     {item.icon}
  //                                     <p>{item.name}</p>
  //                                   </button>
  //                                   :
  //                                   <></>
  //                               }
  //                             </>

  //                           ))}
  //                       </div>
  //                   }

  //                 </div>
  //             }


  //             {/* Working receiver */}
  //             {
  //               isShowWorkingRendering2 === false ? <></>
  //                 :
  //                 <div className="flex space-y-3 flex-col w-full h-max text-md">
  //                   <p
  //                     style={{
  //                       fontWeight: "bold",
  //                       color: "rgb(52, 152, 219)"
  //                     }}>
  //                     VIỆC ĐÃ NHẬN
  //                   </p>

  //                   <div className="w-full h-max flex space-x-5">
  //                     {
  //                       workingRendering2?.map((item: any, index: any) => (
  //                         <>
  //                           {
  //                             item.isShow === true ?
  //                               <button className={`
  //                     flex flex-col justify-center items-center w-[10rem] h-[10rem] bg-slate-300 rounded-lg space-y-2
  //                     hover:bg-slate-200 hover:shadow-md ${item.color}
  //                   `}>
  //                                 {item.icon}
  //                                 <p>{item.name}</p>
  //                               </button>
  //                               :
  //                               <></>
  //                           }
  //                         </>

  //                       ))}
  //                   </div>
  //                 </div>
  //             }


  //             {/* Report 1 */}
  //             {
  //               isShowReportRendering1 === false ? <></>
  //                 :
  //                 <div className="flex space-y-3 flex-col w-full h-max text-md">
  //                   <p
  //                     style={{
  //                       fontWeight: "bold",
  //                       color: "rgb(52, 152, 219)"
  //                     }}>
  //                     BIỂU ĐỒ CÁ NHÂN
  //                   </p>

  //                   <div className="w-full h-max flex flex-col space-y-5">
  //                     <div className="w-full h-max flex space-x-5">
  //                       {
  //                         reportRendering1?.map((item: any, index: any) => (
  //                           <>
  //                             {
  //                               item.isShow === true ?
  //                                 <button className={`
  //                     flex flex-col justify-center items-center w-[10rem] h-[10rem] bg-slate-300 rounded-lg space-y-2
  //                     hover:bg-slate-200 hover:shadow-md ${item.color}
  //                   `}>
  //                                   {item.icon}
  //                                   <p>{item.name}</p>
  //                                 </button>
  //                                 :
  //                                 <></>
  //                             }
  //                           </>

  //                         ))}
  //                     </div>
  //                   </div>
  //                 </div>
  //             }


  //             {/* Report 2 */}
  //             {
  //               isShowReportRendering2 === false ? <></>
  //                 :
  //                 <div className="flex space-y-3 flex-col w-full h-max text-md">
  //                   <p
  //                     style={{
  //                       fontWeight: "bold",
  //                       color: "rgb(52, 152, 219)"
  //                     }}>
  //                     BIỂU ĐỒ CHI TIẾT
  //                   </p>

  //                   <div className="w-full h-max flex flex-col space-y-5">
  //                     <div className="w-full h-max flex space-x-5">
  //                       {
  //                         reportRendering2?.map((item: any, index: any) => (
  //                           <>
  //                             {
  //                               item.isShow === true ?
  //                                 <button className={`
  //                     flex flex-col justify-center items-center w-[10rem] h-[10rem] bg-slate-300 rounded-lg space-y-2
  //                     hover:bg-slate-200 hover:shadow-md ${item.color}
  //                   `}>
  //                                   {item.icon}
  //                                   <p>{item.name}</p>
  //                                 </button>
  //                                 :
  //                                 <></>
  //                             }
  //                           </>

  //                         ))}
  //                     </div>
  //                   </div>
  //                 </div>
  //             }


  //             {/* Report 3 */}
  //             {
  //               isShowReportRendering3 === false ? <></>
  //                 :
  //                 <div className="flex space-y-3 flex-col w-full h-max text-md">
  //                   <p
  //                     style={{
  //                       fontWeight: "bold",
  //                       color: "rgb(52, 152, 219)"
  //                     }}>
  //                     BIỂU ĐỒ TỔNG HỢP
  //                   </p>

  //                   <div className="w-full h-max flex flex-col space-y-5">
  //                     <div className="w-full h-max flex space-x-5">
  //                       {
  //                         reportRendering3?.map((item: any, index: any) => (
  //                           <>
  //                             {
  //                               item.isShow === true ?
  //                                 <button className={`
  //                     flex flex-col justify-center items-center w-[10rem] h-[10rem] bg-slate-300 rounded-lg space-y-2
  //                     hover:bg-slate-200 hover:shadow-md ${item.color}
  //                   `}>
  //                                   {item.icon}
  //                                   <p>{item.name}</p>
  //                                 </button>
  //                                 :
  //                                 <></>
  //                             }
  //                           </>

  //                         ))}
  //                     </div>
  //                   </div>
  //                 </div>
  //             }


  //             {/* Propose */}
  //             {
  //               isShowProposeRendering === false ? <></>
  //                 :
  //                 <div className="flex space-y-3 flex-col w-full h-max text-md">
  //                   <p
  //                     style={{
  //                       fontWeight: "bold",
  //                       color: "rgb(52, 152, 219)"
  //                     }}>
  //                     ĐỀ XUẤT
  //                   </p>

  //                   <div className="w-full h-max flex flex-col space-y-5">
  //                     <div className="w-full h-max flex space-x-5">
  //                       {
  //                         proposeRendering?.map((item: any, index: any) => (
  //                           <>
  //                             {
  //                               item.isShow === true ?
  //                                 <button className={`
  //                     flex flex-col justify-center items-center w-[10rem] h-[10rem] bg-slate-300 rounded-lg space-y-2
  //                     hover:bg-slate-200 hover:shadow-md ${item.color}
  //                   `}>
  //                                   {item.icon}
  //                                   <p>{item.name}</p>
  //                                 </button>
  //                                 :
  //                                 <></>
  //                             }
  //                           </>

  //                         ))}
  //                     </div>
  //                   </div>
  //                 </div>
  //             }
  //           </div>
  //       }
  //     </>
  //   )
  // }

  const _onClickNavigate = (url: string) => {
    const finalUrl = `/${url}`

    navigate(finalUrl)
  }

  const WebComponentJSX = () => {
    return (
      <>
        {
          titlePhanquyen === null
            || titleThietlap === null
            || titleCongviec === null
            || titleBaocao === null
            || titleDexuat === null

            || arrayFormPhanquyen === null
            || arrayFormThietlap === null
            || arrayFormCongviec === null
            || arrayFormBaocao === null
            || arrayFormDexuat === null

            || data.loadNoti === null
            ?
            <div className="flex flex-col w-full h-full space-y-10 justify-center items-center">
              <LoaderSpinner w={32} h={32} />
              <p className="text-primary">Đang tải dữ liệu</p>
            </div>
            :
            <div className="flex flex-col w-full h-max space-y-10">

              {
                // titleCongviec === undefined ? <></>
                //   : titleCongviec.PerActive === true ?
                //     <div className="flex space-y-3 flex-col w-full h-max text-md">
                //       <p
                //         style={{
                //           fontWeight: "bold",
                //           color: "rgb(52, 152, 219)"
                //         }}>
                //         CÔNG VIỆC
                //       </p>

                //       <div className="grid grid-cols-8 gap-4">
                //         {
                //           arrayFormCongviec.map((item: any, index: any) => (
                //             <>
                //               {
                //                 item.PerActive === true ?
                //                   <button key={index} className={`
                //                   flex flex-col justify-center items-center w-[100%] h-[10rem] bg-slate-300 rounded-lg space-y-2
                //                   hover:bg-slate-200 hover:shadow-md text-emerald-600 hover:text-emerald-800 hover:font-semibold active:shadow-inner transition-all delay-75
                //                 `}

                //                     onClick={() => _onClickNavigate(item.FormLink)}
                //                   >
                //                     {
                //                       item.FormLink === "working/new-task" ?
                //                         <>
                //                           {
                //                             data.loadNoti?.countListNew === 0 ?
                //                               <NoteAddIcon sx={{ fontSize: 80 }} />
                //                               :
                //                               <Badge badgeContent={data.loadNoti?.countListNew} color="error" max={999}>
                //                                 <NoteAddIcon sx={{ fontSize: 80 }} />
                //                               </Badge>
                //                           }
                //                         </>

                //                         : item.FormLink === "working/task-finish" ?
                //                           <>
                //                             {
                //                               data.loadNoti?.countListCompleted === 0 ?
                //                                 <TaskIcon sx={{ fontSize: 80 }} />
                //                                 :
                //                                 <Badge badgeContent={data.loadNoti?.countListCompleted} color="error" max={999}>
                //                                   <TaskIcon sx={{ fontSize: 80 }} />
                //                                 </Badge>
                //                             }
                //                           </>

                //                           : item.FormLink === "working/task-finish-late" ?
                //                             <>
                //                               {
                //                                 data.loadNoti?.countListLateComplete === 0 ?
                //                                   <AlarmAddIcon sx={{ fontSize: 80 }} />
                //                                   :
                //                                   <Badge badgeContent={data.loadNoti?.countListLateComplete} color="error" max={999}>
                //                                     <AlarmAddIcon sx={{ fontSize: 80 }} />
                //                                   </Badge>
                //                               }
                //                             </>

                //                             : item.FormLink === "working/task-finish-early" ?
                //                               <>
                //                                 {
                //                                   data.loadNoti?.countListEarlyComplete === 0 ?
                //                                     <HistoryIcon sx={{ fontSize: 80 }} />
                //                                     :
                //                                     <Badge badgeContent={data.loadNoti?.countListEarlyComplete} color="error" max={999}>
                //                                       <HistoryIcon sx={{ fontSize: 80 }} />
                //                                     </Badge>
                //                                 }
                //                               </>

                //                               : item.FormLink === "working/task-finish-punctual" ?
                //                                 <>
                //                                   {
                //                                     data.loadNoti?.countListPunctualCompleted === 0 ?
                //                                       <PunchClockIcon sx={{ fontSize: 80 }} />
                //                                       :
                //                                       <Badge badgeContent={data.loadNoti?.countListPunctualCompleted} color="error" max={999}>
                //                                         <PunchClockIcon sx={{ fontSize: 80 }} />
                //                                       </Badge>
                //                                   }
                //                                 </>

                //                                 : item.FormLink === "working/task-proccessing" ?
                //                                   <>
                //                                     {
                //                                       data.loadNoti?.countListProccessing === 0 ?
                //                                         <FileOpenIcon sx={{ fontSize: 80 }} />
                //                                         :
                //                                         <Badge badgeContent={data.loadNoti?.countListProccessing} color="error" max={999}>
                //                                           <FileOpenIcon sx={{ fontSize: 80 }} />
                //                                         </Badge>
                //                                     }
                //                                   </>

                //                                   : item.FormLink === "working/task-delay" ?
                //                                     <>
                //                                       {
                //                                         data.loadNoti?.countListLate === 0 ?
                //                                           <ErrorRoundedIcon sx={{ fontSize: 80 }} />
                //                                           :
                //                                           <Badge badgeContent={data.loadNoti?.countListLate} color="error" max={999}>
                //                                             <ErrorRoundedIcon sx={{ fontSize: 80 }} />
                //                                           </Badge>
                //                                       }
                //                                     </>

                //                                     : item.FormLink === "working/task-total" ?
                //                                       <>
                //                                         {
                //                                           data.loadNoti?.countListAllHasDelivery === 0 ?
                //                                             <FeaturedPlayListRoundedIcon sx={{ fontSize: 80 }} />
                //                                             :
                //                                             <Badge badgeContent={data.loadNoti?.countListAllHasDelivery_All} color="error" max={999}>
                //                                               <FeaturedPlayListRoundedIcon sx={{ fontSize: 80 }} />
                //                                             </Badge>
                //                                         }
                //                                       </>

                //                                       : item.FormLink === "/working/task-totalall" ?
                //                                         <>
                //                                           {
                //                                             data.loadNoti?.countListAllHasDelivery === 0 ?
                //                                               <FeaturedPlayListRoundedIcon sx={{ fontSize: 80 }} />
                //                                               :
                //                                               <Badge badgeContent={data.loadNoti?.countListAllHasDelivery} color="error" max={999}>
                //                                                 <FeaturedPlayListRoundedIcon sx={{ fontSize: 80 }} />
                //                                               </Badge>
                //                                           }
                //                                         </>

                //                                         : item.FormLink === "working/receiver/task-new" ?
                //                                           <>
                //                                             {
                //                                               data.loadNoti2?.countListNew === 0 ?
                //                                                 <WorkOutlineRoundedIcon sx={{ fontSize: 80 }} />
                //                                                 :
                //                                                 <Badge badgeContent={data.loadNoti2?.countListNew} color="error" max={999}>
                //                                                   <WorkOutlineRoundedIcon sx={{ fontSize: 80 }} />
                //                                                 </Badge>
                //                                             }
                //                                           </>

                //                                           : item.FormLink === "working/receiver/task-received" ?
                //                                             <>
                //                                               {
                //                                                 data.loadNoti2?.countListReveced === 0 ?
                //                                                   <WorkRoundedIcon sx={{ fontSize: 80 }} />
                //                                                   :
                //                                                   <Badge badgeContent={data.loadNoti2?.countListReveced} color="error" max={999}>
                //                                                     <WorkRoundedIcon sx={{ fontSize: 80 }} />
                //                                                   </Badge>
                //                                               }
                //                                             </>

                //                                             : item.FormLink === "working/receiver/task-processing" ?
                //                                               <>
                //                                                 {
                //                                                   data.loadNoti2?.countListWorking === 0 ?
                //                                                     <NextWeekRoundedIcon sx={{ fontSize: 80 }} />
                //                                                     :
                //                                                     <Badge badgeContent={data.loadNoti2?.countListWorking} color="error" max={999}>
                //                                                       <NextWeekRoundedIcon sx={{ fontSize: 80 }} />
                //                                                     </Badge>
                //                                                 }
                //                                               </>

                //                                               : item.FormLink === "working/receiver/task-delay" ?
                //                                                 <>
                //                                                   {
                //                                                     data.loadNoti2?.countListLated === 0 ?
                //                                                       <WorkHistoryRoundedIcon sx={{ fontSize: 80 }} />
                //                                                       :
                //                                                       <Badge badgeContent={data.loadNoti2?.countListLated} color="error" max={999}>
                //                                                         <WorkHistoryRoundedIcon sx={{ fontSize: 80 }} />
                //                                                       </Badge>
                //                                                   }
                //                                                 </>

                //                                                 : item.FormLink === "working/receiver/all-task" ?
                //                                                   <>
                //                                                     {
                //                                                       data.loadNoti2?.countListAll === 0 ?
                //                                                         <ListAltRoundedIcon sx={{ fontSize: 80 }} />
                //                                                         :
                //                                                         <Badge badgeContent={data.loadNoti2?.countListAll} color="error" max={999}>
                //                                                           <ListAltRoundedIcon sx={{ fontSize: 80 }} />
                //                                                         </Badge>
                //                                                     }
                //                                                   </>

                //                                                   : item.FormLink === "working/receiver/task-finish_late" ?
                //                                                     <>
                //                                                       {
                //                                                         data.loadNoti2?.countListAll === 0 ?
                //                                                           <HistoryToggleOffRoundedIcon sx={{ fontSize: 80 }} />
                //                                                           :
                //                                                           <Badge badgeContent={data.loadNoti2?.countListAll} color="error" max={999}>
                //                                                             <HistoryToggleOffRoundedIcon sx={{ fontSize: 80 }} />
                //                                                           </Badge>
                //                                                       }
                //                                                     </>

                //                                                     : item.FormLink === "working/receiver/task-finish_early" ?
                //                                                       <>
                //                                                         {
                //                                                           data.loadNoti2?.countListAll === 0 ?
                //                                                             <ScheduleRoundedIcon sx={{ fontSize: 80 }} />
                //                                                             :
                //                                                             <Badge badgeContent={data.loadNoti2?.countListAll} color="error" max={999}>
                //                                                               <ScheduleRoundedIcon sx={{ fontSize: 80 }} />
                //                                                             </Badge>
                //                                                         }
                //                                                       </>

                //                                                       : item.FormLink === "working/receiver/task-finish_puntual" ?
                //                                                         <>
                //                                                           {
                //                                                             data.loadNoti2?.countListAll === 0 ?
                //                                                               <AlarmOnRoundedIcon sx={{ fontSize: 80 }} />
                //                                                               :
                //                                                               <Badge badgeContent={data.loadNoti2?.countListAll} color="error" max={999}>
                //                                                                 <AlarmOnRoundedIcon sx={{ fontSize: 80 }} />
                //                                                               </Badge>
                //                                                           }
                //                                                         </>

                //                                                         : item.FormLink === "working/management-workflow" ?
                //                                                           <DatasetIcon sx={{ fontSize: 80 }} />

                //                                                           /**biểu quyết công việc **/
                //                                                           : item.FormLink === "voting/voting_work" ?
                //                                                             <HowToVoteRoundedIcon sx={{ fontSize: 80 }} />
                //                                                             /** công việc trả lại **/
                //                                                             : item.FormLink === "working/task-return" ?
                //                                                               <RestorePageRoundedIcon sx={{ fontSize: 80 }} />
                //                                                               /** công việc bị trả lại **/
                //                                                               : item.FormLink === "working/receiver/task-returnwork" ?
                //                                                                 <AssignmentReturnRoundedIcon sx={{ fontSize: 80 }} />
                //                                                                 /** việc hoàn tất chờ duyệt **/
                //                                                                 : item.FormLink === "working/receiver/task-waiting" ?
                //                                                                   <AssignmentTurnedInRoundedIcon sx={{ fontSize: 80 }} />
                //                                                                   /** việc đang yêu cầu biểu quyết **/
                //                                                                   : item.FormLink === "voting/voting_wait" ?
                //                                                                     <BallotIcon sx={{ fontSize: 80 }} />
                //                                                                     /** danh sách tổng hợp việc đã giao**/
                //                                                                     : item.FormLink === "working/task-totalall" ?
                //                                                                       <FeedIcon sx={{ fontSize: 80 }} />
                //                                                                       : <></>
                //                     }
                //                     <p>{item.FormTitle}</p>
                //                   </button>
                //                   :
                //                   <></>
                //               }
                //             </>
                //           ))
                //         }
                //       </div>
                //     </div>
                //     :
                //     <></>
              }

              {
                // titleBaocao === undefined ? <></>
                //   : titleBaocao.PerActive === true ?
                //     <div className="flex space-y-3 flex-col w-full h-max text-md">
                //       <p
                //         style={{
                //           fontWeight: "bold",
                //           color: "rgb(52, 152, 219)"
                //         }}>
                //         BÁO CÁO
                //       </p>

                //       <div className="grid grid-cols-8 gap-4">
                //         {
                //           arrayFormBaocao.map((item: any, index: any) => (
                //             <>
                //               {
                //                 item.PerActive === true ?
                //                   <button key={index} className={`
                //                   flex flex-col justify-center items-center w-[100%] h-[10rem] bg-slate-300 rounded-lg space-y-2
                //                   hover:bg-slate-200 hover:shadow-md text-purple-600 hover:text-purple-800 hover:font-semibold active:shadow-inner transition-all delay-75
                //                 `}

                //                     onClick={() => _onClickNavigate(item.FormLink)}
                //                   >
                //                     {
                //                       item.FormLink === "report/personal-sumwork" ?
                //                         <BarChartRoundedIcon sx={{ fontSize: 80 }} />
                //                         : item.FormLink === "report/user-diftask" ?
                //                           <BubbleChartRoundedIcon sx={{ fontSize: 80 }} />
                //                           : item.FormLink === "report/personal-greenwork" ?
                //                             <InsertChartRoundedIcon sx={{ fontSize: 80 }} />
                //                             : item.FormLink === "report/personal-redwork" ?
                //                               <PieChartRoundedIcon sx={{ fontSize: 80 }} />
                //                               : item.FormLink === "report/user-sumwork" ?
                //                                 <ShowChartRoundedIcon sx={{ fontSize: 80 }} />
                //                                 : item.FormLink === "report/sum-diftask" ?
                //                                   <SsidChartRoundedIcon sx={{ fontSize: 80 }} />
                //                                   : item.FormLink === "report/all-diftask" ?
                //                                     <StackedBarChartRoundedIcon sx={{ fontSize: 80 }} />
                //                                     : item.FormLink === "report/user-greenwork" ?
                //                                       <StackedLineChartRoundedIcon sx={{ fontSize: 80 }} />
                //                                       : item.FormLink === "report/user-redwork" ?
                //                                         <TableChartRoundedIcon sx={{ fontSize: 80 }} />
                //                                         : item.FormLink === "report/sum-taskpercent" ?
                //                                           <WaterfallChartRoundedIcon sx={{ fontSize: 80 }} />
                //                                           : item.FormLink === "report/user-taskpercent" ?
                //                                             <AccountTreeRoundedIcon sx={{ fontSize: 80 }} />
                //                                             : item.FormLink === "report/sum-tasklistall" ?
                //                                               <AutoGraphRoundedIcon sx={{ fontSize: 80 }} />
                //                                               : item.FormLink === "report/sumwork" ?
                //                                                 <DonutLargeRoundedIcon sx={{ fontSize: 80 }} />
                //                                                 : item.FormLink === "report/overview" ?
                //                                                   <DonutSmallRoundedIcon sx={{ fontSize: 80 }} />
                //                                                   : item.FormLink === "report/sum-greenwork" ?
                //                                                     <LegendToggleRoundedIcon sx={{ fontSize: 80 }} />
                //                                                     : item.FormLink === "report/sum-redwork" ?
                //                                                       <TimelineRoundedIcon sx={{ fontSize: 80 }} />
                //                                                       : item.FormLink === "report/ranking" ?
                //                                                         <StorageRoundedIcon sx={{ fontSize: 80 }} />
                //                                                         : item.FormLink === "working/task-listall" ?
                //                                                           <ListAltRoundedIcon sx={{ fontSize: 80 }} />
                //                                                           : <></>
                //                     }
                //                     <p>{item.FormTitle}</p>
                //                   </button>
                //                   :
                //                   <></>
                //               }
                //             </>
                //           ))
                //         }
                //       </div>
                //     </div>
                //     :
                //     <></>
              }

              {
                // titleDexuat === undefined ? <></>
                //   : titleDexuat.PerActive === true ?
                //     <div className="flex space-y-3 flex-col w-full h-max text-md">
                //       <p
                //         style={{
                //           fontWeight: "bold",
                //           color: "rgb(52, 152, 219)"
                //         }}>
                //         ĐỀ XUẤT
                //       </p>

                //       <div className="grid grid-cols-8 gap-4">
                //         {
                //           arrayFormDexuat.map((item: any, index: any) => (
                //             <>
                //               {
                //                 item.PerActive === true ?
                //                   <button key={index} className={`
                //                   flex flex-col justify-center items-center w-[100%] h-[10rem] bg-slate-300 rounded-lg space-y-2
                //                   hover:bg-slate-200 hover:shadow-md text-yellow-600 hover:text-yellow-800 hover:font-semibold active:shadow-inner transition-all delay-75
                //                 `}

                //                     onClick={() => _onClickNavigate(item.FormLink)}
                //                   >
                //                     {
                //                       item.FormLink === "propose/sender/list" ?
                //                         <LightbulbRoundedIcon sx={{ fontSize: 80 }} />
                //                         : item.FormLink === "propose/receiver/list" ?
                //                           <TungstenRoundedIcon sx={{ fontSize: 80 }} />
                //                           : <></>
                //                     }
                //                     <p>{item.FormTitle}</p>
                //                   </button>
                //                   :
                //                   <></>
                //               }
                //             </>
                //           ))
                //         }
                //       </div>
                //     </div>
                //     :
                //     <></>
              }


              {/* <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Quản lý công việc cá nhân" {...a11yProps(0)} />
                    <Tab label="Quản lý công việc chung" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <div className="flex flex-col w-full h-max text-sm space-y-2">
                  
                    <button className="flex space-x-2">
                      <p className="text-primary flex space-x-2 text-sm font-medium"> 1. Các việc đã giao</p>
                      <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                    </button>

                    <button className="ml-3 flex space-x-2">
                      <p className="text-primary hover:underline flex space-x-2 font-medium"> 1.1. Các việc đang làm</p>
                      <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                    </button>

                    <button className="ml-3 flex space-x-2">
                      <p className="text-primary hover:underline flex space-x-2 font-medium"> 1.2. Các việc đã làm xong</p>
                      <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                    </button>

                    <button className="ml-3 flex space-x-2">
                      <p className="text-primary hover:underline flex space-x-2 font-medium"> 1.3. Các việc bị trả về</p>
                      <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                    </button>

                    <button className="ml-3 flex space-x-2">
                      <p className="text-primary hover:underline flex space-x-2 font-medium"> 1.4. Các việc xin gia hạn</p>
                      <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                    </button>

                
                    <button className="flex space-x-2">
                      <p className="text-primary hover:underline flex space-x-2 font-medium">2. Các việc chờ duyệt</p>
                      <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                    </button>

                  
                    <button className="flex space-x-2">
                      <p className="text-primary hover:underline flex space-x-2 font-medium">3. Việc cần biểu quyết</p>
                      <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                    </button>

              
                    <button className="flex space-x-2">
                      <p className="text-primary hover:underline flex space-x-2 font-medium">4. Đề xuất chờ duyệt</p>
                      <p className="w-max h-max px-1 py-0.5 rounded-full bg-red-500 text-white text-xs hover:no-underline">40</p>
                    </button>
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  CÔNG VIỆC CHUNG
                </CustomTabPanel>
              </Box> */}


              {
                data.userData === null ?
                  <div className="w-full h-full flex p-2 text-sm">
                    <LoaderSpinner w={32} h={32} />
                    <p className="text-primary">Đang tải dữ liệu</p>
                  </div>
                  : data.userData?.DataLevel === 1 ? <NormalStaffUI />
                    : data.userData?.DataLevel === 2 ? <MediumManagerUI />
                      : <HighManagerUI />
              }


              {/* {
                titlePhanquyen === undefined ? <></>
                  : titlePhanquyen.PerActive === true ?
                    <div className="flex space-y-3 bg flex-col w-full h-max text-md">
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "rgb(52, 152, 219)"
                        }}>
                        QUẢN TRỊ
                      </p>

                      <div className="grid lg:grid-cols-6 grid-cols-2 gap-5">
                        {
                          arrayFormPhanquyen.map((item: any, index: any) => (
                            <>
                              {
                                item.PerActive === true ?
                                  <button key={index} className={`
                                  flex items-center w-[100%] bg-slate-300 rounded-lg space-x-2 px-2 py-2 shadow-sm font-semibold
                                  hover:bg-slate-200 hover:shadow-md text-cyan-600 hover:text-cyan-800 hover:font-semibold active:shadow-inner transition-all delay-75

                                `}
                                    onClick={() => _onClickNavigate(item.FormLink)}
                                  >
                                    <FolderIcon sx={{ fontSize: 30 }} />
                                    <p>{item.FormTitle}</p>
                                  </button>
                                  :
                                  <></>
                              }
                            </>
                          ))
                        }
                      </div>
                    </div>
                    : <></>
              } */}

              {/* {
                titleThietlap === undefined ? <></>
                  : titleThietlap.PerActive === true ?
                    <div className="flex space-y-3 flex-col w-full h-max text-md">
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "rgb(52, 152, 219)"
                        }}>
                        THIẾT LẬP QUẢN TRỊ
                      </p>

                      <div className="grid lg:grid-cols-6 grid-cols-2 gap-4">
                        {
                          arrayFormThietlap.map((item: any, index: any) => (
                            <>
                              {
                                item.PerActive === true ?
                                  <button key={index} className={`
                                  flex items-center w-[100%] bg-slate-300 rounded-lg space-x-2 px-2 py-2 shadow-sm font-semibold
                                  hover:bg-slate-200 hover:shadow-md text-slate-600 hover:text-slate-800 hover:font-semibold active:shadow-inner transition-all delay-75
                                `}

                                    onClick={() => _onClickNavigate(item.FormLink)}
                                  >
                                    <FolderIcon sx={{ fontSize: 30 }} />
                                    <p>{item.FormTitle}</p>
                                  </button>
                                  :
                                  <></>
                              }
                            </>
                          ))
                        }
                      </div>
                    </div>
                    :
                    <></>
              } */}
            </div>
        }
      </>
    )
  }

  const MobileComponentJSX = () => {
    return (
      <>
        {
          titlePhanquyen === null
            || titleThietlap === null
            || titleCongviec === null
            || titleBaocao === null
            || titleDexuat === null

            || arrayFormPhanquyen === null
            || arrayFormThietlap === null
            || arrayFormCongviec === null
            || arrayFormBaocao === null
            || arrayFormDexuat === null
            || data.loadNoti === null
            ?
            <div className="flex flex-col w-full h-full space-y-10 justify-center items-center">
              <LoaderSpinner w={32} h={32} />
              <p className="text-primary">Đang tải dữ liệu</p>
            </div>
            :
            <div className="flex flex-col w-full h-full space-y-10">

              {
                titlePhanquyen === undefined ? <></>
                  : titlePhanquyen.PerActive === true ?
                    <div className="flex space-y-3 flex-col w-full h-max text-md">
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "rgb(52, 152, 219)"
                        }}>
                        PHÂN QUYỀN
                      </p>

                      <div className="grid grid-cols-3 gap-2">
                        {
                          arrayFormPhanquyen.map((item: any, index: any) => (
                            <>
                              {
                                item.PerActive === true ?
                                  <button key={index} className={`
                                  flex flex-col justify-center items-center w-[100%] h-[6rem] bg-slate-300 rounded-lg space-y-1 
                                  active:bg-slate-200 active:shadow-inner active:shadow-cyan-600 translate-all active:delay-75 text-cyan-600 text-xs
                                `}

                                    onClick={() => _onClickNavigate(item.FormLink)}
                                  >
                                    {
                                      item.FormLink === "user/functional-list" ?
                                        <DisplaySettingsIcon sx={{ fontSize: 30 }} />
                                        : item.FormLink === "user/work-category-list" ?
                                          <AssignmentIcon sx={{ fontSize: 30 }} />
                                          : item.FormLink === "user/data-option" ?
                                            <LandslideRoundedIcon sx={{ fontSize: 30 }} />
                                            : item.FormLink === "user/user-list" ?
                                              <ReduceCapacityRoundedIcon sx={{ fontSize: 30 }} />
                                              : <></>
                                    }
                                    <p>{item.FormTitle}</p>
                                  </button>
                                  :
                                  <></>
                              }
                            </>
                          ))
                        }
                      </div>
                    </div>
                    : <></>
              }

              {
                titleThietlap === undefined ? <></>
                  : titleThietlap.PerActive === true ?
                    <div className="flex space-y-3 flex-col w-full h-max text-md">
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "rgb(52, 152, 219)"
                        }}>
                        THIẾT LẬP
                      </p>

                      <div className="grid grid-cols-3 gap-2">
                        {
                          arrayFormThietlap.map((item: any, index: any) => (
                            <>
                              {
                                item.PerActive === true ?
                                  <button key={index} className={`
                                  flex flex-col justify-center items-center w-[100%] h-[6rem] bg-slate-300 rounded-lg space-y-1
                                  active:bg-slate-200 active:shadow-inner active:shadow-slate-600 translate-all active:delay-75 text-slate-600 text-xs
                                `}

                                    onClick={() => _onClickNavigate(item.FormLink)}
                                  >
                                    {
                                      item.FormLink === "setting/listof-category/list-of-employee" ?
                                        <FolderSharedIcon sx={{ fontSize: 30 }} />
                                        : item.FormLink === "setting/listof-category/list-of-task" ?
                                          <TopicIcon sx={{ fontSize: 30 }} />
                                          : item.FormLink === "setting/listof-category/list-of-general" ?
                                            <FolderIcon sx={{ fontSize: 30 }} />
                                            : item.FormLink === "setting/listof-category/prior-rate" ?
                                              <FolderSpecialIcon sx={{ fontSize: 30 }} />
                                              : item.FormLink === "setting/listof-category/security-rate" ?
                                                <SnippetFolderIcon sx={{ fontSize: 30 }} />
                                                : item.FormLink === "setting/listof-category/difficult-rate" ?
                                                  <FolderZipIcon sx={{ fontSize: 30 }} />
                                                  : item.FormLink === "setting/listof-category/target-rating-capacity" ?
                                                    <FlagCircleIcon sx={{ fontSize: 30 }} />
                                                    : item.FormLink === "setting/listof-category/score-rating-capacity" ?
                                                      <WorkspacePremiumIcon sx={{ fontSize: 30 }} />
                                                      : item.FormLink === "setting/setting/email-automatic" ?
                                                        <EmailIcon sx={{ fontSize: 30 }} />
                                                        : item.FormLink === "setting/setting/overload-setting" ?
                                                          <ReportGmailerrorredRoundedIcon sx={{ fontSize: 30 }} />
                                                          : item.FormLink === "setting/setting/alert-tasking" ?
                                                            <NotificationsActiveIcon sx={{ fontSize: 30 }} />
                                                            : <></>
                                    }
                                    <p>{item.FormTitle}</p>
                                  </button>
                                  :
                                  <></>
                              }
                            </>
                          ))
                        }
                      </div>
                    </div>
                    :
                    <></>
              }

              {
                titleCongviec === undefined ? <></>
                  : titleCongviec.PerActive === true ?
                    <div className="flex space-y-3 flex-col w-full h-max text-md">
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "rgb(52, 152, 219)"
                        }}>
                        CÔNG VIỆC
                      </p>

                      <div className="grid grid-cols-3 gap-2">
                        {
                          arrayFormCongviec.map((item: any, index: any) => (
                            <>
                              {
                                item.PerActive === true ?
                                  <button key={index} className={`
                                  flex flex-col justify-center items-center w-[100%] h-[6rem] bg-slate-300 rounded-lg space-y-1
                                  active:bg-slate-200 active:shadow-inner active:shadow-emerald-600 translate-all active:delay-75 text-emerald-600 text-xs
                                `}

                                    onClick={() => _onClickNavigate(item.FormLink)}
                                  >
                                    {
                                      item.FormLink === "working/new-task" ?
                                        <>
                                          {
                                            data.loadNoti?.countListNew === 0 ?
                                              <NoteAddIcon sx={{ fontSize: 30 }} />
                                              :
                                              <Badge badgeContent={data.loadNoti?.countListNew} color="error" max={999}>
                                                <NoteAddIcon sx={{ fontSize: 30 }} />
                                              </Badge>
                                          }
                                        </>

                                        : item.FormLink === "working/task-finish" ?
                                          <>
                                            {
                                              data.loadNoti?.countListCompleted === 0 ?
                                                <TaskIcon sx={{ fontSize: 30 }} />
                                                :
                                                <Badge badgeContent={data.loadNoti?.countListCompleted} color="error" max={999}>
                                                  <TaskIcon sx={{ fontSize: 30 }} />
                                                </Badge>
                                            }
                                          </>

                                          : item.FormLink === "working/task-proccessing" ?
                                            <>
                                              {
                                                data.loadNoti?.countListProccessing === 0 ?
                                                  <NextWeekRoundedIcon sx={{ fontSize: 30 }} />
                                                  :
                                                  <Badge badgeContent={data.loadNoti?.countListProccessing} color="error" max={999}>
                                                    <NextWeekRoundedIcon sx={{ fontSize: 30 }} />
                                                  </Badge>
                                              }
                                            </>

                                            : item.FormLink === "working/task-delay" ?
                                              <>
                                                {
                                                  data.loadNoti?.countListLate === 0 ?
                                                    <ErrorRoundedIcon sx={{ fontSize: 30 }} />
                                                    :
                                                    <Badge badgeContent={data.loadNoti?.countListLate} color="error" max={999}>
                                                      <ErrorRoundedIcon sx={{ fontSize: 30 }} />
                                                    </Badge>
                                                }
                                              </>

                                              : item.FormLink === "working/task-finish-late" ?
                                                <>
                                                  {
                                                    data.loadNoti?.countListLateComplete === 0 ?
                                                      <AlarmAddIcon sx={{ fontSize: 30 }} />
                                                      :
                                                      <Badge badgeContent={data.loadNoti?.countListLateComplete} color="error" max={999}>
                                                        <AlarmAddIcon sx={{ fontSize: 30 }} />
                                                      </Badge>
                                                  }
                                                </>

                                                : item.FormLink === "working/task-finish-early" ?
                                                  <>
                                                    {
                                                      data.loadNoti?.countListEarlyComplete === 0 ?
                                                        <HistoryIcon sx={{ fontSize: 30 }} />
                                                        :
                                                        <Badge badgeContent={data.loadNoti?.countListEarlyComplete} color="error" max={999}>
                                                          <HistoryIcon sx={{ fontSize: 30 }} />
                                                        </Badge>
                                                    }
                                                  </>

                                                  : item.FormLink === "working/task-finish-punctual" ?
                                                    <>
                                                      {
                                                        data.loadNoti?.countListPunctualCompleted === 0 ?
                                                          <PunchClockIcon sx={{ fontSize: 30 }} />
                                                          :
                                                          <Badge badgeContent={data.loadNoti?.countListPunctualCompleted} color="error" max={999}>
                                                            <PunchClockIcon sx={{ fontSize: 30 }} />
                                                          </Badge>
                                                      }
                                                    </>

                                                    : item.FormLink === "working/task-total" ?
                                                      <>
                                                        {
                                                          data.loadNoti?.countListAllHasDelivery === 0 ?
                                                            <FeaturedPlayListRoundedIcon sx={{ fontSize: 30 }} />
                                                            :
                                                            <Badge badgeContent={data.loadNoti?.countListAllHasDelivery} color="error" max={999}>
                                                              <FeaturedPlayListRoundedIcon sx={{ fontSize: 30 }} />
                                                            </Badge>
                                                        }
                                                      </>

                                                      : item.FormLink === "working/receiver/task-finish_late" ?
                                                        <>
                                                          {
                                                            data.loadNoti2?.countListAll === 0 ?
                                                              <HistoryToggleOffRoundedIcon sx={{ fontSize: 30 }} />
                                                              :
                                                              <Badge badgeContent={data.loadNoti2?.countListAll} color="error" max={999}>
                                                                <HistoryToggleOffRoundedIcon sx={{ fontSize: 30 }} />
                                                              </Badge>
                                                          }
                                                        </>

                                                        : item.FormLink === "working/receiver/task-finish_early" ?
                                                          <>
                                                            {
                                                              data.loadNoti2?.countListAll === 0 ?
                                                                <ScheduleRoundedIcon sx={{ fontSize: 30 }} />
                                                                :
                                                                <Badge badgeContent={data.loadNoti2?.countListAll} color="error" max={999}>
                                                                  <ScheduleRoundedIcon sx={{ fontSize: 30 }} />
                                                                </Badge>
                                                            }
                                                          </>

                                                          : item.FormLink === "working/receiver/task-finish_puntual" ?
                                                            <>
                                                              {
                                                                data.loadNoti2?.countListAll === 0 ?
                                                                  <AlarmOnRoundedIcon sx={{ fontSize: 30 }} />
                                                                  :
                                                                  <Badge badgeContent={data.loadNoti2?.countListAll} color="error" max={999}>
                                                                    <AlarmOnRoundedIcon sx={{ fontSize: 30 }} />
                                                                  </Badge>
                                                              }
                                                            </>


                                                            : item.FormLink === "working/receiver/task-new" ?
                                                              <WorkOutlineRoundedIcon sx={{ fontSize: 30 }} />
                                                              : item.FormLink === "working/receiver/task-received" ?
                                                                <WorkRoundedIcon sx={{ fontSize: 30 }} />
                                                                : item.FormLink === "working/receiver/task-processing" ?
                                                                  <NextWeekRoundedIcon sx={{ fontSize: 30 }} />
                                                                  : item.FormLink === "working/receiver/task-delay" ?
                                                                    <WorkHistoryRoundedIcon sx={{ fontSize: 30 }} />
                                                                    : item.FormLink === "working/receiver/all-task" ?
                                                                      <ListAltRoundedIcon sx={{ fontSize: 30 }} />
                                                                      : item.FormLink === "working/management-workflow" ?
                                                                        <DatasetIcon sx={{ fontSize: 30 }} />
                                                                        /**biểu quyết công việc **/
                                                                        : item.FormLink === "voting/voting_work" ?
                                                                          <HowToVoteRoundedIcon sx={{ fontSize: 30 }} />
                                                                          /** công việc trả lại **/
                                                                          : item.FormLink === "working/task-return" ?
                                                                            <RestorePageRoundedIcon sx={{ fontSize: 30 }} />
                                                                            /** công việc bị trả lại **/
                                                                            : item.FormLink === "working/receiver/task-returnwork" ?
                                                                              <AssignmentReturnRoundedIcon sx={{ fontSize: 30 }} />
                                                                              /** việc hoàn tất chờ duyệt **/
                                                                              : item.FormLink === "working/receiver/task-waiting" ?
                                                                                <AssignmentTurnedInRoundedIcon sx={{ fontSize: 30 }} />
                                                                                /** việc đang yêu cầu biểu quyết **/
                                                                                : item.FormLink === "voting/voting_wait" ?
                                                                                  <BallotIcon sx={{ fontSize: 30 }} />
                                                                                  /** danh sách tổng hợp việc đã giao**/
                                                                                  : item.FormLink === "working/task-totalall" ?
                                                                                    <FeedIcon sx={{ fontSize: 30 }} />
                                                                                    : <></>
                                    }
                                    <p>{item.FormTitle}</p>
                                  </button>
                                  :
                                  <></>
                              }
                            </>
                          ))
                        }
                      </div>
                    </div>
                    :
                    <></>
              }

              {
                titleBaocao === undefined ? <></>
                  : titleBaocao.PerActive === true ?
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "rgb(52, 152, 219)"
                        }}>
                        BÁO CÁO
                      </p>

                      <div className="grid grid-cols-3 gap-2">
                        {
                          arrayFormBaocao.map((item: any, index: any) => (
                            <>
                              {
                                item.PerActive === true ?
                                  <button key={index} className={`
                                  flex flex-col justify-center items-center w-[100%] h-[6rem] bg-slate-300 rounded-lg space-y-1
                                  active:bg-slate-200 active:shadow-inner active:shadow-purple-600 translate-all active:delay-75 text-purple-600 text-[10px]
                                `}

                                    onClick={() => _onClickNavigate(item.FormLink)}
                                  >
                                    {
                                      item.FormLink === "report/personal-sumwork" ?
                                        <BarChartRoundedIcon sx={{ fontSize: 30 }} />
                                        : item.FormLink === "report/user-diftask" ?
                                          <BubbleChartRoundedIcon sx={{ fontSize: 30 }} />
                                          : item.FormLink === "report/personal-greenwork" ?
                                            <InsertChartRoundedIcon sx={{ fontSize: 30 }} />
                                            : item.FormLink === "report/personal-redwork" ?
                                              <PieChartRoundedIcon sx={{ fontSize: 30 }} />
                                              : item.FormLink === "report/user-sumwork" ?
                                                <ShowChartRoundedIcon sx={{ fontSize: 30 }} />
                                                : item.FormLink === "report/sum-diftask" ?
                                                  <SsidChartRoundedIcon sx={{ fontSize: 30 }} />
                                                  : item.FormLink === "report/all-diftask" ?
                                                    <StackedBarChartRoundedIcon sx={{ fontSize: 30 }} />
                                                    : item.FormLink === "report/user-greenwork" ?
                                                      <StackedLineChartRoundedIcon sx={{ fontSize: 30 }} />
                                                      : item.FormLink === "report/user-redwork" ?
                                                        <TableChartRoundedIcon sx={{ fontSize: 30 }} />
                                                        : item.FormLink === "report/sum-taskpercent" ?
                                                          <WaterfallChartRoundedIcon sx={{ fontSize: 30 }} />
                                                          : item.FormLink === "report/user-taskpercent" ?
                                                            <AccountTreeRoundedIcon sx={{ fontSize: 30 }} />
                                                            : item.FormLink === "report/sum-tasklistall" ?
                                                              <AutoGraphRoundedIcon sx={{ fontSize: 30 }} />
                                                              : item.FormLink === "report/sumwork" ?
                                                                <DonutLargeRoundedIcon sx={{ fontSize: 30 }} />
                                                                : item.FormLink === "report/overview" ?
                                                                  <DonutSmallRoundedIcon sx={{ fontSize: 30 }} />
                                                                  : item.FormLink === "report/sum-greenwork" ?
                                                                    <LegendToggleRoundedIcon sx={{ fontSize: 30 }} />
                                                                    : item.FormLink === "report/sum-redwork" ?
                                                                      <TimelineRoundedIcon sx={{ fontSize: 30 }} />
                                                                      : item.FormLink === "report/ranking" ?
                                                                        <StorageRoundedIcon sx={{ fontSize: 30 }} />
                                                                        : item.FormLink === "working/task-listall" ?
                                                                          <ListAltRoundedIcon sx={{ fontSize: 30 }} />
                                                                          : <></>
                                    }
                                    <p>{item.FormTitle}</p>
                                  </button>
                                  :
                                  <></>
                              }
                            </>
                          ))
                        }
                      </div>
                    </div>
                    :
                    <></>
              }

              {
                titleDexuat === undefined ? <></>
                  : titleDexuat.PerActive === true ?
                    <div className="flex space-y-3 flex-col w-full h-max text-md">
                      <p
                        style={{
                          fontWeight: "bold",
                          color: "rgb(52, 152, 219)"
                        }}>
                        ĐỀ XUẤT
                      </p>

                      <div className="grid grid-cols-3 gap-2">
                        {
                          arrayFormDexuat.map((item: any, index: any) => (
                            <>
                              {
                                item.PerActive === true ?
                                  <button key={index} className={`
                                  flex flex-col justify-center items-center w-[100%] h-[6rem] bg-slate-300 rounded-lg space-y-2
                                  active:bg-slate-200 active:shadow-inner active:shadow-yellow-600 translate-all active:delay-75 text-yellow-600 text-xs
                                `}

                                    onClick={() => _onClickNavigate(item.FormLink)}
                                  >
                                    {
                                      item.FormLink === "propose/sender/list" ?
                                        <LightbulbRoundedIcon sx={{ fontSize: 30 }} />
                                        : item.FormLink === "propose/receiver/list" ?
                                          <TungstenRoundedIcon sx={{ fontSize: 30 }} />
                                          : <></>
                                    }
                                    <p>{item.FormTitle}</p>
                                  </button>
                                  :
                                  <></>
                              }
                            </>
                          ))
                        }
                      </div>
                    </div>
                    :
                    <></>
              }
              <br></br>

            </div>
        }
      </>
    )
  }

  return (
    <div className="flex w-full h-full p-3 text-sm">
      {/* Web */}
      <div className="w-full h-full lg:flex hidden">
        <WebComponentJSX />
      </div>

      {/* Mobile */}
      <div className="w-full h-full lg:hidden flex">
        <WebComponentJSX />
      </div>
    </div>
  )
}

export default AllUI