import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";

import { ModalController } from "../../homeUI/_index";

import CreateRoundedIcon from '@mui/icons-material/CreateRounded';

import { TAKE_PARAMETER_LISTKEY_DROPDOWN } from "../../../apis/_index";
import { ONCHANGE_PHONGBAN_LIST } from "../../../redux/reducers/workingReducer/_index";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

import DeleteIcon from '@mui/icons-material/Delete';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

// ui importing
// working
import TotalWorkingTableUI from "../contentWorkingTableUI/totalWorkingTable";
import NewTask from "../contentWorkingUI/newTask";
import TaskNeedToGive from "../contentWorkingUI/taskNeedToGive";
import TaskFinish from "../contentWorkingUI/taskFinish";
import TaskChangeDeadline from "../contentWorkingUI/taskChangeDeadline";
import TaskDelay from "../contentWorkingUI/taskDelay";
import TaskEndSoon from "../contentWorkingUI/taskEndSoon";
import TaskEndToday from "../contentWorkingUI/taskEndToday";
import TaskTotal from "../contentWorkingUI/taskTotal";
// import VotingWork from "../contentWorkingUI/votingWork";
import ManagementWorkflow from "../contentWorkingUI/managementWorkflowUI";
import TaskProccessing from "../contentWorkingUI/taskProccessing";
import TaskWaitingUI from "../workingUI/taskWaitingUI";

import PlanBossPageUI from "../reportUI/kehoach/planBossPageUI";

import TaskNewReceiverUI from "../contentWorkingUI/receiver/taskNewReceiver";
import TaskEndSoonReceiverUI from "../contentWorkingUI/receiver/taskEndSoonReceiver";
import TaskEndTodayReceiverUI from "../contentWorkingUI/receiver/taskEndToday";
import TaskDelayReceiverUI from "../contentWorkingUI/receiver/taskDelayReceiver";
import TaskProcessingReceiverUI from "../contentWorkingUI/receiver/taskProcessingReceiver";
import TaskReceiveReceiverUI from "../contentWorkingUI/receiver/taskReceiveReceiver";
import TaskAllTaskReceiver from "../contentWorkingUI/receiver/taskAllTaskReceiver";

import TaskReturnReceiverUI from "../workingUI/taskReturnReceiver";
import TaskReturnUI from "../workingUI/taskReturn";

import TaskListLate from "../workingUI/taskListLate";
import TaskListEarly from "../workingUI/taskListEarly";
import TaskListPunctual from "../workingUI/taskListPunctual";

import FinishEarly from "../contentWorkingUI/receiver/finishEarly";
import FinishLate from "../contentWorkingUI/receiver/finishLate";
import FinishPunctual from "../contentWorkingUI/receiver/finishPunctual";
import WorkingTaskListAllUI from "../workingUI/taskListAll";
import VotingWork from "../../../components/modal/working/VotingWork";

// report
import MissionProcessingUI from "../reportUI/_deliver/missionProcessing";
import TaskGeneralDeliverUI from "../reportUI/_deliver/taskGeneralDeliverUI";
import WorkerCapacityUI from "../reportUI/_deliver/workerCapacityUI";
import DetailWorkerCapacityUI from "../reportUI/_deliver/detailWorkerCapacity";
import IndividualCapacityUI from "../reportUI/_receiver/individualCapacityUI";
import TableReportCapacityUI from "../reportUI/_receiver/tableReportCapacity";
import AllDifTaskUI from "../reportUI/dificult/allDiftaskUI";
import UserDifTaskUI from "../reportUI/dificult/userDiftaskUI";
import SumDifTaskUI from "../reportUI/dificult/sumDiftaskUI";
import ReportRankingUI from "../reportUI/ranking/_index";

// setting
import ListOfEmployeeUI from "../settingUI/_listCategory/listOfEmployeeUI";
import ListOfTaskUI from "../settingUI/_listCategory/listOfTaskUI";
import ListOfGeneralUI from "../settingUI/_listCategory/listOfGeneralUI";
import PriorRateUI from "../settingUI/_listCategory/priorRateUI";
import ScoreRatingCapacityUI from "../settingUI/_listCategory/scoreRatingCapacity";
import TargetRatingCapacityUI from "../settingUI/_listCategory/targetRatingCapacityUI";
import AlertTaskingSettingUI from "../settingUI/_setting/alertTaskingSettingUI";
import EmailAutomaticUI from "../settingUI/_setting/emailAutomaticUI";
import OverloadSettingUI from "../settingUI/_setting/overloadSettingUI";
import ListOfSecurityLevelUI from "../settingUI/_listCategory/listOfSecurityLevel";
import DifficultRateUI from "../settingUI/_listCategory/difficultRateUI";

// user
import UserListUI from "../userUI/userListUI";
import FunctionalListUI from "../userUI/functionalListUI";
import ScreenListUI from "../userUI/screenListUI";
import WorkCategoryListUI from "../userUI/workCategoryListUI";
import DataOptionUI from "../userUI/dataOptionUI";
import SumRedWorkReportUI from "../reportUI/sumwork/sumRedWorkReportUI";
import SumGreenWorkReportUI from "../reportUI/sumwork/sumGreenWorkReportUI";
import UserGreenWorkReportUI from "../reportUI/userwork/userGreenWorkReportUI";
import UserRedWorkReportUI from "../reportUI/userwork/userRedWorkReportUI";
import SumTaskListAllReportUI from "../reportUI/sumwork/sumTaskListAllReportUI";
import UserTaskPercentReportUI from "../reportUI/userwork/userTaskPercentReportUI";
import SumWorkReportUI from "../reportUI/sumwork/sumWorkReportUI";
import OverviewReportUI from "../reportUI/sumwork/overviewReportUI";
import UserSumWorkReportUI from "../reportUI/userwork/userSumWorkReportUI";
import TilehoanthanhReportUI from "../reportUI/sumwork/tilehoanthanhReportUI";
import PersonalRedWorkReportUI from "../reportUI/userwork/personalRedWorkReportUI";
import PersonalSumWorkReportUI from "../reportUI/userwork/personalSumWorkReportUI";
import PersonalGreenWorkReportUI from "../reportUI/userwork/personalGreenWorkReportUI";

import ElectronicReportUI from "../reportUI/electronic/electronicReportUI";
import ElectronicReceiverReportUI from "../reportUI/electronic/electronicReceiverUI";
import ElectronicManagerReportUI from "../reportUI/electronic/electronicManagerUI";

import PlanSenderUI from "../reportUI/kehoach/planSenderUI";

// propose
import ProposeDeliverListUI from "../proposeUI/proposeDeliverListUI";
import ProposeReceiverListUI from "../proposeUI/proposeReceiverListUI";
import TotalTaskUI from "../workingUI/totalTaskUI";

import VotingUI from "../workingUI/votingUI";

// temp
import NormalStaffUI from "../../../temp/Normal/NormalStaff";
import HighManagerUI from "../../../temp/HighManager/HighMangerUI";
import MediumManagerUI from "../../../temp/MediumManager/MediumManagerUI";
import Error404UI from "../../error404UI/_index";

// all
import AllUI from "../../allPage/_index";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const MainContent = () => {
  // redux
  const data = useSelector((state: any) => state.appReducer);
  const workingData = useSelector((state: any) => state.workingReducer);
  const context = useContext(ModalController)

  const [isShowTemp, setIsShowTemp] = useState<boolean>(false)

  const [isHaveCreateTask, setIsHaveCreateTask] = useState<boolean>(false)
  const [isHaveCreateEmployee, setIsHaveCreateEmployee] = useState<boolean>(false)

  const dispatch = useDispatch();

  const _onClickCreateNewTask = () => {
    context.setFuncs("createNewTask");
    context.handleOpen();
  };

  const _onClickCreateEmployee = () => {
    context.setFuncs("createListOfEmployee");
    context.handleOpen();
  }

  // load phongBanList
  useEffect(() => {
    if (workingData.listPhongBan === null) {
      const accessToken = localStorage.getItem("accessToken");

      const takeData = async () => {
        try {
          await axios
            .get(TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_PHONGBAN", {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res) => {
              if (res.data.StatusCode === 200) {
                dispatch(ONCHANGE_PHONGBAN_LIST(res.data.Data));
              }
            });
        } catch (error) {
          toast.error("Tải dữ liệu thất bại");
        }
      };

      takeData();
    }
  });

  useEffect(() => {
    const tempTaskCreate = localStorage.getItem("tempTaskCreate")
    const tempEmployeeCreate = localStorage.getItem("tempEmployeeCreate")

    if (tempTaskCreate === null || tempTaskCreate === undefined) {
      setIsHaveCreateTask(false)
    } else {
      setIsHaveCreateTask(true)
    }

    if (tempEmployeeCreate === null || tempEmployeeCreate === undefined) {
      setIsHaveCreateEmployee(false)
    } else {
      setIsHaveCreateEmployee(true)
    }
  }, [isShowTemp])

  const urlLink = window.location.pathname;

  const _onClickDeleteTaskTemp = () => {
    localStorage.removeItem("tempTaskCreate")
    setIsHaveCreateTask(false)
  }

  const _onClickDeleteEmployeeTemp = () => {
    localStorage.removeItem("tempEmployeeCreate")
    setIsHaveCreateEmployee(false)
  }

  return (
    <div
      className={`
      h-full ${data.navBarButton === "true"
          ? "lg:w-[calc(100vw-25rem)]"
          : "lg:w-[calc(100vw-7rem)]"
        }
        relative
      bg-slate-50 w-[calc(100vw-3rem)] lg:overflow-scroll overflow-auto`}
    >
      {urlLink === "/workingtable/total" ? (
        <SumWorkReportUI />
      ) : urlLink === "/working/receiver/task-waiting" ? (
        <TaskWaitingUI />
      ) : urlLink === "/working/task-return" ? (
        <TaskReturnUI />
      ) : urlLink === "/working/receiver/task-returnwork" ? (
        <TaskReturnReceiverUI />
      ) : urlLink === "/working/receiver/task-finish_late" ? (
        <TaskListLate />
      ) : urlLink === "/working/receiver/task-finish_early" ? (
        <TaskListEarly />
      ) : urlLink === "/working/receiver/task-finish_puntual" ? (
        <TaskListPunctual />
      ) : urlLink === "/workingtable/total1" ? (
        <OverviewReportUI />
      ) : urlLink === "/workingtable/total2" ? (
        <SumRedWorkReportUI />
      ) : urlLink === "/workingtable/total3" ? (
        <SumGreenWorkReportUI />
      ) : urlLink === "/working/task-totalall" ? (
        <TotalTaskUI />
      ) : urlLink === "/working/new-task" ? (
        <NewTask />
      ) : urlLink === "/working/task-need-give" ? (
        <TaskNeedToGive />
      ) : urlLink === "/working/task-finish" ? (
        <TaskFinish />
      ) : urlLink === "/working/task-delay" ? (
        <TaskDelay />
      ) : urlLink === "/working/task-change-deadline" ? (
        <TaskChangeDeadline />
      ) : urlLink === "/working/task-proccessing" ? (
        <TaskProccessing />
      ) : urlLink === "/working/task-end-soon" ? (
        <TaskEndSoon />
      ) : urlLink === "/working/task-end-today" ? (
        <TaskEndToday />
      ) : urlLink === "/working/task-total" ? (
        <TaskTotal />
      ) : urlLink === "/voting/voting_work" ? (
        <VotingUI />
      ) : urlLink === "/voting/voting_wait" ? (
        <VotingUI />
      ) : urlLink === "/working/receiver/task-new" ? (
        <TaskNewReceiverUI />
      ) : urlLink === "/working/receiver/task-delay" ? (
        <TaskDelayReceiverUI />
      ) : urlLink === "/working/receiver/task-end-soon" ? (
        <TaskEndSoonReceiverUI />
      ) : urlLink === "/working/receiver/task-end-today" ? (
        <TaskEndTodayReceiverUI />
      ) : urlLink === "/working/receiver/task-processing" ? (
        <TaskProcessingReceiverUI />
      ) : urlLink === "/working/receiver/task-received" ? (
        <TaskReceiveReceiverUI />
      ) : urlLink === "/working/receiver/all-task" ? (
        <TaskAllTaskReceiver />
      ) : urlLink === "/working/management-workflow" ? (
        <ManagementWorkflow />
      ) : urlLink === "/report/ranking" ? (
        <ReportRankingUI />
      ) : urlLink === "/report/deliver/mission-processing" ? (
        <MissionProcessingUI />
      ) : urlLink === "/report/deliver/task-general-deliver" ? (
        <TaskGeneralDeliverUI />
      ) : urlLink === "/report/deliver/worker-capacity" ? (
        <WorkerCapacityUI />
      ) : urlLink === "/report/deliver/detail-worker-capacity" ? (
        <DetailWorkerCapacityUI />
      ) : urlLink === "/report/receiver/individual-capacity" ? (
        <IndividualCapacityUI />
      ) : urlLink === "/report/receiver/table-report-capacity" ? (
        <TableReportCapacityUI />
      ) : urlLink === "/report/user-diftask" ? (
        <UserDifTaskUI />
      ) : urlLink === "/report/sum-diftask" ? (
        <SumDifTaskUI />
      ) : urlLink === "/report/all-diftask" ? (
        <AllDifTaskUI />
      ) : urlLink === "/report/sumwork" ? (
        <SumWorkReportUI />
      ) : urlLink === "/report/overview" ? (
        <OverviewReportUI />
      ) : urlLink === "/report/sum-redwork" ? (
        <SumRedWorkReportUI />
      ) : urlLink === "/report/sum-greenwork" ? (
        <SumGreenWorkReportUI />
      ) : urlLink === "/report/user-greenwork" ? (
        <UserGreenWorkReportUI />
      ) : urlLink === "/report/user-redwork" ? (
        <UserRedWorkReportUI />
      ) : urlLink === "/report/sum-taskpercent" ? (
        <TilehoanthanhReportUI />
      ) : urlLink === "/report/user-taskpercent" ? (
        <UserTaskPercentReportUI />
      ) : urlLink === "/report/sum-tasklistall" ? (
        <SumTaskListAllReportUI />
      ) : urlLink === "/report/user-sumwork" ? (
        <UserSumWorkReportUI />
      ) : urlLink === "/report/personal-redwork" ? (
        <PersonalRedWorkReportUI />
      ) : urlLink === "/report/personal-greenwork" ? (
        <PersonalGreenWorkReportUI />
      ) : urlLink === "/report/personal-sumwork" ? (
        <PersonalSumWorkReportUI />
      )

        : urlLink === "/report/electronic" ? (
          <ElectronicReportUI />
        )

          : urlLink === "/report/request" ? (
            <ElectronicManagerReportUI />
          )

            : urlLink === "/report/received" ? (
              <ElectronicReceiverReportUI />
            )

              : urlLink === "/report/PlanSent" ? (
                <PlanSenderUI />
              )

                : urlLink === "/report/PlanRecieved" ? (
                  <PlanBossPageUI />
                )

                  : urlLink === "/setting/listof-category/list-of-employee" ? (
                    <ListOfEmployeeUI />
                  ) : urlLink === "/setting/listof-category/list-of-task" ? (
                    <ListOfTaskUI />
                  ) : urlLink === "/setting/listof-category/list-of-general" ? (
                    <ListOfGeneralUI />
                  ) : urlLink === "/setting/listof-category/prior-rate" ? (
                    <PriorRateUI />
                  ) : urlLink === "/setting/listof-category/score-rating-capacity" ? (
                    <ScoreRatingCapacityUI />
                  ) : urlLink === "/setting/listof-category/target-rating-capacity" ? (
                    <TargetRatingCapacityUI />
                  ) : urlLink === "/setting/setting/alert-tasking" ? (
                    <AlertTaskingSettingUI />
                  ) : urlLink === "/setting/setting/email-automatic" ? (
                    <EmailAutomaticUI />
                  ) : urlLink === "/setting/setting/overload-setting" ? (
                    <OverloadSettingUI />
                  ) : urlLink === "/setting/listof-category/security-rate" ? (
                    <ListOfSecurityLevelUI />
                  ) : urlLink === "/setting/listof-category/difficult-rate" ? (
                    <DifficultRateUI />
                  ) : urlLink === "/user/user-list" ? (
                    <UserListUI />
                  ) : urlLink === "/user/functional-list" ? (
                    <FunctionalListUI />
                  ) : urlLink === "/user/screen-list" ? (
                    <ScreenListUI />
                  ) : urlLink === "/user/work-category-list" ? (
                    <WorkCategoryListUI />
                  ) : urlLink === "/user/data-option" ? (
                    <DataOptionUI />
                  ) : urlLink === "/propose/sender/list" ? (
                    <ProposeDeliverListUI />
                  ) : urlLink === "/propose/receiver/list" ? (
                    <ProposeReceiverListUI />
                  ) : urlLink === "/working/task-finish-punctual" ? (
                    <FinishPunctual />
                  ) : urlLink === "/working/task-finish-late" ? (
                    <FinishLate />
                  ) : urlLink === "/working/task-listall" ? (
                    <WorkingTaskListAllUI />
                  ) : urlLink === "/working/task-finish-early" ? (
                    <FinishEarly />
                  ) : urlLink === "/high-manager" ? (
                    <HighManagerUI />
                  ) : urlLink === "/normal-staff" ? (
                    <NormalStaffUI />
                  ) : urlLink === "/medium-manager" ? (
                    <MediumManagerUI />
                  ) : urlLink === "/allPage" ? (
                    <AllUI />
                  ) : (
                    <Error404UI />
                  )}

      {/* Button temp */}
      <button className="
        w-max h-max fixed bottom-[3rem] right-4
        p-2 bg-slate-400 rounded-full drop-shadow-md
        hover:bg-slate-200 hover:text-white
        z-20
      "
        onClick={() => setIsShowTemp(true)}
      >
        <CreateRoundedIcon fontSize="small" />
      </button>

      {/* Fields temp */}
      <div className={`
        w-[22rem] h-[25rem] fixed bottom-[3rem] right-4
        p-2 bg-white rounded-md drop-shadow-md
        ${isShowTemp === true ? "block" : "hidden"}
        z-20
      `}>
        {/* header */}
        <div className="w-full h-[2rem] flex justify-between items-center">
          <p className="font-semibold">CÁC THAO TÁC TẠM HOÃN</p>

          <button className="w-[1.5rem] justify-center items-center h-[1.5rem] flex rounded-full hover:bg-slate-100"
            onClick={() => setIsShowTemp(false)}
          >
            <ClearRoundedIcon />
          </button>
        </div>

        {/* content */}
        <div className="w-full h-[calc(100%-2rem)] flex">
          <div className="flex-col space-y-2 w-full h-full text-sm text-black flex">
            {/* Create new task */}
            {
              isHaveCreateTask === true ?
                <div className="w-full h-max flex justify-between bg-red-200 p-1.5">
                  <button className="w-max h-max flex justify-start hover:underline"
                    onClick={_onClickCreateNewTask}
                  >
                    <ArrowRightIcon fontSize="small" />
                    <p>Tạo công việc mới</p>
                  </button>

                  <button className="w-max h-max text-red-500 hover:text-red-400"
                    onClick={_onClickDeleteTaskTemp}
                  >
                    <DeleteIcon fontSize="small" />
                  </button>
                </div>

                : <></>
            }

            {/* Create new employee */}
            {
              isHaveCreateEmployee === true ?
                <div className="w-full h-max flex justify-between bg-red-200 p-1.5">
                  <button className="w-max h-max flex justify-start hover:underline"
                    onClick={_onClickCreateEmployee}
                  >
                    <ArrowRightIcon fontSize="small" />
                    <p>Tạo nhân viên mới</p>
                  </button>

                  <button className="w-max h-max text-red-500 hover:text-red-400"
                    onClick={_onClickDeleteEmployeeTemp}
                  >
                    <DeleteIcon fontSize="small" />
                  </button>
                </div>

                : <></>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
