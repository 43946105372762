import React, { useState, useContext, useEffect, useRef } from "react";
import { ModalController } from "../../../ui/homeUI/_index";
import { bangdiemnangluc_list } from "../../../action/bangdiemdanhgiananglucAction";
import moment from "moment";

// icon components
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import NotificationsNoneTwoToneIcon from "@mui/icons-material/NotificationsNoneTwoTone";
import SearchIcon from "@mui/icons-material/Search";
import LoaderSpinner from "../../loader-spinner/_index";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";



const SettingScoring = () => {

	const context = useContext(ModalController)
	
	const [data, setData ] = useState<any | null>(null)
	const [dataALL, setDataALL ] = useState<any | null>(null)
	// ref
	const shouldLog = useRef(true)

	const _onClickDetailTableCapacity = (id: any) => {
		context.setFuncs("detailTableScoringCapacity");
		context.setTaskId(id);
		context.handleOpen();
	};

	const _onClickDeleteTask = (id: any) => {
		context.setFuncs("deleteEmployee");
		context.setTaskId(id);
		context.handleOpen();
	};

	useEffect(() => {
		if (shouldLog.current) {
			shouldLog.current = false
			getList();
		}
	}, [])
	// read all
	const getList = () => {
		bangdiemnangluc_list().then((res: any) => {
			setData(res.data);
			setDataALL(res.dataALL);
		});
	}

	return (
		<div className="flex flex-col text-sm w-full lg:h-[calc(100%-7rem)] pb-[5rem] lg:mt-0 mt-3">
			{" "}
			{/* warm */}
			<div className="tableFixHead styled-scrollbars styled-scrollbars w-full h-[calc(100%-4rem)] shadow-md">
				<table>
					<thead className="text-white font-semibold text-center w-full h-[2rem]">
						<tr>
							<th className="border-l-[1px] border-slate-300">STT</th>
							<th className="border-l-[1px] border-slate-300">Tiêu đề thiết lập</th>
							<th className="border-l-[1px] border-slate-300">Nội dung</th>
							<th className="border-l-[1px] border-slate-300">Phòng ban</th>
							<th className="border-l-[1px] border-slate-300">Ngày áp dụng</th>
							<th className="border-l-[1px] border-slate-300">Áp dụng</th>
							<th className="border-l-[1px] border-r-[1px] border-slate-300"></th>
						</tr>
					</thead>
					<tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
						{
							data === null ? <></>
								: data?.map((item: any, index: any) => (
									<tr className="w-max h-[3rem] bg-white">
										<td className="text-center border border-slate-300">{index + 1}</td>
										<td className="border border-slate-300">{item.tieude}</td>
										<td className="text-center border border-slate-300">{item.NoiDung}</td>
										<td className="text-center border border-slate-300">{item.TenPhongBan}</td>
										<td className="text-center border border-slate-300">
											{moment(item.NgayApDung).format('DD/MM/YYYY')} <br />
											{moment(item.NgayApDung).format('HH:mm:ss')}
										</td>
										<td className="text-center border border-slate-300">
											<input
												type="checkbox"
												checked={item.IsActive}
											/>
										</td>
										<td className="text-center border border-slate-300">
											<div className="dropdown">
												{/* button options */}
												<button className="dropbtn">
													<MoreHorizIcon fontSize="small" />
												</button>

												{/* options fields */}
												<div className="dropdown-content text-xs font-semibold">
													{/* detail */}
													<button
														className="flex w-full h-full"
														onClick={() => _onClickDetailTableCapacity(item.id)}
													>
														{/* detail - contain */}
														<div className="flex w-full h-full space-x-1 hover:text-sky-500">
															{/* icon */}
															<DescriptionTwoToneIcon
																className="text-sky-600"
																sx={{ fontSize: 18 }}
																fontSize="small"
															/>

															{/* title */}
															<div>Chi tiết / Chỉnh sửa</div>
														</div>
													</button>

													<button
														className="flex w-full h-full hover:text-red-500"
														onClick={() => _onClickDeleteTask(1)}
													>
														{/* delete - contain */}
														<div className="flex w-full space-x-1">
															{/* icon */}
															<DeleteTwoToneIcon
																className="text-red-600"
																sx={{ fontSize: 18 }}
																fontSize="small"
															/>

															{/* title */}
															<div className="h-full">Xoá</div>
														</div>
													</button>
												</div>
											</div>
										</td>
									</tr>
								))
						}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default SettingScoring