import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ModeTwoToneIcon from '@mui/icons-material/ModeTwoTone';

import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

import { takePermission } from "../../../funcs";
import { onTouchForm } from "../../../funcs/onTochForm";

import { DETAIL_TASK_LIST, UPDATE_TASK_LIST, READ_ALL_TASK_LIST } from "../../../apis/_index";
import { ONLOAD_TASKLIST } from "../../../redux/reducers/settingReducer/_index";

import axios from "axios";
import { toast } from "react-toastify";

import LoaderSpinner from "../../loader-spinner/_index";
import SubModal from "../_subModal/_index";

const DetailListOfTask = (props: any) => {

  // state submodal
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const menu = takePermission(11);

  const taskId = props.taskId;
  const handleClose = props.handleClose;

  const shouldLog = useRef(true);

  const data = useSelector((state: any) => state.settingReducer);
  const dispatch = useDispatch();

  // state
  const [taskName, setTaskName] = useState<string | null>(null);
  const [parentID, setParentID] = useState<string | number | null>(null);
  const [groupList, setGroupList] = useState<string | null>(null);

  useEffect(() => {
    if (taskName === null && parentID === null && groupList === null && shouldLog.current) {
      shouldLog.current = false;
      const accessToken = localStorage.getItem("accessToken");
      const API = DETAIL_TASK_LIST + taskId.toString();

      const takeData = async () => {
        try {
          await axios.get(
            API,
            {
              headers: {
                Authorization: "Bearer " + accessToken,
              }
            }
          )
            .then((res: any) => {
              if (res.status === 200) {
                // const data = ((res || {}).data||{}).Data[0] || []
                setTaskName(res.data.Data[0].Tendauviec);
                setParentID(res.data.Data[0].ParentID);
                setGroupList(res.data.Data[0].GroupList);
              } else {
                toast.error("Tải dữ liệu thất bại")
              }
            })
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại");
        }
      }

      takeData();
    }
  })

  const _onChangeTaskName = (e: any) => {
    e.preventDefault();
    setTaskName(e.target.value);
  }

  const _onChangeParentID = (e: any) => {
    e.preventDefault();
    setParentID(e.target.value);
  }

  const _onDeleteTaskList = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  }

  const _onChangeGroupList = (e: any) => {
    e.preventDefault();
    setGroupList(e.target.value);
  }

  const _onClickSubmit = async () => {
    const accessToken = localStorage.getItem("accessToken");

    setIsLoading(true)

    const body = {
      Id: taskId,
      Tendauviec: taskName,
      ParentID: parentID,
      GroupList: ""
    }

    try {
      await axios.post(
        UPDATE_TASK_LIST,
        body,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
            "Content-Type": "application/json"
          }
        }
      )
        .then((res: any) => {
          if (res.status === 200) {
            const takeData = async () => {
              try {
                await axios.get(
                  READ_ALL_TASK_LIST,
                  {
                    headers: {
                      Authorization: "Bearer " + accessToken
                    }
                  }
                )
                  .then((res: any) => {
                    if (res.status === 200) {
                      dispatch(ONLOAD_TASKLIST(res.data.Data));
                      toast.success("Cập nhật thành công.")
                      handleClose()
                      setIsLoading(false)
                      // setIsLoading(false);
                    } else {
                      toast.error("Cập nhật thất bại.");
                      setIsLoading(false)
                      // setIsLoading(false);
                    }
                  })
              } catch (error: any) {
                toast.error("Cập nhật thất bại.");
                setIsLoading(false)
                // setIsLoading(false);
                return;
              }
            }
            takeData();
          } else {
            toast.error("Cập nhật thất bại.")
            setIsLoading(false)
          }
        })
    } catch (error: any) {
      toast.error("Cập nhật thất bại.")
      setIsLoading(false)
    }
  }

  return (
    <div className="
    flex flex-col bg-white
    lg:w-[40rem] w-screen h-[30rem]
    rounded-md
  ">
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
      />

      {/* header */}
      <div className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      ">
        <p>Chi tiết đầu việc</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      {
        taskName === null && parentID === null && groupList === null  || isLoading === true ?
          <div className="relative justify-center items-center flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-1">
            <LoaderSpinner w={32} h={32} />
            <p className="text-primary">Đang tải dữ liệu</p>
          </div>
          :
          <div className="relative flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars space-y-4">
            {/* lines */}
            <div className="flex w-full h-max">
              <div className="flex flex-col w-full pr-0 h-max">
                <label>Tên đầu mục công việc&nbsp;<span className="text-red-500">(*)</span></label>
                <input
                  className={`w-full h-[1.9rem] rounded-md pl-2 pr-2 border-2 border-slate-200 ${menu.PerEdit === true ? "" : "bg-slate-200"}`}
                  value={taskName === null ? "" : taskName}
                  readOnly={menu.PerEdit === true ? false : true}
                  onChange={(e: any) => { _onChangeTaskName(e) }}
                  onTouchStart={(e: any) => onTouchForm(e)}
                />

              </div>
            </div>

            <div className="flex w-full h-max">
              <div className="flex flex-col w-full pr-0 h-max">
                <label>Mô tả</label>
                <textarea
                  className={`${menu.PerEdit === true ? "" : "bg-slate-200"} w-full h-[5rem] rounded-md pt-2 pl-2 pr-2 border-2 border-slate-200`}
                  placeholder="Nhập mô tả"
                  readOnly={menu.PerEdit === true ? false : true}
                />
              </div>
            </div>

            <div className="flex w-full h-max">
              <div className="flex flex-col w-full pr-0 h-max">
                <label>Thuộc đầu mục&nbsp;<span className="text-red-500">(*)</span></label>

                <select
                  className={`w-full h-[1.9rem] rounded-md pl-1 pr-1 border-2 border-slate-200 ${menu.PerEdit === true ? "" : "bg-slate-200"}`}
                  value={parentID === null ? 1 : parentID}
                  disabled={menu.PerEdit === true ? false : true}
                  onChange={(e: any) => { _onChangeParentID(e) }}
                >
                  <option className="text-gray-300" value={-1} disabled>--- Chọn đầu mục ---</option>
                  <option value={0}>KHÔNG</option>
                  {data.taskList.length === 0 ? "" :
                    data.taskList.map((item: any, index: any) => (
                      <>
                        {item.Id === parseInt(taskId) ? "" : <option key={index} value={item.Id}>{item.Tendauviec}</option>}
                      </>
                    ))
                  }
                </select>

              </div>
            </div>


            {/* <div className="absolute left-4 bottom-4 flex items-center space-x-4 lg:w-1/3 w-full lg:pr-2 mt-5 pr-0 h-max">
              <p className="font-bold text-primary">Hiển thị sử dụng</p>
              <input
                type="checkbox"
                readOnly
              />
            </div> */}
          </div>
      }
      {/* footer */}
      {
        taskName === null && parentID === null && groupList === null ?
          <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-between items-center pl-4 pr-4 text-sm space-x-4"></div>
          :
          <div className={`flex w-full h-[3rem] bg-slate-100 rounded-b-md ${menu.PerDelete === true ? "justify-between" : "justify-end"} items-center pl-4 pr-4 text-sm space-x-4`}>
            <button className={`
          fitems-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-500 text-white rounded-md
          hover:bg-slate-400
          ${menu.PerDelete === true ? "flex" : "hidden"}
        `}
              onClick={() => _onDeleteTaskList("deleteTaskList")}
            >
              <DeleteIcon fontSize="small" />
              <p>Xoá</p>
            </button>

            <div className="flex w-max h-max space-x-3">
              <button className={`
          items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md
          ${menu.PerEdit === true ? "flex" : "hidden"}
          ${isLoading === true ? "bg-slate-300" : " hover:bg-sky-400 bg-primary"}
      `}
                disabled={isLoading}
                onClick={_onClickSubmit}
              >
                <SaveIcon fontSize="small" />
                <p>Lưu chỉnh sửa</p>
              </button>

              <button className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
                onClick={handleClose}
              >
                <ExitToAppTwoToneIcon fontSize="small" />
                <p>Đóng</p>
              </button>
            </div>
          </div>
      }

    </div>
  )
}

export default DetailListOfTask;
