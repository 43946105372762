import { get, post } from "../ApiHelper/index";
import { NANG_LUC_NHAN_SU_LIST } from "../apis/_index";

export const listNanglucnhasuAction = (body: any) => {
    return new Promise(async resolve => {
        // dispatch({
        //     type: 'LISTNANGLUCNHANSU', data: initialState
        // });
        return await post(NANG_LUC_NHAN_SU_LIST, body)
            .then((res: any) => {

                resolve({
                    status: true,
                    data: res.data.Data
                });
            })
            .catch((err: any) => {
                resolve({
                    status: false,
                    err: err
                });
            })
    })
}





