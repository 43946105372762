import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ONCHANGE_NGUOI_PHOI_HOP } from "../../../redux/reducers/workingReducer/_index";

import { DETAIL_STEP, DETAIL_RESULT } from "../../../apis/_index";

import { onTouchForm } from "../../../funcs/onTochForm";

const MultipleChoicePartner = (props: any) => {
  const handleClose = props.handleClose;
  let selectedNguoiPhoiHopList = props.selectedNguoiPhoiHopList;
  const selectedNguoiLamViecList = props.selectedNguoiLamViecList;
  const _onCheckNguoiPhoiHop = props._onCheckNguoiPhoiHop;
  // redux
  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();
  const [_listrender, set_listrender] = useState<any | null>(null);

  const _onClickSearchSubmit = (e: any) => {
    set_listrender(
      props.selectedNguoiPhoiHopList?.filter((x: any) =>
        x.FullName.toUpperCase().includes(e.target.value.toUpperCase())
      )
    );
  };

  useEffect(() => {
    set_listrender([...selectedNguoiPhoiHopList]);
  }, []);

  return (
    <div
      className="
        lg:w-[40rem] w-screen lg:h-[40rem] h-[calc(100vh-5rem)] bg-white rounded-md
        flex flex-col
      "
    >
      {/* header */}
      <div
        className="
        flex items-center pl-4 pr-4
        text-white font-semibold
        bg-sky-500 w-full h-[3rem]
        rounded-t-md text-lg justify-between
      "
      >
        <p>Chọn người phối hợp</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      <div
        className="
          flex flex-col p-4 w-full h-[calc(100%-6rem)]
          text-sm
        "
      >
        <div className="flex w-full h-[5rem]">
          <div className="flex flex-col w-full h-max">
            <label>Tên nhân sự</label>
            <input
              className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
              placeholder="Nhập tên nhân sự cần tìm"
              onChange={(e: any) => _onClickSearchSubmit(e)}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
          {/* <div className="flex flex-col w-1/5 pl-3">
            <label className="text-white">Tìm kiếm</label>
            <button
              className="
                bg-slate-300 text-black w-full h-max rounded-md
                hover:bg-slate-200 pt-1.5 pb-1.5
              " onClick={_onClickSearchSubmit}
            >
              Tìm kiếm
            </button>
          </div> */}
        </div>

        <div className="tableFixHead w-full h-[calc(100%-5rem)] shadow-md border-t-2">
          <table>
            <thead className="text-white font-semibold text-center w-full h-[2rem]">
              <tr>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300"></th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  STT
                </th>
                <th className="border-l-[1px] border-slate-300">Tên nhân sự</th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  Phòng ban
                </th>
                <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                  Tổng ngày công trong tháng
                </th>
              </tr>
            </thead>
            <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
              {_listrender === null ? (
                <tr></tr>
              ) : (
                _listrender?.map((item: any, index: any) => (
                  <>
                    {item.Id === "999" ? (
                      ""
                    ) : (
                      <tr key={index} className="w-max h-[3rem] bg-white">
                        <td
                          className={`
                          hidden lg:table-cell border border-slate-300 text-center
                        `}
                        >
                          <input
                            key={index}
                            type="checkbox"
                            value={item.Id}
                            checked={
                              selectedNguoiLamViecList?.find(
                                (item2: any) => item2.Id === item.Id
                              ) === undefined
                                ? false
                                : true
                            }
                            onChange={() => _onCheckNguoiPhoiHop(item.Id)}
                          />
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300 text-center">
                          {index + 1}
                        </td>
                        <td className={`border border-slate-300`}>
                          <p
                            className="hidden lg:flex font-bold"
                            style={{ color: `${item.ColorText}` }}
                          >
                            {item.FullName}
                          </p>

                          <div className="lg:hidden">
                            <p
                              className="font-bold"
                              style={{ color: `${item.ColorText}` }}
                            >
                              <span className="lg:hidden">
                                {index + 1}
                                .&nbsp;
                              </span>
                              {item.FullName}
                            </p>

                            <p>Phòng ban: {item.TenPhongBan}</p>
                            <p>
                              Tổng ngày công trong tháng: {item.SoLuongTask}
                            </p>
                            <div className="flex items-center space-x-1">
                              <input
                                key={index}
                                type="checkbox"
                                value={item.Id}
                                checked={
                                  selectedNguoiLamViecList?.find(
                                    (item2: any) => item2.Id === item.Id
                                  ) === undefined
                                    ? false
                                    : true
                                }
                                onChange={() => _onCheckNguoiPhoiHop(item.Id)}
                              />
                              <label>Chọn hỗ trợ</label>
                            </div>
                          </div>
                        </td>
                        <td
                          className={`hidden lg:table-cell border border-slate-300 text-center`}
                        >
                          {item.TenPhongBan}
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300  text-center">
                          {item.SoLuongTask}
                        </td>
                      </tr>
                    )}
                  </>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* footer */}
      <div
        className="
          flex items-center justify-end w-full h-[3rem] bg-slate-100 rounded-b-md space-x-4 text-sm
          pl-4 pr-4
        "
      >
        <button
          className="bg-green-500 text-white pt-1 pb-1 pr-2 pl-2 rounded-md hover:bg-green-400"
          onClick={handleClose}
        >
          Xác nhận
        </button>
        <button
          className="bg-slate-300 text-white pt-1 pb-1 pr-2 pl-2 rounded-md hover:bg-slate-200"
          onClick={handleClose}
        >
          Đóng
        </button>
      </div>
    </div>
  );
};

export default MultipleChoicePartner;
