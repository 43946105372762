export let bodyLogin = (username: string, password: string): any => {
  return {
    username: username,
    password: password,
    client_id: "task_api",
    scope: "read",
    grant_type: "password",
  };
};

export const dataStatus = [
  {
    Id: 0,
    label: "--- Chọn trạng thái ---",
    value: "--- Chọn trạng thái ---",
  },
  {
    Id: 2,
    label: "Chưa giao",
    value: "Chưa giao",
  },
  {
    Id: 3,
    label: "Đã nhận",
    value: "Đã nhận",
  },
  {
    Id: 4,
    label: "Đang xử lý",
    value: "Đang xử lý",
  },
  {
    Id: 5,
    label: "Hoàn tất",
    value: "Hoàn tất",
  },
  {
    Id: 34,
    label: "Trễ hạn",
    value: "Trễ hạn",
  },
  {
    Id: 345,
    label: "Hoàn tất trễ hạn",
    value: "Hoàn tất trễ hạn",
  },
];

export const dataResult = [
  {
    Id: 1,
    value: 'Xuất sắc',
    label: 'Xuất sắc'
  },
  {
    Id: 2,
    value: 'Tốt',
    label: 'Tốt'
  },
  {
    Id: 3,
    value: 'Trung bình',
    label: 'Trung bình'
  },
  {
    Id: 4,
    value: 'Kém',
    label: 'Kém'
  },
]
