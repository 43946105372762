import React, { useState, useContext } from "react";
import { useSelector } from "react-redux";

import axios from "axios";

import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import CheckIcon from "@mui/icons-material/Check";

import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { ModalController } from "../../../ui/homeUI/_index";
import { PROPOSE_CONFIRM } from "../../../apis/_index";
import { toast } from "react-toastify";

const ConfirmPropose = (props: any) => {
  const taskId = props.taskId;
  const _loadData = props._loadData;
  const handleClose = props.handleClose;
  const context = useContext(ModalController);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rateValue, setRateValue] = React.useState<number | null>(0);

  // funcs
  const _onClickOK = async () => {
    setIsLoading(true);
    const accessToken = localStorage.getItem("accessToken");

    const body = {
      ID: taskId,
      Star: rateValue,
    };

    try {
      await axios
        .post(PROPOSE_CONFIRM, body, {
          headers: {
            Authorization: "Bearer " + accessToken,
            Accept: "text/plain",
          },
        })
        .then((res: any) => {
          if (res.data.StatusCode === 200) {
            toast.success("Đã duyệt đề xuất");
            handleClose();
            _loadData();
            setIsLoading(false);
            context.handleClose();
          } else {
            toast.error(res.data.Message || "Duyệt đề xuất thất bại");
          }
        });
    } catch (error: any) {
      toast.error(error || "Duyệt đề xuất thất bại");
      setIsLoading(false);
    }
  };

  return (
    <div className=" flex flex-col bg-white lg:w-[30rem] md:w-[30rem] h-[18rem] w-screen rounded-md ">
      {/* header */}
      <div
        className="
        flex h-[3rem] bg-sky-500 rounded-t-md
        px-4 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Xác nhận duyệt đề xuất</p>
        <button className="" onClick={handleClose}>
          X
        </button>
      </div>

      {/* content */}
      <div className="grid grid-cols-1 lg:w-[30rem] md:w-[30rem] w-full h-[calc(100%-2rem)] text-center content-center text-xl">
        <p>
          Đánh giá đề xuất&nbsp;<span className="text-red-500">(*)</span>
        </p>
        <Box
          sx={{
            "& > legend": { mt: 2 },
          }}
          className="scale-150"
        >
          <Typography component="legend"></Typography>
          <Rating
            name="simple-controlled"
            value={rateValue}
            onChange={(event, newValue) => {
              setRateValue(newValue);
            }}
          />
        </Box>
      </div>

      {/* footer */}
      <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-end items-center pl-4 pr-4 text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          <button
            className={`
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 text-white rounded-md
          ${
            rateValue === null || rateValue === 0
              ? "bg-slate-300"
              : "bg-green-500 hover:bg-green-400"
          }
        `}
            disabled={rateValue === null || rateValue === 0}
            onClick={_onClickOK}
          >
            <CheckIcon fontSize="small" />
            <p>Xác nhận</p>
          </button>

          <button
            className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-red-500
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPropose;
