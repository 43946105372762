import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  READ_ALL_LIST_QUYTRINH_DROPDOWN,
  LOAD_PHONG_BAN,
  PRIOR_LIST_DROPDOWN,
  SECURITY_READ_ALL_DROPDOWN,
  READ_TASK_LIST_BY_USER,
  TAKE_PARAMETER_LISTKEY_DROPDOWN,
  DETAIL_STEP,
  DETAIL_RESULT,
  CREATE_TASK_LIST_NGUOI_GIAO,
  NGUOI_GIAO_VIEC_LIST,
  NGUOI_GIAO_VIEC_DETAIL,
  NGUOI_GIAO_VIEC_UPDATE,
  DIFFICULT_LEVEL,
  CONFIG_IS_FORCE,
  DIFICULT_LEVEL_DROPDOWN,
  LOAD_PHONG_BAN_BY_USER,
  NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN
} from "../../../apis/_index";

import LoaderSpinner from "../../loader-spinner/_index";

import { readList } from "../../../funcs/taskNguoiGiaoViec";
import PersonOutlineTwoToneIcon from "@mui/icons-material/PersonOutlineTwoTone";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import CameraFrontTwoToneIcon from "@mui/icons-material/CameraFrontTwoTone";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import ExitToAppTwoToneIcon from "@mui/icons-material/ExitToAppTwoTone";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import SubModal from "../_subModal/_index";
import { toast } from "react-toastify";
import axios from "axios";

import { ONLOAD_TASKLIST_ALL } from "../../../redux/reducers/workingReducer/_index";

const CreateNewTask = (props: any) => {
  // reducer
  const data = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  // take props
  const handleClose = props.handleClose;
  const taskId = props.taskId;

  // state
  const shouldLog = useRef(true);
  const shouldLog3 = useRef(true);
  const urlLink = window.location.pathname;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // submodal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);

  const [phongBanList, setPhongBanList] = useState<any[] | null>(null);
  const [userList, setUserList] = useState<any[] | null>(null);
  const [loaiViecList, setLoaiViecList] = useState<any[] | null>(null);
  const [nguonVanBanList, setNguonVanBanList] = useState<any[] | null>(null);
  const [uuTienList, setUuTienList] = useState<any[] | null>(null);
  const [doMatList, setDoMatList] = useState<any[] | null>(null);
  const [dauViecList, setDauViecList] = useState<any[] | null>(null);
  const [quyTrinhList, setQuyTrinhList] = useState<any[] | null>(null);
  const [doKhoList, setDoKhoList] = useState<any[] | null>(null);
  const uploadFileChat = useRef<any>(null);

  const [selectedDoKho, setSelectedDoKho] = useState<any>(-1);
  const [selectedPhongBan, setSelectedPhongBan] = useState<any>(-1);
  const [selectedNguoiNhan, setSelectedNguoiNhan] = useState<any>(-1);
  const [selectedLoaiViec, setSelectedLoaiViec] = useState<any>(-1);
  const [selectedNguonVanBan, setSelectedNguonVanBan] = useState<any>(-1);
  const [selectedUuTien, setSelectedUuTien] = useState<any>(-1);
  const [selectedDoMat, setSelectedDoMat] = useState<any>(-1);
  const [selectedDauViec, setSelectedDauViec] = useState<any>(-1);
  const [selectedQuyTrinh, setSelectedQuyTrinh] = useState<any>(-1);

  const [isAutoForce, setIsAutoForce] = useState<boolean | null>(null);
  const [isAutoSecurity, setIsAutoSecurity] = useState<boolean | null>(null);

  const [selectedNguoiNhanList, setSelectedNguoiNhanList] = useState<any>(null);
  const [selectedNguoiPhoiHopList, setSelectedNguoiPhoiHopList] =
    useState<any>(null);
  const [selectedNguoiLamViecList, setSelectedNguoiLamViecList] = useState<any>(
    []
  );

  const [selectedBuocList, setSelectedBuocList] = useState<any>(null);
  const [selectedKetQuaList, setSelectedKetQuaList] = useState<any>(null);

  const [filesList, setFilesList] = useState<any>([]);
  const [filesNameList, setFilesNameList] = useState<any>();

  const [fileListAPI, setFileListAPI] = useState<any[]>([]);

  const [notes, setNotes] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isForce, setIsForce] = useState<boolean>(false);

  // other thing
  const [taskName, setTaskName] = useState<any>("");
  const [deadLine, setDeadLine] = useState<any>("");
  const [refCode, setRefCode] = useState<any>("");

  // props - states
  const [positionStep, setPositionStep] = useState<number | null>(null);
  const [hourStep, setHourStep] = useState<number | null>(null);
  const [contentStep, setContentStep] = useState<string>("");

  const [ketqua, setKetqua] = useState<string>("");

  // funcs
  // -- for read api
  const _onChangeRefCode = (e: any) => {
    e.preventDefault();
    setRefCode(e.target.value);
  };

  const _onChangePhongBanAPI = (e: any) => {
    setSelectedPhongBan(
      phongBanList?.find((item: any) => item.IdPhongBan === parseInt(e))
    );
    setSelectedNguoiNhanList(
      userList?.filter((item: any) => item.IdPhongBan === parseInt(e))
    );
    setSelectedNguoiNhan(-1);

    setSelectedNguoiLamViecList([]);
    setSelectedQuyTrinh(-1);
    setSelectedBuocList(null);
    setSelectedKetQuaList(null);
  };

  const _onChangeNguoiNhanAPI = (e: any) => {
    setSelectedNguoiNhan(
      selectedNguoiNhanList?.find((item: any) => item.Id === e)
    );
    setSelectedNguoiPhoiHopList(userList?.filter((item: any) => item.Id !== e));

    const tempArray = [
      selectedNguoiNhanList?.find((item: any) => item.Id === e),
    ];

    setSelectedNguoiLamViecList(tempArray);
  };

  const _onClickSwitchToBoss = () => {
    setFuncsSub("switchBoss");
    handleOpenSub();
  };
  const _onClickChooseFileChat = () => {
    uploadFileChat.current.click();
  };

  const _onChangeStepList = (type: string, value: any, index: any) => {
    switch (type) {
      case "stepName":
        let newArray = [...selectedBuocList];
        newArray[index].StepName = value;
        setSelectedBuocList(newArray);
        break;
      case "orderBy":
        let newArray2 = [...selectedBuocList];
        newArray2[index].SortOrder = value;
        setSelectedBuocList(newArray2);
        break;
      case "result":
        let newArray3 = [...selectedKetQuaList];
        newArray3[index].tenketqua = value;
        setSelectedKetQuaList(newArray3);
        break;
      default:
        break;
    }
  };

  // -- for input
  const _onChangePhongBan = (e: any) => {
    e.preventDefault();
    setSelectedPhongBan(
      phongBanList?.find(
        (item: any) => item.IdPhongBan === parseInt(e.target.value)
      )
    );
    setSelectedNguoiNhanList(
      userList?.filter(
        (item: any) => item.IdPhongBan === parseInt(e.target.value)
      )
    );
    setSelectedNguoiNhan(-1);

    setSelectedNguoiLamViecList([]);
    setSelectedQuyTrinh(-1);
    setSelectedBuocList(null);
    setSelectedKetQuaList(null);
  };

  const _onChangeDoKho = (e: any) => {
    e.preventDefault();
    setSelectedDoKho(
      doKhoList?.find((item: any) => item.Id === parseInt(e.target.value))
    );
  };

  const _onChangeNguoiNhan = (e: any) => {
    e.preventDefault();
    setSelectedNguoiNhan(
      selectedNguoiNhanList?.find((item: any) => item.Id === e.target.value)
    );
    setSelectedNguoiPhoiHopList(
      userList?.filter((item: any) => item.Id !== e.target.value)
    );

    const tempArray = [
      selectedNguoiNhanList?.find((item: any) => item.Id === e.target.value),
    ];

    setSelectedNguoiLamViecList(tempArray);
  };

  const _onCheckNguoiLamViec = (id: any) => {
    const isTrue = selectedNguoiLamViecList?.find(
      (item: any) => item.Id === id
    );

    if (isTrue === undefined) {
      setSelectedNguoiLamViecList((oldValue: any) => [
        ...oldValue,
        userList?.find((item: any) => item.Id === id),
      ]);
    } else {
      const tempArray = selectedNguoiLamViecList?.filter(
        (item: any) => item.Id !== id
      );
      setSelectedNguoiLamViecList(tempArray);
    }
  };

  const _onChangeQuyTrinh = (e: any) => {
    e.preventDefault();

    if (e.target.value === "-1") {
      setSelectedQuyTrinh(-1);
      setSelectedBuocList(null);
      setSelectedKetQuaList(null);
    } else {
      const accessToken = localStorage.getItem("accessToken");

      setSelectedQuyTrinh(
        quyTrinhList?.find((item: any) => item.id === parseInt(e.target.value))
      );

      const takeStep = async () => {
        try {
          await axios
            .get(DETAIL_STEP + e.target.value, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              let tempArray: any[] = [];

              res.data.Data.forEach((item: any, index: any) => {
                const body = {
                  StepName: item.tenbuoc,
                  UserDo: "",
                  DeadLine_Step: "",
                  SortOrder: item.orderBy,
                };
                tempArray.push(body);
              });

              setSelectedBuocList(tempArray);
            });
        } catch (error: any) { }
      };

      const takeResult = async () => {
        try {
          await axios
            .get(DETAIL_RESULT + e.target.value, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              setSelectedKetQuaList(res.data.Data);
            });
        } catch (error: any) { }
      };

      takeStep();
      takeResult();
    }
  };

  // non - funcs
  const _onChangeNguoiPhoiHop = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onClickEditBuoc = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onClickEditKetqua = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  // props - funcs
  // buoc
  const _onChangePositionStep = (e: any) => {
    e.preventDefault();
    setPositionStep(parseInt(e.target.value));
  };

  const _onChangeHourStep = (e: any) => {
    e.preventDefault();
    setHourStep(e.target.value);
  };

  const _onChangeContentStep = (e: any) => {
    e.preventDefault();
    setContentStep(e.target.value);
  };

  const _onPushStep = () => {
    const body = {
      StepName: contentStep,
      UserDo: "",
      DeadLine_Step: "",
      SortOrder: positionStep,
    };

    let isExistedStep = selectedBuocList.find(
      (item: any) => item.SortOrder === positionStep
    );

    if (isExistedStep === undefined) {
      setSelectedBuocList((oldValue: any) => [...oldValue, body]);
    } else {
      // do nothing
    }

    setPositionStep(null);
    setHourStep(null);
    setContentStep("");
  };

  const _onDeleteQuyTrinh = (step: any) => {
    setSelectedBuocList(
      selectedBuocList.filter((item: any) => item.SortOrder !== step)
    );
  };

  const _onChangeNguoiPhoiHopEachStep = (orderBy: any, nguoiPhoiHop: any) => {
    const updateArray = selectedBuocList.map((item: any) => {
      if (item.SortOrder === orderBy) {
        return {
          ...item,
          UserDo: nguoiPhoiHop,
        };
      }

      return item;
    });

    setSelectedBuocList(updateArray);
  };

  const _onChangDeadLineStep = (orderBy: any, deadLine: any) => {
    const updateArray = selectedBuocList.map((item: any) => {
      if (item.SortOrder === orderBy) {
        return {
          ...item,
          DeadLine_Step: deadLine,
        };
      }

      return item;
    });

    setSelectedBuocList(updateArray);
  };

  // ket qua
  const _onChangeKetqua = (e: any) => {
    e.preventDefault();
    setKetqua(e.target.value);
  };

  const _onPushKetqua = () => {
    const body = {
      idquytrinh: selectedQuyTrinh.Id,
      tenketqua: ketqua,
    };

    setSelectedKetQuaList((oldValue: any) => [...oldValue, body]);
    setKetqua("");
  };

  const _onDeleteKetqua = (ketQua: string) => {
    setSelectedKetQuaList(
      selectedKetQuaList.filter((item: any) => item.tenketqua !== ketQua)
    );
  };

  // files
  const _onChangeFileImage = (e: any) => {
    const files = e.target.files || [];

    let tempArray: any[] = [...filesList] || [];

    for (let i = 0; i < files.length; i++) {
      tempArray.push(
        new File([files[i]], files[i].name, { type: files[i].type })
      );
    }

    setFilesList(tempArray);
  };

  // const _onChangeFileLoad = (res: any) => {
  //   // console.log("fileList", res.fileList)
  //   // console.log("fileListName", res.fileListName)

  //   const fileList = res.fileList.split(";")
  //   const fileListName = res.fileListName.split(",")

  //   console.log(fileList[0])
  //   console.log(fileListName[0])

  //   console.log(fileListName.length)

  //   let tempArray: any[] = []

  //   for (let i = 0; i < fileListName.length; i++) {
  //     let tempFile = {
  //       listFile: fileList[i],
  //       listFileName: fileListName[i]
  //     }

  //     console.log(tempFile)

  //     tempArray.push(tempFile)

  //     console.log(tempArray)
  //   }

  //   setFilesListAPI(tempArray)

  //   console.log(filesListAPI)
  // }

  const _onDeleteElements = (index: number) => {
    const tempArray: any[] = [...filesList];
    tempArray.splice(index, 1);
    setFilesList(tempArray);
  };

  // other things
  const _onChangeTaskName = (e: any) => {
    e.preventDefault();
    setTaskName(e.target.value);
  };

  const _onChangeDeadLine = (e: any) => {
    e.preventDefault();
    setDeadLine(e.target.value);
  };

  const _onChangeSelectedLoaiCongViec = (e: any) => {
    e.preventDefault();
    setSelectedLoaiViec(
      loaiViecList?.find(
        (item: any) => item.ParameterId === parseInt(e.target.value)
      )
    );
  };

  const _onChangeNguonVanBan = (e: any) => {
    e.preventDefault();
    setSelectedNguonVanBan(
      nguonVanBanList?.find(
        (item: any) => item.ParameterId === parseInt(e.target.value)
      )
    );
  };

  const _onChangeSelectedUuTien = (e: any) => {
    e.preventDefault();
    setSelectedUuTien(
      uuTienList?.find((item: any) => item.Id === parseInt(e.target.value))
    );
  };

  const _onChangeDoMat = (e: any) => {
    e.preventDefault();
    setSelectedDoMat(
      doMatList?.find((item: any) => item.Id === e.target.value)
    );
  };

  const _onChangeDauViec = (e: any) => {
    e.preventDefault();
    setSelectedDauViec(
      dauViecList?.find((item: any) => item.Id === parseInt(e.target.value))
    );
  };

  const _onChangeNotes = (e: any) => {
    e.preventDefault();
    setNotes(e.target.value);
  };

  const _onChangeDescription = (e: any) => {
    e.preventDefault();
    setDescription(e.target.value);
  };

  const _onChangeIsForce = () => {
    setIsForce((item: any) => !item);
  };

  const _onDeleteFileReady = (linkFile: string) => {
    setFileListAPI(
      fileListAPI.filter((item: any) => item.listFile !== linkFile)
    );
  };

  // submit
  // funcs
  // const _onClickSubmit = () => {
  //   // CHÚ Ý:
  //   // - Tạo 2 mảng
  //   // - Lưu
  //   setIsLoading(true);
  //   let tempIsForce: string = isForce === true ? "1" : "0";

  //   let tempStringNguoiPhoiHop: string = "";

  //   let fileString: string = "";

  //   selectedNguoiLamViecList.forEach((item: any, index: any) => {
  //     if (index === 0) {
  //       // do nothing
  //     } else if (index === selectedNguoiLamViecList.length - 1) {
  //       tempStringNguoiPhoiHop += `${item.Id.toString()}`;
  //     } else {
  //       tempStringNguoiPhoiHop += `${item.Id.toString()},`;
  //     }
  //   });

  //   const formData = new FormData();

  //   formData.append("ID", taskId);
  //   formData.append("TaskName", taskName ? taskName : "");
  //   formData.append("TaskListID", selectedDauViec ? selectedDauViec.Id : "");
  //   formData.append("PriorityLevelId", selectedUuTien ? selectedUuTien.Id : "");
  //   formData.append("SecurityLevelID", selectedDoMat ? selectedDoMat.Id : "");

  //   formData.append("DifficultLevelID", selectedDoKho ? selectedDoKho.Id : "");

  //   formData.append("RefCode", "1");

  //   formData.append(
  //     "LoaiViecID",
  //     selectedLoaiViec ? selectedLoaiViec.ParameterId : ""
  //   );
  //   formData.append(
  //     "NguonViecID",
  //     selectedNguonVanBan ? selectedNguonVanBan.ParameterId : ""
  //   );
  //   formData.append("QuyTrinhID", selectedQuyTrinh ? selectedQuyTrinh.id : "");
  //   formData.append("Recipient", selectedNguoiNhan ? selectedNguoiNhan.Id : "");

  //   formData.append("Supporter", tempStringNguoiPhoiHop);
  //   formData.append("Description", description ? description : "");
  //   formData.append("Notes", notes ? notes : "");
  //   formData.append("DeadLine", deadLine ? deadLine : "");

  //   if (filesList) {
  //     for (let i = 0; i < filesList.length; i++) {
  //       formData.append(`Files_TaskFiles`, filesList[i]);

  //       if (i === filesList.length - 1) {
  //         fileString += `${filesList[i].name}`;
  //       } else {
  //         fileString += `${filesList[i].name};`;
  //       }
  //     }
  //   }

  //   formData.append("FilesName_TaskFiles", fileString);

  //   if (selectedBuocList !== null) {
  //     for (let index = 0; index < selectedBuocList.length; index++) {
  //       formData.append(
  //         `TaskContent[${index}].StepName`,
  //         selectedBuocList[index].StepName
  //       );
  //       formData.append(
  //         `TaskContent[${index}].UserDo`,
  //         selectedBuocList[index].UserDo
  //       );
  //       formData.append(
  //         `TaskContent[${index}].SortOrder`,
  //         selectedBuocList[index].SortOrder
  //       );
  //       formData.append(
  //         `TaskContent[${index}].DeadLine_Step`,
  //         selectedBuocList[index].DeadLine_Step
  //       );
  //     }
  //   }

  //   if (selectedKetQuaList !== null) {
  //     for (let index = 0; index < selectedKetQuaList.length; index++) {
  //       formData.append(
  //         `TaskResult[${index}].ResultText`,
  //         selectedKetQuaList[index].tenketqua
  //       );
  //     }
  //   }

  //   formData.append("IsForce", isForce.toString());

  //   const takeData = async () => {
  //     const accessToken = localStorage.getItem("accessToken");

  //     try {
  //       await axios
  //         .post(CREATE_TASK_LIST_NGUOI_GIAO, formData, {
  //           headers: {
  //             Authorization: "Bearer " + accessToken,
  //             "Content-Type": "multipart/form-data",
  //           },
  //         })
  //         .then((res: any) => {
  //           if (res.data.StatusCode === 200) {
  //             readList(
  //               NGUOI_GIAO_VIEC_LIST,
  //               data.pageCurrent,
  //               data.choosePage,
  //               data.searchFilter.taskName,
  //               data.searchFilter.selectedPrior,
  //               data.searchFilter.selectedReceiver,
  //               data.searchFilter.fromDate,
  //               data.searchFilter.toDate,
  //               data.searchFilter.deadLine,
  //               shouldLog3,
  //               urlLink,
  //               (res: any) => {
  //                 dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
  //               }
  //             );
  //             setIsLoading(false);
  //             toast.success("Tạo công viêc mới thành công.");
  //           } else {
  //             setIsLoading(false);
  //           }
  //         });
  //     } catch (error: any) {
  //       setIsLoading(false);
  //       toast.error("Tạo công việc mới thất bại");
  //     }
  //   };

  //   takeData();
  // };

  const _onClickUpdate = () => {
    // CHÚ Ý:
    // - Tạo 2 mảng
    // - Lưu
    setIsLoading(true);
    let tempIsForce: string = isForce === true ? "1" : "0";

    let tempStringNguoiPhoiHop: string = "";

    selectedNguoiLamViecList.forEach((item: any, index: any) => {
      if (index === 0) {
        // do nothing
      } else if (index === selectedNguoiLamViecList.length - 1) {
        tempStringNguoiPhoiHop += `${item.Id.toString()}`;
      } else {
        tempStringNguoiPhoiHop += `${item.Id.toString()},`;
      }
    });

    const formData = new FormData();

    formData.append("ID", taskId);
    formData.append("TaskName", taskName ? taskName : "");
    formData.append("TaskListID", selectedDauViec ? selectedDauViec.Id : "");
    formData.append("PriorityLevelId", selectedUuTien ? selectedUuTien.Id : "");
    formData.append("SecurityLevelID", selectedDoMat ? selectedDoMat.Id : "");

    formData.append("DifficultLevelID", selectedDoKho ? selectedDoKho.Id : "");

    formData.append("RefCode", "1");

    formData.append(
      "LoaiViecID",
      selectedLoaiViec ? selectedLoaiViec.ParameterId : ""
    );
    formData.append(
      "NguonViecID",
      selectedNguonVanBan ? selectedNguonVanBan.ParameterId : ""
    );
    formData.append("QuyTrinhID", selectedQuyTrinh ? selectedQuyTrinh.id : "");
    formData.append("Recipient", selectedNguoiNhan ? selectedNguoiNhan.Id : "");

    formData.append("Supporter", tempStringNguoiPhoiHop);
    formData.append("Description", description ? description : "");
    formData.append("Notes", notes ? notes : "");
    formData.append("DeadLine", deadLine ? deadLine : "");

    let listFileString: string = "";
    let listFileNameString: string = "";
    let listFileNoTaskString: string = "";

    if (filesList.length === 0) {
      // No files changing
      fileListAPI.forEach((item: any, index: any) => {
        if (index === fileListAPI.length - 1) {
          listFileString += `${item.listFile}`;
          listFileNameString += `${item.listFileName}`;
        } else {
          listFileString += `${item.listFile};`;
          listFileNameString += `${item.listFileName};`;
        }
      });

      formData.append("ListFiles", listFileString);
      formData.append("FilesName_TaskFiles", listFileNameString);
      formData.append("Files_TaskFiles", listFileNoTaskString);
    } else {
      // Adding new
      fileListAPI.forEach((item: any, index: any) => {
        if (index === fileListAPI.length - 1) {
          listFileString += `${item.listFile}`;
          listFileNameString += `${item.listFileName}`;
        } else {
          listFileString += `${item.listFile};`;
          listFileNameString += `${item.listFileName};`;
        }
      });

      filesList.forEach((item: any, index: any) => {
        formData.append("Files_TaskFiles", item);

        if (index === 0) {
          if (fileListAPI.length === 0 || fileListAPI === null) {
            listFileNameString += `${item.name};`;
          } else {
            listFileNameString += `;${item.name};`;
          }
        } else if (index === filesList.length - 1) {
          listFileNameString += `${item.name}`;
        } else {
          listFileNameString += `${item.name};`;
        }
      });

      formData.append("ListFiles", listFileString);
      formData.append("FilesName_TaskFiles", listFileNameString);
    }

    // formData.append("ListFiles", listFileString)
    // formData.append("FilesName_TaskFiles", listFileNameString)
    // formData.append("Files_TaskFiles", listFileNoTaskString)

    // if (filesList) {
    //   for (let i = 0; i < filesList.length; i++) {
    //     formData.append(`Files_TaskFiles`, filesList[i]);

    //     if (i === filesList.length - 1) {
    //       fileString += `${filesList[i].name}`;
    //     } else {
    //       fileString += `${filesList[i].name};`;
    //     }
    //   }
    //   formData.append("FilesName_TaskFiles", fileString);
    // }

    // fileListAPI.forEach((item: any, index: any) => {
    //   if (index === fileListAPI.length - 1) {
    //     fileStringReady += `${item.listFileName}`
    //   } else {
    //     fileStringReady += `${item.listFileName};`
    //   }
    // });

    // formData.append("ListFiles", fileStringReady);

    if (selectedBuocList !== null) {
      for (let index = 0; index < selectedBuocList.length; index++) {
        formData.append(
          `TaskContent[${index}].StepName`,
          selectedBuocList[index].StepName
        );
        formData.append(
          `TaskContent[${index}].UserDo`,
          selectedBuocList[index].UserDo
        );
        formData.append(
          `TaskContent[${index}].SortOrder`,
          selectedBuocList[index].SortOrder
        );
        formData.append(
          `TaskContent[${index}].DeadLine_Step`,
          selectedBuocList[index].DeadLine_Step
        );
      }
    }

    if (selectedKetQuaList !== null) {
      for (let index = 0; index < selectedKetQuaList.length; index++) {
        formData.append(
          `TaskResult[${index}].ResultText`,
          selectedKetQuaList[index].tenketqua
        );
      }
    }

    formData.append("IsForce", isForce.toString());
    const API_reload = window.location.pathname === '/working/new-task' ? NGUOI_GIAO_VIEC_LIST : NGUOI_GIAO_VIEC_LIST_NEED_ASSIGN

    const takeData = async () => {
      const accessToken = localStorage.getItem("accessToken");

      try {
        await axios
          .post(NGUOI_GIAO_VIEC_UPDATE, formData, {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res: any) => {
            if (res.data.StatusCode === 200) {
              readList(
                API_reload,
                data.pageCurrent,
                data.choosePage,
                data.searchFilter.taskName,
                data.searchFilter.selectedPrior,
                data.searchFilter.selectedReceiver,
                data.searchFilter.fromDate,
                data.searchFilter.toDate,
                data.searchFilter.deadLine,
                shouldLog3,
                urlLink,
                (res: any) => {
                  dispatch(ONLOAD_TASKLIST_ALL(res.data.Data));
                }
              );
              setIsLoading(false);
              toast.success("Chỉnh sửa thành công.");

              handleClose();
            } else {
              setIsLoading(false);
              toast.error(res.data.Message);
            }
          });
      } catch (error: any) {
        setIsLoading(false);
        toast.error("Chỉnh sửa thất bại");
      }
    };

    takeData();
  };

  // load selecting data
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (shouldLog.current) {
      shouldLog.current = false;

      if (isAutoForce === null) {
        const accessToken = localStorage.getItem("accessToken");

        const takeData = async () => {
          try {
            await axios
              .get(CONFIG_IS_FORCE, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  if (res.data.Data[0].AllowTaskReject === false) {
                    setIsForce(true);
                  }
                  if (res.data.Data[0].AllowUseSecurity === false) {
                    setSelectedDoMat(null);
                  }

                  setIsAutoForce(res.data.Data[0].AllowTaskReject);
                  setIsAutoSecurity(res.data.Data[0].AllowUseSecurity);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (phongBanList === null || userList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(LOAD_PHONG_BAN_BY_USER, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.data.StatusCode === 200) {
                  setPhongBanList(res.data.Data.PhongBan);
                  setUserList(res.data.Data.NguoiNhan);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (loaiViecList === null) {
        const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_LOAIVIEC";

        const takeData = async () => {
          try {
            await axios
              .get(API, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setLoaiViecList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (doKhoList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(DIFICULT_LEVEL_DROPDOWN, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setDoKhoList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (nguonVanBanList === null) {
        const API = TAKE_PARAMETER_LISTKEY_DROPDOWN + "LI_NGUONVANBAN";

        const takeData = async () => {
          try {
            await axios
              .get(API, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setNguonVanBanList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (uuTienList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(PRIOR_LIST_DROPDOWN, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setUuTienList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (doMatList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(SECURITY_READ_ALL_DROPDOWN, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setDoMatList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (dauViecList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(READ_TASK_LIST_BY_USER, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setDauViecList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }

      if (quyTrinhList === null) {
        const takeData = async () => {
          try {
            await axios
              .get(READ_ALL_LIST_QUYTRINH_DROPDOWN, {
                headers: {
                  Accept: "text/plain",
                  Authorization: "Bearer " + accessToken,
                },
              })
              .then((res: any) => {
                if (res.status === 200) {
                  setQuyTrinhList(res.data.Data);
                }
              });
          } catch (error: any) { }
        };

        takeData();
      }
    }
  });

  const _onCheckValid = (): boolean => {
    if (
      selectedBuocList === null ||
      selectedKetQuaList === null ||
      selectedQuyTrinh === -1
    ) {
      return true;
    } else {
      for (let i = 0; i < selectedBuocList.length; i++) {
        if (
          selectedBuocList[i].UserDo === "" ||
          selectedBuocList[i].DeadLine_Step === ""
        ) {
          return false;
        }
      }
      return true;
    }
  };

  // load detail
  useEffect(() => {
    if (
      phongBanList === null ||
      userList === null ||
      loaiViecList === null ||
      nguonVanBanList === null ||
      uuTienList === null ||
      doMatList === null ||
      dauViecList === null ||
      quyTrinhList === null ||
      doKhoList === null
    ) {
      // do nothing
    } else {
      const accessToken = localStorage.getItem("accessToken");
      const API = NGUOI_GIAO_VIEC_DETAIL(taskId);

      const takeData = async () => {
        try {
          await axios
            .get(API, {
              headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "text/plain",
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                // main thing

                // other things
                setSelectedDauViec(
                  dauViecList?.find(
                    (item: any) =>
                      item.Id === parseInt(res.data.Data.task.TaskListID)
                  )
                );
                setDeadLine(res.data.Data.task.DeadLine);
                setNotes(res.data.Data.task.Notes);
                setDescription(res.data.Data.task.Description);
                setIsForce(res.data.Data.task.IsForce);
                setRefCode(res.data.Data.task.RefCode);
                setSelectedLoaiViec(
                  loaiViecList?.find(
                    (item: any) =>
                      item.ParameterId === res.data.Data.task.LoaiViecID
                  )
                );
                setSelectedNguonVanBan(
                  nguonVanBanList?.find(
                    (item: any) =>
                      item.ParameterId === res.data.Data.task.NguonViecID
                  )
                );
                setSelectedQuyTrinh(
                  quyTrinhList?.find(
                    (item: any) => item.id === res.data.Data.task.QuyTrinhID
                  )
                );

                // other things
                setTaskName(res.data.Data.task.TaskName);

                setSelectedDoMat(
                  doMatList?.find(
                    (item: any) =>
                      item.Id === res.data.Data.task.SecurityLevelID.toString()
                  )
                );
                setSelectedUuTien(
                  uuTienList?.find(
                    (item: any) =>
                      item.Id === res.data.Data.task.PriorityLevelID
                  )
                );

                setSelectedPhongBan(
                  phongBanList?.find(
                    (item: any) =>
                      item.IdPhongBan ===
                      parseInt(res.data.Data.task.IdPhongBan)
                  )
                );
                setSelectedNguoiNhanList(
                  userList?.filter(
                    (item: any) =>
                      item.IdPhongBan ===
                      parseInt(res.data.Data.task.IdPhongBan)
                  )
                );
                setSelectedNguoiNhan(
                  userList?.find(
                    (item: any) => item.Id === res.data.Data.task.Recipient
                  )
                );
                setSelectedNguoiPhoiHopList(
                  userList?.filter(
                    (item: any) => item.Id !== res.data.Data.task.Recipient
                  )
                );
                setSelectedDoKho(
                  doKhoList?.find(
                    (item: any) =>
                      item.Id === parseInt(res.data.Data.task.DifficultLevelID)
                  )
                );

                let supp: any = "";

                if (res.data.Data.task.Supporter === null) {
                  supp = ",";
                } else {
                  supp = res.data.Data.task.Supporter.replace("{", "")
                    .replaceAll("}{", ",")
                    .replace("}", "");
                }

                const suppArray = supp.split(",");

                let tempArray: any[] = [];

                tempArray.push(
                  userList?.find(
                    (item: any) => item.Id === res.data.Data.task.Recipient
                  )
                );

                userList.forEach((item: any, index: any) => {
                  const isExist = suppArray.includes(item.Id);

                  if (isExist === true) {
                    tempArray.push(item);
                  } else {
                    // do nothing
                  }
                });

                setSelectedNguoiLamViecList(tempArray);

                let tempArrayBuoc: any[] = [];
                let tempArrayKetqua: any[] = [];

                res.data.Data.taskContents.forEach((item: any) => {
                  const body = {
                    ID: item.ID,
                    StepName: item.StepName,
                    UserDo: item.UserDo,
                    DeadLine_Step: item.DeadLine,
                    SortOrder: item.SortOrder,
                  };

                  tempArrayBuoc.push(body);
                });

                res.data.Data.taskResults.forEach((item: any) => {
                  const body = {
                    id: item.ID,
                    tenketqua: item.ResultText,
                  };

                  tempArrayKetqua.push(body);
                });

                setSelectedBuocList(tempArrayBuoc);
                setSelectedKetQuaList(tempArrayKetqua);

                const fileListResponse =
                  res.data.Data.task.ListFiles === null
                    ? []
                    : res.data.Data.task.ListFiles.split(";");
                const fileListNameResponse =
                  res.data.Data.task.ListFilesName === null
                    ? []
                    : res.data.Data.task.ListFilesName.split(";");

                let tempFileArray: any[] = [];

                fileListNameResponse.forEach((item: any, index: any) => {
                  let tempObject = {
                    listFile: fileListResponse[index],
                    listFileName: item,
                  };

                  tempFileArray.push(tempObject);
                });

                setFileListAPI(tempFileArray);
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại");
        }
      };

      takeData();
    }
  }, [
    phongBanList,
    userList,
    loaiViecList,
    nguonVanBanList,
    uuTienList,
    doMatList,
    dauViecList,
    quyTrinhList,
    taskId,
  ]);

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[70rem] w-screen lg:h-[40rem] h-[calc(100vh-10rem)]
      rounded-md
    "
    >
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        _onCheckNguoiPhoiHop={_onCheckNguoiLamViec}
        taskId={taskId}
        selectedNguoiPhoiHopList={selectedNguoiPhoiHopList}
        selectedNguoiLamViecList={selectedNguoiLamViecList}
        selectedBuocList={selectedBuocList}
        positionStep={positionStep}
        hourStep={hourStep}
        contentStep={contentStep}
        _onChangePositionStep={_onChangePositionStep}
        _onChangeHourStep={_onChangeHourStep}
        _onChangeContentStep={_onChangeContentStep}
        _onPushStep={_onPushStep}
        _onDeleteQuyTrinh={_onDeleteQuyTrinh}
        _onChangeNguoiPhoiHopEachStep={_onChangeNguoiPhoiHopEachStep}
        selectedKetQuaList={selectedKetQuaList}
        ketqua={ketqua}
        _onPushKetqua={_onPushKetqua}
        _onChangeKetqua={_onChangeKetqua}
        _onDeleteKetqua={_onDeleteKetqua}
        _onChangeStepList={_onChangeStepList}
        funcs={funcsSub}
      />

      {/* header */}
      <div
        className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Chi tiết / Chỉnh sửa</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      {phongBanList === null ||
        userList === null ||
        loaiViecList === null ||
        nguonVanBanList === null ||
        uuTienList === null ||
        doMatList === null ||
        dauViecList === null ||
        quyTrinhList === null ||
        isAutoSecurity === null ||
        isAutoForce === null ||
        doKhoList === null ? (
        <div className="flex text-primary justify-center items-center flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
          <LoaderSpinner w={32} h={32} />
          <p>Đang tải biểu mẫu</p>
        </div>
      ) : isLoading === true ? (
        <div className="flex text-primary justify-center items-center flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
          <LoaderSpinner w={32} h={32} />
          <p>Đang tạo công việc mới</p>
        </div>
      ) : (
        <div className="flex flex-col w-full h-[calc(100%-6rem)] overflow-auto p-4 text-sm styled-scrollbars">
          {/* <div className="w-max h-max flex justify-between items-center bg-slate-200 p-1">
                <p>Mã công việc - {taskId}</p>
              </div> */}

          <div className="w-full h-max flex justify-between items-center">
            <p className="font-semibold text-red-500">
              Lưu ý: Việc đã được giao thì không được phép chỉnh sửa thông tin
            </p>
            <button
              className="w-max h-max rounded-md pl-2 pr-2 pt-1.5 pb-1.5 bg-slate-300 font-bold hover:bg-slate-200"
              onClick={() => {
                window.location.reload();
              }}
            >
              Tải lại
            </button>
          </div>
          <>
            {/* Web detail */}
            <div className="hidden lg:flex w-full h-max flex-col mt-4 text-sm space-y-4">
              <div className="flex w-full h-max">
                {/* <div className="flex flex-col w-full h-max">
                  <p className="px-2 py-1 bg-slate-300 w-max h-max font-semibold">Người giao: Lưu Anh Dũng</p>
                </div> */}

                <div className="flex flex-col w-full h-max">
                  <label>
                    Đầu mục&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={
                      selectedDauViec === -1 ||
                        selectedDauViec === null ||
                        selectedDauViec === undefined
                        ? -1
                        : selectedDauViec.Id
                    }
                    onChange={(e: any) => _onChangeDauViec(e)}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn loại đầu mục ---
                    </option>
                    {dauViecList === null ? (
                      <></>
                    ) : (
                      dauViecList.map((item: any, index: any) => (
                        <option key={index} value={item.Id}>
                          {item.Tendauviec}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="flex w-full h-max">
                <div className="flex flex-col w-1/2 pr-3 h-max">
                  <label>
                    Tên công việc &nbsp;
                    <span className="text-red-500">(*)</span>{" "}
                  </label>
                  <input
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    placeholder="Nhập tên công việc"
                    value={taskName}
                    onChange={(e: any) => _onChangeTaskName(e)}
                  />
                </div>
                <div className="flex flex-col pl-3 w-1/4 h-max">
                  <label>
                    Loại công việc&nbsp;
                    <span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={
                      selectedLoaiViec === -1
                        ? -1
                        : selectedLoaiViec.ParameterId
                    }
                    onChange={(e: any) => _onChangeSelectedLoaiCongViec(e)}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn loại công việc ---
                    </option>
                    {loaiViecList === null ? (
                      <></>
                    ) : (
                      loaiViecList.map((item: any, index: any) => (
                        <option key={index} value={item.ParameterId}>
                          {item.ParameterValue}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="flex flex-col pl-3 w-1/4 h-max">
                  <label>
                    Mức độ&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`
                      w-full h-[2rem] rounded-md pl-2 pr-2 border-2
                      ${selectedUuTien === -1 ? "" : "font-bold"}
                    `}
                    value={selectedUuTien === -1 ? -1 : selectedUuTien.Id}
                    onChange={(e: any) => _onChangeSelectedUuTien(e)}
                    style={{
                      color: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                        }`,
                      borderColor: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                        }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn mức độ ---
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item.Color}` }}
                          key={index}
                          value={item.Id}
                        >
                          {item.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="flex w-full h-max">
                <div className="w-1/4 pr-3 h-max">
                  <label>
                    Phòng ban&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 ${selectedPhongBan === -1 ? "" : "font-bold"
                      }`}
                    value={
                      selectedPhongBan === -1 ? -1 : selectedPhongBan?.IdPhongBan
                    }
                    onChange={(e: any) => _onChangePhongBan(e)}
                    style={{
                      color: `${selectedPhongBan === -1
                        ? ""
                        : selectedPhongBan?.ColorText
                        }`,
                      borderColor: `${selectedPhongBan === -1
                        ? ""
                        : selectedPhongBan?.ColorText
                        }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn phòng ban ---
                    </option>
                    {phongBanList === null ? (
                      <></>
                    ) : (
                      phongBanList.map((item: any, index: any) => (
                        <option key={index} value={item.IdPhongBan}>
                          {item.TenPhongBan}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-1/4 pr-3 h-max">
                  <label>
                    Người nhận&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedNguoiNhan === -1 ? "" : "font-bold"}
                  `}
                    style={{
                      color: `${selectedNguoiNhan === -1
                        ? ""
                        : selectedNguoiNhan.ColorText
                        }`,
                      borderColor: `${selectedNguoiNhan === -1
                        ? ""
                        : selectedNguoiNhan.ColorText
                        }`,
                    }}
                    value={selectedNguoiNhan === -1 ? -1 : selectedNguoiNhan.Id}
                    onChange={(e: any) => _onChangeNguoiNhan(e)}
                    disabled={selectedPhongBan === -1 ? true : false}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn người nhận ---
                    </option>
                    {selectedNguoiNhanList === null ? (
                      <></>
                    ) : (
                      selectedNguoiNhanList.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item.ColorText}` }}
                          key={index}
                          value={item.Id}
                        >
                          {item.FullName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-1/4 pl-3 h-max">
                  <label>
                    Nguồn văn bản&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={
                      selectedNguonVanBan === -1
                        ? -1
                        : selectedNguonVanBan.ParameterId
                    }
                    onChange={(e: any) => _onChangeNguonVanBan(e)}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn nguồn văn bản ---
                    </option>
                    {nguonVanBanList === null ? (
                      <></>
                    ) : (
                      nguonVanBanList.map((item: any, index: any) => (
                        <option key={index} value={item.ParameterId}>
                          {item.ParameterValue}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div
                  className={`w-1/4 pl-3 flex-col h-max ${isAutoSecurity === false ? "hidden" : "flex"
                    }`}
                >
                  <label>
                    Độ mật<template></template>&nbsp;
                    <span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedDoMat === -1 ? "" : "font-bold"}
                  `}
                    value={
                      selectedDoMat === -1 ||
                        selectedDoMat === null ||
                        selectedDoMat === undefined
                        ? -1
                        : selectedDoMat.Id
                    }
                    onChange={(e: any) => _onChangeDoMat(e)}
                    style={{
                      color: `${selectedDoMat === -1 ||
                        selectedDoMat === null ||
                        selectedDoMat === undefined
                        ? ""
                        : selectedDoMat.Color
                        }`,
                      borderColor: `${selectedDoMat === -1 ||
                        selectedDoMat === null ||
                        selectedDoMat === undefined
                        ? ""
                        : selectedDoMat.Color
                        }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn độ mật ---
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item.Color}` }}
                          key={index}
                          value={item.Id}
                        >
                          {item.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="flex w-full h-max items-center">
                {/* overload */}
                <div className="flex w-1/2 h-max items-center">
                  <div style={{ display: "flex" }}>
                    <label>Có thể nhận việc: </label>
                    <div
                      style={{
                        backgroundColor: "#00ff00",
                        width: 30,
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                    >
                      {" "}
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <label>Đang nhiều việc: </label>
                    <div
                      style={{
                        backgroundColor: "#FFAA00",
                        width: 30,
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                    ></div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <label>Quá tải: </label>
                    <div
                      style={{
                        backgroundColor: "#ff0000",
                        width: 30,
                        marginLeft: 10,
                      }}
                    ></div>
                  </div>
                </div>

                {/* dificult level */}
                <div className="flex w-1/2 h-max items-center">
                  <div className="w-full pl-3 h-max">
                    <label>
                      Độ khó<template></template>&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <select
                      className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedDoKho === -1 ? "" : "font-bold"}
                  `}
                      value={
                        selectedDoKho === -1 ||
                          selectedDoKho === null ||
                          selectedDoKho === 0 ||
                          selectedDoKho === undefined
                          ? -1
                          : selectedDoKho.Id
                      }
                      onChange={(e: any) => _onChangeDoKho(e)}
                      style={{
                        color: `${selectedDoKho === -1 || selectedDoKho === undefined
                          ? ""
                          : selectedDoKho.Color
                          }`,
                        borderColor: `${selectedDoKho === -1 || selectedDoKho === undefined
                          ? ""
                          : selectedDoKho.Color
                          }`,
                      }}
                    >
                      <option className="text-gray-300" value={-1} disabled>
                        --- Chọn độ khó ---
                      </option>
                      {doKhoList === null ? (
                        <></>
                      ) : (
                        doKhoList.map((item: any, index: any) => (
                          <option
                            style={{ color: `${item.Color}` }}
                            key={index}
                            value={item.Id}
                          >
                            {item.DifficultLevelName}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="flex w-full h-max">
                {/* elemetns */}
                <div className="flex flex-col w-1/2 pr-3 h-max space-y-4">
                  <div className="flex flex-col w-full h-max space-y-1">
                    <div className="flex w-max h-max space-x-3">
                      <label>Người phối hợp (nếu có)</label>
                      <button
                        className={`
                    text-white pl-2 pr-2 pt-0.5 pb-0.5 rounded-md
                    text-xs
                    ${selectedNguoiNhan === -1
                            ? "bg-slate-300"
                            : "bg-primary hover:bg-sky-400"
                          }
                  `}
                        disabled={selectedNguoiNhan === -1 ? true : false}
                        onClick={() =>
                          _onChangeNguoiPhoiHop("multipleChoicePartner")
                        }
                      >
                        Chọn
                      </button>
                    </div>
                    <div className="flex flex-col w-1/2 h-max">
                      {selectedNguoiLamViecList === null
                        ? ""
                        : selectedNguoiLamViecList.map(
                          (item: any, index: any) => (
                            <>
                              {item.Id === selectedNguoiNhan.Id ? (
                                <></>
                              ) : (
                                <div
                                  key={index}
                                  className="flex w-full h-max text-primary items-center justify-between"
                                >
                                  <div
                                    className="flex w-5/6 h-max space-x-1 font-bold"
                                    style={{
                                      color: `${item.ColorText}`,
                                    }}
                                  >
                                    <PersonOutlineTwoToneIcon fontSize="small" />
                                    <p>{item.FullName}</p>
                                  </div>
                                  <button
                                    className="text-red-500 w-1/6 flex justify-end"
                                    onClick={() =>
                                      _onCheckNguoiLamViec(item.Id)
                                    }
                                  >
                                    <DeleteTwoToneIcon fontSize="small" />
                                  </button>
                                </div>
                              )}
                            </>
                          )
                        )}
                    </div>
                  </div>
                  <div
                    className={`
                flex-col w-full h-max space-y-1
              `}
                  >
                    <div
                      className={`${isAutoForce === false ? "hidden" : "flex"
                        } w-max h-max space-x-3`}
                    >
                      <input
                        type="checkbox"
                        checked={isForce}
                        onChange={_onChangeIsForce}
                      />
                      <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200">
                        KHÔNG CHO PHÉP TRẢ VIỆC
                      </label>
                    </div>
                  </div>
                </div>
                <div className="flex w-1/2 pl-3 h-max space-x-3">
                  {/* elements */}
                  <div className="w-1/2 h-max">
                    <label>
                      Thời hạn hoàn thành&nbsp;
                      <span className="text-red-500">(*)</span>
                    </label>
                    <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      placeholder="Nhập tên công việc"
                      type="datetime-local"
                      value={deadLine}
                      onChange={(e: any) => _onChangeDeadLine(e)}
                    />
                  </div>
                  <div className="w-1/2 h-max">
                    <label>Mã tham chiếu</label>
                    <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      placeholder="Nhập refcode"
                      value={refCode}
                      onChange={(e: any) => _onChangeRefCode(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col w-1/2 h-max">
                <label>Quy trình&nbsp;(nếu có)</label>
                <select
                  className={`
                  w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                  `}
                  value={
                    selectedQuyTrinh === -1 || selectedQuyTrinh === undefined
                      ? -1
                      : selectedQuyTrinh.id
                  }
                  disabled={selectedNguoiNhan === -1 ? true : false}
                  onChange={(e: any) => _onChangeQuyTrinh(e)}
                >
                  <option className="text-gray-300" value={-1}>
                    --- Chọn quy trình ---
                  </option>
                  {quyTrinhList === null ? (
                    <></>
                  ) : (
                    quyTrinhList.map((item: any, index: any) => (
                      <option key={index} value={item.id}>
                        {item.tenquytrinh}
                      </option>
                    ))
                  )}
                </select>
              </div>
              <div className="flex w-full h-max">
                {/* buoc */}
                <div
                  className={`
              w-2/3 h-max pr-3 tableFixHead
              ${selectedBuocList === null ? "hidden" : "flex"}
            `}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300">Bước</th>
                        <th className="border border-slate-300">Nội dung</th>
                        <th className="border border-slate-300">
                          Người thực hiện
                        </th>
                        <th className="border border-slate-300">Thời hạn</th>
                        <th className="border border-slate-300">
                          <button
                            className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                            onClick={() => _onClickEditBuoc("editBuoc")}
                          >
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedBuocList === null
                        ? ""
                        : selectedBuocList.map((item: any, index: any) => (
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              <p>{item.SortOrder}</p>
                            </td>
                            <td className="border border-slate-300">
                              <p>{item.StepName}</p>
                            </td>
                            <td className="text-center border border-slate-300">
                              <select
                                className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                value={item.UserDo}
                                onChange={(e: any) => {
                                  e.preventDefault();
                                  _onChangeNguoiPhoiHopEachStep(
                                    item.SortOrder,
                                    e.target.value
                                  );
                                }}
                              >
                                <option
                                  className="text-gray-300"
                                  value={""}
                                  disabled
                                >
                                  --- Chọn người phối hợp ---
                                </option>
                                {selectedNguoiLamViecList === null
                                  ? ""
                                  : selectedNguoiLamViecList.map(
                                    (item: any, index: any) => (
                                      <option key={index} value={item.Id}>
                                        {item.FullName}
                                      </option>
                                    )
                                  )}
                              </select>
                            </td>
                            <td className="text-center border border-slate-300">
                              <input
                                className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                type="datetime-local"
                                value={item.DeadLine_Step}
                                onChange={(e: any) => {
                                  e.preventDefault();
                                  _onChangDeadLineStep(
                                    item.SortOrder,
                                    e.target.value
                                  );
                                }}
                              />
                            </td>
                            <td className="border border-slate-300">
                              {/* <button className="text-red-500 w-1/6">
                                  <DeleteTwoToneIcon fontSize="small" />
                                </button> */}
                              <button
                                className="text-red-500 w-max h-max flex text-center hover:text-red -300"
                                onClick={() =>
                                  _onDeleteQuyTrinh(item.SortOrder)
                                }
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>

                {/* ket qua */}
                <div
                  className={`
              w-1/3 h-max pl-3
              ${selectedKetQuaList === null ? "hidden" : "flex"}
            `}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 ">STT</th>
                        <th className="border border-slate-300 ">
                          Kết quả cần đạt
                        </th>
                        <th className="border text-center border-slate-300 w-[1rem]">
                          <button
                            className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                            onClick={() => _onClickEditKetqua("editKetqua")}
                          >
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedKetQuaList === null ? (
                        <tr></tr>
                      ) : (
                        selectedKetQuaList.map((item: any, index: any) => (
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {index + 1}
                            </td>
                            <td className="text-left border border-slate-300">
                              {item.tenketqua}
                            </td>
                            <td className="border text-center border-slate-300 w-[1rem]">
                              <button
                                className="text-red-500 w-max h-max flex text-center hover:text-red -300"
                                onClick={() => _onDeleteKetqua(item.tenketqua)}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* Phone detail */}
            <div className="lg:hidden">
              <div className="flex w-full h-max my-1.5">
                <div className="flex flex-col w-full h-max">
                  <label>
                    Đầu mục&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={
                      selectedDauViec === -1 ||
                        selectedDauViec === null ||
                        selectedDauViec === undefined
                        ? -1
                        : selectedDauViec.Id
                    }
                    onChange={(e: any) => _onChangeDauViec(e)}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn loại đầu mục ---
                    </option>
                    {dauViecList === null ? (
                      <></>
                    ) : (
                      dauViecList.map((item: any, index: any) => (
                        <option key={index} value={item.Id}>
                          {item.Tendauviec}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="flex w-full h-max my-1.5">
                <div className="flex flex-col w-1/2 pr-3 h-max">
                  <label>
                    Tên công việc &nbsp;
                    <span className="text-red-500">(*)</span>{" "}
                  </label>
                  <input
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    placeholder="Nhập tên công việc"
                    value={taskName}
                    onChange={(e: any) => _onChangeTaskName(e)}
                  />
                </div>
                <div className="flex flex-col pl-3 w-1/2 h-max">
                  <label>
                    Loại công việc&nbsp;
                    <span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={
                      selectedLoaiViec === -1
                        ? -1
                        : selectedLoaiViec.ParameterId
                    }
                    onChange={(e: any) => _onChangeSelectedLoaiCongViec(e)}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn loại công việc ---
                    </option>
                    {loaiViecList === null ? (
                      <></>
                    ) : (
                      loaiViecList.map((item: any, index: any) => (
                        <option key={index} value={item.ParameterId}>
                          {item.ParameterValue}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="flex w-full h-max my-1.5">
                <div className="flex flex-col w-1/2 pr-3 h-max">
                  <label>
                    Mức độ&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`
                      w-full h-[2rem] rounded-md pl-2 pr-2 border-2
                      ${selectedUuTien === -1 ? "" : "font-bold"}
                    `}
                    value={selectedUuTien === -1 ? -1 : selectedUuTien.Id}
                    onChange={(e: any) => _onChangeSelectedUuTien(e)}
                    style={{
                      color: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                        }`,
                      borderColor: `${selectedUuTien === -1 ? "" : selectedUuTien.Color
                        }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn mức độ ---
                    </option>
                    {uuTienList === null ? (
                      <></>
                    ) : (
                      uuTienList.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item.Color}` }}
                          key={index}
                          value={item.Id}
                        >
                          {item.PriorityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-1/2 pl-3 h-max">
                  <label>
                    Nguồn văn bản&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    value={
                      selectedNguonVanBan === -1
                        ? -1
                        : selectedNguonVanBan.ParameterId
                    }
                    onChange={(e: any) => _onChangeNguonVanBan(e)}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn nguồn văn bản ---
                    </option>
                    {nguonVanBanList === null ? (
                      <></>
                    ) : (
                      nguonVanBanList.map((item: any, index: any) => (
                        <option key={index} value={item.ParameterId}>
                          {item.ParameterValue}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="flex w-full h-max my-1.5 ">
                <div
                  className={`w-1/2 pr-3 flex-col h-max ${isAutoSecurity === false ? "hidden" : "flex"
                    }`}
                >
                  <label>
                    Độ mật<template></template>&nbsp;
                    <span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedDoMat === -1 ? "" : "font-bold"}
                  `}
                    value={
                      selectedDoMat === -1 ||
                        selectedDoMat === null ||
                        selectedDoMat === undefined
                        ? -1
                        : selectedDoMat.Id
                    }
                    onChange={(e: any) => _onChangeDoMat(e)}
                    style={{
                      color: `${selectedDoMat === -1 ||
                        selectedDoMat === null ||
                        selectedDoMat === undefined
                        ? ""
                        : selectedDoMat.Color
                        }`,
                      borderColor: `${selectedDoMat === -1 ||
                        selectedDoMat === null ||
                        selectedDoMat === undefined
                        ? ""
                        : selectedDoMat.Color
                        }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn độ mật ---
                    </option>
                    {doMatList === null ? (
                      <></>
                    ) : (
                      doMatList.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item.Color}` }}
                          key={index}
                          value={item.Id}
                        >
                          {item.SecurityLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="w-1/2 pl-3 h-max">
                  <label>
                    Độ khó<template></template>&nbsp;
                    <span className="text-red-500">(*)</span>
                  </label>
                  <select
                    className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedDoKho === -1 ? "" : "font-bold"}
                  `}
                    value={
                      selectedDoKho === -1 ||
                        selectedDoKho === null ||
                        selectedDoKho === 0 ||
                        selectedDoKho === undefined
                        ? -1
                        : selectedDoKho.Id
                    }
                    onChange={(e: any) => _onChangeDoKho(e)}
                    style={{
                      color: `${selectedDoKho === -1 || selectedDoKho === undefined
                        ? ""
                        : selectedDoKho.Color
                        }`,
                      borderColor: `${selectedDoKho === -1 || selectedDoKho === undefined
                        ? ""
                        : selectedDoKho.Color
                        }`,
                    }}
                  >
                    <option className="text-gray-300" value={-1} disabled>
                      --- Chọn độ khó ---
                    </option>
                    {doKhoList === null ? (
                      <></>
                    ) : (
                      doKhoList.map((item: any, index: any) => (
                        <option
                          style={{ color: `${item.Color}` }}
                          key={index}
                          value={item.Id}
                        >
                          {item.DifficultLevelName}
                        </option>
                      ))
                    )}
                  </select>
                </div>
              </div>

              <div className="flex w-full h-max my-1.5">
                <div className="flex flex-col w-1/2 pr-3 h-max">
                  <label>
                    Phòng ban&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                </div>
                <select
                  className={`w-full h-[2rem] rounded-md pl-2 pr-2 border-2 ${selectedPhongBan === -1 ? "" : "font-bold"
                    }`}
                  value={
                    selectedPhongBan === -1 ? -1 : selectedPhongBan?.IdPhongBan
                  }
                  onChange={(e: any) => _onChangePhongBan(e)}
                  style={{
                    color: `${selectedPhongBan === -1 ? "" : selectedPhongBan?.ColorText
                      }`,
                    borderColor: `${selectedPhongBan === -1 ? "" : selectedPhongBan?.ColorText
                      }`,
                  }}
                >
                  <option className="text-gray-300" value={-1} disabled>
                    --- Chọn phòng ban ---
                  </option>
                  {phongBanList === null ? (
                    <></>
                  ) : (
                    phongBanList.map((item: any, index: any) => (
                      <option key={index} value={item.IdPhongBan}>
                        {item.TenPhongBan}
                      </option>
                    ))
                  )}
                </select>
              </div>

              <div className="flex w-full h-max my-1.5">
                <div className="flex flex-col w-1/2 pr-3 h-max">
                  <label>
                    Người nhận&nbsp;<span className="text-red-500">(*)</span>
                  </label>
                </div>

                <select
                  className={`
                    w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200
                    ${selectedNguoiNhan === -1 ? "" : "font-bold"}
                  `}
                  style={{
                    color: `${selectedNguoiNhan === -1
                      ? ""
                      : selectedNguoiNhan.ColorText
                      }`,
                    borderColor: `${selectedNguoiNhan === -1
                      ? ""
                      : selectedNguoiNhan.ColorText
                      }`,
                  }}
                  value={selectedNguoiNhan === -1 ? -1 : selectedNguoiNhan.Id}
                  onChange={(e: any) => _onChangeNguoiNhan(e)}
                  disabled={selectedPhongBan === -1 ? true : false}
                >
                  <option className="text-gray-300" value={-1} disabled>
                    --- Chọn người nhận ---
                  </option>
                  {selectedNguoiNhanList === null ? (
                    <></>
                  ) : (
                    selectedNguoiNhanList.map((item: any, index: any) => (
                      <option
                        style={{ color: `${item.ColorText}` }}
                        key={index}
                        value={item.Id}
                      >
                        {item.FullName}
                      </option>
                    ))
                  )}
                </select>
              </div>

              <div className="flex w-full h-max items-center my-1.5">
                {/* overload */}
                <div className="flex w-full h-max items-center">
                  <div style={{ display: "flex" }}>
                    <label>Có thể nhận việc: </label>
                    <div
                      style={{
                        backgroundColor: "#00ff00",
                        width: 30,
                        borderRadius: 40,
                        margin: 10,
                      }}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <label>Đang nhiều việc: </label>
                    <div
                      style={{
                        backgroundColor: "#FFAA00",
                        width: 30,
                        borderRadius: 40,
                        margin: 10,
                      }}
                    ></div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <label>Quá tải: </label>
                    <div
                      style={{
                        backgroundColor: "#ff0000",
                        width: 30,
                        borderRadius: 40,
                        margin: 10,
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div className="flex w-full h-max my-1.5">
                {/* elemetns */}
                <div className="flex flex-col w-1/2 pr-3 h-max space-y-4">
                  <div className="flex flex-col w-full h-max space-y-1">
                    <div className="flex w-max h-max space-x-3">
                      <label>Người phối hợp (nếu có)</label>
                      <button
                        className={`
                    text-white pl-2 pr-2 pt-0.5 pb-0.5 rounded-md
                    text-xs
                    ${selectedNguoiNhan === -1
                            ? "bg-slate-300"
                            : "bg-primary hover:bg-sky-400"
                          }
                  `}
                        disabled={selectedNguoiNhan === -1 ? true : false}
                        onClick={() =>
                          _onChangeNguoiPhoiHop("multipleChoicePartner")
                        }
                      >
                        Chọn
                      </button>
                    </div>
                    <div className="flex flex-col h-max">
                      {selectedNguoiLamViecList === null
                        ? ""
                        : selectedNguoiLamViecList.map(
                          (item: any, index: any) => (
                            <>
                              {item.Id === selectedNguoiNhan.Id ? (
                                <></>
                              ) : (
                                <div
                                  key={index}
                                  className="flex w-max h-max text-primary items-center justify-between"
                                >
                                  <div
                                    className="flex w-max h-max space-x-1 font-bold"
                                    style={{
                                      color: `${item.ColorText}`,
                                    }}
                                  >
                                    <PersonOutlineTwoToneIcon fontSize="small" />
                                    <p>{item.FullName}</p>
                                  </div>
                                  <button
                                    className="text-red-500 flex justify-start"
                                    onClick={() =>
                                      _onCheckNguoiLamViec(item.Id)
                                    }
                                  >
                                    <DeleteTwoToneIcon fontSize="small" />
                                  </button>
                                </div>
                              )}
                            </>
                          )
                        )}
                    </div>
                  </div>
                  <div
                    className={`
                flex-col w-full h-max space-y-1
              `}
                  >
                    <div
                      className={`${isAutoForce === false ? "hidden" : "flex"
                        } w-max h-max space-x-3`}
                    >
                      <input
                        type="checkbox"
                        checked={isForce}
                        onChange={_onChangeIsForce}
                      />
                      <label className="text-slate-500 font-bold pl-2 pr-2 bg-slate-200">
                        KHÔNG CHO PHÉP TRẢ VIỆC
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full h-max my-1.5">
                {/* elements */}
                <div className="w-full h-max">
                  <label>
                    Thời hạn hoàn thành&nbsp;
                    <span className="text-red-500">(*)</span>
                  </label>
                  <input
                    className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                    placeholder="Nhập tên công việc"
                    type="datetime-local"
                    value={deadLine}
                    onChange={(e: any) => _onChangeDeadLine(e)}
                  />
                </div>
              </div>

              <div className="flex flex-col w-full h-max my-1.5">
                <div className="flex w-full h-max space-x-3">
                  <div className="w-1/2 h-max">
                    <label>Quy trình &nbsp;(nếu có)</label>
                    <select
                      className={`
                  w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200 text-sm
                  `}
                      value={
                        selectedQuyTrinh === -1 ||
                          selectedQuyTrinh === undefined
                          ? -1
                          : selectedQuyTrinh.id
                      }
                      disabled={selectedNguoiNhan === -1 ? true : false}
                      onChange={(e: any) => _onChangeQuyTrinh(e)}
                    >
                      <option className="text-gray-300" value={-1} disabled>
                        --- Chọn quy trình ---
                      </option>
                      {quyTrinhList === null ? (
                        <></>
                      ) : (
                        quyTrinhList.map((item: any, index: any) => (
                          <option key={index} value={item.id}>
                            {item.tenquytrinh}
                          </option>
                        ))
                      )}
                    </select>
                  </div>

                  <div className="w-1/2 h-max">
                    <label>Mã tham chiếu</label>
                    <input
                      className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                      placeholder="Nhập refcode"
                      value={refCode}
                      onChange={(e: any) => _onChangeRefCode(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="flex w-full h-max my-1.5">
                {/* buoc */}
                <div
                  className={`w-full h-max tableFixHead ${selectedBuocList === null || selectedBuocList === undefined
                    ? "flex"
                    : "flex"
                    }`}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300">Nội dung</th>
                        <th className="border border-slate-300">
                          <button
                            className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                            onClick={() => _onClickEditBuoc("editBuoc")}
                          >
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedBuocList === null ||
                        selectedBuocList === undefined
                        ? ""
                        : selectedBuocList.map((item: any, index: any) => (
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="flex flex-col border border-slate-300 space-y-2">
                              <p className="font-bold">
                                Bước&nbsp;{item.SortOrder}:
                              </p>
                              <p>{item.StepName}</p>
                              <select
                                className="lg:w-full sm:w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                value={item.UserDo}
                                onChange={(e: any) => {
                                  e.preventDefault();
                                  _onChangeNguoiPhoiHopEachStep(
                                    item.SortOrder,
                                    e.target.value
                                  );
                                }}
                              >
                                <option
                                  className="text-gray-300"
                                  value={""}
                                  disabled
                                >
                                  --- Chọn người thực hiện ---
                                </option>
                                {selectedNguoiLamViecList === null
                                  ? ""
                                  : selectedNguoiLamViecList.map(
                                    (item: any, index: any) => (
                                      <option key={index} value={item.Id}>
                                        {item.FullName}
                                      </option>
                                    )
                                  )}
                              </select>
                              <input
                                className="w-full h-[2rem] rounded-md pl-2 pr-2 border-2 border-slate-200"
                                type="datetime-local"
                                value={item.DeadLine_Step}
                                onChange={(e: any) => {
                                  e.preventDefault();
                                  _onChangDeadLineStep(
                                    item.SortOrder,
                                    e.target.value
                                  );
                                }}
                              />
                            </td>
                            <td className="border border-slate-300">
                              <button className="text-red-500 w-1/6">
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex w-full h-max my-1.5">
                {/* ket qua */}
                <div
                  className={` w-max h-max ${selectedKetQuaList === null ? "hidden" : "flex"
                    }`}
                >
                  <table className="w-full">
                    <thead className="text-white font-semibold text-center w-full h-[2rem]">
                      <tr>
                        <th className="border border-slate-300 ">STT</th>
                        <th className="border border-slate-300 ">
                          Kết quả cần đạt
                        </th>
                        <th className="border text-center border-slate-300 w-[1rem]">
                          <button
                            className="
                  flex items-center justify-center w-[1rem] h-[1rem] text-green-400 text-sm
                  rounded-full border-2 border-green-400 hover:text-white hover:bg-green-400
                "
                            onClick={() => _onClickEditKetqua("editKetqua")}
                          >
                            +
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto">
                      {selectedKetQuaList === null ? (
                        <tr></tr>
                      ) : (
                        selectedKetQuaList.map((item: any, index: any) => (
                          <tr key={index} className="w-max h-[3rem] bg-white">
                            <td className="text-center border border-slate-300">
                              {index + 1}
                            </td>
                            <td className="text-left border border-slate-300">
                              {item.tenketqua}
                            </td>
                            <td className="border text-center border-slate-300 w-[1rem]">
                              {/* <button className="text-red-500">
                                <DeleteTwoToneIcon fontSize="small" />
                              </button> */}
                              <button
                                className="text-red-500 w-max h-max flex text-center hover:text-red -300"
                                onClick={() => _onDeleteKetqua(item.tenketqua)}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>

          <div className="flex flex-col lg:w-2/3 h-max pr-3 space-y-4">
            {/* elements */}
            <div className="flex flex-col w-full sm:w-full h-max">
              <label>Mô tả</label>
              <textarea
                className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
                placeholder="Nhập mô tả"
                value={description}
                onChange={(e: any) => _onChangeDescription(e)}
              />
            </div>
            <div className="flex flex-col w-full sm:w-full h-max">
              <label>Ghi chú</label>
              <textarea
                className="w-full h-[7rem] rounded-md p-2 border-2 border-slate-200"
                placeholder="Nhập ghi chú"
                value={notes}
                onChange={(e: any) => _onChangeNotes(e)}
              />
            </div>
            <div className="flex w-full h-max">
              {/* elements */}
              <div className="flex flex-col lg:w-1/2 sm:w-max h-max pr-3">
                {/* <div className="flex w-full h-max items-center space-x-2">
                    <p>Đính kèm</p>
                    <input
                      className={`w-max h-max`}
                      multiple
                      type="file"
                      onChange={(e: any) => _onChangeFileImage(e)}
                    />
                  </div> */}
                <div className="flex w-max h-full space-x-2 text-xs">
                  <button
                    className="flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white hover:bg-sky-400 "
                    onClick={_onClickChooseFileChat}
                  >
                    <AttachFileIcon fontSize="small" />
                    <p>Chọn file</p>
                  </button>
                </div>
                <input
                  ref={uploadFileChat}
                  className={`w-max h-max`}
                  multiple
                  type="file"
                  onChange={(e: any) => _onChangeFileImage(e)}
                  hidden
                />
                {/* fields of file */}
                <div className="flex flex-col w-full h-max">
                  {/* element */}
                  <div className="flex w-full h-max text-primary items-center justify-between">
                    <div className="flex flex-col w-full h-max">
                      {fileListAPI.length === 0 ? (
                        <></>
                      ) : (
                        fileListAPI.map((item: any, index: any) => (
                          <div key={index} className="flex w-full h-max">
                            <a
                              href={item.listFile}
                              target="_blank"
                              rel="noreferrer"
                              className="flex w-5/6 h-max space-x-1"
                            >
                              <InsertDriveFileTwoToneIcon fontSize="small" />
                              <p>{item.listFileName}</p>
                            </a>
                            <button
                              className="text-red-500 w-1/6 flex"
                              onClick={() => _onDeleteFileReady(item.listFile)}
                            >
                              <DeleteTwoToneIcon fontSize="small" />
                            </button>
                          </div>
                        ))
                      )}
                      {filesList.length === 0 ||
                        typeof filesList === "undefined" ? (
                        <></>
                      ) : (
                        filesList.map((item: any, index: any) => (
                          <div key={index} className="flex w-full h-max">
                            <div className="flex w-full h-max space-x-1 items-center justify-between">
                              <div className="w-max h-max flex">
                                <InsertDriveFileTwoToneIcon fontSize="small" />
                                <p>{item.name}</p>
                              </div>
                              <button
                                className="text-red-500 w-1/6 flex"
                                onClick={() => _onDeleteElements(index)}
                              >
                                <DeleteTwoToneIcon fontSize="small" />
                              </button>
                            </div>

                            {/* <button
                              className="text-red-500 w-1/6 flex justify-end"
                              onClick={() => _onDeleteElements(index)}
                            >
                              <DeleteTwoToneIcon fontSize="small" />
                            </button> */}
                          </div>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* footer */}
      <div className="flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-end items-center pl-4 pr-4 text-sm space-x-4">
        <div className="flex w-max h-max space-x-3">
          {urlLink === "/working/task-total" ? (
            <></>
          ) : (
            <div className="w-max h-max multiple">
              {/* button options */}
              <button
                className={`flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1  text-white rounded-md
          
          ${selectedPhongBan === -1 ||
                    selectedNguoiNhan === -1 ||
                    selectedLoaiViec === -1 ||
                    selectedNguonVanBan === -1 ||
                    selectedUuTien === -1 ||
                    selectedDoMat === -1 ||
                    selectedDauViec === -1 ||
                    selectedDoKho === -1 ||
                    deadLine === "" ||
                    taskName === "" ||
                    _onCheckValid() === false ||
                    isLoading === true
                    ? // || selectedQuyTrinh === -1 ||
                    // isSelectedBuocOK === false
                    "bg-slate-300"
                    : "bg-primary hover:bg-sky-400"
                  }
          `}
                disabled={
                  selectedPhongBan === -1 ||
                    selectedNguoiNhan === -1 ||
                    selectedLoaiViec === -1 ||
                    selectedNguonVanBan === -1 ||
                    selectedUuTien === -1 ||
                    selectedDoMat === -1 ||
                    selectedDauViec === -1 ||
                    selectedDoKho === -1 ||
                    deadLine === "" ||
                    taskName === "" ||
                    _onCheckValid() === false ||
                    isLoading === true
                    ? // || selectedQuyTrinh === -1 ||
                    // isSelectedBuocOK === false
                    true
                    : false
                }
              >
                <AssignmentIcon fontSize="small" />
                <p>Tác vụ</p>
              </button>
              {/* options fields */}
              <div
                className={`
               ${selectedPhongBan === -1 ||
                    selectedNguoiNhan === -1 ||
                    selectedLoaiViec === -1 ||
                    selectedNguonVanBan === -1 ||
                    selectedUuTien === -1 ||
                    selectedDoMat === -1 ||
                    selectedDauViec === -1 ||
                    selectedDoKho === -1 ||
                    deadLine === "" ||
                    taskName === "" ||
                    _onCheckValid() === false ||
                    isLoading === true
                    ? // || selectedQuyTrinh === -1 ||
                    // isSelectedBuocOK === false
                    "hidden"
                    : "flex"
                  }
              `}
              >
                <div className="multiple-content text-xs font-semibold">
                  {/* delete */}
                  <button
                    className="flex w-full h-full hover:text-red-500"
                    onClick={() => _onClickEditBuoc("deleteTask")}
                  >
                    {/* delete - contain */}
                    <div className="flex w-full space-x-1">
                      {/* icon */}
                      <DeleteTwoToneIcon
                        className="text-red-600"
                        sx={{ fontSize: 18 }}
                        fontSize="small"
                      />
                      {/* title */}
                      <div className="h-full">Xoá</div>
                    </div>
                  </button>
                  {/* delitask */}
                  <button
                    className="flex w-full h-full hover:text-emerald-500"
                    onClick={() => _onClickEditBuoc("giveTask")}
                  >
                    {/* detail - contain */}
                    <div className="flex w-full h-full space-x-1">
                      {/* icon */}
                      <SendTwoToneIcon
                        className="text-emerald-600"
                        sx={{ fontSize: 18 }}
                        fontSize="small"
                      />
                      {/* title */}
                      <div>Giao việc</div>
                    </div>
                  </button>
                  {/* tranf boss */}

                  <div
                    className={`${urlLink === "/working/task-need-give" ? "hidden" : "flex"
                      }`}
                  >
                    <button
                      className={`w-full h-full hover:text-amber-500
                        hidden
                      `}
                      onClick={_onClickSwitchToBoss}
                    >
                      {/* detail - contain */}
                      <div className="flex w-full h-full space-x-1">
                        {/* icon */}
                        <CameraFrontTwoToneIcon
                          className="text-amber-600"
                          sx={{ fontSize: 18 }}
                          fontSize="small"
                        />
                        {/* title */}
                        <div>Chuyển sếp</div>
                      </div>
                    </button>
                  </div>

                  {/* detail */}
                  <button
                    className="flex w-full h-full hover:text-sky-500"
                    onClick={_onClickUpdate}
                  >
                    {/* detail - contain */}
                    <div className="flex w-full h-full space-x-1">
                      {/* icon */}
                      <DescriptionTwoToneIcon
                        className="text-sky-600"
                        sx={{ fontSize: 18 }}
                        fontSize="small"
                      />
                      {/* title */}
                      <div>Lưu chỉnh sửa</div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          )}

          <button
            className="
          flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
          hover:bg-slate-200
        "
            onClick={handleClose}
          >
            <ExitToAppTwoToneIcon fontSize="small" />
            <p>Đóng</p>
          </button>
        </div>
      </div>
    </div>
  );
};
export default CreateNewTask;
