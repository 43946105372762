import { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ONCHANGE_DATE,
  ONCHANGE_SELECTED_BOSS,
} from "../../../redux/reducers/workingReducer/_index";

import moment from "moment";

import { onTouchForm } from "../../../funcs/onTochForm";
import Select from "react-select";

import ReplayIcon from "@mui/icons-material/Replay";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import ModalBase from "../../../components/modal/_index";

const FilterProposeMobile = (props: any) => {
  const urlLink = window.location.pathname;
  const _loadData = props._loadData;

  let renameObjectKey = (object: any) => {
    object.FullName && (object.label = object.FullName);

    object.FullName && (object.value = object.FullName);

    object.PriorityLevelName && (object.label = object.PriorityLevelName);

    object.PriorityLevelName && (object.value = object.PriorityLevelName);

    object.label && (object.value = object.label);

    object.Name && (object.label = object.Name);
  };

  const statusList = props.statusList;
  const setStatus = props.setStatus;
  const proposeTypeList = props.proposeTypeList;
  proposeTypeList.map((item: any) => renameObjectKey(item));

  const priorList = props.priorList;
  priorList.map((item: any) => renameObjectKey(item));

  const senderList = props.senderList;
  senderList.map((item: any) => renameObjectKey(item));

  const receiverList = props.receiverList;
  receiverList.map((item: any) => renameObjectKey(item));

  const name = props.name;
  const setName = props.setName;
  const proposeTypeID = props.proposeTypeID;
  const setProposeTypeID = props.setProposeTypeID;
  const priorId = props.priorId;
  const setPriorId = props.setPriorId;
  const userAdd = props.userAdd;
  const setUserAdd = props.setUserAdd;

  var userCheck = props.userCheck;
  var setUserCheck = props.setUserCheck;

  const dateAdd_From = props.dateAdd_From;
  const setDateAdd_From = props.setDateAdd_From;
  const dateAdd_To = props.dateAdd_To;
  const setDateAdd_To = props.setDateAdd_To;
  // redux
  const workingData = useSelector((state: any) => state.workingReducer);
  const dispatch = useDispatch();

  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string>();
  const [openSub, setOpenSub] = useState(false);
  const handleCloseSub = () => setOpenSub(false);
  const handleOpenSub = () => setOpenSub(true);
  const [funcsSub, setFuncsSub] = useState<string>("");

  const onOpenSub = (text: string) => {
    handleOpenSub();
    setFuncsSub(text);
  };

  const _onClickOpenSearch = () => {
    setOpenSearch(!openSearch);
  };

  function handleSelectStatus(data: any) {
    setStatus(data ? data.Id : "");
  }
  function handleSelectProposeType(data: any) {
    setProposeTypeID(data ? data.ID : "");
  }
  function handleSelectPrior(data: any) {
    setPriorId(data ? data.Id : "");
  }

  function handleSelectReceiver(data: any) {
    setUserCheck(data ? data.Id : "");
  }

  function handleSelectSender(data: any) {
    setUserAdd(data ? data.Id : "");
  }

  useEffect(() => {
    setFromDate(moment(workingData.searchFilter.fromDate).format("DD/MM/YYYY"));
  }, [workingData.searchFilter.fromDate]);

  return (
    <div className="w-full h-max pb-3">
      <ModalBase
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        _loadData={_loadData}
      />
      <div className="flex justify-end space-x-3 w-full h-[2rem]">
        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
          onClick={_onClickOpenSearch}
        >
          <SearchIcon fontSize="small" />
        </button>

        <button
          className="
            flex items-center pl-2 pr-2 pt-1 pb-1 bg-amber-500 rounded-md text-white
              hover:bg-amber-400
            "
          onClick={() => window.location.reload()}
        >
          <ReplayIcon fontSize="small" />
        </button>
        {urlLink !== "/propose/receiver/list" ? (
          <button
            className="flex h-[1.9rem] pl-2 pr-2 pt-1 pb-1 bg-green-500 rounded-md text-white hover:bg-green-400 items-center "
            onClick={() => onOpenSub("createPropose")}
          >
            <AddIcon fontSize="small" />
          </button>
        ) : null}
      </div>
      {openSearch ? (
        <div className="flex flex-col py-3">
          <div className="ten-de-xuat flex flex-col">
            <label>Tên đề xuất</label>
            <input
              className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Nhập tên đề xuất"
              value={name}
              onChange={(e: any) => {
                e.preventDefault();
                setName(e.target.value);
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
          <div className="ten-de-xuat flex flex-col py-1">
            <label>Trạng thái</label>
            <Select
              options={statusList}
              onChange={handleSelectStatus}
              isClearable
              placeholder="Chọn trạng thái"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="loại flex flex-col py-1">
            <label>Loại đề xuất</label>
            <Select
              options={proposeTypeList}
              onChange={handleSelectProposeType}
              isClearable
              placeholder="Chọn loại đề xuất"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="muc-do flex flex-col py-1">
            <label>Mức độ</label>
            <Select
              options={priorList}
              onChange={handleSelectPrior}
              isClearable
              placeholder="Chọn mức độ"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="nguoi-gui flex flex-col py-1">
            <label>Người đề xuất</label>
            <Select
              options={senderList}
              onChange={handleSelectSender}
              isClearable
              placeholder="Chọn người đề xuất"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="nguoi-nhan flex flex-col py-1">
            <label>Người nhận</label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              options={receiverList}
              onChange={handleSelectReceiver}
              isClearable
              isSearchable
              placeholder="Chọn người nhận"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
          </div>
          <div className="ngay-gui-from flex flex-col py-1">
            <label>Ngày gửi (Từ ngày)</label>
            <input
              className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Ngày gửi (Từ ngày)"
              type="datetime-local"
              value={dateAdd_From}
              onChange={(e: any) => {
                e.preventDefault();
                setDateAdd_From(e.target.value);

                dispatch(
                  ONCHANGE_DATE({ type: "fromDate", value: e.target.value })
                );
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
            <input type="date" id="hidden-input" style={{ display: "none" }} />
          </div>
          <div className="ngay-gui-to flex flex-col py-1">
            <label>Ngày gửi (Đến ngày)</label>
            <input
              className="w-full h-[2.4rem] rounded-[4px] pl-2 pr-2 border-[1px] border-gray-400"
              placeholder="Ngày gửi (Đến ngày)"
              type="datetime-local"
              value={dateAdd_To}
              onChange={(e: any) => {
                e.preventDefault();
                setDateAdd_To(e.target.value);
                dispatch(
                  ONCHANGE_DATE({ type: "toDate", value: e.target.value })
                );
              }}
              onTouchStart={(e: any) => onTouchForm(e)}
            />
          </div>
          <div className="py-1">
            <button
              className="
            flex items-center w-full justify-center pl-2 pr-2 pt-1 pb-1 bg-primary rounded-md text-white
              hover:bg-sky-400
            "
              onClick={_loadData}
            >
              <p>Tìm kiếm</p>
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FilterProposeMobile;
