import React from "react";

import Title from "../../../../components/title";
import SubmitHeader from "../../../../components/submitHeader";
import ContentTop from "../../../../components/contentTop";
import Table from "../../../../components/table";
import ReportContent from "../../../../core/reportContent";

const WorkerCapacityUI = () => {
  return (
    <div className="flex flex-col w-full h-full">

      {/* <Title />

      <div className="flex flex-col w-full h-[calc(100%-1.8rem)] pl-5 pr-5 text-sm">
        <SubmitHeader />

        <div className="flex flex-col w-full lg:h-[5rem] h-max space-y-3">
          
          <ContentTop />

          <p className="text-green-600 font-semibold text-xs">Áp dụng đối chiếu với bản thiết lập đánh giá năng lực: BẢNG THIẾT LẬP THÁNG 2</p>
        </div>

        <Table />
      </div> */}
      <ReportContent />

    </div>
  )
}

export default WorkerCapacityUI;
