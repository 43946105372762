/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { Pagination } from "@mui/material";

import moment from "moment";

import SubModal from "../_subModal/_index";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import PanToolAltTwoToneIcon from "@mui/icons-material/PanToolAltTwoTone";

// api importing
import {
  NGUOI_GIAO_LIST_PROCCESSING,
  NGUOI_GIAO_RECEVED,
} from "../../../apis/_index";
import axios from "axios";
import { toast } from "react-toastify";

const TotalListReceved = (props: any) => {
  // props
  const handleClose = props.handleClose;
  const dataModal = props.dataModal;
  const userId = props.userId;

  // state
  const [data, setData] = useState<any[] | null>(null);
  const [pageCurrent, setPageCurrent] = useState<number>(0);
  const pageLimit = 20;
  const [pageTotal, setPageTotal] = useState<number>(0);
  const [buttonSelected, setButtonSelected] = useState<number>(0);

  const [buttonList, setButtonList] = useState<any[]>([
    {
      id: 0,
      title: "countListWaiting",
      isShow: true,
    },
  ]);

  // funcs onClick
  const _onClickChoosingButton = (type: string) => {
    setPageCurrent(0);

    const tempArray = buttonList.map((item) => {
      if (item.title === type) {
        setButtonSelected(item.id);
        return {
          ...item,
          isShow: true,
        };
      } else {
        return {
          ...item,
          isShow: false,
        };
      }
    });

    setButtonList(tempArray);
  };

  // onMount
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    const takeData = async () => {
      const body = {
        Page: pageCurrent,
        Limit: pageLimit,
        Recipient: userId,
      };

      try {
        await axios
          .post(NGUOI_GIAO_RECEVED, body, {
            headers: {
              Authorization: "Bearer " + accessToken,
              Accept: "text/plain",
            },
          })
          .then((res) => {
            if (res.data.StatusCode === 200) {
              setData(res.data.Data.data);

              if (res.data.Data.count % 20 === 0) {
                setPageTotal(Math.floor(res.data.Data.count / 20));
              } else {
                setPageTotal(Math.floor(res.data.Data.count / 20) + 1);
              }
            }
          });
      } catch (error) {
        toast.error("Tải dữ liệu thất bại");
      }
    };

    takeData();
  }, [buttonSelected, pageCurrent]);

  // funcs
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPageCurrent(value - 1);
  };

  // For subModal
  const [openSub, setOpenSub] = useState(false);
  const [funcsSub, setFuncsSub] = useState<string>("");
  const handleOpenSub = () => setOpenSub(true);
  const handleCloseSub = () => setOpenSub(false);
  const [taskId, setTaskId] = useState<string>("");

  const _onClickDetailOfTask = (itemId: string) => {
    setTaskId(itemId);
    setFuncsSub("detailOfTask");
    handleOpenSub();
  };
  // End sub

  return (
    <div
      className="
      flex flex-col bg-white
      lg:w-[70rem] w-screen h-[40rem]
      rounded-md
    "
    >
      {/* SubModal */}
      <SubModal
        open={openSub}
        handleClose={handleCloseSub}
        funcs={funcsSub}
        taskId={taskId}
      />

      {/* header */}
      <div
        className="
        flex w-full h-[3rem] bg-sky-500 rounded-t-md
        pl-4 pr-4 items-center font-semibold text-white
        text-lg justify-between
      "
      >
        <p>Tổng việc chưa xử lý (Thực hiện chính)</p>
        <button
          className="hover:bg-slate-300 pl-2 pr-2 rounded-full"
          onClick={handleClose}
        >
          X
        </button>
      </div>

      {/* content */}
      <div className="flex text-primary w-full h-[calc(100%-6rem)] overflow-auto p-4 lg:text-xs text-xs styled-scrollbars">
        {/* Left info */}
        <div className="flex flex-col w-1/5 h-full lg:text-md text-xs border-r-2 border-slate-300 space-y-1 pt-[0.5rem] pr-1">
          {/* Elements */}

          {/* <div className="flex flex-col w-max h-max">
            <button className={`
              w-max h-max font-semibold hover:font-bold
              ${buttonList[1].isShow === true ? "bg-sky-200" : ""}
            `}
              onClick={() => _onClickChoosingButton("countListReveced")}
            >
              <p>Công việc đã nhận</p>
            </button>
            <p>{dataModal.countListReveced}</p>
          </div>

          <div className="flex flex-col w-max h-max">
            <button className={`
              w-max h-max font-semibold hover:font-bold
              ${buttonList[2].isShow === true ? "bg-sky-200" : ""}
            `}
              onClick={() => _onClickChoosingButton("countListReturnWork")}
            >
              <p>Công việc được trả lại</p>
            </button>
            <p>{dataModal.countListReturnWork}</p>
          </div> */}
        </div>

        {/* Right table */}
        <div className="w-4/5 h-full pl-3">
          <div className="w-full h-full">
            <div className="tableFixHead w-full h-[calc(100%-2rem)] shadow-md">
              <table className="shadow-lg transition-all hover:shadow-slate-500">
                <thead className="text-white font-semibold text-center w-full h-[2rem]">
                  <tr>
                    <th className="hidden lg:table-cell border border-slate-300 ">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>STT</p>
                        <button className="hidden lg:flex w-max h-max hover:text-slate-200"></button>
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Loại</p>
                      </div>
                    </th>
                    <th className="border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Tên công việc</p>
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Mức độ</p>
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Ngày giao</p>
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Người nhận</p>
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center">
                        <p>Thời hạn</p>
                      </div>
                    </th>
                    <th className="hidden lg:table-cell border-l-[1px] border-slate-300">
                      <div className="w-full h-full flex space-x-1 justify-center items-center"></div>
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full h-[calc(100%-2rem)] bg-white overflow-auto text-black">
                  {data === null ? (
                    <tr></tr>
                  ) : (
                    data?.map((item: any, index: any) => (
                      <tr className="w-max h-[3rem] bg-white">
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          {index + 1 + pageCurrent * pageLimit}
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300">
                          {item.IsRecipient ? (
                            <p>Thực hiện chính</p>
                          ) : (
                            <p>Hỗ trợ</p>
                          )}
                        </td>
                        <td className="border border-slate-300 space-y-0.5">
                          <p className="lg:hidden font-bold">
                            <span className="lg:hidden">{index}</span>
                            {item.TaskName}
                          </p>
                          <p className="hidden lg:flex">{item.TaskName}</p>
                          <p className="lg:hidden">
                            <span className="text-xs">Người nhận: </span>
                            <span className="font-semibold">
                              {item.RecipientName}
                            </span>
                          </p>
                          {item.SupporterName !== null && (
                            <p className="lg:hidden">
                              <span className="text-xs">
                                Người hỗ trợ:&nbsp;
                              </span>
                              <span className="text-slate-600">
                                {item.SupporterName}
                              </span>
                            </p>
                          )}
                          <div className="lg:hidden w-full h-max flex flex-col justify-center">
                            <p>
                              <span className="text-xs">Thời hạn: </span>
                              <span className="italic">
                                {moment(item.AssignmentDate).format("HH:mm")}
                                &nbsp;
                                {moment(item.AssignmentDate).format("DD/MM")}
                              </span>
                              &nbsp;-&nbsp;
                              <span className="italic">
                                {moment(item.DeadLine).format("HH:mm")}&nbsp;
                                {moment(item.DeadLine).format("DD/MM")}
                              </span>
                            </p>
                            <p>{item.PriorityLevelName}</p>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <p>{item.PriorityLevelName}</p>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="w-full h-max flex flex-col justify-center items-center">
                            <p>{moment(item.DateAdd).format("DD/MM/YYYY")}</p>
                            <p>{moment(item.DateAdd).format("HH:mm:ss")}</p>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell border border-slate-300">
                          {item.RecipientName}
                          {item.SupporterName && (
                            <p className="text-xs">
                              <span className="text-slate-600">Hỗ trợ:</span>{" "}
                              {item.SupporterName}
                            </p>
                          )}
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="w-full h-max flex flex-col justify-center items-center">
                            <p>{moment(item.DeadLine).format("DD/MM/YYYY")}</p>
                            <p>{moment(item.DeadLine).format("HH:mm:ss")}</p>
                          </div>
                        </td>
                        <td className="hidden lg:table-cell text-center border border-slate-300">
                          <div className="w-full h-max flex flex-col justify-center items-center">
                            <div className="dropdown">
                              {/* button options */}
                              <button className="dropbtn">
                                <MoreHorizIcon fontSize="small" />
                              </button>

                              {/* options fields */}
                              <div className="dropdown-content text-xs font-semibold">
                                {/* detail */}
                                <button
                                  className="flex w-full h-full"
                                  onClick={() => _onClickDetailOfTask(item.ID)}
                                >
                                  {/* detail - contain */}
                                  <div className="flex w-full h-full space-x-1 hover:text-sky-500">
                                    {/* icon */}
                                    <DescriptionTwoToneIcon
                                      className="text-sky-600"
                                      sx={{ fontSize: 18 }}
                                      fontSize="small"
                                    />

                                    {/* title */}
                                    <div>Chi tiết</div>
                                  </div>
                                </button>

                                {/* take task */}
                                {item.IsRecipient && (
                                  <button
                                    className="flex w-full h-full"
                                    onClick={() =>
                                      _onClickDetailOfTask(item.ID)
                                    }
                                  >
                                    {/* detail - contain */}
                                    <div className="flex w-full h-full space-x-1 hover:text-emerald-500">
                                      {/* icon */}
                                      <PanToolAltTwoToneIcon
                                        className="text-emerald-600"
                                        sx={{ fontSize: 18 }}
                                        fontSize="small"
                                      />

                                      {/* title */}
                                      <div>Nhận việc</div>
                                    </div>
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
            <div className="flex lg:flex-row flex-col items-center lg:justify-end w-full h-[2rem] pt-2">
              <Pagination
                count={pageTotal}
                page={pageCurrent + 1}
                variant="outlined"
                color="primary"
                size="small"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>

      {/* footer */}
      <div
        className={`flex w-full h-[3rem] bg-slate-100 rounded-b-md justify-end items-center pl-4 pr-4 text-sm space-x-4`}
      >
        <button
          className="
              flex items-center space-x-1 w-max h-max pl-2 pr-2 pt-1 pb-1 bg-slate-300 text-white rounded-md
              hover:bg-slate-200
            "
          onClick={handleClose}
        >
          <p>Đóng</p>
        </button>
      </div>
    </div>
  );
};

export default TotalListReceved;
