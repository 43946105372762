import React from "react";

const Error404UI = () => {
  return (
    <div className="w-full h-full flex flex-col p-4">
      <p className="text-xl text-primary font-bold">Trang không tồn tại</p>
      <p className="text-primary">Vui lòng chọn các trang khác</p>
    </div>
  )
}

export default Error404UI