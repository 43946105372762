import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { takePermission } from "../../../../funcs";

import AddIcon from "@mui/icons-material/Add";

import Select from "react-select";

import Title from "../../../../components/title";
import Table from "../../../../components/table";

import { ModalController } from "../../../homeUI/_index";
import { convertNameParameter } from "../../../../helperText/index";

import {
  TAKE_PARAMETER_LISTKEY,
  READ_PARAMETER,
} from "../../../../apis/_index";
import {
  ONCHANGE_PARAMETER_LISTKEY,
  ONLOAD_PARAMETER_LIST,
  ONCHANGE_PARAMETERCHOOSE,
} from "../../../../redux/reducers/settingReducer/_index";

import axios from "axios";

const ListOfGeneralUI = () => {
  // // menuList
  // const menuList = useSelector((state: any) => state.appReducer.subMenu.filter((e: any) => e.FormId === 12));
  const menu = takePermission(12);

  const context = useContext(ModalController);
  const data = useSelector((state: any) => state.settingReducer);
  const [parameterChoose, setParameterChoose] = useState<any>(1);
  const dispatch = useDispatch();

  const shouldLog = useRef(true);
  const shouldLog2 = useRef(true);

  // onload listkey
  useEffect(() => {
    const listKey = localStorage.getItem("parameterListKey");

    if (listKey === null && shouldLog.current) {
      shouldLog.current = false;
      const accessToken = localStorage.getItem("accessToken");

      const takeListKey = async () => {
        try {
          await axios
            .get(TAKE_PARAMETER_LISTKEY, {
              headers: {
                Authorization: "Bearer " + accessToken,
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                localStorage.setItem(
                  "parameterListKey",
                  JSON.stringify(res.data.Data)
                );
                dispatch(ONCHANGE_PARAMETER_LISTKEY());
              } else {
                toast.error("Tải dữ liệu danh mục thất bại.");
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu danh mục thất bại.");
        }
      };

      takeListKey();
    } else {
      if (data.parameterListKey.length === 0 && shouldLog.current) {
        shouldLog.current = false;
        dispatch(ONCHANGE_PARAMETER_LISTKEY());
      } else {
        return;
      }
    }
  });

  // onload parameterlist
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken !== null && shouldLog2.current) {
      shouldLog2.current = false;
      const takeParameterList = async () => {
        try {
          await axios
            .get(READ_PARAMETER, {
              headers: {
                Authorization: "Bear " + accessToken,
              },
            })
            .then((res: any) => {
              if (res.status === 200) {
                dispatch(ONLOAD_PARAMETER_LIST(res.data.Data));
              } else {
                return;
              }
            });
        } catch (error: any) {
          toast.error("Tải dữ liệu thất bại.");
        }
      };
      takeParameterList();
    }
  });

  // onload parameterlist via parameterChoose
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    let API = "";

    if (parameterChoose.toString() === "1") {
      API = READ_PARAMETER;
    } else {
      API = READ_PARAMETER + `?key=${parameterChoose}`;
    }

    const takeParameterList = async () => {
      try {
        await axios
          .get(API, {
            headers: {
              Authorization: "Bear " + accessToken,
            },
          })
          .then((res: any) => {
            if (res.status === 200) {
              dispatch(ONLOAD_PARAMETER_LIST(res.data.Data));
            } else {
              return;
            }
          });
      } catch (error: any) {
        toast.error("Tải dữ liệu thất bại.");
      }
    };
    takeParameterList();
  }, [parameterChoose, dispatch]);

  // funcs
  const _onChangeParameterKey = (e: any) => {
    e.preventDefault();
    setParameterChoose(e.target.value);
    dispatch(ONCHANGE_PARAMETERCHOOSE(e.target.value));
  };

  const _onClickCreateListOfGeneral = () => {
    context.setFuncs("createListOfGeneral");
    context.handleOpen();
  };



  // Preprocessing
  // state
  const tempParameterList = data.parameterListKey?.map((item: any) => {
    return { ...item, value: item.ParameterKey, label: convertNameParameter(item.ParameterKey) }
  })

  // funcs
  const _onChangeTempParameter = (data: any) => {
    if (data === null) {
      setParameterChoose(1)
      dispatch(ONCHANGE_PARAMETERCHOOSE(1))
    } else {
      setParameterChoose(data.ParameterKey)
      dispatch(ONCHANGE_PARAMETERCHOOSE(data.ParameterKey))
    }
  }

  // const _onChangeParameterKey = (e: any) => {
  //   e.preventDefault();
  //   setParameterChoose(e.target.value);
  //   dispatch(ONCHANGE_PARAMETERCHOOSE(e.target.value));
  // };

  return (
    <div className="h-full w-full flex flex-col">
      <Title />

      <div className="flex flex-col w-full h-[calc(100%-1.8rem)] p-5 space-y-3">
        <div className="w-full h-[2rem] flex justify-between items-center">
          <div className="w-max h-max flex flex-col">
            <label className="text-xs">Chọn danh mục</label>
            <Select
              className="z-[5]"
              options={tempParameterList}
              onChange={(data: any) => _onChangeTempParameter(data)}
              isClearable
              placeholder="--- Chọn danh mục ---"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
            />
            {/* <select
              className="w-[15rem] shadow-sm h-[1.9rem] rounded-md pl-1 pr-1 border-2 border-slate-200"
              value={parameterChoose}
              onChange={(e: any) => {
                _onChangeParameterKey(e);
              }}
            >
              <option className="text-gray-300" value={0} disabled>
                --- Chọn danh mục ---
              </option>
              <option value={1}>Tất cả danh mục</option>
              {data.parameterListKey.length === 0
                ? ""
                : data.parameterListKey.map((item: any, index: any) => (
                    <option key={index} value={item.ParameterKey}>
                      {item.ParameterKey}
                    </option>
                  ))}
            </select> */}
          </div>

          <button
            className={`
              pl-2 pr-2 pt-1 pb-1 bg-green-500 rounded-md text-white
              hover:bg-green-400 items-center
              ${menu.PerInsert === true ? "flex" : "hidden"}
            `}
            onClick={_onClickCreateListOfGeneral}
          >
            <AddIcon fontSize="small" />
            <p className="hidden lg:flex">Thêm mới</p>
          </button>
        </div>

        <Table />
      </div>
    </div>
  );
};

export default ListOfGeneralUI;
