import React from "react";
import { Oval } from "react-loader-spinner";

const LoaderSpinner = (props: any) => {

  const h = props.h;
  const w = props.w;

  return (
    <Oval
      height={h}
      width={w}
      color="#00B1FF"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel='oval-loading'
      secondaryColor="#38bdf8"
      strokeWidth={3}
      strokeWidthSecondary={3}
    />
  )
}

export default LoaderSpinner;
